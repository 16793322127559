import { QueryKey, useQuery, UseQueryResult } from '@tanstack/react-query';

import useApi from 'hooks/use-api';

export const getOpeningsQueryKey = (shopId: string): QueryKey => [
  shopId,
  'weeklyHours',
];

export const useOpeningsQuery = (shopId: string): UseQueryResult => {
  const api = useApi();

  return useQuery(getOpeningsQueryKey(shopId), () =>
    api.getShopOpenings(shopId),
  );
};
