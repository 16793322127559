import ChooseShop from 'components/choose-shop';
import Page from 'components/shared/page';

const Shops = () => (
  <Page
    name="shops"
    isNavShown={false}
    isHeaderShown={false}
    isFooterShown={false}
  >
    <ChooseShop />
  </Page>
);

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default Shops;
