import { QueryKey, useQuery } from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { PartnerSuccessManagerInfo } from 'types/partner-success-manager';
import { camelCaseKeys } from 'utilities/objects';

export const getPsmInfoQueryKey = (shopId: string): QueryKey => [
  shopId,
  'psmInfo',
];

export const usePsmInfoQuery = (shopId: string) => {
  const { authenticatedFetch } = useApi();

  return useQuery({
    queryKey: getPsmInfoQueryKey(shopId),
    queryFn: async () => {
      const response = await authenticatedFetch.get(
        `api/management/v1/shops/${shopId}/admin`,
      );
      return camelCaseKeys(response) as PartnerSuccessManagerInfo;
    },
  });
};
