import cx from 'classnames';
import PropTypes from 'prop-types';

import Text from 'components/shared/text';
import { toDollarString, toMoneyString } from 'utilities/currency';

import styles from './styles.module.scss';

const ProductType = ({ name, price, unavailable }) => (
  <div className={cx(styles.wrapper, unavailable && styles.unavailable)}>
    <Text clamp className={styles.name}>
      {name}
    </Text>
    <Text wrap="nowrap" className={styles.price}>
      {toMoneyString(toDollarString(price))}
    </Text>
  </div>
);

ProductType.propTypes = {
  name: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  unavailable: PropTypes.bool.isRequired,
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default ProductType;
