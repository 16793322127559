import * as React from "react";
import { forwardRef } from "react";
const SvgValuableCustomersIconNoOutline = ({
  title,
  titleId,
  ...props
}, ref) => {
  return /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", fill: "none", viewBox: "0 0 24 24", ref, "aria-labelledby": titleId, ...props }, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { stroke: "#211E1E", strokeWidth: 2, d: "m12 10-1.7574 1.7574H7.75736v2.4852L6 16l1.75736 1.7574v2.4852h2.48524L12 22l1.7574-1.7574h2.4852v-2.4852L18 16l-1.7574-1.7574v-2.4852h-2.4852L12 10Z" }), /* @__PURE__ */ React.createElement("path", { fill: "#211E1E", d: "M3 3V2H1.25158l.88649 1.50702L3 3Zm5 0 .86824-.49614L8.58032 2H8v1Zm4 7-.8682.4961c.178.3116.5093.5039.8682.5039s.6902-.1923.8682-.5039L12 10Zm9-7 .8619.50702L22.7484 2H21v1Zm-5 0V2h-.5803l-.2879.50386L16 3Zm-7.13807 7.993-5-8.50002-1.72386 1.01404 5 8.49998 1.72386-1.014ZM3 4h5V2H3v2Zm4.13176-.50386 4.00004 6.99996 1.7364-.99224-3.99996-7-1.73648.99228ZM16.8619 12.007l5-8.49998-1.7238-1.01404-5 8.50002 1.7238 1.014ZM21 2h-5v2h5V2Zm-5.8682.50386-4 7 1.7364.99224 4-6.99996-1.7364-.99228Z" }));
};
const ForwardRef = forwardRef(SvgValuableCustomersIconNoOutline);
export default ForwardRef;
