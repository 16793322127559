import { bar, label } from '../svg-settings';

type Props = {
  labelText: string;
  xOffsetFromBarStart: number;
};

const Label = ({ labelText, xOffsetFromBarStart }: Props) => (
  <>
    <line
      x1={bar.x + xOffsetFromBarStart}
      y1={bar.y}
      x2={bar.x + xOffsetFromBarStart}
      y2={bar.y + label.lineLength}
      stroke={label.lineStroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <text
      textAnchor="middle"
      x={bar.x + xOffsetFromBarStart}
      y={bar.y + 2 * label.lineLength}
      fill={label.textFill}
      fontSize="12px"
    >
      {labelText}
    </text>
  </>
);

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default Label;
