import { useCallback } from 'react';

import useAnalytics from 'hooks/use-analytics';

export enum CategoryNavType {
  ItemsPageTopLineButton = 'items page top line button',
  ItemsPageMoreAreaButton = 'items page more area button',
  CategoriesPageLink = 'categories page link',
}

export enum CategoryNavPage {
  ItemsPage = 'menu items page',
  CategoriesPage = 'menu categories page',
}

type TrackCategoryNavClick = {
  categoryNavType: CategoryNavType;
  categoryName: string;
  page: CategoryNavPage;
};

type UseCategoryNavTrackingResult = {
  trackCategoryNavClick: ({
    categoryNavType,
    categoryName,
    page,
  }: TrackCategoryNavClick) => void;
};

export const useCategoryNavTracking = (
  shopId: string,
): UseCategoryNavTrackingResult => {
  const { trackCategoryNavigation } = useAnalytics();

  const trackCategoryNavClick = useCallback(
    ({ categoryNavType, categoryName, page }: TrackCategoryNavClick): void => {
      trackCategoryNavigation({ shopId, categoryNavType, categoryName, page });
    },
    [shopId, trackCategoryNavigation],
  );

  return {
    trackCategoryNavClick,
  };
};
