import { useEffect, useState } from 'react';

import { getWindowSize } from 'utilities/window';

export const useWindowSize = (delay = 100) => {
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;

    const handleResize = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        setWindowSize(getWindowSize());
      }, delay);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener('resize', handleResize);
    };
  }, [delay]);

  return windowSize;
};
