import { ComponentPropsWithoutRef } from 'react';
import cx from 'classnames';

import styles from './styles.module.scss';

type Props = ComponentPropsWithoutRef<'p'>;

const FormText = ({ className, ...props }: Props) => (
  <p className={cx(styles.text, className)} {...props} />
);

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default FormText;
