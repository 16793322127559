import { ReactNode } from 'react';
import ReactModal from 'react-modal';
import cx from 'classnames';

import { Button, ButtonGroup } from 'crust';

import { CloseButton } from '../icon-button';

import styles from './styles.module.scss';

type Props = {
  buttonWrapperClassName?: string;
  contentClassName?: string;
  fadeClassName?: string;
  footerClassName?: string;
  formId?: string;
  header: ReactNode;
  headerWrapperClassName?: string;
  hasDownloadLink?: boolean;
  hideCloseIcon?: boolean;
  downloadLink?: ReactNode;
  isFullScreenMobile?: boolean;
  isNoButtonDisabled?: boolean;
  isYesButtonDisabled?: boolean;
  noButtonText?: string;
  onClickNo?: () => void;
  onClickYes?: () => void;
  yesButtonChameleonTarget?: string;
  yesButtonDataTestId?: string;
  yesButtonText?: string;
} & ReactModal.Props;

const SliceModal = ({
  bodyOpenClassName,
  buttonWrapperClassName,
  children,
  className,
  contentClassName,
  fadeClassName,
  footerClassName,
  formId,
  header,
  headerWrapperClassName,
  hasDownloadLink,
  hideCloseIcon,
  downloadLink,
  isFullScreenMobile = false,
  isNoButtonDisabled,
  isYesButtonDisabled,
  noButtonText = 'Never mind',
  onClickNo,
  onClickYes,
  onRequestClose,
  overlayClassName,
  yesButtonChameleonTarget,
  yesButtonDataTestId,
  yesButtonText = 'Submit',
  ...props
}: Props) => {
  const hasNoButton = onClickNo;
  const hasYesButton = onClickYes || formId;
  const hasButtons = hasNoButton || hasYesButton;

  // The className and overlayClassName props can optionally be an object of
  // class names to be applied for different states.
  // http://reactcommunity.org/react-modal/styles/classes/
  const modalClassNames = cx(
    styles.modal,
    isFullScreenMobile && styles.modalFullScreenMobile,
  );

  if (typeof className === 'object') {
    className.base = cx(modalClassNames, className.base);
  } else {
    className = cx(modalClassNames, className);
  }

  if (typeof overlayClassName === 'object') {
    overlayClassName.base = cx(styles.modalOverlay, overlayClassName.base);
  } else {
    overlayClassName = cx(styles.modalOverlay, overlayClassName);
  }

  return (
    <ReactModal
      className={className}
      bodyOpenClassName={cx(styles.modalBodyOpen, bodyOpenClassName)}
      onRequestClose={onRequestClose}
      overlayClassName={overlayClassName}
      {...props}
    >
      <div className={cx(styles.headerWrapper, headerWrapperClassName)}>
        <div className={styles.modalHeaderContent}>{header}</div>
        {!hideCloseIcon ? (
          <div className={styles.modalHeaderButton}>
            <CloseButton label="Dismiss" onClick={onRequestClose} />
          </div>
        ) : null}
      </div>
      <div className={styles.contentWrapper}>
        <div className={cx(styles.content, contentClassName)}>
          {children}
          <div className={cx(styles.fade, fadeClassName)} />
        </div>
      </div>
      {hasButtons && (
        <div className={cx(styles.footer, footerClassName)}>
          {hasDownloadLink && downloadLink}
          <ButtonGroup
            className={cx(styles.buttonsWrapper, buttonWrapperClassName)}
          >
            {hasNoButton && (
              <Button
                variant="secondary"
                onPress={onClickNo}
                isDisabled={isNoButtonDisabled}
              >
                {noButtonText}
              </Button>
            )}
            {hasYesButton && (
              <Button
                isDisabled={isYesButtonDisabled}
                form={formId}
                type={formId ? 'submit' : 'button'}
                onPress={onClickYes}
                variant="primary"
                data-chameleon-target={yesButtonChameleonTarget}
                data-testid={yesButtonDataTestId}
              >
                {yesButtonText}
              </Button>
            )}
          </ButtonGroup>
        </div>
      )}
    </ReactModal>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default SliceModal;
