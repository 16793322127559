import { Control, useController } from 'react-hook-form';

import { SelectMenuOption } from 'components/website-tools/form/select-menu-option';
import useAnalytics from 'hooks/use-analytics';
import { ShopTraits } from 'types/shops';
import {
  SmartPopupFormValues,
  WebsiteIntegrationsAnalyticsPageName,
} from 'types/website-tools';
import {
  defaultPromoMessageOption,
  getAnalyticsPromoMessageFromForm,
  promoMessageOptions,
} from 'utilities/website-tools';

import SelectMenu from '../../../form/select-menu';

type Props = {
  buttonClassName?: string;
  control: Control<SmartPopupFormValues>;
  setIsFormChanged: (isFormChanged: boolean) => void;
  shopId: number;
  shopTraits: ShopTraits;
  page: WebsiteIntegrationsAnalyticsPageName;
};

const PromoDropdown = ({
  buttonClassName,
  control,
  setIsFormChanged,
  shopId,
  shopTraits,
  page,
}: Props) => {
  const {
    field: { onChange, value },
    fieldState: { isDirty },
  } = useController({
    control,
    name: 'promoMessage',
  });

  const { trackClickedWebsiteIntegrationsEditorDropdown } = useAnalytics();

  const selectedItem = promoMessageOptions.find(
    (option) => option.value === value,
  );

  const handleOnBlur = () => {
    trackClickedWebsiteIntegrationsEditorDropdown({
      field: 'promo message',
      shopId: String(shopId),
      isActive: String(shopTraits.isSmartButtonActive),
      optionSelected: isDirty ? getAnalyticsPromoMessageFromForm(value) : null,
      page,
    });
  };

  return (
    <SelectMenu
      buttonClassName={buttonClassName}
      buttonId="promo-dropdown"
      label={selectedItem?.label ?? defaultPromoMessageOption.label}
      onChange={(value) => {
        onChange(value);
        setIsFormChanged(true);
      }}
      onBlur={handleOnBlur}
    >
      {promoMessageOptions.map((option, index) => {
        const isActive = selectedItem?.value === option.value;

        return (
          <SelectMenuOption
            isActive={isActive}
            key={index}
            label={option.label}
            value={option.value}
            isRecommended={option.value === 'true'}
          />
        );
      })}
    </SelectMenu>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default PromoDropdown;
