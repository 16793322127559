import { ReactElement } from 'react';
import {
  Menu as AriaMenu,
  MenuItem as AriaMenuItem,
  MenuItemProps as AriaMenuItemProps,
  MenuProps as AriaMenuProps,
  MenuTrigger as AriaMenuTrigger,
  MenuTriggerProps as AriaMenuTriggerProps,
} from 'react-aria-components';
import classNames from 'classnames';

import { Popover, PopoverProps } from '../popover/popover';
import { getCrustClassName } from '../utilities/get-crust-class-name';
import { mergeAriaClassName } from '../utilities/merge-aria-class-name';

import './menu.css';

export type MenuProps<T> = AriaMenuProps<T> &
  Pick<PopoverProps, 'crossOffset' | 'offset' | 'triggerRef'> &
  Omit<AriaMenuTriggerProps, 'children'> & {
    triggerElement: ReactElement;
  };

const getMenuClassName = getCrustClassName.bind(null, 'menu');

export const Menu = <T extends object>({
  children,
  className,
  triggerElement,

  // MenuTrigger
  defaultOpen,
  isOpen,
  onOpenChange,
  trigger,

  // Popover
  crossOffset,
  offset,
  triggerRef,

  // Menu
  ...props
}: MenuProps<T>) => (
  <AriaMenuTrigger
    defaultOpen={defaultOpen}
    isOpen={isOpen}
    onOpenChange={onOpenChange}
    trigger={trigger}
  >
    {triggerElement}
    <Popover
      className={getMenuClassName('popover')}
      crossOffset={crossOffset}
      kind="menu"
      offset={offset}
      triggerRef={triggerRef}
    >
      <AriaMenu
        className={classNames(getMenuClassName(), className)}
        {...props}
      >
        {children}
      </AriaMenu>
    </Popover>
  </AriaMenuTrigger>
);

export type MenuItemProps = AriaMenuItemProps;

export const MenuItem = ({ children, className, ...props }: MenuItemProps) => (
  <AriaMenuItem
    className={mergeAriaClassName(getMenuClassName('item'), className)}
    {...props}
  >
    {children}
  </AriaMenuItem>
);
