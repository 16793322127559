import { ReactNode } from 'react';

export enum BaseCustomerLoyaltyStatus {
  New = 'new',
  Loyal = 'loyal',
  Repeat = 'repeat',
}

export enum CustomerLoyaltyStatus {
  New = 'new',
  Loyal = 'loyal',
  Repeat = 'repeat',
  All = 'all',
}

export enum CustomerList {
  All = 'all',
  New = 'new',
  Loyal = 'loyal',
  Repeat = 'repeat',
  Delivery = 'delivery',
  Pickup = 'pickup',
  MostFrequent = 'most_frequent',
  MostValuable = 'most_valuable',
}

export enum CustomerType {
  Online = 'online',
  Register = 'offline',
  All = 'all',
  GetRegister = 'getRegister',
}

export enum Top10CustomersType {
  MostFrequent = 'most_frequent',
  MostValuable = 'most_valuable',
}

export enum RegistrationType {
  Registered = 'registered',
  Guest = 'guest',
  All = 'all',
}

export enum DateRange {
  Seven = 7,
  Thirty = 30,
  Sixty = 60,
  Ninety = 90,
}

export type TopProduct = {
  customersCount: number;
  image: string | null;
  productId: number;
  productName: string;
};

export type CustomersAnalytics = {
  averageOrderValue: number;
  deliveryUsersCount: number;
  orderCount: number;
  orderTotal: number;
  pickupUsersCount: number;
  usersCount: number;
  topProduct: TopProduct;
};

export type CustomerCounts = {
  all: number;
  guest: number;
  loyal: number;
  new: number;
  repeat: number;
};

// Sortable fields defined in OPAS here:
// https://github.com/slicelife/owners-portal-analytics-service/blob/main/app/v2/customers/enums.py
export enum CustomerSortBy {
  AverageOrderValue = 'average_order_value',
  OrderTotal = 'order_total',
  OrderCount = 'order_count',
  OrderDate = 'last_ordered_at',
}

export enum CustomerSortByDeprecated {
  OrderTotal = 'order_total',
  OrderCount = 'order_count',
  OrderDate = 'last_ordered_at',
}

export enum CustomerSortDirection {
  Ascending = 'asc',
  Descending = 'desc',
}

export type CustomerSort = {
  by: CustomerSortBy;
  direction: CustomerSortDirection;
};

export type FavoriteProduct = {
  productId: number;
  productName: string;
};

export type BaseCustomer = {
  firstName: string;
  lastName: string;
  orderCount: number;
  orderTotal: number;
  // https://app.shortcut.com/slicelife/story/328107/customer-types-tidy-up
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  loyaltyStatus:
    | CustomerLoyaltyStatus.New
    | CustomerLoyaltyStatus.Repeat
    | CustomerLoyaltyStatus.Loyal;
  lastOrderedAt: string;
  uuid: string;
  averageOrderValue: number;
  favouriteProduct: FavoriteProduct;
};

export type CustomerProfileFavouriteProduct = {
  productId: number;
  productName: string;
};

export type CustomerProfileOrderItem = {
  productId: number;
  productName: string;
  units: number;
};

export enum CustomerProfileOrderOrderType {
  Online = 'online',
  Offline = 'offline',
}

export enum CustomerProfileOrderShippingType {
  Delivery = 'delivery',
  Pickup = 'pickup',
  ForHere = 'for_here',
  ToGo = 'to_go',
}

export const CustomerProfileOrderOrderTypeLabels = {
  [CustomerProfileOrderOrderType.Online]: 'Online',
  [CustomerProfileOrderOrderType.Offline]: 'Register',
} as const;

export const CustomerProfileOrderShippingTypeLabels = {
  [CustomerProfileOrderShippingType.Delivery]: 'Delivery',
  [CustomerProfileOrderShippingType.Pickup]: 'Pickup',
  [CustomerProfileOrderShippingType.ForHere]: 'Dine In',
  [CustomerProfileOrderShippingType.ToGo]: 'To Go',
} as const;

export type CustomerProfileOrder = {
  datePurchased: string;
  items: CustomerProfileOrderItem[];
  orderTotal: number;
  orderType: CustomerProfileOrderOrderType;
  shippingType: CustomerProfileOrderShippingType;
};

export type PagedCustomerProfileOrder = {
  data: CustomerProfileOrder[];
  nextPage: number | undefined;
};

export type CustomerOrderProfile = {
  averageOrderValue: number;
  favouriteProduct: CustomerProfileFavouriteProduct;
  firstOrderedAt: string;
  lastOrderedAt: string;
  orderCount: number;
  orders: CustomerProfileOrder[];
  orderTotal: number;
  uuid: string;
};

export type CustomerPaginationRequest = {
  page: number;
  perPage: number;
};

export type CustomerPaginationInfo = {
  results: number;
  pages: number;
  currentPage: number;
  perPage: number;
};

export type CustomersResponse = {
  data: BaseCustomer[];
  meta: {
    pagination: CustomerPaginationInfo;
    sort: CustomerSort;
  };
};

export type Interval = {
  start: string;
  finish: string;
};

export type CustomerFilter = {
  key: string;
  term: {
    condition: string;
    value: string | string[];
  };
};

export type CustomerListOption = {
  label: string;
  value: CustomerList;
};

export type CustomerTypeOption = {
  label: ReactNode;
  value: CustomerType;
};

export type DateRangeOption = {
  label: string;
  value: DateRange;
};

export enum Top10CustomersTabType {
  Value = 'value',
  Frequency = 'frequency',
}

export enum CustomerAnalyticsStorageKeys {
  SelectedTop10Tab = 'customer_dashboard_top_10_selected_tab',
}
export enum CustomerSegmentsTableColumnId {
  LoyaltyStatusIcon = 'loyaltyStatusIcon',
  CustomerName = 'name',
  FavoriteItemName = 'favoriteItem',
  CustomerType = 'customerType',
  LastOrderDate = 'lastOrder',
  OrderCount = 'orderCount',
  AverageOrderValue = 'averageOrder',
  TotalSpend = 'totalSpend',
}

export type CustomerSegmentsTableSortableColumnId =
  | CustomerSegmentsTableColumnId.LastOrderDate
  | CustomerSegmentsTableColumnId.OrderCount
  | CustomerSegmentsTableColumnId.AverageOrderValue
  | CustomerSegmentsTableColumnId.TotalSpend;

export const CustomerSegmentsTableDefaultSort = {
  [CustomerList.All]: CustomerSegmentsTableColumnId.TotalSpend,
  [CustomerList.New]: CustomerSegmentsTableColumnId.LastOrderDate,
  [CustomerList.Loyal]: CustomerSegmentsTableColumnId.TotalSpend,
  [CustomerList.Repeat]: CustomerSegmentsTableColumnId.TotalSpend,
  [CustomerList.Delivery]: CustomerSegmentsTableColumnId.TotalSpend,
  [CustomerList.Pickup]: CustomerSegmentsTableColumnId.TotalSpend,
  [CustomerList.MostFrequent]: CustomerSegmentsTableColumnId.OrderCount,
  [CustomerList.MostValuable]: CustomerSegmentsTableColumnId.TotalSpend,
} as const;

export type CustomerSegmentsLocationState = {
  customerList?: CustomerList;
  orderType?: CustomerType;
  resetDateRange?: boolean;
};
