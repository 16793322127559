import { QueryKey, useQuery, UseQueryResult } from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { DailyHours } from 'types/daily-hours';

export const getDailyHoursQueryKey = (shopId: string): QueryKey => [
  shopId,
  'dailyHours',
];

export const useDailyHoursQuery = (
  shopId: string,
): UseQueryResult<DailyHours> => {
  const api = useApi();

  return useQuery(getDailyHoursQueryKey(shopId), () =>
    api.getDailyHours(shopId),
  );
};
