import moment from 'moment-timezone';

import LineChart from 'components/shared/charts/line';
import ContentTile from 'components/shared/content-tile';
import useFetchOrderMetrics from 'components/shared/metric-tiles/use-fetch-order-metrics';
import OrderReportsIcon from 'images/placeholders/order-reports.svg?react';
import { Shop } from 'types/shops';

import { buildChartData } from './helpers';

import styles from './styles.module.scss';

type Props = {
  activeDates: {
    current: {
      startDate: string;
      endDate: string;
    };
    previous: {
      startDate: string;
      endDate: string;
    };
  };
  shop: Shop;
};

export const OrdersTile = ({ activeDates, shop }: Props) => {
  const { currentOrderMetrics, isLoading } = useFetchOrderMetrics(
    activeDates,
    shop.shopId,
    shop.timezoneIdentifier,
  );

  const chartData = buildChartData(currentOrderMetrics);

  return (
    <ContentTile
      title="Your Orders"
      className={styles.sliceOrders}
      isLoading={isLoading}
    >
      {chartData?.length > 0 ? (
        <LineChart
          dataSetIdentifier="orders"
          yAxisTooltipLabel="Orders"
          startDate={moment(activeDates.current.startDate)}
          endDate={moment(activeDates.current.endDate)}
          xyValues={chartData}
          colors={['var(--op-color-graph1)']}
        />
      ) : (
        <div className={styles.empty}>
          <OrderReportsIcon aria-hidden="true" className={styles.emptyIcon} />
          <div className={styles.emptyMessage}>No orders available</div>
        </div>
      )}
    </ContentTile>
  );
};
