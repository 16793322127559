import { FormEventHandler, PropsWithChildren } from 'react';
import {
  Control,
  FieldErrors,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form';

import Input from 'components/shared/input';
import { RegisterDiscountFormValues } from 'types/discounts';
import { Shop } from 'types/shops';

import DateFields from './date-fields';
import NameFields from './name-fields';
import TypeFields from './type-fields';

import styles from './styles.module.scss';

type Props = {
  control: Control<RegisterDiscountFormValues>;
  errors: FieldErrors<RegisterDiscountFormValues>;
  getValues: UseFormGetValues<RegisterDiscountFormValues>;
  isRosEnabled: boolean;
  onSubmit: FormEventHandler<HTMLFormElement>;
  register: UseFormRegister<RegisterDiscountFormValues>;
  setValue: UseFormSetValue<RegisterDiscountFormValues>;
  shopTimezone: Shop['timezoneIdentifier'];
};

const RegisterDiscountForm = ({
  children,
  control,
  errors,
  getValues,
  isRosEnabled,
  onSubmit,
  register,
  setValue,
  shopTimezone,
}: PropsWithChildren<Props>) => {
  return (
    <form onSubmit={onSubmit}>
      <NameFields
        className={styles.tile}
        control={control}
        nameError={errors.name}
      />
      <TypeFields
        amountError={errors.amount}
        className={styles.tile}
        control={control}
        getValues={getValues}
        isRosEnabled={isRosEnabled}
      />
      <DateFields
        control={control}
        getValues={getValues}
        setValue={setValue}
        shopTimezone={shopTimezone}
      />
      {children}
      <Input type="hidden" {...register('id')} />
    </form>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default RegisterDiscountForm;
