import { CSSProperties } from 'react';
import {
  EventHandler,
  MenuItem,
  MenuRadioGroup,
  RadioChangeEvent,
} from '@szhsin/react-menu';

import Text from 'components/shared/text';

import styles from './styles.module.scss';

type Props = {
  colorHex: string;
  handleRadioChange: EventHandler<RadioChangeEvent>;
};

const categoryColors = [
  { label: 'Berry', value: '#cb3365' },
  { label: 'Plum', value: '#660033' },
  { label: 'Strawberry', value: '#d13900' },
  { label: 'Cocoa', value: '#472d13' },
  { label: 'Forest', value: '#21653f' },
  { label: 'Ocean', value: '#205493' },
  { label: 'Blackberry', value: '#4b0082' },
  { label: 'Walnut', value: '#8b4513' },
  { label: 'Navy', value: '#003366' },
];

export const CategoryColors = ({ colorHex, handleRadioChange }: Props) => {
  return (
    <MenuRadioGroup value={colorHex} onRadioChange={handleRadioChange}>
      {categoryColors.map(({ label, value }) => (
        <MenuItem
          key={value}
          style={{ '--option-color': value } as CSSProperties}
          type="radio"
          value={value}
        >
          <Text className={styles.optionText}>{label}</Text>
        </MenuItem>
      ))}
    </MenuRadioGroup>
  );
};
