import { useMutation, useQueryClient } from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import {
  FontColorCodes,
  SiteEditorFormValues,
  SiteEditorSettings,
} from 'types/site-editor';
import { camelCaseKeys, snakeCaseKeys } from 'utilities/objects';

import { getDirectWebSettingsQueryKey } from '../site-editor/use-direct-web-settings';

type Values = {
  settings: SiteEditorFormValues;
  shopId: string;
};

export const useSaveAndPublishMutation = () => {
  const { authenticatedFetch } = useApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ settings, shopId }: Values) => {
      const fullSettings: SiteEditorSettings = {
        color: parseInt(settings.colorCode),
        // For now based on direct web available background colors all use white font color text
        fontColor: FontColorCodes.White,
        fontFamily: parseInt(settings.fontCode),
      };

      const response = await authenticatedFetch.put(
        `api/management/v1/shops/${shopId}/direct_web_settings`,
        snakeCaseKeys(fullSettings),
      );

      return camelCaseKeys(response) as SiteEditorSettings;
    },
    onSuccess: (updatedSettings, values) => {
      queryClient.setQueryData(
        getDirectWebSettingsQueryKey(values.shopId),
        updatedSettings,
      );
    },
  });
};
