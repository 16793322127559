import HeroTile from './hero-tile';
import ServicesTile from './services-tile';

type Props = {
  shopId: string;
};

const SocialMediaTab = ({ shopId }: Props) => {
  return (
    <>
      <HeroTile shopId={shopId} />
      <ServicesTile shopId={shopId} />
    </>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default SocialMediaTab;
