import cx from 'classnames';

import BannerIcon from 'images/smart-buttons/banner-icon.svg?react';
import ButtonIcon from 'images/smart-buttons/button-icon.svg?react';
import StickerIcon from 'images/smart-buttons/sticker-icon.svg?react';
import { WidgetType } from 'types/website-tools';

import styles from './styles.module.scss';

type Props = {
  isActive: boolean;
  widgetType: WidgetType;
};

const MenuItemIcon = ({ isActive, widgetType }: Props) => {
  const Icon =
    widgetType === WidgetType.Banner
      ? BannerIcon
      : widgetType === WidgetType.Button
        ? ButtonIcon
        : StickerIcon;

  return (
    <Icon
      aria-hidden="true"
      className={cx(styles.menuItemIcon, isActive && styles.activeMenuItem)}
    />
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default MenuItemIcon;
