import { TooltipIconButton } from 'components/shared/tooltips';

import styles from './styles.module.scss';

const CustomerDetailsModalHeader = () => {
  return (
    <div className={styles.customerDetailsModalHeader}>
      Customer Details
      <TooltipIconButton
        label="Customer Details Disclaimer"
        tooltipClassName={styles.customerDetailsModalTooltip}
      >
        <span className={styles.customerDetailsModalTooltipHeader}>
          Order data since 1/1/2020
        </span>
        <br />
        <span className={styles.customerDetailsModalTooltipText}>
          Due to privacy considerations, we are not allowed to display certain
          information like phone numbers or emails.
        </span>
      </TooltipIconButton>
    </div>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default CustomerDetailsModalHeader;
