export enum CollapsibleTileAnimationState {
  Collapsing = 'collapsing',
  Collapsed = 'collapsed',
  Expanding = 'expanding',
  Expanded = 'expanded',
}

export enum CollapsibleTileVariant {
  Regular = 'regular',
  Embedded = 'embedded',
}
