import cx from 'classnames';

import Tile from 'components/shared/tile/tile';

import MetricTileLink from './link';

import styles from './styles.module.scss';

type Link = {
  shopId: string;
  title: string;
  to: string;
  trackingName: string;
};

type Props = {
  children: React.ReactNode;
  className?: string;
  dataChameleonTarget?: string;
  dataTestId?: string;
  link?: Link;
};

export const MetricTile = ({
  children,
  className,
  dataChameleonTarget,
  dataTestId,
  link,
}: Props) =>
  link ? (
    <MetricTileLink
      className={className}
      dataChameleonTarget={dataChameleonTarget}
      dataTestId={dataTestId}
      shopId={link.shopId}
      title={link.title}
      to={link.to}
      trackingName={link.trackingName}
    >
      <Tile className={styles.tile}>{children}</Tile>
    </MetricTileLink>
  ) : (
    <Tile
      className={cx(styles.tile, className)}
      data-chameleon-target={dataChameleonTarget}
      data-testid={dataTestId}
    >
      {children}
    </Tile>
  );

export { default as MetricTileContent } from './content';
export { default as MetricTileDelta } from './delta';
export { default as MetricTileHeader } from './header';
export { default as MetricTileLink } from './link';
export { default as MetricTileTitle } from './title';
export { default as MetricTileValue } from './value';

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default MetricTile;
