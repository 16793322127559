import styles from './styles.module.scss';

type Props = {
  isNew: boolean;
};

const RegisterDiscountFormHeader = ({ isNew }: Props) => (
  <div className={styles.header}>
    <h4 className={styles.heading}>
      {isNew
        ? 'Add a new discount that will appear on your Slice register.'
        : 'Edit a discount that will appear on your Slice register.'}
    </h4>
    <p>
      You control whether this discount could be combined with other offers.
    </p>
  </div>
);

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default RegisterDiscountFormHeader;
