import { ResponsiveBar } from '@nivo/bar';
import cx from 'classnames';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

const BarChart = ({
  axisBottom,
  axisLeft,
  axisRight,
  axisTop,
  className,
  colorsFunc,
  data,
  groupMode,
  indexBy,
  keys,
  margin,
  padding,
  tooltipFunc,
  ...rest
}) => (
  <div className={cx(styles.wrapper, className)}>
    <ResponsiveBar
      animate={true}
      axisBottom={axisBottom}
      axisLeft={axisLeft}
      axisRight={axisRight}
      axisTop={axisTop}
      colors={colorsFunc}
      data={data}
      enableLabel={false}
      groupMode={groupMode}
      indexBy={indexBy}
      keys={keys}
      margin={margin}
      motionDamping={15}
      motionStiffness={90}
      padding={padding}
      tooltip={tooltipFunc}
      {...rest}
    />
  </div>
);

BarChart.propTypes = {
  axisBottom: PropTypes.object,
  axisLeft: PropTypes.object,
  axisRight: PropTypes.object,
  axisTop: PropTypes.object,
  className: PropTypes.string,
  colorsFunc: PropTypes.func,
  data: PropTypes.array.isRequired,
  groupMode: PropTypes.string,
  indexBy: PropTypes.string,
  keys: PropTypes.array.isRequired,
  margin: PropTypes.object,
  padding: PropTypes.number,
  tooltipFunc: PropTypes.func,
};

BarChart.defaultProps = {
  axisBottom: null,
  axisLeft: null,
  axisRight: null,
  axisTop: null,
  className: '',
  colorsFunc: () => {},
  groupMode: '',
  indexBy: 'id',
  margin: { top: 5, right: 0, bottom: 30, left: 50 },
  padding: 0.25,
  tooltipFunc: () => {},
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default BarChart;
