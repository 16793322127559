import styles from './styles.module.scss';

export const PromoCodeFormHeader = () => (
  <div className={styles.header}>
    <h4 className={styles.heading}>
      Don’t worry, we won’t combine this promo code with other discounts.
    </h4>
    <p>
      Your customers won’t be able to enter this code if another code is already
      applied at the checkout.
    </p>
  </div>
);
