import PinDigit from './pin-digit';

import styles from './styles.module.scss';

type Props = {
  pin?: string;
};

const PinDisplay = ({ pin = '----' }: Props) => {
  return (
    <div className={styles.pinDisplay}>
      {[...pin].map((digit, index) => (
        <PinDigit digit={digit} key={index} />
      ))}
    </div>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default PinDisplay;
