import * as React from "react";
import { forwardRef } from "react";
const SvgOrderDetailsIcon = ({
  title,
  titleId,
  ...props
}, ref) => {
  return /* @__PURE__ */ React.createElement("svg", { width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", "aria-hidden": "true", ref, "aria-labelledby": titleId, ...props }, title === void 0 ? /* @__PURE__ */ React.createElement("title", { id: titleId }, "Order Details Icon") : title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M6 2C4.89543 2 4 2.89543 4 4V20.5234C4 21.3389 4.66109 22 5.47659 22C6.09216 22 6.63345 21.6083 6.96934 21.0924C7.31366 20.5636 7.8363 20 8.5 20C9.20959 20 9.919 20.6442 10.3999 21.2014C10.8063 21.6723 11.3779 22 12 22C12.6221 22 13.1937 21.6723 13.6001 21.2014C14.081 20.6442 14.7904 20 15.5 20C16.1637 20 16.6863 20.5636 17.0307 21.0924C17.3665 21.6083 17.9078 22 18.5234 22C19.3389 22 20 21.3389 20 20.5234V4C20 2.89543 19.1046 2 18 2H6ZM13 9H7V7H13V9ZM17 9H15V7H17V9ZM13 14H7V12H13V14ZM17 14H15V12H17V14Z", fill: "currentColor" }));
};
const ForwardRef = forwardRef(SvgOrderDetailsIcon);
export default ForwardRef;
