import HeaderText from 'components/shared/header';
import AvoidableOrderIssueRate from 'components/shared/metric-tiles/avoidable-order-issue-rate';
import NetworkStatus from 'components/shared/metric-tiles/network-status';
import ShopScore from 'components/shared/metric-tiles/shop-score';

import styles from './styles.module.scss';

type Props = {
  shopId: string;
};

const Header = ({ shopId }: Props) => (
  <div className={styles.wrapper}>
    <HeaderText
      title="Become an All-star of your business"
      content={[
        `The Pizzeria Score is based on your restaurant’s online presence and the experience you give your customers.
        Our data shows that shops with a higher score get more orders.`,
      ]}
    />
    <div className={styles.tiles}>
      <ShopScore shopId={shopId} className={styles.tile} />
      <NetworkStatus shopId={shopId} className={styles.tile} />
      <AvoidableOrderIssueRate shopId={shopId} className={styles.tile} />
    </div>
  </div>
);

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default Header;
