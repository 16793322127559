import cx from 'classnames';

import {
  DesktopButon,
  MobileButton,
  TabletButton,
} from 'components/shared/icon-button';
import useAnalytics from 'hooks/use-analytics';
import { Breakpoint } from 'types/site-editor';

import styles from './styles.module.scss';

type Props = {
  active: boolean;
  breakpoint: Breakpoint;
  disabled?: boolean;
  previewHandler: (breakpoint: Breakpoint) => void;
  shopId: string;
  domainName: string | null;
};

const BreakpointButton = ({
  active,
  breakpoint,
  disabled,
  previewHandler,
  shopId,
  domainName,
}: Props) => {
  let Button;
  if (breakpoint === 'desktop') {
    Button = DesktopButon;
  } else if (breakpoint === 'tablet') {
    Button = TabletButton;
  } else {
    Button = MobileButton;
  }

  const { trackClickedViewportToggle } = useAnalytics();

  return (
    <Button
      aria-label={breakpoint}
      className={cx(
        styles.deviceButton,
        active && !disabled && styles.activeIcon,
      )}
      disabled={disabled}
      label={breakpoint}
      onClick={() => {
        previewHandler(breakpoint);
        trackClickedViewportToggle({
          shopId,
          view: breakpoint,
          isActive: domainName ? true : false,
        });
      }}
    />
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default BreakpointButton;
