import { useEffect, useState } from 'react';
import { Control, FieldError } from 'react-hook-form';

import CollapsibleTile from 'components/shared/collapsible-tile';
import {
  RHFTextAreaField,
  RHFTextField,
} from 'components/shared/rhf-text-field';
import { MenuCategoryFormValues } from 'types/menu/category';

import styles from './styles.module.scss';

type Props = {
  control: Control<MenuCategoryFormValues>;
  nameError?: FieldError;
  submitCount: number;
};

const CategoryDetailsTile = ({ control, nameError, submitCount }: Props) => {
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    if (nameError) {
      setIsOpen(true);
    }
  }, [nameError, submitCount]);

  return (
    <CollapsibleTile
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title="Name"
      bodyClassName={styles.tile}
      bodyChameleonTarget="Category Name Area"
    >
      <RHFTextField
        control={control}
        isRequired
        label="Category Name"
        name="name"
        rules={{
          required: 'Please enter a name for this category.',
        }}
      />
      <RHFTextAreaField
        control={control}
        description="e.g. Our signature thin crust pizza"
        label="Description (Optional)"
        name="description"
      />
    </CollapsibleTile>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default CategoryDetailsTile;
