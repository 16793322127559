import { cloneElement, ReactElement, ReactNode } from 'react';

import { Icon } from 'crust';

import Tile from '../tile/tile';

import styles from './styles.module.scss';

type Props = {
  children: ReactNode;
};

type HeadingProps = {
  text: string;
  icon: ReactElement;
};

type ItemProps = {
  text: string;
};

type GroupedItemsProps = {
  valueOne: string;
  valueTwo: string;
};

export const ValueTile = (props: Props) => {
  return <Tile className={styles.container}>{props.children}</Tile>;
};

const Heading = ({ text, icon }: HeadingProps) => {
  return (
    <div className={styles.headingContainer}>
      {cloneElement(icon, { 'aria-hidden': 'true' })}
      <p className={styles.headingText}>{text}</p>
    </div>
  );
};

const Item = ({ text }: ItemProps) => {
  return (
    <div className={styles.item}>
      <Icon icon="checkmark" className={styles.icon} />
      <p className={styles.itemText}>{text}</p>
    </div>
  );
};

const GroupedItems = ({ valueOne, valueTwo }: GroupedItemsProps) => {
  return (
    <div className={styles.items}>
      <Item text={valueOne} />
      <Item text={valueTwo} />
    </div>
  );
};

const Content = (props: Props) => {
  return <div className={styles.contentContainer}>{props.children}</div>;
};

ValueTile.Heading = Heading;
ValueTile.Item = Item;
ValueTile.Content = Content;
ValueTile.GroupedItems = GroupedItems;
