import useAnalytics from 'hooks/use-analytics';
import { usePsmInfoQuery } from 'hooks/use-psm-info';
import CaretIcon from 'images/caret.svg?react';

import PsmInfoSubDrawer from './psm-info-subdrawer';

import styles from './styles.module.scss';

type ActiveSubMenu = {
  label: string;
  customSubMenu: React.ReactNode;
};

type Props = {
  setActiveSubMenu: (activeSubMenu: ActiveSubMenu) => void;
  shopId: string;
  verificationCode: string;
};

const PsmInfoDrawerItem = ({
  setActiveSubMenu,
  shopId,
  verificationCode,
}: Props) => {
  const { data, isLoading } = usePsmInfoQuery(shopId);
  const hasPartnerName = !!data?.firstName;
  const partnerName = hasPartnerName
    ? `${data?.firstName} ${data?.lastName}`
    : 'How can we help?';

  const { trackClickedPsmContactInfo } = useAnalytics();

  const handleClick = () => {
    trackClickedPsmContactInfo({
      shopId: shopId,
      page: 'navigation',
      psmName: partnerName,
    });

    setActiveSubMenu({
      label: '',
      customSubMenu: (
        <PsmInfoSubDrawer
          shopId={shopId}
          hasPartnerName={hasPartnerName}
          partnerName={partnerName}
        />
      ),
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.label}>Partner Success Manager</div>
      <button className={styles.drawerItem} onClick={handleClick}>
        {!isLoading && hasPartnerName ? (
          <span
            className={styles.partnerName}
          >{`${data?.firstName} ${data?.lastName}`}</span>
        ) : (
          <span>How can we help?</span>
        )}
        <CaretIcon className={styles.icon} />
      </button>
      {verificationCode && (
        <div className={styles.pin}>PIN: {verificationCode}</div>
      )}
    </div>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default PsmInfoDrawerItem;
