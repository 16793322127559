import { ReactNode } from 'react';

import { Icon } from 'crust';

import Link from 'components/shared/link';
import useAnalytics from 'hooks/use-analytics';
import * as paths from 'routes/paths';
import { CustomerList } from 'types/customers';
import { CustomerListLabels } from 'utilities/customers';

import styles from './styles.module.scss';

type Props = {
  count: number | undefined;
  customerList: CustomerList;
  description: ReactNode;
  isLoading: boolean;
  shopId: string;
};

const CustomerCountInfo = ({
  count,
  customerList,
  description,
  isLoading,
  shopId,
}: Props) => {
  const { trackClickedViewList } = useAnalytics();

  const handleClickLink = (): void => {
    trackClickedViewList(
      shopId,
      CustomerListLabels[customerList].toLowerCase(),
      count ?? 0,
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.descriptiveInfoContainer}>{description}</div>
      <Link
        className={styles.countInfoContainer}
        onClick={handleClickLink}
        to={paths.getCustomerSegmentsPath(shopId)}
        state={{ customerList }}
      >
        <div className={styles.customerCount}>
          {isLoading ? '--' : (count?.toString() ?? '0')}
        </div>
        <Icon icon="chevronRight" />
      </Link>
    </div>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default CustomerCountInfo;
