import { useParams } from 'react-router-dom';

function useTypeSafeParams<T extends string>(
  parameterNames: T[],
): Record<T, string> {
  const params = useParams();

  const typedParams: Record<string, string> = {};

  parameterNames.forEach((paramName) => {
    const currentParam = params[paramName];

    if (currentParam == null) {
      throw new Error(
        `${paramName} not found in useParams. Check the parent route to make sure nothing changed`,
      );
    }

    typedParams[paramName] = currentParam;
  });

  return typedParams;
}

export { useTypeSafeParams };
