import {
  GetRegisterRowDataProps,
  NetworkGetRecommendedPermissionGroupsResponse,
  NetworkGetRegisterUsersResponse,
  NetworkRegisterPermissionGroup,
  NetworkRegisterUser,
  PermissionsInfo,
  RegisterPermission,
  RegisterPermissionGroup,
  RegisterUser,
  RegisterUserRowData,
} from 'types/register-users';

import { capitalize } from './strings';

export const networkRecommendedPermissionGroupsResponseToDomainInfo = (
  response: NetworkGetRecommendedPermissionGroupsResponse,
): PermissionsInfo => {
  const { permissions, recommended_permission_groups } = response;

  return {
    permissions,
    recommendedPermissionGroups: recommended_permission_groups,
  };
};

export const networkToDomainRegisterPermissionGroup = (
  networkRegisterPermissionGroup: NetworkRegisterPermissionGroup,
): RegisterPermissionGroup => {
  const { id, name, permissions, shop_id } = networkRegisterPermissionGroup;

  return {
    id,
    name,
    permissions,
    shopUuid: shop_id,
  };
};

export const getPermissionCategoryDisplayName = (
  value: RegisterPermission['category'],
): string => capitalize(value.toLowerCase().replace('_', ' '));

export const networkRegisterUserToRegisterUser = (
  networkRegisterUser: NetworkRegisterUser,
): RegisterUser => {
  const { id, email, name, phone_number, pin, permission_groups } =
    networkRegisterUser;

  return {
    id,
    email,
    name,
    permissionGroups: permission_groups,
    phoneNumber: phone_number,
    pin,
  };
};

export const networkGetRegisterUsersResponseToRegisterUsers = (
  response: NetworkGetRegisterUsersResponse,
): RegisterUser[] => {
  const networkRegisterUsers = response.register_users;

  const registerUsers: RegisterUser[] = [];

  networkRegisterUsers.forEach((networkRegisterUser) => {
    registerUsers.push(networkRegisterUserToRegisterUser(networkRegisterUser));
  });

  return registerUsers;
};

export const getRegisterUserRowData = ({
  groupsData,
  usersData,
}: GetRegisterRowDataProps): RegisterUserRowData[] => {
  const registerUserRowData = [] as RegisterUserRowData[];

  usersData.forEach((user) => {
    registerUserRowData.push({
      id: user.id,
      email: user.email ?? '',
      name: user.name,
      phone: user.phoneNumber ?? '',
      pin: user.pin ?? '',
      permissionGroupNames: user.permissionGroups.map((permissionGroupId) => {
        return (
          groupsData.find((group) => group.id === permissionGroupId)?.name ?? ''
        );
      }),
    });
  });

  return registerUserRowData;
};
