import * as React from "react";
import { forwardRef } from "react";
import { useId } from "react-aria";
const SvgFacebookGrey = ({
  title,
  titleId,
  ...props
}, ref) => {
  const id0 = useId();
  return /* @__PURE__ */ React.createElement("svg", { width: 16, height: 16, viewBox: "0 0 16 16", fill: "none", xmlns: "http://www.w3.org/2000/svg", ref, "aria-labelledby": titleId, ...props }, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("g", { clipPath: `url(#${id0})` }, /* @__PURE__ */ React.createElement("path", { d: "M2.9215 0C1.30312 0 0 1.30312 0 2.9215V13.0785C0 14.6969 1.30312 16 2.9215 16H8.42651V9.74501H6.77251V7.49301H8.42651V5.56901C8.42651 4.05741 9.40378 2.6695 11.655 2.6695C12.5665 2.6695 13.2405 2.75701 13.2405 2.75701L13.1875 4.86002C13.1875 4.86002 12.5001 4.85352 11.75 4.85352C10.9382 4.85352 10.808 5.22757 10.808 5.84853V7.49302H13.252L13.1455 9.74502H10.808V16H13.0785C14.6969 16 16 14.6969 16 13.0785V2.92152C16 1.30314 14.6969 1.6e-05 13.0785 1.6e-05H2.92149L2.9215 0Z", fill: "#525150" })), /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("clipPath", { id: id0 }, /* @__PURE__ */ React.createElement("rect", { width: 16, height: 16, fill: "white" }))));
};
const ForwardRef = forwardRef(SvgFacebookGrey);
export default ForwardRef;
