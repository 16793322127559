import { ReactElement, ReactNode } from 'react';

import { CustomerList } from 'types/customers';

import CustomerCountInfo from '../../customer-count-info';
import CustomerTypeTooltip from '../tooltip';

import styles from './styles.module.scss';

type Props = {
  count: number | undefined;
  customerList: CustomerList;
  icon: ReactElement;
  isLoading: boolean;
  name: string;
  shopId: string;
  tooltipContent: ReactNode;
};

const CustomerTypeInfo = ({
  count,
  customerList,
  icon,
  isLoading,
  name,
  shopId,
  tooltipContent,
}: Props) => {
  return (
    <CustomerCountInfo
      count={count}
      customerList={customerList}
      description={
        <>
          {icon}
          <div className={styles.typeName}>{name}</div>
          <CustomerTypeTooltip
            content={tooltipContent}
            label={`About ${name}`}
          />
        </>
      }
      isLoading={isLoading}
      shopId={shopId}
    />
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default CustomerTypeInfo;
