import PropTypes from 'prop-types';

const CustomizationSummary = ({ limit, isRequired }) => (
  <>
    {isRequired ? 'Required' : 'Optional'}
    <br />
    {limit ? `Choice of ${limit}` : 'No limit'}
  </>
);

CustomizationSummary.propTypes = {
  isRequired: PropTypes.bool.isRequired,
  limit: PropTypes.number.isRequired,
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default CustomizationSummary;
