import cx from 'classnames';

import { Button } from 'crust';

import { useCurrentShopId } from 'hooks/shops';
import useAnalytics from 'hooks/use-analytics';
import WarningIcon from 'images/warning.svg?react';

import styles from './styles.module.scss';

type Props = {
  className?: string;
  page: 'homepage' | 'statements' | 'orders' | 'reports';
  redirectUrl: string;
};

export const AchEnrollmentBanner = ({
  className,
  page,
  redirectUrl,
}: Props) => {
  const shopId = useCurrentShopId();
  const { trackClickedVerifyAchEnrollmentBanner } = useAnalytics();

  const handleVerifyClick = () => {
    window.open(redirectUrl, '_blank');
    trackClickedVerifyAchEnrollmentBanner({
      linkLabel: 'verify',
      page,
      shopId,
      type: 'cta',
      url: redirectUrl,
    });
  };

  return (
    <div className={cx(styles.achEnrollmentBanner, className)}>
      <WarningIcon className={styles.warningIcon} />
      <div className={styles.headerText}>Complete Payment Verification</div>
      <div className={styles.description}>
        Update your information to verify your account and continue processing
        payments.
      </div>
      <Button onPress={handleVerifyClick} className={styles.verifyButton}>
        verify
      </Button>
    </div>
  );
};
