import { Badge } from 'crust';

import { PayoutStatus } from 'types/payouts';

type Props = {
  status: PayoutStatus;
};

export const PayoutStatusDisplay = ({ status }: Props) => {
  const variant =
    status === 'success' || status === 'paid'
      ? 'success'
      : status === 'pending'
        ? 'neutral'
        : status === 'reversed'
          ? 'warning'
          : 'critical';

  return <Badge variant={variant}>{status}</Badge>;
};
