export const getPsmEmailInfo = (
  shopId: string,
): { body: string; subject: string } => {
  const br = '%0D%0A';

  return {
    body: `${br}${br}${br}${br}${br}---${br}RE: General Inquiry${br}ShopID#: ${shopId}${br}Source: Owner's Portal`,
    subject: `RE: General Inquiry, ShopID#: ${shopId}`,
  };
};
