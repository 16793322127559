import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { z } from 'zod';

const schema = z.union([z.string().uuid(), z.literal('new')]);

export const useCurrentRegisterPermissionGroupId = () => {
  const { permissionGroupId } = useParams();
  const result = useMemo(
    () => schema.safeParse(permissionGroupId),
    [permissionGroupId],
  );

  if (result.success) {
    return result.data;
  }

  // Until we have an error handling pattern for undefined and invalid params,
  // treat them as new.
  return 'new';
};
