import DebitCardContent from 'components/debit-cards';
import Page from 'components/shared/page';

const DebitCards = () => {
  return (
    <Page name="debit-cards" title="Debit Card Payout">
      <DebitCardContent />
    </Page>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default DebitCards;
