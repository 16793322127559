import { useNavigate } from 'react-router-dom';
import {
  AppState,
  Auth0Provider,
  Auth0ProviderOptions,
  AuthorizationParams,
} from '@auth0/auth0-react';

type Props = Pick<Auth0ProviderOptions, 'children' | 'domain' | 'clientId'> &
  Pick<AuthorizationParams, 'audience'>;

const AuthenticationProvider = ({
  children,
  domain,
  clientId,
  audience,
}: Props) => {
  const navigate = useNavigate();

  const onRedirectCallback = (appState?: AppState) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        audience,
        redirect_uri: window.location.origin,
        scope: 'read:current_user update:current_user_metadata',
      }}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens
    >
      {children}
    </Auth0Provider>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default AuthenticationProvider;
