import cx from 'classnames';

import { Checkbox } from 'crust';

import Link from 'components/shared/link';
import { StockDropdown } from 'components/shared/stock-dropdown';
import Text from 'components/shared/text';
import useAnalytics from 'hooks/use-analytics';
import * as paths from 'routes/paths';
import { MenuCategory } from 'types/menu/category';
import { MenuProduct, MenuProductType } from 'types/menu/product';
import { Shop } from 'types/shops';
import { nonDisplayCategoryName } from 'utilities/constants';
import { getShopHasFullRegister } from 'utilities/shops';

import { useBulkEditContext } from '../context';

import ProductImage from './image';
import ProductType from './type';

import styles from './styles.module.scss';

type ActionButtonsProps = {
  product: MenuProduct;
  productTypesById: Record<number, MenuProductType>;
  shopId: string;
  handleOpenEditPage: () => void;
};

// Editing is disabled for non display product categories, otherwise they could break coupon bundles
const ActionButtons = ({
  product,
  productTypesById,
  shopId,
  handleOpenEditPage,
}: ActionButtonsProps) => {
  const productTypes: MenuProductType[] = [];

  for (const productTypeId of product.productTypeIds) {
    if (productTypesById[productTypeId]) {
      productTypes.push(productTypesById[productTypeId]);
    }
  }

  return (
    <div className={styles.actionButtons}>
      <StockDropdown
        product={product}
        productTypes={productTypes}
        shopId={shopId}
      />
      <Link
        className={styles.edit}
        data-chameleon-target="Edit Product Button"
        onClick={handleOpenEditPage}
        aria-label={`Edit product ${product.name}`}
        to={paths.getMenuItemPath(shopId, product.id)}
        variant="tertiary"
      >
        Edit
      </Link>
    </div>
  );
};

type ProductTypesProps = {
  product: MenuProduct;
  productTypesById: Record<number, MenuProductType>;
};

const ProductTypes = ({ product, productTypesById }: ProductTypesProps) => {
  return (
    <div className={styles.types}>
      <div className={styles.typesHeader}>
        <div>Item</div>
        <div>Price</div>
      </div>
      {product.productTypeIds.map((it) => {
        const productType = productTypesById[it];

        if (productType !== undefined) {
          return (
            <ProductType
              key={it}
              name={productType.name}
              price={productType.price}
              unavailable={product.unavailable}
            />
          );
        } else {
          return null;
        }
      })}
    </div>
  );
};

type ProductProps = {
  category: MenuCategory;
  product: MenuProduct;
  shop: Shop;
  productTypesById: Record<number, MenuProductType>;
};

export const Product = ({
  category,
  product,
  productTypesById,
  shop,
}: ProductProps) => {
  const { selectedProductIds, changeSelectedProductIds } = useBulkEditContext();
  const isSelected = selectedProductIds.has(product.id);

  const { trackClickedEditMenuProduct, trackMenuSelectedBulkEditItem } =
    useAnalytics();

  const isNonDisplay = category.name === nonDisplayCategoryName;

  const baseTrackingProperties = {
    shopId: shop.shopId,
    productId: product.id,
    productName: product.name,
  };

  const handleOpenEditPage = () => {
    trackClickedEditMenuProduct({
      ...baseTrackingProperties,
      productUnavailable: product.unavailable,
    });
  };

  const handleToggleProductSelection = (isSelected: boolean) => {
    changeSelectedProductIds([product.id], isSelected);

    trackMenuSelectedBulkEditItem({
      ...baseTrackingProperties,
      page: 'menu items',
      isRegister: getShopHasFullRegister(shop),
      isSelected,
    });
  };

  return (
    <div
      className={cx(styles.product, product.unavailable && styles.unavailable)}
      id={`product-${product.id}`}
    >
      <div className={styles.checkBoxContainer}>
        <Checkbox
          aria-label={`select ${product.name}`}
          isSelected={isSelected}
          onChange={handleToggleProductSelection}
        />
        <ProductImage imageUrl={product.image} imageAlt={product.name} />
      </div>
      <div className={styles.info}>
        <Text clamp as="h5" className={styles.name}>
          {product.name}
        </Text>
        <Text className={styles.description}>{product.description}</Text>
        <ProductTypes product={product} productTypesById={productTypesById} />
      </div>
      {isNonDisplay ? (
        <Text className={styles.nonDisplay} wrap="break">
          To edit or change the stock of this product please contact your
          Partner Success Manager.
        </Text>
      ) : (
        <ActionButtons
          product={product}
          productTypesById={productTypesById}
          shopId={String(shop.shopId)}
          handleOpenEditPage={handleOpenEditPage}
        />
      )}
    </div>
  );
};
