import * as React from "react";
import { forwardRef } from "react";
const SvgBar = ({
  title,
  titleId,
  ...props
}, ref) => {
  return /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 144 115", ref, "aria-labelledby": titleId, ...props }, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("g", { fill: "none", fillRule: "evenodd" }, /* @__PURE__ */ React.createElement("g", { fill: "#E8E8E8" }, /* @__PURE__ */ React.createElement("g", { transform: "translate(-1028 -219) translate(1028 219)" }, /* @__PURE__ */ React.createElement("rect", { width: 27, height: 42, y: 73, rx: 2 }), /* @__PURE__ */ React.createElement("rect", { width: 27, height: 94, x: 40, y: 21, rx: 2 }), /* @__PURE__ */ React.createElement("rect", { width: 27, height: 115, x: 78, rx: 2 }), /* @__PURE__ */ React.createElement("rect", { width: 27, height: 42, x: 117, y: 73, rx: 2 })))));
};
const ForwardRef = forwardRef(SvgBar);
export default ForwardRef;
