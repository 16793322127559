import {
  MenuCategoryResponseBody,
  MenuProductResponseBody,
  MenuResponseBody,
} from 'types/menu/api';
import {
  createIntegerComparator,
  createStringComparator,
} from 'utilities/sorting';

const stringIdComparator = createStringComparator('id');
const integerIdComparator = createIntegerComparator('id');

type Response =
  | MenuResponseBody
  | MenuCategoryResponseBody
  | MenuProductResponseBody;

// Sorts the relationships of a response in-place so that react query can
// reliably deep equal the cached data. The order of relationships arrays does
// not matter because display order is set through arrays of IDs on the
// individual entities (eg Product.productTypeIds).
export const sortRelationships = <T extends Response>(response: T): T => {
  const relationships = response.relationships;

  if ('addonGrids' in relationships) {
    relationships.addonGrids?.sort(stringIdComparator);
  }

  if ('addons' in relationships) {
    relationships.addons?.sort(integerIdComparator);
  }

  if ('catalogEntries' in relationships) {
    relationships.catalogEntries?.sort(stringIdComparator);
    relationships.catalogEntries?.forEach((it) =>
      it.aliases?.sort(stringIdComparator),
    );
  }

  if ('categories' in relationships) {
    relationships.categories?.sort(integerIdComparator);
  }

  if ('productTypes' in relationships) {
    relationships.productTypes?.sort(integerIdComparator);
  }

  if ('products' in relationships) {
    relationships.products?.sort(integerIdComparator);
  }

  if ('selections' in relationships) {
    relationships.selections?.sort(integerIdComparator);
    relationships.selections?.forEach((it) =>
      it.prices.sort(integerIdComparator),
    );
  }

  return response;
};
