import cx from 'classnames';

import defaultImage from 'images/camera.svg';
import { imgixUrl } from 'utilities/image-urls';

import styles from './styles.module.scss';

const height = 40;
const width = 40;

type ProductImageProps = {
  imageUrl?: string | null;
  imageAlt: string;
};

export const ProductImage = ({
  imageUrl = '',
  imageAlt,
}: ProductImageProps) => {
  if (imageUrl) {
    return (
      <img
        className={styles.image}
        src={imgixUrl(imageUrl, height, width)}
        alt={imageAlt}
      />
    );
  }

  return (
    <div className={cx(styles.image, styles.defaultImageWrapper)}>
      <img className={styles.cameraIcon} src={defaultImage} alt={imageAlt} />
    </div>
  );
};
