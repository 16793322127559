import MenuProductPageContent from 'components/menu/product';
import { MenuProductPageHeader } from 'components/menu/product/page-header';
import { UnexpectedError } from 'components/shared/error';
import Loading from 'components/shared/loading';
import Page from 'components/shared/page';
import { useCurrentProductId } from 'hooks/menu/use-current-product-id';
import { useMenuQuery } from 'hooks/menu/use-menu-query';
import { useProductPrintSettingsQuery } from 'hooks/menu/use-product-print-settings-query';
import { useProductQuery } from 'hooks/menu/use-product-query';
import { useCurrentShopId } from 'hooks/shops/use-current-shop-id';
import { useShopQuery } from 'hooks/shops/use-shop-query';
import useFeatureFlag from 'hooks/use-feature-flag';
import { OptimizelyFeatureFlag } from 'types/optimizely-feature-flag';

const MenuProductPage = () => {
  const shopId = useCurrentShopId();
  const productId = useCurrentProductId();

  const isNew = productId === 'new';
  const name = `${isNew ? 'add' : 'edit'}-menu-product`;

  const {
    data: shop,
    isError: isShopError,
    isLoading: isShopLoading,
  } = useShopQuery(shopId);

  // The menu data is used for a few purposes:
  //
  // - Category dropdown in the details tile.
  // - Category and product dropdowns for copying customizations.
  // - Validating that the product name is unique in the category.
  //
  // For the dropdowns, we don't need the entire menu but using the menu query
  // has some advantages. We likely already have the menu data cached, unless
  // the product page was the first page loaded (eg reload, bookmark, etc). If
  // it is not cached, we likely need it soon anyway. The immediate speed
  // benefit of fetching slightly smaller payloads is probably out-weighed by
  // the benefit of fetching the often used menu query since we have to make a
  // network request regardless.
  const {
    data: menuResponse,
    isLoading: isMenuLoading,
    refetch: refetchMenu,
    isError: isMenuError,
  } = useMenuQuery(shopId);

  // -1 is a placeholder that will not be used in an active query and helps
  // avoid more complex changes to satisfy the typing of useProductQuery.
  const {
    data: productResponse,
    isLoading: isProductQueryLoading,
    isError: isProductQueryError,
  } = useProductQuery(shopId, isNew ? -1 : productId, {
    enabled: !isNew,
    staleTime: Infinity,
  });

  // Sending 0 in this query is valid and will return all of the available
  // printer settings as inactive.
  const {
    data: printSettingsResponse,
    isLoading: isProductPrintSettingsLoading,
    isError: isProductPrintSettingsError,
  } = useProductPrintSettingsQuery(shopId, isNew ? 0 : productId, {
    staleTime: Infinity,
  });

  const { isEnabled: isKitchenNameEnabled, isLoading: isKitchenNameLoading } =
    useFeatureFlag(OptimizelyFeatureFlag.ProductKitchenName);

  let content;
  let isReady = false;

  if (
    isShopLoading ||
    isMenuLoading ||
    (!isNew && isProductQueryLoading) ||
    isProductPrintSettingsLoading ||
    isKitchenNameLoading
  ) {
    content = <Loading />;
  } else if (
    isShopError ||
    isMenuError ||
    isProductQueryError ||
    isProductPrintSettingsError
  ) {
    content = <UnexpectedError />;
  } else {
    isReady = true;
    content = (
      <MenuProductPageContent
        isRosEnabled={shop.rosEnabled}
        isKitchenNameEnabled={isKitchenNameEnabled}
        menuResponse={menuResponse}
        printSettings={printSettingsResponse}
        productResponse={productResponse}
        refetchMenu={refetchMenu}
        shopId={String(shopId)}
        shopName={shop.name ?? ''}
      />
    );
  }

  return (
    <Page
      customPageHeader={
        <MenuProductPageHeader
          category={productResponse?.relationships?.categories?.[0]}
          isLoading={!isReady}
          isNew={isNew}
          product={productResponse?.product}
          productTypes={productResponse?.relationships?.productTypes}
          shopId={shopId}
          shopWebSlug={shop?.webSlug}
        />
      }
      name={name}
    >
      {content}
    </Page>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default MenuProductPage;
