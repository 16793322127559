import { ShopPublisherInfo } from '../shop-publishers';

import styles from './styles.module.scss';

type Props = {
  shopPublisherInfo: ShopPublisherInfo;
};

const LinkedAccountTile = ({ shopPublisherInfo }: Props) => {
  const { displayName, isEnabled, logo, logoAlt } = shopPublisherInfo;

  return (
    <div className={styles.linkedAccountTile}>
      <div className={styles.logoAndNameArea}>
        <img className={styles.logo} src={logo} alt={logoAlt} />
        <div className={styles.publisherName}>{displayName}</div>
      </div>
      <div className={styles.activeInactiveArea}>
        <div
          className={isEnabled ? styles.activeCircle : styles.inactiveCircle}
        />
        <div className={styles.activeInactiveText}>
          {isEnabled ? 'Active' : 'Inactive'}
        </div>
      </div>
    </div>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default LinkedAccountTile;
