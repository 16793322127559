import cx from 'classnames';

import styles from './styles.module.scss';

type Props = {
  className?: string;
  Icon?: React.ElementType;
  label: string;
};

export const LegendEntry = ({ className, Icon, label }: Props) => {
  return (
    <div className={styles.legendEntry}>
      <div className={cx(styles.colorBlock, className)}></div>
      {Icon && <Icon className={styles.icon} />}
      <div className={styles.label}>{label}</div>
    </div>
  );
};
