import { Menu, MenuButton } from '@szhsin/react-menu';

import useAnalytics from 'hooks/use-analytics';
import { usePsmInfoQuery } from 'hooks/use-psm-info';

import PsmInfoPopup from './psm-info-popup';

import styles from './styles.module.scss';

type PsmInfoProps = {
  shopId: string;
  verificationCode: string;
};

const PsmInfo = ({ shopId, verificationCode }: PsmInfoProps) => {
  const { data } = usePsmInfoQuery(shopId);
  const hasPartnerName = data?.firstName;
  const partnerName = hasPartnerName
    ? `${data?.firstName} ${data?.lastName}`
    : 'How can we help?';

  const { trackClickedPsmContactInfo } = useAnalytics();

  const handleClickedPsmContactInfo = (): void => {
    trackClickedPsmContactInfo({
      shopId,
      page: 'navigation',
      psmName: partnerName,
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.label}>Partner Success Manager</div>
      <Menu
        menuButton={
          <MenuButton
            className={styles.menuButton}
            onClick={handleClickedPsmContactInfo}
          >
            {partnerName}
          </MenuButton>
        }
        arrow
        direction="right"
        position="anchor"
        align="start"
        menuClassName={styles.menu}
      >
        <PsmInfoPopup shopId={shopId} partnerName={partnerName} />
      </Menu>
      {verificationCode && (
        <div className={styles.pin}>PIN: {verificationCode}</div>
      )}
    </div>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default PsmInfo;
