import Link from 'components/shared/link';
import { useCurrentShopId } from 'hooks/shops';
import * as paths from 'routes/paths';

import styles from './styles.module.scss';

const AddNewCardLink = () => {
  const shopId = useCurrentShopId();

  return (
    <Link
      className={styles.addCardLink}
      to={paths.debitCardNew(shopId)}
      data-chameleon-target="Add New Debit Card"
    >
      <span className={styles.addIcon}>+</span>
      <span className={styles.addCardLinkContent}>Add a new card</span>
    </Link>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default AddNewCardLink;
