import { useMutation } from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { RegisterLayout } from 'types/register-layout';
import { camelCaseKeys, snakeCaseKeys } from 'utilities/objects';

export const useUpdateRegisterLayoutMutation = (shopId: string) => {
  const { authenticatedFetch } = useApi();

  return useMutation({
    mutationFn: async (layout: RegisterLayout) => {
      const response = await authenticatedFetch.put(
        `api/management/v1/ros/shops/${shopId}/menu/layouts/${layout.id}`,
        snakeCaseKeys(layout),
      );

      return camelCaseKeys(response) as RegisterLayout;
    },
  });
};
