import { Discounts } from 'components/discounts';
import { UnexpectedError } from 'components/shared/error';
import Loading from 'components/shared/loading';
import Page from 'components/shared/page';
import { useRegisterDiscountsQuery } from 'hooks/discounts';
import { useCouponsQuery } from 'hooks/discounts/use-coupons-query';
import { usePromoCodesQuery } from 'hooks/discounts/use-promo-codes-query';
import { useCurrentShopId, useShopQuery } from 'hooks/shops';

export const DiscountsPage = () => {
  const shopId = useCurrentShopId();

  const {
    data: shop,
    isError: isShopError,
    isLoading: isShopLoading,
  } = useShopQuery(shopId);

  const {
    data: promoCodes,
    isError: isPromoCodesError,
    isLoading: isPromoCodesLoading,
  } = usePromoCodesQuery(shopId);

  const {
    data: registerDiscounts,
    isError: isRegisterDiscountsError,
    isLoading: isRegisterDiscountsLoading,
  } = useRegisterDiscountsQuery(shopId);

  const {
    data: coupons,
    isError: isCouponsError,
    isLoading: isCouponsLoading,
  } = useCouponsQuery(shopId);

  let content;
  if (
    isShopLoading ||
    isPromoCodesLoading ||
    isRegisterDiscountsLoading ||
    isCouponsLoading
  ) {
    content = <Loading />;
  } else if (
    isShopError ||
    isPromoCodesError ||
    isRegisterDiscountsError ||
    isCouponsError
  ) {
    content = <UnexpectedError />;
  } else {
    content = (
      <Discounts
        coupons={coupons}
        promoCodes={promoCodes}
        registerDiscounts={registerDiscounts}
        shop={shop}
      />
    );
  }

  return (
    <Page name="discounts" title="Discounts">
      {content}
    </Page>
  );
};
