import cx from 'classnames';

import { Top10CustomersTabType } from 'types/customers';

import styles from './styles.module.scss';

type Props = {
  selectedTab: Top10CustomersTabType;
  setSelectedTab: (tab: Top10CustomersTabType) => void;
};

const Top10CustomersTabs = ({ selectedTab, setSelectedTab }: Props) => {
  return (
    <div className={styles.tabsContainer}>
      <button
        type="button"
        className={cx(
          styles.tabButton,
          selectedTab === Top10CustomersTabType.Value && styles.activeTabButton,
        )}
        onClick={() => setSelectedTab(Top10CustomersTabType.Value)}
      >
        Value
      </button>
      <button
        type="button"
        className={cx(
          styles.tabButton,
          selectedTab === Top10CustomersTabType.Frequency &&
            styles.activeTabButton,
        )}
        onClick={() => setSelectedTab(Top10CustomersTabType.Frequency)}
      >
        Frequency
      </button>
    </div>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default Top10CustomersTabs;
