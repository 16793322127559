import { RowData } from '@tanstack/react-table';
import cx from 'classnames';

import Spinner from 'components/shared/spinner';
import {
  AutomaticTableBodyProps,
  BaseTableBodyProps,
  OverloadedTableBodyComponent,
} from 'types/shared/table';

import TableBodyEmpty from './empty';
import TableBodyPlaceholder from './placeholder';
import TableBodyRow from './row';

import styles from './styles.module.scss';

const BaseTableBody = ({ className, ...props }: BaseTableBodyProps) => (
  <tbody className={cx(styles.body, className)} {...props} />
);

const TableBody: OverloadedTableBodyComponent = <TData extends RowData>(
  props: BaseTableBodyProps | AutomaticTableBodyProps<TData>,
) => {
  // Pass the result of useReactTable to automatically the table body.
  if ('table' in props) {
    const { table, ...base } = props;
    const rows = table.getRowModel().rows;
    const isLoading = table.options.meta?.isLoading;

    if (isLoading || rows.length === 0) {
      const colSpan = table.getVisibleLeafColumns().length;
      const emptyPlaceholder =
        table.options.meta?.getEmptyPlaceholder ?? TableBodyEmpty;

      return (
        <BaseTableBody {...base}>
          <TableBodyPlaceholder colSpan={colSpan}>
            {isLoading ? <Spinner /> : emptyPlaceholder({ table })}
          </TableBodyPlaceholder>
        </BaseTableBody>
      );
    } else {
      // The ID used here is provided by React Table and is separate from any ID
      // that may be present in the table data.
      // https://tanstack.com/table/v8/docs/api/core/row#id
      return (
        <BaseTableBody {...base}>
          {rows.map((row, index) => (
            <TableBodyRow key={row.id} row={row} index={index} />
          ))}
        </BaseTableBody>
      );
    }
  }

  return <BaseTableBody {...props} />;
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default TableBody;
