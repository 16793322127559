import { useMatch } from 'react-router-dom';

import PermissionsLogTab from 'components/register/users/permissions-log';
import PermissionsToggle from 'components/register/users/permissions-toggle';
import RegisterPermissionsTab from 'components/register/users/register-permissions';
import RegisterUsersTab from 'components/register/users/register-users';
import NavigationTabs from 'components/shared/navigation-tabs';
import * as paths from 'routes/paths';
import {
  RegisterPermissionGroup,
  RegisterUser,
  RegisterUserRowData,
} from 'types/register-users';
import { Shop } from 'types/shops';

import styles from './styles.module.scss';

type Props = {
  permissionGroups: RegisterPermissionGroup[];
  shopData: Shop;
  users: RegisterUser[];
  usersRowData: RegisterUserRowData[];
};

const Users = ({ permissionGroups, shopData, users, usersRowData }: Props) => {
  const shopId = String(shopData.shopId);

  const usersPath = paths.registerUsers(shopId);
  const permissionsPath = paths.registerPermissions(shopId);
  const logPath = paths.registerPermissionsLog(shopId);

  const usersMatch = useMatch(usersPath);
  const permissionsMatch = useMatch(permissionsPath);
  const logMatch = useMatch(logPath);

  const numUsersWithPin = users.filter((user) => user.pin !== null).length;

  return (
    <>
      <div className={styles.subNavAndToggle}>
        <NavigationTabs className={styles.subNav}>
          <NavigationTabs.Link
            to={usersPath}
            data-chameleon-tag="Register Users"
          >
            Register Users
          </NavigationTabs.Link>
          <NavigationTabs.Link
            to={permissionsPath}
            data-chameleon-tag="Register Permissions"
          >
            Register Permissions
          </NavigationTabs.Link>
          <NavigationTabs.Link
            to={logPath}
            data-chameleon-tag="Permissions Log"
          >
            Permissions Log
          </NavigationTabs.Link>
        </NavigationTabs>
        {numUsersWithPin > 0 ? (
          <PermissionsToggle
            isEnabled={shopData.registerUserPermissions}
            shopId={String(shopId)}
          />
        ) : null}
      </div>
      {usersMatch && (
        <RegisterUsersTab shopId={String(shopId)} usersRowData={usersRowData} />
      )}
      {permissionsMatch && (
        <RegisterPermissionsTab
          permissionGroups={permissionGroups}
          shopId={String(shopId)}
          users={users}
        />
      )}
      {logMatch && <PermissionsLogTab shopId={String(shopId)} />}
    </>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default Users;
