import { useParams } from 'react-router-dom';
import { validate as uuidValidate, version as uuidVersion } from 'uuid';

type Params = {
  discountId: string;
};

export const useCurrentRegisterDiscountId = () => {
  const { discountId } = useParams<Params>();

  if (discountId && uuidValidate(discountId) && uuidVersion(discountId) === 4) {
    return discountId;
  }

  return 'new';
};
