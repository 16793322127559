import {
  GetPublishersResponse,
  TotalRevenueAndOrders,
} from 'types/brand-manager';
import { BrandManagerDateRange } from 'types/brand-manager/date-picker';
import { toMoneyString } from 'utilities/currency';

export const getBrandManagerDatePickerOptions = () =>
  [
    {
      label: 'Last 7 days',
      value: BrandManagerDateRange.Last7Days,
    },
    {
      label: 'Last 28 days',
      value: BrandManagerDateRange.Last28Days,
    },
  ] as const;

export const getTotalRevenueAndOrders = (
  data: GetPublishersResponse | undefined,
  dateRange: BrandManagerDateRange,
): TotalRevenueAndOrders => {
  if (data === undefined) {
    return {
      revenue: '$--',
      orders: '--',
    };
  }

  const accessor =
    dateRange === BrandManagerDateRange.Last7Days ? 'last7Days' : 'last28Days';

  const dataForDateRange = data.all[accessor];

  return {
    revenue: toMoneyString(dataForDateRange.revenue.total, true, false),
    orders: dataForDateRange.orders.total.toString(),
  };
};
