import { ShopTraits } from './traits';

/*
  Based on restaurant-api repo files:
  - db/schema.rb
  - app/models/delivery_zone.rb
*/
export type DeliveryZone = {
  createdAt: string | null;
  deletedAt: string | null;
  deliveryFee: string | null;
  deliveryGeometry: string | null;
  deliveryPercent: string | null;
  deliveryRadius: number | null;
  deliveryZipcodes: string | null;
  id: number;
  internalDeliveryFee: string | null;
  internalDeliveryPercent: string | null;
  minimumOrder: string | null;
  shopId: number;
  updatedAt: string | null;
  uuid: string;
};

export type RegisterMode = {
  id: string;
  name: RegisterModeName;
};

export enum RegisterModeName {
  Basic = 'basic',
  Full = 'full',
}

/**
  Based on restaurant-api repo files:
  - db/schema.rb
  - app/representers/shop_representer.rb (note the default "render_nil")
  - app/models/shop.rb
*/
export enum TransmissionMethod {
  Fax = 'fax',
  Email = 'email',
  Phone = 'phone',
  POS = 'pos',
  Register = 'register',
  Tablet = 'tablet',
}

export type Shop = {
  acceptsCards: number | null;
  acceptsCash: number | null;
  address: string | null;
  city: string | null;
  deliveryEstimate: string | null;
  deliveryOverflowEnabled: boolean;
  deliveryServiceFee: string | null;
  deliveryZones: DeliveryZone[];
  disabled: boolean;
  discountPercent: number | null;
  doesPickup: boolean;
  domainName: string | null;
  facebookPageId: number | null;
  hasProgrammaticEtas: boolean;
  holidays: string | null;
  logoUrl: string | null;
  minDeliveryOrder: string | null;
  minPickupOrder: number | null;
  name: string | null;
  offersCurbsidePickup: boolean;
  openForDelivery: boolean;
  openForPickup: boolean;
  ownWebsite: string | null;
  phone: string | null;
  pickupEstimate: string | null;
  projectBySlice: boolean;
  registerMode: RegisterMode;
  registerUserPermissions: boolean;
  rosEnabled: boolean;
  shopId: number;
  sliceCardEnabled: boolean;
  slicePayments: boolean;
  state: string | null;
  taxDeliveryFee: number | null;
  taxRate: string | null;
  thirdPartyDelivery: boolean;
  /** @deprecated Use `timezoneIdentifier` instead! The `timezone` property
   * can contain non-standard timezone names and may not accurately reflect
   * daylight savings time. */
  timezone: string;
  timezoneIdentifier: string;
  transmissionMethod: TransmissionMethod;
  twilioPhone: string | null;
  usesCrossStreet: boolean;
  uuid: string;
  webSlug: string | null;
  zipcode: string | null;
};

export type ShopsResponse = {
  shops: Shop[];
};

export type ShopRatings = {
  displayRating: number;
  rating: number;
  ratingsCount: number;
  shouldDisplayRating: boolean;
};

export type ShopRatingsResponse = {
  ratings: ShopRatings;
};

export type ShopWithTraits = {
  shop: Shop;
  traits: ShopTraits;
};
