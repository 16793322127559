import { CustomerSegmentsTableColumnId, CustomerSortBy } from 'types/customers';
import { isoDateAndTime, nowDateTime } from 'utilities/date-time';

export type DateRangeOption = {
  label: string;
  value: string;
};

export const FieldLabels = {
  [CustomerSegmentsTableColumnId.AverageOrderValue]: 'Average Order Value',
  [CustomerSegmentsTableColumnId.TotalSpend]: 'Order Total',
  [CustomerSegmentsTableColumnId.OrderCount]: 'Order Count',
  [CustomerSegmentsTableColumnId.LastOrderDate]: 'Order Date',
} as const;

export const getDirectionLabel = (
  columnId: string,
  isDescending: boolean,
): string => {
  if (!isDescending) {
    if (columnId === CustomerSegmentsTableColumnId.LastOrderDate) {
      return 'Oldest - Newest';
    }

    return 'Lowest - Highest';
  }

  if (columnId === CustomerSegmentsTableColumnId.LastOrderDate) {
    return 'Newest - Oldest';
  }

  return 'Highest - Lowest';
};

export const getDateRangeOptions = (
  timezone: string | undefined,
): DateRangeOption[] => [
  {
    label: 'Last 30 days',
    value: nowDateTime(timezone)
      .subtract(30, 'days')
      .endOf('day')
      .format(isoDateAndTime),
  },
];

export const ColumnIdToCustomerSort = {
  [CustomerSegmentsTableColumnId.OrderCount]: CustomerSortBy.OrderCount,
  [CustomerSegmentsTableColumnId.LastOrderDate]: CustomerSortBy.OrderDate,
  [CustomerSegmentsTableColumnId.AverageOrderValue]:
    CustomerSortBy.AverageOrderValue,
  [CustomerSegmentsTableColumnId.TotalSpend]: CustomerSortBy.OrderTotal,
} as const;
