import moment from 'moment-timezone';

import { EventType, ShopStatusHistory } from 'types/hours-analytics';
import { ShippingType } from 'types/shop-hours';

export const eventTypeDisplayValue = {
  [EventType.Close]: 'Close',
  [EventType.ModifiedHours]: 'Modified Hours',
  [EventType.Pause]: 'Pause',
  [EventType.ScheduledClosing]: 'Scheduled Close',
};

export const shippingTypeToDisplayValue = {
  [ShippingType.Delivery]: 'Delivery',
  [ShippingType.Pickup]: 'Pickup',
  [ShippingType.DeliveryAndPickup]: 'Delivery & Pickup',
};

export const getDateDisplayValue = (
  shopStatusHistory: ShopStatusHistory,
  timezone: string,
): string => {
  const { startTime, endTime, eventType } = shopStatusHistory;

  const formattedStart = moment.tz(startTime, timezone).format('M/D/YY');

  if (eventType !== EventType.ScheduledClosing) {
    return formattedStart;
  }

  const formattedEnd = moment.tz(endTime, timezone).format('M/D/YY');

  return `${formattedStart} - ${formattedEnd}`;
};

export const getTimeDisplayValue = (
  shopStatusHistory: ShopStatusHistory,
  timezone: string,
): string => {
  const { startTime, endTime, eventType } = shopStatusHistory;

  if (eventType === EventType.ScheduledClosing) {
    return 'All Day';
  }

  const outputFormat = 'h:mma';

  const formattedStart = moment.tz(startTime, timezone).format(outputFormat);
  const formattedEnd = moment.tz(endTime, timezone).format(outputFormat);

  return `${formattedStart}-${formattedEnd}`;
};

export const getLengthDisplayValue = (
  shopStatusHistory: ShopStatusHistory,
): string => {
  const { eventType, lengthInMinutes } = shopStatusHistory;

  // We do not want to display length for Modified Hours or Scheduled Closing.
  if (
    eventType === EventType.ModifiedHours ||
    eventType === EventType.ScheduledClosing
  ) {
    return '-';
  }

  if (!lengthInMinutes) {
    return '-';
  }

  if (lengthInMinutes < 60) {
    return `${lengthInMinutes}min`;
  }

  const hours = Math.floor(lengthInMinutes / 60);

  if (hours >= 24) {
    const days = Math.floor(hours / 24);
    return `${days}${days === 1 ? 'day' : 'days'}`;
  }

  const minutes = lengthInMinutes % 60;

  if (minutes > 0) {
    return `${hours}hr ${minutes}min`;
  }

  return `${hours}hr`;
};

export const getReasonDisplayValue = (reason: string | null): string => {
  return reason ?? '-';
};
