import SliceIcon from 'images/shop-score/network-status/icon-slice.svg?react';
import FilledSliceIcon from 'images/shop-score/network-status/icon-slice-filled.svg?react';

import styles from './styles.module.scss';

const NetworkStatusLegend = () => (
  <div className={styles.legend}>
    <div className={styles.legendItem}>
      <FilledSliceIcon aria-hidden="true" className={styles.sliceIconShop} />
      <div className={styles.legendTitle}>Your Restaurant</div>
    </div>
    <div className={styles.legendItem}>
      <SliceIcon aria-hidden="true" className={styles.sliceIconAvg} />
      <div className={styles.legendTitle}>Avg. in Your Area </div>
    </div>
  </div>
);

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default NetworkStatusLegend;
