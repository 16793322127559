import styles from './styles.module.scss';

type Props = {
  date: string;
  orderNumber: string;
};

const ModalHeader = ({ date, orderNumber }: Props) => {
  return (
    <>
      <div className={styles.date}>{date}</div>
      <div className={styles.orderNumber}>Order #{orderNumber}</div>
    </>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default ModalHeader;
