import * as React from "react";
import { forwardRef } from "react";
const SvgHelpIcon = ({
  title,
  titleId,
  ...props
}, ref) => {
  return /* @__PURE__ */ React.createElement("svg", { width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", "aria-hidden": "true", ref, "aria-labelledby": titleId, ...props }, title === void 0 ? /* @__PURE__ */ React.createElement("title", { id: titleId }, "Help Icon") : title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { d: "M12 2C9.34781 2 6.80432 3.05356 4.92898 4.92898C3.05356 6.80425 2 9.34788 2 12C2 14.6521 3.05356 17.1957 4.92898 19.071C6.80425 20.9464 9.34788 22 12 22C14.6521 22 17.1957 20.9464 19.071 19.071C20.9464 17.1957 22 14.6521 22 12C22 9.34788 20.9464 6.80432 19.071 4.92898C17.1957 3.05356 14.6521 2 12 2ZM12.756 15.976C12.76 16.618 12.4 17 11.77 17C11.14 17 10.72 16.6319 10.7179 16.094C10.7179 15.348 11.0519 14.976 11.7179 14.9561C12.2601 14.94 12.824 15.272 12.756 15.976V15.976ZM14.1559 11.3599C13.8719 11.5799 13.5739 11.78 13.2879 11.996V11.9958C12.8662 12.2722 12.6239 12.7524 12.652 13.2559C12.659 13.4344 12.6063 13.6101 12.5018 13.7551C12.3974 13.9001 12.2475 14.006 12.076 14.0558C11.8934 14.1261 11.6925 14.1338 11.505 14.0777C11.3175 14.0216 11.154 13.9048 11.04 13.7458C10.9652 13.6205 10.9246 13.4778 10.922 13.3319C10.8927 12.4142 11.3186 11.5414 12.0599 10.9998C12.3809 10.7556 12.6838 10.4881 12.9659 10.1999C13.1004 10.0414 13.1823 9.84501 13.2 9.63785C13.246 9.07186 12.8821 8.70789 12.296 8.66982C11.588 8.62378 10.992 8.8078 10.578 9.43378V9.43392C10.4912 9.58152 10.3613 9.69885 10.2058 9.77042C10.0502 9.84198 9.87652 9.86403 9.70798 9.83389C9.10795 9.73386 8.85002 9.24586 9.09595 8.68393C9.35348 8.13622 9.79612 7.69705 10.3459 7.44398C10.9107 7.16636 11.529 7.01484 12.158 6.99991C12.5137 7.03702 12.867 7.0952 13.2159 7.17388C14.2819 7.47591 14.9159 8.32787 14.9959 9.45986C15.0744 10.1981 14.7535 10.9222 14.1539 11.3598L14.1559 11.3599Z", fill: "currentColor" }));
};
const ForwardRef = forwardRef(SvgHelpIcon);
export default ForwardRef;
