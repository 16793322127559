import { useEffect, useState } from 'react';
import { Control, useController } from 'react-hook-form';
import { CalendarDate, today } from '@internationalized/date';

import { DatePicker, DateRangePicker, Switch, useDateFormatter } from 'crust';

import CollapsibleTile from 'components/shared/collapsible-tile';
import { PromoCodeFormValues } from 'types/discounts';
import { Shop } from 'types/shops';

import styles from './styles.module.scss';

type Props = {
  className?: string;
  control: Control<PromoCodeFormValues>;
  shopTimezone: Shop['timezoneIdentifier'];
};

export const DateFields = ({ className, control, shopTimezone }: Props) => {
  const minValue = today(shopTimezone);
  const [isOpen, setIsOpen] = useState(true);

  const formatter = useDateFormatter({
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    timeZone: shopTimezone,
  });

  const {
    field: { onChange, onBlur, ref, value },
    fieldState: { error, invalid: isInvalid },
    formState: { submitCount },
  } = useController({
    control,
    name: 'dates',
    rules: {
      validate: (next) => {
        const isSingle = next.kind === 'date';

        if (next.value == null) {
          return isSingle
            ? 'Please choose a date.'
            : 'Please choose start and end dates.';
        }

        const start = isSingle ? next.value : next.value.start;
        const end = isSingle ? null : next.value.end;

        if (start.compare(minValue) < 0) {
          const formatted = formatter.format(minValue.toDate(shopTimezone));
          return `Please choose a start date of ${formatted} or later.`;
        }

        if (end && end.compare(start) < 0) {
          return 'Please choose an end date that is after the start date.';
        }

        return true;
      },
    },
  });

  const isSingle = value.kind === 'date';
  const start = isSingle ? value.value : (value.value?.start ?? null);
  const end = isSingle ? null : (value.value?.end ?? null);
  const format = (date: CalendarDate | null) =>
    date ? formatter.format(date.toDate(shopTimezone)) : 'No date selected';

  useEffect(() => {
    if (submitCount && isInvalid) {
      setIsOpen(true);
    }
  }, [isInvalid, submitCount]);

  return (
    <CollapsibleTile
      bodyChameleonTarget="Promo Code Set Dates"
      bodyClassName={styles.body}
      className={className}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      summary={`Start: ${format(start)} End: ${format(end)}`}
      title="Set Date(s)"
    >
      {value.kind === 'range' ? (
        <DateRangePicker
          label="Start and End Date"
          onChange={(value) => onChange({ kind: 'range', value })}
          error={error?.message}
          isInvalid={isInvalid}
          minValue={today(shopTimezone)}
          onBlur={onBlur}
          ref={ref}
          validationBehavior="aria"
          value={value.value}
        />
      ) : (
        <DatePicker
          label="Start Date"
          onChange={(value) => onChange({ kind: 'date', value })}
          error={error?.message}
          isInvalid={isInvalid}
          minValue={today(shopTimezone)}
          onBlur={onBlur}
          ref={ref}
          validationBehavior="aria"
          value={value.value}
        />
      )}
      <Switch
        label="Set end date"
        isSelected={value.kind === 'range'}
        onChange={(isSelected) => {
          onChange(
            isSelected
              ? {
                  kind: 'range',
                  value: start ? { start, end: start.add({ days: 1 }) } : null,
                }
              : { kind: 'date', value: start },
          );
        }}
      />
    </CollapsibleTile>
  );
};
