import {
  Children,
  ComponentPropsWithoutRef,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { NavigationType, NavLink, useNavigationType } from 'react-router-dom';
import cx from 'classnames';

import styles from './styles.module.scss';

type NavigationTabsProps = ComponentPropsWithoutRef<'nav'>;

// NavLinkProps is not exported from the compatability library, but the
// interface has changed in v6. For now, use typeof.
type NavLinkProps = Parameters<typeof NavLink>[0];
type NavigationTabsLinkProps = Omit<NavLinkProps, 'activeClassName'>;

const NavigationTabs = ({
  children,
  className,
  ...props
}: NavigationTabsProps) => {
  const navigationType = useNavigationType();
  const navRef = useRef<HTMLElement>(null);

  // We want to keep the focus on a tab after it's clicked, so we'll focus the
  // ative tab. This solution is broader than just focusing after the click, but
  // good enough.
  useEffect(() => {
    if (navigationType === NavigationType.Push) {
      const element = navRef.current?.querySelector('a.active');

      if (element) {
        (element as HTMLAnchorElement).focus();
      }
    }
  }, [navigationType]);

  return (
    <nav className={cx(styles.nav, className)} ref={navRef} {...props}>
      <ul className={styles.tabs}>
        {Children.toArray(children).map((child, index) => (
          <li key={index} className={styles.tab}>
            {child}
          </li>
        ))}
      </ul>
    </nav>
  );
};

// React Router doesn't export this type.
type NavLinkRenderProps = Parameters<
  // eslint-disable-next-line @typescript-eslint/ban-types
  Extract<NavLinkProps['className'], Function>
>[0];

// NavLink wrapper that applies the appropriate classes.
const NavigationTabsLink = ({
  className,
  ...navLinkProps
}: NavigationTabsLinkProps) => {
  const resolvedClassName = useCallback(
    ({ isActive, isPending, isTransitioning }: NavLinkRenderProps) =>
      cx(
        styles.link,
        isActive && styles.active,
        typeof className === 'function'
          ? className({ isActive, isPending, isTransitioning })
          : className,
      ),
    [className],
  );

  return <NavLink className={resolvedClassName} {...navLinkProps} />;
};

NavigationTabs.Link = NavigationTabsLink;

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default NavigationTabs;
