import * as React from "react";
import { forwardRef } from "react";
const SvgHouseIcon = ({
  title,
  titleId,
  ...props
}, ref) => {
  return /* @__PURE__ */ React.createElement("svg", { width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", "aria-hidden": "true", ref, "aria-labelledby": titleId, ...props }, title === void 0 ? /* @__PURE__ */ React.createElement("title", { id: titleId }, "House Icon") : title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M12 3C11.4922 3 11.0006 3.17759 10.6122 3.50144L2.32356 10.4116C2.03145 10.6551 1.92422 11.0534 2.05514 11.4086C2.18607 11.7637 2.52725 12 2.90913 12H4.72731V19.2C4.72731 20.1941 5.54133 21 6.54548 21H10.1818V17.4C10.1818 16.4059 10.9958 15.6 12 15.6C13.0041 15.6 13.8181 16.4059 13.8181 17.4V21H17.4545C18.4587 21 19.2727 20.1941 19.2727 19.2V12H21.0909C21.4728 12 21.8139 11.7637 21.9449 11.4086C22.0758 11.0534 21.9686 10.6551 21.6764 10.4116L13.3878 3.50144C12.9994 3.17759 12.5078 3 12 3ZM10.1818 11.1C10.1818 10.1059 10.9958 9.3 12 9.3C13.0041 9.3 13.8181 10.1059 13.8181 11.1V12H10.1818V11.1Z", fill: "currentColor" }));
};
const ForwardRef = forwardRef(SvgHouseIcon);
export default ForwardRef;
