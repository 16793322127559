import cx from 'classnames';
import PropTypes from 'prop-types';

import VisuallyHidden from 'components/shared/visually-hidden';

import HeaderCellSortable from './header-cell-sortable';

import styles from './styles.module.scss';

const AnalyticsTable = ({
  className,
  data,
  RowComponent,
  sortBy,
  setSortBy,
  isSortAscending,
}) => (
  <table className={cx(styles.table, className)}>
    <thead>
      <tr>
        <th className={styles.heading}>
          <VisuallyHidden>Product name</VisuallyHidden>
        </th>
        <HeaderCellSortable
          columnId={'orderCount'}
          content={'Orders'}
          onClickSort={() => setSortBy('orderCount')}
          isSorted={sortBy === 'orderCount'}
          isSortAscending={isSortAscending}
          className={styles.heading}
          buttonClassName={styles.columnHeaderButton}
          isSortArrowVisible={false}
        />
        <HeaderCellSortable
          columnId={'salesValue'}
          content={'Money Spent'}
          onClickSort={() => setSortBy('salesValue')}
          isSorted={sortBy === 'salesValue'}
          isSortAscending={isSortAscending}
          className={styles.heading}
          buttonClassName={styles.columnHeaderButton}
          isSortArrowVisible={false}
        />
      </tr>
    </thead>
    <tbody>
      {data.map((item) => (
        <RowComponent
          key={item.product_id}
          className={styles.row}
          data={item}
        />
      ))}
    </tbody>
  </table>
);

AnalyticsTable.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array,
  RowComponent: PropTypes.func,
  sortBy: PropTypes.string,
  setSortBy: PropTypes.func,
  isSortAscending: PropTypes.bool,
};

AnalyticsTable.defaultProps = {
  className: '',
  data: [],
  RowComponent: () => {},
  sortBy: 'salesValue',
  setSortBy: () => {},
  isSortAscending: false,
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default AnalyticsTable;
