import { useQuery } from '@tanstack/react-query';

import { OrderStatement } from 'types/order-statement';
import { Shop } from 'types/shops';
import { camelCaseKeys } from 'utilities/objects';

import useApi from './use-api';

type Props = {
  shopId: Shop['shopId'];
};

export const useShopStatementsQuery = ({ shopId }: Props) => {
  const { authenticatedFetch } = useApi();

  return useQuery({
    queryKey: [shopId, 'statements'],
    queryFn: async () => {
      const response = await authenticatedFetch.get(
        `api/management/v1/shops/${shopId}/statements`,
      );

      return camelCaseKeys(response.statements) as OrderStatement[];
    },
  });
};
