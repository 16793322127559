import cx from 'classnames';

import {
  MetricTile,
  MetricTileContent,
  MetricTileHeader,
  MetricTileTitle,
  MetricTileValue,
} from 'components/shared/metric-tiles/metric-tile';
import Suspended from 'components/shared/suspended';

import styles from './styles.module.scss';

type Props = {
  className?: string;
  canCollapse?: boolean;
  contentClassName?: string;
  headerClassName?: string;
  isCurrency: boolean;
  isLoading: boolean;
  maxFontSize?: number;
  roundValue?: boolean;
  title: string;
  titleClassName?: string;
  value: number;
  variant?: 'dashboard' | 'inline';
};

const AnalyticsTile = ({
  className,
  canCollapse = false,
  contentClassName,
  headerClassName,
  isCurrency,
  isLoading,
  maxFontSize,
  roundValue = true,
  title,
  titleClassName,
  value,
  variant = 'dashboard',
}: Props) => {
  const variantTileStyles = cx(
    className,
    variant === 'dashboard' && styles.dashboardTile,
    variant === 'inline' && styles.inlineTile,
    canCollapse && styles.collapse,
  );

  let formattedValue;

  if (isCurrency) {
    if (roundValue) {
      formattedValue = Math.round(value / 100);
    } else {
      formattedValue = value / 100;
    }
  } else {
    if (roundValue) {
      formattedValue = Math.round(value);
    } else {
      formattedValue = value;
    }
  }

  return (
    <MetricTile className={variantTileStyles}>
      <MetricTileContent className={cx(styles.tileContent, contentClassName)}>
        <Suspended isLoading={isLoading}>
          <MetricTileHeader
            headerClassName={cx(styles.tileHeader, headerClassName)}
          >
            <MetricTileTitle className={cx(styles.tileTitle, titleClassName)}>
              {title}
            </MetricTileTitle>
          </MetricTileHeader>
          <MetricTileValue
            className={cx(styles.tileValue)}
            maxFontSize={maxFontSize}
            value={formattedValue}
            type={isCurrency ? 'currency' : 'number'}
          />
        </Suspended>
      </MetricTileContent>
    </MetricTile>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default AnalyticsTile;
