import { AutomaticDiscount } from 'components/discounts/automatic-discount';
import { UnexpectedError } from 'components/shared/error';
import Loading from 'components/shared/loading';
import Page from 'components/shared/page';
import { useCurrentOnlineDiscountId } from 'hooks/discounts';
import { useCurrentShopId, useShopQuery } from 'hooks/shops';

export const AutomaticDiscountPage = () => {
  const shopId = useCurrentShopId();
  const discountId = useCurrentOnlineDiscountId();

  const isNew = discountId === 'new';
  const name = isNew ? 'add-automatic-discount' : 'edit-automatic-discount';
  const title = isNew ? 'Add Discount' : 'Edit Discount';

  const {
    data: shop,
    isError: isShopError,
    isLoading: isShopLoading,
  } = useShopQuery(shopId, { staleTime: Infinity });

  let content;
  if (isShopLoading) {
    content = <Loading />;
  } else if (isShopError) {
    content = <UnexpectedError />;
  } else {
    content = (
      <AutomaticDiscount
        shopDiscountPercent={shop.discountPercent}
        shopId={shop.shopId}
      />
    );
  }

  return (
    <Page name={name} title={title}>
      {content}
    </Page>
  );
};
