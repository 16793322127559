import Header from 'components/shared/header';
import { useShopTraitsContext } from 'hooks/shops/use-shop-traits-context';
import useAnalytics from 'hooks/use-analytics';
import BlogIcon from 'images/operations/operations-blog.svg?react';
import FindWorkersIcon from 'images/operations/operations-find-workers.svg?react';
import PizzeriaSuppliesIcon from 'images/operations/operations-pizzeria-supplies.svg?react';
import * as externalUrls from 'routes/external-urls';
import { ClickedExternalLinkType } from 'types/shared/analytics';

import OperationsCard from './card';

import styles from './styles.module.scss';

type Props = {
  shopId: number;
};

const Operations = ({ shopId }: Props) => {
  const { trackExternalLinkClick, trackProductUpsellLinkClick } =
    useAnalytics();
  const shopTraits = useShopTraitsContext();

  const onLinkClick = (tileName: string) => {
    trackExternalLinkClick({
      linkLabel: 'visit',
      page: `operations ${tileName}`,
      shopId: String(shopId),
      type: ClickedExternalLinkType.CTA,
    });
  };

  const onGoodsLinkClick = () => {
    trackProductUpsellLinkClick({
      shopId: String(shopId),
      product: 'the goods',
      page: 'operations the goods',
      linkLabel: 'shop',
      type: ClickedExternalLinkType.CTA,
      outcome: 'information',
      shopIntegrationAdoption: shopTraits.shopIntegrationAdoption,
    });
  };

  return (
    <>
      <Header
        title="Re-sauces"
        content={[
          `Let us help you with the operations side of the business.
            Here are some valuable resources to help you order supplies, find workers, and get tips.`,
        ]}
      />
      <div className={styles.content}>
        <OperationsCard
          description="Order premium pizza boxes, bags, and other supplies and have them
            shipped directly to your pizzeria."
          header="Pizzeria Supplies"
          Icon={PizzeriaSuppliesIcon}
          onVisitClick={() => onGoodsLinkClick()}
          url={externalUrls.sliceShop}
          label="shop"
        />
        <OperationsCard
          description="Looking to grow your team? Search, find, and hire your next pizzaiolo for your shop through Indeed."
          header="Find Workers"
          Icon={FindWorkersIcon}
          onVisitClick={() => onLinkClick('indeed')}
          url={externalUrls.sliceIndeed}
        />
        <OperationsCard
          description="Check out our blog for industry news, marketing tips, local legends, and all-things pizza."
          header="The Sauce"
          Icon={BlogIcon}
          onVisitClick={() => onLinkClick('blog')}
          url={externalUrls.sliceCommunity}
        />
      </div>
    </>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default Operations;
