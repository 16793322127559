import * as React from "react";
import { forwardRef } from "react";
const SvgChevronRightIcon = ({
  title,
  titleId,
  ...props
}, ref) => {
  return /* @__PURE__ */ React.createElement("svg", { width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", "aria-hidden": "true", ref, "aria-labelledby": titleId, ...props }, title === void 0 ? /* @__PURE__ */ React.createElement("title", { id: titleId }, "Chevron Right Icon") : title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M8.62421 2L7 3.62421L7.14283 3.76703L13.2694 11.9974L6.89331 20.4988L8.62421 22L15.8515 14.7727C16.5869 14.0373 17 13.04 17 12C17 10.96 16.5869 9.96267 15.8515 9.22731L8.62421 2Z", fill: "currentColor" }));
};
const ForwardRef = forwardRef(SvgChevronRightIcon);
export default ForwardRef;
