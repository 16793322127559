import cx from 'classnames';
import moment from 'moment-timezone';

import Button, { ButtonVariant } from 'components/shared/slice-button';
import TooltipWrapper from 'components/shared/tooltips/tooltip-wrapper';
import { useLiveTime } from 'hooks/hours';
import useAnalytics from 'hooks/use-analytics';
import { OrderStatus, OrderStatusLabels, OrderType } from 'types/shop-hours';
import { getPauseTimeRemainingInMinutesAndSeconds } from 'utilities/shop-hours-utils';
import { capitalize } from 'utilities/strings';

import styles from './styles.module.scss';

const escapeId = (id: string) => id.replace(/&/g, 'and').replace(/\s/g, '-');

export type ShopOpeningIndicatorProps = {
  areOptionsDisabled?: boolean;
  className?: string;
  isRegularScheduleOpen?: boolean;
  nextOpenAtDate?: string;
  onClickClose: () => void;
  onClickExtend: () => void;
  onClickOpen: (isOpeningFromPaused: boolean) => void;
  onClickPause: () => void;
  orderStatus: OrderStatus;
  orderType: OrderType;
  pauseEndTime?: string;
  shopId: string;
  shopTimezone: string;
};

const ShopOpeningIndicator = ({
  areOptionsDisabled = false,
  className = '',
  isRegularScheduleOpen = true,
  nextOpenAtDate = '',
  onClickClose,
  onClickExtend,
  onClickOpen,
  onClickPause,
  orderStatus = OrderStatus.Accepting,
  orderType = OrderType.DeliveryAndPickup,
  pauseEndTime,
  shopId,
  shopTimezone,
}: ShopOpeningIndicatorProps) => {
  const liveTime = useLiveTime({
    timezone: shopTimezone,
    enabled: OrderStatus.Paused && isRegularScheduleOpen,
  });

  const {
    trackClickCloseShop,
    trackClickOpenShop,
    trackClickPauseShop,
    trackClickedExtendPause,
  } = useAnalytics();

  const shouldShowOpenButton =
    orderStatus !== OrderStatus.Accepting && isRegularScheduleOpen;

  const shouldShowCloseButton =
    orderStatus !== OrderStatus.Closed && isRegularScheduleOpen;

  const shouldShowPauseButton =
    orderStatus === OrderStatus.Accepting && isRegularScheduleOpen;

  const shouldShowExtendButton =
    orderStatus === OrderStatus.Paused && isRegularScheduleOpen;

  const getInfoText = () => {
    if (orderStatus === OrderStatus.Closed) {
      if (!isRegularScheduleOpen) {
        return (
          <>
            <span>{capitalize(nextOpenAtDate)}</span>
            <br />
            <span>Edit your scheduled hours to start accepting orders</span>
          </>
        );
      }

      return capitalize(nextOpenAtDate);
    }

    if (orderStatus === OrderStatus.Accepting) {
      if (orderType === OrderType.DeliveryAndPickup) {
        return 'Shop is accepting all orders';
      }

      return `Shop accepting orders for ${
        orderType === OrderType.Delivery ? 'delivery' : 'pickup'
      } only`;
    }

    if (orderStatus === OrderStatus.Paused) {
      const endTimeMoment = moment.tz(pauseEndTime, shopTimezone);

      const pauseDuration = endTimeMoment.diff(liveTime, 'minutes');

      return `Paused for ${pauseDuration} min, opens at ${endTimeMoment.format(
        'h:mma',
      )}`;
    }

    return '';
  };

  const isPaused = orderStatus === OrderStatus.Paused;

  const handleClickOpen = () => {
    trackClickOpenShop({
      shopId,
      shippingType: orderType,
      currentStatus: orderStatus,
    });
    onClickOpen?.(isPaused);
  };

  const handleClickClose = () => {
    trackClickCloseShop({
      shopId,
      shippingType: orderType,
      currentStatus: orderStatus,
    });
    onClickClose?.();
  };

  const handleClickPause = () => {
    trackClickPauseShop({
      shopId,
      shippingType: orderType,
    });
    onClickPause?.();
  };

  const handleClickExtend = () => {
    trackClickedExtendPause({
      shopId,
      shippingType: orderType,
      timeRemaining: getPauseTimeRemainingInMinutesAndSeconds(
        moment.tz(pauseEndTime, shopTimezone),
      ),
    });
    onClickExtend?.();
  };

  const orderStatusLabel =
    orderStatus === OrderStatus.Accepting
      ? OrderStatusLabels.Accepting
      : orderStatus === OrderStatus.Paused
        ? OrderStatusLabels.Paused
        : OrderStatusLabels.Closed;

  const shouldDisableExtendPause = () => {
    const endTimeMoment = moment.tz(pauseEndTime, shopTimezone);

    const pauseDuration = endTimeMoment.diff(liveTime, 'minutes');

    return pauseDuration >= 60;
  };

  return (
    <div className={cx(styles.shopStatusContainer, className)}>
      <div
        className={cx(
          styles.statusIndicator,
          orderStatus === OrderStatus.Closed && styles.closed,
          orderStatus === OrderStatus.Paused && styles.paused,
        )}
      >
        <span
          className={styles.orderType}
          data-testid={escapeId(`shop-status-order-type-${orderType}`)}
        >
          {orderType}
        </span>
        <span
          className={styles.orderStatus}
          data-testid={escapeId(
            `shop-status-order-type-${orderType}-status-${orderStatus}`,
          )}
        >
          {orderStatusLabel}
        </span>
      </div>
      <span className={styles.infoText}>{getInfoText()}</span>
      <div className={styles.buttonContainer}>
        {shouldShowOpenButton && (
          <Button
            className={styles.shopStatusButton}
            disabled={areOptionsDisabled}
            onClick={handleClickOpen}
            variant={ButtonVariant.Secondary}
            data-testid={escapeId(`open-${orderType}-from-${orderStatus}`)}
          >
            Open
          </Button>
        )}
        {shouldShowCloseButton && (
          <Button
            className={styles.shopStatusButton}
            disabled={areOptionsDisabled}
            onClick={handleClickClose}
            variant={ButtonVariant.Secondary}
            data-testid={escapeId(`close-${orderType}-from-${orderStatus}`)}
          >
            Close
          </Button>
        )}
        {shouldShowPauseButton && (
          <Button
            data-chameleon-target="Pause Button"
            className={styles.shopStatusButton}
            disabled={areOptionsDisabled}
            onClick={handleClickPause}
            variant={ButtonVariant.Secondary}
            data-testid={escapeId(`pause-${orderType}-from-${orderStatus}`)}
          >
            Pause
          </Button>
        )}
        {shouldShowExtendButton && (
          <TooltipWrapper
            isTooltipEnabled={areOptionsDisabled || shouldDisableExtendPause()}
            targetElement={
              <Button
                className={styles.shopStatusButton}
                aria-disabled={areOptionsDisabled || shouldDisableExtendPause()}
                onClick={() => {
                  if (areOptionsDisabled || shouldDisableExtendPause()) {
                    return;
                  }

                  handleClickExtend();
                }}
                variant={ButtonVariant.Secondary}
                data-testid={escapeId(`extend-pause-${orderType}`)}
              >
                Extend
              </Button>
            }
            tooltipContent={
              <>
                You have reached the maximum pause duration, you can extend this
                pause again when the time remaining is less than 60 minutes
              </>
            }
          />
        )}
      </div>
    </div>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default ShopOpeningIndicator;
