import { useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import {
  ColumnFilter,
  createColumnHelper,
  SortingState,
  VisibilityState,
} from '@tanstack/react-table';

import { Link } from 'crust';

import ContentTile from 'components/shared/content-tile';
import Modal from 'components/shared/slice-modal';
import Table from 'components/shared/table';
import TableAction from 'components/shared/table/action';
import Text from 'components/shared/text';
import VisuallyHidden from 'components/shared/visually-hidden';
import { useDeletePromoCodeMutation } from 'hooks/discounts';
import { usePromoCodesQuery } from 'hooks/discounts/use-promo-codes-query';
import { useTable } from 'hooks/shared';
import * as paths from 'routes/paths';
import { PromoCode } from 'types/discounts';
import { Shop } from 'types/shops';
import {
  getFormattedPromoCodeAmount,
  getFormattedPromoCodeDate,
} from 'utilities/discounts';
import { showUnexpectedErrorToast } from 'utilities/forms';

import styles from './styles.module.scss';

type Props = {
  shopId: Shop['shopId'];
  shopTimezone: Shop['timezoneIdentifier'];
};

export const PromoCodes = ({ shopId, shopTimezone }: Props) => {
  const { data: promoCodes, isLoading: isPromoCodesLoading } =
    usePromoCodesQuery(shopId);
  const { mutate: deletePromoCode } = useDeletePromoCodeMutation();

  const [promoCodeToDelete, setPromoCodeToDelete] = useState<PromoCode>();

  // The Admin API doesn't allow for filtering and we want to hide deactivated
  // promo codes.
  const [columnFilters, setColumnFilters] = useState<ColumnFilter[]>([
    {
      id: 'active',
      value: true,
    },
  ]);

  // Hide the active column since we are just using it for filtering.
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({
    active: false,
  });

  // Sorting by name is more helpful than the default order in Admin.
  const [sorting, setSorting] = useState<SortingState>([
    {
      id: 'tag',
      desc: false,
    },
  ]);

  const columns = useMemo(() => {
    const helper = createColumnHelper<PromoCode>();

    return [
      helper.accessor('tag', {
        header: 'Promo Code',
        cell(ctx) {
          return <Text clamp>{ctx.getValue()}</Text>;
        },
      }),

      // This column is only for filtering (for now).
      helper.accessor('active', {
        header: 'Active',
      }),

      // If this column were sortable, it wouldn't make sense to sort by the raw
      // numbers because they are a mix of money as cents and percents as
      // integers. Instead, we'll use the formatted amount as the base value.
      helper.accessor(
        (row) =>
          getFormattedPromoCodeAmount(
            row.promoFlatAmount,
            row.promoPercentAmount,
            row.category,
          ),
        {
          id: 'amount',
          header: 'Amount',
          enableSorting: false,
        },
      ),

      helper.accessor('dateStart', {
        header: 'Start Date',
        enableSorting: false,
        meta: {
          mobileHeading: 'Start',
        },
        cell(ctx) {
          return getFormattedPromoCodeDate(ctx.getValue(), shopTimezone);
        },
      }),

      helper.accessor('dateEnd', {
        id: 'endAt',
        header: 'Expires On',
        enableSorting: false,
        meta: {
          mobileHeading: 'End',
        },
        cell(ctx) {
          return getFormattedPromoCodeDate(ctx.getValue(), shopTimezone);
        },
      }),

      helper.display({
        id: 'actions',
        enableSorting: false,
        header() {
          return <VisuallyHidden>Actions</VisuallyHidden>;
        },
        cell(ctx) {
          return (
            <>
              <TableAction
                icon="trashCan"
                title={`Delete promo code ${ctx.row.original.tag}`}
                onClick={() =>
                  setPromoCodeToDelete(
                    promoCodes?.find((it) => it.id === ctx.row.original.id),
                  )
                }
              />
            </>
          );
        },
        meta: {
          className: styles.editColumn,
          isActionColumn: true,
        },
      }),
    ];
  }, [promoCodes, shopTimezone]);

  const table = useTable({
    columns,
    data: promoCodes ?? [],
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    onSortingChange: setSorting,
    state: {
      columnFilters,
      columnVisibility,
      sorting,
      isLoading: isPromoCodesLoading,
    },
    chameleonTableTitle: 'Promo codes',
  });

  const handleModalClose = () => {
    setPromoCodeToDelete(undefined);
  };

  const handleModalYes = () => {
    if (promoCodeToDelete) {
      deletePromoCode(
        { shopId, promoCodeId: promoCodeToDelete.id },
        {
          onError: () => {
            showUnexpectedErrorToast();
          },
          onSuccess: () => {
            toast.success('Promo Code successfully deleted');
          },
          onSettled() {
            setPromoCodeToDelete(undefined);
          },
        },
      );
    }
  };

  return (
    <>
      <Modal
        header={`Are you sure you want to delete ${promoCodeToDelete?.tag}?`}
        isOpen={promoCodeToDelete != null}
        onClickNo={handleModalClose}
        onClickYes={handleModalYes}
        onRequestClose={handleModalClose}
        yesButtonText="Delete"
      >
        You are about to delete your Promo Code, this action cannot be undone.
      </Modal>
      <ContentTile
        title="Promo Codes"
        headerContent={
          <Link
            appearance="button"
            href={paths.newPromoCode(shopId)}
            variant="secondary"
          >
            Create
          </Link>
        }
      >
        <Table className={styles.table} table={table} />
      </ContentTile>
    </>
  );
};
