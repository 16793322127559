import * as React from "react";
import { forwardRef } from "react";
const SvgCloseIcon = ({
  title,
  titleId,
  ...props
}, ref) => {
  return /* @__PURE__ */ React.createElement("svg", { width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", "aria-hidden": "true", ref, "aria-labelledby": titleId, ...props }, title === void 0 ? /* @__PURE__ */ React.createElement("title", { id: titleId }, "Close Icon") : title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M4.24072 5.65079L5.65077 4.24074L12 9.68292L18.3492 4.24074L19.7592 5.65079L14.3171 12L19.7592 18.3492L18.3492 19.7593L12 14.3171L5.65077 19.7593L4.24072 18.3492L9.6829 12L4.24072 5.65079Z", fill: "currentColor" }));
};
const ForwardRef = forwardRef(SvgCloseIcon);
export default ForwardRef;
