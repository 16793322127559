import { useEffect } from 'react';

import { Breakpoint } from 'types/site-editor';

import BreakpointButton from '../breakpoint-button';

import styles from './styles.module.scss';

type Props = {
  breakpoint: Breakpoint;
  isDesktopPreviewDisabled: boolean;
  isTabletPreviewDisabled: boolean;
  setBreakpoint: (breakpoint: Breakpoint) => void;
  shopId: string;
  domainName: string | null;
};

const BreakpointButtonGroup = ({
  breakpoint,
  isDesktopPreviewDisabled,
  isTabletPreviewDisabled,
  setBreakpoint,
  shopId,
  domainName,
}: Props) => {
  useEffect(() => {
    if (breakpoint === 'desktop' && isDesktopPreviewDisabled) {
      setBreakpoint(isTabletPreviewDisabled ? 'phone' : 'tablet');
      return;
    }
    if (
      (breakpoint === 'desktop' || breakpoint === 'tablet') &&
      isTabletPreviewDisabled
    ) {
      setBreakpoint('phone');
      return;
    }
  }, [
    breakpoint,
    isDesktopPreviewDisabled,
    isTabletPreviewDisabled,
    setBreakpoint,
  ]);

  const breakpointButtons: Breakpoint[] = ['desktop', 'tablet', 'phone'];

  const previewHandler = (breakpoint: Breakpoint): void => {
    setBreakpoint(breakpoint);
  };

  return (
    <div className={styles.deviceOptions}>
      {breakpointButtons.map((b) => (
        <BreakpointButton
          active={breakpoint === b}
          breakpoint={b}
          disabled={b === 'desktop' && isDesktopPreviewDisabled}
          key={b}
          previewHandler={previewHandler}
          shopId={shopId}
          domainName={domainName}
        />
      ))}
    </div>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default BreakpointButtonGroup;
