import {
  DraggableAttributes,
  DraggableSyntheticListeners,
} from '@dnd-kit/core';
import cx from 'classnames';

import { DragButton } from 'components/shared/icon-button';
import Text from 'components/shared/text';

import styles from './styles.module.scss';

type Props = {
  className?: string;
  draggableAttributes?: DraggableAttributes;
  draggableListeners?: DraggableSyntheticListeners;
  isDragging?: boolean;
  isOverlay?: boolean;
  name: string;
};

const MenuLayoutProduct = ({
  className,
  draggableAttributes,
  draggableListeners,
  isDragging = false,
  isOverlay = false,
  name,
}: Props) => (
  <div
    className={cx(
      styles.product,
      isDragging && styles.dragging,
      isOverlay && styles.overlay,
      className,
    )}
  >
    <DragButton
      className={styles.handle}
      label={`Move ${name}`}
      {...draggableAttributes}
      {...draggableListeners}
    />
    <Text as="span" clamp={2}>
      {name}
    </Text>
  </div>
);

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default MenuLayoutProduct;
