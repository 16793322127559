import Link from 'components/shared/link';
import cloverLogo from 'images/integrations/clover-logo.png';
import { phoneNumbers } from 'utilities/constants';
import { formatPhoneNumber } from 'utilities/strings';

import IntegrationListItem from '../';

import styles from './styles.module.scss';

const CloverIntegrationListItem = () => (
  <IntegrationListItem
    styles={styles}
    disabled
    copyText={
      <>
        <p className={styles.cloverCopy}>
          View all your Slice orders directly on your Clover Point of Sale!
        </p>
        <p className={styles.cloverCopy}>
          To get started with this integration please contact your slice account
          manager at{' '}
          <Link to="mailto:partner@slicelife.com">partner@SliceLife.com</Link>{' '}
          or {formatPhoneNumber(phoneNumbers.accountManagers)} to have us walk
          you through the process.
        </p>
      </>
    }
    imageAltText="clover logo"
    imageSource={cloverLogo}
    provider="clover"
  />
);

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default CloverIntegrationListItem;
