import { CampaignManagerResults } from 'types/campaign-manager';

import Metric from './metric';

import styles from './styles.module.scss';

type Props = {
  hasEnded?: boolean;
  results?: CampaignManagerResults;
};

const Metrics = ({ hasEnded = false, results }: Props) => {
  const total = hasEnded ? 'Total' : '';

  return (
    <div className={styles.metrics}>
      <Metric isCurrency title={`${total} Sales`} value={results?.sales} />
      <Metric title={`${total} Orders`} value={results?.orders} />
      <Metric title={`${total} New Customers`} value={results?.newCustomers} />
      <Metric title={`${total} Ad Views`} value={results?.impressions} />
    </div>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default Metrics;
