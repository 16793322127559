import {
  ComponentPropsWithoutRef,
  ElementRef,
  ForwardedRef,
  forwardRef,
} from 'react';
import { Text } from 'react-aria-components';
import classNames from 'classnames';

import { getCrustClassName } from '../utilities/get-crust-class-name';

import './field-description.css';

export type FieldDescriptionProps = ComponentPropsWithoutRef<'span'>;

export const FieldDescription = forwardRef(function FieldDescription(
  { className, ...props }: FieldDescriptionProps,
  ref: ForwardedRef<ElementRef<typeof Text>>,
) {
  return (
    <Text
      className={classNames(getCrustClassName('field-description'), className)}
      slot="description"
      ref={ref}
      {...props}
    />
  );
});
