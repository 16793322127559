import {
  Control,
  FieldErrors,
  SubmitHandler,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormWatch,
} from 'react-hook-form';
import { z } from 'zod';

import FormFeedback from 'components/shared/form-feedback';
import Input from 'components/shared/input';
import Label from 'components/shared/label';
import { RHFRadio, RHFRadioGroup } from 'components/shared/rhf-radio';
import { RegisterUpsellFormValues } from 'types/register/register-splash';
import { showInvalidSubmitToast } from 'utilities/forms';

import styles from './styles.module.scss';

const posOptions = ['None', 'Square', 'Clover', 'Other'] as const;

const posExpirationOptions = [
  'No Contract',
  '0-3 Months',
  '4-12 Months',
  '12 Months+',
  'Not Sure',
] as const;

const posServiceTypeOptions = [
  'No',
  'Bar Service',
  'Waiter Service',
  'Both',
] as const;

const posNumOfLocationsOptions = ['1', '2', '3+'] as const;

export const registerUpsellFormValidationSchema = z.object({
  posSystem: z.enum(posOptions, {
    invalid_type_error: 'Please select your point of sale system.',
  }),
  posSystemName: z
    .string()
    .min(1, 'Please enter the name of your current POS system.')
    .optional(),
  posExpiration: z
    .enum(posExpirationOptions, {
      invalid_type_error:
        'Please select when your current POS contract expires.',
    })
    .optional(),
  posServiceType: z.enum(posServiceTypeOptions, {
    invalid_type_error: 'Please select your service type.',
  }),
  posNumOfLocations: z.enum(posNumOfLocationsOptions, {
    invalid_type_error: 'Please select your number of locations.',
  }),
});

type Props = {
  control: Control<RegisterUpsellFormValues>;
  errors: FieldErrors<RegisterUpsellFormValues>;
  handleSubmit: UseFormHandleSubmit<RegisterUpsellFormValues>;
  onSubmitSuccess: (formValues: RegisterUpsellFormValues) => void;
  register: UseFormRegister<RegisterUpsellFormValues>;
  watch: UseFormWatch<RegisterUpsellFormValues>;
};

export const ScheduleDemoForm = ({
  control,
  errors,
  handleSubmit,
  onSubmitSuccess,
  register,
  watch,
}: Props) => {
  const selectedPos = watch('posSystem');

  const handleSubmitClick: SubmitHandler<RegisterUpsellFormValues> = (
    values,
  ) => {
    onSubmitSuccess(values);
  };

  return (
    <form
      id="schedule-register-demo-form"
      className={styles.modalForm}
      onSubmit={handleSubmit(handleSubmitClick, showInvalidSubmitToast)}
    >
      <p className={styles.modalHelpText}>
        Tell us a little about your business to help us personalize your demo.
      </p>
      <RHFRadioGroup
        control={control}
        isRequired
        label="What is your current point of sale system?"
        name="posSystem"
        orientation="horizontal"
        variant="card"
      >
        {posOptions.map((option) => (
          <RHFRadio key={option} label={option} value={option} />
        ))}
      </RHFRadioGroup>
      {selectedPos === 'Other' ? (
        <div className={styles.labeledTextInput}>
          <Label htmlFor={`pos-system-name`}>POS System Name:</Label>
          <Input
            id={`pos-system-name`}
            aria-required
            required
            {...register('posSystemName')}
          />
          <FormFeedback>{errors.posSystemName?.message}</FormFeedback>
        </div>
      ) : null}
      {selectedPos && selectedPos !== 'None' ? (
        <RHFRadioGroup
          control={control}
          isRequired
          label="When does your POS contract expire?"
          name="posExpiration"
          orientation="horizontal"
          variant="card"
        >
          {posExpirationOptions.map((option) => (
            <RHFRadio key={option} label={option} value={option} />
          ))}
        </RHFRadioGroup>
      ) : null}
      <RHFRadioGroup
        control={control}
        isRequired
        label="Do you offer bar or waiter service?"
        name="posServiceType"
        orientation="horizontal"
        variant="card"
      >
        {posServiceTypeOptions.map((option) => (
          <RHFRadio key={option} label={option} value={option} />
        ))}
      </RHFRadioGroup>
      <RHFRadioGroup
        control={control}
        isRequired
        label="How many locations do you own/manage?"
        name="posNumOfLocations"
        orientation="horizontal"
        variant="card"
      >
        {posNumOfLocationsOptions.map((option) => (
          <RHFRadio key={option} label={option} value={option} />
        ))}
      </RHFRadioGroup>
    </form>
  );
};
