import { QueryKey, useQuery } from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { RegisterLayout } from 'types/register-layout';
import { camelCaseKeys } from 'utilities/objects';

export const getRegisterLayoutQueryKey = (shopId: string): QueryKey => [
  shopId,
  'registerMenuLayout',
];

export const useRegisterLayoutQuery = (shopId: string) => {
  const { authenticatedFetch } = useApi();

  return useQuery({
    queryKey: getRegisterLayoutQueryKey(shopId),
    queryFn: async () => {
      const response = await authenticatedFetch.get(
        `api/management/v1/ros/shops/${shopId}/menu`,
      );

      return camelCaseKeys(response) as RegisterLayout;
    },
  });
};
