import { formatDate } from 'utilities/date-time';

export const formatTimes = (times) =>
  times.map((time) => ({
    from: formatDate(time.from, 'HH:mm'),
    to: formatDate(time.to, 'HH:mm'),
  }));

export const flattenErrorMessages = (errors) => {
  const unique = new Set();

  if (errors?.hours) {
    for (const error of errors.hours) {
      if (error?.from?.message) {
        unique.add(error.from?.message);
      }

      if (error?.to?.message) {
        unique.add(error.to?.message);
      }
    }
  }

  return Array.from(unique);
};
