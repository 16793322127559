import { PropsWithChildren } from 'react';

import { Button, ButtonGroup } from 'crust';

import ContentTile from 'components/shared/content-tile';

import styles from './styles.module.scss';

type Props = PropsWithChildren<{
  isSubmitting?: boolean;
  onCancel: () => void;
  onSubmit: () => void;
}>;

export const CreateReportForm = ({
  children,
  onCancel: handleCancel,
  onSubmit: handleSubmit,
  isSubmitting = false,
}: Props) => {
  return (
    <ContentTile title={'Create New Report'}>
      <form
        aria-label="Create new report form"
        className={styles.form}
        onSubmit={handleSubmit}
      >
        {children}
        <ButtonGroup autoCollapse>
          <Button
            isDisabled={isSubmitting}
            onPress={handleCancel}
            variant="secondary"
          >
            Cancel
          </Button>
          <Button isDisabled={isSubmitting} type="submit" variant="primary">
            Create
          </Button>
        </ButtonGroup>
      </form>
    </ContentTile>
  );
};
