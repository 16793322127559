import { useState } from 'react';
import { parseAbsolute } from '@internationalized/date';

import { Button } from 'crust';

import Alert from 'components/shared/alert';
import Link from 'components/shared/link';
import useAnalytics from 'hooks/use-analytics';
import {
  CustomerImport,
  CustomerImportErrors,
  CustomerImports,
} from 'types/customers/profile/customer-profile-import';
import {
  CustomerExport,
  CustomerExports,
} from 'types/customers/profile-exports';
import { Shop } from 'types/shops';
import { isFromLast24Hours, isFromLastGivenHours } from 'utilities/date-time';

import { CustomersTile } from './customers';
import { CustomerImportErrorDetailsModal } from './error-details-modal';
import { ExportsTile } from './exports';

import styles from './styles.module.scss';

const hasImportInProgress = (customerImport: CustomerImport | undefined) => {
  if (!customerImport) {
    return false;
  }

  return (
    customerImport.uploadStatus === 'client_upload_success' ||
    customerImport.uploadStatus === 'queued' ||
    customerImport.uploadStatus === 'processing' ||
    customerImport.uploadStatus === 'pending'
  );
};

type ExportAlertBannerProps = {
  latestExport: CustomerExport | undefined;
};

const ExportAlertBanner = ({ latestExport }: ExportAlertBannerProps) => {
  if (!latestExport) {
    return null;
  }

  if (latestExport.status === 'pending') {
    return (
      <Alert variant="info">
        Your export is being processed. Please check back soon.
      </Alert>
    );
  }

  if (
    latestExport.status === 'success' &&
    isFromLast24Hours(parseAbsolute(latestExport.createdAt, 'UTC'))
  ) {
    return (
      <Alert variant="success">
        <span className={styles.downloadText}>
          Your export is ready to download.
          <Link to={latestExport.downloadUrl}>Download</Link>
        </span>
      </Alert>
    );
  }

  return null;
};

type ImportAlertBannerProps = {
  latestImport: CustomerImport | undefined;
  handleViewDetailsPress: () => void;
};

const ImportAlertBanner = ({
  latestImport,
  handleViewDetailsPress,
}: ImportAlertBannerProps) => {
  // The following diagram from the artefact document service will help visualize how the upload status of a customer import is seen: https://github.com/slicelife/artefact-document-service/blob/c8e20acb14f2d1be464b028d271cfe4376231ea5/docs/service/uploads/customer_upload.md#visualsing-the-upload-status
  // Essentially a success state (within the last 24 hours) is a completed successfully state, a failed state is a completed with errors state and other states
  // are considered in progress within the UI.
  if (!latestImport) {
    return null;
  }

  if (hasImportInProgress(latestImport)) {
    return (
      <Alert variant="info">
        Your import is being processed. Please check back soon.
      </Alert>
    );
  }

  if (
    latestImport.uploadStatus === 'success' &&
    isFromLast24Hours(parseAbsolute(latestImport.createdAt, 'UTC'))
  ) {
    return (
      <Alert variant="success">
        {latestImport.uploadMetadata?.customers.total} Customer(s) have been
        successfully imported.
      </Alert>
    );
  }

  if (
    latestImport.uploadStatus === 'failure' &&
    isFromLastGivenHours(parseAbsolute(latestImport.createdAt, 'UTC'), 72)
  ) {
    return (
      <Alert variant="warning">
        <div className={styles.alertWarningContent}>
          <p>
            {
              "There are errors with your CSV file and we couldn't process your import."
            }
          </p>
          <Button
            appearance="link"
            size="small"
            variant="secondary"
            onPress={handleViewDetailsPress}
          >
            View Details
          </Button>
        </div>
      </Alert>
    );
  }

  return null;
};

type Props = {
  customerImportErrors?: CustomerImportErrors;
  exports: CustomerExports;
  imports: CustomerImports;
  isCustomerImportAvailable: boolean;
  isFullRegisterShop: boolean;
  shopId: Shop['shopId'];
  shopTimezone: Shop['timezoneIdentifier'];
};

export function CustomerProfiles({
  customerImportErrors,
  exports,
  imports,
  isCustomerImportAvailable,
  isFullRegisterShop,
  shopId,
  shopTimezone,
}: Props) {
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const { trackViewedCustomerImportErrorDetails } = useAnalytics();

  const latestExport = exports.data.at(0);

  const latestImport = imports.data.at(0);
  const isLatestImportInProgress = hasImportInProgress(latestImport);

  const handleViewImportErrorDetailsPress = () => {
    setIsErrorModalOpen(true);
    trackViewedCustomerImportErrorDetails({ shopId });
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.banners}>
          <ImportAlertBanner
            latestImport={latestImport}
            handleViewDetailsPress={handleViewImportErrorDetailsPress}
          />
          <ExportAlertBanner latestExport={latestExport} />
        </div>
        <CustomersTile
          hasPendingExport={latestExport?.status === 'pending'}
          isCustomerImportAvailable={isCustomerImportAvailable}
          isFullRegisterShop={isFullRegisterShop}
          isLatestImportInProgress={isLatestImportInProgress}
          shopId={shopId}
          shopTimezone={shopTimezone}
        />
        <ExportsTile shopId={shopId} shopTimezone={shopTimezone} />
      </div>
      {customerImportErrors ? (
        <CustomerImportErrorDetailsModal
          customerImportErrors={customerImportErrors}
          isOpen={isErrorModalOpen}
          onClose={() => setIsErrorModalOpen(false)}
        />
      ) : null}
    </>
  );
}
