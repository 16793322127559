import { ReactNode, useEffect, useMemo } from 'react';

import { ButtonContext, Icon, IconButton, LinkContext } from 'crust';

import { useCurrentShopId } from 'hooks/shops';
import useAnalytics from 'hooks/use-analytics';
import useStoredState from 'hooks/use-stored-state';
import { CalloutEventType } from 'types/callout';
import { EXPIRY_IN_14DAYS, getIsStoredDateActive } from 'utilities/salesforce';

import Tile from '../tile/tile';

import styles from './styles.module.scss';

const PRIMARY_CONTEXT_VALUE = {
  appearance: 'link',
  size: 'small',
  variant: 'primary',
} as const;

const SECONDARY_CONTEXT_VALUE = {
  appearance: 'link',
  size: 'small',
  variant: 'secondary',
} as const;

type Props = {
  event: CalloutEventType;
  image: string;
  primary: ReactNode;
  secondary?: ReactNode;
  text: string;
};

const Callout = ({ event, image, primary, secondary, text }: Props) => {
  const shopId = useCurrentShopId();

  const { trackDismissedCallout, trackViewedCallout } = useAnalytics();

  useEffect(() => {
    trackViewedCallout(event);
  }, [event, trackViewedCallout]);

  const [calloutDismissedAt, setCalloutDismissedAt] = useStoredState<string>(
    `callout-${shopId}-dismiss-clicked-at`,
  );

  const handleClickDismiss = () => {
    const today = new Date();
    setCalloutDismissedAt(String(today));
    trackDismissedCallout(event);
  };

  const isDismissalActive = useMemo(() => {
    return getIsStoredDateActive(EXPIRY_IN_14DAYS, calloutDismissedAt);
  }, [calloutDismissedAt]);

  return (
    <>
      {!isDismissalActive && (
        <Tile className={styles.container}>
          <div className={styles.header}>
            <div className={styles.banner}>
              <Icon icon="sparkle" className={styles.sparkleIcon} />
              Recommended for you
            </div>
            <IconButton
              icon="close"
              aria-label="Dismiss callout"
              onPress={handleClickDismiss}
            />
          </div>
          <img alt="callout" className={styles.img} src={image} />
          <div className={styles.text}>{text}</div>
          <div className={styles.footer}>
            <ButtonContext.Provider value={PRIMARY_CONTEXT_VALUE}>
              <LinkContext.Provider value={PRIMARY_CONTEXT_VALUE}>
                {primary}
              </LinkContext.Provider>
            </ButtonContext.Provider>
            {secondary && (
              <ButtonContext.Provider value={SECONDARY_CONTEXT_VALUE}>
                <LinkContext.Provider value={SECONDARY_CONTEXT_VALUE}>
                  {secondary}
                </LinkContext.Provider>
              </ButtonContext.Provider>
            )}
          </div>
        </Tile>
      )}
    </>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default Callout;
