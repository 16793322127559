import { TooltipIconButton } from 'components/shared/tooltips';
import { Last30Days } from 'types/hours-analytics';

import {
  MetricTile,
  MetricTileContent,
  MetricTileHeader,
  MetricTileTitle,
} from '../metric-tile';

import AvailabilityPercentageBar from './bar';

import styles from './styles.module.scss';

type Props = {
  data: Last30Days;
};

const AvailabilityTile = ({ data }: Props) => {
  return (
    <MetricTile
      className={styles.tile}
      dataTestId="hours-analytics-availability-tile"
    >
      <MetricTileContent>
        <MetricTileHeader>
          <MetricTileTitle>Availability</MetricTileTitle>
          {data.availabilityScore != null && (
            <TooltipIconButton label="availability info">
              You availability is {data.shopAvailability}%, which is{' '}
              {data.availabilityScoreRange} amongst local pizzerias.
            </TooltipIconButton>
          )}
        </MetricTileHeader>
        <AvailabilityPercentageBar data={data} />
      </MetricTileContent>
    </MetricTile>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default AvailabilityTile;
