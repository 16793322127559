import { useState } from 'react';
import moment from 'moment-timezone';

import { Radio, RadioGroup } from 'crust';

import Modal from 'components/shared/slice-modal';
import { useLiveTime } from 'hooks/hours';
import useAnalytics from 'hooks/use-analytics';
import {
  Pause,
  PauseDurationOption,
  ShippingTypeLabels,
} from 'types/shop-hours';
import {
  durationOptions,
  getPauseTimeRemainingInMinutesAndSeconds,
} from 'utilities/shop-hours-utils';

import styles from './styles.module.scss';

const defaultDuration = 15;
type DurationOption = {
  disabled: boolean;
} & PauseDurationOption;

type Props = {
  existingPause: Pause;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: ({
    updatedEndTime,
    callback,
  }: {
    updatedEndTime: string;
    callback: () => void;
  }) => void;
  shopId: string;
  shopTimezone: string;
};

const ExtendPauseModal = ({
  existingPause,
  isOpen = false,
  onClose,
  onSubmit,
  shopId,
  shopTimezone,
}: Props) => {
  const { trackSubmitExtendPause } = useAnalytics();

  const [seletedDuration, setSelectedDuration] = useState(15);

  const currentTime = useLiveTime({ timezone: shopTimezone });
  const initialEndTime = moment.tz(existingPause.endTime, shopTimezone);
  const updatedEndTime = initialEndTime.clone().add(seletedDuration, 'minutes');

  const initialTimeRemaining = initialEndTime.diff(currentTime, 'minutes');
  const updatedTimeRemaining = updatedEndTime.diff(currentTime, 'minutes');

  const initiallyOverHourPause = initialTimeRemaining >= 60;
  const acceptingNewOrdersAt = initiallyOverHourPause
    ? initialEndTime.format('h:mma')
    : updatedEndTime.format('h:mma');

  const availableDurationOptions: DurationOption[] = [];

  // If we are already paused for 60 minutes or more, then no duration options are available
  let durationOptionDisabled = initiallyOverHourPause;

  durationOptions.forEach((option) => {
    availableDurationOptions.push({
      ...option,
      disabled: durationOptionDisabled,
    });

    if (!durationOptionDisabled) {
      if (initialTimeRemaining + option.value >= 60) {
        durationOptionDisabled = true;
      }
    }
  });

  const selectedDurationLabel = initiallyOverHourPause
    ? '-'
    : availableDurationOptions
        .find((option) => option.value === seletedDuration)
        ?.label.toLowerCase();

  const newTimeRemainingLabel = initiallyOverHourPause
    ? '-'
    : `${updatedTimeRemaining} minutes`;

  const handleOnClose = () => {
    onClose();
    setSelectedDuration(defaultDuration);
  };

  const handleOnClickSubmit = () => {
    const originalTimeRemainingFormatted =
      getPauseTimeRemainingInMinutesAndSeconds(initialEndTime);
    const newTimeRemainingFormatted =
      getPauseTimeRemainingInMinutesAndSeconds(updatedEndTime);

    trackSubmitExtendPause({
      shopId,
      shippingType:
        ShippingTypeLabels[existingPause.shippingType].toLowerCase(),
      originalTimeRemaining: originalTimeRemainingFormatted,
      addedTime: seletedDuration,
      newTimeReamaining: newTimeRemainingFormatted,
    });
    onSubmit({
      updatedEndTime: updatedEndTime.clone().utc().format(),
      callback: () => setSelectedDuration(defaultDuration),
    });
  };

  return (
    <Modal
      header="Extend Paused Orders"
      isFullScreenMobile
      isOpen={isOpen}
      isYesButtonDisabled={initiallyOverHourPause}
      onClickNo={handleOnClose}
      onClickYes={handleOnClickSubmit}
      onRequestClose={handleOnClose}
      noButtonText="Cancel"
      yesButtonText="Extend Pause"
    >
      <div className={styles.contentWrapper}>
        <div className={styles.modalContentHeader}>
          {`Extend Pause for ${
            ShippingTypeLabels[existingPause.shippingType]
          } Orders`}
        </div>
        <RadioGroup
          className={styles.radios}
          label="Extend pause duration by"
          orientation="horizontal"
          value={initiallyOverHourPause ? undefined : String(seletedDuration)}
          onChange={(value) => setSelectedDuration(Number(value))}
          variant="card"
        >
          {availableDurationOptions.map((option) => (
            <Radio
              isDisabled={option.disabled}
              key={option.value}
              label={option.label}
              value={String(option.value)}
            />
          ))}
        </RadioGroup>
        <div className={styles.extendDetails}>
          <div className={styles.extendDetailsHeader}>Pause Duration</div>
          <div>
            Time Remaining:{' '}
            <strong
              className={styles.bold}
            >{`${initialTimeRemaining} minutes`}</strong>
          </div>
          <div>
            Added Time:{' '}
            <strong className={styles.bold}>{`${
              initiallyOverHourPause ? '-' : selectedDurationLabel
            }`}</strong>
          </div>
          <div>
            New Time Remaining:{' '}
            <strong className={styles.bold}>{newTimeRemainingLabel}</strong>
          </div>
        </div>
        <div className={styles.extendSummary}>
          <div>
            You are extending your pause for{' '}
            <strong
              className={styles.bold}
            >{`${selectedDurationLabel}`}</strong>
          </div>
          <div>
            You will resume accepting orders at{' '}
            <strong className={styles.bold}>{`${acceptingNewOrdersAt}`}</strong>
          </div>
        </div>
      </div>
    </Modal>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default ExtendPauseModal;
