import * as React from "react";
import { forwardRef } from "react";
const SvgClose = ({
  title,
  titleId,
  ...props
}, ref) => {
  return /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 18 17", ref, "aria-labelledby": titleId, ...props }, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { fill: "none", fillRule: "evenodd", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: 1.5, d: "M1.333.777L16.667 15.48m0-14.703L1.333 15.48" }));
};
const ForwardRef = forwardRef(SvgClose);
export default ForwardRef;
