import { RegisterSplash } from 'components/register/register-splash';
import { UnexpectedError } from 'components/shared/error';
import Loading from 'components/shared/loading';
import Page from 'components/shared/page';
import { useCurrentUserQuery } from 'hooks/shared';
import { useCurrentShopId } from 'hooks/shops';

import styles from './styles.module.scss';

export const RegisterSplashPage = () => {
  const shopId = useCurrentShopId();

  const {
    data: user,
    isError: isUserError,
    isLoading: isUserLoading,
  } = useCurrentUserQuery();

  let content;

  if (isUserLoading) {
    content = <Loading />;
  } else if (isUserError) {
    content = <UnexpectedError />;
  } else {
    content = <RegisterSplash shopId={shopId} user={user} />;
  }

  return (
    <Page
      name="viewed_register_splash_page"
      isHeaderShown={false}
      wrapperClassName={styles.pageWrapper}
      contentClassName={styles.pageContent}
    >
      {content}
    </Page>
  );
};
