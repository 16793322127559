import { CalendarDate } from '@internationalized/date';
import { QueryKey, useQuery } from '@tanstack/react-query';
import { ColumnSort } from '@tanstack/react-table';
import queryString from 'query-string';

import { RangeValue } from 'crust';

import useApi from 'hooks/use-api';
import {
  OnlineOrderFilterValue,
  OnlineOrdersRequestParams,
  OnlineOrdersResponse,
  OnlineOrdersSortKey,
} from 'types/financials/online-orders';
import {
  toEndOfDateAbsoluteString,
  toStartOfDateAbsoluteString,
} from 'utilities/date-time';
import { onlineOrdersSortKeys } from 'utilities/financials/orders';
import { camelCaseKeys, snakeCaseKeys } from 'utilities/objects';
import { toSnakeCase } from 'utilities/strings';

type Props = {
  dates: RangeValue<CalendarDate>;
  includeVoidedOrders?: boolean;
  page: number;
  perPage: number;
  shopId: string;
  shopTimezone: string;
  sort: ColumnSort;
  status: OnlineOrderFilterValue;
};

export const getOnlineOrdersQueryKey = (
  shopId: string,
  params: OnlineOrdersRequestParams,
): QueryKey => [shopId, 'online-orders', params];

const parseSort = (sortParams: ColumnSort): string => {
  const { id, desc } = sortParams;
  const direction = desc ? '-' : '+';
  const sortKey = onlineOrdersSortKeys[id as OnlineOrdersSortKey];

  return `${direction}${toSnakeCase(sortKey)}`;
};

const useOrders = ({
  dates,
  includeVoidedOrders = true,
  page,
  perPage,
  shopId,
  shopTimezone,
  sort,
  status,
}: Props) => {
  const { authenticatedFetch } = useApi();

  // If we are requesting all orders we don't send an order_Status param and we
  // can use this included_voided_orders param. However including an
  // order_Status means we cannot use include_voided_orders so we need to check
  // if we are requesting all orders, ie. not sending an order_status
  const params = {
    startDate: toStartOfDateAbsoluteString(dates.start, shopTimezone),
    endDate: toEndOfDateAbsoluteString(dates.end, shopTimezone),
    includeVoidedOrders:
      status === OnlineOrderFilterValue.All ? includeVoidedOrders : undefined,
    orderStatus: status !== OnlineOrderFilterValue.All ? status : undefined,
    page,
    perPage,
    sort: parseSort(sort),
  };

  return useQuery({
    queryKey: getOnlineOrdersQueryKey(shopId, params),
    queryFn: async () => {
      const queryStr = `?${queryString.stringify(snakeCaseKeys(params))}`;

      const response = await authenticatedFetch.get(
        `api/management/v2/shops/${shopId}/orders${queryStr}`,
        {},
        true,
        true,
        true,
      );

      response.body.meta = {
        ...response.body.meta,
        pagination: {
          pages: Number(response.headers.get('X-Total-Pages') ?? 0),
          total: Number(response.headers.get('X-Total') ?? 0),
        },
      };

      return camelCaseKeys(response.body) as OnlineOrdersResponse;
    },
  });
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default useOrders;
