import { TooltipIconButton } from 'components/shared/tooltips';

type Props = {
  text: string;
};

const PermissionsToggleTooltip = ({ text }: Props) => {
  return (
    <TooltipIconButton label="About toggling permissions">
      {text}
    </TooltipIconButton>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default PermissionsToggleTooltip;
