import { ForwardedRef, forwardRef, ReactElement, Ref } from 'react';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom';
import cx from 'classnames';

import styles from './styles.module.scss';

// The innerRef prop is deprecated and href should not be used.
export type LinkProps = {
  // Many links are styled like buttons, so we anticipate other variants here as
  // those "buttons" are migrated to this component.
  variant?: 'primary' | 'text' | 'undecorated' | 'tertiary';
} & RouterLinkProps;

const Link = forwardRef(function Link(
  { className, replace, to, variant = 'text', ...anchorProps }: LinkProps,
  ref: ForwardedRef<HTMLAnchorElement>,
) {
  const resolvedClassName = cx(styles[variant], className);

  // By default, don't leave the app when opening external links. The Link
  // component from React Router doesn't handle external links so we need to
  // render an HTML anchor directly.
  if (
    typeof to === 'string' &&
    (to.startsWith('http') || to.startsWith('mailto') || to.startsWith('tel'))
  ) {
    return (
      // The content is included in anchorProps. We disable no-target-blank as an issue for ios mobile devices
      // opening mailto links was found. See this stackoverflow - https://stackoverflow.com/questions/42839716/ios-tel-and-mailto-links-only-work-with-target-blank
      // eslint-disable-next-line jsx-a11y/anchor-has-content, react/jsx-no-target-blank
      <a
        ref={ref}
        className={resolvedClassName}
        href={to}
        target="_blank"
        rel={to.startsWith('http') ? 'noopener noreferrer' : undefined}
        {...anchorProps}
      />
    );
  }

  return (
    <RouterLink
      ref={ref}
      className={resolvedClassName}
      replace={replace}
      to={to}
      {...anchorProps}
    />
  );
}) as (
  props: LinkProps & {
    ref?: Ref<HTMLAnchorElement>;
  },
) => ReactElement;

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default Link;
