import Suspended from 'components/shared/suspended';
import { Shop } from 'types/shops';
import { calculatePercentDelta } from 'utilities/misc';

import {
  MetricTile,
  MetricTileContent,
  MetricTileDelta,
  MetricTileHeader,
  MetricTileTitle,
  MetricTileValue,
} from '../metric-tile';
import useFetchOrderMetrics from '../use-fetch-order-metrics';

type Props = {
  activeDates: {
    current: {
      startDate: string;
      endDate: string;
    };
    previous: {
      startDate: string;
      endDate: string;
    };
  };
  className?: string;
  shopId: Shop['shopId'];
  shopTimezone: Shop['timezoneIdentifier'];
};

export const AverageOrder = ({
  activeDates,
  className,
  shopId,
  shopTimezone,
}: Props) => {
  const {
    currentOrderSummary,
    previousOrderSummary,
    isLoading: isMetricsLoading,
  } = useFetchOrderMetrics(activeDates, shopId, shopTimezone);

  const isLoading = isMetricsLoading;

  // The average in cents can have fractional cents because we are dividing an
  // integer quantity of cents by an arbitrary integer quantity of orders.
  const currentAverage = currentOrderSummary?.digitalAverageOrderValue ?? 0;

  const delta = calculatePercentDelta(
    currentAverage,
    previousOrderSummary?.digitalAverageOrderValue,
  );

  return (
    <MetricTile className={className}>
      <MetricTileContent>
        <MetricTileHeader>
          <MetricTileTitle>Avg. Order</MetricTileTitle>
        </MetricTileHeader>
        <Suspended isLoading={isLoading}>
          <MetricTileValue type="currency" value={currentAverage / 100} />
          {delta !== 0 && (
            <MetricTileDelta value={delta} maxFractionDigits={0} />
          )}
        </Suspended>
      </MetricTileContent>
    </MetricTile>
  );
};
