import * as React from "react";
import { forwardRef } from "react";
const SvgPhoneIcon = ({
  title,
  titleId,
  ...props
}, ref) => {
  return /* @__PURE__ */ React.createElement("svg", { width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", "aria-hidden": "true", ref, "aria-labelledby": titleId, ...props }, title === void 0 ? /* @__PURE__ */ React.createElement("title", { id: titleId }, "Phone Icon") : title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { d: "M15.933 14.2677C17.7743 11.0785 18.692 7.61145 17.7417 5.06924C17.311 3.9171 16.5149 3.00576 15.2923 2.35879C14.5516 1.97045 13.8021 1.78321 13.1823 1.88695C12.7119 1.95905 12.3248 2.21679 12.1629 2.68303L11.1481 5.7405C10.9904 6.19938 10.9764 6.57431 11.1024 6.89255C11.2479 7.30066 11.6342 7.6219 12.1971 7.94685L13.0994 8.4678C13.2423 8.55033 13.3198 8.66378 13.3666 8.78901C13.4312 8.92456 13.4121 9.06083 13.3922 9.15732C13.2459 9.74069 12.7931 10.8345 12.0915 12.0497C11.3899 13.265 10.7001 14.2122 10.228 14.6172C10.1587 14.6754 10.0455 14.7475 9.90941 14.777C9.7818 14.7917 9.65801 14.7791 9.51082 14.704L8.59532 14.1852C8.02823 13.8677 7.55688 13.6937 7.13492 13.7644C6.79208 13.8217 6.47437 14.0213 6.15583 14.3873L4.04557 16.7632C3.70534 17.1462 3.68034 17.6228 3.85398 18.106C4.06508 18.6895 4.58882 19.2472 5.30396 19.6797C6.49727 20.3981 7.71005 20.5877 8.89681 20.389C11.5736 19.9409 14.0918 17.4568 15.933 14.2677Z", fill: "currentColor" }));
};
const ForwardRef = forwardRef(SvgPhoneIcon);
export default ForwardRef;
