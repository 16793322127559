import AnalyticsTile from 'components/customers/analytics-tile';
import { CustomersAnalytics } from 'types/customers';

import styles from './styles.module.scss';

type Props = {
  customersAnalytics: CustomersAnalytics | undefined;
  isLoading: boolean;
};

const CustomerSegmentsAnalyticsTiles = ({
  customersAnalytics,
  isLoading,
}: Props) => {
  return (
    <div
      className={styles.analyticsContainer}
      data-testid="my-customers-analytics-container"
    >
      <AnalyticsTile
        className={styles.metricTile}
        isCurrency={false}
        isLoading={isLoading}
        title="Number of Customers"
        value={customersAnalytics?.usersCount ?? 0}
      />
      <AnalyticsTile
        className={styles.metricTile}
        isCurrency={false}
        isLoading={isLoading}
        title="Total Order Count"
        value={customersAnalytics?.orderCount ?? 0}
      />
      <AnalyticsTile
        className={styles.metricTile}
        isCurrency={true}
        isLoading={isLoading}
        title="Average Order Value"
        value={customersAnalytics?.averageOrderValue ?? 0}
      />
      <AnalyticsTile
        className={styles.metricTile}
        isCurrency={true}
        isLoading={isLoading}
        title="Total Spend"
        value={customersAnalytics?.orderTotal ?? 0}
      />
    </div>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default CustomerSegmentsAnalyticsTiles;
