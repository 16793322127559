import { ElementRef, ForwardedRef, forwardRef, ReactElement } from 'react';
import {
  FieldPath,
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';
import { Merge } from 'type-fest';

import {
  mergeRefs,
  TextAreaField,
  TextField,
  type TextFieldProps,
} from 'crust';

export type RHFTextFieldProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = Merge<
  Omit<TextFieldProps, 'isInvalid' | 'onChange' | 'onBlur'>,
  Omit<UseControllerProps<TFieldValues, TName>, 'disabled'>
>;

export const RHFTextField = forwardRef(function RHFTextField<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
  {
    control,
    defaultValue,
    isDisabled,
    name,
    rules,
    shouldUnregister,
    ...props
  }: RHFTextFieldProps<TFieldValues, TName>,
  ref: ForwardedRef<ElementRef<typeof TextField>>,
) {
  const { field, fieldState } = useController({
    control,
    defaultValue,
    disabled: isDisabled,
    name,
    rules,
    shouldUnregister,
  });

  return (
    <TextField
      error={fieldState.error?.message}
      isDisabled={field.disabled}
      isInvalid={fieldState.invalid}
      name={field.name}
      onBlur={field.onBlur}
      onChange={field.onChange}
      ref={mergeRefs(ref, field.ref)}
      validationBehavior="aria"
      value={field.value}
      {...props}
    />
  );
}) as <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
  props: RHFTextFieldProps<TFieldValues, TName>,
  ref: ForwardedRef<ElementRef<typeof TextField>>,
) => ReactElement;

export const RHFTextAreaField = forwardRef(function RHFAreaTextField<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
  {
    control,
    defaultValue,
    isDisabled,
    name,
    rules,
    shouldUnregister,
    ...props
  }: RHFTextFieldProps<TFieldValues, TName>,
  ref: ForwardedRef<ElementRef<typeof TextAreaField>>,
) {
  const { field, fieldState } = useController({
    control,
    defaultValue,
    disabled: isDisabled,
    name,
    rules,
    shouldUnregister,
  });

  return (
    <TextAreaField
      error={fieldState.error?.message}
      isDisabled={field.disabled}
      isInvalid={fieldState.invalid}
      name={field.name}
      onBlur={field.onBlur}
      onChange={field.onChange}
      ref={mergeRefs(ref, field.ref)}
      validationBehavior="aria"
      value={field.value}
      {...props}
    />
  );
}) as <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
  props: RHFTextFieldProps<TFieldValues, TName>,
  ref: ForwardedRef<ElementRef<typeof TextAreaField>>,
) => ReactElement;
