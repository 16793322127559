import * as React from "react";
import { forwardRef } from "react";
const SvgEdit = ({
  title,
  titleId,
  ...props
}, ref) => {
  return /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 17 17", ref, "aria-labelledby": titleId, ...props }, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("g", { fill: "none", fillRule: "evenodd", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round" }, /* @__PURE__ */ React.createElement("path", { d: "M4.863 15.45L.553 16.46l1.01-4.31 10.4-10.401a1.335 1.335 0 0 1 1.887 0l1.414 1.414c.52.521.52 1.365 0 1.886L4.863 15.45zM14.794 5.523L11.492 2.22M13.843 6.474l-3.294-3.31M5.03 15.283l-3.3-3.3" })));
};
const ForwardRef = forwardRef(SvgEdit);
export default ForwardRef;
