import { Id, shop } from './shop';

export const marketingBase = (shopId?: Id) => `${shop(shopId)}/marketing`;

export const shopMarketing = (shopId?: Id) =>
  `${marketingBase(shopId)}/marketing-services`;

export const assetLibrary = (shopId?: Id) =>
  `${marketingBase(shopId)}/branding-studio`;

export const campaignManager = (shopId?: Id) =>
  `${marketingBase(shopId)}/digital-ads-report`;

// Discounts
export const automaticDiscounts = (shopId?: Id) =>
  `${marketingBase(shopId)}/discounts`;

export const automaticDiscount = (shopId?: Id, discountId = ':discountId') =>
  `${automaticDiscounts(shopId)}/${discountId}`;

export const promoCodes = (shopId?: Id) =>
  `${automaticDiscounts(shopId)}/promo-codes`;

export const newPromoCode = (shopId?: Id) => `${promoCodes(shopId)}/new`;

export const discountDealsPath = (shopId?: Id) =>
  `${automaticDiscounts(shopId)}/deals`;
