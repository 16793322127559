import * as React from "react";
import { forwardRef } from "react";
const SvgExpand = ({
  title,
  titleId,
  ...props
}, ref) => {
  return /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", viewBox: "0 0 32 32", ref, "aria-labelledby": titleId, ...props }, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("circle", { cx: 16, cy: 16, r: 16, fill: "#fff" }), /* @__PURE__ */ React.createElement("path", { fill: "currentColor", d: "M22 15c.5312 0 1-.4375 1-1v-4c0-.125-.0312-.25-.0938-.375-.0937-.25-.2812-.4375-.5312-.53125C22.25 9.03125 22.125 9 22 9h-4c-.5625 0-1 .46875-1 1 0 .5625.4375 1 1 1h1.5625L16 14.5938 12.4062 11H14c.5312 0 1-.4375 1-1 0-.53125-.4688-1-1-1h-4c-.15625 0-.28125.03125-.40625.09375-.25.09375-.4375.28125-.53125.53125C9 9.75 9 9.875 9 10v4c0 .5625.4375 1 1 1 .5312 0 1-.4375 1-1v-1.5625L14.5625 16 11 19.5938V18c0-.5312-.4688-1-1-1-.5625 0-1 .4688-1 1v4c0 .1562 0 .2812.0625.4062.09375.25.28125.4376.53125.5313.125.0625.25.0625.40625.0625h4c.5312 0 1-.4375 1-1 0-.5312-.4688-1-1-1h-1.5938L16 17.4375 19.5625 21H18c-.5625 0-1 .4688-1 1 0 .5625.4375 1 1 1h4c.125 0 .25 0 .375-.0625.25-.0937.4375-.2813.5312-.5313.0626-.125.0938-.25.0938-.4062v-4c0-.5312-.4688-1-1-1-.5625 0-1 .4688-1 1v1.5938L17.4062 16 21 12.4375V14c0 .5625.4375 1 1 1Z" }));
};
const ForwardRef = forwardRef(SvgExpand);
export default ForwardRef;
