import { useMutation, useQueryClient } from '@tanstack/react-query';

import { getShopQueryKey } from 'hooks/shops';
import useAnalytics from 'hooks/use-analytics';
import useApi from 'hooks/use-api';
import {
  EditOnlineDiscountResponseBody,
  OnlineDiscountFormValues,
} from 'types/discounts';
import { Shop } from 'types/shops';
import { getEditOnlineDiscountRequestBody } from 'utilities/discounts';
import { camelCaseKeys, snakeCaseKeys } from 'utilities/objects';

export const useSaveOnlineDiscountMutation = (
  shopId: Shop['shopId'],
  isNew: boolean,
) => {
  const { authenticatedFetch } = useApi();
  const queryClient = useQueryClient();
  const {
    trackCreateAutomaticDiscount,
    trackDeleteAutomaticDiscount,
    trackEditAutomaticDiscount,
  } = useAnalytics();

  return useMutation({
    mutationFn: async (values: OnlineDiscountFormValues) => {
      const response = await authenticatedFetch.post(
        `api/management/v1/shops/${shopId}/discount_percent`,
        snakeCaseKeys(getEditOnlineDiscountRequestBody(values)),
      );

      return camelCaseKeys(response) as EditOnlineDiscountResponseBody;
    },
    onSuccess: (data) => {
      queryClient.setQueryData<Shop>(getShopQueryKey(shopId), (current) => {
        if (current) {
          return { ...current, discountPercent: data.discountPercent };
        }
      });

      if (isNew) {
        trackCreateAutomaticDiscount({
          shopId: String(shopId),
          discountPercent: data.discountPercent,
        });
      } else if (data.discountPercent == null) {
        trackDeleteAutomaticDiscount({
          shopId: String(shopId),
        });
      } else {
        trackEditAutomaticDiscount({
          shopId: String(shopId),
          discountPercent: data.discountPercent,
        });
      }
    },
  });
};
