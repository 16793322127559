import PropTypes from 'prop-types';

import Link from 'components/shared/link';
import LightbulbIcon from 'images/lightbulb.svg?react';

import styles from './styles.module.scss';

const AnalyticsTileFooter = ({ footerText, link, linkText }) => (
  <div className={styles.footer}>
    <LightbulbIcon className={styles.lightbulb} aria-hidden="true" />
    <div className={styles.content}>
      <div className={styles.text}>{footerText}</div>
      {link && (
        <Link className={styles.link} to={link}>
          {linkText}
        </Link>
      )}
    </div>
  </div>
);

AnalyticsTileFooter.propTypes = {
  footerText: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
  link: PropTypes.string,
  linkText: PropTypes.string,
};

AnalyticsTileFooter.defaultProps = {
  link: '',
  linkText: '',
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default AnalyticsTileFooter;
