import { useNavigate } from 'react-router-dom';

import { Button, Icon } from 'crust';

import ContentTile from 'components/shared/content-tile';
import { useShopQuery } from 'hooks/shops';
import useAnalytics from 'hooks/use-analytics';
import { Shop } from 'types/shops';
import { getShopHasFullRegister } from 'utilities/shops';

import { searchAndClaim } from '../paths';

import styles from './styles.module.scss';

type Props = {
  shopId: Shop['shopId'];
};

export const SearchCTA = ({ shopId }: Props) => {
  const { data: shop, isSuccess: isShopSuccess } = useShopQuery(shopId);
  const isShopRegister =
    (isShopSuccess && getShopHasFullRegister(shop)) ?? false;

  const { trackBusinessProfileClickedSearchLocation } = useAnalytics();

  const navigate = useNavigate();

  return (
    <ContentTile>
      {isShopSuccess ? (
        <>
          <p className={styles.header}>Confirm this is your business</p>
          <p className={styles.subheader}>
            {
              'To finish your Google Business Profile please confirm this is your business'
            }
          </p>
          <div className={styles.banner}>
            <Icon icon="info" size="large" className={styles.icon} />
            <p className={styles.subheader}>
              We couldn’t find any results linked to your Google Business
              Profile. Search for locations based on your Slice details below.
            </p>
          </div>
          <div className={styles.shopInfo}>
            <p>{shop.name}</p>
            <p>{shop.address}</p>
            <p>{`${shop.city}, ${shop.zipcode}`}</p>
            <p>{shop.ownWebsite}</p>
          </div>
          <Button
            variant="primary"
            onPress={() => {
              trackBusinessProfileClickedSearchLocation({
                shopId,
                isRegister: isShopRegister,
                shopAddress: shop.address || '',
              });
              navigate(`../${searchAndClaim}`, { relative: 'path' });
            }}
            className={styles.searchButton}
          >
            Search
          </Button>
        </>
      ) : (
        <div className={styles.header}>
          Something went wrong retrieving your business information. Please try
          again or contact your Partner Success Manager.
        </div>
      )}
    </ContentTile>
  );
};
