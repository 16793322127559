import RegisterDiscount from 'components/register/discount';
import { UnexpectedError } from 'components/shared/error';
import Loading from 'components/shared/loading';
import Page from 'components/shared/page';
import {
  useCurrentRegisterDiscountId,
  useRegisterDiscountQuery,
} from 'hooks/discounts';
import { useCurrentShopId, useShopQuery } from 'hooks/shops';

export const RegisterDiscountPage = () => {
  const shopId = useCurrentShopId();
  const discountId = useCurrentRegisterDiscountId();

  const isNew = discountId === 'new';
  const name = isNew ? 'add-register-discount' : 'edit-register-discount';
  const title = isNew ? 'Add New Register Discount' : 'Edit Register Discount';

  const {
    data: shop,
    isError: isShopError,
    isLoading: isShopLoading,
  } = useShopQuery(shopId);

  const {
    data: discount,
    isError: isDiscountError,
    isLoading: isDiscountLoading,
  } = useRegisterDiscountQuery(shopId, discountId, {
    enabled: !isNew,
    staleTime: Infinity,
  });

  let content;
  if ((!isNew && isDiscountLoading) || isShopLoading) {
    content = <Loading />;
  } else if (isDiscountError || isShopError) {
    content = <UnexpectedError />;
  } else {
    content = (
      <RegisterDiscount
        discount={discount ?? null}
        isRosEnabled={shop.rosEnabled}
        shopId={shopId}
        shopTimezone={shop.timezoneIdentifier}
      />
    );
  }

  return (
    <Page name={name} title={title}>
      {content}
    </Page>
  );
};
