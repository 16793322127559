import { Shop } from 'types/shops';

/*
  See: https://mypizza.atlassian.net/wiki/spaces/~324605453/pages/4509335588/Register+vs.+Non-Register+Shop+Oct.+2023

  A "register" shop means the shop has an iPad with the Slice
  ROS ("restaurant operating system") app, and the register mode
  is fully enabled.

  There are two components from the Shop model used to determine
  whether or not a shop is register enabled:
  - ros_enabled = does the shop have the iPad with the Slice ROS app?
  - register_mode.name = what mode is the app in?

  Examples:
  - ros_enabled is true and register_mode.name is "full" => register enabled
  - ros_enabled is true and register_mode.name is "basic" => not register enabled
  - ros_enabled is false and register_mode.name is "full" => not register enabled
  - ros_enabled is false and register_mode.name is "basic" => not register enabled
*/

export const getShopHasAnyRegister = (shop: Shop): boolean => {
  return shop.rosEnabled;
};

export const getShopHasBasicRegister = (shop: Shop): boolean => {
  return shop.rosEnabled && shop.registerMode.name === 'basic';
};

export const getShopHasFullRegister = (shop: Shop): boolean => {
  return shop.rosEnabled && shop.registerMode.name === 'full';
};
