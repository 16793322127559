import { Icon } from 'crust';

import Button, { ButtonVariant } from 'components/shared/slice-button';
import DownloadIcon from 'images/download-square.svg?react';
import ExpandIcon from 'images/expand.svg?react';
import { AssetLibraryAsset } from 'types/asset-library';
import { isAssetVideo } from 'utilities/asset-library';

import styles from './styles.module.scss';

type Props = {
  asset: AssetLibraryAsset;
  downloadAssetImage: (
    asset: AssetLibraryAsset,
    page: 'Social Media' | 'Asset Modal',
  ) => Promise<void>;
  onClickExpand: () => void;
};

const PostTile = ({ asset, downloadAssetImage, onClickExpand }: Props) => {
  /*
    See imgix docs for info on settings such as the focal point crop settings:
    https://docs.imgix.com/apis/rendering/focalpoint-crop/fp-debug

    See Slack discussion for imgix settings:
    https://mypizza.slack.com/archives/C03FTCW80CS/p1663603848531339?thread_ts=1663602569.750559&cid=C03FTCW80CS

    These settings basically match the imgix settings used by Admin, except with the width and height adjusted
    to match the designs for Owner's Portal.
  */
  const imgixUri = `${
    isAssetVideo(asset) ? asset.thumbnailUri : asset.uri
  }?w=337&h=180&fp-x=0&fp-y=0.5&fp-z=1&fp-debug=false&fit=crop`;

  const maxTitleCharLength = 40;
  const { title } = asset;

  let truncatedTitle = title;

  if (title.length > maxTitleCharLength) {
    const rawTruncatedTitle = title.substring(0, maxTitleCharLength);
    truncatedTitle = `${rawTruncatedTitle.substring(
      0,
      Math.min(maxTitleCharLength, rawTruncatedTitle.lastIndexOf(' ')),
    )}...`;
  }

  const maxDescriptionCharLength = 80;
  const { description } = asset;

  let truncatedDescription = description;

  if (description.length > maxDescriptionCharLength) {
    const rawTruncatedDescription = description.substring(
      0,
      maxDescriptionCharLength,
    );
    truncatedDescription = `${rawTruncatedDescription.substring(
      0,
      Math.min(
        maxDescriptionCharLength,
        rawTruncatedDescription.lastIndexOf(' '),
      ),
    )}...`;
  }

  return (
    <div className={styles.postTile}>
      <div className={styles.imageContainer}>
        <img src={imgixUri} alt={asset.title} className={styles.postImage} />
        <ExpandIcon
          className={styles.expandIcon}
          onClick={() => onClickExpand()}
          data-chameleon-target="Branding Studio Expand Icon"
        />
      </div>
      <div className={styles.bottomContentContainer}>
        <div className={styles.textContent}>
          {isAssetVideo(asset) && (
            <div className={styles.videoDetails}>
              <Icon icon="play" className={styles.playIcon} />
              <span className={styles.duration}> {asset.duration}</span>
            </div>
          )}
          <div className={styles.title}>{truncatedTitle}</div>
          <div className={styles.description}>{truncatedDescription}</div>
        </div>
        <div className={styles.downloadIconContainer}>
          <Button
            onClick={() => downloadAssetImage(asset, 'Social Media')}
            variant={ButtonVariant.Transparent}
          >
            <DownloadIcon className={styles.downloadIcon} />
          </Button>
        </div>
      </div>
    </div>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default PostTile;
