import { ForwardedRef } from 'react';

export const mergeRefs =
  <T>(...refs: ForwardedRef<T>[]): ForwardedRef<T> =>
  (value: T | null) => {
    for (const ref of refs) {
      if (typeof ref === 'function') {
        ref(value);
      } else if (ref) {
        ref.current = value;
      }
    }
  };
