import * as React from "react";
import { forwardRef } from "react";
const SvgMenuIcon = ({
  title,
  titleId,
  ...props
}, ref) => {
  return /* @__PURE__ */ React.createElement("svg", { width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", "aria-hidden": "true", ref, "aria-labelledby": titleId, ...props }, title === void 0 ? /* @__PURE__ */ React.createElement("title", { id: titleId }, "Menu Icon") : title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { d: "M20.0426 3.22659C19.8273 3.01811 19.4555 3.03701 19.2795 3.28344L14.5832 9.51877L12.47 12.2479L10.9045 10.22C11.5895 9.59455 11.7068 8.55213 11.1393 7.79407L7.71497 3.22655C7.5389 2.98013 7.16708 2.92328 6.91267 3.11285C6.65825 3.28339 6.59956 3.64352 6.79529 3.88993L9.61304 7.64251C9.69125 7.7562 9.67173 7.90783 9.55435 8.00264L9.06516 8.34371C8.94778 8.41946 8.79122 8.40056 8.69335 8.28686L5.87559 4.51534C5.69951 4.26892 5.3277 4.21207 5.07329 4.40165C4.819 4.57219 4.76031 4.93231 4.9559 5.17859L7.77366 8.93116C7.85187 9.04486 7.83235 9.19649 7.71497 9.29129L7.22578 9.63237C7.10839 9.70812 6.95184 9.68921 6.85396 9.57552L4.0362 5.82295C3.86013 5.57653 3.48831 5.51968 3.2339 5.70925C2.97948 5.8798 2.92079 6.23992 3.11652 6.48634L6.5213 11.0349C7.10836 11.812 8.20411 12.0205 9.04544 11.5276L10.963 14.1809L7.26476 18.9569C6.81474 19.5255 6.95165 20.3404 7.5387 20.7573C8.12576 21.1743 8.96709 21.0416 9.39758 20.4541L12.4697 16.2277L15.5419 20.4541C15.9724 21.0416 16.7942 21.1743 17.4008 20.7573C17.9879 20.3404 18.1248 19.5255 17.6747 18.9569L13.9569 14.1809L15.2092 12.4562L15.6397 12.7595C16.3833 13.2712 17.4204 13.0816 17.9096 12.3425L18.9467 10.7695C19.8468 9.4049 20.4924 7.92661 20.8839 6.37252C21.1969 5.23544 20.8642 4.04154 20.0424 3.22652L20.0426 3.22659Z", fill: "currentColor" }));
};
const ForwardRef = forwardRef(SvgMenuIcon);
export default ForwardRef;
