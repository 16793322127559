import { Pie } from '@nivo/pie';
import PropTypes from 'prop-types';

import Legend from 'components/shared/charts/legend';
import EmptyPieIcon from 'images/empty-graphs/pie.svg?react';

import styles from './styles.module.scss';

const PieChart = ({
  data,
  isEmpty,
  chartOnClick,
  onMouseEnter,
  legendTitleClassName,
  legendOnClick,
}) => {
  const getColor = (bar) => data.find((item) => bar.id === item.id).color;

  return (
    <div className={styles.content}>
      {isEmpty ? (
        <>
          <EmptyPieIcon className={styles.pie} aria-hidden="true" />
          <div className={styles.emptyText}>
            No results yet! Check back soon
          </div>
        </>
      ) : (
        <>
          <Pie
            className={styles.pie}
            colors={getColor}
            height={160}
            width={160}
            data={data}
            innerRadius={0.67}
            motionStiffness={90}
            motionDamping={15}
            enableSliceLabels={false}
            enableArcLabels={false}
            enableArcLinkLabels={false}
            onClick={chartOnClick}
            onMouseEnter={onMouseEnter}
          />
          <Legend
            content={data}
            legendTitleClassName={legendTitleClassName}
            onClick={legendOnClick}
          />
        </>
      )}
    </div>
  );
};

PieChart.propTypes = {
  data: PropTypes.array,
  isEmpty: PropTypes.bool,
  chartOnClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  legendTitleClassName: PropTypes.string,
  legendOnClick: PropTypes.func,
};

PieChart.defaultProps = {
  data: [],
  isEmpty: false,
  chartOnClick: () => {},
  onMouseEnter: () => {},
  legendTitleClassName: '',
  legendOnClick: () => {},
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default PieChart;
