import { useMemo } from 'react';
import { Menu, MenuButton, MenuItem } from '@szhsin/react-menu';

import CalendarIcon from 'images/calendarEvent.svg?react';
import CaretIcon from 'images/caret.svg?react';
import { BrandManagerDateRange } from 'types/brand-manager/date-picker';
import { getBrandManagerDatePickerOptions } from 'utilities/brand-manager';

import styles from './styles.module.scss';

type Props = {
  dateRange: BrandManagerDateRange;
  setDateRange: (dateRange: BrandManagerDateRange) => void;
};

const DateRangeFilter = ({ dateRange, setDateRange }: Props) => {
  const options = useMemo(() => getBrandManagerDatePickerOptions(), []);

  const label = options.find((option) => option.value === dateRange)?.label;

  return (
    <div className={styles.dateFilterContainer}>
      <Menu
        menuButton={({ open }) => (
          <MenuButton className={styles.menuButton}>
            <CalendarIcon className={styles.calendarIcon} />
            <span className={styles.menuButtonText}>{label}</span>
            <CaretIcon className={open ? styles.caretUp : styles.caretDown} />
          </MenuButton>
        )}
      >
        {options.map((option) => (
          <MenuItem
            key={option.value}
            onClick={() => setDateRange(option.value)}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default DateRangeFilter;
