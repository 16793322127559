import * as React from "react";
import { forwardRef } from "react";
const SvgFavoriteStar = ({
  title,
  titleId,
  ...props
}, ref) => {
  return /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", fill: "none", viewBox: "0 0 25 24", ref, "aria-labelledby": titleId, ...props }, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { stroke: "currentColor", strokeLinejoin: "round", strokeWidth: 2, d: "m12.5 3 2.4334 5.65067 6.1261.56818-4.6221 4.06045 1.3527 6.0019L12.5 16.14l-5.29007 3.1412 1.3527-6.0019-4.62214-4.06045 6.12611-.56818L12.5 3Z" }));
};
const ForwardRef = forwardRef(SvgFavoriteStar);
export default ForwardRef;
