import cx from 'classnames';

import styles from './styles.module.scss';

type ToggleOption = {
  label: string;
  value: string;
};

type Props = {
  className?: string;
  disabled?: boolean;
  id: string;
  name: string;
  onChange: (newValue: string) => void;
  selectedValue: string;
  toggleOptionClassName?: string;
  toggleOptions: ToggleOption[];
};

const RadioToggleButton = ({
  className,
  disabled = false,
  id,
  onChange,
  name,
  selectedValue,
  toggleOptions,
}: Props) => {
  const getInputId = (optionValue: string) => {
    return `${id}-${optionValue}`;
  };

  const handleChange = (newValue: string) => {
    onChange(newValue);
  };

  return (
    <div className={cx(styles.container, className)}>
      {toggleOptions.map((option) => (
        <label key={getInputId(option.value)} className={styles.label}>
          <input
            className={styles.input}
            checked={selectedValue === option.value}
            disabled={disabled}
            id={getInputId(option.value)}
            name={name}
            type="radio"
            onChange={() => handleChange(option.value)}
          />
          <span className={styles.text}>{option.label}</span>
        </label>
      ))}
    </div>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default RadioToggleButton;
