import {
  averageIndicatorColors,
  scoreIndicators,
  scoreToXPosition,
  shopIndicatorColors,
} from '../svg-settings';

type Props = {
  score: number;
  isAverageScore?: boolean;
};

const ScoreIndicator = ({ score, isAverageScore = false }: Props) => (
  <svg
    aria-hidden="true"
    x={scoreToXPosition(score)}
    width={scoreIndicators.width}
    height={scoreIndicators.height}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 16 18"
  >
    <path
      fill={
        isAverageScore ? averageIndicatorColors.fill : shopIndicatorColors.fill
      }
      fillRule="evenodd"
      stroke={
        isAverageScore
          ? averageIndicatorColors.stroke
          : shopIndicatorColors.stroke
      }
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M7.49965 16.5654L14 3.96436S11.8328 1 7.49965 1C3.16725 1 1 3.96436 1 3.96436L7.49965 16.5654z"
      clipRule="evenodd"
    />
    <path
      stroke={
        isAverageScore
          ? averageIndicatorColors.stroke
          : shopIndicatorColors.stroke
      }
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M13.1613 5.62757c-.7423-1.03299-2.6805-2.52412-5.48279-2.52412-2.72738 0-4.6362 1.37368-5.42045 2.43877"
    />
  </svg>
);

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default ScoreIndicator;
