import { ResponsiveLine } from '@nivo/line';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';

import { useWindowSize } from 'hooks/use-window-size';
import { range } from 'utilities/lists';

import { ChartTooltip } from './chart-tooltip';
import {
  axisBottom,
  axisLeft,
  data,
  maxDayLabels,
  maxYAxisLabels,
  xScale,
  yScale,
  yScaleFactor,
} from './config';
import { PointSymbol } from './point-symbol';

import styles from './styles.module.scss';

const LineChart = ({
  dataSetIdentifier,
  yAxisTooltipLabel,
  startDate,
  endDate,
  xyValues,
  colors,
}) => {
  const { isMobile } = useWindowSize(0);

  // Calculate number of days in range and the max y-axis value
  const numDays = endDate.diff(startDate, 'days');
  const yValues = xyValues.map((xy) => xy.y);
  const maxY = Math.trunc(Math.max(...yValues) * yScaleFactor);

  // Determine number of days between x-axis labels, using max labels settings (to prevent label overlap)
  const currentMaxDayLabels = isMobile
    ? maxDayLabels.mobile
    : maxDayLabels.desktop;
  const daysBetweenLabels =
    numDays <= currentMaxDayLabels
      ? 1
      : Math.trunc(numDays / currentMaxDayLabels);

  // Determine number of ticks between y-axis labels, using max labels settings (to prevent label overlap)
  const currentMaxYAxisLabels = isMobile
    ? maxYAxisLabels.mobile
    : maxYAxisLabels.desktop;
  const ticksBetweenYAxisLabels =
    maxY <= currentMaxYAxisLabels
      ? 1
      : Math.trunc(maxY / currentMaxYAxisLabels);

  const xLabels = [],
    formattedXYValues = [];
  for (let i = 0; i <= numDays; i++) {
    const date = moment(startDate).add(i, 'days').format('YYYY-MM-DD');

    // Format data for the chart.
    // We want the data to have an x and y pair for every date in the range,
    // with zeros for dates with no data.
    formattedXYValues.push({
      x: i,
      y: xyValues.find((xy) => xy.x === date)?.y ?? 0,
    });

    // We need to tell the nivo chart what the x-axis labels should be
    // for each data point. The "days between labels" will hide labels
    // to prevent label overlap as needed.
    xLabels.push(
      i % daysBetweenLabels === 0
        ? moment(startDate).add(i, 'days').format('M-D')
        : '',
    );
  }

  const xTicks = range(0, numDays + 1, daysBetweenLabels);
  const yTicks = range(0, maxY + 1, ticksBetweenYAxisLabels);

  const bottomAxisData = axisBottom(xLabels, xTicks);
  const xAxisTickIndexes = bottomAxisData.tickValues;

  return (
    <div className={styles.wrapper}>
      <ResponsiveLine
        data={data(dataSetIdentifier, startDate, endDate, formattedXYValues)}
        curve="monotoneX"
        xScale={xScale(numDays)}
        axisBottom={bottomAxisData}
        gridXValues={xTicks}
        yScale={yScale(maxY)}
        axisLeft={axisLeft(yTicks, ticksBetweenYAxisLabels)}
        gridYValues={yTicks}
        colors={colors}
        useMesh={true}
        pointColor={{ from: 'color' }}
        pointSize={isMobile ? 5 : 8}
        margin={{ top: 5, right: 30, bottom: 30, left: 30 }}
        pointSymbol={(props) => (
          <PointSymbol
            isMobile={isMobile}
            pointIndexesToShow={xAxisTickIndexes}
            pointSymbolProps={props}
          />
        )}
        tooltip={(props) => (
          <ChartTooltip
            isMobile={isMobile}
            pointIndexesToShow={xAxisTickIndexes}
            pointTooltipProps={props}
            yAxisTooltipLabel={yAxisTooltipLabel}
          />
        )}
      />
    </div>
  );
};

LineChart.propTypes = {
  dataSetIdentifier: PropTypes.string.isRequired,
  yAxisTooltipLabel: PropTypes.string.isRequired,
  colors: PropTypes.array.isRequired,
  xyValues: PropTypes.arrayOf(
    PropTypes.shape({
      x: PropTypes.string,
      y: PropTypes.number,
    }),
  ).isRequired,
  startDate: PropTypes.object.isRequired,
  endDate: PropTypes.object.isRequired,
};

LineChart.defaultProps = {};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default LineChart;
