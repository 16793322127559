import cx from 'classnames';
import PropTypes from 'prop-types';

import EmptyAnalyticsIcon from 'images/empty-graphs/default.svg?react';

import styles from './styles.module.scss';

const Placeholder = ({ className, Icon, text }) => (
  <div className={cx(styles.placeholder, className)}>
    <Icon className={styles.placeholderIcon} />
    <div className={styles.placeholderText}>{text}</div>
  </div>
);

Placeholder.propTypes = {
  className: PropTypes.string,
  // When running this is an object. When running the tests this is a function, and causes the tests to fail if not allowed to also be a function.
  // Fixing as part of this ticket - https://app.shortcut.com/slicelife/story/461976/fix-console-warning-in-dev-and-qa-environments-around-invalid-icon-props-throughout-the-app
  // but we may need further investigation into why the tests expect a func and the running code is a n object.
  Icon: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  text: PropTypes.string,
};

Placeholder.defaultProps = {
  className: '',
  Icon: EmptyAnalyticsIcon,
  text: 'No results yet. Check back soon!',
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default Placeholder;
