import { useEffect, useMemo, useState } from 'react';
import { useMatch } from 'react-router-dom';
import {
  CalendarDate,
  DateValue,
  now,
  parseAbsolute,
  parseDate,
  today,
} from '@internationalized/date';
import { z } from 'zod';

import {
  PresetDateRange,
  PresetRangePicker,
  PresetRangeState,
  PresetRangeValue,
  useDateFormatter,
} from 'crust';

import { AchEnrollmentBanner } from 'components/shared/ach-enrollment-banner';
import ContentTile from 'components/shared/content-tile';
import NavigationTabs from 'components/shared/navigation-tabs';
import { useAchOnboardingLink } from 'hooks/ach-enrollment-banner/use-ach-enrollment-link';
import useAnalytics from 'hooks/use-analytics';
import { getFinancialsOrdersPath } from 'routes/paths/financials';
import { isFromLast24Hours } from 'utilities/date-time';
import { onlineMetricIds } from 'utilities/financials/orders';
import { getDefaultDateRangePresets } from 'utilities/shared/date-range-presets';

import OnlineOrderMetrics from './online-orders/metrics';
import OnlineOrdersTable from './online-orders/table';
import RegisterMetrics from './register-orders/metrics';
import RegisterOrdersTable from './register-orders/table';

import styles from './styles.module.scss';

const localStorageId = 'digital-orders';

const getStoredValue = () => {
  const item = localStorage.getItem(localStorageId);

  if (item) {
    try {
      return JSON.parse(item);
    } catch (error) {
      // Do nothing.
    }
  }
};

const setStoredValue = (dates: unknown) => {
  try {
    localStorage.setItem(
      localStorageId,
      JSON.stringify({
        lastUpdatedAt: now('UTC').toAbsoluteString(),
        dates,
      }),
    );
  } catch {
    // Do nothing.
  }
};

type SchemaContext = {
  maxValue: CalendarDate;
  presets: ReturnType<typeof getDefaultDateRangePresets>;
};

const getSchema = ({ maxValue, presets }: SchemaContext) => {
  const date = z
    .string()
    .date()
    .transform((it) => parseDate(it));
  return z.object({
    lastUpdatedAt: z
      .string()
      .datetime()
      .transform((it) => parseAbsolute(it, 'UTC'))
      .refine((it) => isFromLast24Hours(it)),
    dates: z
      .object({
        id: z.string(),
        label: z.string(),
        start: date,
        end: date,
      })
      .transform((value, ctx) => {
        const id = value.id;
        const preset = presets.find((it) => it.id === id);

        if (preset) {
          return preset;
        }

        if (
          value.end.compare(value.start) >= 0 &&
          value.end.compare(value.start) < 32 &&
          value.end.compare(maxValue) <= 0
        ) {
          return {
            id: 'custom',
            label: 'Custom',
            start: value.start,
            end: value.end,
          } as const;
        }

        // The message is unimportant here.
        ctx.addIssue({ code: z.ZodIssueCode.custom });

        return z.NEVER;
      }),
  });
};

type DateRangeFilterProps<T extends DateValue, S extends string> = {
  defaultValue: PresetRangeValue<T, S>;
  maxValue: CalendarDate;
  onChange: (value: PresetRangeValue<T, S>) => void;
  presets: readonly PresetDateRange<T, S>[];
  shopId: string;
  shopTimezone: string;
};

const DateRangeFilter = <T extends DateValue, S extends string>({
  defaultValue,
  maxValue,
  onChange,
  presets,
  shopId,
  shopTimezone,
}: DateRangeFilterProps<T, S>) => {
  const { trackDigitalOrdersDatePicker } = useAnalytics();

  const [dates, setDates] =
    useState<PresetRangeState<typeof presets>>(defaultValue);

  const dateFormatter = useDateFormatter({
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    timeZone: shopTimezone,
  });

  const error = useMemo(() => {
    if (dates == null) {
      return 'Please choose start and end dates.';
    }

    if (dates.end.compare(maxValue) > 0) {
      const formatted = dateFormatter.format(maxValue.toDate(shopTimezone));
      return `Please choose an end date of ${formatted} or earlier.`;
    }

    if (dates.end.compare(dates.start) < 0) {
      return 'Please choose an end date that is after the start date.';
    }

    if (dates.end.compare(dates.start) > 30) {
      return 'Please select a maximum of 31 days.';
    }
  }, [dateFormatter, dates, maxValue, shopTimezone]);

  useEffect(() => {
    if (dates != null && error == null) {
      onChange(dates);
      trackDigitalOrdersDatePicker({
        shopId,
        startDate: dates.start.toString(),
        endDate: dates.end.toString(),
        label: dates.label.toLowerCase(),
      });
    }
  }, [dates, error, onChange, shopId, trackDigitalOrdersDatePicker]);

  return (
    <PresetRangePicker
      className={styles.ordersDatePicker}
      error={error}
      isInvalid={error != null}
      aria-label="orders date range"
      maxValue={maxValue}
      onChange={setDates}
      presets={presets}
      validationBehavior="aria"
      value={dates}
    />
  );
};

const getDateContext = (timezone: string) =>
  ({
    timezone,
    maxValue: today(timezone),
    presets: getDefaultDateRangePresets(timezone),
  }) as const;

type Props = {
  rosEnabled: boolean;
  isSliceOsMode: boolean;
  shopId: string;
  shopTimezone: string;
};

export const Orders = ({
  rosEnabled,
  isSliceOsMode,
  shopId,
  shopTimezone,
}: Props) => {
  const { data: achOnboardingLink } = useAchOnboardingLink(Number(shopId));

  // https://github.com/slicelife/owners-web/pull/4139#discussion_r1576826416
  const [dateContext, setDateContext] = useState(() =>
    getDateContext(shopTimezone),
  );

  useEffect(() => {
    if (shopTimezone !== dateContext.timezone) {
      setDateContext(getDateContext(shopTimezone));
    }
  }, [dateContext.timezone, shopTimezone]);

  const { presets, maxValue } = dateContext;

  const [dates, setDates] = useState<
    NonNullable<PresetRangeState<typeof presets>>
  >(() => {
    const stored = getStoredValue();

    if (stored) {
      const schema = getSchema({ maxValue, presets });
      const result = schema.safeParse(stored);

      if (result.success) {
        return result.data.dates;
      }
    }

    return presets[2];
  });

  useEffect(() => {
    setStoredValue({
      ...dates,
      start: dates.start.toString(),
      end: dates.end.toString(),
    });
  }, [dates]);

  const digitalOrdersPath = getFinancialsOrdersPath(shopId, 'online');
  const registerOrdersPath = getFinancialsOrdersPath(shopId, 'register');

  const digitalOrdersMatch = useMatch(digitalOrdersPath);
  const registerOrdersMatch = useMatch(registerOrdersPath);

  return (
    <>
      {achOnboardingLink?.showOnboardingLink && (
        <AchEnrollmentBanner
          className={styles.achEnrollmentBanner}
          page="orders"
          redirectUrl={achOnboardingLink.redirectUrl}
        />
      )}
      <DateRangeFilter
        defaultValue={dates}
        maxValue={maxValue}
        onChange={setDates}
        presets={presets}
        shopId={shopId}
        shopTimezone={shopTimezone}
      />
      <NavigationTabs className={styles.ordersSubNav}>
        <NavigationTabs.Link to={digitalOrdersPath}>Online</NavigationTabs.Link>
        {rosEnabled && !isSliceOsMode && (
          <NavigationTabs.Link to={registerOrdersPath}>
            Register
          </NavigationTabs.Link>
        )}
      </NavigationTabs>
      {digitalOrdersMatch && (
        <>
          <OnlineOrderMetrics
            dates={dates}
            metricIds={onlineMetricIds}
            shopId={shopId}
            shopTimezone={shopTimezone}
          />
          <ContentTile>
            <OnlineOrdersTable
              dates={dates}
              shopId={shopId}
              shopTimezone={shopTimezone}
            />
          </ContentTile>
        </>
      )}
      {registerOrdersMatch && (
        <>
          <RegisterMetrics
            dates={dates}
            shopId={shopId}
            shopTimezone={shopTimezone}
          />
          <ContentTile>
            <RegisterOrdersTable
              dates={dates}
              shopId={shopId}
              shopTimezone={shopTimezone}
            />
          </ContentTile>
        </>
      )}
    </>
  );
};
