import { Fragment } from 'react';
import { UseFormRegister } from 'react-hook-form';

import VisuallyHidden from 'components/shared/visually-hidden';
import { ConfigOption, SiteEditorFormValues } from 'types/site-editor';
import { colorOptions } from 'utilities/site-editor';

import styles from './styles.module.scss';

type Props = {
  register: UseFormRegister<SiteEditorFormValues>;
};

const ColorPicker = ({ register }: Props) => {
  const getInputId = (color: ConfigOption) => {
    return `site-editor-color-picker-${color.name}`;
  };

  return (
    <div>
      <div className={styles.helpText}>
        Update the order button, header and footer section by picking a color
        option.
      </div>
      <div className={styles.colorOptionsContainer}>
        {colorOptions.map((color) => (
          <Fragment key={getInputId(color)}>
            <input
              className={styles.colorOption}
              id={getInputId(color)}
              style={{
                backgroundColor: `#${color.hexValue}`,
              }}
              type="radio"
              {...register('colorCode')}
              value={color.value}
            />
            <VisuallyHidden>
              <label htmlFor={getInputId(color)}>{color.label}</label>
            </VisuallyHidden>
          </Fragment>
        ))}
      </div>
    </div>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default ColorPicker;
