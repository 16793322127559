import cx from 'classnames';

import { Icon } from 'crust';

import ContentTile from 'components/shared/content-tile';
import IpadFrame from 'images/register/register-splash/ipad-frame.jpg';

import styles from './styles.module.scss';

export const BenefitsSection = () => {
  return (
    <div className={styles.container}>
      <ContentTile
        className={cx(styles.darkContentTile, styles.ipadContentTile)}
      >
        <div className={styles.wrapper}>
          <div className={styles.title}>Easy to use</div>
          <div className={styles.text}>
            With an intuitive iPad-based interface, Slice Register is simple for
            staff to learn and master.
          </div>
        </div>
        <img alt="ipad-frame" src={IpadFrame} />
      </ContentTile>
      <div className={styles.right}>
        <ContentTile
          className={cx(styles.darkContentTile, styles.benefitsContentTile)}
        >
          <Icon icon="money" className={styles.icon} size="large" />
          <div className={styles.wrapper}>
            <div className={styles.title}>Save time and money</div>
            <div className={styles.text}>
              Slice Register reduces staffing stress by digitizing tickets. This
              saves time entering orders and improves order accuracy.
            </div>
          </div>
        </ContentTile>
        <ContentTile
          className={cx(styles.darkContentTile, styles.benefitsContentTile)}
        >
          <Icon icon="bubble" className={styles.icon} size="large" />
          <div className={styles.wrapper}>
            <div className={styles.title}>24 / 7 support</div>
            <div className={styles.text}>
              Our best-in-class customer service is available 24/7. If you ever
              have any questions, a member of our team will help.
            </div>
          </div>
        </ContentTile>
      </div>
    </div>
  );
};
