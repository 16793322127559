export enum EventType {
  Close = 'closing',
  ModifiedHours = 'modified hours',
  Pause = 'pausing',
  ScheduledClosing = 'scheduled closing',
}

export enum ShippingType {
  Delivery = 'delivery',
  DeliveryAndPickup = 'delivery_and_pickup',
  Pickup = 'pickup',
}

export type ShopStatusHistory = {
  endTime: string; // Format example: "2023-08-28T09:18:21.000Z"
  eventType: EventType;
  lengthInMinutes: number | null;
  reason: string;
  shippingType: ShippingType;
  startTime: string;
};

export type GetShopStatusHistoryRequestParams = {
  page: number;
  perPage: number;
};

export type GetShopStatusHistoryResponseMeta = {
  total: number;
  page: number;
  perPage: number;
  maxPerPage: number;
};

export type GetShopStatusHistoryResponse = {
  data: ShopStatusHistory[];
  meta: GetShopStatusHistoryResponseMeta;
};
