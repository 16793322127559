import {
  Dialog as AriaDialog,
  DialogProps as AriaDialogProps,
} from 'react-aria-components';
import classNames from 'classnames';

import { getCrustClassName } from '../utilities/get-crust-class-name';

import './dialog.css';

export type DialogProps = AriaDialogProps;

export const Dialog = ({ children, className, ...props }: DialogProps) => (
  <AriaDialog
    className={classNames(getCrustClassName('dialog'), className)}
    {...props}
  >
    {children}
  </AriaDialog>
);
