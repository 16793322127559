import { getFormattedDateRange } from 'utilities/hours-analytics';

import styles from './styles.module.scss';

type Props = {
  regionAverageAvailability: number;
  shopAvailability: number;
  weekEndDate: string;
};

export const Tooltip = ({
  regionAverageAvailability,
  shopAvailability,
  weekEndDate,
}: Props) => {
  const formattedDateRange = getFormattedDateRange(weekEndDate);

  return (
    <div className={styles.tooltip}>
      <div className={styles.dateRange}>{formattedDateRange}</div>
      <div className={styles.entry}>
        <div>{shopAvailability.toFixed(1)}%</div>
        <div>Your Shop</div>
      </div>
      <div className={styles.entry}>
        <div>{regionAverageAvailability.toFixed(1)}%</div>
        <div>Local Avg</div>
      </div>
    </div>
  );
};
