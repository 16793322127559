import { ElementRef, ForwardedRef, forwardRef } from 'react';
import {
  NumberField as AriaNumberField,
  NumberFieldProps as AriaNumberFieldProps,
} from 'react-aria-components';
import type { Merge } from 'type-fest';

import { FieldChildren, FieldProps, useField } from '../field/field';
import { Input } from '../input/input';

import './number-field.css';

export type NumberFieldProps = Merge<
  AriaNumberFieldProps,
  FieldProps & {
    prefix?: string;
    suffix?: string;
  }
>;

export const NumberField = forwardRef(function NumberField(
  {
    className,
    label,
    description,
    error,
    prefix,
    suffix,
    ...props
  }: NumberFieldProps,
  ref: ForwardedRef<ElementRef<typeof AriaNumberField>>,
) {
  const { getFieldClassName, fieldProps, fieldChildrenProps } = useField({
    className,
    description,
    error,
    label,
    block: 'number-field',
  });

  const isAffixed = !!prefix || !!suffix;

  return (
    <AriaNumberField ref={ref} {...fieldProps} {...props}>
      <FieldChildren {...fieldChildrenProps}>
        {isAffixed ? (
          <div className={getFieldClassName('affixer')}>
            {prefix && (
              <span className={getFieldClassName('affix')}>{prefix}</span>
            )}
            <Input className={getFieldClassName('input', ['affixed'])} />
            {suffix && (
              <span className={getFieldClassName('affix')}>{suffix}</span>
            )}
          </div>
        ) : (
          <Input className={getFieldClassName('input')} />
        )}
      </FieldChildren>
    </AriaNumberField>
  );
});
