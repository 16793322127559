import cx from 'classnames';

import CopyToClipboardButton from 'components/shared/copy-button';

import styles from './styles.module.scss';

type Props = {
  className?: string;
  code: string;
  copyButtonText?: string;
  heading?: string;
  subHeading?: string;
  onCopyClick?: () => void;
};

const CopyableCodeBlock = ({
  className,
  code,
  copyButtonText,
  heading,
  subHeading,
  onCopyClick,
}: Props) => {
  return (
    <div className={cx(styles.codeSample, className)}>
      {(heading || subHeading) && (
        <div className={styles.sectionHeadingContainer}>
          {heading && <h4 className={styles.heading}>{heading}</h4>}
          {subHeading && (
            <div className={styles.sectionSubHeadingContainer}>
              {subHeading}
            </div>
          )}
        </div>
      )}
      <pre>
        <code>{code}</code>
      </pre>
      <CopyToClipboardButton
        buttonText={copyButtonText}
        className={styles.copyButton}
        textToCopy={code ?? ''}
        onClick={onCopyClick}
      />
    </div>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default CopyableCodeBlock;
