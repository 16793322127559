import { OpasFilters } from 'types/opas';
import { OrderTypes } from 'types/orders';
import {
  createExactFilter,
  createMatchFilter,
  createMatchFilterTerm,
  createOpasFilters,
} from 'utilities/opas';

export const createFilters = (
  startDate: string,
  endDate: string,
  orderType: OrderTypes,
  categoryId = 'all',
): OpasFilters => {
  const matchFilter =
    categoryId !== 'all'
      ? [
          createMatchFilter('category_id', [
            createMatchFilterTerm([categoryId]),
          ]),
        ]
      : [];

  return createOpasFilters(
    [
      createExactFilter('start', startDate),
      createExactFilter('finish', endDate),
      createExactFilter('order_type', orderType),
    ],
    matchFilter,
  );
};
