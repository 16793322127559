import moment from 'moment-timezone';

import {
  ChartDatum,
  WeeklySummary,
} from 'types/hours-analytics/availability-summary';

export const shopAvailabilityToChartData = (
  availability: WeeklySummary[],
): ChartDatum[] => {
  return availability.map((singleWeekAvailability) => ({
    x: singleWeekAvailability.weekEnd,
    y: Number(singleWeekAvailability.shopAvailability),
  }));
};

export const regionAvailabilityToChartData = (
  availability: WeeklySummary[],
): ChartDatum[] => {
  return availability.map((singleWeekAvailability) => ({
    x: singleWeekAvailability.weekEnd,
    y: Number(singleWeekAvailability.subregionShopAvailability),
  }));
};

export const getSlicedData = (
  availability: WeeklySummary[],
  isMobile: boolean,
): WeeklySummary[] => {
  return isMobile ? availability.slice(-6) : availability;
};

export const getFormattedDateRange = (
  weekEndDate: string,
  useShortDates = false,
): string => {
  const format = useShortDates ? 'M/D' : 'MMM D';
  const sameMonthFormat = useShortDates ? 'M/D' : 'D';

  const startOfWeekMoment = moment(weekEndDate).subtract(6, 'days');
  const startOfWeek = startOfWeekMoment.format(format);
  const startMonth = startOfWeekMoment.month();
  const endMonth = moment(weekEndDate).month();

  if (startMonth === endMonth) {
    return `${startOfWeek} - ${moment(weekEndDate).format(sameMonthFormat)}`;
  }

  return `${startOfWeek} - ${moment(weekEndDate).format(format)}`;
};
