import { useWatch } from 'react-hook-form';
import PropTypes from 'prop-types';

import { CustomizationField, getCustomizationFieldName } from 'utilities/menu';

import CustomizationTitle from '../../customization-title';

const CustomizationTileTitle = ({ control, index }) => {
  const name = useWatch({
    control,
    name: getCustomizationFieldName(CustomizationField.Name, index),
  });

  const selections = useWatch({
    control,
    name: getCustomizationFieldName(CustomizationField.Selections, index),
  });

  return <CustomizationTitle name={name} selections={selections} />;
};

CustomizationTileTitle.propTypes = {
  control: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default CustomizationTileTitle;
