import { useMutation, useQueryClient } from '@tanstack/react-query';

import { getMenuProductsQueryKey } from 'hooks/menu/use-menu-products-query';
import useApi from 'hooks/use-api';
import {
  RegisterPrinter,
  RegisterPrinterFormValues,
} from 'types/register-printing';
import { insert } from 'utilities/lists';
import { snakeCaseKeys } from 'utilities/objects';
import { networkToDomainRegisterPrinter } from 'utilities/register-printers';

export const useCreateRegisterPrintingSettingMutation = (shopId: string) => {
  const { authenticatedFetch } = useApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (values: RegisterPrinterFormValues) => {
      const printerSetting = {
        name: values.name,
        numberOfCopies: values.numberOfCopies,
        printMode: values.printMode.value,
      };
      const response = await authenticatedFetch.post(
        `api/management/v1/ros/shops/${shopId}/printer_roles`,
        snakeCaseKeys(printerSetting),
      );

      return networkToDomainRegisterPrinter(response) as RegisterPrinter;
    },

    onSuccess: (data) => {
      // Add the new print setting to cached list.
      queryClient.setQueryData<RegisterPrinter[]>(
        [shopId, 'printSettings'],
        (old) => {
          if (old) {
            return insert(old, data);
          }

          return [data];
        },
      );

      // Invalidate query for menu products
      queryClient.invalidateQueries(getMenuProductsQueryKey(shopId));
    },
  });
};
