import { useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Link } from 'crust';

import RequestProductChangeModal from 'components/menu/product/form/request-change-modal';
import FormText from 'components/shared/form-text';
import { phoneNumbers } from 'utilities/constants';
import { formatPhoneNumber } from 'utilities/strings';

const ProductTypesHelp = ({ getValues, productId, shopId }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <FormText>
      Note: when adding an option such as another pizza size, prices for the
      toppings will remain the same for all sizes. You can update the prices
      below in Customizations. For assistance, please call us at{' '}
      <Link href={`tel:${phoneNumbers.accountManagers}`} variant="tertiary">
        {formatPhoneNumber(phoneNumbers.accountManagers)}
      </Link>{' '}
      or send them{' '}
      <Button
        appearance="link"
        onPress={() => setIsModalOpen(true)}
        variant="tertiary"
      >
        here.
      </Button>
      <RequestProductChangeModal
        getProductFormValues={getValues}
        isOpen={isModalOpen}
        productId={productId}
        setIsOpen={setIsModalOpen}
        shopId={shopId}
      />
    </FormText>
  );
};

ProductTypesHelp.propTypes = {
  getValues: PropTypes.func.isRequired,
  productId: PropTypes.string.isRequired,
  shopId: PropTypes.string.isRequired,
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default ProductTypesHelp;
