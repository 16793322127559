import moment from 'moment-timezone';

import {
  nextDayLabels,
  timeAsInts,
} from 'components/hours/weekly-hours/helpers';
import { CLOSED_VALUE } from 'components/shared/time-select';

import { DEFAULT_EMPTY_VALUE } from '../constants';

import styles from './styles.module.scss';

type Props = {
  from: string;
  to: string;
  day: keyof typeof nextDayLabels | null;
};

const HoursSummary = ({ from, to, day = null }: Props) => {
  const start = moment(from, 'HH:mm').format('h:mma');
  const end = moment(to, 'hh:mm').format('h:mma');

  if (from === DEFAULT_EMPTY_VALUE || to === DEFAULT_EMPTY_VALUE) {
    return <div className={styles.hoursLabel}>Please select a time range</div>;
  } else if (from === CLOSED_VALUE) {
    return (
      <div>
        <div
          className={styles.closedLabel}
          data-testid="day-entry-closed-label"
        >
          Closed
        </div>
      </div>
    );
  } else {
    const dayLabel =
      day && timeAsInts(from) > timeAsInts(to)
        ? ` (${nextDayLabels[day]})`
        : '';

    return (
      <div>
        <div className={styles.hoursSummaryArea}>
          <div className={styles.hoursRangeArea}>
            <div className={styles.startTime}>{start}</div>
            <div className={styles.dash}>{`-`}</div>
            <div className={styles.endTime}>
              {end}
              {dayLabel}
            </div>
          </div>
        </div>
      </div>
    );
  }
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default HoursSummary;
