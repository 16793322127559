import { ElementRef, ForwardedRef, forwardRef, ReactNode } from 'react';
import { useId } from 'react-aria';
import {
  Radio as AriaRadio,
  RadioGroup as AriaRadioGroup,
  RadioGroupProps as AriaRadioGroupProps,
  RadioProps as AriaRadioProps,
} from 'react-aria-components';
import { Merge } from 'type-fest';

import { FieldChildren, type FieldProps, useField } from '../field/field';
import { getCrustClassName } from '../utilities/get-crust-class-name';
import { mergeAriaClassName } from '../utilities/merge-aria-class-name';

import './radio.css';

export type RadioGroupProps = Merge<
  AriaRadioGroupProps,
  FieldProps & {
    children: ReactNode;
    variant?: 'card' | 'default';
  }
>;

export const RadioGroup = forwardRef(function RadioGroup(
  {
    children,
    className,
    description,
    error,
    label,
    variant = 'default',
    ...props
  }: RadioGroupProps,
  ref: ForwardedRef<ElementRef<typeof AriaRadioGroup>>,
) {
  const { getFieldClassName, fieldProps, fieldChildrenProps } = useField({
    className,
    description,
    error,
    label,
    block: 'radio-group',
  });

  return (
    <AriaRadioGroup ref={ref} {...fieldProps} {...props}>
      <FieldChildren {...fieldChildrenProps}>
        <div className={getFieldClassName('radios', [variant])}>{children}</div>
      </FieldChildren>
    </AriaRadioGroup>
  );
});

export type RadioProps = Merge<
  Omit<AriaRadioProps, 'children'>,
  {
    description?: ReactNode;
    label?: ReactNode;
  }
>;

const getRadioClassName = getCrustClassName.bind(null, 'radio');

export const Radio = forwardRef(function Radio(
  { label, className, description, id, ...props }: RadioProps,
  ref: ForwardedRef<ElementRef<typeof AriaRadio>>,
) {
  /* The aria-description attribute does not have adequate support, yet, so
   * we'll use an extra element with the aria-describedby attribute to associate
   * the description with the input. */
  const descriptionId = useId(id ? `${id}-description` : undefined);

  /* The indicator SVG is currently hard-coded and represents the default
   * circle. A future feature will allow the SVG to be customized. */
  return (
    <AriaRadio
      aria-describedby={description ? descriptionId : undefined}
      className={mergeAriaClassName(getRadioClassName(), className)}
      id={id}
      ref={ref}
      {...props}
    >
      <span aria-hidden="true" className={getRadioClassName('input')}>
        <svg
          className={getRadioClassName('indicator')}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 10 10"
        >
          <circle cx="5" cy="5" r="5" fill="currentColor" />
        </svg>
      </span>
      {label && <span className={getRadioClassName('label')}>{label}</span>}
      {description && (
        /* We render the description twice because the outer element of
         * AriaRadio is a <label/> and the inadequate support for
         * aria-description. We want the description text to be part of the
         * clickable area of the radio, but announced as the input description
         * and not as part of the input label. Note that elements do not need to
         * be visible for use with aria-describedby. */
        <>
          <span aria-hidden="true" className={getRadioClassName('description')}>
            {description}
          </span>
          <span hidden id={descriptionId}>
            {description}
          </span>
        </>
      )}
    </AriaRadio>
  );
});
