import * as React from "react";
import { forwardRef } from "react";
const SvgWebsiteIcon = ({
  title,
  titleId,
  ...props
}, ref) => {
  return /* @__PURE__ */ React.createElement("svg", { width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", "aria-hidden": "true", ref, "aria-labelledby": titleId, ...props }, title === void 0 ? /* @__PURE__ */ React.createElement("title", { id: titleId }, "Website Icon") : title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { d: "M6 20C6 19.4477 6.44772 19 7 19H17C17.5523 19 18 19.4477 18 20C18 20.5523 17.5523 21 17 21H7C6.44772 21 6 20.5523 6 20Z", fill: "currentColor" }), /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M5 3C3.89543 3 3 3.89543 3 5V15C3 16.1046 3.89543 17 5 17H19C20.1046 17 21 16.1046 21 15V5C21 3.89543 20.1046 3 19 3H5ZM7 7C6.44772 7 6 7.44772 6 8C6 8.55228 6.44772 9 7 9H11C11.5523 9 12 8.55228 12 8C12 7.44772 11.5523 7 11 7H7ZM7 11C6.44772 11 6 11.4477 6 12C6 12.5523 6.44771 13 7 13H17C17.5523 13 18 12.5523 18 12C18 11.4477 17.5523 11 17 11H7Z", fill: "currentColor" }));
};
const ForwardRef = forwardRef(SvgWebsiteIcon);
export default ForwardRef;
