import { Shop } from 'types/shops';

export enum OnlineDiscountType {
  Percent = 'PERCENT',
}

export type OnlineDiscountFormValues = {
  amount: string | null;
  type: OnlineDiscountType;
};

export type EditOnlineDiscountRequestBody = Pick<Shop, 'discountPercent'>;
export type EditOnlineDiscountResponseBody = Pick<Shop, 'discountPercent'>;

export type OnlineDiscountEvent = {
  existingOnlineDiscountPercentage: number | null;
  isRegister: boolean;
  location?: string;
  page: string;
  shopId: number;
};
