import * as React from "react";
import { forwardRef } from "react";
const SvgGraphIcon = ({
  title,
  titleId,
  ...props
}, ref) => {
  return /* @__PURE__ */ React.createElement("svg", { width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", "aria-hidden": "true", ref, "aria-labelledby": titleId, ...props }, title === void 0 ? /* @__PURE__ */ React.createElement("title", { id: titleId }, "Graph Icon") : title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M4.8 3C3.80589 3 3 3.80589 3 4.8V19.2C3 20.1941 3.80589 21 4.8 21H19.2C20.1941 21 21 20.1941 21 19.2V4.8C21 3.80589 20.1941 3 19.2 3H4.8ZM17.389 9.02692C17.426 8.85508 17.3682 8.67744 17.2349 8.55352C17.1017 8.42961 16.9106 8.37582 16.7258 8.41019L13.9077 8.93431C13.7492 8.9638 13.6119 9.05516 13.5308 9.18518C13.4496 9.31521 13.4326 9.4718 13.4837 9.61442C13.614 9.96622 13.7933 10.313 14.0211 10.6258L11.7351 12.2201L10.2195 11.3393C9.74675 11.0645 9.12902 11.1524 8.76621 11.546L5.7 14.8725L6.50879 15.6L9.57449 12.9867L11.0852 13.8647C11.5303 14.1234 12.1086 14.0622 12.4797 13.717L14.896 11.4701C15.2603 11.7169 15.6756 11.91 16.0945 12.042C16.2479 12.0895 16.4158 12.0735 16.5556 11.9981C16.6955 11.9226 16.7937 11.795 16.8254 11.6476L17.389 9.02692Z", fill: "currentColor" }));
};
const ForwardRef = forwardRef(SvgGraphIcon);
export default ForwardRef;
