import { Moment } from 'moment-timezone';

export type Pause = {
  id: string;
  closureReason: {
    id: number;
    name: string;
  };
  endTime: string;
  shippingType:
    | ShippingType.Delivery
    | ShippingType.DeliveryAndPickup
    | ShippingType.Pickup;
  source: string;
  startTime: string;
};

export enum OrderStatus {
  Accepting = 'open',
  Closed = 'closed',
  Paused = 'paused',
}

export enum OrderStatusLabels {
  Accepting = 'accepting orders',
  Closed = 'shop closed',
  Paused = 'orders paused',
}

export enum OrderType {
  Delivery = 'delivery',
  DeliveryAndPickup = 'delivery & pickup',
  Pickup = 'pickup',
}

export enum ShippingType {
  DeliveryAndPickup = 'delivery_and_pickup',
  Delivery = 'delivery',
  Pickup = 'pickup',
}

export const ShippingTypeLabels = {
  [ShippingType.DeliveryAndPickup]: 'Delivery & Pickup',
  [ShippingType.Delivery]: 'Delivery',
  [ShippingType.Pickup]: 'Pickup',
} as const;

type ShippingStatus = {
  status: OrderStatus;
  nextOpen: string;
  isRegularScheduleOpen: boolean;
};

type PauseDetails = {
  endTime: string;
};

export type ShopShippingStatus = {
  deliveryAndPickup: ShippingStatus;
  delivery: ShippingStatus;
  pickup: ShippingStatus;
  pauseDetails: PauseDetails;
};

export type PauseReasonOption = {
  label: string;
  value: number;
};

export type ShippingTypeOption = {
  disabled: boolean;
  value: ShippingType;
};

export type PauseDurationOption = {
  label: string;
  value: 15 | 30 | 45 | 60;
};

type ShippingTypeSchedule = {
  from: Moment;
  to: Moment;
};

export type ScheduleForDate = {
  delivery: ShippingTypeSchedule[];
  pickup: ShippingTypeSchedule[];
};

type ShippingTypeScheduleStringDates = {
  from: string;
  to: string;
};

type ShippingTypePauseSchedule = {
  reason: string;
} & ShippingTypeScheduleStringDates;

export type Schedule = {
  schedule: {
    delivery: ShippingTypeScheduleStringDates[];
    pausings: {
      delivery: ShippingTypePauseSchedule[];
      pickup: ShippingTypePauseSchedule[];
    };
    pickup: ShippingTypeScheduleStringDates[];
    timezone: string;
  };
};

export type ShopOpening = {
  id: number;
  from: string;
  to: string;
  openFor: string;
  dayOfWeek: string;
  effectiveDate: string | null;
};
