import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { CalendarDate, today } from '@internationalized/date';

import { RangeValue, useDateFormatter } from 'crust';

import { CreateReportForm } from 'components/reports/create-report-form';
import { RHFCheckbox } from 'components/shared/rhf-checkbox';
import { RHFDateRangePicker } from 'components/shared/rhf-date-range-picker';
import { useCreateOrderActivityReportMutation } from 'hooks/reports';
import useAnalytics from 'hooks/use-analytics';
import { ReportAnalyticsType } from 'types/financials';
import {
  showInvalidSubmitToast,
  showUnexpectedErrorToast,
} from 'utilities/forms';

type Props = {
  closeForm: () => void;
  isRosEnabled: boolean;
  isSliceOsMode: boolean;
  shopId: number;
  shopTimezone: string;
};

type FormValues = {
  dates: RangeValue<CalendarDate>;
  onlineOrdersOnly: boolean;
};

export const OrderActivityReportsForm = ({
  closeForm,
  isRosEnabled,
  isSliceOsMode,
  shopId,
  shopTimezone,
}: Props) => {
  const maxDate = today(shopTimezone);
  const { trackCreateReportClick } = useAnalytics();

  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
  } = useForm<FormValues>({
    defaultValues: {
      // The date picker start blank. There is no CalendarDate value we can
      // provide that will satisfy TS, but also represent a invalid value. The
      // DateRangePicker accepts null, though.
      dates: null as unknown as FormValues['dates'],
      // When the register sales summary flag is on we want to always set this
      // report to online orders only as users will now use the register sales
      // summary tab for register orders.
      onlineOrdersOnly: false,
    },
    mode: 'onChange',
  });

  const dateFormatter = useDateFormatter({
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    timeZone: shopTimezone,
  });

  const validateDates = (dates: RangeValue<CalendarDate>) => {
    if (!dates || !dates.start || !dates.end) {
      return 'Please select start and end dates.';
    }

    if (dates.end.compare(dates.start) < 0) {
      return 'Please select an end date that is after the start date.';
    }

    if (dates.end.compare(maxDate) > 0) {
      const formatted = dateFormatter.format(maxDate.toDate(shopTimezone));
      return `Please select an end date of ${formatted} or earlier.`;
    }

    if (dates.end.compare(dates.start) > 30) {
      return 'Please select a maximum of 31 days.';
    }

    return true;
  };

  const { mutate: createReport, isLoading: isCreatingReport } =
    useCreateOrderActivityReportMutation(shopId);

  const handleValidSubmit = (values: FormValues) =>
    createReport(values, {
      onError: () => {
        showUnexpectedErrorToast();
      },
      onSuccess: (_data, { dates, onlineOrdersOnly }) => {
        trackCreateReportClick({
          type: ReportAnalyticsType.OrderActivity,
          startDate: dates.start.toString(),
          endDate: dates.end.toString(),
          excludeRegisterTransactionsChecked: onlineOrdersOnly ?? false,
        });
        toast.success('Report is being generated. Check back soon!');
        closeForm();
      },
    });

  const shouldShowOnlineOnlyCheckbox = isRosEnabled && !isSliceOsMode;

  return (
    <CreateReportForm
      onCancel={closeForm}
      onSubmit={handleSubmit(handleValidSubmit, showInvalidSubmitToast)}
      isSubmitting={isCreatingReport || isSubmitting}
    >
      <RHFDateRangePicker
        control={control}
        name="dates"
        label="Date Range"
        maxValue={maxDate}
        rules={{
          validate: validateDates,
        }}
      />
      {shouldShowOnlineOnlyCheckbox && (
        <RHFCheckbox
          control={control}
          label="Exclude Register transactions"
          name="onlineOrdersOnly"
        />
      )}
    </CreateReportForm>
  );
};
