import { useParams } from 'react-router-dom';

// Each shop can only have one online discount, so the possible IDs we can
// retrieve from the URL params are only "edit" or "new".

type Params = {
  discountId: 'edit' | 'new';
};

export const useCurrentOnlineDiscountId = (): Params['discountId'] => {
  const { discountId } = useParams<Params>();
  return discountId === 'edit' ? 'edit' : 'new';
};
