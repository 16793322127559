import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import FormFeedback from 'components/shared/form-feedback';
import Input from 'components/shared/input';
import Label from 'components/shared/label';
import Modal from 'components/shared/slice-modal';
import { useCreateSalesforceCaseMutation } from 'hooks/salesforce/use-create-salesforce-case';
import { useShopTraitsContext } from 'hooks/shops/use-shop-traits-context';
import useAnalytics from 'hooks/use-analytics';
import {
  SalesforceCaseType,
  SalesforceNote,
  SalesforceSubject,
} from 'types/salesforce';
import { Shop } from 'types/shops';
import { User } from 'types/user';

import styles from './styles.module.scss';

const validationSchema = z.object({
  nameOfContact: z.string().min(1, 'Please enter the name of contact.'),
  emailPhoneOfContact: z
    .string()
    .min(1, 'Please enter the email/phone of contact.'),
  hostingCompany: z.string().optional(),
  yourWebsiteCompany: z.string().min(1, 'Please enter your website company.'),
});

type FormValues = z.infer<typeof validationSchema>;

type Props = {
  isOpen: boolean;
  isSmartButtonActive: boolean | undefined;
  onClose: () => void;
  shopId: Shop['shopId'];
  user: User;
};

const ShareEmbedCodeModal = ({
  isOpen,
  isSmartButtonActive,
  onClose,
  shopId,
  user,
}: Props) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    getValues,
  } = useForm<FormValues>({
    defaultValues: {
      nameOfContact: '',
      emailPhoneOfContact: '',
      hostingCompany: '',
      yourWebsiteCompany: '',
    },
    resolver: zodResolver(validationSchema),
    mode: 'onTouched',
    shouldUnregister: true,
  });

  const { mutate: createSalesforceCase } = useCreateSalesforceCaseMutation();

  const { trackSubmittedShareCode } = useAnalytics();
  const shopTraits = useShopTraitsContext();

  const createSalesforceNote = () => {
    return `${SalesforceNote.ShareSmartButtonNote} --- Name of Contact: ${getValues('nameOfContact')}, Email/Phone of Contact: ${getValues('emailPhoneOfContact')}, ${getValues('hostingCompany') && `Hosting Company: ${getValues('hostingCompany')}, `}Your Website Address: ${getValues('yourWebsiteCompany')}`;
  };

  const onSubmit: SubmitHandler<FormValues> = () => {
    createSalesforceCase(
      {
        salesforceCase: {
          caseType: SalesforceCaseType.SmartButton,
          subject: SalesforceSubject.SmartButton,
        },
        note: createSalesforceNote(),
        shopId,
        user,
      },
      {
        onError: () => {
          toast.error(
            `We weren't able to process your request. Please contact your Partner Success Manager.`,
          );
        },
        onSuccess: () => {
          toast.success('Great! The embed code was shared.');
          onClose();
        },
      },
    );
    trackSubmittedShareCode({
      shopId: String(shopId),
      page: 'smart buttons',
      isActive: String(isSmartButtonActive),
      shopIntegrationAdoption: shopTraits.shopIntegrationAdoption,
    });
  };

  return (
    <Modal
      className={styles.modal}
      fadeClassName={styles.fade}
      footerClassName={styles.footer}
      header="Share Embed Code"
      isFullScreenMobile
      isOpen={isOpen}
      isYesButtonDisabled={!isValid}
      noButtonText="Cancel"
      onClickNo={onClose}
      onClickYes={handleSubmit(onSubmit)}
      onRequestClose={onClose}
      yesButtonText="Send"
    >
      Share the embed code with your web developer or hosting company so that
      they can easily add the Smart Button to your website.
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.input}>
          <Label htmlFor="name-of-contact">Name of Contact</Label>
          <Input
            id={`name-of-contact`}
            aria-required
            {...register('nameOfContact')}
          />
          <FormFeedback>{errors.nameOfContact?.message}</FormFeedback>
        </div>
        <div className={styles.input}>
          <Label htmlFor="email-phone-of-contact">Email/Phone of Contact</Label>
          <Input
            id={`email-phone-of-contact`}
            aria-required
            {...register('emailPhoneOfContact')}
          />
          <FormFeedback>{errors.emailPhoneOfContact?.message}</FormFeedback>
        </div>

        <div className={styles.input}>
          <Label htmlFor="hosting-company">Hosting Company (Optional)</Label>
          <Input id={`hosting-company`} {...register('hostingCompany')} />
        </div>

        <div className={styles.input}>
          <Label htmlFor="your-website-address">Your Website Address</Label>
          <Input
            id={`your-website-address`}
            aria-required
            {...register('yourWebsiteCompany')}
          />
          <FormFeedback>{errors.yourWebsiteCompany?.message}</FormFeedback>
        </div>
      </form>
    </Modal>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default ShareEmbedCodeModal;
