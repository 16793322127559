import { useMemo } from 'react';

import AddNewCardLink from 'components/debit-cards/add-new-card-link';
import Header from 'components/shared/header';
import Suspended from 'components/shared/suspended';
import { useDebitCardsQuery } from 'hooks/debit-cards/use-debit-cards-query';
import { useTypeSafeParams } from 'hooks/params/use-type-safe-params';

import Card from './view-card';

import styles from './styles.module.scss';

const DebitCards = () => {
  const { shopId } = useTypeSafeParams(['shopId']);

  const { data: debitCards, isLoading: isDebitCardLoading } =
    useDebitCardsQuery(shopId);

  // The debit card api can return up to 2 cards, an already approved 'default' card and a
  // currently pending card. We want to show the 'default' card if available, else show the pending card.
  const cardToShow = useMemo(
    () =>
      debitCards?.find((debitCard) => debitCard.approved) ?? debitCards?.[0],
    [debitCards],
  );

  return (
    <div className={styles.container}>
      <Header
        title="Get paid faster by adding a debit card"
        content={[
          `Tired of slow bank transfers to receive your Slice funds? Switch your
        payout method to debit card and get paid in under 2 business days. Just
        add your card below and we'll do the rest.`,
        ]}
      />
      <div className={styles.cardsHeader}>Saved Card</div>
      <Suspended isLoading={isDebitCardLoading}>
        {cardToShow == null ? (
          <AddNewCardLink />
        ) : (
          <Card
            isApproved={cardToShow.approved}
            last4Digits={cardToShow.last4}
            expireDate={cardToShow.exp}
            type={cardToShow.brand}
          />
        )}
      </Suspended>
    </div>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default DebitCards;
