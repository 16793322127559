import moment, { Moment } from 'moment-timezone';

import {
  ActiveCampaignPhase,
  CampaignManagerPlatformsInfo,
  LowerDeliveryFeeEmailInfo,
} from 'types/campaign-manager';
import { DeliveryZone } from 'types/shops';

const MAX_LEARNING_DAYS = 7;
const MIN_RAMPUP_DAYS = 8;
const MAX_RAMPUP_DAYS = 30;
const MIN_OPTIMIZATION_DAYS = 31;
const MAX_OPTIMIZATION_DAYS = 89;

export const insightText = {
  yourAdsAreLive:
    'Your ads are currently live on Google, Bing, Facebook, and Instagram.',
  campaignInLearningPhase:
    'Your campaign is in "the learning phase" where the ad platforms explore the best way to deliver the ads and which demographics to focus on.',
  resultsWillImprove:
    'As the platforms "learn", the results are typically low. Results will continue to improve as the platforms "learn", especially in the first couple weeks.',
  performingWell: 'Your campaign is performing well and meeting expectations.',
  basedOnRecentSales:
    'Based on recent sales, your campaign performance has been high and exceeding expectations.',
};

export const getRecentResultsPerformanceText = (
  salesInPennies?: number,
): string | null => {
  if (!salesInPennies) {
    return null;
  }

  const sales = salesInPennies / 100;

  if (sales < 2000) {
    return null;
  } else if (sales >= 2000 && sales < 3000) {
    return 'Your campaign is performing well and meeting expectations.';
  } else {
    return 'Based on recent sales, your campaign performance has been high and exceeding expectations.';
  }
};

export const getAdsAreLiveOnPlatformsText = (
  allPlatforms: string[],
): string => {
  let text = 'Your ads are currently live on ';
  const numPlatforms = allPlatforms.length;

  allPlatforms.forEach((platform, index) => {
    if (index === numPlatforms - 1) {
      text += `and ${platform}.`;
    } else {
      text += `${platform}, `;
    }
  });

  return text;
};

export const getPlatformPerformingBetterText = (
  orders: number | undefined,
  platformsInfo?: CampaignManagerPlatformsInfo,
): string | null => {
  if (!orders || orders === 0 || !platformsInfo) {
    return null;
  }

  const { leading } = platformsInfo;

  if (!leading) {
    return 'Your ads have been performing similarly on Google, Bing, Facebook, and Instagram.';
  }

  if (leading.toLowerCase() === 'google') {
    return 'Google has been outperforming Facebook, Instagram, and Bing.';
  }

  if (leading.toLowerCase() === 'facebook') {
    // A result of "facebook" is considered to actually mean results for "Meta".
    // I.e., the results are for Facebook and Instagram combined.
    // This is a limitation of the data the backend has access to.
    return 'Facebook/Instagram have been outperforming Google and Bing.';
  }

  // If we don't know that "google" or "facebook" are outperforming the
  // others, we can't say anything more specific. We don't know whether Bing
  // is outperforming. So, we fall back to a more generic statement.
  return 'Your ads have been performing similarly on Google, Bing, Facebook, and Instagram.';
};

export const getDayCount = (startDate: Moment): number => {
  return moment().diff(startDate, 'days') + 1;
};

export const getActiveCampaignPhase = (
  startDate: Moment,
): ActiveCampaignPhase => {
  const dayCount = getDayCount(startDate);

  if (dayCount <= MAX_LEARNING_DAYS) {
    return ActiveCampaignPhase.Learning;
  } else if (dayCount >= MIN_RAMPUP_DAYS && dayCount <= MAX_RAMPUP_DAYS) {
    return ActiveCampaignPhase.RampUp;
  } else if (
    dayCount >= MIN_OPTIMIZATION_DAYS &&
    dayCount <= MAX_OPTIMIZATION_DAYS
  ) {
    return ActiveCampaignPhase.Optimization;
  } else {
    return ActiveCampaignPhase.Execution;
  }
};

export const getLowerDeliveryFeeEmailInfo = (
  shopId: number,
): LowerDeliveryFeeEmailInfo => {
  const br = '%0D%0A';
  const subject = 'RE: Lower Delivery Fee';
  const source = "Owner's Portal";

  return {
    body: `${br.repeat(
      5,
    )}---${br}${subject}${br}ShopID#: ${shopId}${br}Source: ${source}`,
    subject: `${subject}, ShopID#: ${shopId}`,
  };
};

export const shopHasLowDeliveryFee = (deliveryZones: DeliveryZone[]): boolean =>
  deliveryZones.some(
    (zone) => zone.deliveryFee == null || Number(zone.deliveryFee) <= 4,
  );
