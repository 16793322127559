import { ElementRef, ForwardedRef, forwardRef, ReactNode } from 'react';
import {
  Switch as AriaSwitch,
  SwitchProps as AriaSwitchProps,
} from 'react-aria-components';

import { getCrustClassName } from '../utilities/get-crust-class-name';
import { mergeAriaClassName } from '../utilities/merge-aria-class-name';

import './switch.css';

export type SwitchProps = Omit<AriaSwitchProps, 'children' | 'isDisabled'> & {
  label?: ReactNode;
};

const getSwitchClassName = getCrustClassName.bind(null, 'switch');

export const Switch = forwardRef(function Switch(
  { className, label, ...props }: SwitchProps,
  ref: ForwardedRef<ElementRef<typeof AriaSwitch>>,
) {
  return (
    <AriaSwitch
      className={mergeAriaClassName(getSwitchClassName(), className)}
      ref={ref}
      {...props}
    >
      <div className={getSwitchClassName('indicator')} />
      {label}
    </AriaSwitch>
  );
});
