import { useState } from 'react';
import PropTypes from 'prop-types';

import DebitCardCvcInput from 'components/debit-card/form/inputs/debit-card-cvc-input';
import DebitCardExpiryDateInput from 'components/debit-card/form/inputs/debit-card-expiry-input';
import NameOnCardInput from 'components/debit-card/form/inputs/debit-card-name-input';
import DebitCardNumberInput from 'components/debit-card/form/inputs/debit-card-number-input';
import CollapsibleTile from 'components/shared/collapsible-tile';

import styles from './styles.module.scss';

const AddDebitCardTile = ({
  clearErrors,
  control,
  errors,
  register,
  setError,
}) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <CollapsibleTile
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title="Enter debit card details"
    >
      <div className={styles.tileBodyContent}>
        <div className={styles.inputWrapper}>
          <DebitCardNumberInput
            clearErrors={clearErrors}
            control={control}
            numberError={errors.cardNumber}
            setError={setError}
          />
        </div>
        <div className={styles.inputWrapper}>
          <NameOnCardInput nameError={errors.nameOnCard} register={register} />
        </div>
        <div className={styles.inputWrapper}>
          <DebitCardExpiryDateInput
            clearErrors={clearErrors}
            control={control}
            expiryError={errors.cardExpiry}
            setError={setError}
          />
        </div>
        <div className={styles.inputWrapper}>
          <DebitCardCvcInput
            clearErrors={clearErrors}
            control={control}
            cvcError={errors.cardCvc}
            setError={setError}
          />
        </div>
      </div>
    </CollapsibleTile>
  );
};

AddDebitCardTile.propTypes = {
  clearErrors: PropTypes.func.isRequired,
  control: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  register: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default AddDebitCardTile;
