import AvailabilityTile from 'components/shared/metric-tiles/availability-tile';
import UnavailabilityDeliveryPickup from 'components/shared/metric-tiles/unavailability-delivery-pickup';
import { Last30Days } from 'types/hours-analytics';

import styles from './styles.module.scss';

type Props = {
  data: Last30Days;
};

const Last30DaysMetricTiles = ({ data }: Props) => {
  return (
    <div className={styles.container}>
      <AvailabilityTile data={data} />
      <UnavailabilityDeliveryPickup
        dataTestId="delivery-unavailability-totals-tile"
        header="delivery"
        totals={data.unavailabilityTotals.delivery}
        totalsSubregion={data.unavailabilityTotalsGtSubregion.delivery}
      />
      <UnavailabilityDeliveryPickup
        dataTestId="pickup-unavailability-totals-tile"
        header="pickup"
        totals={data.unavailabilityTotals.pickup}
        totalsSubregion={data.unavailabilityTotalsGtSubregion.pickup}
      />
    </div>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default Last30DaysMetricTiles;
