import cx from 'classnames';

import CaretIcon from 'images/caret.svg?react';

import styles from './styles.module.scss';

type Props = {
  children: React.ReactNode;
  headerClassName?: string;
  linkIncluded?: boolean;
  titleContainerClassName?: string;
};

const MetricTileHeader = ({
  children,
  headerClassName,
  linkIncluded = false,
  titleContainerClassName,
}: Props) => {
  return (
    <div className={cx(styles.header, headerClassName)}>
      <div className={cx(styles.titleContainer, titleContainerClassName)}>
        {children}
      </div>
      {linkIncluded && (
        <CaretIcon aria-hidden="true" className={styles.arrowIcon} />
      )}
    </div>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default MetricTileHeader;
