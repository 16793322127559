import { parseAbsolute, toCalendarDate, today } from '@internationalized/date';
import moment from 'moment-timezone';

import {
  RegisterDiscount,
  RegisterDiscountFormValues,
  RegisterDiscountType,
} from 'types/discounts';
import { Shop } from 'types/shops';
import {
  toDollarString,
  toMoneyString,
  toPennyString,
} from 'utilities/currency';
import {
  toEndOfDateAbsoluteString,
  toStartOfDateAbsoluteString,
} from 'utilities/date-time';

export const getRegisterDiscountBody = (
  values: RegisterDiscountFormValues,
  timezone: Shop['timezoneIdentifier'],
) => ({
  name: values.name,
  description: values.description,
  type: values.type,
  isCombinable: values.isCombinable === 'true',
  amount:
    values.type === RegisterDiscountType.Fixed
      ? toPennyString(values.amount)
      : null,
  percent:
    values.type === RegisterDiscountType.Percent
      ? values.amount.toFixed(2)
      : null,
  startAt: toStartOfDateAbsoluteString(values.start, timezone),
  endAt: values.end ? toEndOfDateAbsoluteString(values.end, timezone) : null,
});

export const getDefaultRegisterDiscountFormValues = (
  discount: RegisterDiscount | null,
  timezone: Shop['timezoneIdentifier'],
) => {
  const start = discount?.startAt
    ? toCalendarDate(parseAbsolute(discount.startAt, timezone))
    : today(timezone);
  const end = discount?.endAt
    ? toCalendarDate(parseAbsolute(discount.endAt, timezone))
    : null;

  return {
    // Treat the percent as an amount in pennies to simplify using one field for
    // the two types of amounts.
    type: discount?.type ?? RegisterDiscountType.Fixed,
    amount:
      discount?.type === RegisterDiscountType.Percent
        ? Number.parseFloat(discount.percent ?? '')
        : Number.parseInt(discount?.amount ?? '') / 100,
    name: discount?.name ?? '',
    description: discount?.description ?? '',
    id: discount?.id ?? '',
    isCombinable: String(discount?.isCombinable ?? true),
    isTemporary: end != null,
    start,
    end,
  } as RegisterDiscountFormValues;
};

export const getFormattedRegisterDiscountAmount = (
  discount: Pick<RegisterDiscount, 'amount' | 'percent' | 'type'>,
): string => {
  if (discount.type === RegisterDiscountType.Fixed) {
    return toMoneyString(toDollarString(discount.amount ?? 0));
  }

  if (discount.type === RegisterDiscountType.Percent) {
    return `${discount.percent ?? 0}%`;
  }
  return 'Variable Discount';
};

export const getFormattedRegisterDiscountIsCombinable = (
  isCombinable: RegisterDiscount['isCombinable'],
): string => (isCombinable ? 'Yes' : 'No');

export const getFormattedRegisterDiscountDate = (
  value: RegisterDiscount['startAt'] | RegisterDiscount['endAt'],
  timezone: Shop['timezoneIdentifier'],
): string =>
  value ? moment.tz(value, timezone).format('dddd MM/DD/YYYY') : '';
