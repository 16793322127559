import { Control, useController } from 'react-hook-form';

import { SelectMenuOption } from 'components/website-tools/form/select-menu-option';
import useAnalytics from 'hooks/use-analytics';
import { ShopTraits } from 'types/shops';
import {
  SmartButtonFormValues,
  WebsiteIntegrationColors,
  WebsiteIntegrationsAnalyticsPageName,
} from 'types/website-tools';
import { capitalize } from 'utilities/strings';

import SelectMenu from '../../../form/select-menu';

type Props = {
  buttonClassName?: string;
  control: Control<SmartButtonFormValues>;
  setIsFormChanged: (isFormChanged: boolean) => void;
  shopId: number;
  shopTraits: ShopTraits;
  page: WebsiteIntegrationsAnalyticsPageName;
};

const ColorDropdown = ({
  buttonClassName,
  control,
  setIsFormChanged,
  shopId,
  shopTraits,
  page,
}: Props) => {
  const {
    field: { onChange, value },
    fieldState: { isDirty },
  } = useController({
    control,
    name: 'color',
  });

  const { trackClickedWebsiteIntegrationsEditorDropdown } = useAnalytics();

  const handleOnBlur = () => {
    trackClickedWebsiteIntegrationsEditorDropdown({
      field: 'color',
      isActive: String(shopTraits.isSmartButtonActive),
      shopId: String(shopId),
      optionSelected: isDirty ? value : null,
      page: page,
    });
  };

  return (
    <SelectMenu
      buttonClassName={buttonClassName}
      buttonId="color-dropdown"
      label={capitalize(value)}
      onChange={(value) => {
        onChange(value);
        setIsFormChanged(true);
      }}
      onBlur={handleOnBlur}
    >
      {Object.keys(WebsiteIntegrationColors).map((color) => {
        const isActive = value === color;

        return (
          <SelectMenuOption
            hasColor
            isActive={isActive}
            key={color}
            label={capitalize(color)}
            value={color}
          />
        );
      })}
    </SelectMenu>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default ColorDropdown;
