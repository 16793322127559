export enum MenuProductAvailability {
  Online = 'online',
  Offline = 'offline',
}

export type MenuProductType = {
  addonIds: number[];
  externalId: string | null;
  id: number;
  name: string;
  price: number;
  productId: number;
  unavailable: boolean;
  unavailableUntil: string | null;
};

export type MenuProduct = {
  addonGridIds?: string[];
  categoryId: number;
  description: string;
  externalId: string | null;
  id: number;
  image: string | null;
  isAlcohol: boolean;
  isDealsExempt: boolean;
  isFeatured: boolean;
  isAvailableOnline: boolean;
  isAvailableOffline: boolean;
  isLoyaltyReward: boolean;
  isPhotoHidden: boolean;
  name: string;
  productTypeIds: number[];
  unavailable: boolean;
  unavailableUntil: string | null;
};

type ProductFormId = number | `new_${string}`;

type ProductFormPrinterSetting = {
  id: number;
  isEnabled: boolean;
  name: string;
};

type ProductFormProductType = {
  id: number;
  name: string;
  price: string;
};

type ProductFormRelationship = {
  id: number;
  productTypeId: number;
};

type ProductFormOffer = {
  id: number;
  isDisabled: boolean;
  leftId: number;
  price: string;
  productTypeId: number;
  rightId: number;
};

type ProductFormSelection = {
  name: string;
  halfOffers: ProductFormOffer[];
  relationships: ProductFormRelationship[];
  wholeOffers: ProductFormOffer[];
};

type ProductFormCustomization = {
  activeProductTypeId: number;
  isByHalf: boolean;
  isByType: boolean;
  isRequired: boolean;
  limit: number;
  name: string;
  relationships: ProductFormRelationship[];
  selections: ProductFormSelection[];
};

export type ProductFormValues = {
  categoryId: number;
  customizations: ProductFormCustomization[];
  description: string;
  id: ProductFormId;
  image: string;
  isFeatured: boolean;
  isAvailableOnline: boolean;
  isAvailableOffline: boolean;
  name: string;
  printerSettings: ProductFormPrinterSetting[];
  productTypes: ProductFormProductType[];
  productTypesUpdate: number;

  categoryChanged: boolean;
  customizationAdded: boolean;
  customizationLimitChanged: boolean;
  customizationNameChanged: boolean;
  customizationRemoved: boolean;
  customizationRequiredChanged: boolean;
  descriptionChanged: boolean;
  isFeaturedChanged: boolean;
  isAvailableOnlineChanged: boolean;
  isAvailableOfflineChanged: boolean;
  nameChanged: boolean;
  printingChanged: boolean;
  selectionAdded: boolean;
  selectionNameChanged: boolean;
  selectionPriceChanged: boolean;
  selectionRemoved: boolean;
  typeAdded: boolean;
  typeNameChanged: boolean;
  typePriceChanged: boolean;
  typeRemoved: boolean;
};
