import image from 'images/register-layout-screenshot.png';

import styles from './styles.module.scss';

const RegisterMenuPageHeader = () => (
  <div className={styles.header} data-chameleon-target="Menu PosMenuHeader">
    <div className={styles.screenshot}>
      <img src={image} alt="Slice Register Screenshot" />
    </div>
    <div className={styles.content}>
      <h2 className={styles.title}>Register Menu Layout</h2>
      <h1 className={styles.action}>
        View and manage your Slice Register menu
      </h1>
      <div className={styles.description}>
        Re-arrange the menu tiles in the order that works best for your
        restaurant workflow. It will not affect how your customers see your
        menu.
      </div>
    </div>
  </div>
);

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default RegisterMenuPageHeader;
