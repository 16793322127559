import { toast } from 'react-toastify';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';

import {
  getDailyHoursQueryKey,
  useDailyHoursQuery,
} from 'hooks/daily-hours/use-daily-hours';
import { useShopQuery } from 'hooks/shops';
import useAnalytics from 'hooks/use-analytics';
import useApi from 'hooks/use-api';
import { showUnexpectedErrorToast } from 'utilities/forms';
import * as sortUtils from 'utilities/sorting';

import HoursOverview from './overview';

import styles from './styles.module.scss';

const OpeningStatus = ({ shopId }) => {
  const { trackDailyHoursActions } = useAnalytics();
  const api = useApi();
  const queryClient = useQueryClient();

  const { data: shopDailyHours } = useDailyHoursQuery(shopId);

  const trackDailyHoursActionsHelper = (
    buttonClicked,
    shippingType,
    date,
    editedDayTile,
    timeRange,
  ) =>
    trackDailyHoursActions(
      shopId,
      buttonClicked,
      shippingType,
      date,
      editedDayTile,
      timeRange || '',
    );

  const { data: shop } = useShopQuery(shopId);

  const shopTimezone = shop?.timezoneIdentifier;

  const { data: closingsData } = useQuery([shopId, 'closings'], () =>
    api.getClosings(shopId),
  );

  const sortedClosings = closingsData
    ? sortUtils.sortDate('startDate', true, closingsData)
    : [];

  const isToday = (closing) => {
    const today = moment.tz(shopTimezone).format('YYYY-MM-DD');

    return moment(today).isBetween(
      closing.startDate,
      closing.endDate,
      'day',
      '[]',
    );
  };

  const nextClosing = sortedClosings?.find((closing) => !isToday(closing));

  const todaysClosing = sortedClosings?.find((closing) => isToday(closing));

  const { mutate: updateDailyHours, isLoading: isUpdateInProgress } =
    useMutation(
      (values) => {
        const params = {
          daily_hours: {
            hours: values.hours,
            effective_date: values.effectiveDate,
            open_for: values.shippingType,
          },
        };

        return api.updateDailyHours(shopId, params);
      },
      {
        onError: () => showUnexpectedErrorToast(),
        onSuccess: (data, values) => {
          // For the status indicator we are driven by the open_for_today values so after posting to
          // daily hours we want to refetch the open for today values based on the new special hours
          queryClient.invalidateQueries([shopId, 'openForToday']);
          queryClient.setQueryData(getDailyHoursQueryKey(shopId), (old) => {
            old[values.todayOrTomorrow][values.shippingType] = data;

            return old;
          });

          toast.success(
            "Your restaurant's hours of operation have been successfully updated.",
          );
        },
      },
    );

  const onHoursFormSubmit = ({
    effectiveDate,
    hours,
    shippingType,
    todayOrTomorrow,
  }) => {
    return updateDailyHours({
      effectiveDate,
      hours,
      shippingType,
      todayOrTomorrow,
    });
  };

  return (
    <div className={styles.container}>
      <HoursOverview
        isUpdateInProgress={isUpdateInProgress}
        nextClosing={nextClosing}
        shopDailyHours={shopDailyHours}
        todaysClosing={todaysClosing}
        shopTimezone={shopTimezone}
        onHoursFormSubmit={onHoursFormSubmit}
        trackDailyHoursActions={trackDailyHoursActionsHelper}
        shopId={shopId}
      />
    </div>
  );
};

OpeningStatus.propTypes = {
  shopId: PropTypes.string.isRequired,
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default OpeningStatus;
