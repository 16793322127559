import { Control, useWatch } from 'react-hook-form';

import { PromoCodeFormValues } from 'types/discounts';
import { toMoneyString } from 'utilities/currency';

type Props = {
  control: Control<PromoCodeFormValues>;
};

export const OrderMinimumSummary = ({ control }: Props) => {
  const hasOrderMinimum = useWatch({
    control,
    name: 'hasOrderMinimum',
  });

  const orderMinimum = useWatch({
    control,
    name: 'orderMinimum',
  });

  const content =
    hasOrderMinimum === 'true'
      ? orderMinimum
        ? toMoneyString(orderMinimum)
        : null
      : 'None';

  return <>{content}</>;
};
