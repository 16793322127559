import { useMemo } from 'react';
import { SingleValue } from 'react-select';

import { Radio, RadioGroup } from 'crust';

import Label from 'components/shared/label';
import Modal from 'components/shared/slice-modal';
import Select from 'components/shared/slice-select';
import Suspended from 'components/shared/suspended';
import {
  ClosingScope,
  SelectClosingReason,
  useClosingReasons,
} from 'hooks/hours';
import useAnalytics from 'hooks/use-analytics';
import { createStringComparator } from 'utilities/sorting';

import styles from './styles.module.scss';

export enum ShippingTypeValue {
  DeliveryAndPickup = 'delivery_and_pickup',
  Delivery = 'delivery',
  Pickup = 'pickup',
}

enum ShippingTypeLabel {
  DeliveryAndPickup = 'Delivery & Pickup',
  Delivery = 'Delivery',
  Pickup = 'Pickup',
}

type ShippingTypeOption = {
  disabled: boolean;
  label: ShippingTypeLabel;
  value: ShippingTypeValue;
};

type CanCloseForShippingType = {
  canCloseForDeliveryAndPickup: boolean;
  canCloseForDelivery: boolean;
  canCloseForPickup: boolean;
};

export type CloseShopModalProps = {
  canCloseForShippingTypes: CanCloseForShippingType;
  closedForTodayDate: string;
  isOpen?: boolean;
  nextOpenAtDate: string;
  onClose: () => void;
  onSubmit: () => void;
  selectedClosingReasonId: number | null;
  selectedShippingType: ShippingTypeValue | null;
  setSelectedReasonId: (reasonId: number) => void;
  setShippingType: (shippingType: ShippingTypeValue) => void;
  shopId: string;
};

const CloseShopModal = ({
  canCloseForShippingTypes = {
    canCloseForDeliveryAndPickup: false,
    canCloseForDelivery: false,
    canCloseForPickup: false,
  },
  closedForTodayDate,
  isOpen = false,
  nextOpenAtDate,
  onClose,
  onSubmit,
  selectedClosingReasonId,
  selectedShippingType,
  setSelectedReasonId,
  setShippingType,
  shopId,
}: CloseShopModalProps) => {
  const {
    trackSelectedCloseShopService,
    trackClickedReasonForClosing,
    trackSelectedReasonForClosing,
    trackSubmitCloseShop,
  } = useAnalytics();

  const { data: closingReasonOptions, isLoading: closingReasonsLoading } =
    useClosingReasons(ClosingScope.Closing);

  const sortedReasons = useMemo(() => {
    if (!closingReasonOptions) {
      return [];
    }

    const comparator = createStringComparator('label');

    return closingReasonOptions
      .map((it) => ({ id: it.id, label: it.label, value: it.id }))
      .sort(comparator);
  }, [closingReasonOptions]);

  const shippingOptions: ShippingTypeOption[] = [
    {
      value: ShippingTypeValue.DeliveryAndPickup,
      label: ShippingTypeLabel.DeliveryAndPickup,
      disabled: !canCloseForShippingTypes.canCloseForDeliveryAndPickup,
    },
    {
      value: ShippingTypeValue.Delivery,
      label: ShippingTypeLabel.Delivery,
      disabled: !canCloseForShippingTypes.canCloseForDelivery,
    },
    {
      value: ShippingTypeValue.Pickup,
      label: ShippingTypeLabel.Pickup,
      disabled: !canCloseForShippingTypes.canCloseForPickup,
    },
  ];

  const handleShippingTypeOnChange = (value: ShippingTypeValue) => {
    const option = shippingOptions.find((it) => it.value === value);

    if (!option) {
      return;
    }

    trackSelectedCloseShopService({
      shopId,
      shippingType: option.label.toLowerCase(),
    });
    setShippingType(option.value);
  };

  const handleReasonMenuOpened = () => trackClickedReasonForClosing({ shopId });

  const handleReasonSelected = (
    selectedOption: SingleValue<SelectClosingReason>,
  ) => {
    if (selectedOption) {
      trackSelectedReasonForClosing({
        shopId,
        reason: selectedOption.label.toLowerCase(),
        reasonId: selectedOption.value,
      });
      setSelectedReasonId(selectedOption.value ?? '');
    }
  };

  const handleSubmit = () => {
    const selectedShippingTypeLabel = shippingOptions
      .find((option) => option.value === selectedShippingType)
      ?.label.toLowerCase();

    const selectedReasonLabel = sortedReasons
      .find((reason) => reason.value === selectedClosingReasonId)
      ?.label.toLowerCase();

    trackSubmitCloseShop({
      shopId,
      shippingType: selectedShippingTypeLabel,
      reason: selectedReasonLabel,
      reasonId: selectedClosingReasonId,
    });
    onSubmit();
  };

  return (
    <Modal
      header="Close Shop"
      isFullScreenMobile
      isOpen={isOpen}
      isYesButtonDisabled={
        selectedShippingType === null || selectedClosingReasonId === null
      }
      noButtonText="Cancel"
      onClickNo={onClose}
      onClickYes={handleSubmit}
      onRequestClose={onClose}
      yesButtonText="Close Shop"
    >
      <div className={styles.contentWrapper}>
        <Suspended isLoading={closingReasonsLoading}>
          <div className={styles.copyWrapper}>
            <span>Close Shop for </span>
            <span className={styles.bold}>{closedForTodayDate}?</span>
          </div>
          <RadioGroup
            className={styles.radioButton}
            label="Select service to close"
            onChange={(value) =>
              handleShippingTypeOnChange(value as ShippingTypeValue)
            }
            orientation="horizontal"
            value={selectedShippingType || undefined}
            variant="card"
          >
            {shippingOptions.map((option) => (
              <Radio
                isDisabled={option.disabled}
                key={option.value}
                label={option.label}
                value={option.value}
              />
            ))}
          </RadioGroup>
          <div className={styles.selectContainer}>
            <Label htmlFor="closure-reason-select">Reason for closing</Label>
            <Select
              inputId="closure-reason-select"
              options={sortedReasons}
              value={sortedReasons.find(
                (option) => option.value === selectedClosingReasonId,
              )}
              onChange={handleReasonSelected}
              onMenuOpen={handleReasonMenuOpened}
              maxMenuHeight={180}
            />
          </div>
          <div className={styles.copyWrapper}>
            Don’t worry, you can open shop at any time.
          </div>
          <div className={styles.copyWrapper}>
            <span>
              If you close your shop for the rest of today, you will
              automatically be accepting new orders the next business day,{' '}
            </span>
            <span className={styles.bold}>{nextOpenAtDate}, </span>
            <span>according to your original weekly schedule.</span>
          </div>
        </Suspended>
      </div>
    </Modal>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default CloseShopModal;
