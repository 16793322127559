import * as React from "react";
import { forwardRef } from "react";
const SvgMagnifyingGlassIcon = ({
  title,
  titleId,
  ...props
}, ref) => {
  return /* @__PURE__ */ React.createElement("svg", { width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", "aria-hidden": "true", ref, "aria-labelledby": titleId, ...props }, title === void 0 ? /* @__PURE__ */ React.createElement("title", { id: titleId }, "Magnifying Glass Icon") : title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M3 10.2478C3 6.24495 6.24495 3 10.2478 3C14.2507 3 17.4956 6.24495 17.4956 10.2478C17.4956 11.8457 16.9785 13.3229 16.1025 14.521C16.9782 15.0753 17.8543 15.6671 18.5892 16.402C19.5587 17.3714 20.3193 18.5762 21 19.7638L19.7638 21C18.5708 20.3162 17.3784 19.5656 16.402 18.5892C15.6671 17.8543 15.0753 16.9782 14.521 16.1025C13.3229 16.9785 11.8457 17.4956 10.2478 17.4956C6.24495 17.4956 3 14.2507 3 10.2478ZM10.2478 15.6837C13.2499 15.6837 15.6837 13.2499 15.6837 10.2478C15.6837 7.24566 13.2499 4.81195 10.2478 4.81195C7.24566 4.81195 4.81195 7.24566 4.81195 10.2478C4.81195 13.2499 7.24566 15.6837 10.2478 15.6837Z", fill: "currentColor" }));
};
const ForwardRef = forwardRef(SvgMagnifyingGlassIcon);
export default ForwardRef;
