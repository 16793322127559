import { useCallback, useEffect } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';

import {
  entriesOverlap,
  getDayMetadata,
} from 'components/hours/weekly-hours/helpers';
import { CLOSED_VALUE } from 'components/shared/time-select';
import EditIcon from 'images/edit.svg?react';
import { showInvalidSubmitToast } from 'utilities/forms';

import HoursSummary from './hours-summary/index.tsx';
import { DEFAULT_EMPTY_VALUE, DEFAULT_FROM, DEFAULT_TO } from './constants';
import DayEditorForm from './editor-form';

import styles from './styles.module.scss';

const Day = ({
  day,
  entries,
  isActive,
  isUpdateInProgress,
  onClick,
  saveEntries,
  setActiveDay,
  type,
  validateAllDays,
}) => {
  const getDefaultValues = useCallback(() => {
    const entriesAsArray = Object.values(entries);
    const { isOpenToday } = getDayMetadata(entriesAsArray);

    if (!entriesAsArray?.length) {
      return {
        isOpenToday,
        hoursForDay: [
          {
            dayOfWeek: day,
            from: '0',
            id: 'closed-entry',
            openFor: type,
            to: '0',
            isModified: false,
            isDeleted: false,
          },
        ],
      };
    }

    return {
      isOpenToday,
      hoursForDay: entriesAsArray.map((entry) => ({
        ...entry,
        isModified: false,
        isDeleted: false,
      })),
    };
  }, [entries, day, type]);

  const {
    handleSubmit,
    clearErrors,
    control,
    reset,
    formState: { isDirty, errors },
    setValue,
    getValues,
    trigger,
    watch,
  } = useForm({
    mode: 'onBlur',
    defaultValues: getDefaultValues(),
  });

  const { fields, append, remove, replace, update } = useFieldArray({
    control,
    name: 'hoursForDay',
    keyName: 'key',
  });

  useEffect(() => {
    if (!isDirty) {
      reset(getDefaultValues());
    }
  }, [isDirty, reset, getDefaultValues]);

  const openShop = ({ from = DEFAULT_FROM, to = DEFAULT_TO } = {}) => {
    // When we open a shop, we need to also check for any closing that were done so that we still delete those.
    // This is to handle the case where  user is editing hours, closes the shop, the reopens it all in the same editing
    // session, or removes some entries, closes and reopens the shop in the same session.
    const entriesToBeDeleted = fields.filter((entry) => entry.isDeleted);

    const newId = `new-${uuid()}`;

    replace([
      ...entriesToBeDeleted,
      {
        from,
        to,
        dayOfWeek: day,
        openFor: type,
        isModified: true,
        id: newId,
      },
    ]);
  };

  const closeShop = () => {
    // When we close a shop, existing entries will need to be marked as deleted so the endpoint is called
    // new entries that were added before marking to delete only need removed from the form
    const entriesToBeDeleted = fields.filter(
      (entry) => !String(entry.id).includes('new'),
    );

    replace([
      ...entriesToBeDeleted.map((entry) => ({
        ...entry,
        isModified: true,
        isDeleted: true,
      })),
      {
        from: '0',
        to: '0',
        dayOfWeek: day,
        openFor: type,
        isModified: false,
        id: 'closed-entry',
      },
    ]);
  };

  const addEntry = (overrides = {}) => {
    if (!getValues('isOpenToday')) {
      openShop();
      setValue('isOpenToday', true);
      return;
    }

    const newId = `new-${uuid()}`;

    append({
      from: DEFAULT_EMPTY_VALUE,
      to: DEFAULT_EMPTY_VALUE,
      dayOfWeek: day,
      openFor: type,
      isModified: true,
      id: newId,
      ...overrides,
    });
  };

  const removeEntry = (index) => () => {
    clearErrors();
    // If this is an existing entry, we set it to isDeleted so that the delete API call is made.
    // If this is an entry that was being added then decided to not add, we remove it from the form only.
    const fieldToRemove = fields[index];
    const isNewField = String(fieldToRemove.id).includes('new');

    if (isNewField) {
      remove(index);
    } else {
      update(index, {
        ...fieldToRemove,
        isModified: true,
        isDeleted: true,
      });
    }

    trigger();
  };

  const validateEntries = (indexBeingValidated) => {
    const hoursArray = getValues('hoursForDay');
    const entryBeingValidated = hoursArray[indexBeingValidated];
    const isLastEntry = indexBeingValidated === hoursArray.length - 1;
    let isInvalid = false;

    hoursArray.forEach((entryA) => {
      isInvalid = hoursArray.find(
        (entryB) =>
          !entryA.isDeleted &&
          !entryB.isDeleted &&
          entryA.openFor === entryB.openFor &&
          entryA.dayOfWeek === entryB.dayOfWeek &&
          entryA.id !== entryB.id &&
          entriesOverlap(entryA, entryB),
      );
    });

    if (
      entryBeingValidated?.from !== CLOSED_VALUE &&
      validateAllDays(entryBeingValidated)
    ) {
      return 'Your times cannot overlap with an adjacent day that extends into today, please adjust your selection';
    }

    // When two times overlap in the same day we only want to highlight the second entry
    if (isInvalid && isLastEntry) {
      return 'Your times cannot overlap, please adjust your selection.';
    }

    return true;
  };

  const dayStyles = cx(styles.day, { [styles.dayWithChanges]: isDirty });

  const summaryHours = watch('hoursForDay');
  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...summaryHours[index],
    };
  });

  const onSubmitSuccessCallback = () => {
    setActiveDay(false);
    reset(getValues());
  };

  const onSubmitSuccess = (formValues) => {
    saveEntries(formValues.hoursForDay, onSubmitSuccessCallback);
  };

  const onSubmitError = () => showInvalidSubmitToast();

  const hoursSummary = controlledFields
    .filter((entry) => !entry.isDeleted)
    .map((entry) => {
      return (
        <HoursSummary
          key={entry.id}
          from={entry.from}
          to={entry.to}
          day={day}
        />
      );
    });

  const isOpenToday = watch('isOpenToday');

  const onToggleOpenToday = () => (isOpenToday ? closeShop() : openShop());

  const onCancel = () => {
    reset(getDefaultValues());
    setActiveDay(false);
  };

  return (
    <div className={cx(styles.container, { [styles.isActive]: isActive })}>
      <button className={dayStyles} onClick={onClick}>
        <div className={styles.dayLabel}>
          {day.substring(0, 3)}
          <span className={styles.dayLabelDesktop}>{day.substring(3)}</span>
        </div>
        <div className={styles.dayInfo}>
          <div className={styles.hoursContainer}>{hoursSummary}</div>
          <EditIcon className={styles.dayEditIcon} title="Edit day hours" />
        </div>
      </button>
      <div className={styles.dayEditor}>
        <form
          className={styles.editorContent}
          onSubmit={handleSubmit(onSubmitSuccess, onSubmitError)}
        >
          <DayEditorForm
            addEntry={addEntry}
            closeShop={closeShop}
            control={control}
            entries={fields}
            errors={errors}
            isDirty={isDirty}
            isUpdateInProgress={isUpdateInProgress}
            onCancel={onCancel}
            onToggleOpenToday={onToggleOpenToday}
            openShop={openShop}
            removeEntry={removeEntry}
            reset={() => reset(getDefaultValues())}
            setValue={setValue}
            trigger={trigger}
            type={type}
            validateEntries={validateEntries}
          />
        </form>
      </div>
    </div>
  );
};

Day.propTypes = {
  day: PropTypes.string,
  entries: PropTypes.object,
  isActive: PropTypes.bool.isRequired,
  isUpdateInProgress: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  saveEntries: PropTypes.func,
  setActiveDay: PropTypes.func,
  type: PropTypes.string,
  validateAllDays: PropTypes.func.isRequired,
};

Day.defaultProps = {
  day: null,
  entries: {},
  onClick: () => {},
  saveEntries: () => {},
  setActiveDay: () => {},
  type: null,
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default Day;
