import { useQuery } from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { ApiRequestError } from 'providers/api/helpers';
import { Shop } from 'types/shops';
import { camelCaseKeys } from 'utilities/objects';

export const usePosIntegrationQuery = (shopId: Shop['shopId']) => {
  const { authenticatedFetch } = useApi();

  return useQuery({
    queryKey: [shopId, 'integration'],
    queryFn: async () => {
      try {
        const response = await authenticatedFetch.get(
          `api/management/v2/shops/${shopId}/pos_integration`,
        );

        return camelCaseKeys(response);
      } catch (error) {
        // A 404 here is fine, it just means the shop has no integration.
        if (error instanceof ApiRequestError && error.status === 404) {
          return null;
        }

        throw error;
      }
    },
  });
};
