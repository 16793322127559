import { useMemo, useState } from 'react';
import {
  ColumnSort,
  createColumnHelper,
  PaginationState,
  SortingState,
} from '@tanstack/react-table';
import moment from 'moment-timezone';

import { Button, ButtonGroup } from 'crust';

import ContentTile from 'components/shared/content-tile';
import Table from 'components/shared/table';
import Pagination from 'components/shared/table/pagination';
import Text from 'components/shared/text';
import { useProfileCustomersQuery } from 'hooks/customers/use-profile-customers-query';
import { useTable } from 'hooks/shared';
import {
  Customer,
  GetProfileCustomersParams,
} from 'types/customers/profile/customer-profile';
import { Shop } from 'types/shops';
import { formatPhoneNumber } from 'utilities/strings';

import { ExportModal } from '../export-modal';
import { ImportModal } from '../import-modal';

import styles from './styles.module.scss';

const mapSortingStateToSortParam = (sorting: ColumnSort) => {
  let sortParam: GetProfileCustomersParams['sort'] = 'created_at';

  if (sorting.id === 'lastOrderedAt') {
    sortParam = 'last_ordered_at';
  }

  if (sorting.desc) {
    sortParam = `-${sortParam}`;
  }

  return sortParam;
};

type TileHeaderProps = {
  hasPendingExport: boolean;
  isCustomerImportAvailable: boolean;
  isLatestImportInProgress: boolean;
  totalCustomers?: number;
  onExportPress: () => void;
  onImportPress: () => void;
};

const TileHeader = ({
  hasPendingExport,
  isCustomerImportAvailable,
  isLatestImportInProgress,
  totalCustomers,
  onExportPress,
  onImportPress,
}: TileHeaderProps) => {
  const handleExportPress = () => {
    onExportPress();
  };

  return (
    <>
      <div>
        <p className={styles.heading}>Customers</p>
        <p className={styles.subheading}>{totalCustomers ?? '-'} customers</p>
      </div>
      <ButtonGroup className={styles.buttonGroup}>
        <Button
          isDisabled={hasPendingExport}
          variant="secondary"
          onPress={handleExportPress}
        >
          export
        </Button>
        {isCustomerImportAvailable ? (
          <Button
            isDisabled={isLatestImportInProgress}
            variant="secondary"
            onPress={onImportPress}
          >
            import
          </Button>
        ) : null}
      </ButtonGroup>
    </>
  );
};

const DEFAULT_SORT = {
  id: 'createdAt',
  desc: true,
} as const;

type Props = {
  hasPendingExport: boolean;
  isCustomerImportAvailable: boolean;
  isFullRegisterShop: boolean;
  isLatestImportInProgress: boolean;
  shopId: Shop['shopId'];
  shopTimezone: Shop['timezoneIdentifier'];
};

export const CustomersTile = ({
  hasPendingExport,
  isCustomerImportAvailable,
  isFullRegisterShop,
  isLatestImportInProgress,
  shopId,
  shopTimezone,
}: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);
  const [sorting, setSorting] = useState<SortingState>([DEFAULT_SORT]);

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });

  const { data: customersResponse, isLoading: isCustomersLoading } =
    useProfileCustomersQuery({
      shopId,
      params: {
        page: pagination.pageIndex + 1,
        perPage: pagination.pageSize,
        sort: mapSortingStateToSortParam(sorting.at(0) ?? DEFAULT_SORT),
      },
    });

  const toggleExportModal = () => {
    setIsModalOpen((prevIsOpen) => !prevIsOpen);
  };

  const handleImportPress = () => {
    setIsImportModalOpen((prevIsOpen) => !prevIsOpen);
  };

  const columns = useMemo(() => {
    const helper = createColumnHelper<Customer>();

    return [
      helper.accessor('firstName', {
        id: 'name',
        enableSorting: false,
        header: 'Name',
        cell(ctx) {
          const customer = ctx.row.original;
          const customerNameFields = [
            customer.firstName,
            customer.lastName,
          ].filter(Boolean);

          if (customerNameFields.length > 0) {
            return <Text>{customerNameFields.join(' ')}</Text>;
          }

          return '-';
        },
      }),
      helper.accessor('addressOne', {
        id: 'address',
        enableSorting: false,
        header: 'Address',
        cell(ctx) {
          const customer = ctx.row.original;
          const addressLineOneFields = [
            customer.addressOne,
            customer.addressTwo,
          ].filter(Boolean);
          const addressLineTwoFields = [
            customer.city,
            customer.state,
            customer.zipcode,
          ].filter(Boolean);

          if (
            addressLineOneFields.length > 0 ||
            addressLineTwoFields.length > 0
          ) {
            return (
              <div className={styles.addressColumnDataWrapper}>
                {addressLineOneFields.length > 0 ? (
                  <Text wrap="truncate">{addressLineOneFields.join(', ')}</Text>
                ) : null}
                {addressLineTwoFields.length > 0 ? (
                  <Text wrap="truncate">{addressLineTwoFields.join(', ')}</Text>
                ) : null}
              </div>
            );
          }

          return '-';
        },
      }),
      helper.accessor('email', {
        id: 'email',
        enableSorting: false,
        header: 'Email',
        cell(ctx) {
          const customer = ctx.row.original;

          return customer.email ? (
            <Text wrap="break">{customer.email}</Text>
          ) : (
            '-'
          );
        },
      }),
      helper.accessor('mobileNumber', {
        id: 'phone',
        enableSorting: false,
        header: 'Phone',
        meta: {
          className: styles.phoneColumn,
        },
        cell(ctx) {
          const customer = ctx.row.original;

          return customer.mobileNumber ? (
            <Text wrap="truncate">
              {formatPhoneNumber(customer.mobileNumber)}
            </Text>
          ) : (
            '-'
          );
        },
      }),
      helper.accessor('createdAt', {
        id: 'createdAt',
        enableSorting: true,
        header: 'Created',
        meta: {
          className: styles.dateColumn,
        },
        cell(ctx) {
          const customer = ctx.row.original;

          return customer.createdAt
            ? moment.tz(customer.createdAt, shopTimezone).format('MMM D, YYYY')
            : '-';
        },
      }),
      helper.accessor('lastOrderedAt', {
        id: 'lastOrderedAt',
        enableSorting: true,
        header: 'Last Order',
        meta: {
          className: styles.dateColumn,
        },
        cell(ctx) {
          const customer = ctx.row.original;

          return customer.lastOrderedAt
            ? moment
                .tz(customer.lastOrderedAt, shopTimezone)
                .format('MMM D, YYYY')
            : '-';
        },
      }),
    ];
  }, [shopTimezone]);

  const totalPages = customersResponse?.meta.pagination.pages ?? 1;

  const table = useTable({
    columns,
    data: customersResponse?.customers ?? [],
    manualPagination: true,
    manualSorting: true,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    pageCount: totalPages,
    state: {
      pagination,
      sorting,
      isLoading: isCustomersLoading,
    },
    chameleonTableTitle: 'Customers',
  });

  return (
    <>
      <ContentTile
        className={styles.tile}
        headerClassName={styles.header}
        suppressTitle
        headerContent={
          <TileHeader
            hasPendingExport={hasPendingExport}
            isCustomerImportAvailable={isCustomerImportAvailable}
            isLatestImportInProgress={isLatestImportInProgress}
            totalCustomers={customersResponse?.meta.pagination.total}
            onExportPress={toggleExportModal}
            onImportPress={handleImportPress}
          />
        }
      >
        <Table className={styles.table} table={table} />
        <Pagination
          currentPage={table.getState().pagination.pageIndex + 1}
          isPageControlVisible
          isPerPageControlVisible={table.getPageCount() > 1}
          onChangePage={(page: number) => table.setPageIndex(page - 1)}
          totalPages={table.getPageCount()}
          perPage={pagination.pageSize}
          onChangePerPage={(page: number) => table.setPageSize(page)}
        />
      </ContentTile>
      <ImportModal
        isOpen={isImportModalOpen}
        onClose={handleImportPress}
        shopId={shopId}
      />

      <ExportModal
        closeModal={toggleExportModal}
        isFullRegisterShop={isFullRegisterShop}
        isOpen={isModalOpen}
        shopTimezone={shopTimezone}
        shopId={shopId}
      />
    </>
  );
};
