import { useMutation, useQueryClient } from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { Shop } from 'types/shops';

export const usePosIntegrationRevokeAuthMutation = (shopId: Shop['shopId']) => {
  const { authenticatedFetch } = useApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (provider: string) => {
      const response = await authenticatedFetch.post(
        `api/management/v2/shops/${shopId}/pos_integration/${provider}/revoke`,
      );
      return response;
    },
    onSuccess: () => {
      queryClient.setQueryData([shopId, 'integration'], null);
    },
  });
};
