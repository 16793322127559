import { ReactNode } from 'react';
import cx from 'classnames';

import styles from './styles.module.scss';

type Props = {
  children: ReactNode;
  className: string;
};

const PageTitle = ({ children, className }: Props) => (
  <h1 className={cx(styles.title, className)}>{children}</h1>
);

PageTitle.defaultProps = {
  className: null,
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default PageTitle;
