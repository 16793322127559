import cx from 'classnames';

import AttentionIcon from 'images/attention.svg?react';
import SuccessIcon from 'images/success.svg?react';

import styles from './styles.module.scss';

type StatusMessages = {
  negative: string;
  positive: string;
};

type Props = {
  className?: string;
  healthIndicators?: 'arrows' | 'icons';
  isInverse?: boolean;
  maxFractionDigits?: number;
  statusMessages?: StatusMessages;
  unit?: string;
  value: number | null;
};

const MetricTileDelta = ({
  className,
  healthIndicators = 'arrows',
  isInverse = false,
  maxFractionDigits = 2,
  statusMessages,
  unit = '%',
  value,
}: Props) => {
  // Round delta value to nearest non-zero value when maxFractionDigits = 0,
  // so that positive or negative values are not rounded to 0%.
  // i.e.
  //   0.12345 rounded up to 1%
  //  -0.12345 rounded down to -1%
  if (value && maxFractionDigits === 0) {
    value = value > 0 && value < 1 ? 1 : value;
    value = value < 0 && value > -1 ? -1 : value;
  }

  let isPositive = value ? value > 0 : false;
  isPositive = isInverse ? !isPositive : isPositive;

  const color = isPositive ? styles.positive : styles.negative;

  const getHealthIcon = (isPositive: boolean) => {
    return isPositive ? (
      <SuccessIcon aria-hidden="true" className={styles.healthIcon} />
    ) : (
      <AttentionIcon aria-hidden="true" className={styles.healthIcon} />
    );
  };

  const getStatusMessage = (
    statusMessages: StatusMessages,
    isPositive: boolean,
  ): string => (isPositive ? statusMessages.positive : statusMessages.negative);

  const getArrow = (isPositive: boolean) => (
    <div className={isPositive ? styles.triangleUp : styles.triangleDown} />
  );

  return (
    <div className={cx(styles.delta, className)}>
      <div className={cx(styles.content, color)}>
        {healthIndicators !== 'arrows'
          ? getHealthIcon(isPositive)
          : getArrow(isPositive)}
        <div className={cx(styles.deltaDetails)}>
          {value && (
            <span className={styles.valueAndUnitContainer}>
              {Math.abs(value).toLocaleString('en-US', {
                maximumFractionDigits: maxFractionDigits,
              })}
              {unit}
            </span>
          )}
          {statusMessages && (
            <span
              className={cx(styles.message, isPositive && styles.messageColor)}
            >
              {getStatusMessage(statusMessages, !!isPositive)}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default MetricTileDelta;
