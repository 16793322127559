import useStoredState, {
  SetStoredState,
  StoredState,
} from 'hooks/use-stored-state';

type ProductsPageState = {
  scrollId: StoredState<string>;
  search: StoredState<string>;
};

type UseProductsPageState = {
  setScrollId: SetStoredState<string>;
  setSearch: SetStoredState<string>;
} & ProductsPageState;

type DeprecatedSearch = {
  searchValue: string;
  shouldUseStoredValue: boolean;
};

const prefix = 'menu-products-page';

// Exported mainly for testing purposes.
export const ProductsPageStateKey = {
  ScrollId: `${prefix}-scroll-id`,
  Search: `${prefix}-search`,
} as const;

// A previous version of the search state might still be stored, so we'll need
// to parse it appropriately. We don't bother clearing the deprecated value
// until we try to set it.
const migrateSearch = (
  value: StoredState<string> | DeprecatedSearch,
): StoredState<string> => {
  if (value === undefined || typeof value === 'string') {
    return value;
  }

  if (typeof value?.searchValue === 'string') {
    return value.searchValue;
  }

  return undefined;
};

export const setMenuProductsScrollIdSynchronous = (value: string): void => {
  try {
    localStorage.setItem(ProductsPageStateKey.ScrollId, JSON.stringify(value));
  } catch {
    // Nothing to do.
  }
};

export const removeMenuProductsSearchSynchronous = (): void => {
  try {
    localStorage.removeItem(ProductsPageStateKey.Search);
  } catch {
    // Nothing to do.
  }
};

export const useProductsPageState = (
  initialState?: ProductsPageState,
): UseProductsPageState => {
  const [scrollId, setScrollId] = useStoredState(
    ProductsPageStateKey.ScrollId,
    initialState?.scrollId,
  );

  const [search, setSearch] = useStoredState(
    ProductsPageStateKey.Search,
    initialState?.search,
  );

  return {
    scrollId,
    search: migrateSearch(search),
    setScrollId,
    setSearch,
  };
};
