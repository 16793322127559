import * as React from "react";
import { forwardRef } from "react";
const SvgCreditCardIcon = ({
  title,
  titleId,
  ...props
}, ref) => {
  return /* @__PURE__ */ React.createElement("svg", { width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", "aria-hidden": "true", ref, "aria-labelledby": titleId, ...props }, title === void 0 ? /* @__PURE__ */ React.createElement("title", { id: titleId }, "Credit Card Icon") : title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { d: "M4 4C2.89543 4 2 4.89543 2 6V9H22V6C22 4.89543 21.1046 4 20 4H4Z", fill: "currentColor" }), /* @__PURE__ */ React.createElement("path", { d: "M22 11H2V18C2 19.1046 2.89543 20 4 20H20C21.1046 20 22 19.1046 22 18V11Z", fill: "currentColor" }));
};
const ForwardRef = forwardRef(SvgCreditCardIcon);
export default ForwardRef;
