import * as React from "react";
import { forwardRef } from "react";
const SvgAttention = ({
  title,
  titleId,
  ...props
}, ref) => {
  return /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 36 32", ref, "aria-labelledby": titleId, ...props }, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("g", { fill: "none", fillRule: "evenodd", stroke: "currentColor", strokeLinejoin: "round" }, /* @__PURE__ */ React.createElement("path", { strokeLinecap: "round", strokeWidth: 2, d: "M18 21.616v-9.981m16.261 18.713H1.739L18 1.652z" }), /* @__PURE__ */ React.createElement("path", { fill: "currentColor", d: "M18.74 25.783c0 .36-.332.652-.74.652s-.74-.292-.74-.652c0-.36.332-.653.74-.653s.74.293.74.653h0z" })));
};
const ForwardRef = forwardRef(SvgAttention);
export default ForwardRef;
