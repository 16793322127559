import { ComponentPropsWithoutRef } from 'react';
import cx from 'classnames';

import styles from './styles.module.scss';

type Props = ComponentPropsWithoutRef<'div'>;

export const Tile = ({ className, children, ...props }: Props) => (
  <div className={cx(styles.tile, className)} {...props}>
    {children}
  </div>
);

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default Tile;
