import { CustomerList, CustomerType, DateRange } from 'types/customers';
import { knowYourCustomersListDescriptions } from 'utilities/customers';

import KnowYourCustomersIcon from './icon';
import KnowYourCustomersTitle from './title';

import styles from './styles.module.scss';

type Props = {
  customerList: CustomerList;
  customerType: CustomerType;
  dateRange: DateRange;
};

const KnowYourCustomers = ({
  customerList,
  customerType,
  dateRange,
}: Props) => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <KnowYourCustomersIcon customerList={customerList} />
        <div className={styles.titleArea}>
          <div className={styles.staticTitle}>Know Your Customers</div>
          <KnowYourCustomersTitle
            customerList={customerList}
            customerType={customerType}
            dateRange={dateRange}
          />
        </div>
      </div>
      <div className={styles.description}>
        {knowYourCustomersListDescriptions[customerList]}
      </div>
    </div>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default KnowYourCustomers;
