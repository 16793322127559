import cx from 'classnames';
import PropTypes from 'prop-types';

import Button, { ButtonVariant } from 'components/shared/slice-button';
import NavArrowLeftIcon from 'images/nav-arrow-left.svg?react';
import NavArrowRightIcon from 'images/nav-arrow-right.svg?react';

import styles from './styles.module.scss';

const selectedClass = (currentPos, thisPos) =>
  currentPos === thisPos ? styles.filled : styles.empty;

const clickableClass = (pos, lockedAtPos) =>
  pos === lockedAtPos ? styles.locked : '';

const incrementPos = (currentPos, count, setPos) => {
  if (currentPos + 1 < count) {
    setPos(currentPos + 1);
  }
};

const decrementPos = (currentPos, setPos) => {
  if (currentPos - 1 >= 0) {
    setPos(currentPos - 1);
  }
};

const selectionDots = (count, position, setPos, parentTileTitle) => {
  let dots = [];
  for (let i = 0; i < count; i++) {
    dots.push(
      <Button
        key={i}
        onClick={() => setPos(i)}
        variant={ButtonVariant.LinkStylePrimary}
        title={`${parentTileTitle} page ${i + 1}`}
      >
        <div className={cx(styles.dot, selectedClass(position, i))} />
      </Button>,
    );
  }
  return dots;
};

const Control = ({
  className,
  count,
  position,
  setPosition,
  parentTileTitle,
}) => (
  <div className={cx(styles.wrapper, className)}>
    <Button
      className={styles.navButton}
      disabled={position === 0}
      onClick={() => decrementPos(position, setPosition)}
      variant={ButtonVariant.LinkStylePrimary}
    >
      <NavArrowLeftIcon
        className={cx(styles.navArrow, clickableClass(position, 0))}
        title={`${parentTileTitle} previous page`}
      />
    </Button>
    <div className={styles.selection}>
      {selectionDots(count, position, setPosition, parentTileTitle)}
    </div>
    <Button
      className={styles.navButton}
      disabled={position === count - 1}
      onClick={() => incrementPos(position, count, setPosition)}
      variant={ButtonVariant.LinkStylePrimary}
    >
      <NavArrowRightIcon
        className={cx(styles.navArrow, clickableClass(position, count - 1))}
        title={`${parentTileTitle} next page`}
      />
    </Button>
  </div>
);

Control.propTypes = {
  className: PropTypes.string,
  count: PropTypes.number,
  position: PropTypes.number,
  setPosition: PropTypes.func,
  parentTileTitle: PropTypes.string,
};

Control.defaultProps = {
  className: '',
  count: 1,
  position: 0,
  setPosition: () => {},
  parentTileTitle: '',
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default Control;
