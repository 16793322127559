import { CustomerProfiles } from 'components/customers/profiles';
import { NotFoundError } from 'components/shared/error';
import Loading from 'components/shared/loading';
import Page from 'components/shared/page';
import { useCustomerExportsQuery } from 'hooks/customers/use-customer-exports';
import { useCustomerImportErrorsQuery } from 'hooks/customers/use-customer-import-errors-query';
import { useCustomerImportsQuery } from 'hooks/customers/use-customer-imports-query';
import { useCurrentShopId, useShopQuery } from 'hooks/shops';
import useFeatureFlag from 'hooks/use-feature-flag';
import { OptimizelyFeatureFlag } from 'types/optimizely-feature-flag';
import { getShopHasFullRegister } from 'utilities/shops/register-app';

export const CustomerProfilesPage = () => {
  const shopId = useCurrentShopId();
  const {
    data: shop,
    isError: isShopError,
    isLoading: isShopLoading,
  } = useShopQuery(shopId);

  const isFullRegisterShop = !!shop && getShopHasFullRegister(shop);

  const {
    isEnabled: isCustomerProfilesPageEnabled,
    isLoading: isCustomerProfilesPageFeatureFlagLoading,
  } = useFeatureFlag(OptimizelyFeatureFlag.OwnersPortalCustomerProfilesPage);

  const {
    isEnabled: isCustomerImportEnabled,
    isLoading: isCustomerImportFeatureFlagLoading,
  } = useFeatureFlag(OptimizelyFeatureFlag.CustomerProfilesPageImport);

  const {
    data: customerExports,
    isLoading: isExportsLoading,
    isError: isExportsError,
  } = useCustomerExportsQuery(shopId, { pageIndex: 0, pageSize: 5 });

  const {
    data: customerImports,
    isLoading: isImportsLoading,
    isError: isImportsError,
  } = useCustomerImportsQuery(shopId);

  const latestImport = customerImports?.data.at(0);

  const {
    data: customerImportErrors,
    isInitialLoading: isCustomerImportErrorsLoading,
    isError: isCustomerImportErrorsError,
  } = useCustomerImportErrorsQuery(shopId, latestImport?.id ?? '', {
    enabled: !!latestImport && latestImport.uploadStatus === 'failure',
  });

  let content;

  if (
    isCustomerProfilesPageFeatureFlagLoading ||
    isShopLoading ||
    isExportsLoading ||
    isImportsLoading ||
    isCustomerImportErrorsLoading ||
    isCustomerImportFeatureFlagLoading
  ) {
    content = <Loading />;
  } else if (
    !isCustomerProfilesPageEnabled ||
    isShopError ||
    isExportsError ||
    isImportsError ||
    isCustomerImportErrorsError
  ) {
    content = <NotFoundError />;
  } else {
    content = (
      <CustomerProfiles
        customerImportErrors={customerImportErrors}
        exports={customerExports}
        imports={customerImports}
        isCustomerImportAvailable={
          isCustomerImportEnabled && isFullRegisterShop
        }
        isFullRegisterShop={isFullRegisterShop}
        shopId={shopId}
        shopTimezone={shop.timezoneIdentifier}
      />
    );
  }

  return (
    <Page name="Customer Profiles" title="Customer Profiles">
      {content}
    </Page>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default CustomerProfilesPage;
