import * as React from "react";
import { forwardRef } from "react";
const SvgPeopleIcon = ({
  title,
  titleId,
  ...props
}, ref) => {
  return /* @__PURE__ */ React.createElement("svg", { width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", "aria-hidden": "true", ref, "aria-labelledby": titleId, ...props }, title === void 0 ? /* @__PURE__ */ React.createElement("title", { id: titleId }, "People Icon") : title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { d: "M8.49998 11C10.433 11 12 9.433 12 7.5C12 5.567 10.433 4 8.49998 4C6.56699 4 4.99999 5.567 4.99999 7.5C4.99999 9.433 6.56699 11 8.49998 11Z", fill: "currentColor" }), /* @__PURE__ */ React.createElement("path", { d: "M17 12C18.6568 12 19.9999 10.6569 19.9999 9C19.9999 7.34315 18.6568 6 17 6C15.3431 6 14 7.34315 14 9C14 10.6569 15.3431 12 17 12Z", fill: "currentColor" }), /* @__PURE__ */ React.createElement("path", { d: "M2 20C2 20 2 13 8.49998 13C15 13 15 20 15 20C15 20.5523 14.5522 21 14 21H3C2.44771 21 2 20.5523 2 20Z", fill: "currentColor" }), /* @__PURE__ */ React.createElement("path", { d: "M21 21H16.8294C16.9399 20.6872 17.0001 20.3506 17.0001 20V19.9981L17 19.9914L17 19.9802C16.9774 17.9918 16.3708 15.9398 15.2246 14.3029C15.7323 14.111 16.3199 14 17.0001 14C22 14 22 20 22 20C22 20.5523 21.5523 21 21 21Z", fill: "currentColor" }));
};
const ForwardRef = forwardRef(SvgPeopleIcon);
export default ForwardRef;
