import * as React from "react";
import { forwardRef } from "react";
const SvgPie = ({
  title,
  titleId,
  ...props
}, ref) => {
  return /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 164 164", ref, "aria-labelledby": titleId, ...props }, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("g", { fill: "none", fillRule: "evenodd" }, /* @__PURE__ */ React.createElement("g", { stroke: "#E8E8E8", strokeWidth: 24 }, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("g", { transform: "translate(-242 -190) translate(178 139) translate(76 63)" }, /* @__PURE__ */ React.createElement("circle", { cx: 70, cy: 70, r: 70 }))))));
};
const ForwardRef = forwardRef(SvgPie);
export default ForwardRef;
