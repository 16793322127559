import { ReactNode } from 'react';

import ContentTile from 'components/shared/content-tile';
import Link from 'components/shared/link';

import styles from './styles.module.scss';

type Props = {
  code: number;
  description: ReactNode;
  instructions: ReactNode;
  title: ReactNode;
};

const ErrorView = ({ code, description, instructions, title }: Props) => (
  <ContentTile className={styles.tile}>
    <h1 className={styles.title}>{title}</h1>
    <h2 className={styles.description}>{description}</h2>
    <p className={styles.instructions}>{instructions}</p>
    <p>Error {code}</p>
  </ContentTile>
);

export const NotFoundError = () => (
  <ErrorView
    code={404}
    description="We can't seem to find the page you are looking for."
    instructions={
      <>
        Try refreshing this page or return to <Link to="/">homepage</Link>.
      </>
    }
    title="Uh Oh"
  />
);

export const UnexpectedError = () => (
  <ErrorView
    code={500}
    description="We're having trouble displaying this page at the moment."
    instructions="Please wait and try refreshing this page."
    title="Something went wrong!"
  />
);

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default ErrorView;
