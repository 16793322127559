import logo from 'images/integrations/facebook-logo.png';

import IntegrationListItem from '..';

import styles from './styles.module.scss';

const FacebookIntegrationListItem = () => (
  <IntegrationListItem
    copyText={
      <>
        <p className={styles.copy}>
          Facebook Food Ordering is now available with Slice.
        </p>
        <p className={styles.copy}>
          Connect your online ordering to Facebook today. Here’s how to add an
          Order Now button to your Facebook page.
        </p>
      </>
    }
    imageAltText="Facebook logo"
    imageSource={logo}
    redirectUrl="https://blog.slicelife.com/facebook-food-ordering-slice/"
    provider="facebook"
  />
);

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default FacebookIntegrationListItem;
