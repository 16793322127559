import PropTypes from 'prop-types';

import { Button, ButtonGroup } from 'crust';

import { getDayMetadata } from 'components/hours/weekly-hours/helpers';
import FormFeedback from 'components/shared/form-feedback';
import { RHFCheckbox } from 'components/shared/rhf-checkbox';

import Entry from './entry';

import styles from './styles.module.scss';

const DayEditorForm = ({
  addEntry,
  closeShop,
  control,
  entries,
  errors,
  isDirty,
  isUpdateInProgress,
  onCancel,
  onToggleOpenToday,
  openShop,
  removeEntry,
  setValue,
  trigger,
  type,
  validateEntries,
}) => {
  const { hasMaxEntries } = getDayMetadata(entries);

  const flattenErrorMessages = (errors) => {
    const unique = new Set();

    if (errors?.hoursForDay) {
      for (const error of errors.hoursForDay) {
        if (error?.from?.message) {
          unique.add(error.from?.message);
        }

        if (error?.to?.message) {
          unique.add(error.to?.message);
        }
      }
    }

    return Array.from(unique);
  };

  const feedback = flattenErrorMessages(errors);

  return (
    <>
      <div className={styles.toggles}>
        <RHFCheckbox
          control={control}
          name="isOpenToday"
          label={`Open for ${type}`}
          rules={{
            onChange: () => onToggleOpenToday(),
          }}
        />
      </div>
      <div className={styles.form}>
        {entries.map(
          ({ id, isDeleted, dayOfWeek }, index) =>
            !isDeleted && (
              <Entry
                canDelete={hasMaxEntries}
                closeShop={closeShop}
                control={control}
                dayOfWeek={dayOfWeek}
                errors={errors}
                index={index}
                key={id}
                onDeleteEntry={removeEntry(index)}
                openShop={openShop}
                setValue={setValue}
                trigger={trigger}
                validateEntries={validateEntries}
              />
            ),
        )}
        {feedback.map((message) => (
          <FormFeedback key={message} className={styles.error}>
            {message}
          </FormFeedback>
        ))}
        <Button
          appearance="link"
          isDisabled={hasMaxEntries}
          onPress={() => addEntry()}
        >
          Add additional time
        </Button>
        <ButtonGroup autoCollapse>
          <Button
            isDisabled={isUpdateInProgress}
            onPress={onCancel}
            variant="secondary"
          >
            Cancel
          </Button>
          <Button
            isDisabled={!isDirty || isUpdateInProgress}
            type="submit"
            variant="primary"
          >
            Save
          </Button>
        </ButtonGroup>
      </div>
    </>
  );
};

DayEditorForm.propTypes = {
  addEntry: PropTypes.func.isRequired,
  closeShop: PropTypes.func.isRequired,
  control: PropTypes.object.isRequired,
  entries: PropTypes.array,
  errors: PropTypes.object,
  handleSubmit: PropTypes.func,
  isDirty: PropTypes.bool,
  isUpdateInProgress: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onToggleOpenToday: PropTypes.func.isRequired,
  openShop: PropTypes.func.isRequired,
  removeEntry: PropTypes.func.isRequired,
  setValue: PropTypes.func,
  trigger: PropTypes.func.isRequired,
  type: PropTypes.string,
  validateEntries: PropTypes.func,
};

DayEditorForm.defaultProps = {
  entries: [],
  type: null,
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default DayEditorForm;
