import { RowData, Table } from '@tanstack/react-table';

import styles from './styles.module.scss';

type Props<TData extends RowData> = {
  searchTerm?: string;
  table?: Table<TData>;
};

const TableBodyEmpty = <TData extends RowData>({
  searchTerm,
  table,
}: Props<TData>) => {
  if (table) {
    searchTerm = table.getState().globalFilter;
  }

  if (searchTerm) {
    return (
      <>
        <div className={styles.sorry}>
          {"Sorry, we couldn't find what you were looking for."}
        </div>
        <div>
          {`No results for "${searchTerm}". Check your spelling and try
            again, or search for something different.`}
        </div>
      </>
    );
  }

  return <>There are no results to show.</>;
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default TableBodyEmpty;
