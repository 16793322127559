import { Statements as StatementsContent } from 'components/financials/statements';
import { AchEnrollmentBanner } from 'components/shared/ach-enrollment-banner';
import { UnexpectedError } from 'components/shared/error';
import Loading from 'components/shared/loading';
import Page from 'components/shared/page';
import { useAchOnboardingLink } from 'hooks/ach-enrollment-banner/use-ach-enrollment-link';
import { useCurrentShopId, useShopQuery } from 'hooks/shops';

import styles from './styles.module.scss';

export const Statements = () => {
  const shopId = useCurrentShopId();

  const {
    data: shop,
    isLoading: isShopLoading,
    isError: isShopError,
  } = useShopQuery(shopId);

  const { data: achOnboardingLink } = useAchOnboardingLink(shopId);

  let content;

  if (isShopLoading) {
    content = <Loading />;
  } else if (isShopError) {
    content = <UnexpectedError />;
  } else {
    content = (
      <>
        {achOnboardingLink?.showOnboardingLink && (
          <AchEnrollmentBanner
            className={styles.achEnrollmentBanner}
            page="statements"
            redirectUrl={achOnboardingLink.redirectUrl}
          />
        )}
        <StatementsContent shopId={shop.shopId} />
      </>
    );
  }

  return (
    <Page name="statements" title="Statements">
      {content}
    </Page>
  );
};
