import AllCustomersIcon from 'images/all-customers-icon.svg?react';
import AllCustomersIconNoOutline from 'images/all-customers-icon-no-outline.svg?react';
import DeliveryCustomersIcon from 'images/delivery-customers-icon.svg?react';
import DeliveryCustomersIconNoOutline from 'images/delivery-customers-icon-no-outline.svg?react';
import LoyalCustomerIcon from 'images/loyal-customer-icon.svg?react';
import LoyalCustomerIconNoOutline from 'images/loyal-customer-icon-no-outline.svg?react';
import NewCustomerIcon from 'images/new-customer-icon.svg?react';
import NewCustomerIconNoOutline from 'images/new-customer-icon-no-outline.svg?react';
import PickupCustomersIcon from 'images/pickup-customers-icon.svg?react';
import PickupCustomersIconNoOutline from 'images/pickup-customers-icon-no-outline.svg?react';
import RepeatCustomerIcon from 'images/repeat-customer-icon.svg?react';
import RepeatCustomerIconNoOutline from 'images/repeat-customer-icon-no-outline.svg?react';
import ValuableCustomersIcon from 'images/valuable-customers-icon.svg?react';
import ValuableCustomersIconNoOutline from 'images/valuable-customers-icon-no-outline.svg?react';
import {
  CustomerFilter,
  CustomerList,
  CustomerListOption,
  CustomerLoyaltyStatus,
  CustomerType,
  CustomerTypeOption,
  DateRange,
  DateRangeOption,
  Interval,
} from 'types/customers';
import { isoDateAndTime, nowDateTime } from 'utilities/date-time';

export const CustomerListLabels = {
  [CustomerList.New]: 'New Customers',
  [CustomerList.Repeat]: 'Repeat Customers',
  [CustomerList.Loyal]: 'Loyal Customers',
  [CustomerList.All]: 'All Customers',
  [CustomerList.Delivery]: 'Delivery Customers',
  [CustomerList.Pickup]: 'Pickup Customers',
  [CustomerList.MostFrequent]: 'Most Frequent Customers',
  [CustomerList.MostValuable]: 'Most Valuable Customers',
};

export const customerListKnowYourCustomersLabels = {
  [CustomerList.New]: 'New',
  [CustomerList.Repeat]: 'Repeat',
  [CustomerList.Loyal]: 'Loyal',
  [CustomerList.All]: 'All',
  [CustomerList.Delivery]: 'Delivery',
  [CustomerList.Pickup]: 'Pickup',
  [CustomerList.MostFrequent]: 'Top 10 Most Frequent',
  [CustomerList.MostValuable]: 'Top 10 Most Valuable',
};

export const customerListTableColumnLabels = {
  [CustomerLoyaltyStatus.All]: 'All',
  [CustomerLoyaltyStatus.New]: 'New',
  [CustomerLoyaltyStatus.Repeat]: 'Repeat',
  [CustomerLoyaltyStatus.Loyal]: 'Loyal',
};

export const customerTypeLabels = {
  [CustomerType.Online]: 'Online',
  [CustomerType.Register]: 'Register',
  [CustomerType.All]: 'Online + Register',
  [CustomerType.GetRegister]: '',
};

export const customerListDescriptions = {
  [CustomerLoyaltyStatus.All]:
    'Every customer counts! This is a list of all customers.',
  [CustomerLoyaltyStatus.New]:
    'Exciting! New customers can become loyal customers.',
  [CustomerLoyaltyStatus.Loyal]:
    'Hard work pays off! These customers order regularly.',
  [CustomerLoyaltyStatus.Repeat]:
    'Opportunity! These are casual customers who order infrequently.',
};

export const knowYourCustomersListDescriptions = {
  [CustomerList.All]:
    "Use this list to understand who your most valuable customers are, their average order size, and how frequently they order. Click on the columns to sort or click a customer's name to see a summary of their order history.",
  [CustomerList.New]:
    "Exciting! These customers made their first order, and we're working hard to bring them back and turn them into loyal regulars.",
  [CustomerList.Loyal]:
    'Your hard work has paid off! These customers have fallen in love with your food and are now ordering regularly. Everyone on this list is certified Loyal.',
  [CustomerList.Repeat]:
    "This is an opportunity. These are casual customers who order sporadically. We're focusing on bringing them back again and again for reliable reorders.",
  [CustomerList.Delivery]:
    'This data shows who tends to make the most frequent delivery orders among your customer base.',
  [CustomerList.Pickup]:
    'This data shows who tends to make the most frequent pickup orders among your customer base.',
  [CustomerList.MostValuable]:
    'You earn the most revenue from these customers. Click any name to see details.',
  [CustomerList.MostFrequent]:
    'These are your customers who order the most often. Click any name to see details.',
};

export const customerTypeIcons = {
  [CustomerLoyaltyStatus.New]: NewCustomerIcon,
  [CustomerLoyaltyStatus.Repeat]: RepeatCustomerIcon,
  [CustomerLoyaltyStatus.Loyal]: LoyalCustomerIcon,
} as const;

export const getCustomerListOptions = (): {
  options: CustomerListOption[];
  defaultCustomerListOption: CustomerListOption;
} => {
  const allCustomersOption = {
    label: CustomerListLabels.all,
    value: CustomerList.All,
  };
  const newCustomerOption = {
    label: CustomerListLabels.new,
    value: CustomerList.New,
  };
  const loyalCustomersOption = {
    label: CustomerListLabels.loyal,
    value: CustomerList.Loyal,
  };
  const repeatCustomersOption = {
    label: CustomerListLabels.repeat,
    value: CustomerList.Repeat,
  };
  const deliveryCustomersOption = {
    label: CustomerListLabels.delivery,
    value: CustomerList.Delivery,
  };
  const pickupCustomersOption = {
    label: CustomerListLabels.pickup,
    value: CustomerList.Pickup,
  };
  const mostFrequentCustomersOption = {
    label: CustomerListLabels.most_frequent,
    value: CustomerList.MostFrequent,
  };
  const mostValuableCustomersOption = {
    label: CustomerListLabels.most_valuable,
    value: CustomerList.MostValuable,
  };

  const customerListOptions = [
    allCustomersOption,
    newCustomerOption,
    loyalCustomersOption,
    repeatCustomersOption,
    deliveryCustomersOption,
    pickupCustomersOption,
    mostFrequentCustomersOption,
    mostValuableCustomersOption,
  ];

  return {
    options: customerListOptions,
    defaultCustomerListOption: allCustomersOption,
  };
};

export const getCustomerTypeOptions = (
  isRegisterShop: boolean | undefined,
): {
  options: CustomerTypeOption[];
  defaultCustomerTypeOption: CustomerTypeOption;
} => {
  const onlineOption = {
    label: customerTypeLabels.online,
    value: CustomerType.Online,
  };
  const offlineOption = {
    label: customerTypeLabels.offline,
    value: CustomerType.Register,
  };
  const allOption = {
    label: customerTypeLabels.all,
    value: CustomerType.All,
  };
  const getRegisterOption = {
    label: customerTypeLabels.getRegister,
    value: CustomerType.GetRegister,
  };

  const options = [onlineOption];

  if (isRegisterShop) {
    options.push(offlineOption, allOption);
  } else {
    options.push(getRegisterOption);
  }

  return {
    options,
    defaultCustomerTypeOption: isRegisterShop ? allOption : onlineOption,
  };
};

export const dateRangeLabels = {
  [DateRange.Seven]: 'Last 7 Days',
  [DateRange.Thirty]: 'Last 30 Days',
  [DateRange.Sixty]: 'Last 60 Days',
  [DateRange.Ninety]: 'Last 90 Days',
};

export const getDateRangeOptions = (): {
  options: DateRangeOption[];
  defaultDateRangeOption: DateRangeOption;
} => {
  const sevenDaysOption = {
    label: dateRangeLabels[DateRange.Seven],
    value: DateRange.Seven,
  };
  const thirtyDaysOption = {
    label: dateRangeLabels[DateRange.Thirty],
    value: DateRange.Thirty,
  };
  const sixtyDaysOption = {
    label: dateRangeLabels[DateRange.Sixty],
    value: DateRange.Sixty,
  };
  const ninetyDaysOption = {
    label: dateRangeLabels[DateRange.Ninety],
    value: DateRange.Ninety,
  };

  const dateRangeOptions = [
    sevenDaysOption,
    thirtyDaysOption,
    sixtyDaysOption,
    ninetyDaysOption,
  ];

  return {
    options: dateRangeOptions,
    defaultDateRangeOption: thirtyDaysOption,
  };
};

export const createCustomerFilter = (
  key: string,
  condition: string,
  value: string | string[],
): CustomerFilter => ({
  key,
  term: { condition, value },
});

export const getStartDate = (
  dateRange: DateRange,
  timezone: string | undefined,
): string =>
  nowDateTime(timezone)
    .subtract(dateRange, 'days')
    .startOf('day')
    .utc()
    .format(isoDateAndTime);

export const getInterval = (
  dateRange: DateRange,
  timezone: string | undefined,
): Interval => ({
  start: getStartDate(dateRange, timezone),
  finish: nowDateTime(timezone).endOf('day').utc().format(isoDateAndTime),
});

export const customerListIcons = {
  [CustomerList.All]: AllCustomersIcon,
  [CustomerList.New]: NewCustomerIcon,
  [CustomerList.Loyal]: LoyalCustomerIcon,
  [CustomerList.Repeat]: RepeatCustomerIcon,
  [CustomerList.Pickup]: PickupCustomersIcon,
  [CustomerList.Delivery]: DeliveryCustomersIcon,
  [CustomerList.MostFrequent]: ValuableCustomersIcon,
  [CustomerList.MostValuable]: ValuableCustomersIcon,
};

export const customerListIconsNoOutline = {
  [CustomerList.All]: AllCustomersIconNoOutline,
  [CustomerList.New]: NewCustomerIconNoOutline,
  [CustomerList.Loyal]: LoyalCustomerIconNoOutline,
  [CustomerList.Repeat]: RepeatCustomerIconNoOutline,
  [CustomerList.Pickup]: PickupCustomersIconNoOutline,
  [CustomerList.Delivery]: DeliveryCustomersIconNoOutline,
  [CustomerList.MostFrequent]: ValuableCustomersIconNoOutline,
  [CustomerList.MostValuable]: ValuableCustomersIconNoOutline,
};

export const getCustomerDisplayName = (
  firstName: string,
  lastName: string,
): string => {
  const rawFullName = `${firstName} ${lastName}`;
  const adjustedFullName = rawFullName.replaceAll('None', '').trim();

  return adjustedFullName.length === 0 ? '[Name is blank]' : adjustedFullName;
};
