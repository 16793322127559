import { CSSProperties } from 'react';
import {
  EventHandler,
  MenuItem,
  MenuRadioGroup,
  RadioChangeEvent,
} from '@szhsin/react-menu';

import Text from 'components/shared/text';

import styles from './styles.module.scss';

type Props = {
  colorHex: string;
  handleRadioChange: EventHandler<RadioChangeEvent>;
};

const menuItemColors = [
  { label: 'Gray', value: '#f7f7f7' },
  { label: 'Red', value: '#fbe8e7' },
  { label: 'Orange', value: '#fbe8c2' },
  { label: 'Yellow', value: '#ffffe9' },
  { label: 'Green', value: '#f5ffe9' },
  { label: 'Blue', value: '#e9f1fe' },
  { label: 'Purple', value: '#e8e7fd' },
  { label: 'Neutral', value: '#fefbf8' },
  { label: 'White', value: '#f7f6f4' },
];

export const MenuItemColors = ({ colorHex, handleRadioChange }: Props) => {
  return (
    <MenuRadioGroup value={colorHex} onRadioChange={handleRadioChange}>
      {menuItemColors.map(({ label, value }) => (
        <MenuItem
          key={value}
          style={{ '--option-color': value } as CSSProperties}
          type="radio"
          value={value}
        >
          <Text className={styles.optionText}>{label}</Text>
        </MenuItem>
      ))}
    </MenuRadioGroup>
  );
};
