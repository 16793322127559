import PropTypes from 'prop-types';

import styles from './styles.module.scss';

const NavigationSection = ({ children, title }) => (
  <div className={styles.section}>
    {title && <div className={styles.sectionTitle}>{title}</div>}
    {children}
  </div>
);

NavigationSection.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
};

NavigationSection.defaultProps = {
  title: '',
  children: null,
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default NavigationSection;
