import * as React from "react";
import { forwardRef } from "react";
const SvgChevronDownIcon = ({
  title,
  titleId,
  ...props
}, ref) => {
  return /* @__PURE__ */ React.createElement("svg", { width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", "aria-hidden": "true", ref, "aria-labelledby": titleId, ...props }, title === void 0 ? /* @__PURE__ */ React.createElement("title", { id: titleId }, "Chevron Down Icon") : title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M14.7727 15.8515L22 8.62421L20.4988 6.89331L12 13.2674L3.5012 6.89331L2 8.62421L9.22731 15.8515C9.96267 16.5869 10.96 17 12 17C13.04 17 14.0373 16.5869 14.7727 15.8515Z", fill: "currentColor" }));
};
const ForwardRef = forwardRef(SvgChevronDownIcon);
export default ForwardRef;
