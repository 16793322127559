import { useEffect, useState } from 'react';

import { Button } from 'crust';

import ContentTile from 'components/shared/content-tile';
import { copyTextToClipboard } from 'utilities/misc';

import PinDisplay from './pin-display';

import styles from './styles.module.scss';

type Props = {
  generatePin: () => void;
  isDisabled?: boolean;
  pin?: string;
};

const GeneratePinTile = ({ generatePin, isDisabled = false, pin }: Props) => {
  const [isCopyClicked, setIsCopyClicked] = useState(false);

  useEffect(() => {
    if (isCopyClicked) {
      const timeout = setTimeout(() => {
        setIsCopyClicked(false);
      }, 2000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [isCopyClicked]);

  const handleOnCopyClick = () => {
    if (!isCopyClicked) {
      copyTextToClipboard(pin, false);
      setIsCopyClicked(true);
    }
  };

  return (
    <ContentTile className={styles.generatePinTile} title="Generate PIN">
      <div className={styles.description}>
        A PIN is used to verify actions in Register.
      </div>
      <PinDisplay pin={pin} />
      <hr className={styles.divider} />
      {isDisabled && (
        <p className={styles.description}>
          Please save this user in order to Generate a New PIN for them.
        </p>
      )}
      <div className={styles.buttonsWrapper}>
        <Button
          className={styles.button}
          isDisabled={isDisabled || isCopyClicked || (pin ? false : true)}
          onPress={handleOnCopyClick}
          variant="secondary"
        >
          {isCopyClicked ? 'Copied!' : 'Copy'}
        </Button>
        <Button
          className={styles.button}
          isDisabled={isDisabled}
          onPress={generatePin}
          data-chameleon-target="Save Register User Pin"
          variant="primary"
        >
          Generate
        </Button>
      </div>
    </ContentTile>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default GeneratePinTile;
