import cx from 'classnames';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

const Legend = ({ content, isHorizontal, onClick, legendTitleClassName }) => (
  <div
    className={cx(styles.legend, isHorizontal && styles.horizontal)}
    aria-label="Chart legend"
  >
    {content.map((legendItem) => (
      <div className={styles.legendItem} key={legendItem.id}>
        <div
          className={styles.legendIcon}
          style={{ backgroundColor: legendItem.color }}
          // Inline style used as this is the only variable in the style and is used elsewhere (by charts).
          // Avoiding extra complexity of custom SCSS classes, combined with graphColors defined in utilities/colors.
        />
        <div className={styles.legendText} aria-label="Legend entry">
          <div
            className={cx(styles.legendTitle, legendTitleClassName)}
            onClick={() => onClick(legendItem.id)}
            role="button"
            tabIndex={0}
            onKeyDown={() => onClick(legendItem.id)}
          >
            {legendItem.id}
          </div>
          <div>{legendItem.legendText}</div>
        </div>
      </div>
    ))}
  </div>
);

Legend.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string,
      id: PropTypes.string,
      legendText: PropTypes.string,
    }),
  ),
  isHorizontal: PropTypes.bool,
  onClick: PropTypes.func,
  legendTitleClassName: PropTypes.string,
};

Legend.defaultProps = {
  content: [],
  isHorizontal: false,
  onClick: () => {},
  legendTitleClassName: '',
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default Legend;
