import cx from 'classnames';

import styles from './styles.module.scss';

type Props = {
  className?: string;
  label: string;
  value: string;
};

const Metric = ({ className, label, value }: Props) => {
  return (
    <div className={cx(className)}>
      <div className={styles.label}>{label}</div>
      <div className={styles.value}>{value}</div>
    </div>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default Metric;
