import {
  ExactFilterTerm,
  FilterCondition,
  MatchFilterTerm,
  OpasExactFilter,
  OpasFilters,
  OpasMatchFilter,
} from 'types/opas';

export const createExactFilterTerm = (
  value: string | number,
  condition: FilterCondition = FilterCondition.Is,
): ExactFilterTerm => {
  return {
    condition,
    value,
  };
};

export const createExactFilter = (
  key: string,
  value: string | number,
  filterCondition: FilterCondition = FilterCondition.Is,
): OpasExactFilter => {
  return {
    key,
    term: createExactFilterTerm(value, filterCondition),
  };
};

export const createMatchFilterTerm = (
  values: string[] | number[],
  filterCondition: FilterCondition = FilterCondition.Is,
): MatchFilterTerm => {
  return {
    condition: filterCondition,
    values,
  };
};

export const createMatchFilter = (
  key: string,
  terms: MatchFilterTerm[],
): OpasMatchFilter => {
  return {
    key,
    terms,
  };
};

export const createOpasFilters = (
  exactFilters: OpasExactFilter[],
  matchFilters?: OpasMatchFilter[],
): OpasFilters => {
  return {
    filters: [
      {
        exact: exactFilters,
        ...(matchFilters && { match: matchFilters }),
      },
    ],
  };
};
