import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import { Link } from 'crust';

import { RHFCheckbox } from 'components/shared/rhf-checkbox';
import { RHFFileUpload } from 'components/shared/rhf-file-upload';
import Modal from 'components/shared/slice-modal';
import { useCreateCustomerUploadMutation } from 'hooks/customers/use-create-customer-upload-mutation';
import useAnalytics from 'hooks/use-analytics';
import { customerImportTemplateUrl } from 'routes/external-urls';
import { ImportCustomersFormValues } from 'types/customers/profile/customer-profile';
import { Shop } from 'types/shops';
import {
  showInvalidSubmitToast,
  showUnexpectedErrorToast,
} from 'utilities/forms';

import styles from './styles.module.scss';

// 100 MB
const MAX_FILE_SIZE = 1048576 * 100;

export const importCustomersFormValidationSchema = z.object({
  customerCsv: z
    .custom<File[]>((files) => {
      if (!Array.isArray(files)) {
        return false;
      }

      return !!files.length;
    }, 'Please choose a file.')
    .refine((files) => {
      // To account for windows machines with excel installed making csv mime types application/vnd.ms-excel
      // we check the file name extension in combination with that mime type and consider it still valid.
      const extension = files.at(0)?.name.split('.').at(-1)?.toLowerCase();

      return (
        files.at(0)?.type === 'text/csv' ||
        (files.at(0)?.type === 'application/vnd.ms-excel' &&
          extension === 'csv')
      );
    }, 'Only CSV files (.csv) are supported. Please try again.')
    .refine(
      (files) => files.at(0)?.size ?? 0 < MAX_FILE_SIZE,
      'Please choose a file smaller than 100MB.',
    ),
  shouldMerge: z.boolean(),
});

type ImportModalProps = {
  isOpen: boolean;
  onClose: () => void;
  shopId: Shop['shopId'];
};

const DownloadLink = () => {
  return (
    <Link href={customerImportTemplateUrl} icon="sparkle" variant="secondary">
      Download our CSV template
    </Link>
  );
};

export const ImportModal = ({ isOpen, onClose, shopId }: ImportModalProps) => {
  const { trackClickedCustomerImport } = useAnalytics();
  const {
    control,
    formState: { isValid, errors },
    getValues,
    handleSubmit,
    watch,
  } = useForm<ImportCustomersFormValues>({
    defaultValues: {
      shouldMerge: false,
    },
    resolver: zodResolver(importCustomersFormValidationSchema),
    mode: 'onChange',
    shouldUnregister: true,
  });

  const {
    mutate: createCustomerUploadRequest,
    isLoading: isCreateCustomerUploadRequestLoading,
  } = useCreateCustomerUploadMutation(shopId);

  const selectedCsvWatch = watch('customerCsv');
  const hasFileSelected = selectedCsvWatch && !!selectedCsvWatch.at(0);
  const hasFileSelectionError = !!errors.customerCsv;

  const handleValidSubmit: SubmitHandler<ImportCustomersFormValues> = (
    formValues,
  ) => {
    const file = formValues.customerCsv.at(0);

    if (file) {
      createCustomerUploadRequest(
        {
          customerCsv: file,
          shouldMerge: formValues.shouldMerge,
        },
        {
          onError: () => {
            showUnexpectedErrorToast();
          },
          onSuccess: (isUploadSuccess) => {
            if (isUploadSuccess) {
              toast.success(
                'Your import is being processed. Please check back soon.',
              );
              onClose();
            } else {
              showUnexpectedErrorToast();
            }
          },
        },
      );
    }
  };

  const handleCloseModal = () => {
    if (!isCreateCustomerUploadRequestLoading) {
      onClose();
    }
  };

  const onClickedImport = () => {
    trackClickedCustomerImport({
      shopId,
      shouldMerge: getValues('shouldMerge'),
    });
  };

  return (
    <Modal
      className={styles.modal}
      footerClassName={styles.footer}
      fadeClassName={styles.fade}
      formId="import-customers-modal-form"
      header="Import Customers"
      hideCloseIcon
      isFullScreenMobile
      hasDownloadLink
      downloadLink={<DownloadLink />}
      isOpen={isOpen}
      noButtonText="Cancel"
      onClickNo={handleCloseModal}
      onClickYes={onClickedImport}
      onRequestClose={handleCloseModal}
      yesButtonText="Import"
      isNoButtonDisabled={isCreateCustomerUploadRequestLoading}
      isYesButtonDisabled={!isValid || isCreateCustomerUploadRequestLoading}
      shouldCloseOnOverlayClick={!isCreateCustomerUploadRequestLoading}
      shouldCloseOnEsc={!isCreateCustomerUploadRequestLoading}
    >
      <form
        id="import-customers-modal-form"
        onSubmit={handleSubmit(handleValidSubmit, showInvalidSubmitToast)}
      >
        <RHFFileUpload
          acceptedFileTypes={['text/csv']}
          className={styles.fileUpload}
          control={control}
          description="Maximum 10,000 rows"
          isRequired
          label="Select CSV"
          maxFileSize={MAX_FILE_SIZE}
          name="customerCsv"
        />
        {hasFileSelected && !hasFileSelectionError && (
          <RHFCheckbox
            className={styles.mergeCheckbox}
            control={control}
            label="Merge with existing customers that have the same email or phone"
            name="shouldMerge"
          />
        )}
      </form>
    </Modal>
  );
};
