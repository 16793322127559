import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';

import FormFeedback from 'components/shared/form-feedback';
import InputDescription from 'components/shared/input-description';
import Label from 'components/shared/label';
import Select from 'components/shared/slice-select';
import { printModeSelectOptions } from 'utilities/register-printers';

const PrintModeInput = ({ control, error, inputIdPrefix }) => (
  <>
    <Label id="print-mode-label" htmlFor={`${inputIdPrefix}-print-mode`}>
      Print Mode
    </Label>
    <Controller
      control={control}
      name="printMode"
      rules={{ required: 'Please select a print mode.' }}
      render={({ field }) => (
        <Select
          aria-describedby={`${inputIdPrefix}-print-mode-description`}
          aria-labelledby={`${inputIdPrefix}-print-mode-label`}
          inputId={`${inputIdPrefix}-print-mode`}
          isInvalid={error != null}
          options={printModeSelectOptions}
          {...field}
        />
      )}
    />
    <FormFeedback feedback={error?.message} />
    <InputDescription id={`${inputIdPrefix}-print-mode-description`}>
      &quot;After Payments&quot; prints a complete Order Ticket only after
      payment occurs.
    </InputDescription>
  </>
);

PrintModeInput.propTypes = {
  control: PropTypes.object.isRequired,
  error: PropTypes.object,
  inputIdPrefix: PropTypes.string.isRequired,
};

PrintModeInput.defaultProps = {
  error: null,
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default PrintModeInput;
