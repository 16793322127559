import cx from 'classnames';

import styles from './styles.module.scss';

type Props = {
  className?: string;
  dataTestId?: string;
  hideDecimalsAboveValue?: number;
  isNotAvailable?: boolean;
  maxFontSize?: number;
  notAvailableText?: string;
  reduceFontSizeAtDigits?: number;
  type?: 'number' | 'currency' | 'percent' | 'hours' | 'minutes';
  value: number;
};

const MetricTileValue = ({
  className,
  dataTestId,
  hideDecimalsAboveValue,
  isNotAvailable = false,
  maxFontSize = 24,
  notAvailableText = '--',
  reduceFontSizeAtDigits = 10,
  type = 'number',
  value,
}: Props) => {
  // Only display the fraction digits if they exist.
  // Suppress fraction digits above a certain value, if specified.
  const numFractionDigits =
    Number.isInteger(value) ||
    (hideDecimalsAboveValue && value > hideDecimalsAboveValue)
      ? 0
      : 2;

  const formatted = value.toLocaleString('en-US', {
    maximumFractionDigits: numFractionDigits,
    minimumFractionDigits: numFractionDigits,
  });

  // The bigger the number gets, the smaller we have to make the font size so
  // that the number continues to fit within the fixed width tile without
  // overlapping the tile link. Determining with precision the largest font
  // size available for the given content and container size is a very difficult
  // task, so we'll just estimate here.
  const length = formatted.replace(/[.,]/g, '').length;

  // Up to 10 significant digits looks fine at 28px, so we will penalize each
  // additional digit 2px of font size. Use Math.max to ensure that our maximum
  // font size is 28px.
  const fontSize =
    maxFontSize - Math.max(0, length - reduceFontSizeAtDigits) * 2;

  return (
    <div
      className={cx(styles.value, className)}
      data-testid={dataTestId}
      style={{ fontSize: `${fontSize}px` }}
    >
      {isNotAvailable ? (
        notAvailableText
      ) : (
        <>
          {type === 'currency' && <span>$</span>}
          {formatted}
          {type === 'percent' && <span>%</span>}
          {type === 'hours' && <span>hr</span>}
          {type === 'minutes' && <span>min</span>}
        </>
      )}
    </div>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default MetricTileValue;
