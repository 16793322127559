import * as React from "react";
import { forwardRef } from "react";
const SvgFontPicker = ({
  title,
  titleId,
  ...props
}, ref) => {
  return /* @__PURE__ */ React.createElement("svg", { width: 24, height: 25, viewBox: "0 0 24 25", fill: "none", xmlns: "http://www.w3.org/2000/svg", ref, "aria-labelledby": titleId, ...props }, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { d: "M8 6.5H14M20 6.5H14M14 6.5V20.5", stroke: "#211E1E", strokeWidth: 2 }), /* @__PURE__ */ React.createElement("path", { d: "M3 12.5H7M11 12.5H7M7 12.5V20.5", stroke: "#211E1E", strokeWidth: 2 }));
};
const ForwardRef = forwardRef(SvgFontPicker);
export default ForwardRef;
