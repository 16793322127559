import { useMemo, useState } from 'react';

import useStoredState from 'hooks/use-stored-state';
import { Shop } from 'types/shops';
import { getIsStoredDateValid } from 'utilities/salesforce';

export const useRegisterUpsellModal = (shopId: Shop['shopId']) => {
  const [openedModal, setOpenedModal] = useState(false);

  const [caseExpiration, setCaseExpiration] = useStoredState<string>(
    `sf-${shopId}-register-upsell-expires-at`,
  );

  const hasValidCase = useMemo(() => {
    return getIsStoredDateValid(caseExpiration);
  }, [caseExpiration]);

  const handleOpenModalClick = () => {
    setOpenedModal(true);
  };

  const handleModalClose = () => {
    setOpenedModal(false);
  };

  return {
    handleModalClose,
    handleOpenModalClick,
    hasValidCase,
    openedModal,
    setCaseExpiration,
    setOpenedModal,
  };
};
