import { useMutation } from '@tanstack/react-query';

import useAnalytics from 'hooks/use-analytics';
import useApi from 'hooks/use-api';
import { MenuPhotoUploadResponseBody } from 'types/menu/api';

type Props = {
  page: string;
  pageDetail?: string;
  productId: number;
  productName: string;
  shopId: string;
};

export const usePhotoUploadMutation = ({
  page,
  pageDetail,
  productId,
  productName,
  shopId,
}: Props) => {
  const { authenticatedFetch } = useApi();

  const { trackProductImageUpload } = useAnalytics();

  return useMutation({
    mutationFn: async (image: string | Blob) => {
      const data = new FormData();
      data.append('image', image);

      const response = await authenticatedFetch.postFile(
        `api/management/v1/shops/${shopId}/product_images`,
        data,
      );

      return response as MenuPhotoUploadResponseBody;
    },
    onSuccess: (response, image) => {
      const url = response.sources.imgix;

      trackProductImageUpload({
        id: productId,
        shopId,
        productImageFile: image,
        imageUrl: url,
        page,
        pageDetail,
        productName,
        successful: true,
      });
    },
    onError: (_, image) => {
      trackProductImageUpload({
        id: productId,
        shopId,
        productImageFile: image,
        imageUrl: '',
        page,
        pageDetail,
        productName,
        successful: false,
      });
    },
  });
};
