import PasswordResetEmailForm from 'components/password-reset-email';
import Page from 'components/shared/page';

const pageName = 'reset-password-email';

const ResetPassword = () => (
  <Page
    name={pageName}
    isNavShown={false}
    isHeaderShown={false}
    isFooterShown={false}
  >
    <PasswordResetEmailForm />
  </Page>
);

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default ResetPassword;
