import * as React from "react";
import { forwardRef } from "react";
const SvgChartIcon = ({
  title,
  titleId,
  ...props
}, ref) => {
  return /* @__PURE__ */ React.createElement("svg", { width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", "aria-hidden": "true", ref, "aria-labelledby": titleId, ...props }, title === void 0 ? /* @__PURE__ */ React.createElement("title", { id: titleId }, "Chart Icon") : title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { d: "M11.3688 2C6.13939 2.32731 2 6.6311 2 11.8925V12.0874C2 17.5618 6.48173 22 12.0099 22C17.323 22 21.6691 17.9008 21.9996 12.7219C21.9897 12.7224 21.9799 12.7225 21.9699 12.7225H13.0943C12.1412 12.7225 11.3686 11.9574 11.3686 11.0136L11.3688 2Z", fill: "currentColor" }), /* @__PURE__ */ React.createElement("path", { d: "M22 11.2584C21.6871 6.35439 17.7737 2.41889 12.848 2.01459V11.0139C12.848 11.1488 12.9583 11.258 13.0945 11.258H21.9701C21.9801 11.258 21.9901 11.2579 22 11.2584Z", fill: "currentColor" }));
};
const ForwardRef = forwardRef(SvgChartIcon);
export default ForwardRef;
