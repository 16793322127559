import { Button, Link } from 'crust';

import Header from 'components/shared/header';
import useAnalytics from 'hooks/use-analytics';
import { registerUserGuide as registerUserGuideUrl } from 'routes/external-urls';
import { ClickedExternalLinkType } from 'types/shared/analytics';

import styles from './styles.module.scss';

type Props = {
  onClickCreateProfile: () => void;
  shopId: string;
};

const RegisterProfilesHeader = ({ onClickCreateProfile, shopId }: Props) => {
  const { trackExternalLinkClick } = useAnalytics();

  const handleLinkClick = () => {
    trackExternalLinkClick({
      linkLabel: 'register user guide',
      page: 'system profiles',
      shopId,
      type: ClickedExternalLinkType.LinkedText,
    });
  };

  return (
    <div className={styles.header}>
      <Header
        title="Manage Register Profiles"
        content={[
          <>
            {`A 'Profile' is used in multi-Register installations to enable you to restrict feature access. Learn more in the `}
            <Link
              className={styles.titleLink}
              href={registerUserGuideUrl}
              onPress={handleLinkClick}
              variant="tertiary"
            >
              Register User Guide
            </Link>
            .
          </>,
        ]}
      />
      <Button
        className={styles.addProfileButton}
        onPress={onClickCreateProfile}
        variant="secondary"
      >
        Create New Profile
      </Button>
    </div>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default RegisterProfilesHeader;
