/* eslint-disable @typescript-eslint/no-explicit-any */
import { curveMonotoneX, line } from 'd3-shape';

/*
  There are issues with types, in particular for `xScale` and `yScale`.
  The easiest way around this issue is to make use of `any`.
  This was done as an alternative to keeping this file in JS rather than TS.

  For more info:
  https://github.com/plouc/nivo/issues/1947
  https://github.com/plouc/nivo/issues/2173
*/
type Props = {
  series: any;
  xScale: any;
  yScale: any;
};

export const RegionAverageAvailabilityLineLayer = ({
  series,
  xScale,
  yScale,
}: Props) => {
  const lineGenerator = line()
    .x((d: any) => xScale(d.data.x))
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    .y((d: any) => yScale(d.data.y))
    .curve(curveMonotoneX); // https://d3js.org/d3-shape/area#area_curve

  return (
    <>
      <path
        d={lineGenerator(series[1].data) as string}
        fill="none"
        stroke="var(--op-color-hours-analytics-chart-region-average)"
        strokeDasharray="4 4"
        strokeWidth={1}
        strokeOpacity={1.0}
      />
    </>
  );
};
