import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import SubmitFooter from 'components/shared/submit-footer';
import { useSaveCategoryMutation } from 'hooks/menu/use-save-category-mutation';
import useAnalytics from 'hooks/use-analytics';
import * as paths from 'routes/paths';
import { MenuCategoryResponseBody } from 'types/menu/api';
import { MenuCategoryFormValues } from 'types/menu/category';
import { Shop } from 'types/shops';
import {
  showInvalidSubmitToast,
  showUnexpectedErrorToast,
} from 'utilities/forms';
import { getDefaultCategoryFormValues } from 'utilities/menu';

import CategoryForm from './form';

type Props = {
  original?: MenuCategoryResponseBody;
  shopId: Shop['shopId'];
};

const MenuCategory = ({ original, shopId }: Props) => {
  const navigate = useNavigate();
  const { trackCategoryCreated, trackCategoryUpdated } = useAnalytics();
  const [defaultValues] = useState(() =>
    getDefaultCategoryFormValues(original),
  );

  const { control, formState, getValues, handleSubmit, setValue, trigger } =
    useForm({
      defaultValues,
      mode: 'onBlur',
    });

  const { errors, isSubmitting: isFormValidating, submitCount } = formState;

  const {
    isLoading: isCategorySaving,
    isSuccess: isCategorySaved,
    mutate: saveCategory,
  } = useSaveCategoryMutation(shopId, original?.category);

  const handleValidSubmit = (values: MenuCategoryFormValues) => {
    saveCategory(values, {
      onError: () => {
        showUnexpectedErrorToast();
      },
      onSuccess: (response) => {
        if (original) {
          trackCategoryUpdated(response.shopId, response.category.id);
        } else {
          trackCategoryCreated(response.shopId, response.category.id);
        }
        toast.success(`${response.category.name} Saved!`);
        navigate(paths.getMenuCategoriesPath(response.shopId));
      },
    });
  };

  const isSubmitting = isFormValidating || isCategorySaving;

  return (
    <CategoryForm
      control={control}
      errors={errors}
      getValues={getValues}
      onSubmit={handleSubmit(handleValidSubmit, showInvalidSubmitToast)}
      setValue={setValue}
      submitCount={submitCount}
      trigger={trigger}
    >
      <SubmitFooter
        onCancel={() => navigate(-1)}
        isSubmitting={isSubmitting}
        isSubmitDisabled={isSubmitting || isCategorySaved}
        submitButtonChameleonTarget="Menu Category Save Button"
      />
    </CategoryForm>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default MenuCategory;
