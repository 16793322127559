import { PointSymbolProps } from '@nivo/line';

type Props = {
  isMobile: boolean;
  pointIndexesToShow: number[];
  pointSymbolProps: PointSymbolProps;
};

export const PointSymbol = ({
  isMobile,
  pointIndexesToShow,
  pointSymbolProps,
}: Props) => {
  const pointIndex = pointSymbolProps.datum.x as number;

  // When on mobile, limit points to only those with corresponding
  // x-axis labels.
  if (!isMobile || pointIndexesToShow.includes(pointIndex)) {
    return (
      <circle
        r="4"
        fill="var(--op-color-graph1)"
        stroke="transparent"
        strokeWidth="0"
        style={{ pointerEvents: 'none' }}
      ></circle>
    );
  }

  return <></>;
};
