import {
  Control,
  useController,
  UseFormGetValues,
  UseFormSetValue,
} from 'react-hook-form';

import { SelectMenuOption } from 'components/website-tools/form/select-menu-option';
import useAnalytics from 'hooks/use-analytics';
import { ShopTraits } from 'types/shops';
import {
  BannerPosition,
  ButtonPosition,
  SmartButtonFormValues,
  WebsiteIntegrationsAnalyticsPageName,
  WidgetType,
} from 'types/website-tools';
import {
  formatOptionLabels,
  getDefaultPostionOptionForWidget,
  smartButtonAnalyticsFormatStrings,
} from 'utilities/website-tools';

import SelectMenu from '../../../form/select-menu';
import MenuItemIcon from '../menu-item-icon';

type Props = {
  buttonClassName?: string;
  control: Control<SmartButtonFormValues>;
  getValues: UseFormGetValues<SmartButtonFormValues>;
  setIsFormChanged: (isFormChanged: boolean) => void;
  setValue: UseFormSetValue<SmartButtonFormValues>;
  shopId: number;
  shopTraits: ShopTraits;
};

const FromatDropdown = ({
  buttonClassName,
  control,
  getValues,
  setIsFormChanged,
  setValue,
  shopId,
  shopTraits,
}: Props) => {
  const {
    field: { onChange, value },
    fieldState: { isDirty },
  } = useController({
    control,
    name: 'format',
  });

  const { trackClickedWebsiteIntegrationsEditorDropdown } = useAnalytics();

  const handleChangeWidgetType = (next: string) => {
    const current = getValues('format');

    if (next === WidgetType.Banner) {
      if (current !== next) {
        setValue(
          'position',
          getDefaultPostionOptionForWidget(next as WidgetType)
            .value as BannerPosition,
        );
      }
    } else {
      // If we are switching from button to sticker or vice versa
      // we keep the position selected as they use the same values
      if (current === WidgetType.Banner) {
        setValue(
          'position',
          getDefaultPostionOptionForWidget(next as WidgetType)
            .value as ButtonPosition,
        );
      }
    }

    onChange(next);
    setIsFormChanged(true);
  };

  const handleOnBlur = () => {
    trackClickedWebsiteIntegrationsEditorDropdown({
      field: 'format',
      shopId: String(shopId),
      isActive: String(shopTraits.isSmartButtonActive),
      optionSelected: isDirty ? smartButtonAnalyticsFormatStrings[value] : null,
      page: WebsiteIntegrationsAnalyticsPageName.SmartButtons,
    });
  };

  return (
    <SelectMenu
      buttonClassName={buttonClassName}
      buttonId="format-dropdown"
      label={formatOptionLabels[value]}
      onChange={handleChangeWidgetType}
      onBlur={handleOnBlur}
    >
      {Object.values(WidgetType).map((widgetType) => {
        const isActive = value === widgetType;

        return (
          <SelectMenuOption
            isActive={isActive}
            isRecommended={widgetType === WidgetType.Banner}
            key={widgetType}
            label={formatOptionLabels[widgetType]}
            value={widgetType}
          >
            <MenuItemIcon isActive={isActive} widgetType={widgetType} />
          </SelectMenuOption>
        );
      })}
    </SelectMenu>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default FromatDropdown;
