import Suspended from 'components/shared/suspended';
import { TooltipIconButton } from 'components/shared/tooltips';
import { useShopScoreQuery } from 'hooks/shops';
import { ShopScoreAdditionalTraits } from 'types/shops';
import { scoreAsPercentage } from 'utilities/shops';

import {
  MetricTile,
  MetricTileContent,
  MetricTileHeader,
  MetricTileTitle,
} from '../metric-tile';

import NetworkStatusBar from './bar';
import NetworkStatusLegend from './legend';

import styles from './styles.module.scss';

const tooltipText =
  'Your network status shows how you compare to other restaurants on Slice';

type Props = {
  className?: string;
  shopId: string;
};

const NetworkStatus = ({ className, shopId }: Props) => {
  const { data: shopScoreData, isLoading } = useShopScoreQuery(shopId);

  // The shop score values come from the API as decimals (e.g., 0.50 means 50%).
  const shopScore = shopScoreData?.score
    ? scoreAsPercentage(shopScoreData.score)
    : 0;

  // The average values come from the API as percentages (e.g., 50 means 50%).
  const averageScore =
    shopScoreData?.traitsWithValues?.[ShopScoreAdditionalTraits.NetworkScore] ??
    0;

  return (
    <MetricTile className={className}>
      <MetricTileContent className={styles.content}>
        <MetricTileHeader>
          <MetricTileTitle>Network Status</MetricTileTitle>
          <TooltipIconButton label="network status info">
            {tooltipText}
          </TooltipIconButton>
        </MetricTileHeader>
        <Suspended isLoading={isLoading}>
          <NetworkStatusLegend />
          <NetworkStatusBar shopScore={shopScore} averageScore={averageScore} />
        </Suspended>
      </MetricTileContent>
    </MetricTile>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default NetworkStatus;
