import { PropsWithChildren } from 'react';
import { Menu, MenuButton } from '@szhsin/react-menu';

import CaretIcon from 'images/dropdown-arrow.svg?react';

import styles from './styles.module.scss';

type Props = {
  buttonClassName?: string;
  buttonId: string;
  label: string;
  onBlur?: () => void;
  onChange: (value: string) => void;
};

const SelectMenu = ({
  buttonClassName,
  buttonId,
  children,
  label,
  onBlur,
  onChange,
}: PropsWithChildren<Props>) => {
  return (
    <Menu
      menuButton={({ open }) => {
        const style = open
          ? 'menuButtonCaretIconOpen'
          : 'menuButtonCaretIconClosed';
        return (
          <MenuButton className={buttonClassName} id={buttonId}>
            <div className={styles.menuButtonText}>{label}</div>
            <CaretIcon aria-hidden="true" className={styles[style]} />
          </MenuButton>
        );
      }}
      onItemClick={({ value }) => {
        onChange(value);
      }}
      onMenuChange={({ open }) => {
        if (!open && onBlur) {
          onBlur();
        }
      }}
    >
      {children}
    </Menu>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default SelectMenu;
