type Props = {
  type: string;
};

export const DownloadTypeCell = ({ type }: Props) => {
  const types: Record<string, string> = {
    customers_download: 'All Customers',
    online_customers_download: 'Online Customers',
    offline_customers_download: 'Offline Customers',
  };

  return <p>{types[type]}</p>;
};
