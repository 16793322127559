import { CustomerPaginationInfo, DateRange } from 'types/customers';
import { dateRangeLabels } from 'utilities/customers';

type Props = {
  dateRange: DateRange;
  pagination: CustomerPaginationInfo | undefined;
};

const CustomerSegmentsTableResultsInfo = ({ pagination, dateRange }: Props) => {
  let paginationText = '';

  if (pagination) {
    const { currentPage, perPage, results } = pagination;

    if (results === 0) {
      paginationText = 'No Results';
    } else {
      paginationText = `${(currentPage - 1) * perPage + 1} - ${Math.min(
        perPage * currentPage,
        results,
      )} of ${results}`;
    }
  }

  return (
    <div>
      Results for {dateRangeLabels[dateRange]}
      {pagination ? `: ${paginationText}` : ''}
    </div>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default CustomerSegmentsTableResultsInfo;
