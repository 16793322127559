import * as React from "react";
import { forwardRef } from "react";
const SvgDelete = ({
  title,
  titleId,
  ...props
}, ref) => {
  return /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 22 22", ref, "aria-labelledby": titleId, ...props }, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { fill: "none", fillRule: "evenodd", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round", d: "M20.565 11c0 5.282-4.283 9.565-9.565 9.565-5.283 0-9.565-4.283-9.565-9.565 0-5.283 4.282-9.565 9.565-9.565 5.282 0 9.565 4.282 9.565 9.565h0zm-5.876-3.689L7.31 14.69m7.379 0L7.31 7.31" }));
};
const ForwardRef = forwardRef(SvgDelete);
export default ForwardRef;
