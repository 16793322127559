import cx from 'classnames';

import styles from './styles.module.scss';

type Props = {
  children: React.ReactNode;
  className?: string;
};

const MetricTileContent = ({ children, className }: Props) => (
  <div className={cx(styles.content, className)}>{children}</div>
);

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default MetricTileContent;
