import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { z } from 'zod';

const schema = z.union([
  z.coerce.number().int().nonnegative(),
  z.literal('new'),
]);

export const useCurrentProductId = () => {
  const { productId } = useParams();
  const result = useMemo(() => schema.safeParse(productId), [productId]);

  if (result.success) {
    return result.data;
  }

  // Until we have an error handling pattern for undefined and invalid params,
  // treat them as new.
  return 'new';
};
