import { QueryKey, useQuery } from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { ShopProfile } from 'types/brand-manager';

const getQueryKey = (shopId: string): QueryKey => [shopId, 'shopProfile'];

export const useShopProfileQuery = (shopId: string) => {
  const { authenticatedFetch } = useApi();

  return useQuery({
    queryKey: getQueryKey(shopId),
    queryFn: async () => {
      const response = await authenticatedFetch.get(
        `api/management/v1/brand_manager/shop_profile/${shopId}`,
      );
      return response as ShopProfile;
    },
  });
};
