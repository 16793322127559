import { CSSProperties } from 'react';
import { RowData } from '@tanstack/react-table';
import cx from 'classnames';

import {
  AutomaticTableProps,
  BaseTableProps,
  OverloadedTableComponent,
} from 'types/shared/table';

import TableBody from './body';
import TableHeader from './header';

import styles from './styles.module.scss';

const BaseTable = ({
  children,
  className,
  numColumns,
  ...props
}: BaseTableProps) => (
  <table
    className={cx(styles.table, className)}
    style={
      {
        '--columns': numColumns,
      } as CSSProperties
    }
    {...props}
  >
    {children}
  </table>
);

export const Table: OverloadedTableComponent = <TData extends RowData>(
  props: BaseTableProps | AutomaticTableProps<TData>,
) => {
  // Pass the result of useReactTable to automatically render the table.
  if ('table' in props) {
    const { table, ...base } = props;

    return (
      <BaseTable numColumns={table.getVisibleLeafColumns().length} {...base}>
        <TableHeader table={table} />
        <TableBody table={table} />
      </BaseTable>
    );
  }

  return <BaseTable {...props} />;
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default Table;
