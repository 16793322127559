import { useFieldArray } from 'react-hook-form';
import PropTypes from 'prop-types';

import { Button } from 'crust';

import Label from 'components/shared/label';
import {
  AnalyticsField,
  createFormProductType,
  ProductField,
  ProductTypeField,
} from 'utilities/menu';

import ProductType from './product-type';

import styles from './styles.module.scss';

const ProductTypes = ({
  control,
  errors,
  getValues,
  register,
  setValue,
  submitCount,
  shopId,
}) => {
  const { append, fields, remove } = useFieldArray({
    control,
    keyName: 'key', // Don't confuse the field key with the product type id.
    name: ProductField.ProductTypes,
  });

  const handleAdd = () => {
    append(createFormProductType());
    setValue(AnalyticsField.TypeAdded, true);
  };

  const handleRemove = (index) => {
    remove(index);
    setValue(AnalyticsField.TypeRemoved, true);
  };

  return (
    <div className={styles.container}>
      <Label className={styles.nameHeader}>Type</Label>
      <Label className={styles.priceHeader}>Price</Label>
      {fields.map((field, index) => (
        <ProductType
          control={control}
          getValues={getValues}
          id={field[ProductTypeField.Id]}
          index={index}
          key={field.key}
          length={fields.length}
          nameError={errors?.[index]?.[ProductTypeField.Name]}
          priceError={errors?.[index]?.[ProductTypeField.Price]}
          register={register}
          removeField={fields.length > 1 ? () => handleRemove(index) : null}
          setValue={setValue}
          shopId={shopId}
          submitCount={submitCount}
        />
      ))}
      <Button
        appearance="link"
        className={styles.addButton}
        onPress={handleAdd}
      >
        Add More
      </Button>
      <input type="hidden" {...register(AnalyticsField.TypeAdded)} />
      <input type="hidden" {...register(AnalyticsField.TypeRemoved)} />
      <input type="hidden" {...register(AnalyticsField.TypeNameChanged)} />
      <input type="hidden" {...register(AnalyticsField.TypePriceChanged)} />
    </div>
  );
};

ProductTypes.propTypes = {
  control: PropTypes.object.isRequired,
  getValues: PropTypes.func.isRequired,
  errors: PropTypes.array,
  register: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  submitCount: PropTypes.number.isRequired,
  shopId: PropTypes.string.isRequired,
};

ProductTypes.defaultProps = {
  errors: null,
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default ProductTypes;
