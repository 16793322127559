import { z } from 'zod';

export const businessProfileLocationSchema = z.object({
  name: z.string(),
  title: z.string(),
  websiteUri: z.string().nullable(),
  storefrontAddress: z
    .object({
      addressLines: z.array(z.string()).max(5),
      languageCode: z.string().optional(),
      locality: z.string().optional(),
      postalCode: z.string().optional(),
      regionCode: z.string(),
    })
    .nullable(),
  metadata: z.object({
    hasVoiceOfMerchant: z.boolean().nullable(),
  }),
});

// Temporarily amend the main Location schema until we're doing a better
// job defining separate response types for these in the API based on which
// fields from Google are present in different scenarios.
const searchLocationSchema = businessProfileLocationSchema.omit({
  name: true,
  metadata: true,
});

export const businessProfileLocationsSearchSchema = z.object({
  googleLocations: z.array(
    z.object({
      name: z.string(),
      requestAdminRightsUri: z.string().nullable(),
      location: searchLocationSchema,
    }),
  ),
});

export const createLocationResponseSchema = z.object({
  location: businessProfileLocationSchema.pick({
    name: true,
    title: true,
    storefrontAddress: true,
  }),
});

export const businessProfileSchema = z.object({
  googleLocation: businessProfileLocationSchema,
  googleLocationId: z.string(),
  id: z.number(),
  shopId: z.number(),
  state: z.string(),
});
