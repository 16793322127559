import styles from './styles.module.scss';

export const onStripeInputChange =
  (inputName, field, setError, clearErrors) => (event) => {
    // The event object returned from the stripe components does not contain the full value.
    // Instead we can get an event.error and event.complete value so we manually update
    // the form values based on these. The card inputs will either have a value of 'complete'
    // or an empty string value in react-hook-form to know when an input has been entered correctly.
    if (event?.error?.message) {
      setError(inputName, {
        type: 'manual',
        message: event.error.message,
      });
    } else {
      clearErrors(inputName);
    }

    field.onChange(event.complete ? 'complete' : '');
  };

export const getStripeInputOptions = (baseStyle = styles.validInput) => ({
  // The colors below are hard-coded due to the form being rendered within an iframe,
  // which limits access to our CSS custom properties.
  style: {
    base: {
      color: '#211E1E', // --crust-color-text-default
      fontFamily: 'Open Sans, sans-serif',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: '400',
      letterSpacing: 'normal',
      lineHeight: '38px',
      '::placeholder': {
        color: '#777675', // --crust-color-text-subtlest
      },
    },
    invalid: {
      color: '#da3327', // --crust-color-text-critical
    },
  },
  classes: {
    base: baseStyle,
    focus: styles.validInputFocussed,
    invalid: styles.invalidInput,
  },
});
