import cx from 'classnames';

import { Icon, type IconProps } from 'crust';

import Link, { LinkProps } from 'components/shared/link';
import Button, {
  ButtonProps,
  ButtonVariant,
} from 'components/shared/slice-button';

import styles from './styles.module.scss';

type LinkActionProps = Pick<
  LinkProps,
  'className' | 'onClick' | 'title' | 'to'
>;
type ButtonActionProps = Pick<
  ButtonProps,
  'className' | 'onClick' | 'title' | 'disabled'
>;

type TableActionProps = { icon: IconProps['icon']; dataTestId?: string } & (
  | LinkActionProps
  | ButtonActionProps
);

const TableAction = ({
  className,
  dataTestId,
  icon,
  ...props
}: TableActionProps) => {
  const resolvedClassName = cx(styles.action, className);
  const resolvedChildren = (
    <Icon className={styles.icon} icon={icon} size="large" />
  );

  if ('to' in props) {
    return (
      <Link
        className={resolvedClassName}
        variant="undecorated"
        data-testid={dataTestId}
        {...props}
      >
        {resolvedChildren}
      </Link>
    );
  }

  return (
    <Button
      className={resolvedClassName}
      variant={ButtonVariant.Transparent}
      data-testid={dataTestId}
      {...props}
    >
      {resolvedChildren}
    </Button>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default TableAction;
