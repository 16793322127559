import { useMemo, useState } from 'react';
import { createColumnHelper, PaginationState } from '@tanstack/react-table';

import ContentTile from 'components/shared/content-tile';
import { Feedback } from 'components/shared/feedback';
import Table from 'components/shared/table';
import Pagination from 'components/shared/table/pagination';
import Text from 'components/shared/text';
import useShopStatusHistoryQuery from 'hooks/hours-analytics/use-shop-status-history';
import { useTable } from 'hooks/shared';
import {
  GetShopStatusHistoryResponseMeta,
  ShopStatusHistory,
} from 'types/hours-analytics';
import {
  eventTypeDisplayValue,
  getDateDisplayValue,
  getLengthDisplayValue,
  getReasonDisplayValue,
  getTimeDisplayValue,
  shippingTypeToDisplayValue,
} from 'utilities/hours-analytics/shop-status-history';

import styles from './styles.module.scss';

const DEFAULT_PER_PAGE = 10;

const getTotalPages = (meta: GetShopStatusHistoryResponseMeta | undefined) => {
  return Math.max(1, Math.ceil((meta?.total || 0) / (meta?.perPage || 1)));
};

type Props = {
  shopId: number;
  timezone: string;
};

export const StatusHistoryTile = ({ shopId, timezone }: Props) => {
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const { data: response, isLoading } = useShopStatusHistoryQuery({
    shopId,
    params: {
      page: pagination.pageIndex + 1,
      perPage: pagination.pageSize,
    },
  });

  const data = response?.data ?? [];
  const meta = response?.meta;

  const columns = useMemo(() => {
    const helper = createColumnHelper<ShopStatusHistory>();

    return [
      helper.accessor((row) => getDateDisplayValue(row, timezone), {
        id: 'date',
        header: 'Date',
        meta: {
          className: styles.dateColumn,
        },
      }),
      helper.accessor((row) => eventTypeDisplayValue[row.eventType], {
        id: 'eventType',
        header: 'Type',
        meta: {
          className: styles.typeColumn,
        },
      }),
      helper.accessor((row) => getTimeDisplayValue(row, timezone), {
        id: 'time',
        header: 'Time',
        meta: {
          className: styles.timeColumn,
        },
      }),
      helper.accessor((row) => getLengthDisplayValue(row), {
        id: 'length',
        header: 'Length',
        meta: {
          className: styles.lengthColumn,
        },
      }),
      helper.accessor((row) => shippingTypeToDisplayValue[row.shippingType], {
        id: 'shippingType',
        header: 'Service',
        meta: {
          className: styles.serviceColumn,
        },
      }),
      helper.accessor('reason', {
        cell: (ctx) => (
          <Text clamp>{getReasonDisplayValue(ctx.row.original.reason)}</Text>
        ),
        id: 'reason',
        header: 'Reason',
      }),
    ];
  }, [timezone]);

  const setPerPage = (perPage: number): void => {
    setPagination({
      pageIndex: 0,
      pageSize: perPage,
    });
  };

  const totalPages = getTotalPages(meta);

  const table = useTable({
    chameleonTableTitle: 'Shop Status History',
    columns,
    data,
    enableSorting: false,
    manualPagination: true,
    onPaginationChange: setPagination,
    pageCount: totalPages,
    state: {
      isLoading,
      pagination,
    },
  });

  const shouldSuppressPagination = !meta || isLoading || totalPages === 1;

  return (
    <ContentTile
      dataTestId="shop-status-history-tile"
      className={styles.tile}
      title="Shop Status History"
      headerClassName={styles.header}
      headerContent={
        <Feedback
          contentDescription="shop status history table"
          page="hours analytics"
          shopId={shopId}
          storageKey="hours_analytics_feedback_shop_status_history"
          tile="shop status history"
        />
      }
    >
      <Table className={styles.table} table={table} />
      <Pagination
        className={styles.pagination}
        currentPage={table.getState().pagination.pageIndex + 1}
        isPageControlVisible={!shouldSuppressPagination}
        onChangePage={(page) => table.setPageIndex(page - 1)}
        totalPages={table.getPageCount()}
        perPage={meta?.perPage ?? DEFAULT_PER_PAGE}
        onChangePerPage={setPerPage}
      />
    </ContentTile>
  );
};
