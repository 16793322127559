import * as React from "react";
import { forwardRef } from "react";
const SvgGearIcon = ({
  title,
  titleId,
  ...props
}, ref) => {
  return /* @__PURE__ */ React.createElement("svg", { width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", "aria-hidden": "true", ref, "aria-labelledby": titleId, ...props }, title === void 0 ? /* @__PURE__ */ React.createElement("title", { id: titleId }, "Gear Icon") : title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M21 8.85754L19.3554 6.14253C18.2472 6.96743 16.6809 7.11772 15.3883 6.40648C14.0958 5.69523 13.4492 4.32716 13.6447 3L10.3554 3C10.5508 4.32716 9.90423 5.69523 8.6117 6.40648C7.31917 7.11773 5.75281 6.96742 4.64464 6.14251L3 8.85754C4.30363 9.35979 5.22339 10.5775 5.22339 12.0001C5.22339 13.4225 4.30365 14.6403 3.00005 15.1425L4.64468 17.8576C5.75284 17.0327 7.31917 16.8824 8.61168 17.5936C9.90417 18.3049 10.5508 19.6729 10.3554 21H13.6446C13.4492 19.6729 14.0958 18.3049 15.3883 17.5936C16.6808 16.8824 18.2472 17.0327 19.3553 17.8576L20.9999 15.1425C19.6963 14.6403 18.7766 13.4225 18.7766 12.0001C18.7766 10.5775 19.6964 9.35979 21 8.85754ZM12.0001 14.5411C13.4725 14.5411 14.6662 13.4034 14.6662 12C14.6662 10.5966 13.4725 9.45887 12.0001 9.45887C10.5276 9.45887 9.33393 10.5966 9.33393 12C9.33393 13.4034 10.5276 14.5411 12.0001 14.5411Z", fill: "currentColor" }));
};
const ForwardRef = forwardRef(SvgGearIcon);
export default ForwardRef;
