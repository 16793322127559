import * as React from "react";
import { forwardRef } from "react";
const SvgCheckmarkSolid = ({
  title,
  titleId,
  ...props
}, ref) => {
  return /* @__PURE__ */ React.createElement("svg", { viewBox: "0 0 16 16", fill: "none", xmlns: "http://www.w3.org/2000/svg", ref, "aria-labelledby": titleId, ...props }, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { d: "m11.836 2-5.532 8.122-2.661-1.629L2 10.171l2.762 3.042A2.476 2.476 0 0 0 6.594 14c.904 0 1.73-.468 2.133-1.21L14 3.114 11.836 2Z", fill: "currentColor" }));
};
const ForwardRef = forwardRef(SvgCheckmarkSolid);
export default ForwardRef;
