import styles from './styles.module.scss';

type Props = {
  numUsers: number;
};

const UserCount = ({ numUsers }: Props) => {
  return (
    <div className={styles.userCount}>
      {numUsers} Register {numUsers === 1 ? 'User' : 'Users'}
    </div>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default UserCount;
