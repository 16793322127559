import { Id, shop } from './shop';

export const onlinePresenceBase = (shopId?: Id) =>
  `${shop(shopId)}/online-presence`;

export const shopScore = (shopId?: Id) =>
  `${onlinePresenceBase(shopId)}/pizzeria-score`;

export const brandManager = (shopId?: Id) =>
  `${onlinePresenceBase(shopId)}/brand-manager`;

export const googleBusinessProfile = (shopId?: Id) =>
  `${onlinePresenceBase(shopId)}/google-business-profile`;
