import { useEffect, useMemo, useState } from 'react';

import ContentTile from 'components/shared/content-tile';
import { SearchBar } from 'components/shared/search-bar';
import Text from 'components/shared/text';
import { useProductsPageState } from 'hooks/menu';
import useAnalytics from 'hooks/use-analytics';
import { MenuResponseBody } from 'types/menu/api';
import { Shop } from 'types/shops';
import { indexBy } from 'utilities/lists';
import { getShopHasFullRegister } from 'utilities/shops';
import { createStringComparator } from 'utilities/sorting';

import { ActionBar } from './action-bar';
import { Category } from './category';
import CategoryButtons from './category-buttons';
import { useBulkEditContext } from './context';
import { CustomOutOfStockModal } from './custom-out-of-stock-modal';

import styles from './styles.module.scss';

type Props = {
  menu: MenuResponseBody;
  shop: Shop;
};

export const MenuProducts = ({ menu, shop }: Props) => {
  const { trackSearchedMenuItems } = useAnalytics();

  const { scrollId, search, setScrollId, setSearch } = useProductsPageState();
  const { selectedProductIds, clearSelectedProductIds } = useBulkEditContext();

  const [isCustomOutOfStockModalOpen, setIsCustomOutOfStockModalOpen] =
    useState(false);

  const toggleCustomOutOfStockModal = () => {
    setIsCustomOutOfStockModalOpen(!isCustomOutOfStockModalOpen);
  };

  const { categoriesById, parsed, productTypesById, productsById } =
    useMemo(() => {
      const categories = menu?.relationships.categories ?? [];
      const products = menu?.relationships.products ?? [];
      const productTypes = menu?.relationships.productTypes ?? [];
      const categoryIds = menu?.menu.categoryIds ?? [];

      const categoriesById = indexBy(categories, 'id');
      const productsById = indexBy(products, 'id');
      const productTypesById = indexBy(productTypes, 'id');

      const parsed = categoryIds.map((categoryId) => {
        const category = categoriesById[categoryId];
        return { categoryId, productIds: category?.productIds ?? [] };
      });

      return {
        categoriesById,
        parsed,
        productTypesById,
        productsById,
      };
    }, [menu]);

  const selectedProductNames = Array.from(selectedProductIds)
    .map((id) => productsById[id]?.name)
    .filter((x) => x !== undefined) as string[]; // TypeScript 5.5 onwards will automatically narrow this type based on the filter - use a manual type assertion until then.

  const isRegister = getShopHasFullRegister(shop) ?? false;

  const filtered = useMemo(() => {
    if (search) {
      const target = search.toLowerCase();
      const productIds = [];

      for (const entry of parsed) {
        for (const id of entry.productIds) {
          const product = productsById[id];

          if (
            product?.name.toLowerCase().includes(target) ||
            product?.description?.toLowerCase().includes(target)
          ) {
            productIds.push(id);
          }
        }
      }
      trackSearchedMenuItems({
        countResults: productIds?.length ?? 0,
        isRegister: isRegister,
        page: 'menu items',
        searchText: search,
        shopId: shop.shopId,
      });

      return [{ categoryId: -1, productIds }];
    }

    return parsed.filter((it) => it.productIds.length);
  }, [
    isRegister,
    parsed,
    productsById,
    search,
    shop.shopId,
    trackSearchedMenuItems,
  ]);

  useEffect(() => {
    clearSelectedProductIds();
  }, [clearSelectedProductIds, search]);

  useEffect(() => {
    if (!scrollId) {
      return;
    }

    const element = document.getElementById(scrollId);

    if (element) {
      const elementBoundingRect = element.getBoundingClientRect();
      const elementTop = elementBoundingRect.top;

      let offset = 0;

      const appHeader = document.getElementById('app-header');

      if (appHeader) {
        offset += appHeader.getBoundingClientRect().height;
      }

      const pageHeader = document.getElementById('menu-page-header');

      if (pageHeader) {
        offset += pageHeader.getBoundingClientRect().height;
      }

      window.scrollTo({
        top: elementTop - offset,
      });

      // Discard the ID regardless of whether we found it. We don't want to try to
      // scroll to this entity again.
      setScrollId(undefined);
    }
  }, [scrollId, setScrollId]);

  const categoryNamesAndIds = useMemo(() => {
    // When search is active, categories are not shown.
    if (search) {
      return [];
    }

    const nameComparator = createStringComparator('name', {
      sensitivity: 'base',
    });
    return filtered
      .map(({ categoryId }) => ({
        name: categoriesById[categoryId]?.name ?? '[No name]',
        id: categoryId,
      }))
      .sort(nameComparator);
  }, [categoriesById, filtered, search]);

  const searchResult = search ? filtered.at(0) : undefined;

  const totalItems = menu?.relationships?.products?.length ?? 0;
  const outOfStockCount = menu?.relationships?.products?.filter(
    (product) => product?.unavailable === true,
  ).length;

  return (
    <ContentTile
      dataTestId="menuItemsPageContentTile"
      className={styles.productContainer}
    >
      <div className={styles.headerWrapper}>
        <SearchBar
          className={styles.search}
          initialSearchValue={search}
          onChange={setSearch}
          placeholderText="Search menu items"
        />
        <div className={styles.itemInfo}>
          {outOfStockCount && outOfStockCount > 0 ? (
            <Text className={styles.text}>
              {`${outOfStockCount} ${outOfStockCount === 1 ? 'item' : 'items'} out of stock`}
            </Text>
          ) : null}
          <Text
            className={styles.text}
          >{`${totalItems} ${totalItems === 1 ? 'item' : 'items'}`}</Text>
        </div>
      </div>
      {searchResult ? (
        <>
          <Category
            categoriesById={categoriesById}
            id={searchResult.categoryId}
            productIds={searchResult.productIds}
            productTypesById={productTypesById}
            productsById={productsById}
            shop={shop}
          />
          {searchResult.productIds.length === 0 && (
            <div className={styles.noSearchResultsWrapper}>
              <div className={styles.noSearchResultsTitle}>
                {"Sorry, we couldn't find what you were looking for."}
              </div>
              <div className={styles.noSearchResultsBody}>
                No results for &quot;{search}&quot;. Check your spelling and try
                again, or search for something different.
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          <CategoryButtons
            categoryNamesAndIds={categoryNamesAndIds}
            onClickCategoryButton={(categoryId: number): void => {
              setScrollId(`category-${categoryId}`);
            }}
            shopId={String(shop.shopId)}
          />
          {filtered.map(({ categoryId, productIds }) => (
            <Category
              categoriesById={categoriesById}
              id={categoryId}
              key={categoryId}
              productIds={productIds}
              productTypesById={productTypesById}
              productsById={productsById}
              shop={shop}
            />
          ))}
        </>
      )}
      {/* Only here for testing purposes, this page is behind a flag anyway */}
      <ActionBar
        onPressCustom={toggleCustomOutOfStockModal}
        shop={shop}
        selectedProductNames={selectedProductNames}
      />
      <CustomOutOfStockModal
        closeModal={toggleCustomOutOfStockModal}
        isOpen={isCustomOutOfStockModalOpen}
        shop={shop}
      />
    </ContentTile>
  );
};
