import * as React from "react";
import { forwardRef } from "react";
const SvgPizzaSliceIcon = ({
  title,
  titleId,
  ...props
}, ref) => {
  return /* @__PURE__ */ React.createElement("svg", { width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", "aria-hidden": "true", ref, "aria-labelledby": titleId, ...props }, title === void 0 ? /* @__PURE__ */ React.createElement("title", { id: titleId }, "Pizza Slice Icon") : title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { d: "M12 2C9.27027 2 6.67247 2.57627 4.32378 3.61474C3.48773 3.9844 3 4.81285 3 5.67548C3 6.05269 3.09285 6.4241 3.27036 6.75693L4.00632 8.13686C6.35828 6.77776 9.08831 6 12 6C14.9117 6 17.6417 6.77776 19.9937 8.13686L20.7296 6.75693C20.9071 6.4241 21 6.05269 21 5.67548C21 4.81285 20.5123 3.9844 19.6762 3.61474C17.3275 2.57627 14.7297 2 12 2Z", fill: "currentColor" }), /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M19.0517 9.90302C16.9809 8.69324 14.5714 8 12 8C9.42861 8 7.01914 8.69324 4.94828 9.90302L7.49654 14.681C7.86309 14.2635 8.40077 14 9 14C10.1046 14 11 14.8954 11 16C11 17.0172 10.2406 17.857 9.25788 17.9835L11.1176 21.4706C11.2914 21.7965 11.6307 22 12 22C12.3693 22 12.7086 21.7965 12.8824 21.4706L19.0517 9.90302ZM13.5 13C14.3284 13 15 12.3284 15 11.5C15 10.6716 14.3284 10 13.5 10C12.6716 10 12 10.6716 12 11.5C12 12.3284 12.6716 13 13.5 13Z", fill: "currentColor" }));
};
const ForwardRef = forwardRef(SvgPizzaSliceIcon);
export default ForwardRef;
