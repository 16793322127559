import FeatureHighlight from 'components/shared/feature-highlight';
import Link from 'components/shared/link';
import useAnalytics from 'hooks/use-analytics';
import { shopReferral as shopReferralUrl } from 'routes/external-urls';
import { ClickedExternalLinkType } from 'types/shared/analytics';

import styles from './styles.module.scss';

const linkLabel = 'See Bonus';

type Props = {
  shopId: string;
};

export const ShopReferral = ({ shopId }: Props) => {
  const { trackClickedPartnerReferralLink } = useAnalytics();

  const onSeeBonusClick = () => {
    trackClickedPartnerReferralLink({
      linkLabel,
      page: 'sidebar',
      referredFor: 'new shop referral',
      shopId,
      type: ClickedExternalLinkType.LinkedText,
    });
  };

  return (
    <FeatureHighlight
      containerClassName={styles.container}
      primaryCta={
        <Link
          className={styles.link}
          onClick={onSeeBonusClick}
          to={shopReferralUrl}
        >
          {linkLabel}
        </Link>
      }
      text="Introduce us to shops that could benefit from Slice to get a major bonus."
      title="Get a Referral Bonus"
    />
  );
};
