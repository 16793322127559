import { useMemo, useState } from 'react';
import {
  FormValidationState,
  useFormValidationState,
} from '@react-stately/form';
import { InputDOMProps, Validation, ValueBase } from '@react-types/shared';

import { FileCriteria, getFilesValidation } from './get-files-validation';

export type FileFieldStateProps = InputDOMProps &
  Validation<File[]> &
  Pick<ValueBase<File[]>, 'onChange'> &
  FileCriteria;

export type FileFieldState = FormValidationState & {
  /** The currently selected files. */
  value: File[];
  /** Sets the selected files. */
  setValue(value: File[]): void;
  /** Whether the field control is an active drop target. */
  isDropTarget: boolean;
  /** Sets the drop target status. */
  setIsDropTarget(value: boolean): void;
  /**
   * Whether the file field is invalid, based on the `isInvalid`,
   * `acceptedFileTypes`, and `maxFileSize` props.
   */
  isInvalid: boolean;
};

/**
 * Provides the state implementation for a file field.
 * @param props - state inputs for the file field.
 */
export const useFileFieldState = (
  props: FileFieldStateProps,
): FileFieldState => {
  // An empty FileList is the only sensible default value.
  const [value, setValue] = useState<File[]>([]);
  const [isDropTarget, setIsDropTarget] = useState(false);

  const { acceptedFileTypes, maxFileSize, onChange } = props;

  const builtinValidation = useMemo(
    () => getFilesValidation(value, { acceptedFileTypes, maxFileSize }),
    [acceptedFileTypes, maxFileSize, value],
  );

  const validation = useFormValidationState({
    ...props,
    value,
    builtinValidation,
  });

  const isValueInvalid = validation.displayValidation.isInvalid;

  return {
    ...validation,
    value,
    setValue: (files: File[]) => {
      setValue(files);
      validation.commitValidation();
      onChange?.(files);
    },
    isDropTarget,
    setIsDropTarget,
    isInvalid: isValueInvalid,
  };
};
