import MarketingServices from 'components/marketing-services';
import { UnexpectedError } from 'components/shared/error';
import Loading from 'components/shared/loading';
import Page from 'components/shared/page';
import { useCurrentUserQuery } from 'hooks/shared';
import { useCurrentShopId } from 'hooks/shops';

const Marketing = () => {
  const shopId = useCurrentShopId();

  const {
    data: user,
    isError: isUserError,
    isLoading: isUserLoading,
  } = useCurrentUserQuery();

  let content;
  if (isUserLoading) {
    content = <Loading />;
  } else if (isUserError) {
    content = <UnexpectedError />;
  } else {
    content = <MarketingServices shopId={shopId} user={user} />;
  }

  return (
    <Page name="Marketing Services" title="Marketing Services">
      {content}
    </Page>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default Marketing;
