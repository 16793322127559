import { Id, shop } from './shop';

export const shopAnalytics = (shopId?: Id): string =>
  `${shop(shopId)}/analytics`;

export const shopAnalyticsOrders = (shopId?: Id): string =>
  `${shopAnalytics(shopId)}/orders`;

export const shopAnalyticsCustomers = (shopId?: Id): string =>
  `${shopAnalytics(shopId)}/customers`;
