import cx from 'classnames';

import styles from './styles.module.scss';

type Props = {
  className?: string;
  label: string;
  value: string;
  Icon: React.ComponentType<React.HTMLAttributes<HTMLOrSVGElement>>;
};

const CustomerStat = ({ className, label, value, Icon }: Props) => {
  return (
    <div className={cx(styles.customerStat, className)}>
      <Icon className={styles.customerStatIcon} aria-hidden="true" />
      <div className={styles.customerStatDetails}>
        <div className={styles.customerStatLabel}>{label}:</div>
        <div className={styles.customerStatValue} title={value}>
          {value}
        </div>
      </div>
    </div>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default CustomerStat;
