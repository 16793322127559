import {
  NetworkRegisterPrinter,
  PrintMode,
  PrintModeSelectOption,
  RegisterPrinter,
} from 'types/register-printing';

export const printModeSelectOptions = [
  {
    label: PrintMode.AfterPayments,
    value: PrintMode.AfterPayments,
  },
  {
    label: PrintMode.Incremental,
    value: PrintMode.Incremental,
  },
] as const;

export const printModeToOption = (
  printMode: PrintMode,
): PrintModeSelectOption =>
  printMode === PrintMode.AfterPayments
    ? printModeSelectOptions[0]
    : printModeSelectOptions[1];

export const networkToDomainRegisterPrinter = (
  networkPrinter: NetworkRegisterPrinter,
): RegisterPrinter => {
  const {
    default_printing_enabled,
    id,
    name,
    number_of_copies,
    print_mode,
    shop_id,
  } = networkPrinter;

  let printMode = PrintMode.AfterPayments;

  if ((Object.values(PrintMode) as string[]).includes(print_mode)) {
    printMode = print_mode as PrintMode;
  } else {
    console.error(
      `Received unhandled print_mode for register printer: ${print_mode}`,
    );
  }

  return {
    defaultPrintingEnabled: default_printing_enabled,
    id,
    name,
    numberOfCopies: number_of_copies,
    printMode,
    shopUuid: shop_id,
  };
};
