import { ComponentPropsWithoutRef } from 'react';
import { useId } from 'react-aria';
import classNames from 'classnames';

import { getCrustClassName } from '../utilities/get-crust-class-name';

import './rating-star.css';

export type RatingStarProps = ComponentPropsWithoutRef<'svg'> & {
  variant?: 'empty' | 'half' | 'whole';
};

const getClassName = getCrustClassName.bind(null, 'rating-star');

export const RatingStar = ({
  className,
  variant = 'whole',
  ...props
}: RatingStarProps) => {
  const id = useId();

  return (
    <svg
      // @ts-expect-error -- overloads are lost with bind.
      className={classNames(getClassName([variant]), className)}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 15 15"
      {...props}
    >
      <title>{variant} rating star</title>
      <defs>
        <linearGradient id={id}>
          <stop className={getClassName('left')} offset="0%" />
          <stop className={getClassName('left')} offset="50%" />
          <stop className={getClassName('right')} offset="50%" />
          <stop className={getClassName('right')} offset="100%" />
        </linearGradient>
      </defs>
      <path
        d="M7.25.547852c-.21765 0-.43683.119589-.54797.357151L4.86979 4.79003l-4.095124.62219c-.501664.07595-.7023301.72238-.339588 1.09246L3.39876 9.52834 2.69952 13.798c-.06792.4137.24697.7499.60354.7499.09416 0 .1914-.0227.28556-.0744L7.25 12.4583l3.6629 2.0152c.0942.0517.1914.0744.2856.0744.3566 0 .6699-.3362.602-.7499l-.6993-4.26966 2.9637-3.02366c.3628-.37008.1621-1.01651-.3396-1.09246l-4.09509-.62219L7.79797.905003C7.68683.667441 7.46765.547852 7.25.547852Z"
        fill={`url(#${id})`}
      />
    </svg>
  );
};
