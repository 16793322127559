import { cloneElement, CSSProperties, ReactElement } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import cx from 'classnames';

import styles from './styles.module.scss';

type Props = {
  children: ReactElement;
  className?: string;
  data?: Record<string, unknown>;
  id: number | string;
};

const SortableListItem = ({ children, className, data, id }: Props) => {
  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({
    data,
    id: String(id),
  });

  return (
    <li
      className={cx(styles.container, isDragging && styles.dragging, className)}
      ref={setNodeRef}
      style={
        {
          transition,
          transform: CSS.Transform.toString(transform),
        } as CSSProperties
      }
    >
      {cloneElement(children, {
        isDragging,
        draggableAttributes: attributes,
        draggableListeners: listeners,
      })}
    </li>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default SortableListItem;
