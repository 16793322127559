import { Fragment } from 'react';
import cx from 'classnames';

import { AvailableColor } from 'types/slice-ordering';
import {
  customColors,
  isColorDark,
  sliceColors,
} from 'utilities/slice-ordering/slice-ordering';

import styles from './styles.module.scss';

type Props = {
  className?: string;
  disabled?: boolean;
  id: string;
  isCustomSelected: boolean;
  onChange: (selectedColor: AvailableColor) => void;
  selectedColor: AvailableColor;
};

const ColorOptions = ({
  className,
  disabled = false,
  id,
  isCustomSelected = false,
  onChange,
  selectedColor,
}: Props) => {
  const availableColors = isCustomSelected ? customColors : sliceColors;

  const getInputId = (color: AvailableColor) => {
    return `${id}-${color.name}`;
  };

  return (
    <div className={cx(styles.colorOptionsContainer, className)}>
      {availableColors.map((color) => (
        <Fragment key={getInputId(color)}>
          <input
            style={{
              backgroundColor: `#${color.hex}`,
              color: isColorDark(color.hex)
                ? 'var(--crust-color-text-white)'
                : 'var(--crust-color-text-default)',
            }}
            className={styles.colorOption}
            checked={selectedColor.name === color.name}
            disabled={disabled}
            id={getInputId(color)}
            type="radio"
            onChange={() => onChange(color)}
            name="partnerButtonColorOption"
          />
          <label className={styles.optionLabel} htmlFor={getInputId(color)}>
            {color.label}
          </label>
        </Fragment>
      ))}
    </div>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default ColorOptions;
