import * as React from "react";
import { forwardRef } from "react";
const SvgDeliveryCustomersIconNoOutline = ({
  title,
  titleId,
  ...props
}, ref) => {
  return /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", fill: "none", viewBox: "0 0 24 24", ref, "aria-labelledby": titleId, ...props }, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { fill: "#242323", fillRule: "evenodd", d: "M19 7c0-1.1-.9-2-2-2h-3v2h3v2.65L13.52 14H10V9H6c-2.21 0-4 1.79-4 4v3h2c0 1.66 1.34 3 3 3s3-1.34 3-3h4.48L19 10.35V7ZM4 14v-1c0-1.1.9-2 2-2h2v3H4Zm3 3c-.55 0-1-.45-1-1h2c0 .55-.45 1-1 1Zm3-11H5v2h5V6Zm6 10c0-1.66 1.34-3 3-3s3 1.34 3 3-1.34 3-3 3-3-1.34-3-3Zm2 0c0 .55.45 1 1 1s1-.45 1-1-.45-1-1-1-1 .45-1 1Z", clipRule: "evenodd" }));
};
const ForwardRef = forwardRef(SvgDeliveryCustomersIconNoOutline);
export default ForwardRef;
