import { ReactElement } from 'react';
import cx from 'classnames';

import { Icon, IconProps } from 'crust';

import { Tile } from 'components/shared/tile/tile';

import styles from './styles.module.scss';

type Props = {
  buttonsWrapperClassName?: string;
  containerClassName?: string;
  headerClassName?: string;
  textClassName?: string;
  titleClassName?: string;
  icon?: IconProps['icon'];
  primaryCta?: ReactElement;
  secondaryCta?: ReactElement;
  text: string;
  title?: string;
};

const FeatureHighlight = ({
  buttonsWrapperClassName,
  containerClassName,
  headerClassName,
  textClassName,
  titleClassName,
  icon = 'sparkle',
  primaryCta,
  secondaryCta,
  text,
  title,
}: Props) => {
  return (
    <Tile className={cx(styles.tile, containerClassName)}>
      <div className={cx(styles.header, headerClassName)}>
        <Icon className={styles.icon} icon={icon} size="large" />
        {title && (
          <div className={cx(styles.title, titleClassName)}>{title}</div>
        )}
      </div>
      <div className={cx(styles.text, textClassName)}>{text}</div>
      <div className={cx(styles.buttonsWrapper, buttonsWrapperClassName)}>
        {primaryCta}
        {secondaryCta}
      </div>
    </Tile>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default FeatureHighlight;
