import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useMutation } from '@tanstack/react-query';
import PropTypes from 'prop-types';

import ClosingForm from 'components/hours/closings/closing-form';
import { useClosings } from 'hooks/hours';
import useAnalytics from 'hooks/use-analytics';
import useApi from 'hooks/use-api';
import { formatClosingForApi, formatClosingForTable } from 'utilities/closings';
import { showInvalidSubmitToast } from 'utilities/forms';
import { upsertBy } from 'utilities/lists';

const EditClosingTile = ({
  activeClosingId,
  defaultValues,
  onClose,
  shopId,
  shopTimezone,
}) => {
  const api = useApi();

  const { trackUpdateClosing } = useAnalytics();

  const { invalidateOpenForTodayQueries, setClosingsQueryData } =
    useClosings(shopId);

  const {
    control,
    formState: { isSubmitting, errors },
    handleSubmit,
    setError,
  } = useForm({ defaultValues, mode: 'onBlur' });

  const updateClosingInQueryData = (updateClosingResponse) =>
    setClosingsQueryData((cachedClosingsData = []) =>
      upsertBy(
        cachedClosingsData,
        formatClosingForTable(updateClosingResponse),
        'id',
      ),
    );

  const { mutate: handleSubmitUpdateClosing } = useMutation(
    (values) =>
      api.updateClosing(shopId, activeClosingId, formatClosingForApi(values)),
    {
      onSuccess: (response) => {
        updateClosingInQueryData(response);

        trackUpdateClosing(shopId, response, true);
        onClose();

        // We invalidate the query to refetch the 'open_for_today' values. The backend calculates values needed
        // such as next_open_at which may be impacted by closing updates
        invalidateOpenForTodayQueries();
      },
      onError: (error) => {
        handleRequestError(error.responseBody, setError);
      },
    },
  );

  const handleRequestError = (responseBody, setError) => {
    if (
      responseBody?.status === 422 &&
      responseBody?.messages.some((it) =>
        it.includes('lie within another Closing'),
      )
    ) {
      setError('dates', {
        type: 'manual',
        message: 'The dates cannot overlap with an existing closing.',
      });
      showInvalidSubmitToast();
    } else {
      toast.error('An unexpected error occurred. Please try again later.');
    }
  };

  return (
    <ClosingForm
      action="edit"
      control={control}
      errors={errors}
      isSubmitting={isSubmitting}
      onClickCancel={onClose}
      onSubmit={handleSubmit(handleSubmitUpdateClosing, showInvalidSubmitToast)}
      shopTimezone={shopTimezone}
    />
  );
};

EditClosingTile.propTypes = {
  activeClosingId: PropTypes.number,
  defaultValues: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  shopId: PropTypes.string.isRequired,
  shopTimezone: PropTypes.string.isRequired,
};

EditClosingTile.defaultProps = {
  activeClosingId: null,
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default EditClosingTile;
