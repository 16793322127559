import * as React from "react";
import { forwardRef } from "react";
const SvgOrderReports = ({
  title,
  titleId,
  ...props
}, ref) => {
  return /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "8 5 16 22", ref, "aria-labelledby": titleId, ...props }, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("g", { fill: "none", fillRule: "evenodd", stroke: "currentColor" }, /* @__PURE__ */ React.createElement("path", { strokeWidth: 1.5, d: "M19 6h1l3 3v1h-4z" }), /* @__PURE__ */ React.createElement("path", { strokeWidth: 1.5, d: "M23 26H9V6h11l3 3z" }), /* @__PURE__ */ React.createElement("path", { strokeLinecap: "square", d: "M16 16h-5 5v2.5h5-5V16zm0 0h5-5v-2.5124922V16zm0 2.5h-5 5V21h5-5v-2.5zm0 2.5v2.5V21h-5 5zm-5.5 2.5h11-11zm.5 0V13.4875078 23.5zm10 0V13.4875078 23.5zM10.5 13h11-11z" })));
};
const ForwardRef = forwardRef(SvgOrderReports);
export default ForwardRef;
