import queryString from 'query-string';

import {
  ColorCodes,
  ConfigOption,
  FontCodes,
  FontColorCodes,
} from 'types/site-editor';
import { snakeCaseKeys } from 'utilities/objects';

export const defaultColorCode = ColorCodes.SliceRed;
export const defaultFontCode = FontCodes.GtAmerica;
export const defaultColorHex = 'DA3327';
export const defaultColorLabel = 'slice red';

type DirectWebUrlParameters = {
  color: string;
  domainName?: string;
  disableScripts: string;
  fontColor: number;
  fontFamily: string;
};

const { MODE } = import.meta.env;

export const getDirectWebUrl = (
  domainName: string,
  color: string,
  fontFamily: string,
): string => {
  let base = 'https://direct-web.dev.slicelife.com';
  const params: DirectWebUrlParameters = {
    color,
    domainName,
    fontFamily,
    disableScripts: 'datadog,segment,ethyca',
    fontColor: FontColorCodes.White,
  };

  if (MODE === 'production') {
    // This domain name comes from admin and the field does not always include
    // the protocol section of the url so we check for it and add it if needed.
    base = domainName.startsWith('http') ? domainName : `https://${domainName}`;
    delete params.domainName;
  } else if (MODE === 'qa') {
    base = 'https://direct-web.qa.slicelife.com';
  }

  // The disableScripts parameter needs to be camelCase.
  // https://github.com/slicelife/direct-web/blob/cc7b34d616dcffa288b375b1d5c48c39dc99889a/src/utilities/disabledScripts.ts#L17
  return `${base}?${queryString.stringify(
    snakeCaseKeys(params, { exclude: ['disableScripts'] }),
  )}`;
};

const defaultColorOption = {
  hexValue: 'DA3327',
  label: 'Slice Red',
  name: 'sliceRed',
  value: ColorCodes.SliceRed,
};

export const colorOptions: ConfigOption[] = [
  defaultColorOption,
  {
    hexValue: '87281F',
    label: 'Dark Red',
    name: 'darkRed',
    value: ColorCodes.DarkRed,
  },
  {
    hexValue: '211E1E',
    label: 'Graphite',
    name: 'graphite',
    value: ColorCodes.Graphite,
  },
  {
    hexValue: '17723E',
    label: 'Deep Green',
    name: 'deepGreen',
    value: ColorCodes.DeepGreen,
  },
  {
    hexValue: '83194C',
    label: 'Cherry Wine',
    name: 'cherryWine',
    value: ColorCodes.CherryWine,
  },
];

const defaultFontOption = {
  name: 'gtAmerica',
  label: 'GT America',
  value: FontCodes.GtAmerica,
};

export const fontOptions: ConfigOption[] = [
  defaultFontOption,
  {
    name: 'pollerOne',
    label: 'Poller One',
    value: FontCodes.PollerOne,
  },
  {
    name: 'noto',
    label: 'Noto',
    value: FontCodes.Noto,
  },
  {
    name: 'oswald',
    label: 'Oswald',
    value: FontCodes.Oswald,
  },
  {
    name: 'jost',
    label: 'Jost',
    value: FontCodes.Jost,
  },
];

export const findSelectedOptions = ({
  selectedColorCode,
  selectedFontCode,
}: {
  selectedColorCode: string;
  selectedFontCode: string;
}): { selectedColor: ConfigOption; selectedFont: string } => {
  const selectedColor = colorOptions.find(
    (option) => String(option.value) === selectedColorCode,
  );

  const selectedFont = fontOptions
    .find((option) => String(option.value) === selectedFontCode)
    ?.label.toLowerCase();

  return {
    selectedColor: selectedColor ?? defaultColorOption,
    selectedFont: selectedFont ?? defaultFontOption.label.toLowerCase(),
  };
};
