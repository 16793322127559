import { useMemo } from 'react';
import cx from 'classnames';

import { OrderItem } from 'types/register-orders';
import { createIntegerComparator } from 'utilities/sorting';

import OrderItemEntry from '../order-item-entry';

import styles from './styles.module.scss';

type Props = {
  className?: string;
  orderItems: OrderItem[];
};

const OrderItemsList = ({ className, orderItems }: Props) => {
  const sortedItems = useMemo(() => {
    const orderItemComparator = createIntegerComparator('position');

    return orderItems.slice().sort(orderItemComparator);
  }, [orderItems]);

  return (
    <>
      {sortedItems.length > 0 && (
        <ul className={cx(styles.list, className)}>
          {sortedItems.map((item) => (
            <OrderItemEntry key={item.id} orderItem={item} />
          ))}
        </ul>
      )}
    </>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default OrderItemsList;
