import { createContext, useContext } from 'react';

import { ShopTraits } from 'types/shops/traits';

export const ShopTraitsContext = createContext<ShopTraits | null>(null);

export const useShopTraitsContext = () => {
  const traits = useContext(ShopTraitsContext);

  if (traits == null) {
    throw new Error(
      'useShopWithTraits must be used within an ShopWithTraitsProvider.',
    );
  }

  return traits;
};
