import { ComponentPropsWithoutRef } from 'react';
import cx from 'classnames';

import styles from './styles.module.scss';

const Label = (props: ComponentPropsWithoutRef<'label'>) => {
  const { children, className, ...labelProps } = props;

  return (
    <label className={cx(styles.label, className)} {...labelProps}>
      {children}
    </label>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default Label;
