import * as React from "react";
import { forwardRef } from "react";
const SvgDropdownArrow = ({
  title,
  titleId,
  ...props
}, ref) => {
  return /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: 10, height: 6, fill: "none", viewBox: "0 0 10 6", ref, "aria-labelledby": titleId, ...props }, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { fill: "currentColor", d: "m0 .5 5 5 5-5H0Z" }));
};
const ForwardRef = forwardRef(SvgDropdownArrow);
export default ForwardRef;
