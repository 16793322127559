import moment from 'moment-timezone';

import useAnalytics from 'hooks/use-analytics';
import {
  ActiveCampaignPhase,
  CampaignManagerState,
  CampaignManagerSummary,
} from 'types/campaign-manager';
import { Shop } from 'types/shops';
import { User } from 'types/user';
import { getActiveCampaignPhase } from 'utilities/campaign-manager';

import ExecutionPhase from './phases/execution';
import LearningPhase from './phases/learning';
import OptimizationPhase from './phases/optimization';
import RampUpPhase from './phases/ramp-up';

type Props = {
  shopId: Shop['shopId'];
  startDate: string;
  summaryData: CampaignManagerSummary;
  user: User;
};

const ActiveCampaign = ({ shopId, startDate, summaryData, user }: Props) => {
  const { trackViewedCampaignManagerState } = useAnalytics();
  const activeCampaignPhase = getActiveCampaignPhase(moment(startDate));

  switch (activeCampaignPhase) {
    case ActiveCampaignPhase.Learning:
      trackViewedCampaignManagerState({
        shopId: String(shopId),
        state: CampaignManagerState.LearningPhase,
      });

      return (
        <LearningPhase
          shopId={shopId}
          startDate={startDate}
          summaryData={summaryData}
        />
      );
    case ActiveCampaignPhase.RampUp:
      trackViewedCampaignManagerState({
        shopId: String(shopId),
        state: CampaignManagerState.RampUpPhase,
      });

      return (
        <RampUpPhase
          shopId={shopId}
          startDate={startDate}
          summaryData={summaryData}
        />
      );
    case ActiveCampaignPhase.Optimization:
      trackViewedCampaignManagerState({
        shopId: String(shopId),
        state: CampaignManagerState.OptimizationPhase,
      });

      return (
        <OptimizationPhase
          shopId={shopId}
          startDate={startDate}
          summaryData={summaryData}
        />
      );
    case ActiveCampaignPhase.Execution:
      trackViewedCampaignManagerState({
        shopId: String(shopId),
        state: CampaignManagerState.ExecutionPhase,
      });

      return (
        <ExecutionPhase shopId={shopId} summaryData={summaryData} user={user} />
      );
  }
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default ActiveCampaign;
