import * as React from "react";
import { forwardRef } from "react";
const SvgDeliveryCustomersIcon = ({
  title,
  titleId,
  ...props
}, ref) => {
  return /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", fill: "none", viewBox: "0 0 32 32", ref, "aria-labelledby": titleId, ...props }, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("circle", { cx: 16, cy: 16, r: 16, fill: "#F5F3F0" }), /* @__PURE__ */ React.createElement("path", { fill: "#54B399", fillRule: "evenodd", d: "M23 10c0-1.1-.9-2-2-2h-3v2h3v2.65L17.52 17H14v-5h-4c-2.21 0-4 1.79-4 4v3h2c0 1.66 1.34 3 3 3s3-1.34 3-3h4.48L23 13.35V10ZM8 17v-1c0-1.1.9-2 2-2h2v3H8Zm3 3c-.55 0-1-.45-1-1h2c0 .55-.45 1-1 1Zm3-11H9v2h5V9Zm6 10c0-1.66 1.34-3 3-3s3 1.34 3 3-1.34 3-3 3-3-1.34-3-3Zm2 0c0 .55.45 1 1 1s1-.45 1-1-.45-1-1-1-1 .45-1 1Z", clipRule: "evenodd" }));
};
const ForwardRef = forwardRef(SvgDeliveryCustomersIcon);
export default ForwardRef;
