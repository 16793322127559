import { useCallback, useMemo, useState } from 'react';
import { createColumnHelper, Row } from '@tanstack/react-table';

import RegisterProfileForm from 'components/register/register-profiles/form';
import RegisterProfilesHeader from 'components/register/register-profiles/header';
import ContentTile from 'components/shared/content-tile';
import Table from 'components/shared/table';
import TableAction from 'components/shared/table/action';
import Text from 'components/shared/text';
import VisuallyHidden from 'components/shared/visually-hidden';
import { useTable } from 'hooks/shared';
import { RegisterProfile } from 'types/register-profile';
import { Shop } from 'types/shops';

import styles from './styles.module.scss';

type Props = {
  registerProfiles: RegisterProfile[];
  shopId: Shop['shopId'];
};

const RegisterProfiles = ({ registerProfiles, shopId }: Props) => {
  const [isCreateFormOpen, setIsCreateFormOpen] = useState(false);
  const toggleIsCreateFormOpen = useCallback(
    () => setIsCreateFormOpen((current) => !current),
    [],
  );

  const columns = useMemo(() => {
    const helper = createColumnHelper<RegisterProfile>();

    return [
      helper.accessor('name', {
        enableSorting: true,
        header: 'Name',
        cell(ctx) {
          return <Text wrap="truncate">{ctx.getValue()}</Text>;
        },
        meta: {
          className: styles.nameColumn,
        },
      }),
      helper.display({
        id: 'actions',
        enableSorting: false,
        header() {
          return <VisuallyHidden>Actions</VisuallyHidden>;
        },
        cell(ctx) {
          return (
            <>
              <TableAction
                icon="pencil"
                title={`Edit register profile ${ctx.row.original.name}`}
                onClick={() => ctx.row.toggleExpanded()}
              />
            </>
          );
        },
        meta: {
          isActionColumn: true,
        },
      }),
    ];
  }, []);

  const getRowExpansion = useCallback(
    ({ row }: { row: Row<RegisterProfile> }) => {
      const collapse = () => row.toggleExpanded(false);

      return (
        <RegisterProfileForm
          onCancel={collapse}
          onSave={collapse}
          profile={row.original}
          profiles={registerProfiles}
          shopId={shopId}
        />
      );
    },
    [registerProfiles, shopId],
  );

  const table = useTable({
    columns,
    data: registerProfiles,
    getRowCanExpand: () => true,
    getRowExpansion,
    initialState: {
      sorting: [
        {
          id: 'name',
          desc: false,
        },
      ],
    },
    chameleonTableTitle: 'Register Profile Settings',
  });

  return (
    <>
      <RegisterProfilesHeader
        onClickCreateProfile={toggleIsCreateFormOpen}
        shopId={String(shopId)}
      />
      {isCreateFormOpen && (
        <ContentTile title="Create New Profile">
          <RegisterProfileForm
            onCancel={toggleIsCreateFormOpen}
            onSave={toggleIsCreateFormOpen}
            profiles={registerProfiles}
            shopId={shopId}
          />
        </ContentTile>
      )}
      <ContentTile>
        <Table table={table} />
      </ContentTile>
    </>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default RegisterProfiles;
