import cx from 'classnames';

import {
  MetricTile,
  MetricTileContent,
  MetricTileHeader,
  MetricTileTitle,
  MetricTileValue,
} from 'components/shared/metric-tiles/metric-tile';
import Suspended from 'components/shared/suspended';
import { TooltipIconButton } from 'components/shared/tooltips';
import { useWindowSize } from 'hooks/use-window-size';
import { OnlineMetricKeys, RegisterMetricKeys } from 'types/orders';

import styles from './styles.module.scss';

type Props = {
  dataTestId?: string;
  isOnlineOrdersMetric?: boolean;
  isLoading: boolean;
  labelKey: string;
  title: string;
  value: number | string;
};

const AnalyticsTile = ({
  dataTestId,
  isOnlineOrdersMetric = false,
  isLoading,
  labelKey,
  title,
  value,
}: Props) => {
  const { isMobile } = useWindowSize();

  const isRealNumber =
    labelKey === RegisterMetricKeys.NumOrders ||
    labelKey === OnlineMetricKeys.TotalOrders;

  return (
    <MetricTile className={styles.metricTile} dataTestId={dataTestId}>
      <MetricTileContent className={styles.metricTileContent}>
        <Suspended isLoading={isLoading}>
          <MetricTileHeader headerClassName={styles.metricTileHeader}>
            <MetricTileTitle>{title}</MetricTileTitle>
            {labelKey === OnlineMetricKeys.VoidedEarnings && (
              <TooltipIconButton label="Voided Earnings">
                These are orders that you have chosen not accept or have
                canceled by customer.
              </TooltipIconButton>
            )}
          </MetricTileHeader>
          <MetricTileValue
            className={cx(
              styles.metricTileValue,
              labelKey === OnlineMetricKeys.VoidedEarnings &&
                styles.voidedSalesValue,
            )}
            value={Number(value)}
            type={isRealNumber ? 'number' : 'currency'}
            hideDecimalsAboveValue={isOnlineOrdersMetric ? 99999 : undefined}
            reduceFontSizeAtDigits={isMobile ? 4 : isOnlineOrdersMetric ? 5 : 7}
          />
        </Suspended>
      </MetricTileContent>
    </MetricTile>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default AnalyticsTile;
