import { UseQueryResult } from '@tanstack/react-query';

import appleMapsIcon from 'images/appleMaps.png';
import bingIcon from 'images/bing.png';
import gmbIcon from 'images/gmb.png';
import googleFoodOrderingIcon from 'images/googleFoodOrdering.png';
import nextDoorIcon from 'images/nextdoor.png';
import oneBiteIcon from 'images/onebite.png';
import singlePlatformIcon from 'images/singleplatform.png';
import tripadvisorIcon from 'images/tripAdvisor.png';
import yahooIcon from 'images/yahoo.png';
import { GetPublishersResponse } from 'types/brand-manager';

enum ShopPublisherKey {
  Bing = 'bing',
  GMB = 'gmb',
  NextDoor = 'nextDoor',
  OneBite = 'oneBite',
  SinglePlatform = 'singlePlatform',
  Yahoo = 'yahoo',
  GoogleFoodOrdering = 'gfo',
  AppleMaps = 'appleMaps',
  Tripadvisor = 'tripAdvisor',
}

export type ShopPublisherStaticInfo = {
  displayName: string;
  logo: string;
  logoAlt: string;
};

export type ShopPublisherInfo = ShopPublisherStaticInfo & {
  isEnabled: boolean;
};

export const staticInfo = {
  [ShopPublisherKey.Bing]: {
    displayName: 'Bing',
    logo: bingIcon,
    logoAlt: 'Bing logo',
  },
  [ShopPublisherKey.GMB]: {
    displayName: 'Google Business Profile',
    logo: gmbIcon,
    logoAlt: 'Google Business Profile logo',
  },
  [ShopPublisherKey.NextDoor]: {
    displayName: 'Nextdoor',
    logo: nextDoorIcon,
    logoAlt: 'Nextdoor logo',
  },
  [ShopPublisherKey.OneBite]: {
    displayName: 'One Bite',
    logo: oneBiteIcon,
    logoAlt: 'One Bite logo',
  },
  [ShopPublisherKey.SinglePlatform]: {
    displayName: 'SinglePlatform',
    logo: singlePlatformIcon,
    logoAlt: 'SinglePlatform logo',
  },
  [ShopPublisherKey.Yahoo]: {
    displayName: 'Yahoo',
    logo: yahooIcon,
    logoAlt: 'Yahoo logo',
  },
  [ShopPublisherKey.GoogleFoodOrdering]: {
    displayName: 'Google Food Ordering',
    logo: googleFoodOrderingIcon,
    logoAlt: 'Google Food Ordering logo',
  },
  [ShopPublisherKey.AppleMaps]: {
    displayName: 'Apple Maps',
    logo: appleMapsIcon,
    logoAlt: 'Apple Maps logo',
  },
  [ShopPublisherKey.Tripadvisor]: {
    displayName: 'Tripadvisor',
    logo: tripadvisorIcon,
    logoAlt: 'Tripadvisor logo',
  },
};

const extractInfo = (
  shopPublishers: GetPublishersResponse,
  key: ShopPublisherKey,
): ShopPublisherInfo => {
  return {
    displayName: staticInfo[key].displayName,
    isEnabled: shopPublishers[key].enabled,
    logo: staticInfo[key].logo,
    logoAlt: staticInfo[key].logoAlt,
  };
};

export const getShopPublishersInfoList = (
  shopPublishersQuery: UseQueryResult<GetPublishersResponse>,
): ShopPublisherInfo[] => {
  if (shopPublishersQuery.isLoading || !shopPublishersQuery.data) {
    return [];
  }

  const { data } = shopPublishersQuery;

  const infoList = [];

  infoList.push(extractInfo(data, ShopPublisherKey.GMB));
  infoList.push(extractInfo(data, ShopPublisherKey.GoogleFoodOrdering));
  infoList.push(extractInfo(data, ShopPublisherKey.AppleMaps));
  infoList.push(extractInfo(data, ShopPublisherKey.Tripadvisor));
  infoList.push(extractInfo(data, ShopPublisherKey.Bing));
  infoList.push(extractInfo(data, ShopPublisherKey.Yahoo));
  infoList.push(extractInfo(data, ShopPublisherKey.SinglePlatform));
  infoList.push(extractInfo(data, ShopPublisherKey.OneBite));
  infoList.push(extractInfo(data, ShopPublisherKey.NextDoor));

  return infoList;
};
