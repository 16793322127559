import { useState } from 'react';
import PropTypes from 'prop-types';

import CollapsibleTile from 'components/shared/collapsible-tile';
import { Divider } from 'components/shared/divider';
import FormText from 'components/shared/form-text';
import { RHFCheckbox, RHFCheckboxGroup } from 'components/shared/rhf-checkbox';
import { MenuProductAvailability } from 'types/menu/product';
import { AnalyticsField, ProductField } from 'utilities/menu';

import styles from './styles.module.scss';

const ProductOptionsTile = ({ control, isRosEnabled, register, setValue }) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <CollapsibleTile
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title="Options"
      bodyChameleonTarget="Edit Item Options Area"
    >
      <FormText>
        Additional controls for your menu item. If you need some products to be
        only available via the register and not online, select{' '}
        {'"Item available on Slice Register"'} and de-select{' '}
        {'"Item available online"'}.
      </FormText>
      <div
        className={styles.checkbox}
        data-chameleon-target="Product Featured Checkbox"
      >
        <RHFCheckbox
          control={control}
          description="Featured items will appear on Slice app profile"
          label="Feature this item (up to 5 per menu)"
          name={ProductField.IsFeatured}
          rules={{
            onChange: () => setValue(AnalyticsField.IsFeaturedChanged, true),
          }}
        />
      </div>
      {isRosEnabled && (
        <div>
          <Divider className={styles.divider} />
          <RHFCheckboxGroup
            aria-label="item availability"
            control={control}
            name={ProductField.Availabilities}
            orientation="vertical"
            rules={{
              required:
                'Item must be available either Online or on the Slice Register or both.',
            }}
          >
            <RHFCheckbox
              label="Item available online"
              description="The item will be available for customers to purchase online via the website & app"
              value={MenuProductAvailability.Online}
              rules={{
                onChange: () =>
                  setValue(AnalyticsField.IsAvailableOnlineChanged, true),
              }}
            />
            <RHFCheckbox
              description="The item will be available on the Slice Register"
              label="Item available on Slice Register"
              value={MenuProductAvailability.Offline}
              rules={{
                onChange: () =>
                  setValue(AnalyticsField.IsAvailableOfflineChanged, true),
              }}
            />
          </RHFCheckboxGroup>
        </div>
      )}
      <input type="hidden" {...register(AnalyticsField.IsFeaturedChanged)} />
      <input
        type="hidden"
        {...register(AnalyticsField.IsAvailableOnlineChanged)}
      />
      <input
        type="hidden"
        {...register(AnalyticsField.IsAvailableOfflineChanged)}
      />
    </CollapsibleTile>
  );
};

ProductOptionsTile.propTypes = {
  control: PropTypes.object.isRequired,
  isRosEnabled: PropTypes.bool.isRequired,
  register: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default ProductOptionsTile;
