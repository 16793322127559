import ContentTile from 'components/shared/content-tile';
import { Feedback } from 'components/shared/feedback';
import { useWindowSize } from 'hooks/use-window-size';
import { WeeklySummary } from 'types/hours-analytics';
import { getBlankDates } from 'utilities/hours-analytics';

import { Legend } from './chart/legend';
import { Chart } from './chart';

import styles from './styles.module.scss';

type Props = {
  data: WeeklySummary[];
  dataAsOfText: string;
  shopId: number;
  timezone: string;
};

export const ShopAvailabilityTile = ({
  data,
  dataAsOfText,
  shopId,
  timezone,
}: Props) => {
  const { isMobile } = useWindowSize();

  return (
    <ContentTile
      dataTestId="shop-availability-tile"
      className={styles.tile}
      headerClassName={styles.header}
      headerContent={
        <div className={styles.customHeader}>
          <div className={styles.topHeaderRow}>
            <div className={styles.title}>Shop Availability</div>
            {isMobile || <Legend />}
            <Feedback
              contentDescription="shop availability chart"
              page="hours analytics"
              shopId={shopId}
              storageKey="hours_analytics_feedback_shop_availability"
              tile="shop availability"
            />
          </div>
          {isMobile && <Legend className={styles.bottomHeaderRowLegend} />}
        </div>
      }
      suppressTitle
    >
      <Chart
        data={data.length === 0 ? getBlankDates(timezone) : data}
        dataAsOfText={dataAsOfText}
        isEmpty={data.length === 0}
      />
    </ContentTile>
  );
};
