import { QueryKey, useQuery } from '@tanstack/react-query';
import { z } from 'zod';

import useApi from 'hooks/use-api';
import { camelCaseKeys } from 'utilities/objects';

import { businessProfileLocationsSearchSchema } from './schemas';
import { useHandleGoogleReauthError } from './use-handle-google-reauth-error';

export type ResponseBusinessProfileAccountLocationsSearch = z.infer<
  typeof businessProfileLocationsSearchSchema
>;

export const getQueryKey = (shopId: number): QueryKey => [
  shopId,
  'businessProfileAccountLocationsSearch',
];

export const useGBPAccountLocationSearchQuery = (shopId: number) => {
  const { authenticatedFetch } = useApi();
  const handleGoogleReauthError = useHandleGoogleReauthError();

  return useQuery({
    queryKey: getQueryKey(shopId),
    queryFn: async () => {
      try {
        const response = await authenticatedFetch.get(
          `api/management/v1/shops/${shopId}/business_profile/account_locations/search`,
        );
        return businessProfileLocationsSearchSchema.parse(
          camelCaseKeys(response),
        ) as ResponseBusinessProfileAccountLocationsSearch;
      } catch (error) {
        if (error instanceof z.ZodError) {
          console.error(
            'Error parsing Business Profile Search Locations response',
            error,
          );
        }

        handleGoogleReauthError(shopId, error);

        throw error;
      }
    },
  });
};
