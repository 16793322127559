import { shippingTypes } from 'utilities/constants';

export const modalOptions = {
  pauseShippingType: 'pauseShippingType',
  closeShippingType: 'closeShippingType',
  extendPauseShippingType: 'extendPauseShippingType',
};

export const shippingTypeValues = Object.entries(shippingTypes).reduce(
  (acc, [key, { value }]) => ({ ...acc, [key]: value }),
  {},
);

export const openForShippingTypePayloads = {
  [shippingTypeValues.delivery_and_pickup]: { delivery: false, pickup: false },
  [shippingTypeValues.delivery]: { delivery: false, pickup: true },
  [shippingTypeValues.pickup]: { delivery: true, pickup: false },
};
