import { CSSProperties, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  DragOverEvent,
  DragStartEvent,
  KeyboardSensor,
  MeasuringStrategy,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  rectSortingStrategy,
  SortableContext,
  sortableKeyboardCoordinates,
} from '@dnd-kit/sortable';
import { useQueryClient } from '@tanstack/react-query';
import cx from 'classnames';

import ContentTile from 'components/shared/content-tile';
import SortableListItem from 'components/shared/sortable-list-item';
import SortableOverlay from 'components/shared/sortable-overlay';
import { useRegisterLayoutQuery } from 'hooks/register-layout/use-register-layout-query';
import { useUpdateRegisterLayoutMutation } from 'hooks/register-layout/use-update-register-layout-mutation';
import useAnalytics from 'hooks/use-analytics';
import useDebounce from 'hooks/use-debounce';
import {
  RegisterLayout as IRegisterLayout,
  RegisterLayoutCategory,
} from 'types/register-layout';
import { showUnexpectedErrorToast } from 'utilities/forms';
import {
  createRevisionFromColorChange,
  createRevisionFromSort,
  createSortableLayout,
  isRevisionEqual,
} from 'utilities/menu';

import RegisterListItem from './list-item';
import RegisterMenuPageHeader from './page-header';
import RegisterMenuTileHeader from './tile-header';

import styles from './styles.module.scss';

type Props = {
  categoryId?: string;
  shopId: string;
};

export const RegisterLayoutRedesign = ({ categoryId, shopId }: Props) => {
  const queryClient = useQueryClient();
  const { trackRegisterMenuSorted, trackRegisterMenuColorChanged } =
    useAnalytics();
  const params = useParams();

  const { data: layout, isLoading: isLayoutLoading } =
    useRegisterLayoutQuery(shopId);

  const sortableLayout = useMemo(() => createSortableLayout(layout), [layout]);

  const category = useMemo(
    () => sortableLayout.categories.find((it) => it.id === Number(categoryId)),
    [categoryId, sortableLayout.categories],
  );

  const source = category?.items ?? sortableLayout.categories;
  const maxColumns = sortableLayout.maxColumns;
  const maxRows = sortableLayout.maxRows;
  const pageSize = maxColumns * maxRows;

  const [revision, setRevision] = useState(sortableLayout);
  const debouncedRevision = useDebounce(revision, 1000);

  const isRevisingRef = useRef(false);
  const draftRef = useRef(revision);

  useEffect(() => {
    setRevision(sortableLayout);
  }, [sortableLayout]);

  const { mutate: handleUpdate } = useUpdateRegisterLayoutMutation(shopId);

  useEffect(() => {
    if (isRevisingRef.current) {
      isRevisingRef.current = false;
      handleUpdate(debouncedRevision, {
        // This handler is defined outside of the mutation definition because we
        // only want it to fire once after consecutive mutations.
        // https://react-query.tanstack.com/guides/mutations#consecutive-mutations
        onSettled: (data: IRegisterLayout | undefined, error: unknown) => {
          toast.dismiss();

          if (error) {
            showUnexpectedErrorToast();
            return;
          }

          toast.success('Layout updated!');

          // Only update the query if this is the latest revision. Another
          // mutation might be about to start and we don't want the page to
          // momentarily "jump" to a previous state before that mutation
          // finishes.
          if (!isRevisingRef.current) {
            queryClient.setQueryData([shopId, 'registerMenuLayout'], data);
          }
        },
      });
    }
  }, [debouncedRevision, handleUpdate, queryClient, shopId]);

  const shouldShowGridView = !!params.categoryId;
  const [isSingleColumn, setIsSingleColumn] = useState(!shouldShowGridView);

  useEffect(() => {
    setIsSingleColumn(!shouldShowGridView);
  }, [shouldShowGridView]);

  const [sortables, setSortables] = useState(source);
  useEffect(() => setSortables(source), [source]);

  const snapshotRef = useRef(sortables);

  const findIndex = (id: number) => sortables.findIndex((it) => it.id === id);
  const findPage = (index: number) => Math.floor(index / pageSize);

  const pages = useMemo(() => {
    const pages = [];

    for (let i = 0; i < sortables.length; i += pageSize) {
      pages.push(sortables.slice(i, i + pageSize));
    }

    return pages;
  }, [sortables, pageSize]);

  const [activeId, setActiveId] = useState(-1);

  const activeItem = useMemo(
    () => sortables.find((it) => it.id === activeId),
    [activeId, sortables],
  );

  const sensors = useSensors(
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
    useSensor(MouseSensor),
    useSensor(TouchSensor),
  );

  const handleDragStart = ({ active }: DragStartEvent) => {
    draftRef.current = revision;
    snapshotRef.current = sortables;

    setActiveId(Number(active?.id ?? -1));
  };

  const handleDragOver = ({ active, over }: DragOverEvent) => {
    if (over?.id == null) {
      return;
    }

    const activeIndex = findIndex(Number(active.id));
    const overIndex = findIndex(Number(over.id));
    const activePage = findPage(activeIndex);
    const overPage = findPage(overIndex);

    if (activePage === overPage) {
      return;
    }

    draftRef.current = createRevisionFromSort(
      draftRef.current,
      activeIndex,
      overIndex,
      active.data.current?.parentId,
    );

    setSortables((current) => arrayMove(current, activeIndex, overIndex));
  };

  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    setActiveId(-1);

    if (over == null) {
      return;
    }

    const activeIndex = findIndex(Number(active.id));
    const overIndex = findIndex(Number(over.id));

    if (activeIndex !== overIndex) {
      draftRef.current = createRevisionFromSort(
        draftRef.current,
        activeIndex,
        overIndex,
        active.data.current?.parentId,
      );

      setSortables((current) => arrayMove(current, activeIndex, overIndex));
    }

    if (!isRevisionEqual(revision, draftRef.current)) {
      setRevision(draftRef.current);
      isRevisingRef.current = true;

      trackRegisterMenuSorted(
        shopId,
        active.data.current?.parentId == null,
        isSingleColumn,
      );
    }
  };

  const handleDragCancel = () => {
    setActiveId(-1);
    setSortables(snapshotRef.current);
  };

  const handleColorChange = (
    colorHex: string,
    id: number,
    parentId?: number,
  ) => {
    setSortables((current) =>
      current.map((it) => (it.id === id ? { ...it, colorHex } : it)),
    );
    setRevision((current) =>
      createRevisionFromColorChange(current, colorHex, id, parentId),
    );
    isRevisingRef.current = true;

    trackRegisterMenuColorChanged(
      shopId,
      colorHex,
      parentId == null,
      isSingleColumn,
    );
  };

  return (
    <>
      <RegisterMenuPageHeader />
      <ContentTile isLoading={isLayoutLoading}>
        <RegisterMenuTileHeader category={category} shopId={shopId} />
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          measuring={{
            droppable: {
              strategy: MeasuringStrategy.Always,
            },
          }}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
          onDragCancel={handleDragCancel}
          onDragOver={handleDragOver}
        >
          <ol className={styles.pages}>
            {pages.map((page, index) => (
              <li key={index} className={styles.page}>
                <h4 className={styles.pageNumber}>Page {index + 1}</h4>
                <SortableContext
                  items={page.map((item) => String(item.id))}
                  strategy={rectSortingStrategy}
                >
                  <ol
                    className={cx(styles.grid, isSingleColumn && styles.single)}
                    style={
                      {
                        '--col-count': String(maxColumns),
                      } as CSSProperties
                    }
                  >
                    {page.map((item) => (
                      <SortableListItem
                        data={{ parentId: category?.id }}
                        id={item.id}
                        key={item.id}
                      >
                        <RegisterListItem
                          colorHex={item.colorHex}
                          count={
                            (item as RegisterLayoutCategory).items?.length ?? 0
                          }
                          id={item.id}
                          isSingleColumn={isSingleColumn}
                          name={item.name}
                          onSelectColor={handleColorChange}
                          parentId={category?.id}
                          shopId={shopId}
                        />
                      </SortableListItem>
                    ))}
                  </ol>
                </SortableContext>
              </li>
            ))}
          </ol>
          <SortableOverlay withPortal>
            {activeItem ? (
              <RegisterListItem
                colorHex={activeItem.colorHex}
                count={
                  (activeItem as RegisterLayoutCategory).items?.length ?? 0
                }
                id={activeItem.id}
                isSingleColumn={isSingleColumn}
                name={activeItem.name}
                onSelectColor={handleColorChange}
                parentId={category?.id}
                shopId={shopId}
              />
            ) : null}
          </SortableOverlay>
        </DndContext>
      </ContentTile>
    </>
  );
};
