import Modal from 'components/shared/slice-modal';
import { Shop } from 'types/shops';

import { VideoModalContent } from '../video-modal-content';

import styles from './styles.module.scss';

type Props = {
  isOpen: boolean;
  onRequestClose: () => void;
  onScheduleDemoClick: () => void;
  hasValidCase: boolean;
  shopId: Shop['shopId'];
};

export const VideoPreviewModal = ({
  isOpen,
  onRequestClose,
  onScheduleDemoClick,
  hasValidCase,
  shopId,
}: Props) => {
  const handleModalClose = () => {
    onRequestClose();
  };

  const handleScheduleDemoClick = () => {
    onScheduleDemoClick();
  };

  return (
    <Modal
      className={styles.modal}
      contentClassName={styles.videoModalContent}
      header="See How Register Works"
      isOpen={isOpen}
      isFullScreenMobile
      onRequestClose={handleModalClose}
    >
      <VideoModalContent
        onScheduleDemoClick={handleScheduleDemoClick}
        hasValidCase={hasValidCase}
        shopId={shopId}
      />
    </Modal>
  );
};
