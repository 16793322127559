import { CSSProperties } from 'react';
import {
  DraggableAttributes,
  DraggableSyntheticListeners,
} from '@dnd-kit/core';
import { Menu, MenuChangeEvent, RadioChangeEvent } from '@szhsin/react-menu';
import cx from 'classnames';

import { Link } from 'crust';

import { DragButton, OverflowButton } from 'components/shared/icon-button';
import Text from 'components/shared/text';
import useAnalytics from 'hooks/use-analytics';
import { registerLayoutCategory } from 'routes/paths';
import { getItemsWord } from 'utilities/menu';

import { CategoryColors } from './category-colors';
import { MenuItemColors } from './menu-item-colors';

import styles from './styles.module.scss';

type Props = {
  colorHex: string;
  count: number;
  draggableAttributes?: DraggableAttributes;
  draggableListeners?: DraggableSyntheticListeners;
  id: number;
  isDragging?: boolean;
  isOverlay?: boolean;
  isSingleColumn?: boolean;
  name: string;
  onSelectColor: (colorHex: string, id: number, productId?: number) => void;
  parentId?: number;
  shopId: string;
};

type CategoryItemNameProps = {
  count: number;
  name: string;
};

const CategoryItemName = ({ count, name }: CategoryItemNameProps) => {
  return (
    <div>
      <Text className={styles.name} clamp={2}>
        {name}
      </Text>
      <Text className={styles.itemCount} clamp={2}>
        {count} {getItemsWord(count)}
      </Text>
    </div>
  );
};

type MenuItemNameProps = {
  name: string;
};

const MenuItemName = ({ name }: MenuItemNameProps) => {
  return (
    <Text className={styles.itemName} clamp={2}>
      {name}
    </Text>
  );
};

const RegisterListItem = ({
  colorHex,
  count = 0,
  draggableAttributes,
  draggableListeners,
  id,
  isDragging = false,
  isOverlay = false,
  isSingleColumn = false,
  name,
  onSelectColor,
  parentId,
  shopId,
}: Props) => {
  const { trackRegisterMenuEditItemsClicked, trackRegisterMenuColorsOpened } =
    useAnalytics();

  const handleRadioChange = (event: RadioChangeEvent) => {
    onSelectColor(event.value, id, parentId);
  };

  const handleMenuChange = (event: MenuChangeEvent) => {
    if (event.open) {
      trackRegisterMenuColorsOpened(shopId, parentId == null, isSingleColumn);
    }
  };

  const handleClickEditItems = () => {
    trackRegisterMenuEditItemsClicked(shopId, isSingleColumn);
  };

  const showEditItems = count > 0;

  return (
    <div
      className={cx(
        styles.content,
        isDragging && styles.dragging,
        isOverlay && styles.overlay,
        isSingleColumn && styles.single,
      )}
      style={
        {
          '--layout-color': colorHex,
        } as CSSProperties
      }
    >
      <div
        className={cx(
          isSingleColumn ? styles.categoryContainer : styles.itemContainer,
        )}
      >
        <DragButton
          className={styles.handle}
          iconClassName={cx(isSingleColumn ? styles.dragIcon : null)}
          label={`Move ${name}`}
          {...draggableAttributes}
          {...draggableListeners}
        />
        {isSingleColumn ? (
          <CategoryItemName count={count} name={name} />
        ) : (
          <MenuItemName name={name} />
        )}
      </div>
      <div className={styles.editContainer}>
        {showEditItems && (
          <Link
            className={styles.edit}
            variant="secondary"
            onPress={handleClickEditItems}
            href={registerLayoutCategory(shopId, id)}
          >
            Edit items
          </Link>
        )}
        <Menu
          portal
          align="end"
          className={styles.menu}
          menuButton={
            <OverflowButton
              className={cx(
                isSingleColumn ? styles.categoryOverflow : styles.overflow,
              )}
              label={`Change the color of ${name}`}
            />
          }
          onMenuChange={handleMenuChange}
        >
          {isSingleColumn ? (
            <CategoryColors
              colorHex={colorHex}
              handleRadioChange={handleRadioChange}
            />
          ) : (
            <MenuItemColors
              colorHex={colorHex}
              handleRadioChange={handleRadioChange}
            />
          )}
        </Menu>
      </div>
    </div>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default RegisterListItem;
