import { createElement, useState } from 'react';
import { toast } from 'react-toastify';

import Link from 'components/shared/link';
import Button, { ButtonVariant } from 'components/shared/slice-button';
import { ActionData } from 'components/shop-score/actions-overview';
import useAnalytics from 'hooks/use-analytics';

import styles from './styles.module.scss';

type Props = {
  data: ActionData;
  shopId: string;
};

const Action = ({ data, shopId }: Props) => {
  const { trackShopScore } = useAnalytics();
  const traitName = data.id;

  const [isDisabled, setIsDisabled] = useState(false);

  const trackButtonClick = () => {
    trackShopScore({ shopId, traitName });

    if (data.disabledButtonText) {
      setIsDisabled(true);
    }

    if (data.isToastShownOnButtonClick) {
      toast.success(
        'Message sent. Your account manager will be in touch soon.',
      );
    }
  };

  if (!data || !shopId) {
    return <></>;
  }

  return (
    <div aria-label="action item" className={styles.wrapper}>
      <div className={styles.icon}>
        {createElement(data.icon, {
          'aria-hidden': true,
          className: styles.actionItemSvgIcon,
        })}
      </div>
      <div className={styles.content}>
        <div className={styles.actionInfo}>
          <div className={styles.title}>{data.title}</div>
          <div className={styles.description}>
            {data.description}
            {!!data.descriptionLink && !!data.descriptionUrl && (
              <>
                <Link
                  className={styles.descriptionLink}
                  to={data.descriptionUrl}
                >
                  {data.descriptionLink}
                </Link>
                .
              </>
            )}
          </div>
        </div>
        <div className={styles.actionButton}>
          {data.isCompleted ? (
            <div className={styles.allSetText}>Completed!</div>
          ) : (
            <Button
              className={styles.button}
              variant={ButtonVariant.Secondary}
              data-chameleon-target={data.chameleonTag}
              onClick={trackButtonClick}
              disabled={isDisabled}
            >
              {isDisabled ? data.disabledButtonText : data.buttonText}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default Action;
