import { Icon } from 'crust';

import styles from './styles.module.scss';

export const NoDataMessage = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.noDataMessage}>
        <Icon className={styles.graphIcon} icon="graph" size="large" />
        <div className={styles.text}>
          <p>We&apos;re gathering your data.</p>
          <p>Check back tomorrow.</p>
        </div>
      </div>
    </div>
  );
};
