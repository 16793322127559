import { useEffect, useState } from 'react';
import { Control, useForm, useWatch } from 'react-hook-form';

import { Button } from 'crust';

import { RHFTextField } from 'components/shared/rhf-text-field';
import { TooltipIconButton } from 'components/shared/tooltips';
import useAnalytics from 'hooks/use-analytics';
import { SetStoredState, StoredState } from 'hooks/use-stored-state';
import { ShopTraits } from 'types/shops';
import {
  SmartButtonFormValues,
  UrlInputFormValues,
  WebsiteIntegrationsAnalyticsPageName,
} from 'types/website-tools';
import { addHttpsProtocolToUrl, isValidHttpUrlString } from 'utilities/strings';
import { getUrlParams } from 'utilities/website-tools';

import styles from './styles.module.scss';

type Props = {
  control: Control<SmartButtonFormValues>;
  shopId: number;
  shopUuid: string;
  shopUrl: StoredState<string>;
  setShopUrl: SetStoredState<string>;
  shopTraits: ShopTraits;
  page: WebsiteIntegrationsAnalyticsPageName;
  isMultiLocEnabled: boolean;
};

const SmartButtonPreview = ({
  control,
  shopId,
  shopUuid,
  shopUrl,
  setShopUrl,
  shopTraits,
  page,
  isMultiLocEnabled,
}: Props): JSX.Element => {
  const [invalidSubmitCount, setInvalidSubmitCount] = useState(0);

  const {
    trackSubmittedWebsiteIntegrationsWebsitePreview,
    trackWebsiteIntegrationsUrlValidationErrorDisplayed,
  } = useAnalytics();
  const currentFormValues = useWatch({ control });

  const {
    control: urlControl,
    formState: { errors },
    getValues,
    handleSubmit,
  } = useForm<UrlInputFormValues>({
    defaultValues: {
      urlInput: shopUrl ?? '',
    },
    mode: 'onBlur',
  });

  const params = getUrlParams(
    shopUrl ?? '',
    shopUuid,
    currentFormValues as SmartButtonFormValues,
  );

  const handleValidSubmit = ({ urlInput }: UrlInputFormValues) => {
    trackSubmittedWebsiteIntegrationsWebsitePreview({
      shopId: String(shopId),
      page,
      value: urlInput,
      passedValidation: true,
      isActive: String(shopTraits.isSmartButtonActive),
    });
    setShopUrl(urlInput === '' ? urlInput : addHttpsProtocolToUrl(urlInput));
    setInvalidSubmitCount(0);
  };

  const handleInvalidSubmit = () => {
    trackSubmittedWebsiteIntegrationsWebsitePreview({
      shopId: String(shopId),
      page,
      value: getValues('urlInput'),
      passedValidation: false,
      isActive: String(shopTraits.isSmartButtonActive),
    });
    setInvalidSubmitCount((previous) => {
      return previous + 1;
    });
  };

  useEffect(() => {
    if (invalidSubmitCount > 0) {
      trackWebsiteIntegrationsUrlValidationErrorDisplayed({
        shopId: String(shopId),
        errorMessage: 'please enter a valid URL',
        errorType: 'validate',
        input: 'website preview',
        location: WebsiteIntegrationsAnalyticsPageName.SmartButtons,
        submitCount: invalidSubmitCount,
        value: getValues('urlInput'),
        isActive: String(shopTraits.isSmartButtonActive),
      });
    }
  }, [
    getValues,
    invalidSubmitCount,
    shopId,
    trackWebsiteIntegrationsUrlValidationErrorDisplayed,
    shopTraits.isSmartButtonActive,
  ]);

  const validateUrlInput = (value: string) => {
    if (value === '' || isValidHttpUrlString(addHttpsProtocolToUrl(value))) {
      return true;
    }

    return 'Please enter a valid URL.';
  };

  const disablePopup = `&modal_enabled=${isMultiLocEnabled ? 'true' : 'false'}`;

  return (
    <div>
      <form
        className={styles.previewUrlForm}
        onSubmit={handleSubmit(handleValidSubmit, handleInvalidSubmit)}
      >
        <div className={styles.urlInputContainer}>
          <RHFTextField
            aria-label="Enter your website address"
            className={styles.urlField}
            control={urlControl}
            name="urlInput"
            placeholder={'Enter your website address'}
            rules={{
              validate: validateUrlInput,
            }}
          />
          <Button type="submit" variant="secondary">
            Preview
          </Button>
        </div>
        {shopUrl && !errors.urlInput && (
          <p className={styles.urlHint}>
            Preview your site below. Not seeing your website?
            <TooltipIconButton
              label="Preview Site"
              tooltipClassName={styles.tooltip}
            >
              Check the URL and try again. Note: Some websites are not able to
              be previewed. You can still manage your Smart Button using this
              tool.
            </TooltipIconButton>
          </p>
        )}
      </form>
      <iframe
        className={styles.previewIframe}
        src={`/smart-button-preview.html?${params}${disablePopup}`}
        scrolling="no"
        title="Smart Button Preview"
      />
    </div>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default SmartButtonPreview;
