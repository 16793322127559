// Figma designs: https://www.figma.com/file/LhfbXA0WBRo95JUlABH9yG/Pizzeria-Score-1.2?node-id=1%3A7

export const bar = {
  x: 10,
  y: 13,
  width: 215,
  height: 13,
  fill: '#9AA9B9',
  fillOpacity: '0.31',
};

export const xOffsetsFromBarStart = {
  ok: 13,
  good: 73,
  great: 137,
  allStar: 199,
};

export const scoreIndicators = {
  width: 21,
  height: 25,
};

// Width must contain bar and half a score indicator beyond both ends of the bar
export const outerSvgDimensions = {
  width: bar.width + scoreIndicators.width,
  height: 50,
};

export const scoreToXPosition = (score: number): number =>
  (score / 100) * bar.width;

export const label = {
  lineLength: 16,
  lineStroke: '#9AA9B9',
  textFill: '#697280',
};

export const shopIndicatorColors = {
  fill: '#FFE4AF',
  stroke: '#181218',
};

export const averageIndicatorColors = {
  fill: '#fff',
  stroke: '#697280',
};
