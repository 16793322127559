import { PartialKeys } from '@tanstack/react-table';
import { v4 as uuidv4 } from 'uuid';

import { MenuCategoryResponseBody } from 'types/menu/api';
import {
  DayOfWeek,
  MenuCategory,
  MenuCategoryAvailability,
  MenuCategoryFormAvailability,
  MenuCategoryFormDay,
  MenuCategoryFormEndOption,
  MenuCategoryFormStartOption,
  MenuCategoryFormValues,
  ModifiedMenuCategory,
} from 'types/menu/category';
import {
  compareTimes,
  get12HourString,
  getIsValidTime,
  getTimesInRange,
} from 'utilities/shared/time';

export const getNewId = () => `new_${uuidv4()}` as const;

export const getCategoryBody = (
  values: MenuCategoryFormValues,
  original?: MenuCategory | undefined,
): ModifiedMenuCategory => {
  const category: ModifiedMenuCategory = {
    availabilities: [],
    description: values.description,
    externalId: original?.externalId ?? null,
    hidden: original?.hidden ?? false,
    id: original?.id ?? getNewId(),
    name: values.name,
    productIds: original?.productIds ?? [],
    shippingType: original?.shippingType ?? null,
    validFrom: original?.validFrom ?? null,
    validThrough: original?.validThrough ?? null,
  };

  for (const day of values.days) {
    for (const availability of day.availabilities) {
      category.availabilities.push({
        id: availability.id ?? getNewId(),
        dayOfWeek: day.name,
        startTime: availability.start,
        endTime: availability.end,
      });
    }
  }

  return category;
};

export const getEmptyCategoryFormDays = (): MenuCategoryFormDay[] =>
  Object.values(DayOfWeek).map((it) => ({
    availabilities: [],
    name: it,
  }));

export const getDefaultCategoryFormDays = (
  availabilities: MenuCategoryAvailability[],
): MenuCategoryFormDay[] => {
  if (availabilities.length === 0) {
    return [];
  }

  const days = getEmptyCategoryFormDays();
  const daysByName = Object.fromEntries(days.map((it) => [it.name, it]));

  for (const availability of availabilities) {
    const { dayOfWeek, endTime: end, id, startTime: start } = availability;
    const day = daysByName[dayOfWeek];

    if (day) {
      day.availabilities.push({
        end,
        start,
        id,
      });
    }
  }

  return days;
};

export const getDefaultCategoryFormValues = (
  response?: MenuCategoryResponseBody,
): MenuCategoryFormValues => ({
  days: getDefaultCategoryFormDays(response?.category.availabilities ?? []),
  description: response?.category.description ?? '',
  name: response?.category.name ?? '',
});

export const getIsStartBeforeEnd = (
  availability: MenuCategoryFormAvailability,
): boolean => compareTimes(availability.start, availability.end) < 0;

// The start time is overlapping when it is within the bounds of the other
// availability. This test detects overlaps in which the availability in
// question is either entirely contained by the other availability or it
// overlaps the availability on the trailing edge (ie the end time in question
// occurs after the other availability.
export const getIsStartOverlapping = (
  start: string,
  other: MenuCategoryFormAvailability,
): boolean =>
  compareTimes(start, other.start) >= 0 && compareTimes(start, other.end) < 0;

// The end time is overlapping when it is within the bounds of the other
// availability or it comes anytime after the start of the other availability
// and the associated start time in question occurs before the other
// availability. This extra check of the associated start time detects overlaps
// in which the availability in question is longer than the other and overlaps
// it entirely or on the leading edge.
export const getIsEndOverlapping = (
  start: string,
  end: string,
  other: MenuCategoryFormAvailability,
): boolean =>
  compareTimes(end, other.start) > 0 &&
  (compareTimes(start, other.start) < 0 || compareTimes(end, other.end) <= 0);

const getIsOverlapping = (
  a: MenuCategoryFormAvailability,
  b: MenuCategoryFormAvailability,
): boolean =>
  getIsStartOverlapping(a.start, b) || getIsEndOverlapping(a.start, a.end, b);

const getIsAvailablityValid = (
  availability: MenuCategoryFormAvailability,
  others: MenuCategoryFormAvailability[],
): boolean =>
  getIsValidTime(availability.start) &&
  getIsValidTime(availability.end) &&
  getIsStartBeforeEnd(availability) &&
  !others.some((others) => getIsOverlapping(availability, others));

// Availabilities are always validated against previous valid availabilities in
// the list. For example, if there two overlapping availabilities (with valid
// time values) only the second one is invalid.
const getValidAvailabilities = (
  availabilities: MenuCategoryFormAvailability[],
): MenuCategoryFormAvailability[] => {
  const valids: MenuCategoryFormAvailability[] = [];

  for (const availability of availabilities) {
    if (getIsAvailablityValid(availability, valids)) {
      valids.push(availability);
    }
  }

  return valids;
};

export const getPossibleAvailabilities = (
  availabilities: MenuCategoryFormAvailability[],
): MenuCategoryFormAvailability[] => {
  const candidates: MenuCategoryFormAvailability[] = [];
  const sorted = getValidAvailabilities(availabilities).sort((a, b) =>
    compareTimes(a.start, b.start),
  );

  let start = '00:00';

  for (const availability of sorted) {
    if (compareTimes(start, availability.start) < 0) {
      candidates.push({ start, end: availability.start });
    }

    start = availability.end;
  }

  if (start !== '23:59') {
    candidates.push({ start, end: '23:59' });
  }

  return candidates;
};

const getEndOption = (
  data: PartialKeys<MenuCategoryFormEndOption, 'isDisabled' | 'label'>,
): MenuCategoryFormEndOption => ({
  isDisabled: data.isDisabled ?? false,
  value: data.value,
  label: get12HourString(data.value),
});

const getStartOption = (
  data: PartialKeys<MenuCategoryFormStartOption, 'isDisabled' | 'label'>,
): MenuCategoryFormStartOption => ({
  ...getEndOption(data),
  latestEndTime: data.latestEndTime,
});

export const getAvailabilityOptions = (
  currentAvailability: MenuCategoryFormAvailability,
  otherAvailabilities: MenuCategoryFormAvailability[],
): {
  startOptions: MenuCategoryFormStartOption[];
  endOptions: MenuCategoryFormEndOption[];
} => {
  const endOptions: MenuCategoryFormEndOption[] = [];
  const startOptions: MenuCategoryFormStartOption[] = [];

  const possibleAvailablities = getPossibleAvailabilities(otherAvailabilities);

  // See comment below. We need to know if we have seen the values of the
  // current availability while creating the options.
  let isCurrentEndAnOption = false;
  let isCurrentStartAnOption = false;

  // This function could run quite often so we try to loop through the
  // candidates only once to create all of the options.
  for (const { end, start } of possibleAvailablities) {
    const times = getTimesInRange(start, end, 15);

    // Once we have seen the current start value in this range we can use the
    // rest of the range to create end options.
    let hasCurrentStart = false;

    times.forEach((time, i) => {
      // Deal with end options first because we only want to start creating them
      // on the iteration after we first see the current start time. The end
      // time must be later than the start time.
      if (hasCurrentStart) {
        endOptions.push(
          getEndOption({
            value: time,
          }),
        );

        if (time === currentAvailability.end) {
          isCurrentEndAnOption = true;
        }
      }

      // The start time cannot be the last time in the range.
      if (i < times.length - 1) {
        const lastTime = times[times.length - 1];
        if (lastTime) {
          startOptions.push(
            getStartOption({
              value: time,
              latestEndTime: lastTime,
            }),
          );
        }

        if (time === currentAvailability.start) {
          hasCurrentStart = true;
          isCurrentStartAnOption = true;
        }
      }
    });
  }

  // Before sc-329538, the category form allowed for saving invalid time values.
  // If the user opens a category with bad data, we want to be able to show them
  // the bad times in the select, but not allow them to keep those values on
  // save or recreate them. If a invalid start or end time is present and not
  // already in the list of options, add it to the end and disable it.
  // https://app.shortcut.com/slicelife/story/329538/no-validation-in-the-time-picker-of-the-category-availability
  if (currentAvailability.start && !isCurrentStartAnOption) {
    startOptions.push(
      getStartOption({
        isDisabled: true,
        value: currentAvailability.start,
        latestEndTime: '11:59',
      }),
    );
  }

  if (currentAvailability.end && !isCurrentEndAnOption) {
    endOptions.push(
      getEndOption({
        isDisabled: true,
        value: currentAvailability.end,
      }),
    );
  }

  return {
    endOptions,
    startOptions,
  };
};

export const getContiguousEnd = (
  end: string,
  { value: start, latestEndTime }: MenuCategoryFormStartOption,
): string => {
  if (getIsEndOverlapping(start, end, { start, end: latestEndTime })) {
    return end;
  }

  return latestEndTime;
};
