import { useQuery } from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { DebitCards } from 'types/debit-cards';
import { Shop } from 'types/shops';
import { camelCaseKeys } from 'utilities/objects';

export const useDebitCardsQuery = (shopId: Shop['shopId']) => {
  const { authenticatedFetch } = useApi();

  return useQuery({
    queryKey: [shopId, 'debitCard'],
    queryFn: async () => {
      const response = await authenticatedFetch.get(
        `api/management/v1/shops/${shopId}/debit_cards`,
      );

      return camelCaseKeys(response.data) as DebitCards[];
    },
  });
};
