import { useEffect, useMemo, useState } from 'react';
import { CalendarDate, DateValue } from '@internationalized/date';

import {
  Link,
  PresetDateRange,
  PresetRangePicker,
  PresetRangeState,
  PresetRangeValue,
  useDateFormatter,
} from 'crust';

import { AchEnrollmentBanner } from 'components/shared/ach-enrollment-banner';
import Tile from 'components/shared/tile/tile';
import useAnalytics from 'hooks/use-analytics';
import { scamAdviceUrl } from 'routes/external-urls';
import { Shop } from 'types/shops';
import { getDefaultDateRangePresets } from 'utilities/shared/date-range-presets';
import { getShopHasFullRegister } from 'utilities/shops';

import styles from './styles.module.scss';

type DateRangeFilterProps<T extends DateValue, S extends string> = {
  defaultValue: PresetRangeValue<T, S>;
  maxValue: CalendarDate;
  onChange: (value: PresetRangeValue<T, S>) => void;
  presets: readonly PresetDateRange<T, S>[];
  shopId: string;
  shopTimezone: string;
};

const DateRangeFilter = <T extends DateValue, S extends string>({
  defaultValue,
  maxValue,
  onChange,
  presets,
  shopId,
  shopTimezone,
}: DateRangeFilterProps<T, S>) => {
  const [dates, setDates] =
    useState<PresetRangeState<typeof presets>>(defaultValue);

  const dateFormatter = useDateFormatter({
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    timeZone: shopTimezone,
  });

  const error = useMemo(() => {
    if (dates == null) {
      return 'Please choose start and end dates.';
    }

    if (dates.end.compare(maxValue) > 0) {
      const formatted = dateFormatter.format(maxValue.toDate(shopTimezone));
      return `Please choose an end date of ${formatted} or earlier.`;
    }

    if (dates.end.compare(dates.start) < 0) {
      return 'Please choose an end date that is after the start date.';
    }

    if (dates.end.compare(dates.start) > 30) {
      return 'Please select a maximum of 31 days.';
    }
  }, [dateFormatter, dates, maxValue, shopTimezone]);

  useEffect(() => {
    if (dates != null && error == null) {
      onChange(dates);
    }
  }, [dates, error, onChange, shopId]);

  return (
    <PresetRangePicker
      aria-label="dashboard date range"
      error={error}
      isInvalid={error != null}
      maxValue={maxValue}
      onChange={setDates}
      presets={presets}
      validationBehavior="aria"
      value={dates}
    />
  );
};

type DateRangePreset = ReturnType<typeof getDefaultDateRangePresets>;
type NotNullablePresetRangeState = NonNullable<
  PresetRangeState<DateRangePreset>
>;

type Props = {
  defaultValue: NotNullablePresetRangeState;
  maxValue: CalendarDate;
  onChange: (value: NotNullablePresetRangeState) => void;
  presets: DateRangePreset;
  shop: Shop;
  isAchOnboardingLink: boolean;
  redirectUrl: string;
};

export const DashboardSubheader = ({
  defaultValue,
  maxValue,
  onChange,
  presets,
  shop,
  isAchOnboardingLink,
  redirectUrl,
}: Props) => {
  const { trackClickedScamsInfoLink } = useAnalytics();

  const handleScamAdviceClick = () => {
    trackClickedScamsInfoLink({
      isRegister: getShopHasFullRegister(shop) ?? false,
      shopId: shop.shopId.toString(),
    });
  };

  return (
    <>
      {isAchOnboardingLink && (
        <AchEnrollmentBanner
          className={styles.achEnrollmentBanner}
          page="homepage"
          redirectUrl={redirectUrl}
        />
      )}
      <div className={styles.subheader}>
        <DateRangeFilter
          defaultValue={defaultValue}
          maxValue={maxValue}
          onChange={onChange}
          presets={presets}
          shopId={shop.shopId.toString()}
          shopTimezone={shop.timezoneIdentifier}
        />
        <Tile className={styles.linkTile}>
          <Link
            href={scamAdviceUrl}
            icon="lock"
            onPress={handleScamAdviceClick}
            size="small"
            variant="secondary"
          >
            Learn how to avoid scams
          </Link>
        </Tile>
      </div>
    </>
  );
};
