import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Link from 'components/shared/link';
import Text from 'components/shared/text';
import CaretIcon from 'images/caret.svg?react';
import DummyLogoIcon from 'images/dummy-logo.svg?react';
import * as paths from 'routes/paths';

import styles from './styles.module.scss';

const ShopSelector = ({ activeShop, shops }) => {
  const [isDropdownExpanded, setIsDropdownExpanded] = useState(false);
  const { logout } = useAuth0();

  useEffect(() => {
    setIsDropdownExpanded(false);
  }, [activeShop]);

  const hasMultipleShops = shops.length > 1;
  const onShopListClick = () => setIsDropdownExpanded(true);
  const clickableStyle = cx(styles.shopDetails, styles.clickable);

  const getLogoImage = (logo) =>
    logo ? (
      <img alt="Shop logo" className={styles.shopLogo} src={logo} />
    ) : (
      <DummyLogoIcon className={styles.dummyLogo} title="no logo" />
    );

  return (
    <>
      {isDropdownExpanded && (
        <button
          className={styles.clickAway}
          onClick={() => setIsDropdownExpanded(false)}
        />
      )}
      <div
        className={clickableStyle}
        onClick={onShopListClick}
        role="button"
        tabIndex={0}
        onKeyDown={onShopListClick}
        data-testid="desktopNavShopSelectorButton"
      >
        <div className={styles.shopTextWrapper}>
          <Text className={styles.shopDetailsShopName} clamp>
            {activeShop.name}
          </Text>
          <Text className={styles.shopDetailsShopAddress} clamp>
            {activeShop.address}
          </Text>
        </div>
        <CaretIcon className={styles.caret} />
        {isDropdownExpanded && (
          <div className={styles.shopDropdownContainer}>
            {hasMultipleShops && (
              <div className={styles.shopsList}>
                {shops.map(
                  (option) =>
                    option.shopId !== activeShop.shopId && (
                      <Link
                        key={option.shopId}
                        to={paths.shop(option.shopId)}
                        className={styles.dropdownOption}
                        variant="undecorated"
                      >
                        {getLogoImage(option.logoUrl)}
                        <div className={styles.dropdownTextWrapper}>
                          <div className={styles.dropdownShopName}>
                            {option.name}
                          </div>
                          <div className={styles.dropdownShopAddress}>
                            {option.address}
                          </div>
                        </div>
                      </Link>
                    ),
                )}
              </div>
            )}
            <button
              className={styles.logoutButton}
              onClick={() =>
                logout({
                  logoutParams: { returnTo: window.location.origin },
                })
              }
              type="button"
              data-testid="desktopNavShopSelectorMenuLogoutButton"
            >
              Log Out
            </button>
          </div>
        )}
      </div>
    </>
  );
};

ShopSelector.propTypes = {
  activeShop: PropTypes.object,
  shops: PropTypes.array,
};

ShopSelector.defaultProps = {
  activeShop: {},
  shops: [],
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default ShopSelector;
