import { datadogRum } from '@datadog/browser-rum';

import * as helpers from './helpers';

const { VITE_RESTAURANT_API_HOST } = import.meta.env;

const getPath = (path) => {
  if (path.startsWith('http')) {
    return path;
  }

  const separator = path.startsWith('/') ? '' : '/';
  return VITE_RESTAURANT_API_HOST + separator + path;
};

export const standard =
  (
    // eslint-disable-next-line no-use-before-define
    fetch,
    refreshAndRetryOnAuthFail,
    method,
    getAccessTokenSilently,
  ) =>
  async (
    path,
    payload = {},
    headers = {},
    includeCredentials = true,
    shouldRefreshAndRetryOnAuthFail = true,
    unauthenticatedRequest = false,
  ) => {
    let accessToken;
    try {
      accessToken = await getAccessTokenSilently();
    } catch (error) {
      datadogRum.addError(error);
    }

    if (!accessToken && !unauthenticatedRequest) {
      window.location.href = '/logout';
    }

    const options = {
      method,
      credentials: includeCredentials ? 'include' : 'omit',
      headers: { ...helpers.getContentAndAuthHeaders(accessToken), ...headers },
      body: JSON.stringify(payload),
    };

    let response;

    try {
      response = await fetch(getPath(path), options);
    } catch (error) {
      throw new helpers.NetworkError(error.message);
    }

    response = await refreshAndRetryOnAuthFail(
      options,
      shouldRefreshAndRetryOnAuthFail,
    )(response);

    // A band-aid for handling 204 responses that don't have a body. The API
    // currently requires a JSON response to all requests, but this API layer is
    // doing way too much.
    let body = null;
    try {
      body = await response.json();
    } catch (error) {
      if (response.status !== 204) {
        throw error;
      }
    }

    return helpers.checkStatusCode(response, body);
  };

export const bodiless =
  (
    // eslint-disable-next-line no-use-before-define
    fetch,
    refreshAndRetryOnAuthFail,
    method,
    getAccessTokenSilently,
  ) =>
  async (
    path,
    headers = {},
    includeCredentials = true,
    shouldRefreshAndRetryOnAuthFail = true,
    includeResponseHeaders = false,
  ) => {
    let accessToken;
    try {
      accessToken = await getAccessTokenSilently();
    } catch (error) {
      datadogRum.addError(error);
    }

    if (!accessToken) {
      window.location.href = '/logout';
    }

    const options = {
      method,
      credentials: includeCredentials ? 'include' : 'omit',
      headers: { ...helpers.getContentAndAuthHeaders(accessToken), ...headers },
    };

    let response;

    try {
      response = await fetch(getPath(path), options);
    } catch (error) {
      throw new helpers.NetworkError(error.message);
    }

    response = await refreshAndRetryOnAuthFail(
      options,
      shouldRefreshAndRetryOnAuthFail,
    )(response);

    // Here to allow 204 No Content to be considered valid
    const body =
      method === 'DELETE' ||
      ((method === 'POST' || method === 'PUT') && response.status === 204)
        ? {}
        : await response.json();
    return helpers.checkStatusCode(response, body, includeResponseHeaders);
  };

// POST-ing files in a multipart form-data request requires explicitly *not*
// setting any content-type header manually on the request, so this has been
// separated from the standard JSON POST-ing function.
export const postFile =
  (fetch, refreshAndRetryOnAuthFail, getAccessTokenSilently) =>
  async (path, formData, shouldRefreshAndRetryOnAuthFail = true) => {
    let accessToken;
    try {
      accessToken = await getAccessTokenSilently();
    } catch (error) {
      datadogRum.addError(error);
    }

    if (!accessToken) {
      window.location.href = '/logout';
    }

    const options = {
      body: formData,
      credentials: 'include',
      headers: { ...helpers.getAuthHeader(accessToken) },
      method: 'POST',
    };

    let response;

    try {
      response = await fetch(getPath(path), options);
    } catch (error) {
      throw new helpers.NetworkError(error.message);
    }

    response = await refreshAndRetryOnAuthFail(
      options,
      shouldRefreshAndRetryOnAuthFail,
    )(response);
    const body = await response.json();
    return helpers.checkStatusCode(response, body);
  };
