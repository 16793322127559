import { useQuery } from '@tanstack/react-query';
import { z } from 'zod';

import { Shop } from 'types/shops';
import { camelCaseKeys } from 'utilities/objects';

import useApi from '../use-api';

const schema = z.object({
  verificationCode: z.string(),
});

const getVerificationCodeQueryKey = (shopId: Shop['shopId']) =>
  [shopId, 'verification-code'] as const;

export const useVerificationCodeQuery = (shopId: Shop['shopId']) => {
  const { authenticatedFetch } = useApi();

  return useQuery({
    queryKey: getVerificationCodeQueryKey(shopId),
    queryFn: async () => {
      const response = await authenticatedFetch.get(
        `api/management/v1/shops/${shopId}/verification_code`,
      );

      return schema.parse(camelCaseKeys(response));
    },
  });
};
