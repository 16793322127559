import { ComponentPropsWithoutRef, forwardRef, Ref } from 'react';
import cx from 'classnames';

import styles from './styles.module.scss';

export type InputProps = {
  isInvalid?: boolean;
} & ComponentPropsWithoutRef<'input'>;

const Input = forwardRef(function Input(
  { className, isInvalid = false, ...inputProps }: InputProps,
  ref: Ref<HTMLInputElement>,
) {
  return (
    <input
      className={cx(styles.input, className)}
      aria-invalid={isInvalid}
      spellCheck
      ref={ref}
      {...inputProps}
    />
  );
});

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default Input;
