import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { zodResolver } from '@hookform/resolvers/zod';
import { CalendarDate, today } from '@internationalized/date';
import { useIsMutating } from '@tanstack/react-query';
import cx from 'classnames';
import { z } from 'zod';

import { useDateFormatter } from 'crust';

import FormFeedback from 'components/shared/form-feedback';
import { RHFDateRangePicker } from 'components/shared/rhf-date-range-picker';
import { RHFRadio, RHFRadioGroup } from 'components/shared/rhf-radio';
import Modal from 'components/shared/slice-modal';
import {
  getCustomerExportsMutationKey,
  useCreateCustomersExportMutation,
} from 'hooks/customers/use-create-customer-export-mutation';
import useAnalytics from 'hooks/use-analytics';
import { Shop } from 'types/shops';
import {
  showInvalidSubmitToast,
  showWereNotAbleToProcessErrorToast,
} from 'utilities/forms';
import {
  getLast7DaysPresetRange,
  getLast30DaysPresetRange,
  getTodayPresetRange,
} from 'utilities/shared/date-range-presets';

import styles from './styles.module.scss';

const customerTypeOptions = [
  { label: 'All', value: 'all' },
  { label: 'Online', value: 'online' },
  { label: 'Register', value: 'offline' },
] as const;

const getPresets = (timezone: string) =>
  [
    getTodayPresetRange(timezone),
    getLast7DaysPresetRange(timezone),
    getLast30DaysPresetRange(timezone),
  ] as const;

type SchemaContext = {
  maxValue: CalendarDate;
  presets: ReturnType<typeof getPresets>;
};

type MappedValues<T extends readonly Record<K, unknown>[], K extends string> = {
  [I in keyof T]: T[I][K];
};

const getSchema = ({ maxValue, presets }: SchemaContext) => {
  const customerType = z.enum(
    customerTypeOptions.map((it) => it.value) as unknown as MappedValues<
      typeof customerTypeOptions,
      'value'
    >,
  );

  return z.discriminatedUnion('lastOrderPreset', [
    z.object({
      customerType,
      lastOrderPreset: z.enum([
        'all',
        ...(presets.map((it) => it.id) as unknown as MappedValues<
          typeof presets,
          'id'
        >),
      ]),
      dates: z.null().optional(),
    }),
    z.object({
      customerType,
      lastOrderPreset: z.literal('custom'),
      dates: z
        .object(
          {
            start: z.instanceof(CalendarDate),
            end: z.instanceof(CalendarDate),
          },
          {
            invalid_type_error: 'Please choose export dates.',
            required_error: 'Please choose export dates.',
          },
        )
        .superRefine((value, ctx) => {
          if (value.end.compare(maxValue) > 0) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'Please choose an end date of {maxValue} or earlier.',
            });

            return z.NEVER;
          }

          if (value.end.compare(value.start) < 0) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message:
                'Please choose an end date that is after the start date.',
            });
          }
        }),
    }),
  ]);
};

type FormValues = z.infer<ReturnType<typeof getSchema>>;

const getDateContext = (timezone: string) => {
  const maxValue = today(timezone);
  const presets = getPresets(timezone);

  return {
    maxValue,
    presets,
    timezone,
    schema: getSchema({ maxValue, presets }),
  };
};

type ExportFormProps = {
  id: string;
  isFullRegisterShop: boolean;
  onError: () => void;
  onSuccess: () => void;
  shopId: Shop['shopId'];
  shopTimezone: Shop['timezoneIdentifier'];
};

const ExportForm = ({
  id,
  isFullRegisterShop,
  onError,
  onSuccess,
  shopId,
  shopTimezone,
}: ExportFormProps) => {
  const [dateContext, setDateContext] = useState(() =>
    getDateContext(shopTimezone),
  );

  useEffect(() => {
    if (shopTimezone !== dateContext.timezone) {
      setDateContext(getDateContext(shopTimezone));
    }
  }, [dateContext.timezone, shopTimezone]);

  const { trackClickedCustomerExport } = useAnalytics();

  const dateFormatter = useDateFormatter({
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    timeZone: shopTimezone,
  });

  const { mutate: createCustomerExport } = useCreateCustomersExportMutation(
    shopId,
    shopTimezone,
  );

  const { presets, maxValue } = dateContext;

  const {
    clearErrors,
    control,
    formState: { errors },
    handleSubmit,
    setError,
    watch,
  } = useForm<FormValues>({
    defaultValues: {
      customerType: isFullRegisterShop ? 'all' : 'online',
      lastOrderPreset: 'all',
      dates: null,
    },
    resolver: zodResolver(dateContext.schema),
    mode: 'onChange',
    shouldUnregister: true,
  });

  const selectedPreset = watch('lastOrderPreset');

  const clearServerError = () => {
    clearErrors('root.serverError');
  };

  const handleValidSubmit = (values: FormValues) => {
    const { customerType, lastOrderPreset } = values;

    const preset =
      lastOrderPreset === 'all' || lastOrderPreset === 'custom'
        ? undefined
        : presets.find((it) => it.id == lastOrderPreset);
    const dates =
      lastOrderPreset === 'custom'
        ? values.dates
        : preset
          ? { start: preset.start, end: preset.end }
          : undefined;

    trackClickedCustomerExport({
      customerType,
      dates,
      shopId,
      lastOrderPreset,
    });

    createCustomerExport(
      { customerType, dates },
      {
        onError: () => {
          showWereNotAbleToProcessErrorToast();
          onError();
        },
        onSuccess: (response) => {
          // a 204 response means the request was successful but no csv was generated as it would have no customers
          // within it. In this case the export id will not be available. We use this logic to show an error message to the use explaining
          // that the selected date range would contain no customers and keep th modal open.
          if (response.id) {
            toast.success(
              'Your export is being processed. Please check back soon.',
            );
            onSuccess();
          } else {
            setError('root.serverError', {
              type: 'noCustomersInRange',
              message:
                'There are no customers to export for the selected date range.',
            });
          }
        },
      },
    );
  };

  return (
    <form
      id={id}
      onSubmit={handleSubmit(handleValidSubmit, showInvalidSubmitToast)}
    >
      <div className={styles.radioGroupsContainer}>
        <RHFRadioGroup
          className={cx(
            !isFullRegisterShop && styles.customerTypeOptionsHidden,
          )}
          control={control}
          isRequired
          label="Customers"
          name="customerType"
          orientation="horizontal"
          rules={{
            onChange: clearServerError,
          }}
        >
          {customerTypeOptions.map(({ label, value }) => (
            <RHFRadio key={value} label={label} value={value} />
          ))}
        </RHFRadioGroup>
        <RHFRadioGroup
          control={control}
          isRequired
          label="Date of Last Order"
          name="lastOrderPreset"
          orientation="vertical"
          rules={{
            onChange: clearServerError,
          }}
        >
          <RHFRadio label="All" value="all" />
          {presets.map(({ id, label }) => (
            <RHFRadio key={id} label={label} value={id} />
          ))}
          <RHFRadio label="Custom" value="custom" />
        </RHFRadioGroup>
      </div>
      {selectedPreset === 'custom' ? (
        <RHFDateRangePicker
          aria-label="custom last order dates"
          control={control}
          error={
            'dates' in errors &&
            errors.dates?.message?.replace(
              '{maxValue}',
              dateFormatter.format(maxValue.toDate(shopTimezone)),
            )
          }
          maxValue={maxValue}
          name="dates"
          rules={{
            onChange: clearServerError,
          }}
        />
      ) : null}
      <FormFeedback>{errors.root?.serverError?.message}</FormFeedback>
    </form>
  );
};

type Props = {
  closeModal: () => void;
  isFullRegisterShop: boolean;
  isOpen: boolean;
  shopTimezone: Shop['timezoneIdentifier'];
  shopId: Shop['shopId'];
};

export const ExportModal = ({
  closeModal,
  isFullRegisterShop,
  isOpen,
  shopTimezone,
  shopId,
}: Props) => {
  const isMutating = useIsMutating({
    mutationKey: getCustomerExportsMutationKey(shopId),
  });

  return (
    <Modal
      className={styles.modal}
      fadeClassName={styles.fade}
      formId="export-customers-modal-form"
      header="Export Customers"
      hideCloseIcon
      isFullScreenMobile
      isOpen={isOpen}
      isNoButtonDisabled={isMutating > 0}
      isYesButtonDisabled={isMutating > 0}
      noButtonText="Cancel"
      onClickNo={closeModal}
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
      yesButtonText="Export"
    >
      <ExportForm
        id="export-customers-modal-form"
        isFullRegisterShop={isFullRegisterShop}
        onError={closeModal}
        onSuccess={closeModal}
        shopId={shopId}
        shopTimezone={shopTimezone}
      />
    </Modal>
  );
};
