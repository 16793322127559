import * as React from "react";
import { forwardRef } from "react";
const SvgPencilIcon = ({
  title,
  titleId,
  ...props
}, ref) => {
  return /* @__PURE__ */ React.createElement("svg", { width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", "aria-hidden": "true", ref, "aria-labelledby": titleId, ...props }, title === void 0 ? /* @__PURE__ */ React.createElement("title", { id: titleId }, "Pencil Icon") : title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { d: "M18.5319 2C17.6121 2 16.7299 2.36539 16.0795 3.01579L14.2222 4.8731L19.1269 9.77778L20.9842 7.92047C21.6346 7.27007 22 6.38793 22 5.46813C22 3.55273 20.4473 2 18.5319 2Z", fill: "currentColor" }), /* @__PURE__ */ React.createElement("path", { d: "M3.87181 15.2235L12.6509 6.44445L17.5555 11.3491L8.77648 20.1282C8.34929 20.5554 7.81402 20.8585 7.22791 21.005L3.38062 21.9668C3.00198 22.0615 2.60144 21.9505 2.32546 21.6745C2.04948 21.3986 1.93854 20.998 2.0332 20.6194L2.99502 16.7721C3.14155 16.186 3.44461 15.6507 3.87181 15.2235Z", fill: "currentColor" }));
};
const ForwardRef = forwardRef(SvgPencilIcon);
export default ForwardRef;
