import TextHeader from 'components/shared/header';
import { ShopRatings } from 'types/shops';

import ReviewSummary from './review-summary';

import styles from './styles.module.scss';

type Props = {
  isLoading?: boolean;
  shopRatings?: ShopRatings;
};

const Header = ({ isLoading, shopRatings }: Props) => (
  <div className={styles.wrapper}>
    <div className={styles.infoWrapper}>
      <TextHeader
        title="Track Your Ratings & Reviews"
        content={[
          `Customers have an opportunity to rate and review their experience after
          an order on Slice. Here you can track your ratings and respond to your
          customers.`,
        ]}
      />
      {/* Because we want to bold the word 'Note:' we cannot pass this into our content array in the TextHeader */}
      <p className={styles.headerReplyHelp}>
        <span className={styles.headerReplyNote}>Note:</span> You can only reply
        to reviews posted within the last 90 days.
      </p>
    </div>
    {shopRatings?.shouldDisplayRating && !isLoading && (
      <ReviewSummary
        rating={shopRatings.displayRating}
        ratingsCount={shopRatings.ratingsCount}
      />
    )}
  </div>
);

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default Header;
