import * as React from "react";
import { forwardRef } from "react";
const SvgCheckmarkIcon = ({
  title,
  titleId,
  ...props
}, ref) => {
  return /* @__PURE__ */ React.createElement("svg", { width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", "aria-hidden": "true", ref, "aria-labelledby": titleId, ...props }, title === void 0 ? /* @__PURE__ */ React.createElement("title", { id: titleId }, "Checkmark Icon") : title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { d: "M17.7541 3L9.45669 15.1824L5.4644 12.7401L3 15.2567L7.14297 19.8188C7.82268 20.5672 8.82956 21 9.8913 21C11.2464 21 12.4851 20.2975 13.0911 19.1853L21 4.67045L17.7541 3Z", fill: "currentColor" }));
};
const ForwardRef = forwardRef(SvgCheckmarkIcon);
export default ForwardRef;
