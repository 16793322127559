import { ReactNode } from 'react';
import cx from 'classnames';

import CheckmarkSolidIcon from 'images/checkmark-solid.svg?react';

import styles from './styles.module.scss';

type Props = {
  isActive?: boolean;
  subtitle: string;
  title: ReactNode;
};

const ListItem = ({ isActive, subtitle, title }: Props) => {
  return (
    <li className={cx(styles.listItem, isActive && styles.isActive)}>
      <div className={styles.iconWrapper}>
        <CheckmarkSolidIcon className={styles.icon} />
      </div>
      <div className={styles.description}>
        <div className={styles.title}>{title}</div>
        <div className={styles.subtitle}>{subtitle}</div>
      </div>
    </li>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default ListItem;
