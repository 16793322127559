import { useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { createColumnHelper, SortingState } from '@tanstack/react-table';

import { Link } from 'crust';

import ContentTile from 'components/shared/content-tile';
import Header from 'components/shared/header';
import Modal from 'components/shared/slice-modal';
import Table from 'components/shared/table';
import TableAction from 'components/shared/table/action';
import Text from 'components/shared/text';
import VisuallyHidden from 'components/shared/visually-hidden';
import { useDeleteRegisterDiscountsMutation } from 'hooks/discounts/use-delete-register-discounts-mutation';
import { useTable } from 'hooks/shared';
import * as paths from 'routes/paths';
import { RegisterDiscount } from 'types/discounts';
import { Shop } from 'types/shops';
import {
  getFormattedRegisterDiscountAmount,
  getFormattedRegisterDiscountDate,
  getFormattedRegisterDiscountIsCombinable,
} from 'utilities/discounts';
import { showUnexpectedErrorToast } from 'utilities/forms';

import styles from './styles.module.scss';

type Props = {
  discounts: RegisterDiscount[];
  shopId: Shop['shopId'];
  shopTimezone: Shop['timezoneIdentifier'];
};

const RegisterDiscounts = ({ discounts, shopId, shopTimezone }: Props) => {
  const [discountToDelete, setDiscountToDelete] = useState<RegisterDiscount>();

  const [sorting, setSorting] = useState<SortingState>([
    {
      id: 'name',
      desc: false,
    },
  ]);

  const { mutate: deleteDiscount } = useDeleteRegisterDiscountsMutation(shopId);

  const handleDeleteRegisterDiscount = (id: RegisterDiscount['id']) =>
    deleteDiscount(id, {
      onError: () => {
        showUnexpectedErrorToast();
      },
      onSuccess: () => {
        toast.success('Discount successfully deleted!');
      },
      onSettled: () => {
        handleModalClose();
      },
    });

  const handleModalClose = () => {
    setDiscountToDelete(undefined);
  };

  const handleModalYes = () => {
    const id = discountToDelete?.id;

    if (id) {
      handleDeleteRegisterDiscount(id);
    }
  };

  const columns = useMemo(() => {
    const helper = createColumnHelper<RegisterDiscount>();

    return [
      helper.accessor('name', {
        header: 'Code',
        cell(ctx) {
          return <Text clamp>{ctx.getValue()}</Text>;
        },
      }),

      // If this column were sortable, it wouldn't make sense to sort by the raw
      // numbers because they are a mix of money as cents and percents as
      // integers. Instead, we'll use the formatted amount as the base value.
      helper.accessor(getFormattedRegisterDiscountAmount, {
        id: 'amount',
        header: 'Amount',
        enableSorting: false,
      }),

      helper.accessor('isCombinable', {
        header: 'Combinable',
        enableSorting: false,
        cell(ctx) {
          return getFormattedRegisterDiscountIsCombinable(ctx.getValue());
        },
      }),

      // The formatted dates are not sortable values as they contain the day of
      // the week first.
      helper.accessor('startAt', {
        header: 'Start',
        enableSorting: false,
        cell(ctx) {
          return getFormattedRegisterDiscountDate(ctx.getValue(), shopTimezone);
        },
      }),

      helper.accessor('endAt', {
        id: 'endAt',
        header: 'End',
        enableSorting: false,
        cell(ctx) {
          return getFormattedRegisterDiscountDate(ctx.getValue(), shopTimezone);
        },
      }),

      helper.display({
        id: 'actions',
        enableSorting: false,
        header() {
          return <VisuallyHidden>Actions</VisuallyHidden>;
        },
        cell(ctx) {
          return (
            <>
              <TableAction
                icon="pencil"
                title={`Edit discount ${ctx.row.original.name}`}
                to={paths.registerDiscount(shopId, ctx.row.original.id)}
              />
              <TableAction
                icon="trashCan"
                title={`Delete discount ${ctx.row.original.name}`}
                onClick={() =>
                  setDiscountToDelete(
                    discounts?.find(
                      (discount) => discount.id === ctx.row.original.id,
                    ),
                  )
                }
              />
            </>
          );
        },
        meta: {
          className: styles.editColumn,
          isActionColumn: true,
        },
      }),
    ];
  }, [discounts, shopId, shopTimezone]);

  const table = useTable({
    columns,
    data: discounts ?? [],
    onSortingChange: setSorting,
    state: {
      sorting,
    },
    chameleonTableTitle: 'Register discounts',
  });

  return (
    <>
      <Modal
        header={`Are you sure you want to delete ${discountToDelete?.name}?`}
        isOpen={discountToDelete != null}
        onClickNo={handleModalClose}
        onClickYes={handleModalYes}
        onRequestClose={handleModalClose}
        yesButtonText="Delete"
      >
        You are about to delete your Discount, this action cannot be undone.
      </Modal>
      <Header
        title="Create Your Register Discounts"
        content={[
          `Discounts are a great way to increase orders with new customers and loyal regulars. Create and manage discounts for your Register from here.`,
        ]}
        containerClass={styles.header}
      />
      <ContentTile
        className={styles.container}
        title="Register Discounts"
        headerContent={
          <Link
            appearance="button"
            data-chameleon-target="Add New Register Discount Button"
            href={paths.registerDiscount(shopId, 'new')}
            variant="secondary"
          >
            Create
          </Link>
        }
      >
        <Table className={styles.table} table={table} />
      </ContentTile>
    </>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default RegisterDiscounts;
