import Modal from 'components/shared/slice-modal';
import useCustomerCountsQuery from 'hooks/customers/use-customer-counts-query';
import useCustomersAnalyticsQuery from 'hooks/customers/use-customers-analytics-query';
import {
  CustomerList,
  CustomerType,
  DateRange,
  RegistrationType,
} from 'types/customers';
import { customerTypeLabels } from 'utilities/customers';

import AnalyticsTile from '../../analytics-tile';

import styles from './styles.module.scss';

type Props = {
  closeModal: () => void;
  customerType: CustomerType;
  dateRange: DateRange;
  guestCount: number;
  isOpen: boolean;
  shopId: string;
  timezone: string;
};

const GuestCustomersModal = ({
  closeModal,
  customerType,
  dateRange,
  guestCount,
  isOpen,
  shopId,
  timezone,
}: Props) => {
  const headerText = `${guestCount} Guest ${customerTypeLabels[customerType]} ${
    guestCount === 1 ? 'Customer' : 'Customers'
  }`;

  const { data: customersAnalytics, isLoading: isCustomersAnalyticsLoading } =
    useCustomersAnalyticsQuery({
      customerList: CustomerList.All,
      customerType,
      dateRange,
      registrationType: RegistrationType.Guest,
      shopId,
      timezone,
      options: {
        enabled: isOpen,
      },
    });

  const {
    data: guestRegisterCustomerCounts,
    isLoading: isGuestRegisterCustomerCountsLoading,
  } = useCustomerCountsQuery({
    customerType: CustomerType.Register,
    dateRange,
    registrationType: RegistrationType.Guest,
    shopId,
    timezone,
    options: {
      enabled: isOpen,
    },
  });

  const {
    data: guestOnlineCustomerCounts,
    isLoading: isGuestOnlineCustomerCountsLoading,
  } = useCustomerCountsQuery({
    customerType: CustomerType.Online,
    dateRange,
    registrationType: RegistrationType.Guest,
    shopId,
    timezone,
    options: {
      enabled: isOpen,
    },
  });

  return (
    <Modal
      fadeClassName={styles.modalFade}
      header={<div className={styles.modalHeader}>{headerText}</div>}
      headerWrapperClassName={styles.modalHeaderWrapper}
      isOpen={isOpen}
      onRequestClose={closeModal}
      contentClassName={styles.modal}
    >
      <div className={styles.analyticsContainer}>
        <AnalyticsTile
          className={styles.metricTile}
          contentClassName={styles.metricTileContent}
          headerClassName={styles.metricTileHeader}
          isCurrency={false}
          isLoading={isCustomersAnalyticsLoading}
          title="Total Order Count"
          titleClassName={styles.metricTileTitle}
          value={customersAnalytics?.orderCount ?? 0}
        />
        <AnalyticsTile
          className={styles.metricTile}
          contentClassName={styles.metricTileContent}
          headerClassName={styles.metricTileHeader}
          isCurrency={true}
          isLoading={isCustomersAnalyticsLoading}
          title="Average Order Value"
          titleClassName={styles.metricTileTitle}
          value={customersAnalytics?.averageOrderValue ?? 0}
        />
        <AnalyticsTile
          className={styles.metricTile}
          contentClassName={styles.metricTileContent}
          headerClassName={styles.metricTileHeader}
          isCurrency={true}
          isLoading={isCustomersAnalyticsLoading}
          title="Total Spend"
          titleClassName={styles.metricTileTitle}
          value={customersAnalytics?.orderTotal ?? 0}
        />
      </div>
      <div className={styles.customerInfoContainer}>
        {(customerType === CustomerType.All ||
          customerType === CustomerType.Register) && (
          <div className={styles.customerInfo}>
            <div className={styles.customerCountInfo}>
              Guest Register Customer:{' '}
              {isGuestRegisterCustomerCountsLoading
                ? '--'
                : (guestRegisterCustomerCounts?.all ?? 0)}
            </div>
            <div>
              Adding walk-in and phone customer data via Register drives repeat
              business. You’ll be able to recognize customers quickly, pull up
              past orders, and improve overall satisfaction.
            </div>
          </div>
        )}
        {(customerType === CustomerType.All ||
          customerType === CustomerType.Online) && (
          <div className={styles.customerInfo}>
            <div className={styles.customerCountInfo}>
              Guest Online Customer:{' '}
              {isGuestOnlineCustomerCountsLoading
                ? '--'
                : (guestOnlineCustomerCounts?.all ?? 0)}
            </div>
            <div>
              You have customer tracking for logged-in orders through Slice.
              Orders placed through third-party apps or guest checkouts cannot
              be tracked.
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default GuestCustomersModal;
