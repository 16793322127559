export enum ButtonType {
  Slice = 'slice',
  Custom = 'custom',
}

export enum SizeOption {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

export enum ShapeOption {
  Rounded = 'round',
  Square = 'sqr',
  Pill = 'pill',
}

export enum LetterCaseOption {
  Upper = 'up',
  Lower = 'lo',
}

export type AvailableColor = {
  name: string;
  urlCode: string;
  label: string;
  hex: string;
};

export enum UtmContentTypeOptions {
  Slice = 'slice_button',
  Custom = 'custom_button',
  Link = 'custom_link',
}

export enum SliceBrandingTabLabels {
  CreateButton = 'create an order button',
  GetLink = 'get an ordering link',
}

export type ButtonConfigTouchedStatus = {
  shopId: string;
  type: string;
  color: boolean;
  size: boolean;
  shape: boolean;
  letterCase: boolean;
};
