import Modal from 'components/shared/slice-modal';

import styles from './styles.module.scss';

type Props = {
  modalHeader: string;
  onClose: () => void;
  showModal: boolean;
  videoSrc: string;
  videoTitle: string;
};

const VideoTutorialModal = ({
  modalHeader,
  onClose,
  showModal,
  videoSrc,
  videoTitle,
}: Props) => {
  return (
    <Modal header={modalHeader} isOpen={showModal} onRequestClose={onClose}>
      <div className={styles.videoContainer}>
        <iframe
          className={styles.videoFrame}
          title={videoTitle}
          src={videoSrc}
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
        />
      </div>
    </Modal>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default VideoTutorialModal;
