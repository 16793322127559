import { Link } from 'crust';

import { getFinancialsOrdersPath } from 'routes/paths/financials';

import styles from './styles.module.scss';

type Props = {
  shopId: string;
};

const ViewAllLink = ({ shopId }: Props) => {
  return (
    <div className={styles.viewAllLinkWrapper}>
      <Link
        href={getFinancialsOrdersPath(shopId, 'online')}
        icon="chevronRight"
        iconPosition="end"
        variant="secondary"
      >
        View All
      </Link>
    </div>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default ViewAllLink;
