import styles from './styles.module.scss';

const CampaignManagerHeader = () => {
  return (
    <div className={styles.header}>
      <div className={styles.title}>Targeted Digital Advertising</div>
      <div className={styles.description}>
        {"Maximize your shop's online visibility and boost your customer base."}
      </div>
    </div>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default CampaignManagerHeader;
