import { ComponentPropsWithoutRef } from 'react';

import styles from './styles.module.scss';

export const VisuallyHiddenLabel = (
  props: Omit<ComponentPropsWithoutRef<'label'>, 'className'>,
  // eslint-disable-next-line jsx-a11y/label-has-associated-control
) => <label className={styles.visuallyHidden} {...props} />;

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default VisuallyHiddenLabel;
