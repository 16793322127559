import { useMutation, useQueryClient } from '@tanstack/react-query';
import { z } from 'zod';

import useApi from 'hooks/use-api';
import { Shop } from 'types/shops/shop';
import { camelCaseKeys } from 'utilities/objects';

import { businessProfileSchema } from './schemas';
import { getQueryKey as getBusinessProfileQueryKey } from './use-business-profile-query';
import { useHandleGoogleReauthError } from './use-handle-google-reauth-error';

const schema = z.object({
  businessProfile: businessProfileSchema,
});

export type ResponseGoogleBusinessProfile = z.infer<typeof schema>;

export const useCreateBusinessProfileMutation = () => {
  const queryClient = useQueryClient();
  const { authenticatedFetch } = useApi();
  const handleGoogleReauthError = useHandleGoogleReauthError();

  return useMutation({
    mutationFn: async ({
      shopId,
      googleLocationId,
    }: {
      shopId: Shop['shopId'];
      googleLocationId: string;
    }) => {
      try {
        const response = await authenticatedFetch.post(
          `api/management/v1/shops/${shopId}/business_profile`,
          {
            business_profile: { google_location_id: googleLocationId },
          },
        );
        return schema.parse(camelCaseKeys(response));
      } catch (error) {
        if (error instanceof z.ZodError) {
          console.error(
            'Error parsing Create Business Profile response',
            error,
          );
        }

        handleGoogleReauthError(shopId, error);

        throw error;
      }
    },
    onSuccess(data, variables) {
      queryClient.setQueryData(
        getBusinessProfileQueryKey(variables.shopId),
        data,
      );
    },
  });
};
