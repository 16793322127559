import { ComponentPropsWithoutRef } from 'react';
import cx from 'classnames';

import Spinner from 'components/shared/spinner';

import styles from './styles.module.scss';

type Props = ComponentPropsWithoutRef<'div'>;

const Loading = ({ children, className }: Props) => {
  return (
    <div className={cx(styles.loading, className)}>
      <Spinner />
      {children}
    </div>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default Loading;
