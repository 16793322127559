import { ReactElement } from 'react';

import { LinkContext } from 'crust';

import styles from './styles.module.scss';

type Props = {
  content: string;
  header: string;
  image: string;
  link?: ReactElement;
};

export const Card = ({ content, header, image, link }: Props) => {
  return (
    <div className={styles.card}>
      <img alt={header} className={styles.img} src={image} />
      <div className={styles.header}>{header}</div>
      <div className={styles.content}>{content}</div>
      {link ? (
        <LinkContext.Provider
          value={{
            'aria-label': `Create ${header}`,
            className: styles.link,
            size: 'small',
            variant: 'primary',
          }}
        >
          {link}
        </LinkContext.Provider>
      ) : null}
    </div>
  );
};
