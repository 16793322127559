import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';

import FormFeedback from 'components/shared/form-feedback';
import Label from 'components/shared/label';
import NumberInput from 'components/shared/number-input';

const PrintQuantityInput = ({ control, error, inputIdPrefix }) => (
  <>
    <Label htmlFor={`${inputIdPrefix}-number-of-copies`}>Quantity</Label>
    <Controller
      control={control}
      name="numberOfCopies"
      rules={{
        min: {
          value: 1,
          message: 'Must be at least 1.',
        },
        max: {
          value: 10,
          message: 'Cannot be greater than 10.',
        },
        required: 'Please enter a number.',
      }}
      render={({ field, fieldState }) => (
        <NumberInput
          aria-required="true"
          decimalScale={0}
          id={`${inputIdPrefix}-number-of-copies`}
          isInvalid={fieldState.error != null}
          name={field.name}
          onBlur={field.onBlur}
          onValueChange={(values) => field.onChange(values.floatValue)}
          placeholder="e.g. 1"
          ref={field.ref}
          value={field.value}
        />
      )}
    />
    <FormFeedback>{error?.message}</FormFeedback>
  </>
);

PrintQuantityInput.propTypes = {
  control: PropTypes.object.isRequired,
  error: PropTypes.object,
  inputIdPrefix: PropTypes.string.isRequired,
};

PrintQuantityInput.defaultProps = {
  error: null,
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default PrintQuantityInput;
