import { useMutation } from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { camelCaseKeys } from 'utilities/objects';

type Values = {
  password: string;
  passwordResetToken: string;
};

export const usePasswordResetMutation = () => {
  const { authenticatedFetch } = useApi();

  return useMutation({
    mutationFn: async ({ password, passwordResetToken }: Values) => {
      const response = await authenticatedFetch.put(
        `api/management/v1/password_resets`,
        {
          password,
          password_reset_token: passwordResetToken,
        },
        {},
        true,
        false,
        true,
      );
      return camelCaseKeys(response);
    },
  });
};
