import * as React from "react";
import { forwardRef } from "react";
const SvgClock = ({
  title,
  titleId,
  ...props
}, ref) => {
  return /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 22 22", ref, "aria-labelledby": titleId, ...props }, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { fill: "none", stroke: "currentColor", strokeWidth: 2, d: "M11,1C5.48,1,1,5.48,1,11s4.48,10,10,10,10-4.48,10-10S16.52,1,11,1Z" }), /* @__PURE__ */ React.createElement("polygon", { fill: "currentColor", points: "13.78 16.15 9.78 12.15 9.49 11.85 9.49 11.44 9.49 4.44 11.49 4.44 11.49 11.03 15.19 14.73 13.78 16.15" }));
};
const ForwardRef = forwardRef(SvgClock);
export default ForwardRef;
