import { AvailabilitySummary, WeeklySummary } from 'types/hours-analytics';
import { nowDateTime } from 'utilities/date-time';

const addWeek = (timezone: string, weeksAgo: number): WeeklySummary => {
  const daysAgoToWeekEnd = 7 * weeksAgo;
  const daysAgoToWeekStart = daysAgoToWeekEnd + 6;

  const weekStart = nowDateTime(timezone).subtract(daysAgoToWeekStart, 'days');
  const weekEnd = nowDateTime(timezone).subtract(daysAgoToWeekEnd, 'days');

  return {
    weekEnd: weekEnd.format('yyyy-MM-DD'),
    weekStart: weekStart.format('yyyy-MM-DD'),
    shopAvailability: null,
    subregionShopAvailability: null,
  };
};

export const getBlankDates = (timezone: string): WeeklySummary[] => {
  const numWeeks = 9;
  const blankDates: WeeklySummary[] = [];

  for (let i = numWeeks; i >= 0; i--) {
    const week = addWeek(timezone, i);
    blankDates.push(week);
  }

  return blankDates;
};

export const getBlankSummaryData = (timezone: string): AvailabilitySummary => {
  return {
    dataAsOfText: nowDateTime(timezone).format('MMM D'),
    last30Days: {
      availabilityScore: null,
      availabilityScoreRange: null,
      shopAvailability: null,
      unavailabilityTotals: {
        delivery: null,
        pickup: null,
      },
      unavailabilityTotalsGtSubregion: {
        delivery: null,
        pickup: null,
      },
    },
    weeklySummary: [],
  };
};
