import ContentTile from 'components/shared/content-tile';
import { Feedback } from 'components/shared/feedback';
import Header from 'components/shared/header';
import { AvailabilitySummary } from 'types/hours-analytics';
import { Shop } from 'types/shops';

import Last30DaysMetricTiles from './last-30-days-metric-tiles';
import { ShopAvailabilityTile } from './shop-availability';
import { StatusHistoryTile } from './status-history-tile';

import styles from './styles.module.scss';

type Props = {
  shop: Shop;
  summaryData: AvailabilitySummary;
};

export const HoursAnalytics = ({ shop, summaryData }: Props) => {
  return (
    <>
      <Header
        title="Track Your Shop Availability"
        containerClass={styles.headerContainer}
        content={[
          "Get insights into your shop's availability and optimize to stay ahead of your competition.",
        ]}
      />
      <ContentTile
        title="Last 30 Days"
        headerClassName={styles.headerClassName}
        dataTestId="last-30-days-tile"
        headerContent={
          <Feedback
            contentDescription="last 30 days information"
            page="hours analytics"
            shopId={shop.shopId}
            storageKey="hours_analytics_feedback_last_30_days"
            tile="last 30 days"
          />
        }
      >
        <Last30DaysMetricTiles data={summaryData.last30Days} />
      </ContentTile>
      <ShopAvailabilityTile
        data={summaryData.weeklySummary}
        dataAsOfText={summaryData.dataAsOfText}
        shopId={shop.shopId}
        timezone={shop.timezoneIdentifier}
      />
      <StatusHistoryTile
        shopId={shop.shopId}
        timezone={shop.timezoneIdentifier}
      />
    </>
  );
};
