import { useState } from 'react';
import { useFieldArray } from 'react-hook-form';
import PropTypes from 'prop-types';

import CollapsibleTile from 'components/shared/collapsible-tile';
import FormText from 'components/shared/form-text';
import { RHFCheckbox } from 'components/shared/rhf-checkbox';
import Suspended from 'components/shared/suspended';
import Text from 'components/shared/text';
import {
  AnalyticsField,
  getPrinterSettingFieldName,
  PrinterSettingField,
  ProductField,
} from 'utilities/menu';

import styles from './styles.module.scss';

const RegisterPrintingTile = ({ control, isLoading, register, setValue }) => {
  const [isOpen, setIsOpen] = useState(true);

  const { fields } = useFieldArray({
    control,
    keyName: 'key', // The printer settings already have an id.
    name: ProductField.PrinterSettings,
  });

  return (
    <CollapsibleTile
      bodyChameleonTarget="Product Printer Settings"
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title="Register Printing"
    >
      <Suspended isLoading={isLoading} variant="short">
        <FormText>
          Select where you would like this menu item to be printed.
        </FormText>
        {fields.map((field, index) => {
          return (
            <div key={field.key}>
              <RHFCheckbox
                control={control}
                name={getPrinterSettingFieldName(
                  PrinterSettingField.IsEnabled,
                  index,
                )}
                className={styles.checkbox}
                label={<Text clamp>{field[PrinterSettingField.Name]}</Text>}
                rules={{
                  onChange: () =>
                    setValue(AnalyticsField.PrintingChanged, true),
                }}
              />
              <input
                defaultValue={field[PrinterSettingField.Name]}
                type="hidden"
                {...register(
                  getPrinterSettingFieldName(PrinterSettingField.Name, index),
                )}
              />
              <input
                defaultValue={field[PrinterSettingField.Id]}
                type="hidden"
                {...register(
                  getPrinterSettingFieldName(PrinterSettingField.Id, index),
                )}
              />
            </div>
          );
        })}
        <input type="hidden" {...register(AnalyticsField.PrintingChanged)} />
      </Suspended>
    </CollapsibleTile>
  );
};

RegisterPrintingTile.propTypes = {
  control: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  register: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
};

RegisterPrintingTile.defaultProps = {
  isLoading: false,
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default RegisterPrintingTile;
