import { Icon } from 'crust';

import Scribble from 'images/register/register-splash/scribble.svg?react';
import { Shop } from 'types/shops';

import { ScheduleDemoButton } from '../schedule-demo-button';

import styles from './styles.module.scss';

type Props = {
  hasValidCase: boolean;
  onScheduleDemoButtonClick: () => void;
  shopId: Shop['shopId'];
};

export const RegisterSplashHeader = ({
  hasValidCase,
  onScheduleDemoButtonClick,
  shopId,
}: Props) => {
  return (
    <div className={styles.container}>
      <h1 className={styles.heading}>Slice Register</h1>
      <h2 className={styles.subheading}>
        THE EASY POS.
        <Scribble aria-hidden="true" className={styles.underline} />
      </h2>
      <p className={styles.description}>
        Slice Register is the easy-to-install, simple-to-use POS that transforms
        your business and makes your customers more valuable.
      </p>
      {hasValidCase ? (
        <div className={styles.messageBanner}>
          <Icon icon="verification" size="large" className={styles.icon} />
          {`Thanks for requesting a Register demo! We'll be in touch soon.`}
        </div>
      ) : (
        <ScheduleDemoButton
          onScheduleDemoButtonClick={onScheduleDemoButtonClick}
          shopId={shopId}
        />
      )}
    </div>
  );
};
