import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Link } from 'crust';

import ContentTile from 'components/shared/content-tile';
import useCustomerCountsQuery from 'hooks/customers/use-customer-counts-query';
import useAnalytics from 'hooks/use-analytics';
import LoyalCustomerIcon from 'images/loyal-customer-icon.svg?react';
import NewCustomerIcon from 'images/new-customer-icon.svg?react';
import RepeatCustomerIcon from 'images/repeat-customer-icon.svg?react';
import * as paths from 'routes/paths';
import {
  CustomerList,
  CustomerType,
  DateRange,
  RegistrationType,
} from 'types/customers';
import {
  CustomerListLabels,
  customerTypeLabels,
  dateRangeLabels,
} from 'utilities/customers';

import CustomerTypeInfo from './customer-type-info';

import styles from './styles.module.scss';

type Props = {
  customerType: CustomerType;
  dateRange: DateRange;
  shopId: string;
  timezone: string;
};

const CustomerTypeTile = ({
  customerType,
  dateRange,
  shopId,
  timezone,
}: Props) => {
  const { trackUpdatedCustomerAnalyticsLandingPage, trackClickedViewList } =
    useAnalytics();
  const navigate = useNavigate();

  const { data: customerCounts, isLoading } = useCustomerCountsQuery({
    customerType,
    dateRange,
    registrationType: RegistrationType.Registered,
    shopId,
    timezone,
  });

  useEffect(() => {
    if (!isLoading) {
      trackUpdatedCustomerAnalyticsLandingPage(
        shopId,
        customerTypeLabels[customerType],
        dateRangeLabels[dateRange],
        customerCounts?.all ?? 0,
        customerCounts?.new ?? 0,
        customerCounts?.repeat ?? 0,
        customerCounts?.loyal ?? 0,
      );
    }
    // We want to fire the event when the request to update the data is returned.
    // We want customerType and dateRange to cause the event to fire again,
    // otherwise the event would only fire if there was new data (i.e., it would
    // not fire if there were cached data).
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, customerType, dateRange]);

  const handleClickAllCustomersLink = (): void => {
    navigate(
      {
        pathname: paths.getCustomerSegmentsPath(shopId),
      },
      {
        state: {
          customerList: CustomerList.All,
        },
      },
    );
    trackClickedViewList(
      shopId,
      CustomerListLabels[CustomerList.All].toLowerCase(),
      customerCounts?.all ?? 0,
    );
  };

  return (
    <ContentTile
      className={styles.customerTypesTile}
      headerClassName={styles.tileHeader}
      title="Customer Segment"
    >
      <div data-testid="customer-dashboard-customer-type-info">
        <CustomerTypeInfo
          customerList={CustomerList.New}
          isLoading={isLoading}
          count={customerCounts?.new}
          icon={<NewCustomerIcon className={styles.customerTypeIcon} />}
          name="New Customers"
          shopId={shopId}
          tooltipContent={
            <p className={styles.tooltipText}>New - First-time order</p>
          }
        />
        <CustomerTypeInfo
          customerList={CustomerList.Repeat}
          isLoading={isLoading}
          count={customerCounts?.repeat}
          icon={<RepeatCustomerIcon className={styles.customerTypeIcon} />}
          name="Repeat Customers"
          shopId={shopId}
          tooltipContent={
            <p className={styles.tooltipText}>
              Repeat – Ordered{' '}
              <span className={styles.emphasis}>less than</span> 3 times/3
              months.
            </p>
          }
        />
        <CustomerTypeInfo
          customerList={CustomerList.Loyal}
          isLoading={isLoading}
          count={customerCounts?.loyal}
          icon={<LoyalCustomerIcon className={styles.customerTypeIcon} />}
          name="Loyal Customers"
          shopId={shopId}
          tooltipContent={
            <p className={styles.tooltipText}>
              Loyal - Ordered <span className={styles.emphasis}>more than</span>{' '}
              3 times/3 months.
            </p>
          }
        />
      </div>
      <Link
        className={styles.viewAllLink}
        appearance="button"
        variant="secondary"
        onPress={handleClickAllCustomersLink}
      >
        View All Segments
      </Link>
    </ContentTile>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default CustomerTypeTile;
