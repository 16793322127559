import moment from 'moment-timezone';

import { SingleRegisterOrder } from 'types/register-orders';

import styles from './styles.module.scss';

type Props = {
  order: SingleRegisterOrder;
  timezone: string;
};

const AdditionalOrderDetails = ({ order, timezone }: Props) => {
  const orderType = order.type
    .replaceAll('_', ' ')
    .replace(/^\w|\s\w/g, (char) => char.toUpperCase());

  const convertOrderStatusToLabel = (status: string) => {
    switch (status) {
      case 'ORDER_AWAITING_PAYMENT':
        return 'Awaiting Payment';
      case 'ORDER_CLEARED':
        return 'Cleared';
      case 'ORDER_HELD':
        return 'Held';
      case 'ORDER_PARTIALLY_PAID':
        return 'Partially Paid';
      case 'ORDER_PAID':
        return 'Paid';
      case 'ORDER_PARTIALLY_REFUNDED':
        return 'Partially Refunded';
      case 'ORDER_REFUNDED':
        return 'Refunded';
      case 'ORDER_VOID':
        return 'Void';
      case 'ORDER_CANCELLED':
        return 'Cancelled';
      default:
        return 'Unknown';
    }
  };

  return (
    <div>
      <div className={styles.orderDetail}>
        <div className={styles.orderDetailTitle}>Order Placed:</div>
        <div>
          {moment.tz(order.createdAt, timezone).format('ddd MM/DD/YY h:mmA z')}
        </div>
      </div>
      <div className={styles.orderDetail}>
        <div className={styles.orderDetailTitle}>Order Notes:</div>
        <div>{order.note || 'None Entered'}</div>
      </div>
      <div className={styles.orderDetail}>
        <div className={styles.orderDetailTitle}>
          This is a {orderType} order:
        </div>
        <div>ASAP</div>
      </div>
      <div className={styles.orderDetail}>
        <div className={styles.orderDetailTitle}>Status:</div>
        <div>{convertOrderStatusToLabel(order.orderStatus)}</div>
      </div>
    </div>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default AdditionalOrderDetails;
