import { Icon } from 'crust';

import ContentTile from 'components/shared/content-tile';

import styles from './styles.module.scss';

type Props = {
  categoryCount?: number;
  isLoading?: boolean;
  productCount?: number;
};

const MenuInfoTile = ({
  categoryCount = 0,
  isLoading = false,
  productCount = 0,
}: Props) => (
  <ContentTile className={styles.tile} isLoading={isLoading}>
    <div className={styles.iconWrapper}>
      <Icon icon="menu" size="large" />
    </div>
    <div className={styles.contentWrapper}>
      <div>
        <div className={styles.listTitle}>My Menu</div>
        <ul className={styles.list}>
          <li className={styles.listItem}>
            Categories:{' '}
            <span className={styles.itemCount}>{categoryCount}</span>
          </li>
          <li className={styles.listItem}>
            Items: <span className={styles.itemCount}>{productCount}</span>
          </li>
        </ul>
      </div>
    </div>
  </ContentTile>
);

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default MenuInfoTile;
