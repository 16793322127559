import cx from 'classnames';
import PropTypes from 'prop-types';

import Suspended from 'components/shared/suspended';
import Tile from 'components/shared/tile/tile';

import styles from './styles.module.scss';

const ContentTile = ({
  children,
  className,
  dataTestId,
  headerContent,
  headerClassName,
  isLoading,
  suppressTitle,
  title,
  onClick,
  onKeyDown,
  role,
  tabIndex,
}) => {
  return (
    <Tile
      className={cx(styles.container, className)}
      {...(dataTestId ? { 'data-testid': dataTestId } : {})}
      onClick={onClick}
      onKeyDown={onKeyDown}
      role={role}
      tabIndex={tabIndex}
    >
      {(title || headerContent) && (
        <div className={cx(styles.header, headerClassName)}>
          {suppressTitle || <div className={styles.title}>{title}</div>}
          {headerContent}
        </div>
      )}
      <Suspended isLoading={isLoading} variant="short">
        {children}
      </Suspended>
    </Tile>
  );
};

ContentTile.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  headerContent: PropTypes.node,
  headerClassName: PropTypes.string,
  isLoading: PropTypes.bool,
  link: PropTypes.string,
  PlaceholderIcon: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  placeholderMessage: PropTypes.string,
  suppressTitle: PropTypes.bool,
  title: PropTypes.node,
  onClick: PropTypes.func,
  onKeyDown: PropTypes.func,
  role: PropTypes.string,
  tabIndex: PropTypes.number,
};

ContentTile.defaultProps = {
  className: null,
  dataTestId: null,
  headerContent: null,
  headerClassName: null,
  isLoading: false,
  link: null,
  PlaceholderIcon: null,
  placeholderMessage: null,
  suppressTitle: false,
  title: null,
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default ContentTile;
