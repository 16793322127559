import { PropsWithChildren, useEffect } from 'react';
import { BrowserRouter, useNavigate } from 'react-router-dom';
import { Slide, ToastContainer } from 'react-toastify';
import { useAuth0 } from '@auth0/auth0-react';
import { datadogRum } from '@datadog/browser-rum';
import { Elements as StripeProvider } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { I18nProvider, RouterProvider } from 'crust';

import Suspended from 'components/shared/suspended';
import { useCurrentUserQuery } from 'hooks/shared';
import { useShopsQuery } from 'hooks/shops';
import useAnalytics from 'hooks/use-analytics';
import AnalyticsProvider from 'providers/analytics';
import ApiProvider from 'providers/api';
import AuthenticationProvider from 'providers/authentication';
import FeatureFlagsProvider from 'providers/feature-flags';
import { AppRoutes } from 'routes';
import { AnalyticsAdapter } from 'types/shared/analytics';

type AppHarnessProps = PropsWithChildren<{
  analyticsAdapter: AnalyticsAdapter;
  apiHostname: string;
  auth0Audience: string;
  auth0ClientId: string;
  auth0Domain: string;
  queryClient: QueryClient;
  stripeKey: string;
}>;

// At some point the ComponentTestHarness could probably just render an
// AppHarness with the right props for the testing environment. The two
// components have mostly the same JSX.
export const AppHarness = ({
  analyticsAdapter,
  apiHostname,
  auth0Audience,
  auth0ClientId,
  auth0Domain,
  children,
  queryClient,
  stripeKey,
}: AppHarnessProps) => (
  <BrowserRouter>
    <AnalyticsProvider adapter={analyticsAdapter}>
      <AuthenticationProvider
        domain={auth0Domain}
        clientId={auth0ClientId}
        audience={auth0Audience}
      >
        <ApiProvider hostname={apiHostname}>
          <QueryClientProvider client={queryClient}>
            <StripeProvider stripe={loadStripe(stripeKey)}>
              <FeatureFlagsProvider>
                {children}
                <ToastContainer
                  autoClose={5000}
                  closeButton={false}
                  draggable={false}
                  hideProgressBar
                  position="bottom-center"
                  theme="colored"
                  transition={Slide}
                />
              </FeatureFlagsProvider>
            </StripeProvider>
            <ReactQueryDevtools />
          </QueryClientProvider>
        </ApiProvider>
      </AuthenticationProvider>
    </AnalyticsProvider>
  </BrowserRouter>
);

export const App = () => {
  const navigate = useNavigate();
  const { identifyUser } = useAnalytics();
  const { error, isAuthenticated, isLoading } = useAuth0();

  const { data: user } = useCurrentUserQuery({ enabled: isAuthenticated });

  const { data: shops } = useShopsQuery({
    enabled: isAuthenticated,
  });

  useEffect(() => {
    if (user == null || shops == null) {
      return;
    }

    identifyUser(user.email, {
      createdDate: user.createdAt,
      associatedShops: shops,
    });
  }, [identifyUser, user, shops]);

  useEffect(() => {
    // An error has occured in Auth0 so log the error and log the user out.
    if (error) {
      datadogRum.addError(error);
      navigate('/logout');
    }
  }, [error, navigate]);

  if (isLoading) {
    return <Suspended isLoading variant={'viewPort'} />;
  }

  if (error) {
    return null;
  }

  return (
    // Until we officially support localization across the entire app, we'll
    // force all localization from Crust code to use en-US.
    <I18nProvider locale="en-US">
      {/* While RouterProvider accepts ReactRouter useHref, it currently breaks
      external links.
      https://github.com/adobe/react-spectrum/issues/6397 */}
      <RouterProvider openExternalLinksInNewTab navigate={navigate}>
        <AppRoutes />
      </RouterProvider>
    </I18nProvider>
  );
};
