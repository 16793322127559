import { Shop } from 'types/shops';
import {
  BannerPosition,
  ButtonLayout,
  ButtonPosition,
  EmailTemplate,
  PromoMessageFormValue,
  SaveSmartButtonsAnalyticsParams,
  SaveSmartButtonsRequestAnalytics,
  SmartButtonConfigOption,
  SmartButtonFormAnalyticsParams,
  SmartButtonFormAnalyticsValues,
  SmartButtonFormValues,
  Theme,
  ThemeVariant,
  WebsiteIntegrationColors,
  WebsiteIntegrationsSettings,
  WidgetType,
} from 'types/website-tools';
import { snakeCaseKeys } from 'utilities/objects';
import { capitalize } from 'utilities/strings';

const { MODE } = import.meta.env;

export const smartButtonsDefaultOptions: SmartButtonFormValues = {
  format: WidgetType.Banner,
  position: BannerPosition.Top,
  theme: Theme.Simple,
  color: 'black',
  mode: ThemeVariant.Light,
  promoMessage: 'true',
};

const defaultBannerPositionOption = {
  label: 'Top',
  value: BannerPosition.Top,
} as const;

const defaultButtonPositionOption = {
  label: 'Bottom Center',
  value: ButtonPosition.BottomCenter,
};

export const defaultColorOption = {
  label: 'Black',
  value: 'black',
};

export const buttonPostionOptions = [
  defaultButtonPositionOption,
  {
    label: 'Bottom Right',
    value: ButtonPosition.BottomRight,
  },
  {
    label: 'Bottom Left',
    value: ButtonPosition.BottomLeft,
  },
] as const;

export const bannerPostionOptions = [
  defaultBannerPositionOption,
  {
    label: 'Bottom',
    value: BannerPosition.Bottom,
  },
] as const;

export const themeOptions: SmartButtonConfigOption[] = [
  {
    label: 'Simple',
    value: Theme.Simple,
  },
  {
    label: 'Iconic',
    value: Theme.Iconic,
  },
  {
    label: 'Elegant',
    value: Theme.Elegant,
  },
];

export const modeOptions = [
  {
    label: 'Light',
    value: ThemeVariant.Light,
  },
  {
    label: 'Dark',
    value: ThemeVariant.Dark,
  },
] as const;

export const defaultPromoMessageOption = {
  label: 'On',
  value: 'true',
} as const;

export const promoMessageOptions = [
  defaultPromoMessageOption,
  {
    label: 'Off',
    value: 'false',
  },
] as const;

export const formatOptionLabels = {
  [WidgetType.Banner]: 'Banner',
  [WidgetType.Button]: 'Button',
  [WidgetType.Sticker]: 'Sticker',
} as const;

export const themeOptionLabels = {
  [Theme.Simple]: 'Simple',
  [Theme.Iconic]: 'Iconic',
  [Theme.Elegant]: 'Elegant',
  [Theme.Slice]: 'Slice',
} as const;

export const getSmartButtonsDefaultFormValuesFromSettings = (
  smartButtonSettings: WebsiteIntegrationsSettings,

  isPromoMessageAvailable: boolean,
): SmartButtonFormValues => {
  if (
    !smartButtonSettings.floatingButtonEnabled &&
    !smartButtonSettings.navBarEnabled
  ) {
    return {
      ...smartButtonsDefaultOptions,

      promoMessage: isPromoMessageAvailable ? 'true' : '',
    };
  }

  if (smartButtonSettings.floatingButtonEnabled) {
    return {
      format:
        smartButtonSettings.floatingButtonLayout === ButtonLayout.Button
          ? WidgetType.Button
          : WidgetType.Sticker,

      position:
        smartButtonSettings.floatingButtonPosition ||
        ButtonPosition.BottomRight,

      theme:
        smartButtonSettings.floatingButtonTheme ||
        smartButtonsDefaultOptions.theme,
      color:
        smartButtonSettings.floatingButtonThemeColor ||
        smartButtonsDefaultOptions.color,
      mode:
        smartButtonSettings.floatingButtonThemeVariant ||
        smartButtonsDefaultOptions.mode,
      promoMessage:
        smartButtonSettings.floatingButtonValueMessage ??
        smartButtonsDefaultOptions.promoMessage,
    };
  }

  if (smartButtonSettings.navBarEnabled) {
    return {
      format: WidgetType.Banner,

      position: smartButtonSettings.navBarPosition || BannerPosition.Top,

      theme:
        smartButtonSettings.navBarTheme || smartButtonsDefaultOptions.theme,
      color:
        smartButtonSettings.navBarThemeColor ||
        smartButtonsDefaultOptions.color,
      mode:
        smartButtonSettings.navBarThemeVariant ||
        smartButtonsDefaultOptions.mode,
      promoMessage:
        smartButtonSettings.navBarValueMessage ??
        smartButtonsDefaultOptions.promoMessage,
    };
  }

  return {
    ...smartButtonsDefaultOptions,

    promoMessage: isPromoMessageAvailable ? 'true' : '',
  };
};

export const getPositionOptions = (selectedWidget: WidgetType) => {
  if (selectedWidget === WidgetType.Banner) {
    return bannerPostionOptions;
  }

  return buttonPostionOptions;
};

export const getDefaultPostionOptionForWidget = (
  widget: WidgetType,
): SmartButtonConfigOption => {
  if (widget === WidgetType.Banner) {
    return defaultBannerPositionOption;
  }

  return defaultButtonPositionOption;
};

export const convertSmartButtonFormValuesToSettings = (
  formValues: SmartButtonFormValues,
): Partial<WebsiteIntegrationsSettings> => {
  if (formValues.format === WidgetType.Banner) {
    return {
      floatingButtonEnabled: false,
      navBarEnabled: true,
      navBarPosition: formValues.position as BannerPosition,
      navBarTheme: formValues.theme,
      navBarThemeColor: formValues.color,
      navBarThemeVariant: formValues.mode,
      navBarValueMessage: formValues.promoMessage
        ? formValues.promoMessage
        : null,
    };
  } else {
    return {
      floatingButtonEnabled: true,
      navBarEnabled: false,
      floatingButtonLayout:
        formValues.format === WidgetType.Button
          ? ButtonLayout.Button
          : ButtonLayout.Sticker,
      floatingButtonPosition: formValues.position as ButtonPosition,
      floatingButtonTheme: formValues.theme,
      floatingButtonThemeColor: formValues.color,
      floatingButtonThemeVariant: formValues.mode,
      floatingButtonValueMessage: formValues.promoMessage
        ? formValues.promoMessage
        : null,
    };
  }
};

/**
 * A shop URL may already have an existing smart button configured. When they set that as the preview url
 * we set the inner iframe source to that value. In order to not show both the new button being configured,
 * and the existing button a shop url may have on their site, we add the two enabled parameters set to false
 * to the shop/inner iframe url, to disable the existing button from being shown.
 */
export const addDisableExistingSmartButtonsParamsToShopUrl = (
  shopUrl: string,
): string => {
  // We consider an empty string valid to reset the preview url
  if (!shopUrl) {
    return shopUrl;
  }

  let updatedShopUrl = shopUrl;

  // Accounting for a url that may have been copied with existing query parameters already applied.
  if (updatedShopUrl.includes('?')) {
    updatedShopUrl += '&nav_bar_enabled=false&floating_button_enabled=false';
  } else {
    updatedShopUrl += '?nav_bar_enabled=false&floating_button_enabled=false';
  }

  return updatedShopUrl;
};

export const getUrlParams = (
  shopUrl: string,
  shopUuid: string,
  currentFormValues: SmartButtonFormValues,
): URLSearchParams => {
  // For the url params we need to use snake case keys and also only pass params with valid values
  // meaning non-null and non-undefined values only.
  const convertedFormValues = snakeCaseKeys(
    convertSmartButtonFormValuesToSettings(currentFormValues),
  );

  // Within the form and saving settings, we only deal with 'true' or null for on or off. This is because, although
  // false is needed for the param when we want to override a currently on smart button, sending the string of null
  // currently shows the promo message. This is a bug on the smart buttons side but we feel it will avoid future issues if we
  // try to deal with only true and null, we expect true in the settings response to always show it and
  // null in the settings response to never show it. There may be other string options that enable/disable it in the
  // future but currently all strings turn it on except the string 'false'.
  // See this smart buttons ticket - https://app.shortcut.com/slicelife/story/375357/update-the-value-message-url-parameter-to-only-be-true-if-the-value-is-set-to-true-and-not-any-string
  if (
    convertedFormValues.floating_button_enabled &&
    convertedFormValues.floating_button_value_message === null
  ) {
    convertedFormValues.floating_button_value_message = 'false';
  }

  if (
    convertedFormValues.nav_bar_enabled &&
    convertedFormValues.nav_bar_value_message === null
  ) {
    convertedFormValues.nav_bar_value_message = 'false';
  }

  const filteredFormValues = Object.fromEntries(
    Object.entries(convertedFormValues).filter(([, v]) => {
      return v !== null && v !== undefined;
    }),
  );

  const params = new URLSearchParams({
    _iframe_src: addDisableExistingSmartButtonsParamsToShopUrl(shopUrl),
    _shop_uuid: shopUuid,
    _smart_buttons_widget_src:
      MODE === 'production'
        ? 'https://restaurant-widgets-integrations.slicelife.com'
        : 'https://owners-portal-feature-branch.restaurant-widgets-integrations.pages.dev',
    ...filteredFormValues,
  });

  // To have the smart buttons backend taget dev rather than QA, we need to pass this additional
  // query param where we pass in things like color and theme for the smart button
  if (MODE === 'development' || MODE === 'staging') {
    params.set('env', 'dev');
  }

  return params;
};

/**
 * The Promo Message should only show for a shop in three cases.
 * The shop has an Online Discount, the shop has at least one Delivery Zone with no delivery fee
 * or the shop has at least one Delivery Zone with a 0% Delivery Percent
 */
export const getIsPromoMessageAvailableForShop = (shop: Shop): boolean => {
  if (shop.discountPercent) {
    return true;
  }

  if (
    shop.deliveryZones.some((zone) => {
      if (
        (zone.deliveryFee === '0.0' || zone.deliveryFee === null) &&
        !zone.deliveryPercent
      ) {
        return true;
      }

      if (
        (zone.deliveryPercent === '0.0' || zone.deliveryPercent === null) &&
        !zone.deliveryFee
      ) {
        return true;
      }

      return false;
    })
  ) {
    return true;
  }

  return false;
};

export const getTechnicalHelpEmailDetails = (shopId: number): EmailTemplate => {
  const br = '%0D%0A';

  const body = `${br}${br}${br}${br}${br}---${br}RE: Smart Button Technical Help${br}ShopID#: ${shopId}${br}Source: Owner’s Portal`;
  return {
    toAddress: 'partnersuccess@slicelife.com',
    subject: `RE: Smart Button Technical Help, ShopID#: ${shopId}`,
    body,
  };
};

export const smartButtonAnalyticsFormatStrings = {
  [WidgetType.Banner]: 'banner',
  [WidgetType.Button]: 'button',
  [WidgetType.Sticker]: 'sticker',
} as const;

export const smartButtonAnalyticsPositionStrings = {
  [ButtonPosition.BottomCenter]: 'bottom center',
  [ButtonPosition.BottomLeft]: 'bottom left',
  [ButtonPosition.BottomRight]: 'bottom right',
  [BannerPosition.Bottom]: 'bottom',
  [BannerPosition.Top]: 'top',
} as const;

export const websiteIntegrationsAnalyticsThemeStrings = {
  [Theme.Simple]: 'simple',
  [Theme.Elegant]: 'elegant',
  [Theme.Iconic]: 'iconic',
  [Theme.Slice]: 'slice',
} as const;

export const getWebsiteIntegrationColorOptions =
  (): SmartButtonConfigOption[] =>
    Object.entries(WebsiteIntegrationColors).map(([value]) => ({
      label: capitalize(value),
      value,
    }));

export const smartButtonAnalyticsModeStrings = {
  [ThemeVariant.Dark]: 'dark',
  [ThemeVariant.Light]: 'light',
} as const;

export const getAnalyticsPromoMessageFromForm = (
  promoMessage: PromoMessageFormValue,
): 'on' | 'off' => {
  switch (promoMessage) {
    case 'true':
      return 'on';
    default:
      return 'off';
  }
};

export const getSmartButtonsAnalyticsValuesForForm = ({
  shopId,
  formValues,
  isFirstTimeSetup,
  isActive,
}: SmartButtonFormAnalyticsParams): SmartButtonFormAnalyticsValues => {
  return {
    shopId: String(shopId),
    format: smartButtonAnalyticsFormatStrings[formValues.format],
    position: smartButtonAnalyticsPositionStrings[formValues.position],
    theme: websiteIntegrationsAnalyticsThemeStrings[formValues.theme],
    color: formValues.color,
    colorHex: WebsiteIntegrationColors[formValues.color],
    mode: smartButtonAnalyticsModeStrings[formValues.mode],
    promoMessage: getAnalyticsPromoMessageFromForm(formValues.promoMessage),
    previouslyEnabledSettings: !isFirstTimeSetup,
    isActive: isActive,
  };
};

export const getSmartButtonsAnalyticsValuesForSaveRequest = ({
  shopId,
  formValues,
  success,
  url,
  isFirstTimeSetup,
  page,
  isActive,
}: SaveSmartButtonsAnalyticsParams): SaveSmartButtonsRequestAnalytics => {
  return {
    ...getSmartButtonsAnalyticsValuesForForm({
      shopId,
      formValues,
      isFirstTimeSetup,
      isActive,
    }),
    url,
    success,
    page,
  };
};

/**
 * A shop is considered a first time user of smart buttons if the settings response has both the
 * floating button and nav bar disabled. This means even if a shop were to have previously saved settings but now
 * currently has both nav bar and button disabled in admin, we consider them 'new' again.
 */
export const isSmartButtonsFirstTimeSetup = (
  settings: WebsiteIntegrationsSettings,
): boolean => {
  return !settings.floatingButtonEnabled && !settings.navBarEnabled;
};
