import { Checkbox } from 'crust';

import Text from 'components/shared/text';
import useAnalytics from 'hooks/use-analytics';
import { MenuCategory } from 'types/menu/category';
import { MenuProduct, MenuProductType } from 'types/menu/product';
import { Shop } from 'types/shops';
import { getShopHasFullRegister } from 'utilities/shops';

import { useBulkEditContext } from '../context';
import { Product } from '../product';

import styles from './styles.module.scss';

type Props = {
  categoriesById: Record<MenuCategory['id'], MenuCategory>;
  id: MenuCategory['id'];
  productIds: MenuProduct['id'][];
  productTypesById: Record<MenuProductType['id'], MenuProductType>;
  productsById: Record<MenuProduct['id'], MenuProduct>;
  shop: Shop;
};

export const Category = ({
  categoriesById,
  id,
  productIds,
  productTypesById,
  productsById,
  shop,
}: Props) => {
  const { selectedProductIds, changeSelectedProductIds } = useBulkEditContext();
  const { trackMenuSelectedBulkEditCategory } = useAnalytics();

  const isSelected = productIds.every((it) => selectedProductIds.has(it));
  const isIndeterminate =
    !isSelected && productIds.some((it) => selectedProductIds.has(it));

  const isSearch = id === -1;
  const categoryItemsCount = productIds.length;
  const name = isSearch
    ? `${categoryItemsCount} Search Results`
    : categoriesById[id]?.name;
  const description = isSearch ? '' : categoriesById[id]?.description;

  const renderProduct = (productId: MenuProduct['id']) => {
    const product = productsById[productId];
    const category = product && categoriesById[product.categoryId];

    if (product == null || category == null) {
      return null;
    }

    return (
      <Product
        key={productId}
        category={category}
        product={product}
        productTypesById={productTypesById}
        shop={shop}
      />
    );
  };

  const unavailableProductsInCategory = productIds.filter(
    (productId) => productsById[productId]?.unavailable ?? false,
  ).length;

  const handleToggleCategorySelection = (isSelected: boolean) => {
    changeSelectedProductIds(productIds, isSelected);

    if (name) {
      trackMenuSelectedBulkEditCategory({
        shopId: shop.shopId,
        categoryId: id,
        categoryName: name,
        isRegister: getShopHasFullRegister(shop),
        page: 'menu items',
        isSelected,
      });
    }
  };

  return (
    <div className={styles.category}>
      <h4 id={`category-${id}`} className={styles.header}>
        <div className={styles.categoryHeader}>
          {categoryItemsCount > 0 && (
            <Checkbox
              aria-label={`select all products in ${name}`}
              isSelected={isSelected}
              isIndeterminate={isIndeterminate}
              onChange={handleToggleCategorySelection}
            />
          )}
          <Text clamp as="span" aria-label="category-header">
            {name}
          </Text>
        </div>
        <div className={styles.itemInfo}>
          {unavailableProductsInCategory > 0 && (
            <Text className={styles.text}>
              {`${unavailableProductsInCategory} ${unavailableProductsInCategory === 1 ? 'item' : 'items'} out of stock`}
            </Text>
          )}
          <Text
            className={styles.text}
          >{`${categoryItemsCount} ${categoryItemsCount === 1 ? 'item' : 'items'}`}</Text>
        </div>
      </h4>
      {description && <div className={styles.description}>{description}</div>}
      {productIds.map(renderProduct)}
    </div>
  );
};
