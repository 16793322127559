import ContentTile from 'components/shared/content-tile';
import { Shop } from 'types/shops';

import { ExportsTable } from './table';

import styles from './styles.module.scss';

const Header = () => {
  return (
    <div>
      <p>Exports</p>
      <p className={styles.subTitle}>Exports available for 30 days.</p>
    </div>
  );
};

export const ExportsTile = ({
  shopId,
  shopTimezone,
}: {
  shopId: Shop['shopId'];
  shopTimezone: Shop['timezoneIdentifier'];
}) => {
  return (
    <ContentTile title={<Header />}>
      <ExportsTable shopId={shopId} shopTimezone={shopTimezone} />
    </ContentTile>
  );
};
