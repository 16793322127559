import {
  ChangeEvent,
  ElementRef,
  ForwardedRef,
  forwardRef,
  useMemo,
  useState,
} from 'react';
import {
  TextField as AriaTextField,
  TextFieldProps as AriaTextFieldProps,
} from 'react-aria-components';
import { Merge } from 'type-fest';

import { FieldChildren, FieldProps, useField } from '../field/field';
import { Input, TextArea } from '../input/input';

import './text-field.css';

export type TextFieldProps = Merge<
  AriaTextFieldProps,
  FieldProps & { placeholder?: string }
>;

export const TextField = forwardRef(function TextField(
  {
    className,
    label,
    description,
    error,
    placeholder,
    ...props
  }: TextFieldProps,
  ref: ForwardedRef<ElementRef<typeof AriaTextField>>,
) {
  const { getFieldClassName, fieldProps, fieldChildrenProps } = useField({
    className,
    description,
    error,
    label,
    block: 'text-field',
  });

  return (
    <AriaTextField ref={ref} {...fieldProps} {...props}>
      <FieldChildren {...fieldChildrenProps}>
        <Input
          className={getFieldClassName('input')}
          placeholder={placeholder}
        />
      </FieldChildren>
    </AriaTextField>
  );
});

export const TextAreaField = forwardRef(function TextAreaField(
  { className, label, description, error, ...props }: TextFieldProps,
  ref: ForwardedRef<ElementRef<typeof AriaTextField>>,
) {
  const isUncontrolled = props.value === undefined;
  const [value, setValue] = useState(props.value ?? props.defaultValue ?? '');
  const handleChange = useMemo(() => {
    if (isUncontrolled) {
      return (event: ChangeEvent<HTMLTextAreaElement>) =>
        setValue(event.target.value);
    }
  }, [isUncontrolled]);

  const { getFieldClassName, fieldProps, fieldChildrenProps } = useField({
    className,
    description,
    error,
    label,
    block: 'text-area-field',
  });

  return (
    <AriaTextField ref={ref} {...fieldProps} {...props}>
      <FieldChildren {...fieldChildrenProps}>
        <div
          className={getFieldClassName('resizer')}
          data-value={props.value ?? value}
        >
          <TextArea
            className={getFieldClassName('text-area')}
            onChange={handleChange}
          />
        </div>
      </FieldChildren>
    </AriaTextField>
  );
});
