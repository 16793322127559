import EmptyBarIcon from 'images/empty-graphs/bar.svg?react';

import styles from './styles.module.scss';

const ChartPlaceholder = () => (
  <div className={styles.emptyContent}>
    <EmptyBarIcon aria-hidden="true" className={styles.emptyIcon} />
    <div className={styles.emptyText}>No results yet! Check back soon.</div>
  </div>
);

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default ChartPlaceholder;
