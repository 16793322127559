import { CSSProperties, useMemo } from 'react';
import cx from 'classnames';

import ColorIcon from 'images/shop-score/progress-status-colored.svg?react';
import OutlineIcon from 'images/shop-score/progress-status-outline.svg?react';
import { ShopScoreStatusLevel } from 'types/shops';
import { formatDate } from 'utilities/date-time';

import styles from './styles.module.scss';

type Props = {
  isShopScoreInProgress: boolean;
  score: number;
  lastUpdatedDate: string;
};

const StatusIndicator = ({
  isShopScoreInProgress = true,
  score = 0,
  lastUpdatedDate,
}: Props) => {
  const dateText = `As of ${formatDate(lastUpdatedDate, 'MM/DD/YY')}`;

  const icon = useMemo(
    () =>
      score >= ShopScoreStatusLevel.AllStar ? (
        <ColorIcon className={styles.statusIcon} aria-hidden="true" />
      ) : (
        <OutlineIcon
          className={cx(styles.statusIcon, styles.outlineIcon)}
          aria-hidden="true"
        />
      ),
    [score],
  );

  const { percentText, statusStyle, statusText } = useMemo(() => {
    let percentText = '--';
    let statusStyle = undefined;
    let statusText = 'In Progress';

    if (!isShopScoreInProgress) {
      percentText = `${score}%`;

      if (score >= ShopScoreStatusLevel.AllStar) {
        statusText = 'All-star';
        statusStyle = styles.AllStar;
      } else if (score >= ShopScoreStatusLevel.Great) {
        statusText = 'Great';
        statusStyle = styles.great;
      } else if (score >= ShopScoreStatusLevel.Good) {
        statusText = 'Good';
        statusStyle = styles.good;
      } else {
        statusText = 'Okay';
        statusStyle = styles.okay;
      }
    }
    return { percentText, statusStyle, statusText };
  }, [isShopScoreInProgress, score]);

  return (
    <div className={styles.statusWrapper}>
      <div className={styles.statusIndicator}>
        <div
          className={styles.statusRingAndIconWrapper}
          style={{ '--progress': score } as CSSProperties}
        >
          {icon}
        </div>
        <div
          aria-label="score percentage"
          className={cx(styles.statusPercentage, statusStyle)}
        >
          {percentText}
        </div>
      </div>
      <div className={styles.statusText}>
        <div className={styles.statusHeader}>{statusText}</div>
        {!!lastUpdatedDate && (
          <div className={styles.statusDate}>{dateText}</div>
        )}
      </div>
    </div>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default StatusIndicator;
