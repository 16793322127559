// Max x-axis (days) labels on desktop and mobile (to prevent label overlap)
export const maxDayLabels = {
  desktop: 10,
  mobile: 5,
};

// Max y-axis labels on desktop and mobile (to prevent label overlap)
export const maxYAxisLabels = {
  desktop: 10,
  mobile: 5,
};

// Set a y-axis "scaling" factor.
// Applied to the max y-value in the chart data.
// Prevents max y-value hitting the top of the chart (and prevents sharp peaks/troughs)
export const yScaleFactor = 1.8;

// Nivo chart helper functions
export const xScale = (numDays) => ({
  type: 'linear',
  min: 0,
  max: numDays,
});

export const yScale = (maxY) => ({
  type: 'linear',
  min: 0,
  max: maxY,
});

export const axisBottom = (xLabels, xTicks) => ({
  format: (x) => xLabels[x],
  tickValues: xTicks,
});

export const axisLeft = (yTicks, ticksBetweenYAxisLabels) => ({
  format: (y) => (y % ticksBetweenYAxisLabels === 0 ? y : ''),
  tickValues: yTicks,
});

// ID for nivo chart must be unique to time period, to prevent chart rendering improperly.
export const data = (
  dataSetIdentifier,
  startDate,
  endDate,
  formattedXYValues,
) => [
  {
    id: `${dataSetIdentifier}-${startDate}-${endDate}`,
    data: formattedXYValues,
  },
];
