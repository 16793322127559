import * as React from "react";
import { forwardRef } from "react";
const SvgMeetAtDoorIcon = ({
  title,
  titleId,
  ...props
}, ref) => {
  return /* @__PURE__ */ React.createElement("svg", { width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", "aria-hidden": "true", ref, "aria-labelledby": titleId, ...props }, title === void 0 ? /* @__PURE__ */ React.createElement("title", { id: titleId }, "Meet At Door Icon") : title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M12 2C7.58172 2 4 5.58172 4 10V20C4 21.1046 4.89543 22 6 22H9.5L9 17V16L9.3199 12.801C9.42214 11.7786 10.2825 11 11.31 11H14.4384C14.8973 11 15.2973 10.6877 15.4086 10.2425L16.0299 7.75746L17.9701 8.24254L17.3489 10.7276C17.0362 11.9781 15.9642 12.8792 14.6989 12.9888L15 16V17L14.5 22H18C19.1046 22 20 21.1046 20 20V10C20 5.58172 16.4183 2 12 2ZM12 10C13.1046 10 14 9.10457 14 8C14 6.89543 13.1046 6 12 6C10.8954 6 10 6.89543 10 8C10 9.10457 10.8954 10 12 10Z", fill: "currentColor" }), /* @__PURE__ */ React.createElement("path", { d: "M13 22V17H11V22H13Z", fill: "currentColor" }));
};
const ForwardRef = forwardRef(SvgMeetAtDoorIcon);
export default ForwardRef;
