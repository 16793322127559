import { ReactElement } from 'react';

import { RatingStar, RatingStarProps } from 'crust';

import styles from './styles.module.scss';

type Props = {
  rating: number;
};

export const Stars = ({ rating }: Props) => {
  let count = 0;
  const stars: ReactElement[] = [];

  // There are always five stars. We'll run the loop five times and subtract
  // one from the rating each time. The result of the subtraction can be read
  // as the amount of the rating remaining for the current star position.
  for (let index = 0, balance = rating; index < 5; index++, balance--) {
    let variant: RatingStarProps['variant'] = 'empty';

    if (balance > 0.39) {
      const isWhole = balance > 0.71;

      count += isWhole ? 1 : 0.5;
      variant = isWhole ? 'whole' : 'half';
    }

    stars.push(<RatingStar key={index} variant={variant} />);
  }

  return (
    <div
      aria-label={`Rating: ${count} out of 5 stars`}
      className={styles.stars}
      role="img"
    >
      {stars}
    </div>
  );
};
