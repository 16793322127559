import { ComponentPropsWithRef } from 'react';
import classNames from 'classnames';

import styles from './styles.module.scss';

type Props = ComponentPropsWithRef<'hr'>;

export const Divider = ({ className, ...props }: Props) => {
  return <hr className={classNames(styles.hr, className)} {...props} />;
};
