import momment from 'moment-timezone';

import ResultsTile from 'components/campaign-manager/campaign-info/results-tile';
import {
  CampaignManagerState,
  CampaignManagerSummary,
} from 'types/campaign-manager';
import { Shop } from 'types/shops';
import {
  getPlatformPerformingBetterText,
  getRecentResultsPerformanceText,
} from 'utilities/campaign-manager';

type Props = {
  shopId: Shop['shopId'];
  startDate: string;
  summaryData: CampaignManagerSummary;
};

const OptimizationPhase = ({ shopId, startDate, summaryData }: Props) => {
  const formattedDate = momment(startDate).format('MMM D, YYYY');

  return (
    <>
      <ResultsTile
        campaignState={CampaignManagerState.OptimizationPhase}
        description={`Since ${formattedDate}`}
        insights={[
          getRecentResultsPerformanceText(summaryData?.last90Days?.sales),
          getPlatformPerformingBetterText(
            summaryData?.last90Days?.orders,
            summaryData?.platforms,
          ),
        ].filter((node) => node !== null)}
        platformsInfo={summaryData?.platforms}
        results={summaryData?.last90Days}
        shopId={shopId}
        title="Recent Results"
      />
    </>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default OptimizationPhase;
