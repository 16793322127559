import { CustomerList, CustomerType } from 'types/customers';
import {
  customerListKnowYourCustomersLabels,
  customerTypeLabels,
} from 'utilities/customers';

import styles from './styles.module.scss';

type Props = {
  customerList: CustomerList;
  customerType: CustomerType;
};

const CustomerSegmentsTableTitle = ({ customerList, customerType }: Props) => {
  const customerListText = customerListKnowYourCustomersLabels[customerList];
  const customerTypeText = customerTypeLabels[customerType];

  const text = `${customerListText} ${customerTypeText} Customers`;

  return <div className={styles.dynamicTitle}>{text}</div>;
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default CustomerSegmentsTableTitle;
