import { useEffect } from 'react';

import { useShopTraitsContext } from 'hooks/shops/use-shop-traits-context';
import useAnalytics from 'hooks/use-analytics';
import { User } from 'types/user';

import SiteConfiguration from './site-configuration';

type Props = {
  domainName: string | null;
  shopId: string;
  user: User;
};

const CustomWebsite = ({ domainName, shopId, user }: Props) => {
  const { trackUserViewedCustomWebsite } = useAnalytics();
  const traits = useShopTraitsContext();

  useEffect(() => {
    trackUserViewedCustomWebsite({
      shopId,
      shopIntegrationAdoption: traits.shopIntegrationAdoption,
      isActive: domainName ? true : false,
    });
  }, [
    domainName,
    shopId,
    trackUserViewedCustomWebsite,
    traits.shopIntegrationAdoption,
  ]);

  return (
    <SiteConfiguration domainName={domainName} shopId={shopId} user={user} />
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default CustomWebsite;
