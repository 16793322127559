import { useMutation } from '@tanstack/react-query';

import { OrderStatementResponse } from 'types/order-statement';
import { Shop } from 'types/shops';
import { camelCaseKeys } from 'utilities/objects';

import useApi from './use-api';

type Props = {
  shopId: Shop['shopId'];
};

export const useOrderStatementMutation = ({ shopId }: Props) => {
  const { authenticatedFetch } = useApi();

  return useMutation({
    mutationFn: async (orderId: string) => {
      const response = await authenticatedFetch.get(
        `api/management/v2/shops/${shopId}/orders/${orderId}/statement`,
      );

      return camelCaseKeys(response) as OrderStatementResponse;
    },
  });
};
