import { useParams } from 'react-router-dom';

import RegisterLayout from 'components/register/menu-layout';
import { RegisterLayoutRedesign } from 'components/register/menu-layout-redesign';
import Page from 'components/shared/page';
import { useCurrentShopId } from 'hooks/shops';
import useFeatureFlag from 'hooks/use-feature-flag';
import { OptimizelyFeatureFlag } from 'types/optimizely-feature-flag';

type RegisterLayoutParams = {
  categoryId?: string;
  shopId: string;
};

const RegisterLayoutPage = () => {
  const { categoryId } = useParams<RegisterLayoutParams>();
  const shopId = useCurrentShopId();
  const {
    isEnabled: isRegisterMenuLayoutRedesignEnabled,
    isLoading: isFeatureFlagLoading,
  } = useFeatureFlag(OptimizelyFeatureFlag.RegisterMenuLayoutRedesign);

  return (
    <Page
      isHeaderShown={false}
      isLoading={isFeatureFlagLoading}
      name="sort-register-menu"
    >
      {isRegisterMenuLayoutRedesignEnabled ? (
        <RegisterLayoutRedesign
          categoryId={categoryId}
          shopId={String(shopId)}
        />
      ) : (
        <RegisterLayout categoryId={categoryId} shopId={String(shopId)} />
      )}
    </Page>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default RegisterLayoutPage;
