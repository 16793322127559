// Slight overkill for now but will come in handy when we
// have more than one integration type
export const PosProviders = {
  Square: 'square',
};

export const PosAuthModes = {
  Disconnect: 'disconnect',
  Revoke: 'revoke',
};
