import { CamelCasedProperties } from 'type-fest';

// Relevant ROS service enum:
// https://github.com/slicelife/ros-service/blob/9e6bbe9c81e9d32145627a69c95363972a8879c6/app/permissions/models/audit_log.py#L10
export enum AuditLogStatus {
  Authorized = 'action_authorized',
  Successful = 'action_successful',
  Preauthorized = 'action_preauthorized',
}

export type NetworkPermissionLog = {
  action: string;
  action_category: string;
  action_name: string;
  audit_log_group_id: string;
  authorized_by_id: string;
  authorized_by_name: string;
  created_at: string;
  id: string;
  notes: string | null;
  register_created_at: string;
  register_profile_id: string;
  register_profile_name: string;
  register_user_id: string;
  register_user_name: string;
  resource_id: string | null;
  resource_type: string;
  shop_id: string;
  status: AuditLogStatus;
};

export type NetworkPermissionsLogMetadata = {
  current_page: number;
  pages: number;
  per_page: number;
  total: number;
};

export type NetworkGetPermissionsLogResponse = {
  data: NetworkPermissionLog[];
  metadata: NetworkPermissionsLogMetadata;
};

export type PermissionLog = CamelCasedProperties<NetworkPermissionLog>;

export type PermissionsLogMetadata =
  CamelCasedProperties<NetworkPermissionsLogMetadata>;

export type GetPermissionsLogResponse = {
  data: PermissionLog[];
  metadata: PermissionsLogMetadata;
};

export type PermissionsLogRequestParams = {
  page: number;
  perPage: number;
};
