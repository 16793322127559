import { CalendarDate } from '@internationalized/date';

import { RangeValue } from 'crust';

export enum PromoCodeCategory {
  FreeDelivery = 'free_delivery',
  Internal = 'internal',
  Promocode = 'promocode',
}

export enum PromoCodeDepartment {
  ChainFunded = 'chain_funded',
  DirectMail = 'direct_mail',
  Finance = 'finance',
  Internal = 'internal',
  Loyalty = 'loyalty',
  MarketingAquisition = 'marketing_acquisition',
  MarketingBrand = 'marketing_brand',
  MarketingPartnerships = 'marketing_partnerships',
  MarketingRetention = 'marketing_retention',
  MarketingSweepstakes = 'marketing_sweepstakes',
  MarketingTransitions = 'marketing_transitions',
  ProductUserTesting = 'product_user_testing',
  RestaurantFunded = 'restaurant_funded',
  RevenueCouncil = 'revenue_council',
  Support = 'support',
}

export enum PromoCodeChannel {
  Web = 'web',
  Ios = 'iosapp',
  Android = 'androidapp',
}

export enum PromoCodeType {
  Flat = 'flat',
  Percent = 'percent',
}

export type PromoCode = {
  active: boolean;
  category: PromoCodeCategory;
  createdAt: string;
  dateEnd: string | null;
  dateStart: string;
  department: PromoCodeDepartment;
  description: string | null;
  firstTimeOnly: boolean | null;
  firstTimeOnlyPlacedVia: boolean | null;
  id: number;
  minOrder: string | null;
  placedViasMask: number;
  promoFlatAmount: string | null;
  promoPercentAmount: string | null;
  shopId: number;
  singleUse: boolean;
  singleUsePerCustomer: boolean;
  tag: string;
  updatedAt: string;
};

export enum PromoCodeLimit {
  None = 'none',
  Once = 'once',
  OncePerCustomer = 'oncePerCustomer',
}

export type PromoCodeFormValues = {
  amount: string;
  channels: PromoCodeChannel[];
  dates:
    | { kind: 'date'; value: CalendarDate }
    | { kind: 'range'; value: RangeValue<CalendarDate> };
  hasOrderMinimum: 'false' | 'true';
  limit: PromoCodeLimit;
  orderMinimum: string;
  tag: string;
  type: PromoCodeType;
};

export type CreatePromoCodeRequestBody = {
  promoCode: {
    category: PromoCodeCategory;
    active: boolean;
    tag: string;
    promoAmountType: PromoCodeType;
    promoPercentAmount: number | null;
    promoFlatAmount: number | null;
    minOrder: number;
    dateStart: string;
    dateEnd: string | null;
    singleUse: boolean;
    singleUsePerCustomer: boolean;
    placedVias: PromoCodeChannel[];
  };
};

export type PromoCodeEvent = {
  countActivePromoCodes: number;
  countPromoCodes: number;
  isRegister: boolean;
  location?: string;
  page: string;
  shopId: number;
};
