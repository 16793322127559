import { useFieldArray, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import Link from 'components/shared/link';
import { RHFCheckbox } from 'components/shared/rhf-checkbox';
import Modal from 'components/shared/slice-modal';
import * as paths from 'routes/paths';
import { RegisterPrinter } from 'types/register-printing';
import { Shop } from 'types/shops';

import styles from './styles.module.scss';

type Props = {
  shop: Shop;
  printerSettings: RegisterPrinter[];
  isOpen: boolean;
  closeModal: () => void;
};

const validationSchema = z.object({
  printers: z.array(
    z.object({
      id: z.string(),
      name: z.string(),
      isSelected: z.boolean(),
    }),
  ),
});

type FormValues = z.infer<typeof validationSchema>;

export const AssignPrintersModal = (props: Props) => {
  const { closeModal, printerSettings, isOpen, shop } = props;

  const { control, reset, handleSubmit } = useForm<FormValues>({
    defaultValues: {
      printers: printerSettings.map((it) => ({
        id: it.id,
        name: it.name,
        isSelected: false,
      })),
    },
    resolver: zodResolver(validationSchema),
    mode: 'onBlur',
  });

  const onSubmit = () => {
    reset();
    closeModal();
  };

  const { fields: printerFields } = useFieldArray({
    control,
    name: 'printers',
  });

  const hasPrinters = printerFields.length > 0;

  return (
    <Modal
      header="Assign Printers"
      isFullScreenMobile
      isOpen={isOpen}
      noButtonText="Cancel"
      onClickNo={closeModal}
      formId="assign-printers-modal-form"
      onRequestClose={closeModal}
      yesButtonText="Save"
      isYesButtonDisabled={!hasPrinters}
      className={styles.modal}
    >
      {hasPrinters ? (
        <form id="assign-printers-modal-form" onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.content}>
            {printerFields.map((field, index) => (
              <RHFCheckbox
                key={field.id}
                control={control}
                name={`printers.${index}.isSelected`}
                label={field.name}
              />
            ))}
          </div>
        </form>
      ) : (
        <p>
          It looks like you haven&apos;t added any printers yet. You can add one
          in the{' '}
          <Link to={paths.shopRegisterPrinting(shop.shopId)}>
            Printer Settings
          </Link>{' '}
          page.
        </p>
      )}
    </Modal>
  );
};
