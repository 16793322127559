import { ForwardedRef, forwardRef } from 'react';
import { PatternFormat, PatternFormatProps } from 'react-number-format';
import { SetOptional } from 'type-fest';

import Input, { InputProps } from 'components/shared/input';

type Props = SetOptional<PatternFormatProps<InputProps>, 'format'>;

const PhoneInput = forwardRef(function PhoneInput(
  props: Props,
  ref: ForwardedRef<HTMLInputElement>,
) {
  return (
    <PatternFormat
      allowEmptyFormatting={false}
      customInput={Input}
      format="(###) ###-####"
      mask="_"
      getInputRef={ref}
      inputMode="numeric"
      placeholder="(123) 456-7890"
      {...props}
    />
  );
});

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default PhoneInput;
