import { RegisterDiscountType } from './discounts';
import { OrderFilterValue, OrderTabs, RegisterMetrics } from './orders';

export type NetworkRegisterOrderCustomer = {
  first_name: string;
  last_name: string;
};

export type NetworkRegisterOrder = {
  created_at: string;
  delivery_fee: number;
  discount_total: number;
  effective_subtotal: number;
  first_name: string | null;
  last_name: string | null;
  order_status: string;
  order_uuid: string;
  refunded_total: number;
  shipping_type: string;
  shop_uuid: string;
  tax: number;
  tip: number;
  total: number;
};

export type OrderAddress = {
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zipcode: string;
};

export type OrderCustomer = {
  firstName: string;
  lastName: string;
  phoneNumber: string;
};

export type OrderItemAddonSelections = {
  kind: string;
  unitPrice: number | null;
  position: number;
  quantity: number;
  modifierName: string;
};

export type OrderItemAddon = {
  id: string;
  name: string;
  orderItemSelections: OrderItemAddonSelections[];
  position: number;
};

export type OrderItem = {
  active: boolean;
  id: string;
  lineItemType: string | null;
  orderItemAddons: OrderItemAddon[];
  position: number;
  productName: string;
  quantity: number;
  refundId: string | null;
  typeName: string;
  unitPrice: number;
};

type RegisterOrderDiscount = {
  active: boolean;
  createdAt: string;
  discountId: string;
  id: string;
  name: string;
  orderId: string;
  total: number;
  type: RegisterDiscountType;
  value: number;
};

type OrderPaymentTip = {
  id: string;
  refundId: string | null;
  value: number;
};

type OrderPaymentRefund = {
  id: string;
  value: number | null;
};

type OrderPayments = {
  cardFeeAmount: number | null;
  cardFeeReversalAmount?: number | null;
  cashDiscount?: number | null;
  id: string;
  orderId: string;
  orderTip: OrderPaymentTip | null;
  refunds: OrderPaymentRefund[];
};

type OrderFee = {
  active: boolean;
  id: string;
  totalAmount: number;
  type: string;
};

export type SingleRegisterOrder = {
  cashDiscountTotal: number;
  completedAt: string;
  createdAt: string;
  discountTotal: number;
  note: string;
  number: string;
  orderAddress: OrderAddress;
  orderCustomer: OrderCustomer;
  orderDiscounts: RegisterOrderDiscount[];
  orderItems: OrderItem[];
  orderFees: OrderFee[];
  orderPayments: OrderPayments[];
  orderStatus: string;
  status: string;
  subtotal: number;
  taxTotal: number;
  tip: number;
  total: number;
  type: string;
};

export type RegisterOrder = {
  cashDiscountTotal: number;
  createdAt: string;
  deliveryFee: number;
  discountTotal: number;
  effectiveSubtotal: number;
  firstName: string | null;
  lastName: string | null;
  orderIdentifier: string;
  orderStatus: string;
  orderUuid: string;
  refundedTotal: number;
  shippingType: string;
  shopUuid: string;
  tax: number;
  tip: number;
  total: number;
};

export type SelectedRegisterOrderStatusFilterData<T extends OrderFilterValue> =
  {
    orderStatus: T;
    shopId: string;
    tab: OrderTabs;
  };

export type ClickedOpenRegisterReceiptModalData = {
  shopId: string;
};

export type ClickedPrintReceiptModalData = {
  shopId: string;
};

export enum RegisterOrdersAnalyticsEventsName {
  SelectedOrderStatusFilter = 'selected_order_status_filter',
  ClickedOpenReceiptModal = 'clicked_open_receipt_modal',
  ClickedPrintInReceiptModal = 'clicked_print_in_receipt_modal',
}

export enum RegisterOrderFilterValue {
  All = 'all',
  AwaitingPayment = 'awaiting_payment',
  Cleared = 'cleared',
  Held = 'held',
  Paid = 'paid',
  PartiallyRefunded = 'partially_refunded',
  Refunded = 'refunded',
}

export type RegisterOrderMetaData = {
  finish: string;
  includesOrderStatuses?: RegisterOrderFilterValue[];
  page: number;
  pages: number;
  perPage: number;
  results: number;
  sortBy: string;
  sortDirection: string;
  start: string;
};

export type RegisterOrdersResponse = {
  data: RegisterOrder[];
  meta: RegisterOrderMetaData;
};

export type RegisterOrdersRequestParams = {
  finish: string;
  orderStatus?: RegisterOrderFilterValue;
  page: number;
  perPage: number;
  sortBy: string;
  sortDirection: string;
  start: string;
};

export const getRegisterOrderMetaDefault = (
  shopTimezone: string,
): RegisterOrderMetaData => {
  return {
    finish: new Date().toLocaleString('en-US', { timeZone: shopTimezone }),
    page: 1,
    pages: 1,
    perPage: 20,
    results: 0,
    sortBy: 'date_purchased',
    sortDirection: 'desc',
    start: new Date().toLocaleString('en-US', { timeZone: shopTimezone }),
  };
};

export type NetworkRosOrderMetrics = {
  average_order_total: string;
  order_count: number;
  order_total: string;
  tip_total: string;
};

export const networkToDomainRosOrderMetrics = (
  networkRosTipsAndEarnings: NetworkRosOrderMetrics,
): RegisterMetrics => {
  return {
    averageOrder: networkRosTipsAndEarnings.average_order_total,
    numOrders: networkRosTipsAndEarnings.order_count,
    totalSales: networkRosTipsAndEarnings.order_total,
    totalTips: networkRosTipsAndEarnings.tip_total,
  };
};
