import {
  CalendarDate,
  now,
  toZoned,
  ZonedDateTime,
} from '@internationalized/date';
import moment, { Moment } from 'moment-timezone';

// Earliest available data from Owner's Portal Analytics Service
// is January 1, 2020.
/** @deprecated */
export const earliestOpasDate = moment('2020-01-01');

/** @deprecated */
export const startOfDayString = (
  date: Moment | null,
  timezone?: string,
): string | null => {
  if (!date) {
    return null;
  }

  if (!timezone) {
    return date.clone().startOf('day').format();
  }

  return date.clone().tz(timezone).startOf('day').format();
};

/** @deprecated */
export const endOfDayString = (
  date: Moment | null,
  timezone?: string,
): string | null => {
  if (!date) {
    return null;
  }

  if (!timezone) {
    return date.clone().endOf('day').format();
  }

  return date.clone().tz(timezone).endOf('day').format();
};

/** @deprecated */
export const nowDateTime = (timezone?: string | null): Moment =>
  timezone ? moment.tz(timezone) : moment();

/** @deprecated */
export const getNextDay = (date: Moment): Moment =>
  moment(date).clone().add(1, 'days');

/** @deprecated */
export const getFutureUtcDateTime = (hoursInFuture: number): Moment => {
  const roundedCurrentTime = nowDateTime().milliseconds(0);
  // We remove milliseconds because they're unnecessary for our purposes and can actually cause rounding issues.
  return roundedCurrentTime.add(hoursInFuture, 'h').utc();
};

/** @deprecated */
export const formatDate = (
  date: Moment | string,
  format: string,
  timezone?: string,
): string => {
  let datetime = moment(date);

  if (timezone) {
    datetime = datetime.tz(timezone);
  }

  return datetime.format(format);
};

/** @deprecated */
export const formatTime = (
  time: string,
  format: string,
  newFormat: string,
): string => moment(time, format).format(newFormat);

/** @deprecated */
export const isoDateAndTime = 'YYYY-MM-DD[T]HH:mm:ss';

/** @deprecated */
export const isDateTomorrow = (date: Moment, timezone?: string): boolean => {
  const now = timezone ? moment.tz(timezone) : moment();
  const tomorrow = now.clone().startOf('day').add(1, 'days');

  return date.clone().startOf('day').isSame(tomorrow);
};

/** @deprecated */
export const formatDailyHour = (hour: string, shopTimezone: string): string => {
  return moment(hour, 'HH:mm', shopTimezone).format('h:mma');
};

/**
 * Determines if a ZonedDateTime occurred in the past N hours. The end range
 * comparison is not inclusive.
 */
export const isFromLastGivenHours = (past: ZonedDateTime, hours: number) => {
  const present = now('UTC');
  const future = past.add({ hours });

  return present.compare(past) >= 0 && present.compare(future) < 0;
};

/**
 * Determines if a ZonedDateTime occurred in the past 24 hours. The end range
 * comparison is not inclusive.
 */
export const isFromLast24Hours = (past: ZonedDateTime) =>
  isFromLastGivenHours(past, 24);

/**
 * Formats a CalendarDate as an ISO 8601 date and time in UTC string relative to
 * midnight in the timezone.
 */
export const toStartOfDateAbsoluteString = (
  date: CalendarDate,
  timezone: string,
) => toZoned(date, timezone).toAbsoluteString();

/**
 * Formats a CalendarDate as an ISO 8601 date and time in UTC string relative to
 * the last millisecond of the date in the timezone.
 */
export const toEndOfDateAbsoluteString = (
  date: CalendarDate,
  timezone: string,
) =>
  toZoned(date, timezone)
    .add({ days: 1 })
    .subtract({ milliseconds: 1 })
    .toAbsoluteString();
