import * as React from "react";
import { forwardRef } from "react";
const SvgMinusIcon = ({
  title,
  titleId,
  ...props
}, ref) => {
  return /* @__PURE__ */ React.createElement("svg", { width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", "aria-hidden": "true", ref, "aria-labelledby": titleId, ...props }, title === void 0 ? /* @__PURE__ */ React.createElement("title", { id: titleId }, "Minus Icon") : title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { d: "M22 13.125L12 13.75L2 13.125V10.625L12 10L22 10.625V13.125Z", fill: "currentColor" }));
};
const ForwardRef = forwardRef(SvgMinusIcon);
export default ForwardRef;
