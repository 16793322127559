import { Fragment, useEffect } from 'react';
import { useFieldArray, useWatch } from 'react-hook-form';
import PropTypes from 'prop-types';

import {
  createFormRelationship,
  CustomizationField,
  findFieldsToAdd,
  findFieldsToRemove,
  getCustomizationFieldName,
  getCustomizationRelationshipFieldName,
  ProductField,
  RelationshipField,
} from 'utilities/menu';

const CustomizationMeta = ({ control, customizationIndex, register }) => {
  const { append, fields, remove } = useFieldArray({
    control,
    keyName: 'key', // Don't confuse the field key with the relationship ID.
    name: getCustomizationFieldName(
      CustomizationField.Relationships,
      customizationIndex,
    ),
  });

  // A product type was added, removed, etc.
  const productTypes = useWatch({
    control,
    name: ProductField.ProductTypes,
  });

  useEffect(() => {
    const toAdd = findFieldsToAdd(productTypes, fields);

    if (toAdd.length > 0) {
      // Create relationships for new product types.
      append(
        toAdd.map((productTypeId) => createFormRelationship({ productTypeId })),
        { shouldFocus: false },
      );

      // We can only call one field array action per render.
      return;
    }

    const toRemove = findFieldsToRemove(productTypes, fields);

    if (toRemove.length > 0) {
      // Remove relationships for product types that no longer exist.
      remove(toRemove);
    }
  }, [append, fields, productTypes, remove]);

  return fields.map((field, index) => (
    <Fragment key={field.key}>
      <input
        defaultValue={field[RelationshipField.Id]}
        type="hidden"
        {...register(
          getCustomizationRelationshipFieldName(
            RelationshipField.Id,
            customizationIndex,
            index,
          ),
        )}
      />
      <input
        defaultValue={field[RelationshipField.ProductTypeId]}
        type="hidden"
        {...register(
          getCustomizationRelationshipFieldName(
            RelationshipField.ProductTypeId,
            customizationIndex,
            index,
          ),
        )}
      />
    </Fragment>
  ));
};

CustomizationMeta.propTypes = {
  control: PropTypes.object.isRequired,
  customizationIndex: PropTypes.number.isRequired,
  register: PropTypes.func.isRequired,
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default CustomizationMeta;
