import moment from 'moment-timezone';

import ResultsTile from 'components/campaign-manager/campaign-info/results-tile';
import {
  CampaignManagerState,
  CampaignManagerSummary,
} from 'types/campaign-manager';
import { Shop } from 'types/shops';
import { insightText } from 'utilities/campaign-manager';

type Props = {
  shopId: Shop['shopId'];
  startDate: string;
  summaryData: CampaignManagerSummary;
};

const RampUpPhase = ({ shopId, startDate, summaryData }: Props) => {
  const formattedDate = moment(startDate).format('MMM D, YYYY');

  return (
    <>
      <ResultsTile
        campaignState={CampaignManagerState.RampUpPhase}
        description={`Since ${formattedDate}`}
        insights={[
          insightText.yourAdsAreLive,
          insightText.campaignInLearningPhase,
          insightText.resultsWillImprove,
        ]}
        platformsInfo={summaryData?.platforms}
        results={summaryData?.last90Days}
        shopId={shopId}
        title="Recent Results"
      />
    </>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default RampUpPhase;
