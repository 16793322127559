import FeaturedPhotosIcon from 'images/shop-score/items/ic_app_photos.svg?react';
import DeliveryServiceIcon from 'images/shop-score/items/ic_delivery.svg?react';
import DiscountsIcon from 'images/shop-score/items/ic_discounts.svg?react';
import ExclusiveOrderingIcon from 'images/shop-score/items/ic_exclusive.svg?react';
import GoogleBusinessIcon from 'images/shop-score/items/ic_google_business_profile.svg?react';
import GoogleOrderingIcon from 'images/shop-score/items/ic_google_ordering.svg?react';
import MenuPhotosIcon from 'images/shop-score/items/ic_menu_photos.svg?react';
import AvoidableOrderIssueRateIcon from 'images/shop-score/items/ic_odr.svg?react';
import SliceOnlineOrderingIcon from 'images/shop-score/items/ic_online_ordering.svg?react';
import OrderMethodIcon from 'images/shop-score/items/ic_order_method.svg?react';
import { ShopScoreTraits } from 'types/shops';

export const shopScoreActions = {
  [ShopScoreTraits.OpsScore10PlusPhotos]: {
    title: 'Menu Photos',
    description:
      "Customers order what they can see. Adding photos to your menu can increase sales by up to 20%, it's an easy, effective way to increase sales. Upload photos of your delicious food today!",
    icon: MenuPhotosIcon,
    buttonText: 'Take a Tour',
    isToastShownOnButtonClick: false,
  },
  [ShopScoreTraits.OpsScorePhotosHasFeaturedPhotos]: {
    title: 'App Profile Photos',
    description:
      'Stand out on Slice by adding featured photos of your best selling dishes and start getting more orders!',
    icon: FeaturedPhotosIcon,
    buttonText: 'Take a Tour',
    isToastShownOnButtonClick: false,
  },
  [ShopScoreTraits.DigitalScoreGmbWebsite]: {
    title: 'Your Business on Google',
    description:
      "List your business on Google and you could see a 40% increase in sales. We'll manage your online reputation and keep your profile up-to-date so customers know what to expect.",
    icon: GoogleBusinessIcon,
    buttonText: 'Request This',
    disabledButtonText: 'Message Sent',
    isToastShownOnButtonClick: true,
  },
  [ShopScoreTraits.DigitalScoreHasGfo]: {
    title: 'Ordering via Google',
    description:
      "You could increase online orders by up to 55% if you activate Google Food Ordering. We've got a preferred partnership with Google and customers love the convenience of ordering directly through your search listing. Use it and earn more.",
    icon: GoogleOrderingIcon,
    buttonText: 'Request This',
    disabledButtonText: 'Message Sent',
    isToastShownOnButtonClick: true,
  },
  [ShopScoreTraits.OpsScoreAvoidableOrderIssueRate28]: {
    title: 'Keep Your Avoidable Order Issues Low',
    description:
      "Want to build customer trust and get more regulars? Don't lose customers with avoidable order issues. Keep your menu and hours up-to-date and talk to your account manager about any changes you'd like to make.",
    icon: AvoidableOrderIssueRateIcon,
    buttonText: 'Chat with Us',
    disabledButtonText: 'Message Sent',
    isToastShownOnButtonClick: true,
  },
  [ShopScoreTraits.DigitalScoreHasOnlineOrdering]: {
    title: 'Slice Online Ordering',
    description:
      "Need a website? We'll create one for you that customers can order from. Already have one? Upgrade it with the Slice ordering button and get more online orders.",
    icon: SliceOnlineOrderingIcon,
    buttonText: 'Get Slice Ordering',
    disabledButtonText: 'Message Sent',
    isToastShownOnButtonClick: true,
  },
  [ShopScoreTraits.DigitalScoreRealDirectPartnership]: {
    title: 'Exclusive Website Ordering',
    description:
      'How much are our competitors? Too much. We help you thrive without high fees. Feature us exclusively on your website, save money, and earn customers.',
    icon: ExclusiveOrderingIcon,
    buttonText: 'Get Started',
    disabledButtonText: 'Message Sent',
    isToastShownOnButtonClick: true,
  },
  [ShopScoreTraits.OpsScorePreferredTransmission]: {
    title: 'Ordering Methods',
    description:
      'Increase sales by up to 15% with fast, accurate digital ordering systems like Slice Register (our new POS made exclusively for Pizzerias), a POS integration with Square or Clover, or a free Slice Tablet.',
    icon: OrderMethodIcon,
    buttonText: 'Chat with Us',
    disabledButtonText: 'Message Sent',
    isToastShownOnButtonClick: true,
  },
  [ShopScoreTraits.OpsScoreHasDelivery]: {
    title: 'Delivery Service',
    description:
      "Shops like yours can gain 45% more orders when they deliver. Don't know how? Contact us and we'll tell you all about ",
    descriptionLink: 'Slice Delivery',
    descriptionUrl: 'https://slicelife.com/owners/slice-delivery',
    icon: DeliveryServiceIcon,
    buttonText: 'Chat with Us',
    disabledButtonText: 'Message Sent',
    isToastShownOnButtonClick: true,
  },
  [ShopScoreTraits.OpsScoreHasOnlineDiscount]: {
    title: 'Discounts',
    description:
      'Discounts drive sales. See up to 15% more sales by adding a discount today. Reward loyal customers and earn new ones, while standing out from your competition.',
    icon: DiscountsIcon,
    buttonText: 'Take a Tour',
    isToastShownOnButtonClick: false,
  },
};
