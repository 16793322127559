import { ElementRef, ForwardedRef } from 'react';
import {
  FieldPath,
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';
import { DateValue } from '@internationalized/date';
import { Merge } from 'type-fest';

import {
  DateRangePicker,
  DateRangePickerProps,
  forwardRefWithGenerics,
  mergeRefs,
} from 'crust';

export type RHFDateRangePickerProps<
  TDateValue extends DateValue,
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = Merge<
  Omit<
    DateRangePickerProps<TDateValue>,
    'isInvalid' | 'onChange' | 'onBlur' | 'validate' | 'validationBehavior'
  >,
  Omit<UseControllerProps<TFieldValues, TName>, 'disabled'>
>;

export const RHFDateRangePicker = forwardRefWithGenerics(
  function RHFDateRangePicker<
    TDateValue extends DateValue,
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
  >(
    {
      control,
      defaultValue,
      isDisabled,
      name,
      rules,
      shouldUnregister,
      ...props
    }: RHFDateRangePickerProps<TDateValue, TFieldValues, TName>,
    ref: ForwardedRef<ElementRef<typeof DateRangePicker>>,
  ) {
    const { field, fieldState } = useController({
      control,
      defaultValue,
      disabled: isDisabled,
      name,
      rules,
      shouldUnregister,
    });

    return (
      <DateRangePicker
        error={fieldState.error?.message}
        isDisabled={field.disabled}
        isInvalid={fieldState.invalid}
        onBlur={field.onBlur}
        onChange={field.onChange}
        ref={mergeRefs(ref, field.ref)}
        validationBehavior="aria"
        value={field.value}
        {...props}
      />
    );
  },
);
