import { ComponentPropsWithoutRef } from 'react';
import cx from 'classnames';

import TableBodySpanner from '../spanner';

import styles from './styles.module.scss';

type Props = {
  colSpan: number;
} & ComponentPropsWithoutRef<'div'>;

// A table body with one row and one column with a min-height and centered
// content.
const TableBodyPlaceholder = ({ className, colSpan, ...props }: Props) => (
  <TableBodySpanner colSpan={colSpan}>
    <div className={cx(styles.placeholder, className)} {...props} />
  </TableBodySpanner>
);

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default TableBodyPlaceholder;
