import * as React from "react";
import { forwardRef } from "react";
const SvgPickupCustomersIconNoOutline = ({
  title,
  titleId,
  ...props
}, ref) => {
  return /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", fill: "none", viewBox: "0 0 24 24", ref, "aria-labelledby": titleId, ...props }, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { stroke: "#211E1E", strokeWidth: 2, d: "M16 7c0-2.20914-1.7909-4-4-4-2.20914 0-4 1.79086-4 4m8 4c0 2.2091-1.7909 4-4 4-2.20914 0-4-1.7909-4-4M4 8h16v11c0 1.1046-.8954 2-2 2H6c-1.10457 0-2-.8954-2-2V8Z" }));
};
const ForwardRef = forwardRef(SvgPickupCustomersIconNoOutline);
export default ForwardRef;
