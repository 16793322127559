import { useMemo, useState } from 'react';
import { Menu, MenuItem } from '@szhsin/react-menu';
import {
  ColumnSort,
  createColumnHelper,
  PaginationState,
  SortingState,
} from '@tanstack/react-table';
import moment from 'moment-timezone';

import { Icon, Link } from 'crust';

import ContentTile from 'components/shared/content-tile';
import { PayoutStatusDisplay } from 'components/shared/payout-status-display';
import Button, { ButtonVariant } from 'components/shared/slice-button';
import Table from 'components/shared/table';
import TableAction from 'components/shared/table/action';
import Pagination from 'components/shared/table/pagination';
import VisuallyHidden from 'components/shared/visually-hidden';
import { useStatementsQuery } from 'hooks/financials/use-statements';
import { useTable } from 'hooks/shared';
import useAnalytics from 'hooks/use-analytics';
import {
  statementsDefinitions as statementsDefinitionsUrl,
  statementsFaq as statementsFaqUrl,
} from 'routes/external-urls';
import {
  DownloadedStatementDocData,
  GetStatementsParams,
  Statement,
} from 'types/financials/statements';
import { Shop } from 'types/shops';
import { toMoneyString } from 'utilities/currency';

import { PayoutsFeatureHighlight } from './payouts-feature-highlight';

import styles from './styles.module.scss';

const formatDateRangeToDisplay = (statement: Statement) => {
  // Statement dates are always relative to US Pacific time. Using a different
  // timezone here will make Owner's Portal display different dates than what
  // the statement PDF acutally shows.
  if (statement.type === 'monthly') {
    return `${moment
      .tz(statement.generatedDate, 'America/Los_Angeles')
      .format('MMMM YYYY')}`;
  }

  return `${moment
    .tz(statement.fromDate, 'America/Los_Angeles')
    .format('MM/DD/YYYY')} - ${moment
    .tz(statement.toDate, 'America/Los_Angeles')
    .format('MM/DD/YYYY')}`;
};

const mapSortingStateToSortParam = (sorting: ColumnSort) => {
  let sortParam: GetStatementsParams['sort'] = 'created_at';

  if (sorting.id === 'payoutAmount') {
    sortParam = 'payout_amount';
  } else if (sorting.id === 'status') {
    sortParam = 'status';
  }

  if (sorting.desc) {
    sortParam = `-${sortParam}`;
  }

  return sortParam;
};

type TileHeaderProps = {
  shopId: Shop['shopId'];
};

const TileHeader = ({ shopId }: TileHeaderProps) => {
  const { trackClickedSupportDocLink } = useAnalytics();

  const handleDefinitionsClick = () => {
    trackClickedSupportDocLink({
      shopId,
      page: 'statements',
      copy: 'definitions',
    });
  };

  const handleFaqClick = () => {
    trackClickedSupportDocLink({
      shopId,
      page: 'statements',
      copy: 'faqs',
    });
  };

  return (
    <>
      <Link
        href={statementsDefinitionsUrl}
        variant="primary"
        size="small"
        onPress={handleDefinitionsClick}
      >
        Definitions
      </Link>
      <Link
        href={statementsFaqUrl}
        variant="primary"
        size="small"
        onPress={handleFaqClick}
      >
        FAQs
      </Link>
    </>
  );
};

type TableDownloadMenuProps = {
  shopId: Shop['shopId'];
  statement: Statement;
};

const TableDownloadMenu = ({ shopId, statement }: TableDownloadMenuProps) => {
  const { trackDownloadedFromStatements, trackClickedDownloadStatementIcon } =
    useAnalytics();

  const handleMenuButtonClick = () =>
    trackClickedDownloadStatementIcon({
      shopId,
      statementId: statement.id,
      triggeredDirectDownload: false,
    });

  const handleDownloadItemClick = (
    statementType: DownloadedStatementDocData['statementType'],
    fileType: DownloadedStatementDocData['fileType'],
  ) => {
    const statementBucket: DownloadedStatementDocData['statementBucket'] =
      statement.type === 'monthly' ? 'monthly' : 'payout';

    trackDownloadedFromStatements({
      shopId,
      statementId: statement.id,
      statementType,
      statementBucket,
      fileType,
    });
  };

  const range = formatDateRangeToDisplay(statement);

  return (
    <div className={styles.dropdownContainer}>
      <Menu
        menuButton={
          <Button
            className={styles.downloadMenuButton}
            aria-label={`Download orders statement for ${range}.`}
            variant={ButtonVariant.Transparent}
            onClick={handleMenuButtonClick}
          >
            <Icon
              icon="download"
              className={styles.downloadIcon}
              size="large"
            />
          </Button>
        }
        align="end"
      >
        <MenuItem>
          <Link
            className={styles.downloadMenuItemLink}
            href={statement.statementUrl}
            icon="download"
            iconPosition="end"
            variant="secondary"
            size="small"
            onPress={() => handleDownloadItemClick('statement', 'pdf')}
          >
            Statement
          </Link>
        </MenuItem>
        {statement.breakdownPdf ? (
          <MenuItem>
            <Link
              className={styles.downloadMenuItemLink}
              href={statement.breakdownPdf}
              icon="download"
              iconPosition="end"
              variant="secondary"
              size="small"
              onPress={() =>
                handleDownloadItemClick('transaction breakdown', 'pdf')
              }
            >
              Transactions (PDF)
            </Link>
          </MenuItem>
        ) : null}
        {statement.breakdownCsv ? (
          <MenuItem>
            <Link
              className={styles.downloadMenuItemLink}
              href={statement.breakdownCsv}
              icon="download"
              iconPosition="end"
              variant="secondary"
              size="small"
              onPress={() =>
                handleDownloadItemClick('transaction breakdown', 'csv')
              }
            >
              Transactions (CSV)
            </Link>
          </MenuItem>
        ) : null}
      </Menu>
    </div>
  );
};

const DEFAULT_SORT = {
  id: 'dateCreated',
  desc: true,
} as const;

type Props = {
  shopId: Shop['shopId'];
};

export const Statements = ({ shopId }: Props) => {
  const { trackClickedDownloadStatementIcon } = useAnalytics();
  const [sorting, setSorting] = useState<SortingState>([DEFAULT_SORT]);

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });

  const setPerPage = (perPage: number): void => {
    setPagination({
      pageIndex: 0,
      pageSize: perPage,
    });
  };

  const { data: statements, isLoading: isStatementsLoading } =
    useStatementsQuery({
      shopId,
      params: {
        page: pagination.pageIndex + 1,
        perPage: pagination.pageSize,
        sort: mapSortingStateToSortParam(sorting.at(0) ?? DEFAULT_SORT),
      },
    });

  const columns = useMemo(() => {
    const helper = createColumnHelper<Statement>();

    return [
      helper.accessor('toDate', {
        id: 'statement',
        enableSorting: false,
        header: 'Statement',
        cell(ctx) {
          const statement = ctx.row.original;

          return (
            <>
              <span className={styles.statementsColumnText}>
                {formatDateRangeToDisplay(statement)}
              </span>
            </>
          );
        },
      }),
      helper.accessor(
        (row) =>
          moment
            .tz(row.generatedDate, 'America/Los_Angeles')
            .format('MM/DD/YY'),
        {
          id: 'dateCreated',
          header: 'Date Created',
          enableSorting: true,
        },
      ),
      helper.accessor(
        (row) => {
          if (row.payoutAmount !== null) {
            return toMoneyString(row.payoutAmount, true, true);
          }

          return '-';
        },
        {
          id: 'payoutAmount',
          header: 'Payout Amount',
          enableSorting: true,
        },
      ),
      helper.accessor((row) => row.status, {
        id: 'status',
        header: 'Status',
        enableSorting: true,
        cell(ctx) {
          const statement = ctx.row.original;

          if (statement.status) {
            return <PayoutStatusDisplay status={statement.status} />;
          } else {
            return '';
          }
        },
      }),
      helper.display({
        id: 'download',
        header() {
          return <VisuallyHidden>Download</VisuallyHidden>;
        },
        meta: {
          isActionColumn: true,
        },
        cell(ctx) {
          const statement = ctx.row.original;

          const range = formatDateRangeToDisplay(statement);

          // When a statement only has a statement url and no transaction link available
          // we want to have the download icon open or download the statement url straight away
          // as the menu would only have the one option anyway.
          if (!statement.breakdownCsv && !statement.breakdownPdf) {
            return (
              <TableAction
                icon="download"
                title={`Download orders statement for ${range}.`}
                to={statement.statementUrl}
                onClick={() =>
                  trackClickedDownloadStatementIcon({
                    shopId,
                    statementId: statement.id,
                    triggeredDirectDownload: true,
                  })
                }
              />
            );
          }

          return <TableDownloadMenu shopId={shopId} statement={statement} />;
        },
      }),
    ];
  }, [trackClickedDownloadStatementIcon, shopId]);

  const totalPages = statements?.meta.pages ?? 1;

  const table = useTable({
    columns,
    data: statements?.statements ?? [],
    manualPagination: true,
    manualSorting: true,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    pageCount: totalPages,
    state: {
      pagination,
      sorting,
      isLoading: isStatementsLoading,
    },
    chameleonTableTitle: 'Statements',
  });

  const handlePageChange = (selectedPage: number) => {
    table.setPageIndex(selectedPage - 1);
  };

  const suppressPagination =
    !statements?.meta.pages || isStatementsLoading || totalPages <= 1;

  return (
    <>
      <PayoutsFeatureHighlight shopId={shopId} />
      <ContentTile
        headerClassName={styles.tileHeader}
        headerContent={<TileHeader shopId={shopId} />}
        suppressTitle
      >
        <Table table={table} />
        <Pagination
          currentPage={table.getState().pagination.pageIndex + 1}
          isPageControlVisible={!suppressPagination}
          onChangePage={handlePageChange}
          onChangePerPage={setPerPage}
          perPage={pagination.pageSize}
          totalPages={totalPages}
        />
      </ContentTile>
    </>
  );
};
