import { databaseRecordSource } from 'utilities/constants';

export const formatClosingForTable = (closing) => ({
  ...closing,
  closureReason: closing.closureReason.id,
  closureReasonName: closing.closureReason.name,
});

export const formatClosingReasonsForSelect = (closingReasons) =>
  closingReasons.map((reason) => ({
    ...reason,
    value: parseInt(reason.id),
    label: reason.label,
    id: parseInt(reason.id),
  }));

export const formatClosingReasonsForPause = (closingReasons) =>
  closingReasons.map((reason) => ({
    ...reason,
    id: parseInt(reason.id),
  }));

export const formatClosingForApi = (values) => {
  const closing = {
    start_date: values.dates.start.toString(),
    end_date: values.dates.end.toString(),
    reason: values.closingComment,
    shipping_type: values.shippingType,
    closure_reason_id: values.closingReason.value,
    source: databaseRecordSource,
  };
  return closing;
};

export const formatGenerateScheduleRequest = (openings, closings, timezone) => {
  const convertedOpenings = openings.map((it) => ({
    day_of_week: it.dayOfWeek,
    id: it.id,
    to: it.to,
    effective_date: it.effectiveDate,
    open_for: it.openFor,
    from: it.from,
  }));

  const convertedClosings = closings.map((it) => ({
    reason: it.reason,
    source: it.source,
    id: it.id,
    start_date: it.startDate,
    shipping_type: it.shippingType,
    end_date: it.endDate,
    closure_reason: {
      id: it.closureReason,
      name: it.closureReasonName,
    },
  }));

  return {
    openings: convertedOpenings,
    closings: convertedClosings,
    timezone,
  };
};
