import { useMemo } from 'react';

import Text from 'components/shared/text';
import { SingleRegisterOrder } from 'types/register-orders';

import PriceItemEntry from '../price-item-entry';

import styles from './styles.module.scss';

type Props = {
  order: SingleRegisterOrder;
};

const PriceDetails = ({ order }: Props) => {
  const discounts = useMemo(() => {
    const allDiscounts = order.orderDiscounts.map((discount) => {
      return {
        id: discount.id,
        label: (
          <span className={styles.discountLabel}>
            <span className={styles.discountLabelOpen}>Discount {`("`}</span>
            <Text clamp className={styles.discountName} as="span">
              {discount.name}
            </Text>
            <span className={styles.discountLabelClose}>{`")`}</span>
          </span>
        ),
        price: discount.total,
      };
    });

    return allDiscounts;
  }, [order.orderDiscounts]);

  const { refundedTip, nonCashAdjustment, cashDiscount, refunded } =
    useMemo(() => {
      let totalRefundedTip = 0;
      let totalNonCashAdjustment = 0;
      let totalRefunded = 0;

      order.orderPayments.forEach((orderPayment) => {
        if (orderPayment.orderTip?.refundId) {
          totalRefundedTip += orderPayment.orderTip.value;
        }

        totalNonCashAdjustment += orderPayment.cardFeeAmount ?? 0;

        orderPayment.refunds?.forEach((refund) => {
          totalRefunded += refund.value ?? 0;
        });
      });

      return {
        refundedTip: totalRefundedTip,
        nonCashAdjustment: totalNonCashAdjustment,
        cashDiscount: order.cashDiscountTotal,
        refunded: totalRefunded,
      };
    }, [order.cashDiscountTotal, order.orderPayments]);

  const deliveryFee = useMemo(() => {
    return (
      order.orderFees.find(
        (orderFee) => orderFee.active && orderFee.type === 'DELIVERY',
      )?.totalAmount ?? 0
    );
  }, [order.orderFees]);

  return (
    <dl className={styles.chargesContainer}>
      <PriceItemEntry label="Subtotal" price={order.subtotal} />
      <PriceItemEntry label="Tax" price={order.taxTotal} />
      {deliveryFee > 0 && (
        <PriceItemEntry label="Delivery Fee" price={deliveryFee} />
      )}
      {discounts.map((discount) => (
        <PriceItemEntry
          key={discount.id}
          label={discount.label}
          price={discount.price}
          pricePrefix="-"
        />
      ))}
      {nonCashAdjustment > 0 && (
        <PriceItemEntry label="Non-Cash Adjustment" price={nonCashAdjustment} />
      )}
      {cashDiscount > 0 && (
        <PriceItemEntry
          label="Cash Discount"
          price={cashDiscount}
          pricePrefix="-"
        />
      )}
      <PriceItemEntry label="Total" price={order.total} isBold />
      <PriceItemEntry
        label="Tip"
        price={order.tip}
        isRefundItem
        {...(order.tip > 0 ? { pricePrefix: '+' } : {})}
      />
      {refundedTip > 0 && (
        <PriceItemEntry
          label="Tip (Refunded)"
          price={refundedTip}
          isRefundItem
        />
      )}
      {refunded > 0 && (
        <PriceItemEntry
          label="Refunded"
          price={refunded}
          isRefundItem
          pricePrefix="-"
        />
      )}
    </dl>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default PriceDetails;
