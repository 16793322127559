import { PropsWithChildren } from 'react';
import { CalendarDate } from '@internationalized/date';

import { PresetRangeValue, useDateFormatter } from 'crust';

import styles from './styles.module.scss';

type Props = {
  currentPage: number;
  dates: PresetRangeValue<CalendarDate, string>;
  itemsTotal: number;
  maxPageSize: number;
  pageSize: number;
  timezone: string;
};

const TableFilter = ({
  children,
  currentPage,
  dates,
  itemsTotal,
  maxPageSize,
  pageSize,
  timezone,
}: PropsWithChildren<Props>) => {
  // MM/DD/YYYY in en-US.
  const dateFormatter = useDateFormatter({
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    timeZone: timezone,
  });

  const startingIndex = currentPage * maxPageSize + 1;
  const endingIndex = startingIndex + pageSize - 1;
  const label =
    dates.id === 'custom'
      ? `${dateFormatter.format(dates.start.toDate(timezone))} - ${dateFormatter.format(dates.end.toDate(timezone))}`
      : dates.label.toLowerCase();

  return (
    <div className={styles.headerArea}>
      <div className={styles.resultDetails}>
        <div className={styles.details}>{`Results for ${label}`}</div>
        <div className={styles.count}>
          {itemsTotal > 0
            ? `Viewing Orders: ${startingIndex} - ${endingIndex} of ${itemsTotal}`
            : 'No results'}
        </div>
      </div>
      <div className={styles.tableActions}>{children}</div>
    </div>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default TableFilter;
