import Closing from './closing';
import EmptyClosing from './empty-closing';

import styles from './styles.module.scss';

type ClosingType = {
  reason: string;
  shippingType: string;
  startDate: string;
  endDate: string;
  closureReason: number;
  closureReasonName: string;
};

type Props = {
  nextClosing?: ClosingType;
  todaysClosing?: ClosingType;
  shopId: string;
};

const UpcomingClosings = ({ nextClosing, todaysClosing, shopId }: Props) => (
  <>
    {todaysClosing && (
      <>
        <div className={styles.title}>Current</div>
        <Closing
          className={styles.today}
          closing={todaysClosing}
          dataTestId="current-closing"
        />
      </>
    )}
    <div className={styles.title}>Upcoming</div>
    {nextClosing ? (
      <Closing closing={nextClosing} dataTestId="next-closing" />
    ) : (
      <EmptyClosing shopId={shopId} />
    )}
  </>
);

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default UpcomingClosings;
