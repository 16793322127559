import { useQuery } from '@tanstack/react-query';
import PropTypes from 'prop-types';

import AnalyticsTile from 'components/shared/analytics-tile';
import PieChart from 'components/shared/charts/pie';
import useApi from 'hooks/use-api';
import { toMoneyString } from 'utilities/currency';
import { capitalize } from 'utilities/strings';

const colorScheme = {
  delivery: 'var(--op-color-graph1)',
  pickup: 'var(--op-color-graph3)',
};

const formatData = (data) =>
  data
    ? Object.entries(data).map(([key, { count, total }]) => ({
        id: capitalize(key),
        value: count,
        color: colorScheme[key],
        legendText: `${count} orders・${toMoneyString(total / 100)}`,
      }))
    : [];

const isDataEmpty = (data) => {
  return !data || !Object.values(data).some((value) => value.count > 0);
};

const NetSalesByShippingType = ({ shopId, startDate, endDate, orderType }) => {
  const api = useApi();

  const { data, isLoading } = useQuery(
    [shopId, { startDate, endDate, orderType }, 'metrics-order-totals'],
    () =>
      api.fetchOrderTotalsByShippingTypeMetrics(shopId, {
        start: startDate,
        finish: endDate,
        orderType,
      }),
  );

  return (
    <AnalyticsTile
      data-chameleon-target="Order Metrics NetSalesByShippingType PieChart"
      title={'Delivery & Pickup'}
      footerText="Learn how to boost your business and much more on our blog."
      linkText="Explore Our Shop Tips"
      link="https://blog.slicelife.com/category/shop-tips/"
    >
      <PieChart
        data={formatData(data)}
        isEmpty={isLoading || isDataEmpty(data)}
      />
    </AnalyticsTile>
  );
};

NetSalesByShippingType.propTypes = {
  shopId: PropTypes.string,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  orderType: PropTypes.string,
  showTileCaption: PropTypes.bool,
};

NetSalesByShippingType.defaultProps = {
  shopId: '',
  shopTimezone: '',
  orderType: 'online',
  showTileCaption: true,
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default NetSalesByShippingType;
