import { ComponentPropsWithoutRef } from 'react';
import cx from 'classnames';

import styles from './styles.module.scss';

const FormFeedback = ({
  children,
  className,
  ...divProps
}: ComponentPropsWithoutRef<'div'>) =>
  children ? (
    <div className={cx(styles.message, className)} role="alert" {...divProps}>
      {children}
    </div>
  ) : null;

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default FormFeedback;
