import styles from './styles.module.scss';

const BrandManagerHeader = () => {
  return (
    <div className={styles.header}>
      <div className={styles.title}>Expand Your Reach, Boost Your Sales</div>
      <div className={styles.description}>
        Customers are everywhere online, so your restaurant must be too.
        Simplify your online presence and keep it up to date with Brand Manager.
        You are now reaching more customers!
      </div>
    </div>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default BrandManagerHeader;
