export type OpasFilter = {
  exact?: OpasExactFilter[];
  match?: OpasMatchFilter[];
};

export type OpasFilters = {
  filters: OpasFilter[];
};

export type OpasExactFilter = {
  key: string;
  term: ExactFilterTerm;
};

export type OpasMatchFilter = {
  key: string;
  terms: MatchFilterTerm[];
};

export type ExactFilterTerm = {
  condition: FilterCondition;
  value: string | number;
};

export type MatchFilterTerm = {
  condition: FilterCondition;
  values: string[] | number[];
};

export enum FilterCondition {
  Is = 'is',
}

export type ProductSalesMetric = {
  category_id: number;
  category: string;
  product_name: string;
  product_id: number;
  sales: number;
  units: number;
  image_url: string | null;
  discontinued: boolean;
  orders: number;
};

export type ProductSalesMetricsResponse = {
  data: ProductSalesMetric[];
};
