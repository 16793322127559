import PropTypes from 'prop-types';

import { useShopScoreQuery } from 'hooks/shops';
import { ShopScoreTraits } from 'types/shops';

import { shopScoreActions as actionDefinitions } from './actions';
import ActionsOverview from './actions-overview';
import Header from './header';

export const buildActionsOptions = (activeTraits, traitsWithValues) => {
  return activeTraits.map((trait) => ({
    ...actionDefinitions[trait],
    id: trait,
    isCompleted: !!traitsWithValues?.[trait],
    chameleonTag: `Shop Score Actions ${trait}`,
  }));
};

const ShopScores = ({ shopId }) => {
  const {
    data: shopScoreData,
    error: shopScoreError,
    isLoading: isShopScoreLoading,
  } = useShopScoreQuery(shopId);

  /*
    There is a difference between the response having:
    (1) No traits data:
      - The response status will be 404.
      - There will be "shop score errors".
      - This represents a special "shop score is in progress" state.
    (2) Traits data, but no actions completed.

    The "success" list:
    - Displays different message and icon for "no traits data" versus
    "we have traits data, but no actions are completed."
    
    The "completed" list:
    - Does NOT display a different message for "no traits data" versus
    "we have traits data, but no actions are completed."
  */
  const isShopScoreInProgress = shopScoreError?.status === 404;

  const activeTraits = Object.values(ShopScoreTraits);
  const traitsWithValues = shopScoreData?.traitsWithValues ?? {};

  const actions = buildActionsOptions(activeTraits, traitsWithValues);

  return (
    <>
      <Header shopId={shopId} />
      <ActionsOverview
        actions={actions}
        isShopScoreInProgress={isShopScoreInProgress}
        isShopScoreLoading={isShopScoreLoading}
        shopId={shopId}
      />
    </>
  );
};

ShopScores.propTypes = {
  shopId: PropTypes.string.isRequired,
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default ShopScores;
