import * as React from "react";
import { forwardRef } from "react";
const SvgValuableCustomersIcon = ({
  title,
  titleId,
  ...props
}, ref) => {
  return /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", fill: "none", viewBox: "0 0 32 32", ref, "aria-labelledby": titleId, ...props }, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("circle", { cx: 16, cy: 16, r: 16, fill: "#F5F3F0" }), /* @__PURE__ */ React.createElement("path", { stroke: "#AA6556", strokeWidth: 2, d: "m16 16-1.7574 1.7574h-2.4852v2.4852L10 22l1.7574 1.7574v2.4852h2.4852L16 28l1.7574-1.7574h2.4852v-2.4852L22 22l-1.7574-1.7574v-2.4852h-2.4852L16 16Z" }), /* @__PURE__ */ React.createElement("path", { fill: "#AA6556", d: "M7 9V8H5.25158l.88649 1.50702L7 9Zm5 0 .8682-.49614L12.5803 8H12v1Zm4 7-.8682.4961c.178.3116.5093.5039.8682.5039s.6902-.1923.8682-.5039L16 16Zm9-7 .8619.50702L26.7484 8H25v1Zm-5 0V8h-.5803l-.2879.50386L20 9Zm-7.1381 7.993L7.86193 8.49298 6.13807 9.50702 11.1381 18.007l1.7238-1.014ZM7 10h5V8H7v2Zm4.1318-.50386 4 6.99996 1.7364-.9922-4-7.00004-1.7364.99228Zm9.7301 8.51086 5-8.49998-1.7238-1.01404-5 8.50002 1.7238 1.014ZM25 8h-5v2h5V8Zm-5.8682.50386-4 7.00004 1.7364.9922 4-6.99996-1.7364-.99228Z" }));
};
const ForwardRef = forwardRef(SvgValuableCustomersIcon);
export default ForwardRef;
