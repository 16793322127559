import * as React from "react";
import { forwardRef } from "react";
const SvgTagIcon = ({
  title,
  titleId,
  ...props
}, ref) => {
  return /* @__PURE__ */ React.createElement("svg", { width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", "aria-hidden": "true", ref, "aria-labelledby": titleId, ...props }, title === void 0 ? /* @__PURE__ */ React.createElement("title", { id: titleId }, "Tag Icon") : title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M3 5V11.1716C3 11.702 3.21071 12.2107 3.58579 12.5858L11.2929 20.2929C11.7456 20.7456 12.3597 21 13 21C13.6403 21 14.2544 20.7456 14.7071 20.2929L20.2929 14.7071C20.7456 14.2544 21 13.6403 21 13C21 12.3597 20.7456 11.7456 20.2929 11.2929L12.5858 3.58579C12.2107 3.21071 11.702 3 11.1716 3H5C3.89543 3 3 3.89543 3 5ZM7.5 9C8.32843 9 9 8.32843 9 7.5C9 6.67157 8.32843 6 7.5 6C6.67157 6 6 6.67157 6 7.5C6 8.32843 6.67157 9 7.5 9Z", fill: "currentColor" }));
};
const ForwardRef = forwardRef(SvgTagIcon);
export default ForwardRef;
