import { useEffect, useMemo } from 'react';
import { useFieldArray, useWatch } from 'react-hook-form';
import PropTypes from 'prop-types';

import {
  createFormHalfOffer,
  createFormWholeOffer,
  CustomizationField,
  findFieldsToAdd,
  findFieldsToRemove,
  getCustomizationFieldName,
  getSelectionFieldName,
  OfferField,
  ProductField,
  SelectionField,
} from 'utilities/menu';

import SelectionOffer from './selection-offer';

import styles from './styles.module.scss';

const SelectionOffers = ({
  control,
  customizationIndex,
  isByType,
  isHalf,
  register,
  selectionIndex,
  setValue,
}) => {
  const { append, fields, remove } = useFieldArray({
    control,
    keyName: 'key', // Offers alredy have an ID.
    name: getSelectionFieldName(
      isHalf ? SelectionField.HalfOffers : SelectionField.WholeOffers,
      customizationIndex,
      selectionIndex,
    ),
  });

  // A product type was added or removed.
  const productTypes = useWatch({
    control,
    name: ProductField.ProductTypes,
  });

  useEffect(() => {
    const toAdd = findFieldsToAdd(productTypes, fields);

    if (toAdd.length > 0) {
      // Create offers for new product types.
      append(
        toAdd.map((productTypeId) =>
          isHalf
            ? createFormHalfOffer({ productTypeId })
            : createFormWholeOffer({ productTypeId }),
        ),
        { shouldFocus: false },
      );

      // We can only call one field array action per render.
      return;
    }

    const toRemove = findFieldsToRemove(productTypes, fields);

    if (toRemove.length > 0) {
      // Remove offers for product types that no longer exist.
      remove(toRemove);
    }
  }, [append, fields, isHalf, remove, productTypes]);

  const activeProductTypeId = useWatch({
    control,
    name: getCustomizationFieldName(
      CustomizationField.ActiveProductTypeId,
      customizationIndex,
    ),
  });

  const activeOfferIndex = useMemo(
    () =>
      fields.findIndex(
        (it) => it[OfferField.ProductTypeId] === activeProductTypeId,
      ),
    [activeProductTypeId, fields],
  );

  return fields.map((field, index) => (
    <SelectionOffer
      activeOfferIndex={activeOfferIndex}
      className={styles[isHalf ? 'half' : 'whole']}
      control={control}
      customizationIndex={customizationIndex}
      isByType={isByType}
      isHalf={isHalf}
      key={field.key}
      offerIndex={index}
      register={register}
      selectionIndex={selectionIndex}
      setValue={setValue}
    />
  ));
};

SelectionOffers.propTypes = {
  control: PropTypes.object.isRequired,
  customizationIndex: PropTypes.number.isRequired,
  isByType: PropTypes.bool.isRequired,
  isHalf: PropTypes.bool,
  register: PropTypes.func.isRequired,
  selectionIndex: PropTypes.number.isRequired,
  setValue: PropTypes.func.isRequired,
};

SelectionOffers.defaultProps = {
  isHalf: false,
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default SelectionOffers;
