import { useEffect, useState } from 'react';
import { Control, FieldError } from 'react-hook-form';

import CollapsibleTile from 'components/shared/collapsible-tile';
import { RHFCheckbox, RHFCheckboxGroup } from 'components/shared/rhf-checkbox';
import { PromoCodeChannel, PromoCodeFormValues } from 'types/discounts';
import { PromoCodeChannelLabels } from 'utilities/discounts';

import { ChannelSummary } from './summary';

type Props = {
  channelsError?: FieldError;
  className?: string;
  control: Control<PromoCodeFormValues>;
};

export const ChannelFields = ({ channelsError, className, control }: Props) => {
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    if (channelsError) {
      setIsOpen(true);
    }
  }, [channelsError]);

  return (
    <CollapsibleTile
      bodyChameleonTarget="Promo Code Select Channels"
      className={className}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      summary={<ChannelSummary control={control} />}
      title="Set Channels"
    >
      <RHFCheckboxGroup
        control={control}
        label="Where will your promo be available?"
        name="channels"
        orientation="vertical"
        rules={{
          required: 'Please select at least one channel.',
        }}
      >
        {Object.values(PromoCodeChannel).map((channel) => (
          <RHFCheckbox
            key={channel}
            label={PromoCodeChannelLabels[channel]}
            value={channel}
          />
        ))}
      </RHFCheckboxGroup>
    </CollapsibleTile>
  );
};
