export type Order = {
  id: string;
  type: 'orders';
  attributes: {
    createdAt: string;
    credit: boolean;
    delivery: boolean;
    faxPdfUploadedAt: string | null;
    name: string;
    paymentStatus: string;
    subtotal: number;
    tax: number;
    tip: number;
    total: number;
  };
};

type Pagination = {
  pages: number;
  total: number;
};

type OnlineOrdersResponseMeta = {
  count: number;
  pagination: Pagination;
  sortAsc: boolean;
  sortKey: string;
};

export type OnlineOrdersResponse = {
  data: Order[];
  meta: OnlineOrdersResponseMeta;
};

export type OnlineOrdersRequestParams = {
  endDate: string;
  includeVoidedOrders: boolean | undefined;
  orderStatus:
    | OnlineOrderFilterValue.Completed
    | OnlineOrderFilterValue.Refunded
    | OnlineOrderFilterValue.Void
    | undefined;
  page: number;
  perPage: number;
  sort: string;
  startDate: string;
};

export enum OnlineOrderStatus {
  All = 'all',
  Authorized = 'authorized',
  Paid = 'paid',
  Refunded = 'refunded',
  Void = 'void',
}

export enum OnlineOrderFilterValue {
  All = 'all',
  Completed = 'completed',
  Refunded = 'refunded',
  Void = 'voided',
}

export type ClickedDownloadOnlineOrderPDFData = {
  orderId: string;
  orderStatus: Omit<OnlineOrderStatus, 'All'>;
  shopId: string;
};

export enum OnlineOrdersSortKey {
  CreatedAt = 'createdAt',
  Name = 'name',
  OrderType = 'orderType',
  Tip = 'tip',
  Total = 'total',
  Status = 'status',
  Subtotal = 'subtotal',
  Tax = 'tax',
  Id = 'id',
}
