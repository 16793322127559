import styles from './styles.module.scss';

type Props = {
  digit?: string;
};

const PinDigit = ({ digit = '-' }: Props) => {
  return <div className={styles.pinDigit}>{digit}</div>;
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default PinDigit;
