import { imgixUrl } from 'utilities/image-urls';

import styles from './styles.module.scss';

type Props = {
  productName: string;
  url: string;
};

const PhotoPreview = ({ productName, url }: Props) => {
  return (
    <img
      alt={productName}
      className={styles.photoPreview}
      src={imgixUrl(url, 456, 686)}
    />
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default PhotoPreview;
