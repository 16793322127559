import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { zodResolver } from '@hookform/resolvers/zod';

import Modal from 'components/shared/slice-modal';
import { useCreateSalesforceCaseMutation } from 'hooks/salesforce/use-create-salesforce-case';
import useAnalytics from 'hooks/use-analytics';
import { RegisterUpsellFormValues } from 'types/register/register-splash';
import {
  SalesforceCaseType,
  SalesforceNote,
  SalesforceSubject,
} from 'types/salesforce';
import { User } from 'types/user';
import { EXPIRY_IN_30DAYS, getExpiresAtDate } from 'utilities/salesforce';

import {
  registerUpsellFormValidationSchema,
  ScheduleDemoForm,
} from '../schedule-demo-form';

import styles from './styles.module.scss';

// The logic for determining `Is Qualified` field is
// A lead is qualified if the user responded:
// [POS contract expiration = “no contract” OR “0-3 months”] AND
// Table AND Bar Service = “No”
// Else, Not Qualified
const getIsFormValueQualified = (formValues: RegisterUpsellFormValues) => {
  const hasNoCurrentPosSystem = formValues.posSystem === 'None';

  const isExpirationValueQualified =
    formValues.posExpiration === 'No Contract' ||
    formValues.posExpiration === '0-3 Months';

  const isServiceTypeQualified = formValues.posServiceType === 'No';

  return (
    (hasNoCurrentPosSystem || isExpirationValueQualified) &&
    isServiceTypeQualified
  );
};

const getSalesforceNoteFromForm = (formValues: RegisterUpsellFormValues) => {
  const currentPosProvider =
    formValues.posSystem === 'Other'
      ? formValues.posSystemName
      : formValues.posSystem;

  const contractExpiration = formValues.posExpiration;
  const barOrTableService = formValues.posServiceType;
  const locationManaged = formValues.posNumOfLocations;

  return `${
    SalesforceNote.SliceRegisterNote
  } --- Current POS Provider: ${currentPosProvider}, ${
    contractExpiration ? `Contract Expiration: ${contractExpiration}, ` : ''
  }Bar/Table Service: ${barOrTableService}, Locations Managed: ${locationManaged}`;
};

type Props = {
  isOpen: boolean;
  shopId: number;
  user: User;
  onRequestClose: () => void;
  setCaseExpiration: (value: string) => void;
  page: string;
};

export const RegisterUpsellModal = ({
  isOpen,
  shopId,
  user,
  onRequestClose,
  setCaseExpiration,
  page,
}: Props) => {
  const {
    mutate: createSalesforceCase,
    isLoading: isCreatingSalesforceCaseLoading,
  } = useCreateSalesforceCaseMutation();

  const handleSetLocalStorage = () => {
    const value = getExpiresAtDate(EXPIRY_IN_30DAYS);
    setCaseExpiration(String(value));
  };

  const { trackSubmittedRegisterUpsellForm } = useAnalytics();

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isValid },
    watch,
  } = useForm<RegisterUpsellFormValues>({
    defaultValues: {
      posSystem: null,
      posExpiration: null,
      posNumOfLocations: null,
      posServiceType: null,
    },
    resolver: zodResolver(registerUpsellFormValidationSchema),
    mode: 'onTouched',
    shouldUnregister: true,
  });

  const handleModalClose = () => {
    onRequestClose();
  };

  const handleScheduleDemoSubmitSuccess = (
    formValues: RegisterUpsellFormValues,
  ) => {
    createSalesforceCase(
      {
        salesforceCase: {
          caseType: SalesforceCaseType.Register,
          subject: SalesforceSubject.Register,
        },
        note: getSalesforceNoteFromForm(formValues),
        shopId,
        user,
        isQualifiedYesOrNo: getIsFormValueQualified(formValues) ? 'Yes' : 'No',
      },
      {
        onError: () =>
          toast.error(
            `We weren't able to process your request. Please contact your Partner Success Manager.`,
          ),
        onSuccess: () => {
          toast.success(
            'Great! We received your request. Our team will be in touch shortly.',
          );
          handleSetLocalStorage();
          onRequestClose();
        },
      },
    );
    trackSubmittedRegisterUpsellForm({
      shopId: String(shopId),
      page: page,
      isQualified: String(getIsFormValueQualified(formValues)),
      currentPos: formValues.posSystem,
      posOther: formValues.posSystemName ? formValues.posSystemName : '',
      contractLength: formValues.posExpiration
        ? formValues.posExpiration
        : 'no contract',
      barWaitService: formValues.posServiceType,
      locations: formValues.posNumOfLocations,
    });
  };

  return (
    <Modal
      className={styles.modal}
      header="Schedule a Demo"
      isOpen={isOpen}
      isFullScreenMobile
      onRequestClose={handleModalClose}
      formId="schedule-register-demo-form"
      onClickNo={handleModalClose}
      noButtonText="Cancel"
      yesButtonText="Schedule"
      isYesButtonDisabled={!isValid || isCreatingSalesforceCaseLoading}
    >
      <ScheduleDemoForm
        control={control}
        errors={errors}
        handleSubmit={handleSubmit}
        onSubmitSuccess={handleScheduleDemoSubmitSuccess}
        register={register}
        watch={watch}
      />
    </Modal>
  );
};
