import { useState } from 'react';

import Button, { ButtonVariant } from 'components/shared/slice-button';
import Text from 'components/shared/text';
import { CustomerProfileOrderItem } from 'types/customers';

import styles from './styles.module.scss';

type Props = {
  items: CustomerProfileOrderItem[];
};

const defaultItemCountToShow = 3;

const ItemsCell = ({ items }: Props) => {
  const isLargeItemCount = items.length > defaultItemCountToShow;
  const [showAllItems, setShowAllItems] = useState(
    isLargeItemCount ? false : true,
  );

  const handleExpanderClick = () => {
    setShowAllItems((previous) => !previous);
  };

  return (
    <>
      {items
        .slice(0, showAllItems ? undefined : defaultItemCountToShow)
        .map((item, index) => (
          <Text clamp key={index} title={`${item.units} • ${item.productName}`}>
            {item.units} • {item.productName}
          </Text>
        ))}
      {isLargeItemCount && (
        <Button
          className={styles.itemsExpandButton}
          onClick={handleExpanderClick}
          variant={ButtonVariant.Transparent}
        >
          Read {showAllItems ? ' Less' : 'More'}
        </Button>
      )}
    </>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default ItemsCell;
