import { OnlineOrderStatus } from './financials/online-orders';
import { RegisterOrderFilterValue } from './register-orders';

export type TipsAndEarningsRequestParams = {
  endDate: string;
  startDate: string;
};

export type TipsAndEarningsResponseBody = {
  tips: string;
  earnings: string;
  voidedEarnings: string;
  totalOrders: string;
  totalVoidedOrders: number;
};

export enum OrderTypes {
  Online = 'online',
  Offline = 'offline',
  All = 'all',
}

export enum OrderTabs {
  Online = 'online',
  Register = 'register',
}

export type RegisterMetrics = {
  averageOrder: string;
  numOrders: number;
  totalSales: string;
  totalTips: string;
};

export enum RegisterMetricKeys {
  AverageOrder = 'averageOrder',
  NumOrders = 'numOrders',
  TotalSales = 'totalSales',
  TotalTips = 'totalTips',
}

export enum OnlineMetricKeys {
  Earnings = 'earnings',
  Tips = 'tips',
  TotalOrders = 'totalOrders',
  AverageOrder = 'averageOrder',
  VoidedEarnings = 'voidedEarnings',
}

export type SortResult = {
  sort_by: string;
  sort_direction: 'asc' | 'desc';
};

export type SortParams = {
  id: string;
  desc: boolean;
};

export type OrderFilterValue = RegisterOrderFilterValue | OnlineOrderStatus;

export type ClickedCreateAnOrderReportData = {
  dateRangeLabel: string;
  endDate: string;
  shopId: string;
  startDate: string;
  tab: OrderTabs;
};

export enum OrdersAnalyticsEventsName {
  ClickedCreateAReport = 'clicked_create_a_report_CTA',
}
