import {
  ButtonType,
  LetterCaseOption,
  ShapeOption,
  SizeOption,
  UtmContentTypeOptions,
} from 'types/slice-ordering';

export const availableSizeOptions = [
  {
    label: 'Small',
    value: SizeOption.Small,
  },
  {
    label: 'Medium',
    value: SizeOption.Medium,
  },
  {
    label: 'Large',
    value: SizeOption.Large,
  },
];

export const availableShapeOptions = [
  {
    label: 'Rounded',
    value: ShapeOption.Rounded,
  },
  {
    label: 'Pill',
    value: ShapeOption.Pill,
  },
  {
    label: 'Square',
    value: ShapeOption.Square,
  },
];

export const availableLetterCaseOptions = [
  {
    label: 'Uppercase',
    value: LetterCaseOption.Upper,
  },
  {
    label: 'Normal',
    value: LetterCaseOption.Lower,
  },
];

export const sliceColors = [
  {
    name: 'yellow',
    urlCode: 'yellow',
    label: 'Yellow',
    hex: 'FDC831',
  },
  {
    name: 'dark',
    urlCode: 'dark',
    label: 'Dark',
    hex: '000000',
  },
  {
    name: 'light',
    urlCode: 'light',
    label: 'Light',
    hex: 'FAF9F8',
  },
] as const;

export const defaultSliceColor = sliceColors[0];

export const customColors = [
  {
    name: 'black',
    urlCode: 'bla',
    label: 'Black',
    hex: '000000',
  },
  {
    name: 'darkBlue',
    urlCode: 'bludrk',
    label: 'Dark Blue',
    hex: '1064A7',
  },
  {
    name: 'green',
    urlCode: 'grn',
    label: 'Green',
    hex: '17723E',
  },
  {
    name: 'darkGreen',
    urlCode: 'grndrk',
    label: 'Dark Green',
    hex: '175431',
  },
  {
    name: 'maroon',
    urlCode: 'mar',
    label: 'Maroon',
    hex: '83194C',
  },
  {
    name: 'orange',
    urlCode: 'ora',
    label: 'Orange',
    hex: 'FA7B1E',
  },
  {
    name: 'red',
    urlCode: 'red',
    label: 'Red',
    hex: 'da3327',
  },
  {
    name: 'darkRed',
    urlCode: 'reddrk',
    label: 'Dark Red',
    hex: '86362B',
  },
  {
    name: 'tan',
    urlCode: 'tan',
    label: 'Tan',
    hex: 'F3E2C4',
  },
  {
    name: 'teal',
    urlCode: 'tea',
    label: 'Teal',
    hex: '1B808D',
  },
  {
    name: 'white',
    urlCode: 'whi',
    label: 'White',
    hex: 'FFFFFF',
  },
  {
    name: 'yellowCustom',
    urlCode: 'yel',
    label: 'Yellow',
    hex: 'FDDB34',
  },
  {
    name: 'darkYellow',
    urlCode: 'yeldrk',
    label: 'Dark Yellow',
    hex: 'FDC831',
  },
] as const;

export const defaultCustomColor = customColors[0];

export const generateCustomizedButtonUrl = (
  utmContentValue: UtmContentTypeOptions,
  shopWebSlug: string,
): string => {
  return `${
    import.meta.env.VITE_STOREFRONT_BASE_URL
  }/restaurants/${shopWebSlug}/menu?utm_campaign=order_now_button&utm_medium=referral&utm_content=${utmContentValue}&utm_source=`;
};

export const generateCustomizedButtonMarkup = (
  utmContentValue: UtmContentTypeOptions,
  shopWebSlug: string,
  customizedImageUrl: string,
): string => {
  return `<a href="${generateCustomizedButtonUrl(
    utmContentValue,
    shopWebSlug,
  )}"><img src="${customizedImageUrl}"/></a>`;
};

export const ButtonTypesAnalyticsStrings: { [key in ButtonType]: string } = {
  [ButtonType.Slice]: 'slice',
  [ButtonType.Custom]: 'unbranded',
} as const;

export const ButtonShapeAnalyticsStrings: { [key in ShapeOption]: string } = {
  [ShapeOption.Pill]: 'pill',
  [ShapeOption.Rounded]: 'rounded',
  [ShapeOption.Square]: 'square',
} as const;

export const ButtonCaseAnalyticsStrings: { [key in LetterCaseOption]: string } =
  {
    [LetterCaseOption.Lower]: 'normal',
    [LetterCaseOption.Upper]: 'uppercase',
  } as const;

export const ButtonSizeAnalyticsStrings: { [key in SizeOption]: string } = {
  [SizeOption.Small]: 'small',
  [SizeOption.Medium]: 'medium',
  [SizeOption.Large]: 'large',
} as const;

// The function below is used to determine if a color is dark or light
export function isColorDark(hex: string): boolean {
  // Convert the hex color to RGB
  const r = parseInt(hex.slice(0, 2), 16);
  const g = parseInt(hex.slice(2, 4), 16);
  const b = parseInt(hex.slice(4, 6), 16);

  // Calculate brightness using a weighted sum of RGB values
  // The weights are based on the luminosity function, approximating human eye sensitivity
  // Red component weighted by 299, green by 587, and blue by 114
  // The result is divided by 1000 to normalize the value between 0 and 1
  // Reference: https://www.w3.org/TR/AERT/#color-contrast
  const brightness = (r * 299 + g * 587 + b * 114) / 1000;

  // Determine if the color is perceived as "dark" based on its brightness
  // The brightness value ranges from 0 to 255, where 0 is the darkest (black) and 255 is the brightest (white)
  // The threshold value of 128 is used - colors with brightness less than 128 are considered "dark"
  // This threshold can be adjusted to customize what is considered a "dark" color
  return brightness < 128;
}
