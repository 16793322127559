import {
  EmailTemplate,
  SaveSmartPopupsAnalyticsParams,
  SaveSmartPopupsRequestAnalytics,
  SmartPopupFormAnalyticsParams,
  SmartPopupFormAnalyticsValues,
  SmartPopupFormValues,
  Theme,
  WebsiteIntegrationColors,
  WebsiteIntegrationsSettings,
} from 'types/website-tools';
import { snakeCaseKeys } from 'utilities/objects';
import {
  getAnalyticsPromoMessageFromForm,
  websiteIntegrationsAnalyticsThemeStrings,
} from 'utilities/website-tools';

import { addDisableExistingSmartButtonsParamsToShopUrl } from './smart-buttons';

const { MODE } = import.meta.env;

export const convertPopupFormValuesToSettings = (
  formValues: SmartPopupFormValues,
): Partial<WebsiteIntegrationsSettings> => {
  return {
    modalTheme: formValues.theme,
    modalThemeColor: formValues.color,
    modalValueMessage: formValues.promoMessage ? formValues.promoMessage : null,
    modalEnabled: true,
  };
};

export const getSmartPopupsUrlParams = (
  shopUrl: string,
  shopUuid: string,
  currentFormValues: SmartPopupFormValues,
): URLSearchParams => {
  // For the url params we need to use snake case keys and also only pass params with valid values
  // meaning non-null and non-undefined values only.
  const convertedFormValues = snakeCaseKeys(
    convertPopupFormValuesToSettings(currentFormValues),
  );

  // Within the form and saving settings, we only deal with 'true' or null for on or off. This is because, although
  // false is needed for the param when we want to override a currently on smart button, sending the string of null
  // currently shows the promo message. This is a bug on the smart buttons side but we feel it will avoid future issues if we
  // try to deal with only true and null, we expect true in the settings response to always show it and
  // null in the settings response to never show it. There may be other string options that enable/disable it in the
  // future but currently all strings turn it on except the string 'false'.
  // See this smart buttons ticket - https://app.shortcut.com/slicelife/story/375357/update-the-value-message-url-parameter-to-only-be-true-if-the-value-is-set-to-true-and-not-any-string

  // Why do we care about smart button parameters here?
  // We need to include the smart button parameters because the smart button
  // configured should be displayed behind the scrim on the smart popups preview.

  if (
    convertedFormValues.floating_button_enabled &&
    convertedFormValues.floating_button_value_message === null
  ) {
    convertedFormValues.floating_button_value_message = 'false';
  }

  if (
    convertedFormValues.nav_bar_enabled &&
    convertedFormValues.nav_bar_value_message === null
  ) {
    convertedFormValues.nav_bar_value_message = 'false';
  }

  if (
    convertedFormValues.modal_enabled &&
    convertedFormValues.modal_value_message === null
  ) {
    convertedFormValues.modal_value_message = 'false';
  }

  const filteredFormValues = Object.fromEntries(
    Object.entries(convertedFormValues).filter(([, v]) => {
      return v !== null && v !== undefined;
    }),
  );

  const params = new URLSearchParams({
    _iframe_src: addDisableExistingSmartButtonsParamsToShopUrl(shopUrl),
    _shop_uuid: shopUuid,
    _smart_buttons_widget_src:
      MODE === 'production'
        ? 'https://restaurant-widgets-integrations.slicelife.com'
        : 'https://owners-portal-feature-branch.restaurant-widgets-integrations.pages.dev',
    ...filteredFormValues,
  });

  // To have the smart buttons backend taget dev rather than QA, we need to pass this additional
  // query param where we pass in things like color and theme for the smart button
  if (MODE === 'development' || MODE === 'staging') {
    params.set('env', 'dev');
  }

  return params;
};

export const smartPopupsDefaultOptions: SmartPopupFormValues = {
  theme: Theme.Simple,
  color: 'red',
  promoMessage: 'true',
};

export const getSmartPopupsDefaultFormValuesFromSettings = (
  smartPopupSettings: WebsiteIntegrationsSettings,
  isPromoMessageAvailable: boolean,
): SmartPopupFormValues => {
  if (!smartPopupSettings.modalEnabled) {
    return {
      ...smartPopupsDefaultOptions,
      promoMessage: isPromoMessageAvailable ? 'true' : '',
    };
  }

  if (smartPopupSettings.modalEnabled) {
    return {
      theme: smartPopupSettings.modalTheme || smartPopupsDefaultOptions.theme,
      color:
        smartPopupSettings.modalThemeColor || smartPopupsDefaultOptions.color,
      promoMessage:
        smartPopupSettings.modalValueMessage ??
        smartPopupsDefaultOptions.promoMessage,
    };
  }

  return {
    ...smartPopupsDefaultOptions,
    promoMessage: isPromoMessageAvailable ? 'true' : '',
  };
};

export const convertSmartPopupFormValuesToSettings = (
  formValues: SmartPopupFormValues,
): Partial<WebsiteIntegrationsSettings> => {
  return {
    modalEnabled: true,
    modalTheme: formValues.theme,
    modalThemeColor: formValues.color,
    modalValueMessage: formValues.promoMessage ? formValues.promoMessage : null,
  };
};

export const getSmartPopupsAnalyticsValuesForForm = ({
  shopId,
  formValues,
  isFirstTimeSetup,
}: SmartPopupFormAnalyticsParams): SmartPopupFormAnalyticsValues => {
  return {
    shopId: String(shopId),
    theme: websiteIntegrationsAnalyticsThemeStrings[formValues.theme],
    color: formValues.color,
    colorHex: WebsiteIntegrationColors[formValues.color],
    promoMessage: getAnalyticsPromoMessageFromForm(formValues.promoMessage),
    previouslyEnabledSettings: !isFirstTimeSetup,
  };
};

export const getSmartPopupsAnalyticsValuesForSaveRequest = ({
  shopId,
  formValues,
  success,
  url,
  isFirstTimeSetup,
  page,
}: SaveSmartPopupsAnalyticsParams): SaveSmartPopupsRequestAnalytics => {
  return {
    ...getSmartPopupsAnalyticsValuesForForm({
      shopId,
      formValues,
      isFirstTimeSetup,
    }),
    url,
    success,
    page,
  };
};

/**
 * A shop is considered a first time user of smart popups if the settings response for
 * modal popup is disabled. This means even if a shop were to have previously saved settings but now
 * currently has popup disabled in admin, we consider them 'new' again.
 */
export const isSmartPopupsFirstTimeSetup = (
  settings: WebsiteIntegrationsSettings,
): boolean => {
  return !settings.modalEnabled;
};

export const getSmartPopupsTechnicalHelpEmailDetails = (
  shopId: number,
): EmailTemplate => {
  const br = '%0D%0A';

  const body = `${br}${br}${br}${br}${br}---${br}RE: Smart Popup Technical Help${br}ShopID#: ${shopId}${br}Source: Owner’s Portal`;
  return {
    toAddress: 'partnersuccess@slicelife.com',
    subject: `RE: Smart Popup Technical Help, ShopID#: ${shopId}`,
    body,
  };
};
