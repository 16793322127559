import { useEffect, useState } from 'react';
import cx from 'classnames';

import { Radio, RadioGroup } from 'crust';

import CopyableCodeBlock from 'components/shared/copyable-code-block';
import Link from 'components/shared/link';
import RadioToggleButton from 'components/shared/radio-toggle-button';
import useAnalytics from 'hooks/use-analytics';
import * as paths from 'routes/paths';
import { Shop } from 'types/shops';
import {
  AvailableColor,
  ButtonConfigTouchedStatus,
  ButtonType,
  LetterCaseOption,
  ShapeOption,
  SizeOption,
  UtmContentTypeOptions,
} from 'types/slice-ordering';
import {
  customPartnerButtonImage,
  partnerButtonImage,
} from 'utilities/image-urls';
import {
  availableLetterCaseOptions,
  availableShapeOptions,
  availableSizeOptions,
  ButtonCaseAnalyticsStrings,
  ButtonShapeAnalyticsStrings,
  ButtonSizeAnalyticsStrings,
  ButtonTypesAnalyticsStrings,
  defaultCustomColor,
  defaultSliceColor,
  generateCustomizedButtonMarkup,
} from 'utilities/slice-ordering/slice-ordering';

import ColorOption from '../color-option';
import ImagePreview from '../image-preview';

import styles from './styles.module.scss';

type Props = {
  shopId: Shop['shopId'];
  shopWebSlug: Shop['webSlug'];
};

const ButtonConfiguration = ({ shopId, shopWebSlug }: Props) => {
  const {
    trackClickedPartnerButtonType,
    trackClickedCopyPartnerButtonHtml,
    trackCustomizedButtonProperties,
    trackClickedClassicButtonsLinkToSmartButtons,
  } = useAnalytics();

  const [selectedButtonType, setSelectedButtonType] = useState<ButtonType>(
    ButtonType.Custom,
  );
  const [selectedColor, setSelectedColor] =
    useState<AvailableColor>(defaultCustomColor);
  const [selectedSize, setSelectedSize] = useState<SizeOption>(
    SizeOption.Small,
  );
  const [selectedShape, setSelectedShape] = useState<ShapeOption>(
    ShapeOption.Rounded,
  );
  const [selectedLetterCasing, setSelectedLetterCasing] =
    useState<LetterCaseOption>(LetterCaseOption.Upper);
  const [touchedState, setTouchedState] = useState<ButtonConfigTouchedStatus>({
    shopId: String(shopId),
    type: ButtonTypesAnalyticsStrings[selectedButtonType],
    color: false,
    size: false,
    shape: false,
    letterCase: false,
  });

  useEffect(() => {
    if (
      touchedState.color ||
      touchedState.size ||
      touchedState.shape ||
      touchedState.letterCase
    ) {
      trackCustomizedButtonProperties(touchedState);
    }
  }, [trackCustomizedButtonProperties, touchedState]);

  const resetSelections = (selectedButtonType: ButtonType) => {
    setSelectedButtonType(selectedButtonType);
    setSelectedColor(
      selectedButtonType === ButtonType.Custom
        ? defaultCustomColor
        : defaultSliceColor,
    );
    setSelectedSize(SizeOption.Small);
    setSelectedShape(ShapeOption.Rounded);
    setSelectedLetterCasing(LetterCaseOption.Upper);
    setTouchedState({
      shopId: String(shopId),
      type: ButtonTypesAnalyticsStrings[selectedButtonType],
      color: false,
      size: false,
      shape: false,
      letterCase: false,
    });
  };

  const convertSizeToCustom = () => {
    if (selectedSize === SizeOption.Small) {
      return 'sm';
    }

    return 'reg';
  };

  const customizedImageUrl =
    selectedButtonType === ButtonType.Custom
      ? customPartnerButtonImage.url(
          convertSizeToCustom(),
          selectedColor.urlCode,
          selectedShape,
          selectedLetterCasing,
        )
      : partnerButtonImage.url(selectedSize, selectedColor.urlCode);
  const customizedImageAlt =
    selectedButtonType === ButtonType.Custom
      ? customPartnerButtonImage.alt(
          convertSizeToCustom(),
          selectedColor.urlCode,
          selectedShape,
          selectedLetterCasing,
        )
      : partnerButtonImage.alt(selectedSize, selectedColor.urlCode);

  const customizedButtonCode = generateCustomizedButtonMarkup(
    selectedButtonType === ButtonType.Custom
      ? UtmContentTypeOptions.Custom
      : UtmContentTypeOptions.Slice,
    shopWebSlug ?? '',
    customizedImageUrl,
  );

  const handleButtonTypeClick = (selectedButtonType: ButtonType) => {
    trackClickedPartnerButtonType({
      shopId: String(shopId),
      type: ButtonTypesAnalyticsStrings[selectedButtonType],
    });

    resetSelections(selectedButtonType);
  };

  const handleColorClick = (selectedColor: AvailableColor) => {
    setSelectedColor(selectedColor);
    setTouchedState({
      ...touchedState,
      color: true,
    });
  };

  const handleSizeOptionChange = (selectedSize: SizeOption) => {
    setSelectedSize(selectedSize);
    setTouchedState({
      ...touchedState,
      size: true,
    });
  };

  const handleShapeOptionChange = (selectedShape: string) => {
    setSelectedShape(selectedShape as ShapeOption);
    setTouchedState({
      ...touchedState,
      shape: true,
    });
  };

  const handleCaseOptionChange = (selectedCase: string) => {
    setSelectedLetterCasing(selectedCase as LetterCaseOption);
    setTouchedState({
      ...touchedState,
      letterCase: true,
    });
  };

  const handleCopyHtmlClick = () => {
    trackClickedCopyPartnerButtonHtml({
      shopId: String(shopId),
      type: ButtonTypesAnalyticsStrings[selectedButtonType],
      color: selectedColor.label.toLowerCase(),
      hex: selectedColor.hex,
      size: ButtonSizeAnalyticsStrings[selectedSize],
      shape:
        selectedButtonType === ButtonType.Custom
          ? ButtonShapeAnalyticsStrings[selectedShape]
          : undefined,
      letterCase:
        selectedButtonType === ButtonType.Custom
          ? ButtonCaseAnalyticsStrings[selectedLetterCasing]
          : undefined,
    });
  };

  // For custom buttons we only show small and large options
  const sizeOptions =
    selectedButtonType === ButtonType.Custom
      ? availableSizeOptions.filter(
          (option) => option.value !== SizeOption.Medium,
        )
      : availableSizeOptions;

  return (
    <>
      <div className={styles.tabSectionHeader}>
        <h3>Adding online ordering is easier than ever.</h3>
        <div>
          Design an integration that fits your brand. Our Classic Buttons pair
          great with the new{' '}
          <Link
            className={styles.infoLink}
            to={paths.shopSmartButtons(shopId)}
            onClick={() => {
              trackClickedClassicButtonsLinkToSmartButtons({
                shopId: String(shopId),
                copy: 'Smart Buttons',
                fromPage: 'Classic Buttons',
                toPage: 'Smart Buttons',
              });
            }}
          >
            Smart Buttons.
          </Link>
        </div>
      </div>
      <div className={styles.customButtonConfig}>
        <div className={styles.configAndPreview}>
          <div className={cx(styles.config, styles.subContainer)}>
            <div className={styles.configButtonType}>
              <RadioGroup
                label="1. Choose type"
                onChange={(value) => handleButtonTypeClick(value as ButtonType)}
                value={selectedButtonType}
              >
                <Radio
                  description="Pick your color, size, and more"
                  label="Customized"
                  value={ButtonType.Custom}
                />
                <Radio
                  description="Includes our logo and colors"
                  label="Slice-branded"
                  value={ButtonType.Slice}
                />
              </RadioGroup>
            </div>
            <div className={styles.configButtonCustomize}>
              <h4 className={styles.sectionHeading}>
                2. Select design elements
              </h4>
              <ColorOption
                id="partner-button-slice-button"
                isCustomSelected={selectedButtonType === ButtonType.Custom}
                onChange={handleColorClick}
                selectedColor={selectedColor}
              />
              <RadioToggleButton
                className={styles.optionButtonsContainer}
                id="slice-branded-button-size-options"
                name="partner-button-slice-button-size-option"
                onChange={(selectedSize: string) =>
                  handleSizeOptionChange(selectedSize as SizeOption)
                }
                selectedValue={selectedSize}
                toggleOptions={sizeOptions}
              />
              {selectedButtonType === ButtonType.Custom && (
                <>
                  <RadioToggleButton
                    className={styles.optionButtonsContainer}
                    id="custom-button-shape-options"
                    name="partner-button-custom-button-shape-option"
                    onChange={handleShapeOptionChange}
                    selectedValue={selectedShape}
                    toggleOptions={availableShapeOptions}
                  />
                  <RadioToggleButton
                    className={styles.optionButtonsContainer}
                    id="custom-button-case-options"
                    name="partner-button-custom-button-case-option"
                    onChange={handleCaseOptionChange}
                    selectedValue={selectedLetterCasing}
                    toggleOptions={availableLetterCaseOptions}
                  />
                </>
              )}
            </div>
          </div>
          <ImagePreview
            className={cx(styles.subContainer, styles.preview)}
            customizedImageUrl={customizedImageUrl}
            customizedImageAlt={customizedImageAlt}
            header="3. Preview button"
          />
        </div>
        <CopyableCodeBlock
          className={styles.codeBlock}
          code={customizedButtonCode ?? ''}
          copyButtonText="Copy Code"
          heading="4. Get your unique HTML code"
          onCopyClick={handleCopyHtmlClick}
          subHeading="Pro Tip: Add your button to the top of your website, where customers can easily see it and order! "
        />
      </div>
    </>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default ButtonConfiguration;
