import { useState } from 'react';

import Button, { ButtonVariant } from 'components/shared/slice-button';
import useAnalytics from 'hooks/use-analytics';
import CaretIcon from 'images/caret.svg?react';
import {
  CampaignManagerPlatformsInfo,
  CampaignManagerState,
} from 'types/campaign-manager';
import { Shop } from 'types/shops';

import Insight from './insight';

import styles from './styles.module.scss';

type Props = {
  campaignState: CampaignManagerState;
  insights: React.ReactNode[];
  platformsInfo?: CampaignManagerPlatformsInfo;
  shopId: Shop['shopId'];
};

const Insights = ({
  campaignState,
  insights,
  platformsInfo,
  shopId,
}: Props) => {
  const { trackClickedCampaignManagerInsights } = useAnalytics();

  const [isOpen, setIsOpen] = useState(false);

  if (!platformsInfo || insights.length === 0) {
    return <></>;
  }

  return (
    <>
      <Button
        aria-controls="insights"
        aria-expanded={isOpen}
        className={styles.button}
        data-testid="insights-button"
        onClick={() => {
          setIsOpen(!isOpen);
          trackClickedCampaignManagerInsights({
            shopId: String(shopId),
            state: campaignState,
          });
        }}
        variant={ButtonVariant.Transparent}
      >
        Insights
        <CaretIcon className={isOpen ? styles.caretUp : styles.caretDown} />
      </Button>
      <ul aria-hidden={!isOpen} id="insights" className={styles.container}>
        {insights.map((insight, index) => (
          <Insight key={index}>{insight}</Insight>
        ))}
      </ul>
    </>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default Insights;
