import * as React from "react";
import { forwardRef } from "react";
const SvgLockIcon = ({
  title,
  titleId,
  ...props
}, ref) => {
  return /* @__PURE__ */ React.createElement("svg", { width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", "aria-hidden": "true", ref, "aria-labelledby": titleId, ...props }, title === void 0 ? /* @__PURE__ */ React.createElement("title", { id: titleId }, "Lock Icon") : title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { d: "M18.8842 9.76845H17.621L17.6211 6.06314C17.6211 3.83161 15.7896 2 13.558 2H10.9474C8.71591 2 6.88431 3.83153 6.88431 6.06314V9.78947H5.62108C4.7159 9.78947 4 10.5264 4 11.4105V20.3789C4 21.2841 4.73691 22 5.62108 22H18.8842C19.7893 22 20.5052 21.2631 20.5052 20.3789L20.5051 11.4105C20.5261 10.5052 19.7894 9.76845 18.884 9.76845H18.8842ZM9.78952 6.06314C9.78952 5.43152 10.3158 4.90521 10.9474 4.90521H13.579C14.2106 4.90521 14.7369 5.43152 14.7369 6.06314V9.78947H9.78959L9.78952 6.06314Z", fill: "currentColor" }));
};
const ForwardRef = forwardRef(SvgLockIcon);
export default ForwardRef;
