import { Menu, MenuButton, MenuItem } from '@szhsin/react-menu';
import cx from 'classnames';

import CaretIcon from 'images/caret.svg?react';

import styles from './styles.module.scss';

type Props = {
  perPage: number;
  setPerPage: (perPage: number) => void;
};

const PerPageMenu = ({ perPage, setPerPage }: Props) => {
  return (
    <Menu
      menuButton={({ open }) => (
        <MenuButton className={styles.menuButton}>
          <div className={styles.menuButtonText}>
            <span className={styles.showText}>Show:</span>
            <span
              className={styles.currentPerPageText}
            >{` ${perPage} Rows`}</span>
          </div>
          <CaretIcon
            className={cx(styles.caretIcon, open && styles.caretIconOpen)}
          />
        </MenuButton>
      )}
    >
      <MenuItem onClick={() => setPerPage(5)}>5 Rows</MenuItem>
      <MenuItem onClick={() => setPerPage(10)}>10 Rows</MenuItem>
      <MenuItem onClick={() => setPerPage(20)}>20 Rows</MenuItem>
    </Menu>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default PerPageMenu;
