import { useQuery, UseQueryResult } from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import {
  formatClosingReasonsForPause,
  formatClosingReasonsForSelect,
} from 'utilities/closings';

export enum ClosingScope {
  Pause = 'pause',
  Closing = 'closing',
}

// The return type of the hook depends on the given scope. Until the utility
// file is converted to TS and the types can be cleaned up, the conditional
// types will be specified here.
export type SelectClosingReason = {
  id: number;
  label: string;
  value: number;
};

export type PauseClosingReason = {
  id: number;
  label: string;
};

type ReasonsType<S extends ClosingScope> = S extends ClosingScope.Closing
  ? SelectClosingReason[]
  : PauseClosingReason[];

export const useClosingReasons = <S extends ClosingScope>(
  scope: S,
): UseQueryResult<ReasonsType<S>> => {
  const api = useApi();

  return useQuery([`closingReasons-${scope}`], async () => {
    const closingReasons = await api.getClosingReasons(scope);

    if (scope === ClosingScope.Pause) {
      return formatClosingReasonsForPause(closingReasons);
    }

    return formatClosingReasonsForSelect(closingReasons);
  });
};
