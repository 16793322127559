import { Control, useWatch } from 'react-hook-form';

import { PromoCodeFormValues, PromoCodeType } from 'types/discounts';
import { toMoneyString } from 'utilities/currency';

type Props = {
  control: Control<PromoCodeFormValues>;
};

export const TypeFieldsSummary = ({ control }: Props) => {
  const type = useWatch({
    control,
    name: 'type',
  });

  const amount = useWatch({
    control,
    name: 'amount',
  });

  let content = null;

  if (amount && type) {
    content =
      type === PromoCodeType.Flat ? toMoneyString(amount) : `${amount}%`;
  }

  return <>{content}</>;
};
