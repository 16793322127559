import { VALID_VALIDITY_STATE } from '@react-stately/form';

export type FileCriteria = {
  /** A list of accepted file mime types. */
  acceptedFileTypes?: string[];
  /** The maximum allowed file size in bytes. */
  maxFileSize?: number;
};

// Adapted from
// https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#examples.
export const formatFileSize = (size: number) => {
  if (size >= 1048576) {
    return `${(size / 1048576).toFixed(2)} MB`;
  }

  if (size >= 1024) {
    return `${(size / 1024).toFixed(2)} KB`;
  }

  return `${size} B`;
};

const formatFileType = (mime: string) =>
  mime.split('/').at(-1)?.toUpperCase() ?? mime;

const validateFileTypes = (
  files: File[],
  acceptedFileTypes?: FileCriteria['acceptedFileTypes'],
) => {
  if (acceptedFileTypes) {
    const types = new Set(acceptedFileTypes);

    const hasUnacceptedFileType = !files.every((it) => {
      // CSVs on windows machines with excel installed may come through as excel specific mimetypes
      // we will want to handle that case by saying if we have supplied an accepted file type of text/csv
      // we also accept the excel mimetype, if the file ends in csv.
      if (it.type === 'application/vnd.ms-excel' && types.has('text/csv')) {
        const extension = it.name.split('.').at(-1)?.toLowerCase();
        return extension === 'csv';
      }

      return types.has(it.type);
    });

    if (hasUnacceptedFileType) {
      const target = acceptedFileTypes
        .map((it, idx, array) => {
          let result = formatFileType(it);

          if (idx > 0 && idx === array.length - 1) {
            result += 'or ';
          }

          return result;
        })
        .join(', ');

      return `The selected file must be a ${target}.`;
    }
  }

  return true;
};

const validateFileSizes = (
  files: File[],
  maxFileSize?: FileCriteria['maxFileSize'],
) => {
  if (maxFileSize) {
    const match = files.filter((it) => it.size > maxFileSize);

    if (match.length) {
      const limit = formatFileSize(maxFileSize);
      return `The selected file must be smaller than ${limit}.`;
    }
  }

  return true;
};

export const getFilesValidation = (
  files: File[],
  { acceptedFileTypes, maxFileSize }: FileCriteria = {},
) => {
  const errors = [
    validateFileTypes(files, acceptedFileTypes),
    validateFileSizes(files, maxFileSize),
  ].filter((it) => typeof it === 'string') as string[];

  const isInvalid = errors.length > 0;

  return {
    isInvalid,
    validationErrors: errors,
    validationDetails: {
      ...VALID_VALIDITY_STATE,
      customError: isInvalid,
      valid: !isInvalid,
    },
  };
};
