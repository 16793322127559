import * as React from "react";
import { forwardRef } from "react";
const SvgShareIcon = ({
  title,
  titleId,
  ...props
}, ref) => {
  return /* @__PURE__ */ React.createElement("svg", { width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", "aria-hidden": "true", ref, "aria-labelledby": titleId, ...props }, title === void 0 ? /* @__PURE__ */ React.createElement("title", { id: titleId }, "Share Icon") : title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { d: "M8.20001 6.4C7.90142 6.79812 7.94101 7.35521 8.2929 7.70711C8.90229 8.31288 9.73267 8.65162 10.5579 8.84096L11.0029 15.0713H12.9978L13.4429 8.84079C14.2545 8.65446 15.1083 8.30494 15.7071 7.70711C16.059 7.35522 16.0986 6.79812 15.8 6.4L12.8 2.4C12.6112 2.14819 12.3148 2 12 2C11.6853 2 11.3889 2.14819 11.2 2.4L8.20001 6.4Z", fill: "currentColor" }), /* @__PURE__ */ React.createElement("path", { d: "M4.99128 8.86487L3.00391 9.08303L3.85108 19.2491C3.98065 20.804 5.28045 22 6.84072 22H17.1602C18.7205 22 20.0203 20.804 20.1498 19.2491L20.997 9.08303L19.0096 8.86487L17.6276 19H6.37334L4.99128 8.86487Z", fill: "currentColor" }));
};
const ForwardRef = forwardRef(SvgShareIcon);
export default ForwardRef;
