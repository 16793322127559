import { toast } from 'react-toastify';

export const copyTextToClipboard = (text, showToast) => {
  // execCommand is marked as deprecated. To ease switchover to using the recommended navigator API
  // we check to see if the users browser has this API avalable and fallback to execCommand if needed
  if (navigator?.clipboard) {
    navigator.clipboard.writeText(text);
  } else {
    const dummyElement = document.createElement('textarea');
    document.body.appendChild(dummyElement);
    dummyElement.value = text;
    dummyElement.select();
    document.execCommand('copy');
    document.body.removeChild(dummyElement);
  }

  if (showToast) {
    toast.success('Code copied successfully!');
  }
};

export const generateShopMenuLink = (webSlug) =>
  `${import.meta.env.VITE_STOREFRONT_BASE_URL}/restaurants/${webSlug}/menu`;

export const calculatePercentDelta = (currentValue, previousValue) => {
  if (!previousValue) {
    return 0;
  }

  return ((currentValue - previousValue) / previousValue) * 100;
};
