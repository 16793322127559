import { useEffect, useMemo, useState } from 'react';
import {
  CalendarDate,
  DateValue,
  now,
  parseAbsolute,
  parseDate,
  today,
} from '@internationalized/date';
import { z } from 'zod';

import {
  PresetDateRange,
  PresetRangePicker,
  PresetRangeState,
  PresetRangeValue,
  useDateFormatter,
} from 'crust';

import { Feedback } from 'components/shared/feedback';
import MetricTile, {
  MetricTileContent,
  MetricTileHeader,
  MetricTileValue,
} from 'components/shared/metric-tiles/metric-tile';
import { Shop } from 'types/shops';
import { isFromLast24Hours } from 'utilities/date-time';
import { getPhoneDateRangePresets } from 'utilities/shared/date-range-presets';

import styles from './styles.module.scss';

const localStorageId = 'phone';

const getStoredValue = () => {
  const item = localStorage.getItem(localStorageId);

  if (item) {
    try {
      return JSON.parse(item);
    } catch (error) {
      // Do nothing.
    }
  }
};

const setStoredValue = (dates: unknown) => {
  try {
    localStorage.setItem(
      localStorageId,
      JSON.stringify({
        lastUpdatedAt: now('UTC').toAbsoluteString(),
        dates,
      }),
    );
  } catch {
    // Do nothing.
  }
};

type SchemaContext = {
  maxValue: CalendarDate;
  presets: ReturnType<typeof getPhoneDateRangePresets>;
};

const getSchema = ({ maxValue, presets }: SchemaContext) => {
  const date = z
    .string()
    .date()
    .transform((it) => parseDate(it));
  return z.object({
    lastUpdatedAt: z
      .string()
      .datetime()
      .transform((it) => parseAbsolute(it, 'UTC'))
      .refine((it) => isFromLast24Hours(it)),
    dates: z
      .object({
        id: z.string(),
        label: z.string(),
        start: date,
        end: date,
      })
      .transform((value, ctx) => {
        const id = value.id;
        const preset = presets.find((it) => it.id === id);

        if (preset) {
          return preset;
        }

        if (
          value.end.compare(value.start) >= 0 &&
          value.end.compare(value.start) < 32 &&
          value.end.compare(maxValue) <= 0
        ) {
          return {
            id: 'custom',
            label: 'Custom',
            start: value.start,
            end: value.end,
          } as const;
        }

        // The message is unimportant here.
        ctx.addIssue({ code: z.ZodIssueCode.custom });

        return z.NEVER;
      }),
  });
};

type DateRangeFilterProps<T extends DateValue, S extends string> = {
  defaultValue: PresetRangeValue<T, S>;
  maxValue: CalendarDate;
  onChange: (value: PresetRangeValue<T, S>) => void;
  presets: readonly PresetDateRange<T, S>[];
  shopId: string;
  shopTimezone: string;
};

const DateRangeFilter = <T extends DateValue, S extends string>({
  defaultValue,
  maxValue,
  onChange,
  presets,
  shopId,
  shopTimezone,
}: DateRangeFilterProps<T, S>) => {
  const [dates, setDates] =
    useState<PresetRangeState<typeof presets>>(defaultValue);

  const dateFormatter = useDateFormatter({
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    timeZone: shopTimezone,
  });

  const error = useMemo(() => {
    if (dates == null) {
      return 'Please choose start and end dates.';
    }

    if (dates.end.compare(maxValue) > 0) {
      const formatted = dateFormatter.format(maxValue.toDate(shopTimezone));
      return `Please choose an end date of ${formatted} or earlier.`;
    }

    if (dates.end.compare(dates.start) < 0) {
      return 'Please choose an end date that is after the start date.';
    }
  }, [dateFormatter, dates, maxValue, shopTimezone]);

  useEffect(() => {
    if (dates != null && error == null) {
      onChange(dates);
    }
  }, [dates, error, onChange, shopId]);

  return (
    <PresetRangePicker
      aria-label="phone date range"
      error={error}
      isInvalid={error != null}
      maxValue={maxValue}
      onChange={setDates}
      presets={presets}
      validationBehavior="aria"
      value={dates}
    />
  );
};

const getDateContext = (timezone: string) =>
  ({
    timezone,
    maxValue: today(timezone),
    presets: getPhoneDateRangePresets(timezone),
  }) as const;

type Props = {
  shop: Shop;
};

export const Phone = ({ shop }: Props) => {
  const [dateContext, setDateContext] = useState(() =>
    getDateContext(shop.timezoneIdentifier),
  );

  useEffect(() => {
    if (shop.timezoneIdentifier !== dateContext.timezone) {
      setDateContext(getDateContext(shop.timezoneIdentifier));
    }
  }, [dateContext.timezone, shop.timezoneIdentifier]);

  const { presets, maxValue } = dateContext;

  const [dates, setDates] = useState<
    NonNullable<PresetRangeState<typeof presets>>
  >(() => {
    const stored = getStoredValue();

    if (stored) {
      const schema = getSchema({ maxValue, presets });
      const result = schema.safeParse(stored);
      if (result.success) {
        return result.data.dates;
      }
    }

    return presets[2];
  });

  useEffect(() => {
    setStoredValue({
      ...dates,
      start: dates.start.toString(),
      end: dates.end.toString(),
    });
  }, [dates]);

  return (
    <>
      <div className={styles.container}>
        <DateRangeFilter
          defaultValue={dates}
          maxValue={maxValue}
          onChange={setDates}
          presets={presets}
          shopId={shop.shopId.toString()}
          shopTimezone={shop.timezoneIdentifier}
        />
        <Feedback
          contentDescription="phone sales dashboard"
          page="phone"
          shopId={shop.shopId}
          storageKey="phone_page_feedback"
          tile="phone sales"
        />
      </div>
      <div className={styles.metricsContainer}>
        <MetricTile className={styles.metricTile}>
          <MetricTileContent>
            <MetricTileHeader headerClassName={styles.metricHeader}>
              Calls Answered
            </MetricTileHeader>
            <MetricTileValue className={styles.metricValue} value={0} />
          </MetricTileContent>
        </MetricTile>
        <MetricTile className={styles.metricTile}>
          <MetricTileContent>
            <MetricTileHeader headerClassName={styles.metricHeader}>
              Phone Orders
            </MetricTileHeader>
            <MetricTileValue className={styles.metricValue} value={0} />
          </MetricTileContent>
        </MetricTile>
        <MetricTile className={styles.metricTile}>
          <MetricTileContent>
            <MetricTileHeader headerClassName={styles.metricHeader}>
              Time Saved
            </MetricTileHeader>
            <MetricTileValue
              className={styles.metricValue}
              type="hours"
              value={0}
            />
          </MetricTileContent>
        </MetricTile>
      </div>
    </>
  );
};
