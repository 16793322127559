import { useMemo } from 'react';

import { OpenForToday } from 'types/open-for-today';
import * as openForTodayUtilities from 'utilities/open-for-today';

export type OpenForTodaySelectors = {
  isAcceptingOrders: boolean;
  isAcceptingDeliveryOrders: boolean;
  isAcceptingPickupOrders: boolean;
  isAcceptingDeliveryAndPickupOrders: boolean;
  isClosedForDelivery: boolean;
  isClosedForPickup: boolean;
  isPausedForDeliveryAndPickupOrders: boolean;
  isPausedForDeliveryOrders: boolean;
  isPausedForPickupOrders: boolean;
  isRegularScheduleDeliveryOpen: boolean;
  isRegularScheduleOpen: boolean;
  isRegularSchedulePickupOpen: boolean;
  nextOpenAt: string;
  nextOpenForDeliveryAt: string;
  nextOpenForPickupAt: string;
  openForShippingType: string;
  unpausedAt: string;
};

export const useOpenForTodaySelectors = (
  shopOpenForTodayData: OpenForToday,
  isShopOpenForTodayLoading: boolean,
): OpenForTodaySelectors => {
  return useMemo(() => {
    let openForTodaySelects: OpenForTodaySelectors = {
      isAcceptingOrders: true,
      isAcceptingDeliveryOrders: true,
      isAcceptingPickupOrders: true,
      isAcceptingDeliveryAndPickupOrders: true,
      isClosedForDelivery: false,
      isClosedForPickup: false,
      isPausedForDeliveryAndPickupOrders: false,
      isPausedForDeliveryOrders: false,
      isPausedForPickupOrders: false,
      isRegularScheduleDeliveryOpen: true,
      isRegularScheduleOpen: true,
      isRegularSchedulePickupOpen: true,
      nextOpenAt: '',
      nextOpenForDeliveryAt: '',
      nextOpenForPickupAt: '',
      openForShippingType: 'delivery_and_pickup',
      unpausedAt: '',
    };

    if (!shopOpenForTodayData || isShopOpenForTodayLoading) {
      return openForTodaySelects;
    }

    openForTodaySelects = {
      isAcceptingOrders:
        openForTodayUtilities.isAcceptingOrders(shopOpenForTodayData),
      isAcceptingDeliveryOrders:
        openForTodayUtilities.isAcceptingDeliveryOrders(shopOpenForTodayData),
      isAcceptingPickupOrders:
        openForTodayUtilities.isAcceptingPickupOrders(shopOpenForTodayData),
      isAcceptingDeliveryAndPickupOrders:
        openForTodayUtilities.isAcceptingDeliveryAndPickupOrders(
          shopOpenForTodayData,
        ),
      isClosedForDelivery:
        openForTodayUtilities.isClosedForDelivery(shopOpenForTodayData),
      isClosedForPickup:
        openForTodayUtilities.isClosedForPickup(shopOpenForTodayData),
      isRegularScheduleDeliveryOpen:
        openForTodayUtilities.isRegularScheduleDeliveryOpen(
          shopOpenForTodayData,
        ),
      isPausedForDeliveryAndPickupOrders:
        openForTodayUtilities.isPausedForDeliveryAndPickupOrders(
          shopOpenForTodayData,
        ),
      isPausedForDeliveryOrders:
        openForTodayUtilities.isPausedForDeliveryOrders(shopOpenForTodayData),
      isPausedForPickupOrders:
        openForTodayUtilities.isPausedForPickupOrders(shopOpenForTodayData),
      isRegularScheduleOpen:
        openForTodayUtilities.isRegularScheduleOpen(shopOpenForTodayData),
      isRegularSchedulePickupOpen:
        openForTodayUtilities.isRegularSchedulePickupOpen(shopOpenForTodayData),
      nextOpenAt: openForTodayUtilities.nextOpenAt(shopOpenForTodayData),
      nextOpenForDeliveryAt:
        openForTodayUtilities.nextOpenForDeliveryAt(shopOpenForTodayData),
      nextOpenForPickupAt:
        openForTodayUtilities.nextOpenForPickupAt(shopOpenForTodayData),
      openForShippingType:
        openForTodayUtilities.openForShippingType(shopOpenForTodayData),
      unpausedAt: openForTodayUtilities.unpausedAt(shopOpenForTodayData),
    };

    return openForTodaySelects;
  }, [shopOpenForTodayData, isShopOpenForTodayLoading]);
};
