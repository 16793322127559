import { QueryKey, useQuery } from '@tanstack/react-query';
import { z } from 'zod';

import useApi from 'hooks/use-api';
import { camelCaseKeys } from 'utilities/objects';

import { businessProfileSchema } from './schemas';
import { useHandleGoogleReauthError } from './use-handle-google-reauth-error';

const schema = z.object({
  businessProfile: businessProfileSchema,
});

export type ResponseGoogleBusinessProfile = z.infer<typeof schema>;

export const getQueryKey = (shopId: number): QueryKey => [
  shopId,
  'businessProfile',
];

export const useBusinessProfileQuery = (shopId: number) => {
  const { authenticatedFetch } = useApi();
  const handleGoogleReauthError = useHandleGoogleReauthError();

  return useQuery({
    queryKey: getQueryKey(shopId),
    queryFn: async () => {
      try {
        const response = await authenticatedFetch.get(
          `api/management/v1/shops/${shopId}/business_profile`,
        );
        return schema.parse(camelCaseKeys(response));
      } catch (error) {
        if (error instanceof z.ZodError) {
          console.error('Error parsing Get Business Profile response', error);
        }

        handleGoogleReauthError(shopId, error);

        throw error;
      }
    },
  });
};
