import {
  ChangeEvent,
  ComponentPropsWithoutRef,
  useEffect,
  useState,
} from 'react';
import cx from 'classnames';

import { Icon } from 'crust';

import Input from 'components/shared/input';
import useDebounce from 'hooks/use-debounce';

import styles from './styles.module.scss';

type Props = {
  delay?: number;
  iconClassName?: string;
  initialSearchValue?: string;
  inputClassName?: string;
  onChange: (value: string) => void;
  placeholderText?: string;
} & Omit<ComponentPropsWithoutRef<'div'>, 'onChange'>;

export const SearchBar = ({
  className,
  delay = 1000,
  iconClassName = '',
  initialSearchValue = '',
  inputClassName = '',
  onChange,
  placeholderText = 'Search',
  ...divProps
}: Props) => {
  const [inputValue, setInputValue] = useState(initialSearchValue);
  const debouncedValue = useDebounce(inputValue, delay);

  const handleSearchUpdate = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  useEffect(() => {
    onChange(debouncedValue);
  }, [debouncedValue, onChange]);

  return (
    <div className={cx(styles.searchBar, className)} {...divProps}>
      <Icon
        icon="magnifyingGlass"
        className={cx(styles.searchIcon, iconClassName)}
      />
      <Input
        aria-label={placeholderText}
        className={cx(styles.searchInput, inputClassName)}
        onChange={handleSearchUpdate}
        placeholder={placeholderText}
        type="search"
        value={inputValue}
      />
    </div>
  );
};
