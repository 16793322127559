import { Control, useController } from 'react-hook-form';
import cx from 'classnames';

import { SelectMenuOption } from 'components/website-tools/form/select-menu-option';
import useAnalytics from 'hooks/use-analytics';
import { ShopTraits } from 'types/shops';
import {
  SmartPopupFormValues,
  Theme,
  WebsiteIntegrationsAnalyticsPageName,
} from 'types/website-tools';
import {
  themeOptionLabels,
  websiteIntegrationsAnalyticsThemeStrings,
} from 'utilities/website-tools';

import SelectMenu from '../../../form/select-menu';

import styles from './styles.module.scss';

type Props = {
  buttonClassName?: string;
  control: Control<SmartPopupFormValues>;
  setIsFormChanged: (isFormChanged: boolean) => void;
  shopId: number;
  shopTraits: ShopTraits;
  page: WebsiteIntegrationsAnalyticsPageName;
};

const ThemeDropdown = ({
  buttonClassName,
  control,
  setIsFormChanged,
  shopId,
  shopTraits,
  page,
}: Props) => {
  const {
    field: { onChange, value },
    fieldState: { isDirty },
  } = useController({
    control,
    name: 'theme',
  });
  const { trackClickedWebsiteIntegrationsEditorDropdown } = useAnalytics();

  const handleChangeTheme = (next: string) => {
    onChange(next);
    setIsFormChanged(true);
  };

  const handleOnBlur = () => {
    trackClickedWebsiteIntegrationsEditorDropdown({
      field: 'theme',
      shopId: String(shopId),
      isActive: String(shopTraits.isSmartButtonActive),
      optionSelected: isDirty
        ? websiteIntegrationsAnalyticsThemeStrings[value]
        : null,
      page,
    });
  };

  return (
    <SelectMenu
      buttonClassName={buttonClassName}
      buttonId="theme-dropdown"
      label={themeOptionLabels[value]}
      onChange={handleChangeTheme}
      onBlur={handleOnBlur}
    >
      {Object.values(Theme).map((option) => {
        const isActive = value === option;
        const fontClassName = cx(
          styles.label,
          option === Theme.Simple && styles.simple,
          option === Theme.Iconic && styles.iconic,
          option === Theme.Elegant && styles.elegant,
        );

        return (
          <SelectMenuOption isActive={isActive} key={option} value={option}>
            <div className={fontClassName}>{themeOptionLabels[option]}</div>
          </SelectMenuOption>
        );
      })}
    </SelectMenu>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default ThemeDropdown;
