import { Link } from 'crust';

import ContentTile from 'components/shared/content-tile';
import { registerPermission } from 'routes/paths/register';
import { RegisterPermissionGroup, RegisterUser } from 'types/register-users';

import RegisterPermissionsTable from './table';

import styles from './styles.module.scss';

type Props = {
  permissionGroups: RegisterPermissionGroup[];
  shopId: string;
  users: RegisterUser[];
};

const RegisterPermissionsTab = ({ permissionGroups, shopId, users }: Props) => {
  return (
    <ContentTile>
      <div className={styles.aboveTableArea}>
        <div className={styles.title}>Register Permissions</div>
        <Link
          appearance="button"
          data-chameleon-target="Create Permission Group"
          href={registerPermission(shopId, 'new')}
          variant="secondary"
        >
          Create
        </Link>
      </div>
      <RegisterPermissionsTable
        permissionGroups={permissionGroups}
        shopId={shopId}
        users={users}
      />
    </ContentTile>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default RegisterPermissionsTab;
