import { Logomark } from 'crust';

import Scribble from 'images/register/register-splash/scribble.svg?react';
import { Shop } from 'types/shops';

import { ScheduleDemoButton } from '../schedule-demo-button';

import styles from './styles.module.scss';

type Props = {
  hasValidCase: boolean;
  onScheduleDemoButtonClick: () => void;
  shopId: Shop['shopId'];
};

export const RegisterSplashFooter = ({
  hasValidCase,
  onScheduleDemoButtonClick,
  shopId,
}: Props) => {
  return (
    <div className={styles.container}>
      <Logomark className={styles.icon} />
      <div>
        <h1 className={styles.heading}>
          STREAMLINE
          <Scribble aria-hidden="true" className={styles.underline} />
        </h1>
        <p className={styles.subheading}>Your Business</p>
      </div>
      {!hasValidCase && (
        <ScheduleDemoButton
          onScheduleDemoButtonClick={onScheduleDemoButtonClick}
          shopId={shopId}
        />
      )}
    </div>
  );
};
