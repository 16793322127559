import { useState } from 'react';

import Button, { ButtonVariant } from 'components/shared/slice-button';
import useAnalytics from 'hooks/use-analytics';
import { AssetLibraryAsset } from 'types/asset-library';

import AssetTile from './asset-tile';

import styles from './styles.module.scss';

type Props = {
  description: string;
  downloadAssetImage: (
    asset: AssetLibraryAsset,
    page: 'Social Media' | 'Asset Modal',
  ) => Promise<void>;
  onClickExpand: (asset: AssetLibraryAsset, serviceName: string) => void;
  serviceName: string;
  assets: AssetLibraryAsset[];
  shopId: string;
};

const SocialMediaService = ({
  description,
  downloadAssetImage,
  onClickExpand,
  assets,
  serviceName,
  shopId,
}: Props) => {
  const { trackClickedLoadMoreDigitalAssets } = useAnalytics();

  const [numLoadMoreClicks, setNumLoadMoreClicks] = useState(0);
  const [numAssetsVisible, setNumAssetsVisible] = useState(3);

  const visibleAssets = assets.slice(0, numAssetsVisible);

  const showMoreAssets = () => {
    trackClickedLoadMoreDigitalAssets({
      shopId: shopId,
      category: 'Social Media',
      subCategory: serviceName,
      timesClicked: numLoadMoreClicks + 1,
    });

    const newNumAssetsVisible = numAssetsVisible + 3;
    setNumAssetsVisible(newNumAssetsVisible);
    setNumLoadMoreClicks(numLoadMoreClicks + 1);
  };

  return (
    <div className={styles.serviceContainer}>
      <div className={styles.serviceName}>{serviceName}</div>
      <div className={styles.description}>{description}</div>
      <div className={styles.postsContainer}>
        {visibleAssets.map((asset: AssetLibraryAsset) => (
          <AssetTile
            key={asset.id}
            downloadAssetImage={downloadAssetImage}
            onClickExpand={() => onClickExpand(asset, serviceName)}
            asset={asset}
          />
        ))}
      </div>
      {visibleAssets.length === assets.length ? null : (
        <div className={styles.buttonContainer}>
          <Button
            className={styles.loadMoreButton}
            variant={ButtonVariant.Tertiary}
            onClick={showMoreAssets}
          >
            Load More {serviceName}
          </Button>
        </div>
      )}
    </div>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default SocialMediaService;
