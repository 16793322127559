import {
  Menu,
  MenuButton,
  MenuDivider,
  MenuHeader,
  MenuItem,
  MenuRadioGroup,
  RadioChangeEvent,
} from '@szhsin/react-menu';
import { ColumnSort } from '@tanstack/react-table';
import cx from 'classnames';

import useAnalytics from 'hooks/use-analytics';
import CaretIcon from 'images/caret.svg?react';
import { CustomerSegmentsTableColumnId, CustomerSort } from 'types/customers';

import { FieldLabels, getDirectionLabel } from '../utilities';

import styles from './styles.module.scss';

type Props = {
  activeSort: ColumnSort;
  columnSort: ColumnSort;
  customerSort: CustomerSort;
  shopId: string;
  setActiveSort: React.Dispatch<React.SetStateAction<ColumnSort>>;
  setCurrentSort: (newColumnSort: ColumnSort) => void;
};

const MobileSortMenu = ({
  activeSort,
  columnSort,
  customerSort,
  setActiveSort,
  setCurrentSort,
  shopId,
}: Props) => {
  const { trackClickedSortByOnMobile } = useAnalytics();

  const handleFieldChange = (event: RadioChangeEvent) => {
    event.keepOpen = true;
    setActiveSort({ ...activeSort, id: event.value });
  };

  const handleDirectionChange = (event: RadioChangeEvent) => {
    event.keepOpen = true;
    setActiveSort({ ...activeSort, desc: event.value });
  };

  const sortId =
    columnSort.id === CustomerSegmentsTableColumnId.OrderCount ||
    columnSort.id === CustomerSegmentsTableColumnId.TotalSpend ||
    columnSort.id === CustomerSegmentsTableColumnId.LastOrderDate ||
    columnSort.id === CustomerSegmentsTableColumnId.AverageOrderValue
      ? columnSort.id
      : CustomerSegmentsTableColumnId.LastOrderDate;

  return (
    <div
      className={styles.container}
      data-testid="customer segments mobile sort menu container"
    >
      <Menu
        menuButton={({ open }) => (
          <MenuButton
            className={cx(
              styles.selectButton,
              open && styles.selectButtonMenuOpen,
            )}
          >
            <span className={styles.selectButtonText}>
              {FieldLabels[sortId]}
            </span>
            <CaretIcon
              aria-hidden="true"
              className={open ? styles.caretUp : styles.caretDown}
            />
          </MenuButton>
        )}
        menuClassName={styles.menu}
        onMenuChange={({ open }) => {
          open && trackClickedSortByOnMobile(shopId, customerSort);
        }}
      >
        <MenuRadioGroup
          value={activeSort.id}
          onRadioChange={(e) => handleFieldChange(e)}
        >
          <MenuHeader className={styles.menuHeader}>Field</MenuHeader>
          <MenuItem
            className={cx(styles.radioButtonLabel, styles.radioButton)}
            type="radio"
            value={CustomerSegmentsTableColumnId.AverageOrderValue}
          >
            Average Order Value
          </MenuItem>
          <MenuItem
            className={cx(styles.radioButtonLabel, styles.radioButton)}
            type="radio"
            value={CustomerSegmentsTableColumnId.TotalSpend}
          >
            Total Spend
          </MenuItem>
          <MenuItem
            className={cx(styles.radioButtonLabel, styles.radioButton)}
            type="radio"
            value={CustomerSegmentsTableColumnId.OrderCount}
          >
            Order Count
          </MenuItem>
          <MenuItem
            className={cx(styles.radioButtonLabel, styles.radioButton)}
            type="radio"
            value={CustomerSegmentsTableColumnId.LastOrderDate}
          >
            Last Order Date
          </MenuItem>
        </MenuRadioGroup>
        <MenuDivider />
        <MenuRadioGroup
          value={activeSort.desc}
          onRadioChange={(e) => handleDirectionChange(e)}
        >
          <MenuHeader className={styles.menuHeader}>Sort By</MenuHeader>
          <MenuItem
            className={cx(styles.radioButtonLabel, styles.radioButton)}
            type="radio"
            value={true}
          >
            {getDirectionLabel(activeSort.id, true)}
          </MenuItem>
          <MenuItem
            className={cx(styles.radioButtonLabel, styles.radioButton)}
            type="radio"
            value={false}
          >
            {getDirectionLabel(activeSort.id, false)}
          </MenuItem>
        </MenuRadioGroup>
        <MenuItem
          className={styles.submitButton}
          onClick={() => setCurrentSort(activeSort)}
        >
          Apply
        </MenuItem>
      </Menu>
    </div>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default MobileSortMenu;
