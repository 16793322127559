import { useEffect, useState } from 'react';

import useAnalytics from 'hooks/use-analytics';
import useStoredState from 'hooks/use-stored-state';
import { useWindowSize } from 'hooks/use-window-size';
import { AnalyticsPageName } from 'providers/analytics/events';

import styles from './styles.module.scss';

const desktopCopy = 'Is this helpful?';
const mobileCopy = 'Helpful?';
const hideFeedbackMilliseconds = 3000;

type FeedbackStorageKey =
  | 'hours_analytics_feedback_last_30_days'
  | 'hours_analytics_feedback_shop_availability'
  | 'hours_analytics_feedback_shop_status_history'
  | 'phone_page_feedback';

export type FeedbackValue = 'thumbs up' | 'thumbs down';

type Props = {
  contentDescription: string;
  page: AnalyticsPageName;
  shopId: number;
  storageKey: FeedbackStorageKey;
  tile: string;
};

export const Feedback = ({
  contentDescription,
  storageKey,
  shopId,
  tile,
  page,
}: Props) => {
  const { isMobile } = useWindowSize();
  const { trackSubmittedProductFeedbackSurvey } = useAnalytics();

  const [storedFeedback, setStoredFeedback] = useStoredState(
    `${shopId}-${storageKey}`,
  );

  const [isFeedbackProvided, setIsFeedbackProvided] = useState(storedFeedback);
  const [shouldHideFeedback, setShouldHideFeedback] = useState(storedFeedback);

  const copy = isMobile ? mobileCopy : desktopCopy;

  useEffect(() => {
    if (isFeedbackProvided) {
      const timeout = setTimeout(() => {
        setShouldHideFeedback(true);
      }, hideFeedbackMilliseconds);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [isFeedbackProvided]);

  const handleClick = (variant: FeedbackValue): void => {
    trackSubmittedProductFeedbackSurvey({
      shopId,
      page,
      tile,
      copy: 'is this helpful?',
      response: variant,
    });

    setStoredFeedback(variant);
    setIsFeedbackProvided(true);
  };

  let content;
  if (shouldHideFeedback) {
    content = <></>;
  } else if (isFeedbackProvided) {
    content = <div className={styles.text}>Thanks for the feedback!</div>;
  } else {
    content = (
      <>
        <div className={styles.text}>{copy}</div>
        <button
          className={styles.button}
          data-testid="thumbs-up-button"
          onClick={() => handleClick('thumbs up')}
          title={`Provide feedback that this ${contentDescription} is helpful.`}
        >
          <span className={styles.icon}>👍</span>
        </button>
        <button
          className={styles.button}
          data-testid="thumbs-down-button"
          onClick={() => handleClick('thumbs down')}
          title={`Provide feedback that this ${contentDescription} is not helpful.`}
        >
          <span className={styles.icon}>👎</span>
        </button>
      </>
    );
  }

  return <div className={styles.container}>{content}</div>;
};
