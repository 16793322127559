import { useMutation } from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { camelCaseKeys } from 'utilities/objects';

export const useSendPasswordResetEmailMutation = () => {
  const { authenticatedFetch } = useApi();

  return useMutation({
    mutationFn: async (email: string) => {
      const response = await authenticatedFetch.post(
        `api/management/v1/password_resets`,
        { email },
        {},
        true,
        false,
        true,
      );
      return camelCaseKeys(response);
    },
  });
};
