import { SubMenu } from '@szhsin/react-menu';
import cx from 'classnames';

import { ProductType } from 'types/shared/stock-dropdown';

import { StockMenuItems } from '../menu-items';
import { getDurationLabel } from '../utilities';

import styles from './styles.module.scss';

type Props = {
  productTypes: ProductType[];
  isProductUnavailable: boolean;
};

const StockSubMenus = ({ productTypes, isProductUnavailable }: Props) => {
  const getLabel = (
    unavailable: boolean,
    unavailableUntil: string | null,
    name: string,
  ) => {
    if (unavailable) {
      const duration = getDurationLabel(unavailable, unavailableUntil);

      return (
        <div>
          {`${name} (Out of Stock)`}
          <div className={styles.duration}>{duration}</div>
        </div>
      );
    }

    return `${name} (In Stock)`;
  };

  return (
    <>
      {productTypes.map((it) => (
        <SubMenu
          key={it.id}
          itemProps={{
            className: cx(it.unavailable && styles.unavailable),
          }}
          disabled={isProductUnavailable}
          label={getLabel(it.unavailable, it.unavailableUntil, it.name)}
        >
          <StockMenuItems entity={it} />
        </SubMenu>
      ))}
    </>
  );
};

export { StockSubMenus };
