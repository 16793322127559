export type MenuCategoryAvailability = {
  dayOfWeek: string;
  endTime: string;
  id: number;
  startTime: string;
};

export type MenuShippingType = 'delivery' | 'pickup';

export type MenuCategory = {
  availabilities: MenuCategoryAvailability[];
  description: string | null;
  externalId: string | null;
  hidden: boolean;
  id: number;
  name: string;
  productIds: number[];
  shippingType: MenuShippingType | null;
  validFrom: string | null;
  validThrough: string | null;
};

export enum DayOfWeek {
  Monday = 'Monday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Thursday = 'Thursday',
  Friday = 'Friday',
  Saturday = 'Saturday',
  Sunday = 'Sunday',
}

export type MenuCategoryFormAvailability = {
  end: string;
  id?: number;
  start: string;
};

export type MenuCategoryFormDay = {
  availabilities: MenuCategoryFormAvailability[];
  name: DayOfWeek;
};

export type MenuCategoryFormValues = {
  days: MenuCategoryFormDay[];
  description: string;
  name: string;
};

export type MenuCategoryFormEndOption = {
  isDisabled: boolean;
  label: string;
  value: string;
};

export type MenuCategoryFormStartOption = {
  latestEndTime: string;
} & MenuCategoryFormEndOption;

export type MenuCategoryFormId = number | `new_${string}`;

type ModifiedMenuCategoryAvailability = Omit<MenuCategoryAvailability, 'id'> & {
  id: MenuCategoryFormId;
};

export type ModifiedMenuCategory = Omit<
  MenuCategory,
  'availabilities' | 'id'
> & {
  availabilities: ModifiedMenuCategoryAvailability[];
  id: MenuCategoryFormId;
};
