import * as React from "react";
import { forwardRef } from "react";
const SvgCompletedPlaceholder = ({
  title,
  titleId,
  ...props
}, ref) => {
  return /* @__PURE__ */ React.createElement("svg", { viewBox: "0 0 52 46", fill: "none", xmlns: "http://www.w3.org/2000/svg", ref, "aria-labelledby": titleId, ...props }, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M9.69565 5.34783C9.69565 7.74783 7.74783 9.69565 5.34783 9.69565C2.94348 9.69565 1 7.74783 1 5.34783C1 2.94783 2.94348 1 5.34783 1C7.74783 1 9.69565 2.94783 9.69565 5.34783Z", fill: "#FFE3AC", stroke: "#C4C4C4", strokeWidth: 2, strokeLinecap: "round", strokeLinejoin: "round" }), /* @__PURE__ */ React.createElement("path", { d: "M18.3914 5.34784H51.0001", stroke: "#C4C4C4", strokeWidth: 2, strokeLinecap: "round", strokeLinejoin: "round" }), /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M9.69565 22.7391C9.69565 25.1391 7.74783 27.0869 5.34783 27.0869C2.94348 27.0869 1 25.1391 1 22.7391C1 20.3391 2.94348 18.3913 5.34783 18.3913C7.74783 18.3913 9.69565 20.3391 9.69565 22.7391Z", fill: "#FFE3AC", stroke: "#C4C4C4", strokeWidth: 2, strokeLinecap: "round", strokeLinejoin: "round" }), /* @__PURE__ */ React.createElement("path", { d: "M18.3914 22.7391H51.0001", stroke: "#C4C4C4", strokeWidth: 2, strokeLinecap: "round", strokeLinejoin: "round" }), /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M9.69565 40.1305C9.69565 42.5305 7.74783 44.4783 5.34783 44.4783C2.94348 44.4783 1 42.5305 1 40.1305C1 37.7305 2.94348 35.7827 5.34783 35.7827C7.74783 35.7827 9.69565 37.7305 9.69565 40.1305Z", fill: "#FFE3AC", stroke: "#C4C4C4", strokeWidth: 2, strokeLinecap: "round", strokeLinejoin: "round" }), /* @__PURE__ */ React.createElement("path", { d: "M18.3914 40.1305H51.0001", stroke: "#C4C4C4", strokeWidth: 2, strokeLinecap: "round", strokeLinejoin: "round" }));
};
const ForwardRef = forwardRef(SvgCompletedPlaceholder);
export default ForwardRef;
