import * as React from "react";
import { forwardRef } from "react";
const SvgDrag = ({
  title,
  titleId,
  ...props
}, ref) => {
  return /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: 11, height: 18, viewBox: "0 0 11 18", ref, "aria-labelledby": titleId, ...props }, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("g", { fill: "none", fillRule: "evenodd" }, /* @__PURE__ */ React.createElement("g", { fill: "currentColor" }, /* @__PURE__ */ React.createElement("g", { transform: "translate(-44 -487) translate(44 487)" }, /* @__PURE__ */ React.createElement("circle", { cx: 2, cy: 2, r: 2 }), /* @__PURE__ */ React.createElement("circle", { cx: 9, cy: 2, r: 2 }), /* @__PURE__ */ React.createElement("circle", { cx: 2, cy: 9, r: 2 }), /* @__PURE__ */ React.createElement("circle", { cx: 9, cy: 9, r: 2 }), /* @__PURE__ */ React.createElement("circle", { cx: 2, cy: 16, r: 2 }), /* @__PURE__ */ React.createElement("circle", { cx: 9, cy: 16, r: 2 })))));
};
const ForwardRef = forwardRef(SvgDrag);
export default ForwardRef;
