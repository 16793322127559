import { Link } from 'crust';

import { restaurantAgreement } from 'routes/external-urls';

import styles from './styles.module.scss';

const PhotoCopyrightWarning = () => {
  return (
    <span className={styles.photoCopyrightWarning}>
      <strong className={styles.emphasis}>ATTENTION: </strong> By uploading an
      image and clicking “Save Changes”, you represent and warrant that you own
      the copyright to the image or have obtained a license from the copyright
      owner permitting you to use the image for commercial purposes on a
      third-party platform, in accordance with the{' '}
      <Link href={restaurantAgreement} variant="tertiary">
        Restaurant Agreement
      </Link>
      .{' '}
      <strong className={styles.emphasis}>
        If you are not confident that you own the rights to an image, please do
        not use it on Slice.
      </strong>
    </span>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default PhotoCopyrightWarning;
