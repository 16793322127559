import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import PropTypes from 'prop-types';

import AnalyticsTile from 'components/shared/analytics-tile';
import useApi from 'hooks/use-api';
import { Direction } from 'utilities/sorting';

import { createFilters } from '../filters';
import ProductsBySales from '../table/products-by-sales';

const LeastSellingProducts = ({ shopId, startDate, endDate, orderType }) => {
  const api = useApi();

  const [selectedCategoryId, setSelectedCategoryId] = useState('all');

  const { data, isLoading } = useQuery(
    [
      shopId,
      { startDate, endDate, orderType, selectedCategoryId },
      'metrics-product-sales',
    ],
    () =>
      api.postProductSalesMetrics(
        shopId,
        createFilters(startDate, endDate, orderType, selectedCategoryId),
      ),
  );

  const leastSellingItems = data?.data ?? [];

  return (
    <AnalyticsTile title="Least Selling Menu Items">
      <ProductsBySales
        data={leastSellingItems}
        isEmpty={leastSellingItems.length === 0}
        isLoading={isLoading}
        shopId={shopId}
        parentTileTitle="Least Selling"
        selectedCategoryId={selectedCategoryId}
        setSelectedCategoryId={setSelectedCategoryId}
        sortDirection={Direction.Ascending}
      />
    </AnalyticsTile>
  );
};

LeastSellingProducts.propTypes = {
  shopId: PropTypes.string,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  orderType: PropTypes.string,
};

LeastSellingProducts.defaultProps = {
  shopId: '',
  orderType: 'online',
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default LeastSellingProducts;
