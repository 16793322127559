import { QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { SiteEditorSettings } from 'types/site-editor';
import { camelCaseKeys } from 'utilities/objects';

export const getDirectWebSettingsQueryKey = (shopId: string): QueryKey => [
  shopId,
  'directWebSettings',
];

export const useDirectWebSettingsQuery = (
  shopId: string,
  options?: UseQueryOptions<SiteEditorSettings>,
) => {
  const { authenticatedFetch } = useApi();

  return useQuery({
    queryKey: getDirectWebSettingsQueryKey(shopId),
    queryFn: async () => {
      const response = await authenticatedFetch.get(
        `api/management/v1/shops/${shopId}/direct_web_settings`,
      );
      return camelCaseKeys(response) as SiteEditorSettings;
    },
    ...options,
  });
};
