import { useEffect, useRef, useState } from 'react';
import {
  Control,
  Controller,
  FieldError,
  UseFormGetValues,
  UseFormRegister,
  UseFormTrigger,
  useWatch,
} from 'react-hook-form';

import CollapsibleTile from 'components/shared/collapsible-tile';
import FormFeedback from 'components/shared/form-feedback';
import InputDescription from 'components/shared/input-description';
import Label from 'components/shared/label';
import NumberInput from 'components/shared/number-input';
import { RHFRadio, RHFRadioGroup } from 'components/shared/rhf-radio';
import { PromoCodeFormValues, PromoCodeType } from 'types/discounts';

import { TypeFieldsSummary } from './summary';

import styles from './styles.module.scss';

type Props = {
  amountError?: FieldError;
  className?: string;
  control: Control<PromoCodeFormValues>;
  getValues: UseFormGetValues<PromoCodeFormValues>;
  register: UseFormRegister<PromoCodeFormValues>;
  trigger: UseFormTrigger<PromoCodeFormValues>;
};

export const TypeFields = ({
  amountError,
  className,
  control,
  getValues,
  trigger,
}: Props) => {
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    if (amountError) {
      setIsOpen(true);
    }
  }, [amountError]);

  // The validation of the amount input will vary slightly depending on the
  // type selected. Ultimately, both flat and percentage promo codes can
  // only use integer amounts, so $10 or 10%. For flat promos, the value we
  // send will be truncated to an integer in Admin. For percentage promos, the
  // value will be divided by 100 and truncated to two decimal places of
  // precision (eg 5.5 becomes 0.05, which is 5%).
  const type = useWatch({ control, name: 'type' });

  // Keep a reference to the previous type so that we can re-validate the amount
  // appropriately, see below.
  const previousTypeRef = useRef(type);

  const isFlat = type === PromoCodeType.Flat;
  const isPercent = type === PromoCodeType.Percent;

  // When the type changes, re-validate the amount after the validation
  // conditions have been updated on render. We compare the current type to the
  // previous one to only trigger validation after the user has changed the type
  // at least once.
  useEffect(() => {
    if (type !== previousTypeRef.current && getValues('amount')) {
      trigger('amount');
    }

    previousTypeRef.current = type;
  }, [getValues, trigger, type]);

  return (
    <CollapsibleTile
      className={className}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      summary={<TypeFieldsSummary control={control} />}
      title="Select Type"
    >
      <RHFRadioGroup
        name="type"
        control={control}
        data-chameleon-target="Promo Code Select Type"
        label="What type of promo do you want to run?"
        orientation="horizontal"
        variant="card"
      >
        <RHFRadio
          description="Offer dollar-based discounts (e.g. $5 Off)"
          label="Fixed Amount"
          value={PromoCodeType.Flat}
        />
        <RHFRadio
          description="Offer percent-based discounts (e.g. 5% Off)"
          label="Percentage"
          value={PromoCodeType.Percent}
        />
      </RHFRadioGroup>
      <div
        className={styles.value}
        data-chameleon-target="Promo Code Type Value"
      >
        <Label htmlFor="promo-code-amount">Value</Label>
        <Controller
          control={control}
          name="amount"
          rules={{
            min: {
              value: 1,
              message: `Must be at least ${isPercent ? '1%' : '$1'}.`,
            },
            max: {
              value: isPercent ? 100 : Number.MAX_VALUE,
              message: 'Cannot be greater than 100%.',
            },
            required: 'Please enter an amount.',
          }}
          render={({ field, fieldState }) => (
            <NumberInput
              aria-describedby="promo-code-amount-description"
              aria-required="true"
              decimalScale={0}
              id="promo-code-amount"
              isInvalid={fieldState.error != null}
              name={field.name}
              onBlur={field.onBlur}
              onValueChange={(values) => field.onChange(values.value)}
              placeholder="e.g. 5"
              prefix={isFlat ? '$' : undefined}
              ref={field.ref}
              suffix={isPercent ? '%' : undefined}
              value={field.value}
              valueIsNumericString
            />
          )}
        />
        <FormFeedback>{amountError?.message}</FormFeedback>
        <InputDescription id="promo-code-amount-description">
          Must be a whole number.
        </InputDescription>
      </div>
    </CollapsibleTile>
  );
};
