import { useMutation, useQueryClient } from '@tanstack/react-query';

import useAnalytics from 'hooks/use-analytics';
import useApi from 'hooks/use-api';
import { PromoCode, PromoCodeFormValues } from 'types/discounts';
import { Shop } from 'types/shops';
import { getCreatePromoCodeRequestBody } from 'utilities/discounts';
import { camelCaseKeys, snakeCaseKeys } from 'utilities/objects';

import { getPromoCodesQueryKey } from './use-promo-codes-query';

export const useSavePromoCodeMutation = (shopId: Shop['shopId']) => {
  const { authenticatedFetch } = useApi();
  const queryClient = useQueryClient();
  const { trackPromoCodeCreate } = useAnalytics();

  return useMutation({
    mutationFn: async (values: PromoCodeFormValues) => {
      const response = await authenticatedFetch.post(
        `api/management/v1/shops/${shopId}/promo_codes`,
        snakeCaseKeys(getCreatePromoCodeRequestBody(values)),
      );

      return camelCaseKeys(response.data) as PromoCode;
    },
    onError: (_error, values) => {
      trackPromoCodeCreate(String(shopId), values.tag, false);
    },
    onSuccess: (promoCode) => {
      queryClient.setQueryData<PromoCode[]>(
        getPromoCodesQueryKey(shopId),
        (current = []) => [...current, promoCode],
      );
      trackPromoCodeCreate(String(shopId), promoCode.tag, true);
    },
  });
};
