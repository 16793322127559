import AddDebitCardContent from 'components/debit-card/create';
import Page from 'components/shared/page';
import { useCurrentShopId } from 'hooks/shops';

const AddDebitCard = () => {
  const shopId = useCurrentShopId();

  return (
    <Page name="add-debit-card" title="Add Debit Card">
      <AddDebitCardContent shopId={String(shopId)} />
    </Page>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default AddDebitCard;
