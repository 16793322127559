import { ComponentPropsWithoutRef } from 'react';
import cx from 'classnames';

import CheckmarkSolidIcon from 'images/checkmark-solid.svg?react';
import InfoSolidIcon from 'images/info-solid.svg?react';
import WarningSolidIcon from 'images/warning.svg?react';

import styles from './styles.module.scss';

type Variant = 'success' | 'info' | 'warning';

type Props = {
  alertClassName?: string;
  variant: Variant;
} & ComponentPropsWithoutRef<'div'>;

const Alert = ({ alertClassName, children, variant, ...props }: Props) => {
  let icon;

  if (variant === 'success') {
    icon = <CheckmarkSolidIcon className={styles.icon} />;
  }

  if (variant === 'info') {
    icon = <InfoSolidIcon className={styles.icon} />;
  }

  if (variant === 'warning') {
    icon = <WarningSolidIcon className={styles.icon} />;
  }

  return (
    <div
      className={cx(styles.alert, alertClassName, styles[variant])}
      {...props}
    >
      {icon}
      <div className={styles.message}>{children}</div>
    </div>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default Alert;
