import { OpenForToday } from 'types/open-for-today';
import { shippingTypes } from 'utilities/constants';

export const isClosedForDelivery = (openForToday: OpenForToday): boolean =>
  openForToday.closedToday.delivery;

export const isClosedForPickup = (openForToday: OpenForToday): boolean =>
  openForToday.closedToday.pickup;

export const isRegularSchedulePickupOpen = (
  openForToday: OpenForToday,
): boolean => openForToday.regularScheduleOpen.pickup;

export const isRegularScheduleDeliveryOpen = (
  openForToday: OpenForToday,
): boolean => openForToday.regularScheduleOpen.delivery;

export const isRegularScheduleOpen = (openForToday: OpenForToday): boolean =>
  isRegularScheduleDeliveryOpen(openForToday) ||
  isRegularSchedulePickupOpen(openForToday);

export const openForShippingType = (openForToday: OpenForToday): string => {
  const regularScheduleOpen = isRegularScheduleOpen(openForToday);
  const closedForToday =
    isClosedForDelivery(openForToday) && isClosedForPickup(openForToday);

  const regularScheduleOpenState = openForToday.regularScheduleOpen;
  const closedTodayState = openForToday.closedToday;

  if (regularScheduleOpen) {
    if (!regularScheduleOpenState.delivery) {
      return shippingTypes.pickup.value;
    }

    if (!regularScheduleOpenState.pickup) {
      return shippingTypes.delivery.value;
    }
  }

  if (!regularScheduleOpen || closedForToday) {
    return shippingTypes.delivery_and_pickup.value;
  }

  if (closedTodayState.delivery) {
    return shippingTypes.pickup.value;
  }

  if (closedTodayState.pickup) {
    return shippingTypes.delivery.value;
  }

  return shippingTypes.delivery_and_pickup.value;
};

export const isAcceptingDeliveryOrders = (
  openForToday: OpenForToday,
): boolean => {
  const regularScheduleOpenState = openForToday.regularScheduleOpen;
  const closedTodayState = openForToday.closedToday;

  const isAcceptingDelivery =
    regularScheduleOpenState.delivery && !closedTodayState.delivery;

  return isAcceptingDelivery;
};

export const isAcceptingPickupOrders = (
  openForToday: OpenForToday,
): boolean => {
  const regularScheduleOpenState = openForToday.regularScheduleOpen;
  const closedTodayState = openForToday.closedToday;

  const isAcceptingPickup =
    regularScheduleOpenState.pickup && !closedTodayState.pickup;

  return isAcceptingPickup;
};

export const isAcceptingOrders = (openForToday: OpenForToday): boolean => {
  return (
    isAcceptingDeliveryOrders(openForToday) ||
    isAcceptingPickupOrders(openForToday)
  );
};

export const isAcceptingDeliveryAndPickupOrders = (
  openForToday: OpenForToday,
): boolean => {
  return (
    isAcceptingDeliveryOrders(openForToday) &&
    isAcceptingPickupOrders(openForToday)
  );
};

export const nextOpenAt = (openForToday: OpenForToday): string =>
  openForToday.nextOpenAt;

export const nextOpenForDeliveryAt = (openForToday: OpenForToday): string =>
  openForToday.nextOpenForDelivery;

export const nextOpenForPickupAt = (openForToday: OpenForToday): string =>
  openForToday.nextOpenForPickup;

export const isPausedForDeliveryOrders = (
  openForToday: OpenForToday,
): boolean => openForToday.pausedToday.delivery;

export const isPausedForPickupOrders = (openForToday: OpenForToday): boolean =>
  openForToday.pausedToday.pickup;

export const isPausedForDeliveryAndPickupOrders = (
  openForToday: OpenForToday,
): boolean =>
  isPausedForDeliveryOrders(openForToday) &&
  isPausedForPickupOrders(openForToday);

export const unpausedAt = (openForToday: OpenForToday): string =>
  openForToday.unpausedAt ?? '';
