import { Control, useWatch } from 'react-hook-form';

import { PromoCodeFormValues } from 'types/discounts';
import { PromoCodeLimitLabels } from 'utilities/discounts';

type Props = {
  control: Control<PromoCodeFormValues>;
};

export const UsageLimitSummary = ({ control }: Props) => {
  const limit = useWatch({
    control,
    name: 'limit',
  });

  return <>{PromoCodeLimitLabels[limit]}</>;
};
