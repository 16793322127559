import * as React from "react";
import { forwardRef } from "react";
const SvgQuestionIcon = ({
  title,
  titleId,
  ...props
}, ref) => {
  return /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", stroke: "currentColor", viewBox: "0 0 30 30", ref, "aria-labelledby": titleId, ...props }, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("circle", { cx: 15, cy: 15, r: 14, fill: "none", strokeWidth: 2 }), /* @__PURE__ */ React.createElement("path", { fill: "none", strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: 2, d: "M11 11c0-2.2 1.8-4 4-4m0 0c2.2 0 4 1.8 4 4m0 0c0 2.2-1.8 4-4 4v4" }), /* @__PURE__ */ React.createElement("circle", { cx: 15, cy: 23, r: 1 }));
};
const ForwardRef = forwardRef(SvgQuestionIcon);
export default ForwardRef;
