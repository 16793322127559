import { useEffect, useRef } from 'react';

import { Button } from 'crust';

import { useShopTraitsContext } from 'hooks/shops/use-shop-traits-context';
import useAnalytics from 'hooks/use-analytics';
import { ClickedExternalLinkType } from 'types/shared/analytics';
import { Shop } from 'types/shops';

import styles from './styles.module.scss';

type Props = {
  onScheduleDemoClick: () => void;
  hasValidCase: boolean;
  shopId: Shop['shopId'];
};

export const VideoModalContent = ({
  onScheduleDemoClick,
  hasValidCase,
  shopId,
}: Props) => {
  const { trackProductUpsellLinkClick } = useAnalytics();
  const shopTraits = useShopTraitsContext();

  const scheduleDemoButton = useRef<HTMLButtonElement>(null);

  // Taken from https://github.com/adobe/react-spectrum/issues/1513#issuecomment-1641830053
  // Fix issue on chrome mobile where clicking the schedule demo button would cause the modal to close
  useEffect(() => {
    scheduleDemoButton.current?.addEventListener(
      'touchend',
      (e) => {
        /**
         * The first condition is necessary to avoid the issue that a button with
         * type="submit" inside a <form> would not trigger the form submit
         * event on a device with touch input.
         *
         * The second condition is needed for these buttons that are links
         * underneath and will have a href. If "touchend" is prevented, links
         * won't open by touch.
         *
         * Admittedly, this is an ugly patch, but given the react-spectrum
         * issue it seems necessary. If this hook grows any larger after
         * further bug reports and edge cases, we may want to reconsider
         * this approach.
         */
        if (
          (e.currentTarget as HTMLElement)?.getAttribute('type') !== 'submit' &&
          typeof (e.currentTarget as HTMLElement)?.getAttribute('href') !==
            'string'
        ) {
          e.preventDefault();
        }
      },
      { passive: false },
    );
  }, []);

  const handleScheduleDemo = () => {
    onScheduleDemoClick();
    trackProductUpsellLinkClick({
      shopId: shopId,
      product: 'register',
      page: 'register [splash] video modal',
      linkLabel: 'schedule demo',
      type: ClickedExternalLinkType.CTA,
      outcome: 'lead',
      shopIntegrationAdoption: shopTraits.shopIntegrationAdoption,
    });
  };

  return (
    <div className={styles.modalContent}>
      <div className={styles.videoContainer}>
        <iframe
          className={styles.videoFrame}
          title="See How Register Works"
          src="https://player.vimeo.com/video/687195963"
          allow="fullscreen; picture-in-picture"
          allowFullScreen
        />
      </div>
      {hasValidCase ? null : (
        <div className={styles.scheduleContainer}>
          <p className={styles.scheduleHelpText}>
            Schedule a demo to see how Slice Register can transform your
            business and make your customers more valuable.
          </p>
          <Button
            className={styles.scheduleButton}
            onPress={handleScheduleDemo}
            ref={scheduleDemoButton}
          >
            Schedule Demo
          </Button>
        </div>
      )}
    </div>
  );
};
