import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import CollapsibleTile from 'components/shared/collapsible-tile';
import FormText from 'components/shared/form-text';
import Suspended from 'components/shared/suspended';
import { AnalyticsField } from 'utilities/menu';

import Customizations from './customizations';
import CustomizationsHelp from './customizations-help';

const ProductCustomizationsTile = ({
  categoriesById,
  categoryOptions,
  control,
  errors,
  getValues,
  isLoading,
  productId,
  productsById,
  register,
  setValue,
  shopId,
  submitCount,
}) => {
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    if (errors) {
      setIsOpen(true);
    }
  }, [errors, submitCount]);

  return (
    <CollapsibleTile
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title="Customizations"
      bodyChameleonTarget="Edit Item Customizations Area"
    >
      <Suspended isLoading={isLoading} variant="short">
        <FormText>
          From pizza toppings to salad dressing, this is where a customer would
          have required or optional choices to make.
        </FormText>
        <Customizations
          categoriesById={categoriesById}
          categoryOptions={categoryOptions}
          control={control}
          errors={errors}
          getValues={getValues}
          productId={productId}
          productsById={productsById}
          register={register}
          setValue={setValue}
          shopId={shopId}
          submitCount={submitCount}
        />
        <CustomizationsHelp
          getValues={getValues}
          productId={productId}
          shopId={shopId}
        />
        <input type="hidden" {...register(AnalyticsField.CustomizationAdded)} />
        <input
          type="hidden"
          {...register(AnalyticsField.CustomizationRemoved)}
        />
        <input
          type="hidden"
          {...register(AnalyticsField.CustomizationNameChanged)}
        />
        <input
          type="hidden"
          {...register(AnalyticsField.CustomizationLimitChanged)}
        />
        <input
          type="hidden"
          {...register(AnalyticsField.CustomizationRequiredChanged)}
        />
        <input type="hidden" {...register(AnalyticsField.SelectionAdded)} />
        <input type="hidden" {...register(AnalyticsField.SelectionRemoved)} />
        <input
          type="hidden"
          {...register(AnalyticsField.SelectionNameChanged)}
        />
        <input
          type="hidden"
          {...register(AnalyticsField.SelectionPriceChanged)}
        />
      </Suspended>
    </CollapsibleTile>
  );
};

ProductCustomizationsTile.propTypes = {
  categoriesById: PropTypes.object.isRequired,
  categoryOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      length: PropTypes.number.isRequired,
      value: PropTypes.number.isRequired,
    }),
  ).isRequired,
  control: PropTypes.object.isRequired,
  errors: PropTypes.array,
  getValues: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  productId: PropTypes.string.isRequired,
  productsById: PropTypes.object.isRequired,
  register: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  shopId: PropTypes.string.isRequired,
  submitCount: PropTypes.number.isRequired,
};

ProductCustomizationsTile.defaultProps = {
  errors: null,
  isLoading: false,
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default ProductCustomizationsTile;
