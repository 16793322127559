import { useEffect, useState } from 'react';
import { Control, FieldError } from 'react-hook-form';

import CollapsibleTile from 'components/shared/collapsible-tile';
import {
  RHFTextAreaField,
  RHFTextField,
} from 'components/shared/rhf-text-field';
import { RegisterDiscountFormValues } from 'types/discounts';

import NameFieldsSummary from './summary';

import styles from './styles.module.scss';

type Props = {
  className?: string;
  control: Control<RegisterDiscountFormValues>;
  nameError?: FieldError;
};

const NameFields = ({ className, control, nameError }: Props) => {
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    if (nameError) {
      setIsOpen(true);
    }
  }, [nameError]);

  return (
    <CollapsibleTile
      bodyChameleonTarget="Register Discount Name Fields"
      className={className}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      summary={<NameFieldsSummary control={control} />}
      title="Add Name"
    >
      <RHFTextField
        className={styles.field}
        control={control}
        description="No spaces, at least 1 letter, at least 6 characters, and cannot start with Slice, App, or Web. e.g. StaffDiscount"
        isRequired
        label="Discount Name"
        name="name"
        rules={{
          pattern: {
            value: /^(?!slice|app|web)(?=.*[a-z])\w{6,}$/i,
            message: 'Please enter a valid discount name.',
          },
          required: 'Please enter a discount name.',
        }}
      />
      <RHFTextAreaField
        className={styles.field}
        control={control}
        description="e.g. 10% off total order"
        label="Description (Optional)"
        name="description"
      />
    </CollapsibleTile>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default NameFields;
