import PropTypes from 'prop-types';

import defaultImage from 'images/camera.svg';
import { imgixUrl } from 'utilities/image-urls';

import styles from './styles.module.scss';

const height = 200;
const width = 200;

const ProductImage = ({ imageUrl, imageAlt }) => {
  return (
    <>
      {imageUrl ? (
        <img
          className={styles.image}
          src={imgixUrl(imageUrl, height, width)}
          alt={imageAlt}
        />
      ) : (
        <div className={styles.defaultImageWrapper}>
          <img
            className={styles.cameraIcon}
            src={defaultImage}
            alt={imageAlt}
          />
        </div>
      )}
    </>
  );
};

ProductImage.propTypes = {
  imageUrl: PropTypes.string,
  imageAlt: PropTypes.string.isRequired,
};

ProductImage.defaultProps = {
  imageUrl: '',
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default ProductImage;
