import { useState } from 'react';

import { BrandManagerDateRange } from 'types/brand-manager/date-picker';

import BusinessProfile from './business-profile';
import DateRangeFilter from './date-range-filter';
import BrandManagerHeader from './header';
import LinkedAccountsArea from './linked-accounts-area';
import ReturnOnInvestment from './return-on-investment';

import styles from './styles.module.scss';

type Props = {
  shopId: string;
};

const BrandManager = ({ shopId }: Props) => {
  // Default to "Last 28 Days"
  const [dateRange, setDateRange] = useState<BrandManagerDateRange>(
    BrandManagerDateRange.Last28Days,
  );

  return (
    <>
      <div className={styles.headerAndDatePicker}>
        <BrandManagerHeader />
        <DateRangeFilter dateRange={dateRange} setDateRange={setDateRange} />
      </div>
      <div className={styles.profileAndRoiContainer}>
        <BusinessProfile shopId={shopId} />
        <ReturnOnInvestment shopId={shopId} dateRange={dateRange} />
      </div>
      <LinkedAccountsArea shopId={shopId} />
    </>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default BrandManager;
