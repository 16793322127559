import * as React from "react";
import { forwardRef } from "react";
const SvgScreenType = ({
  title,
  titleId,
  ...props
}, ref) => {
  return /* @__PURE__ */ React.createElement("svg", { width: 69, height: 53, viewBox: "0 0 69 53", fill: "none", xmlns: "http://www.w3.org/2000/svg", ref, "aria-labelledby": titleId, ...props }, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("rect", { width: 69, height: 53, rx: 5, fill: "white" }), /* @__PURE__ */ React.createElement("rect", { x: 1, y: 1, width: 67, height: 51, rx: 3, stroke: "#2B69F6", strokeWidth: 2 }), /* @__PURE__ */ React.createElement("line", { x1: 1, y1: 9, x2: 67, y2: 9, stroke: "#2B69F6", strokeWidth: 2 }), /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M7 5C7 5.55228 6.55228 6 6 6C5.44772 6 5 5.55228 5 5C5 4.44772 5.44772 4 6 4C6.55228 4 7 4.44772 7 5ZM10 5C10 5.55228 9.55228 6 9 6C8.44772 6 8 5.55228 8 5C8 4.44772 8.44772 4 9 4C9.55228 4 10 4.44772 10 5ZM12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z", fill: "#2B69F6" }), /* @__PURE__ */ React.createElement("rect", { width: 69, height: 9, transform: "translate(0 8)", fill: "#2B69F6" }), /* @__PURE__ */ React.createElement("rect", { x: 35, y: 11, width: 14, height: 3, rx: 1.5, fill: "white" }), /* @__PURE__ */ React.createElement("rect", { x: 51, y: 11, width: 14, height: 3, rx: 1.5, fill: "white" }));
};
const ForwardRef = forwardRef(SvgScreenType);
export default ForwardRef;
