export const shippingTypes = {
  delivery_and_pickup: {
    value: 'delivery_and_pickup',
    label: 'Delivery & Pickup',
  },
  delivery: {
    value: 'delivery',
    label: 'Delivery',
  },
  pickup: {
    value: 'pickup',
    label: 'Pickup',
  },
};

export const shippingTypeOptions = [
  {
    id: shippingTypes.delivery_and_pickup.value,
    label: shippingTypes.delivery_and_pickup.label,
  },
  {
    id: shippingTypes.delivery.value,
    label: shippingTypes.delivery.label,
  },
  {
    id: shippingTypes.pickup.value,
    label: shippingTypes.pickup.label,
  },
];

export const newClosingDefaultValues = {
  closingComment: '',
  closingReason: null,
  dates: { start: null, end: null },
  endDate: null,
  shippingType: shippingTypeOptions[0].id,
  startDate: null,
};

export const emailRegex = /^([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/i;

export const phoneNumbers = {
  accountManagers: '8552374992',
  integrationsContact: '8448802346',
};

export const emailAddresses = {
  partner: 'partner@SliceLife.com',
};

export const orderActivityReportStatuses = {
  1: 'Queued',
  2: 'Generating',
  3: 'Completed',
  4: 'Failed',
};

export const nonDisplayCategoryName = 'Non Display Products';

// This should match the styles/sizes.scss definition.
export const mobileDesktopThresholdWidth = 811;

export const analyticsEventSource = 'owners_portal';
export const databaseRecordSource = analyticsEventSource;

export const viewMyMenuOnlineLinkLocations = {
  menuProductsPage: 'menu_products_page',
  menuCategoriesPage: 'menu_categories_page',
  menuLayoutPage: 'menu_layout_page',
};

export const analyticsTab = {
  orders: 'orders',
  customers: 'customers',
};
