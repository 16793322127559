import {
  cloneElement,
  ComponentPropsWithoutRef,
  ForwardedRef,
  forwardRef,
  ReactElement,
} from 'react';
import cx from 'classnames';

import VisuallyHidden from 'components/shared/visually-hidden';
import CloseIcon from 'images/close.svg?react';
import CloseCircleIcon from 'images/close-circle.svg?react';
import DeleteIcon from 'images/delete.svg?react';
import DragIcon from 'images/drag.svg?react';
import EllipsesIcon from 'images/ellipses.svg?react';
import DesktopIcon from 'images/site-editor/desktop-icon.svg?react';
import MobileIcon from 'images/site-editor/mobile-icon.svg?react';
import TabletIcon from 'images/site-editor/tablet-icon.svg?react';

import styles from './styles.module.scss';

export type IconButtonProps = {
  icon: ReactElement;
  label: string;
} & Omit<ComponentPropsWithoutRef<'button'>, 'children'>;

// The function keyword syntax is used here to avoid having to specify the
// displayName property due to ref forwarding.
const IconButton = forwardRef(function IconButton(
  { className, icon, label, ...buttonProps }: IconButtonProps,
  ref: ForwardedRef<HTMLButtonElement>,
) {
  return (
    <button
      className={cx(styles.button, className)}
      ref={ref}
      type="button"
      {...buttonProps}
    >
      {cloneElement(icon, { 'aria-hidden': 'true' })}
      <VisuallyHidden>{label}</VisuallyHidden>
    </button>
  );
});

type PropsWithoutIcon = {
  label: string;
  iconClassName?: string;
} & Omit<IconButtonProps, 'icon'>;

export const CloseButton = forwardRef<HTMLButtonElement, PropsWithoutIcon>(
  function CloseButton(props, ref) {
    return (
      <IconButton
        icon={<CloseIcon className={styles.close} />}
        ref={ref}
        {...props}
      />
    );
  },
);

export const CloseCircleButton = forwardRef<
  HTMLButtonElement,
  PropsWithoutIcon
>(function CloseButton(props, ref) {
  return (
    <IconButton
      icon={<CloseCircleIcon className={styles.closeCircle} />}
      ref={ref}
      {...props}
    />
  );
});

export const DeleteButton = forwardRef<HTMLButtonElement, PropsWithoutIcon>(
  function DeleteButton(props, ref) {
    return (
      <IconButton
        icon={<DeleteIcon className={styles.delete} />}
        ref={ref}
        {...props}
      />
    );
  },
);

export const DragButton = forwardRef<HTMLButtonElement, PropsWithoutIcon>(
  function DragButton(
    { className, iconClassName, ...props }: PropsWithoutIcon,
    ref,
  ) {
    return (
      <IconButton
        className={cx(styles.dragButton, className)}
        icon={<DragIcon className={cx(styles.drag, iconClassName)} />}
        ref={ref}
        {...props}
      />
    );
  },
);

export const OverflowButton = forwardRef<HTMLButtonElement, PropsWithoutIcon>(
  function OverflowButton(props, ref) {
    return (
      <IconButton
        icon={<EllipsesIcon className={styles.ellipses} />}
        ref={ref}
        {...props}
      />
    );
  },
);

export const DesktopButon = forwardRef<HTMLButtonElement, PropsWithoutIcon>(
  function DesktopButon(props, ref) {
    return (
      <IconButton
        icon={<DesktopIcon className={styles.desktop} />}
        ref={ref}
        {...props}
      />
    );
  },
);

export const TabletButton = forwardRef<HTMLButtonElement, PropsWithoutIcon>(
  function TabletButton(props, ref) {
    return (
      <IconButton
        icon={<TabletIcon className={styles.tablet} />}
        ref={ref}
        {...props}
      />
    );
  },
);

export const MobileButton = forwardRef<HTMLButtonElement, PropsWithoutIcon>(
  function MobileButton(props, ref) {
    return (
      <IconButton
        icon={<MobileIcon className={styles.mobile} />}
        ref={ref}
        {...props}
      />
    );
  },
);

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default IconButton;
