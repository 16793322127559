import * as React from "react";
import { forwardRef } from "react";
const SvgCalculator = ({
  title,
  titleId,
  ...props
}, ref) => {
  return /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", fill: "none", viewBox: "0 0 25 24", ref, "aria-labelledby": titleId, ...props }, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { stroke: "#211E1E", strokeWidth: 2, d: "m20.5 14-3 3m0 0-3 3m3-3-3-3m3 3 3 3m-17-3h8" }), /* @__PURE__ */ React.createElement("path", { fill: "#181218", fillOpacity: 0.6, fillRule: "evenodd", d: "M8.5 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm0 6a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z", clipRule: "evenodd" }), /* @__PURE__ */ React.createElement("path", { stroke: "#211E1E", strokeWidth: 2, d: "M13.5 7h8m-14-4v8m-4-4h8" }));
};
const ForwardRef = forwardRef(SvgCalculator);
export default ForwardRef;
