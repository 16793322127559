import moment from 'moment-timezone';

import ResultsTile from 'components/campaign-manager/campaign-info/results-tile';
import Alert from 'components/shared/alert';
import {
  CampaignManagerState,
  CampaignManagerSummary,
} from 'types/campaign-manager';
import { Shop } from 'types/shops';
import { insightText } from 'utilities/campaign-manager';

import styles from './styles.module.scss';

type Props = {
  shopId: Shop['shopId'];
  startDate: string;
  summaryData: CampaignManagerSummary;
};

const LearningPhase = ({ shopId, startDate, summaryData }: Props) => {
  const formattedDate = moment(startDate).format('MMM D, YYYY');

  return (
    <>
      <Alert alertClassName={styles.alert} variant="success">
        <span className={styles.launchDate}>
          Your campaign launched on {formattedDate}.
        </span>
        {` It may take up to a week to start seeing your data. Check back soon!`}
      </Alert>
      <ResultsTile
        campaignState={CampaignManagerState.LearningPhase}
        description="Coming Soon"
        insights={[
          insightText.yourAdsAreLive,
          insightText.campaignInLearningPhase,
          insightText.resultsWillImprove,
        ]}
        platformsInfo={summaryData?.platforms}
        shopId={shopId}
        title="Recent Results"
      />
    </>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default LearningPhase;
