import { useMatch } from 'react-router-dom';

import { AchEnrollmentBanner } from 'components/shared/ach-enrollment-banner';
import NavigationTabs from 'components/shared/navigation-tabs';
import { useAchOnboardingLink } from 'hooks/ach-enrollment-banner/use-ach-enrollment-link';
import * as paths from 'routes/paths';
import { RegisterModeName, Shop } from 'types/shops';
import {
  getShopHasBasicRegister,
  getShopHasFullRegister,
} from 'utilities/shops/register-app';

import BasicTaxReports from './basic-tax';
import OrderReports from './order';
import OrderActivityReports from './order-activity';
import RegisterSalesSummaryReports from './register-sales-summary';
import SalesMixReports from './sales-mix';
import { SalesSummaryReports } from './sales-summary';
import { StandalonePaymentsReports } from './standalone-payments';

import styles from './styles.module.scss';

export type Props = {
  isRegisterCombinedSalesSummaryReportsEnabled: boolean;
  shop: Shop;
};

export const Reports = ({
  isRegisterCombinedSalesSummaryReportsEnabled,
  shop,
}: Props) => {
  const {
    shopId,
    timezoneIdentifier: timezone,
    rosEnabled,
    registerMode,
  } = shop;

  const { data: achOnboardingLink } = useAchOnboardingLink(shopId);

  const orderActivityReportsPath =
    paths.getFinancialsReportsOrderActivityPath(shopId);
  const salesMixReportsPath = paths.getFinancialsReportsSalesMixPath(shopId);
  const taxReportsPath = paths.getFinancialsReportsBasicTaxPath(shopId);
  const orderReportsPath = paths.getFinancialsReportsOrdersPath(shopId);
  const registerSalesSummaryReportsPath =
    paths.getFinancialsReportsRegisterSalesSummaryPath(shopId);
  const combinedSalesSummaryReportsPath =
    paths.getFinancialsReportsCombinedSalesSummaryPath(shopId);
  const standalonePaymentsPath =
    paths.getFinancialsReportsStandalonePaymentsPath(shopId);

  const orderActivityReportsMatch = useMatch(orderActivityReportsPath);
  const salesMixReportsMatch = useMatch(salesMixReportsPath);
  const taxReportsMatch = useMatch(taxReportsPath);
  const orderReportsMatch = useMatch(orderReportsPath);
  const registerSalesSummaryReportsMatch = useMatch(
    registerSalesSummaryReportsPath,
  );
  const combinedSalesSummaryReportsMatch = useMatch(
    combinedSalesSummaryReportsPath,
  );
  const standalonePaymentsReportsMatch = useMatch(standalonePaymentsPath);

  const isSliceOsMode = registerMode?.name == RegisterModeName.Basic;

  const shopHasFullRegister = getShopHasFullRegister(shop);
  const shopHasBasicRegister = getShopHasBasicRegister(shop);

  return (
    <>
      {achOnboardingLink?.showOnboardingLink && (
        <AchEnrollmentBanner
          className={styles.achEnrollmentBanner}
          page="reports"
          redirectUrl={achOnboardingLink.redirectUrl}
        />
      )}
      <NavigationTabs className={styles.tabs}>
        <NavigationTabs.Link to={orderActivityReportsPath}>
          Order Activity
        </NavigationTabs.Link>
        <NavigationTabs.Link to={orderReportsPath}>Orders</NavigationTabs.Link>
        <NavigationTabs.Link to={taxReportsPath}>
          Tax Reports
        </NavigationTabs.Link>
        {shopHasFullRegister && (
          <NavigationTabs.Link to={salesMixReportsPath}>
            Sales Mix Reports
          </NavigationTabs.Link>
        )}
        {shopHasFullRegister &&
          (isRegisterCombinedSalesSummaryReportsEnabled ? (
            <NavigationTabs.Link to={combinedSalesSummaryReportsPath}>
              Sales Summary
            </NavigationTabs.Link>
          ) : (
            <NavigationTabs.Link to={registerSalesSummaryReportsPath}>
              Register Sales Summary
            </NavigationTabs.Link>
          ))}
        {shopHasBasicRegister ? (
          <NavigationTabs.Link to={standalonePaymentsPath}>
            Credit Card Payments
          </NavigationTabs.Link>
        ) : null}
      </NavigationTabs>
      {orderActivityReportsMatch && (
        <OrderActivityReports
          isRosEnabled={rosEnabled}
          isSliceOsMode={isSliceOsMode}
          shopId={shopId}
          shopTimezone={timezone}
        />
      )}
      {orderReportsMatch && (
        <OrderReports
          shopHasFullRegister={shopHasFullRegister}
          shopId={shopId}
          shopTimezone={timezone}
        />
      )}
      {taxReportsMatch && (
        <BasicTaxReports shopId={shopId} shopTimezone={timezone} />
      )}
      {salesMixReportsMatch && shopHasFullRegister && (
        <SalesMixReports shopId={shopId} shopTimezone={timezone} />
      )}
      {shopHasFullRegister &&
        (isRegisterCombinedSalesSummaryReportsEnabled
          ? combinedSalesSummaryReportsMatch && (
              <SalesSummaryReports shopId={shopId} shopTimezone={timezone} />
            )
          : registerSalesSummaryReportsMatch && (
              <RegisterSalesSummaryReports
                shopId={shopId}
                shopTimezone={timezone}
              />
            ))}
      {standalonePaymentsReportsMatch && shopHasBasicRegister ? (
        <StandalonePaymentsReports shopId={shopId} shopTimezone={timezone} />
      ) : null}
    </>
  );
};
