import RegisterPrinting from 'components/register/register-printing';
import Page from 'components/shared/page';
import { useCurrentShopId } from 'hooks/shops';

const RegisterPrintingPage = () => {
  const shopId = useCurrentShopId();

  return (
    <Page name="printer-settings" title="Printer Settings">
      <RegisterPrinting shopId={String(shopId)} />
    </Page>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default RegisterPrintingPage;
