import { toast } from 'react-toastify';

import ContentTile from 'components/shared/content-tile';
import Link from 'components/shared/link';
import Button, { ButtonVariant } from 'components/shared/slice-button';
import { useCreateSalesforceCaseMutation } from 'hooks/salesforce/use-create-salesforce-case';
import { useShopTraitsContext } from 'hooks/shops/use-shop-traits-context';
import useAnalytics from 'hooks/use-analytics';
import { marketingServices as marketingServicesUrl } from 'routes/external-urls';
import {
  SalesforceCaseType,
  SalesforceNote,
  SalesforceSubject,
} from 'types/salesforce';
import { ClickedExternalLinkType } from 'types/shared/analytics';
import { Shop } from 'types/shops';
import { User } from 'types/user';

import styles from './styles.module.scss';

type Props = {
  shopId: Shop['shopId'];
  user: User;
};

const AdvancedMarketingServices = ({ shopId, user }: Props) => {
  const { trackProductUpsellLinkClick } = useAnalytics();
  const shopTraits = useShopTraitsContext();

  const {
    mutate: createSalesforceCase,
    isLoading: isCreatingSalesforceCaseLoading,
  } = useCreateSalesforceCaseMutation();

  const onGetStartedClick = () => {
    if (!isCreatingSalesforceCaseLoading) {
      createSalesforceCase(
        {
          salesforceCase: {
            caseType: SalesforceCaseType.AMS,
            subject: SalesforceSubject.AMS,
          },
          note: SalesforceNote.AdvancedMarketingServicesNote,
          shopId: Number(shopId),
          user: user,
        },
        {
          onError: () =>
            toast.error(
              `We weren't able to process your request. Please contact your Partner Success Manager.`,
            ),
          onSuccess: () =>
            toast.success(
              'Great! We received your request. Our team will be in touch shortly.',
            ),
        },
      );
    }
    trackProductUpsellLinkClick({
      shopId: String(shopId),
      product: 'advanced marketing services',
      page: 'marketing services',
      linkLabel: 'get started',
      type: ClickedExternalLinkType.CTA,
      outcome: 'lead',
      shopIntegrationAdoption: shopTraits.shopIntegrationAdoption,
    });
  };

  const onLearnMoreClick = () => {
    trackProductUpsellLinkClick({
      shopId: String(shopId),
      product: 'advanced marketing services',
      page: 'marketing services',
      linkLabel: 'learn more',
      type: ClickedExternalLinkType.CTA,
      outcome: 'information',
      shopIntegrationAdoption: shopTraits.shopIntegrationAdoption,
    });
  };

  return (
    <div className={styles.wrapper}>
      <ContentTile className={styles.tile}>
        <div className={styles.title}>Advanced Marketing Services</div>
        <div className={styles.content}>
          Take the guesswork, legwork, and timesuck out of marketing. Our expert
          team handles your digital advertising, direct mail, menu cards and
          more with this new service.
        </div>

        <div className={styles.buttonWrapper}>
          <Button
            className={styles.getStartedButton}
            onClick={() => onGetStartedClick()}
            variant={ButtonVariant.Transparent}
          >
            <span>Get Started</span>
          </Button>
          <Link
            className={styles.learnMoreLink}
            onClick={() => onLearnMoreClick()}
            to={marketingServicesUrl}
            variant="undecorated"
          >
            Learn More
          </Link>
        </div>
      </ContentTile>
    </div>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default AdvancedMarketingServices;
