import PropTypes from 'prop-types';

import AddDebitCardTile from 'components/debit-card/form/add-debit-card-tile';

const AddDebitCardForm = ({
  children,
  clearErrors,
  control,
  errors,
  onSubmit,
  register,
  setError,
}) => {
  return (
    <form onSubmit={onSubmit} data-chameleon-target="Add New Debit Card Form">
      <AddDebitCardTile
        clearErrors={clearErrors}
        control={control}
        errors={errors}
        register={register}
        setError={setError}
      />
      {children}
    </form>
  );
};

AddDebitCardForm.propTypes = {
  children: PropTypes.node.isRequired,
  clearErrors: PropTypes.func.isRequired,
  control: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default AddDebitCardForm;
