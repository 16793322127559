import { getFormattedDateRange } from 'utilities/hours-analytics';

import styles from './styles.module.scss';

// Offset to make y-axis labels appear proper distance below chart.
const yOffset = 25;

type Props = {
  useShortDates?: boolean;
  weekEndDate: string;
  x: number;
  y: number;
};

export const XAxisTick = ({
  useShortDates = false,
  weekEndDate,
  x,
  y,
}: Props) => {
  const formattedDateRange = getFormattedDateRange(weekEndDate, useShortDates);

  return (
    <text className={styles.xAxisTickText} x={x} y={y + yOffset}>
      {formattedDateRange}
    </text>
  );
};
