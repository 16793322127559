import { ComponentPropsWithoutRef, forwardRef } from 'react';
import cx from 'classnames';

import styles from './styles.module.scss';

type Props = {
  isInvalid?: boolean;
} & ComponentPropsWithoutRef<'textarea'>;

// TODO: Replace `isInvalid` with `aria-invalid`.
const TextArea = forwardRef<HTMLTextAreaElement, Props>(function TextArea(
  { className, isInvalid = false, ...props }: Props,
  ref,
) {
  return (
    <textarea
      className={cx(styles.textarea, className)}
      aria-invalid={isInvalid}
      ref={ref}
      spellCheck
      {...props}
    />
  );
});

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default TextArea;
