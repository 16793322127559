import { Control, useWatch } from 'react-hook-form';

import {
  RegisterDiscountFormValues,
  RegisterDiscountType,
} from 'types/discounts';
import { toDollarString, toMoneyString } from 'utilities/currency';

type Props = {
  control: Control<RegisterDiscountFormValues>;
};

const TypeFieldsSummary = ({ control }: Props) => {
  const type = useWatch({
    control,
    name: 'type',
  });

  const amount = useWatch({
    control,
    name: 'amount',
  });

  const isFixed = type === RegisterDiscountType.Fixed;
  const isFlexible = type === RegisterDiscountType.Flexible;
  const isPercent = type === RegisterDiscountType.Percent;

  if (isFlexible) {
    return <>Variable Discount</>;
  }

  if (isFixed && amount) {
    return <>{toMoneyString(toDollarString(amount))}</>;
  }

  if (isPercent && amount) {
    return <>{toDollarString(amount)}%</>;
  }

  return null;
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default TypeFieldsSummary;
