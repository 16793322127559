import { useMatch } from 'react-router-dom';

import Header from 'components/shared/header';
import NavigationTabs from 'components/shared/navigation-tabs';
import useAnalytics from 'hooks/use-analytics';
import * as paths from 'routes/paths';
import { Shop } from 'types/shops';
import {
  SliceBrandingTabLabels,
  UtmContentTypeOptions,
} from 'types/slice-ordering';
import { generateCustomizedButtonUrl } from 'utilities/slice-ordering/slice-ordering';

import ButtonConfiguration from './button-configuration';
import GetCustomLink from './get-custom-link';

import styles from './styles.module.scss';

type Props = {
  shopId: Shop['shopId'];
  shopWebSlug: Shop['webSlug'];
};

const SliceOrdering = ({ shopId, shopWebSlug }: Props) => {
  const { trackClickedPartnerButtonTab } = useAnalytics();

  const createButtonPath = paths.shopPartnerButtonsCreateCustomButton(shopId);
  const customLinkPath = paths.shopPartnerButtonsGetLink(shopId);

  const createButtonPathMatch = useMatch(createButtonPath);
  const customLinkPathMatch = useMatch(customLinkPath);

  return (
    <>
      <Header title="Customize Buttons For Your Website" />
      <NavigationTabs className={styles.tabs}>
        <NavigationTabs.Link
          end
          onClick={() =>
            trackClickedPartnerButtonTab({
              shopId: String(shopId),
              tab: SliceBrandingTabLabels.CreateButton,
              previousTab: SliceBrandingTabLabels.GetLink,
            })
          }
          to={createButtonPath}
        >
          {SliceBrandingTabLabels.CreateButton}
        </NavigationTabs.Link>
        <NavigationTabs.Link
          onClick={() =>
            trackClickedPartnerButtonTab({
              shopId: String(shopId),
              tab: SliceBrandingTabLabels.GetLink,
              previousTab: SliceBrandingTabLabels.CreateButton,
            })
          }
          to={customLinkPath}
        >
          {SliceBrandingTabLabels.GetLink}
        </NavigationTabs.Link>
      </NavigationTabs>
      {createButtonPathMatch && (
        <ButtonConfiguration shopId={shopId} shopWebSlug={shopWebSlug} />
      )}
      {customLinkPathMatch && (
        <GetCustomLink
          customLink={generateCustomizedButtonUrl(
            UtmContentTypeOptions.Link,
            shopWebSlug ?? '',
          )}
          shopId={shopId}
        />
      )}
    </>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default SliceOrdering;
