import cx from 'classnames';

import IconButton from 'components/shared/icon-button';
import InfoIconSolid from 'images/info-solid.svg?react';

import TooltipWrapper from '../tooltip-wrapper';

import styles from './styles.module.scss';

type Props = {
  buttonClassName?: string;
  children: React.ReactNode;
  iconClassName?: string;
  label: string;
  tooltipClassName?: string;
};

const TooltipIconButton = ({
  buttonClassName,
  children,
  iconClassName,
  label,
  tooltipClassName,
}: Props) => {
  return (
    <TooltipWrapper
      targetElement={
        <IconButton
          className={cx(styles.button, buttonClassName)}
          label={label}
          icon={<InfoIconSolid className={cx(styles.icon, iconClassName)} />}
        />
      }
      tooltipClassName={tooltipClassName}
      tooltipContent={children}
    />
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default TooltipIconButton;
