import { Control, useWatch } from 'react-hook-form';

import { Link } from 'crust';

import useAnalytics from 'hooks/use-analytics';
import { SiteEditorFormValues } from 'types/site-editor';
import {
  defaultColorHex,
  defaultColorLabel,
  findSelectedOptions,
  getDirectWebUrl,
} from 'utilities/site-editor';

import styles from './styles.module.scss';

type Props = {
  className?: string;
  control: Control<SiteEditorFormValues>;
  domainName: string;
  shopId: string;
};

const SitePreviewLink = ({ control, domainName, shopId }: Props) => {
  const selectedColorCode = useWatch({
    control,
    name: 'colorCode',
  });

  const selectedFontCode = useWatch({
    control,
    name: 'fontCode',
  });

  const { trackClickedPreviewSiteCta } = useAnalytics();

  const { selectedColor, selectedFont } = findSelectedOptions({
    selectedColorCode,
    selectedFontCode,
  });

  return (
    <Link
      className={styles.previewLink}
      href={getDirectWebUrl(domainName, selectedColorCode, selectedFontCode)}
      onPress={() => {
        trackClickedPreviewSiteCta({
          shopId,
          backgroundColorHex: selectedColor?.hexValue ?? defaultColorHex,
          backgroundColorName: selectedColor?.label ?? defaultColorLabel,
          font: selectedFont,
        });
      }}
      variant="secondary"
    >
      Preview
    </Link>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default SitePreviewLink;
