// Reduces list of by-day order metrics to a summary of totals/averages for tile display.
export const calculateOrderSummaries = (dailyOrderData) => {
  const { offlineData, data: onlineData } = dailyOrderData;

  const defaultOnlineSummaries = {
    digitalCashOrders: 0,
    digitalCreditOrders: 0,
    digitalCashEarnings: 0,
    digitalCreditEarnings: 0,
    totalDigitalOrders: 0,
    totalDigitalEarnings: 0,
    digitalAverageOrderValue: 0,
  };

  const defaultOffineSummaries = {
    offlineOrdersCount: 0,
    offlineCashOrdersEarnings: 0,
    offlineCreditOrdersEarnings: 0,
  };

  if (!dailyOrderData) {
    return defaultOnlineSummaries;
  }

  const onlineAttributes = onlineData?.reduce((totals, values) => {
    totals.digitalCashOrders += values.cash.count;
    totals.digitalCreditOrders += values.credit.count;
    totals.digitalCashEarnings += values.cash.total;
    totals.digitalCreditEarnings += values.credit.total;
    return totals;
  }, defaultOnlineSummaries);

  const offlineAttributes = offlineData?.reduce((totals, values) => {
    totals.offlineCashOrdersEarnings += values.cash;
    totals.offlineCreditOrdersEarnings += values.credit;
    totals.offlineOrdersCount += values.count;
    return totals;
  }, defaultOffineSummaries);

  const totalDigitalOrders =
    onlineAttributes.digitalCashOrders +
    onlineAttributes.digitalCreditOrders +
    offlineAttributes.offlineOrdersCount;
  const totalDigitalEarnings =
    onlineAttributes.digitalCashEarnings +
    onlineAttributes.digitalCreditEarnings +
    offlineAttributes.offlineCashOrdersEarnings +
    offlineAttributes.offlineCreditOrdersEarnings;

  const combinedAttributes = {
    ...onlineAttributes,
    ...offlineAttributes,
    totalDigitalEarnings,
    totalDigitalOrders,
    // ticket to handle error where returned values = NaN:
    // https://app.clubhouse.io/slicelife/story/202071/catch-error-when-values-in-daily-summaries-are-nan
    digitalAverageOrderValue:
      totalDigitalEarnings && totalDigitalEarnings / totalDigitalOrders,
  };

  return combinedAttributes;
};
