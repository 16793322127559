import * as React from "react";
import { forwardRef } from "react";
const SvgAvg = ({
  title,
  titleId,
  ...props
}, ref) => {
  return /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", fill: "none", viewBox: "0 0 12 12", ref, "aria-labelledby": titleId, ...props }, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("rect", { width: 10.5, height: 10.5, x: 0.75, y: 0.75, stroke: "#358474", strokeDasharray: "3 3", strokeWidth: 1.5, rx: 3.25 }));
};
const ForwardRef = forwardRef(SvgAvg);
export default ForwardRef;
