import { useMutation, useQueryClient } from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { Shop } from 'types/shops';
import {
  SmartButtonFormValues,
  WebsiteIntegrationsSettings,
} from 'types/website-tools';
import { camelCaseKeys, snakeCaseKeys } from 'utilities/objects';
import { convertSmartButtonFormValuesToSettings } from 'utilities/website-tools';

import { getWebsiteIntegrationsSettingsQueryKey } from './use-website-integrations-settings';

export const useSaveSmartButtonsSettingsMutation = (shopId: Shop['shopId']) => {
  const { authenticatedFetch } = useApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (values: SmartButtonFormValues) => {
      const settings = convertSmartButtonFormValuesToSettings(values);
      const response = await authenticatedFetch.put(
        `api/management/v1/shops/${shopId}/third_party_web_setting`,
        snakeCaseKeys(settings),
      );

      return camelCaseKeys(response) as WebsiteIntegrationsSettings;
    },
    onSuccess: (updatedSettings) => {
      queryClient.setQueryData(
        getWebsiteIntegrationsSettingsQueryKey(shopId),
        updatedSettings,
      );
    },
  });
};
