import BrushStroke from 'images/register/register-splash/brush-stroke.svg';

import styles from './styles.module.scss';

export const FaqSection = () => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.headerText}>
          Frequently Asked Questions
          <img alt="brushStroke" src={BrushStroke} />
        </div>
      </div>
      <div className={styles.question}>
        <div className={styles.title}>
          How is Slice Register right for my pizzeria business?
        </div>
        {
          "Slice Register is the perfect fit for single-location pizzerias. Slice Register brings your online and offline operations together - all your orders in one place, whether made in person, on the phone, or through any of Slice's ordering options."
        }
      </div>

      <div className={styles.question}>
        <div className={styles.title}>
          How is Slice Register different from other POS systems?
        </div>
        {
          "Slice Register has been designed just for pizzerias. Unlike other POS systems, it's fast and easy to use — no complex installation or training required (but, as always, our team is available if you have any questions)."
        }
      </div>

      <div className={styles.question}>
        <div className={styles.title}>
          {"What's included with Slice Register?"}
        </div>
        Your Slice Register kit consists of a touchscreen tablet, protective
        case, base, a cash drawer, credit card terminal, router, and two
        printers — a receipt printer and a kitchen printer.
      </div>

      <div className={styles.question}>
        <div className={styles.title}>What support do you offer?</div>
        {
          "Slice Register is backed by our best-in-class customer service. There's no complex installation or training required and a dedicated specialist will get you started and walk you through everything. If you ever have any questions, a member of our team is available 24/7."
        }
      </div>

      <div className={styles.question}>
        <div className={styles.title}>
          Do I have to change my credit card processor?{' '}
        </div>
        {
          "Yes. To deliver on the promise of Slice Register, including customer management and cost savings through scale, Slice needs to manage your in-store processing. This will be to your benefit as you'll have the industry's best pricing with no hidden fees and one support number to call for any issues."
        }
      </div>

      <div className={styles.question}>
        <div className={styles.title}>
          Does Slice Register integrate with other services?{' '}
        </div>
        Toss those delivery app tablets into a drawer: You can integrate every
        major online ordering platform (Grubhub, DoorDash, Uber Eats, and 30+
        more) right into Slice Register.
      </div>
    </div>
  );
};
