export const EXPIRY_IN_30DAYS = 30;
export const EXPIRY_IN_14DAYS = 14;

// After some specific action is taken, for now creating a Salesforce case and dismissing Callout from the Homepage,
// we want to keep that for 30/14days using localStorage and after that period to return to initial state.
export const getExpiresAtDate = (expiryInDays: number) => {
  const today = new Date();
  const expiryDate = new Date(today);
  expiryDate.setDate(today.getDate() + expiryInDays);
  return expiryDate;
};

export const getIsStoredDateValid = (expiresAt?: string) => {
  if (!expiresAt) {
    return false;
  }

  const storedDate = new Date(expiresAt);
  const currentDate = new Date();
  return currentDate.getTime() < storedDate.getTime();
};

export const getIsStoredDateActive = (
  expiryInDays: number,
  clickedAt?: string,
) => {
  if (!clickedAt) {
    return false;
  }

  const today = new Date();
  const storedDate = new Date(clickedAt);
  const expiryDate = new Date(storedDate);
  expiryDate.setDate(storedDate.getDate() + expiryInDays);

  return expiryDate.getTime() > today.getTime();
};
