import PropTypes from 'prop-types';

import { shippingTypes } from 'utilities/constants';

import { Day } from './day';

import styles from './styles.module.scss';

const Hours = ({
  dataTestId,
  effectiveDate,
  hours,
  isUpdateInProgress,
  labelPrefix,
  onDailyHoursSubmit,
  shopTimezone,
  trackDailyHoursActions,
}) => {
  return (
    <div className={styles.hoursWrapper}>
      <Day
        ariaLabel={labelPrefix + shippingTypes.delivery.value}
        className={styles.delivery}
        effectiveDate={effectiveDate}
        hours={hours.delivery}
        isUpdateInProgress={isUpdateInProgress}
        onFormSubmit={(submittedHours, callbacks) =>
          onDailyHoursSubmit(
            submittedHours,
            shippingTypes.delivery.value,
            callbacks,
          )
        }
        shippingType={shippingTypes.delivery.value}
        shopTimezone={shopTimezone}
        trackDailyHoursActions={(buttonClicked, timeRange) =>
          trackDailyHoursActions(
            buttonClicked,
            shippingTypes.delivery.value,
            timeRange,
          )
        }
        dataTestId={`${dataTestId}-delivery`}
      />
      <Day
        aria-label={labelPrefix + shippingTypes.pickup.value}
        effectiveDate={effectiveDate}
        hours={hours.pickup}
        isUpdateInProgress={isUpdateInProgress}
        onFormSubmit={(submittedHours, callbacks) =>
          onDailyHoursSubmit(
            submittedHours,
            shippingTypes.pickup.value,
            callbacks,
          )
        }
        shippingType={shippingTypes.pickup.value}
        shopTimezone={shopTimezone}
        trackDailyHoursActions={(buttonClicked, timeRange) =>
          trackDailyHoursActions(
            buttonClicked,
            shippingTypes.pickup.value,
            timeRange,
          )
        }
        dataTestId={`${dataTestId}-pickup`}
      />
    </div>
  );
};

Hours.propTypes = {
  dataTestId: PropTypes.string,
  effectiveDate: PropTypes.string,
  hours: PropTypes.object,
  isUpdateInProgress: PropTypes.bool,
  labelPrefix: PropTypes.string,
  onDailyHoursSubmit: PropTypes.func,
  shopTimezone: PropTypes.string,
  trackDailyHoursActions: PropTypes.func,
};

Hours.defaultProps = {
  dataTestId: null,
  effectiveDate: '',
  hours: {},
  isUpdateInProgress: false,
  labelPrefix: '',
  onDailyHoursSubmit: () => {},
  shopTimezone: '',
  trackDailyHoursActions: () => {},
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default Hours;
