import { Link } from 'crust';

import FeatureHighlight from 'components/shared/feature-highlight';
import useAnalytics from 'hooks/use-analytics';
import * as paths from 'routes/paths';
import { Shop } from 'types/shops';

import styles from './styles.module.scss';

const linkLabel = 'View Statements';

type Props = {
  shopId: Shop['shopId'];
};

export const StatementsFeatureHighlight = ({ shopId }: Props) => {
  const { trackInternalFeatureLinkClick } = useAnalytics();

  const onViewStatementsClick = () => {
    trackInternalFeatureLinkClick({
      copy: 'View Statements',
      fromPage: 'payouts',
      shopId: shopId,
      toPage: 'statements',
    });
  };

  return (
    <FeatureHighlight
      buttonsWrapperClassName={styles.buttonsWrapper}
      containerClassName={styles.container}
      textClassName={styles.text}
      titleClassName={styles.title}
      primaryCta={
        <Link
          onPress={onViewStatementsClick}
          href={paths.getFinancialsStatementsPath(shopId)}
          size="small"
          variant="primary"
        >
          {linkLabel}
        </Link>
      }
      text="Navigate finances confidently with our revamped, easy-to-read statements."
      title="Better Statements"
    />
  );
};
