import { useCallback, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  createColumnHelper,
  PaginationState,
  SortingState,
  VisibilityState,
} from '@tanstack/react-table';
import moment from 'moment-timezone';

import ContentTile from 'components/shared/content-tile';
import Table from 'components/shared/table';
import TableAction from 'components/shared/table/action';
import PageControl from 'components/shared/table/page-control';
import VisuallyHidden from 'components/shared/visually-hidden';
import { useOrdersReportsQuery } from 'hooks/reports';
import { useTable } from 'hooks/shared';
import useAnalytics from 'hooks/use-analytics';
import {
  OrderReportsLocationState,
  OrdersReport,
  OrdersReportOrderType,
  ReportAnalyticsType,
  ReportStatus,
} from 'types/financials';

import { ReportHeader } from '../report-header';

import { OrdersReportForm } from './form';

import styles from './styles.module.scss';

const DEFAULT_COLUMN_SORT = {
  id: 'createdAt',
  desc: true,
} as const;

export type Props = {
  shopHasFullRegister: boolean;
  shopId: number;
  shopTimezone: string;
};

const OrderReports = ({ shopHasFullRegister, shopId, shopTimezone }: Props) => {
  const { trackDownloadReport } = useAnalytics();
  const location = useLocation();

  const locationState = location.state as OrderReportsLocationState | null;

  const getInitialOrderType = (): OrdersReportOrderType => {
    // The initial order type should first check to see if we came to this page through the Create a Report button
    // on the Financials Orders page. IF not then we need to check if we are ros enabled or not to decide if we default to
    // register or online orders
    if (locationState?.orderType) {
      return locationState.orderType;
    }

    return shopHasFullRegister ? 'register' : 'online';
  };

  const initialOrderType = getInitialOrderType();
  const initialStartDate = locationState?.startDate ?? '';
  const initialEndDate = locationState?.endDate ?? '';

  // Only auto-open form if we are coming to this page through the Create a Report button on the Financials/Orders page
  const initialShouldShowForm =
    locationState?.orderType === 'register' ||
    locationState?.orderType === 'online'
      ? true
      : false;

  const [sorting, setSorting] = useState<SortingState>([DEFAULT_COLUMN_SORT]);

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });

  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({
    reportType: shopHasFullRegister,
  });

  const {
    data: ordersReportsQueryResponse,
    isLoading: isOrdersReportsQueryLoading,
  } = useOrdersReportsQuery(
    shopId,
    pagination,
    sorting[0] ?? DEFAULT_COLUMN_SORT,
  );

  const [shouldShowForm, setShouldShowForm] = useState(initialShouldShowForm);

  const columns = useMemo(() => {
    const formatReportDate = (report: OrdersReport) => {
      return moment.tz(report.createdAt, shopTimezone).format('MM/DD/YYYY');
    };
    const helper = createColumnHelper<OrdersReport>();

    const formatDateRange = (from: string, to: string) =>
      `${moment.tz(from, shopTimezone).format('MM/DD/YYYY')} - ${moment
        .tz(to, shopTimezone)
        .format('MM/DD/YYYY')}`;

    return [
      helper.accessor((it) => formatDateRange(it.fromDate, it.toDate), {
        id: 'report',
        header: 'Report',
        enableSorting: false,
        cell(ctx) {
          return (
            <div>
              <span className={styles.dateRange}>{ctx.getValue()}</span>
              <br />
              Orders Report
            </div>
          );
        },
        meta: {
          className: styles.reportColumn,
        },
      }),

      helper.accessor(
        (it) =>
          it.report === 'offline_order_report'
            ? 'Register Orders'
            : 'Online Orders',
        {
          id: 'reportType',
          header: 'Report Type',
          enableSorting: false,
          meta: {
            className: styles.reportTypeColumn,
            mobileHeading: 'Type',
          },
        },
      ),

      helper.accessor(formatReportDate, {
        id: 'createdAt',
        header: 'Date Created',
        meta: {
          chameleonId: 'createdAt',
          className: styles.createdAtColumn,
          mobileHeading: 'Created',
        },
      }),

      helper.display({
        id: 'download',
        header() {
          return <VisuallyHidden>Download</VisuallyHidden>;
        },
        meta: {
          isActionColumn: true,
        },
        cell(ctx) {
          const report = ctx.row.original;
          const range = formatDateRange(report.fromDate, report.toDate);

          const isFailed = report.status === ReportStatus.Failure;
          const isSuccess = report.status === ReportStatus.Success;

          if (isSuccess && report.downloadUrl) {
            return (
              <TableAction
                className={styles.downloadLink}
                icon="download"
                onClick={() =>
                  trackDownloadReport({
                    shopId,
                    type: ReportAnalyticsType.Order,
                    createdDate: formatReportDate(report),
                    ordersReportDataSource:
                      report.report === 'offline_order_report'
                        ? 'register'
                        : 'online',
                    fileType: 'csv',
                  })
                }
                title={`Orders report for ${range}.`}
                to={report.downloadUrl}
              />
            );
          }

          // Success without a URL might as well be a failure.
          return (
            <span className={styles.downloadText}>
              {isFailed || isSuccess ? 'Failed' : 'Pending'}
            </span>
          );
        },
      }),
    ];
  }, [shopId, shopTimezone, trackDownloadReport]);

  const table = useTable({
    columns,
    data: ordersReportsQueryResponse?.data ?? [],
    manualPagination: true,
    manualSorting: true,
    onPaginationChange: setPagination,
    onColumnVisibilityChange: setColumnVisibility,
    onSortingChange: setSorting,
    pageCount: ordersReportsQueryResponse?.meta.pagination.pages ?? 1,
    state: {
      columnVisibility,
      isLoading: isOrdersReportsQueryLoading,
      pagination,
      sorting,
    },
    chameleonTableTitle: 'Orders reports',
  });

  const handleClickCreateReport = useCallback(
    () => setShouldShowForm((current) => !current),
    [],
  );

  return (
    <>
      <ReportHeader onClickCreateReport={handleClickCreateReport} />
      {shouldShowForm && (
        <OrdersReportForm
          shopId={shopId}
          shopTimezone={shopTimezone}
          closeForm={() => setShouldShowForm(false)}
          initialOrderType={initialOrderType}
          initialStartDate={initialStartDate}
          initialEndDate={initialEndDate}
          shopHasFullRegister={shopHasFullRegister}
        />
      )}
      <ContentTile>
        <Table className={styles.table} table={table} />
        <PageControl
          currentPage={table.getState().pagination.pageIndex + 1}
          setPage={(page: number) => table.setPageIndex(page - 1)}
          totalPages={table.getPageCount()}
        />
      </ContentTile>
    </>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default OrderReports;
