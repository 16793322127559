import { Menu, MenuButton, MenuItem } from '@szhsin/react-menu';
import cx from 'classnames';

import { Icon } from 'crust';

import CaretIcon from 'images/caret.svg?react';

import styles from './styles.module.scss';

export type FilterOption = {
  label: string;
  value: string;
};

type Props = {
  filterBy: string;
  filterOptions: FilterOption[];
  selectedStatus: FilterOption;
  setSelectedStatus: (status: string) => void;
  onItemClick?: (status: string) => void;
};

const FilterDropdown = ({
  filterBy,
  filterOptions,
  selectedStatus,
  setSelectedStatus,
  onItemClick,
}: Props) => {
  return (
    <div>
      <div className={styles.filterBy}>{`Filter by ${filterBy}`}</div>
      <div className={styles.dropdownContainer}>
        <Menu
          direction="bottom"
          menuButton={({ open }) => (
            <MenuButton className={styles.menuButton}>
              <span className={styles.menuButtonText}>
                {selectedStatus.label}
              </span>
              <CaretIcon className={open ? styles.caretUp : styles.caretDown} />
            </MenuButton>
          )}
          onItemClick={({ value }) => {
            setSelectedStatus(value);

            if (onItemClick) {
              onItemClick(value.replace(/_/g, ' '));
            }
          }}
        >
          {filterOptions.map(({ label, value }) => {
            const isActive = value === selectedStatus.value;

            return (
              <MenuItem
                className={cx(styles.menuItem, isActive && styles.selected)}
                key={value}
                onClick={() => setSelectedStatus(value)}
                value={value}
              >
                {isActive && (
                  <Icon className={styles.activeIcon} icon="checkmark" />
                )}
                <span>{label}</span>
              </MenuItem>
            );
          })}
        </Menu>
      </div>
    </div>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default FilterDropdown;
