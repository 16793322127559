import { FormEventHandler, PropsWithChildren } from 'react';
import { Control, FieldErrors } from 'react-hook-form';

import { OnlineDiscountFormValues } from 'types/discounts';

import { TypeFields } from './type-fields';

import styles from './styles.module.scss';

type Props = {
  control: Control<OnlineDiscountFormValues>;
  errors: FieldErrors<OnlineDiscountFormValues>;
  onSubmit: FormEventHandler<HTMLFormElement>;
};

export const AutomaticDiscountForm = ({
  children,
  control,
  errors,
  onSubmit,
}: PropsWithChildren<Props>) => (
  <form onSubmit={onSubmit} className={styles.form}>
    <TypeFields
      amountError={errors.amount}
      control={control}
      className={styles.tile}
    />
    {children}
  </form>
);
