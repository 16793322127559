import PropTypes from 'prop-types';

import Link from 'components/shared/link';
import LockIcon from 'images/lock.svg?react';
import { shopClosings } from 'routes/paths';

import styles from './styles.module.scss';

const EmptyClosing = ({ shopId }) => (
  <div className={styles.container} data-testid="no-upcoming-closings">
    <LockIcon className={styles.icon} aria-hidden="true" />
    <div className={styles.title}>You don’t have any upcoming closings</div>
    <div className={styles.description}>
      If you wish to close your restaurant for pickup/delivery services for more
      than one day,{' '}
      <Link to={shopClosings(shopId)} className={styles.link}>
        add a closing.
      </Link>
    </div>
  </div>
);

EmptyClosing.propTypes = {
  shopId: PropTypes.string.isRequired,
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default EmptyClosing;
