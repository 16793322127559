import { MenuItem } from '@szhsin/react-menu';

import { Product, ProductType } from 'types/shared/stock-dropdown';

// Out of stock options in hours relative to now.
const durations = [0, 12, 24, Infinity];

type Props = {
  entity: Product | ProductType;
};

const StockMenuItems = ({ entity }: Props) => {
  const isProduct = 'categoryId' in entity;
  const { id, unavailable, unavailableUntil } = entity;

  const getDisabled = (
    duration: number,
    unavailable: boolean,
    unavailableUntil: string | null,
  ) => {
    if (duration === 0 && !unavailable) {
      return true;
    }

    if (duration === Infinity && unavailable && unavailableUntil == null) {
      return true;
    }

    return false;
  };

  const getLabel = (duration: number) => {
    if (duration === 0) {
      return 'In Stock';
    }

    if (duration === Infinity) {
      return 'Out of Stock (Indefinitely)';
    }

    return `Out of Stock (${duration} Hours)`;
  };

  return (
    <>
      {durations.map((duration) => (
        <MenuItem
          key={duration}
          value={{ id, isProduct, duration }}
          disabled={getDisabled(duration, unavailable, unavailableUntil)}
        >
          {getLabel(duration)}
        </MenuItem>
      ))}
    </>
  );
};

export { StockMenuItems };
