import { Control, useController } from 'react-hook-form';

import { SelectMenuOption } from 'components/website-tools/form/select-menu-option';
import useAnalytics from 'hooks/use-analytics';
import { ShopTraits } from 'types/shops';
import {
  SmartButtonFormValues,
  WebsiteIntegrationsAnalyticsPageName,
} from 'types/website-tools';
import {
  modeOptions,
  smartButtonAnalyticsModeStrings,
} from 'utilities/website-tools';

import SelectMenu from '../../../form/select-menu';

type Props = {
  buttonClassName?: string;
  control: Control<SmartButtonFormValues>;
  setIsFormChanged: (isFormChanged: boolean) => void;
  shopId: number;
  shopTraits: ShopTraits;
};

const ModeDropdown = ({
  buttonClassName,
  control,
  setIsFormChanged,
  shopId,
  shopTraits,
}: Props) => {
  const {
    field: { onChange, value },
    fieldState: { isDirty },
  } = useController({
    control,
    name: 'mode',
  });

  const { trackClickedWebsiteIntegrationsEditorDropdown } = useAnalytics();

  const selectedItem = modeOptions.find((option) => option.value === value);

  const handleOnBlur = () => {
    trackClickedWebsiteIntegrationsEditorDropdown({
      field: 'mode',
      shopId: String(shopId),
      isActive: String(shopTraits.isSmartButtonActive),
      optionSelected: isDirty ? smartButtonAnalyticsModeStrings[value] : null,
      page: WebsiteIntegrationsAnalyticsPageName.SmartButtons,
    });
  };

  return (
    <SelectMenu
      buttonClassName={buttonClassName}
      buttonId="mode-dropdown"
      label={selectedItem?.label ?? modeOptions[0].label}
      onChange={(value) => {
        onChange(value);
        setIsFormChanged(true);
      }}
      onBlur={handleOnBlur}
    >
      {modeOptions.map((option, index) => {
        const isActive = selectedItem?.value === option.value;

        return (
          <SelectMenuOption
            isActive={isActive}
            key={index}
            label={option.label}
            value={option.value}
          />
        );
      })}
    </SelectMenu>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default ModeDropdown;
