import {
  ComponentPropsWithRef,
  ElementRef,
  ForwardedRef,
  forwardRef,
} from 'react';
import cx from 'classnames';

import styles from './styles.module.scss';

export enum ButtonVariant {
  Primary = 'primary',
  Secondary = 'secondary',
  Tertiary = 'tertiary',
  LinkStylePrimary = 'linkStylePrimary',
  LinkStyleSecondary = 'linkStyleSecondary',
  CompactGroupOption = 'compactGroupOption',
  Transparent = 'transparent',
}

export type ButtonProps = {
  isSelected?: boolean;
  variant?: ButtonVariant;
} & ComponentPropsWithRef<'button'>;

const Button = forwardRef(function Button(
  {
    children,
    className,
    isSelected = false,
    type = 'button',
    variant = ButtonVariant.Primary,
    ...rest
  }: Omit<ButtonProps, 'ref'>,
  ref: ForwardedRef<ElementRef<'button'>>,
) {
  const variantOption = cx(
    variant === ButtonVariant.Primary && styles.primary,
    variant === ButtonVariant.Secondary && styles.secondary,
    variant === ButtonVariant.Tertiary && styles.tertiary,
    variant === ButtonVariant.LinkStylePrimary && styles.linkStylePrimary,
    variant === ButtonVariant.LinkStyleSecondary && styles.linkStyleSecondary,
    variant === ButtonVariant.CompactGroupOption && styles.compactGroupOption,
    variant === ButtonVariant.Transparent && styles.transparent,
    isSelected && styles.isSelected,
    className,
  );

  return (
    <button ref={ref} className={variantOption} type={type} {...rest}>
      {children}
    </button>
  );
});

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default Button;
