import { useMemo } from 'react';
import moment from 'moment-timezone';

import Button from 'components/shared/slice-button';
import Modal from 'components/shared/slice-modal';
import CalendarCheckIcon from 'images/calendar-check.svg?react';
import FileSizeIcon from 'images/file-size.svg?react';
import FileTypeIcon from 'images/file-type.svg?react';
import InfoSolidIcon from 'images/info-solid.svg?react';
import { AssetLibraryAsset } from 'types/asset-library';
import { isAssetImage, isAssetVideo } from 'utilities/asset-library';

import styles from './styles.module.scss';

type Props = {
  downloadAssetImage: (
    asset: AssetLibraryAsset,
    page: 'Social Media' | 'Asset Modal',
  ) => Promise<void>;
  isOpen: boolean;
  modalAsset: AssetLibraryAsset;
  onClose: () => void;
  serviceName: string;
};

const AssetModal = ({
  downloadAssetImage,
  isOpen,
  modalAsset,
  onClose,
  serviceName,
}: Props) => {
  const { description, uri, title, createdAt, fileType, fileSize } = modalAsset;

  const nowMoment = moment();
  const createdAtMoment = moment(createdAt);
  const daysAgo = moment.duration(nowMoment.diff(createdAtMoment)).asDays();
  const daysAgoRounded = Math.floor(daysAgo);

  const assetType = useMemo(() => fileType.split('/')[1], [fileType]);

  let publishedText = '';

  if (daysAgoRounded === 0) {
    publishedText = 'Published today';
  } else if (daysAgoRounded === 1) {
    publishedText = 'Published 1 day ago';
  } else {
    publishedText = `Published ${daysAgoRounded} days ago`;
  }

  return (
    <Modal
      isOpen={isOpen}
      header={<span className={styles.modalHeader}>{serviceName}</span>}
      onRequestClose={onClose}
      contentClassName={styles.modalContent}
    >
      <div className={styles.postModalContent}>
        {isAssetImage(modalAsset) && (
          <img
            src={`${uri}?w=416&fit=max`}
            alt={title}
            className={styles.postImage}
          />
        )}
        {isAssetVideo(modalAsset) && (
          // We currently have no way to provide a transcription.
          // eslint-disable-next-line jsx-a11y/media-has-caption
          <video className={styles.postVideo} src={uri} controls />
        )}
        <div className={styles.textContent}>
          <div className={styles.postTitle}>{title}</div>
          <div className={styles.postDescription}>{description}</div>
          <div className={styles.additionalInfo}>
            <CalendarCheckIcon className={styles.icon} />
            <div className={styles.infoText}>{publishedText}</div>
          </div>
          <div className={styles.additionalInfo}>
            <FileSizeIcon className={styles.icon} />
            <div className={styles.infoText}>{`File Size: ${fileSize}`}</div>
          </div>
          <div className={styles.additionalInfo}>
            <FileTypeIcon className={styles.icon} />
            <div className={styles.infoText}>{`File Type: ${assetType}`}</div>
          </div>
          <Button
            className={styles.downloadButton}
            onClick={() => downloadAssetImage(modalAsset, 'Asset Modal')}
            data-chameleon-target="Branding Studio Download Button"
          >
            Download
          </Button>
          <div className={styles.saveToCameraNotice}>
            <div className={styles.saveToCameraIcon}>
              <InfoSolidIcon className={styles.icon} />
            </div>
            <h3 className={styles.noticeText}>
              To save directly to your camera roll on an iOS device, hold down
              on the image or video and click Add to Photos.
            </h3>
          </div>
        </div>
      </div>
    </Modal>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default AssetModal;
