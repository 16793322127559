import { useMemo } from 'react';

import { TimePeriod } from 'types/hours-analytics';
import { capitalize } from 'utilities/strings';

import MetricTile, {
  MetricTileContent,
  MetricTileHeader,
  MetricTileTitle,
  MetricTileValue,
} from '../metric-tile';

import styles from './styles.module.scss';

type Props = {
  dataTestId: string;
  header: string;
  totals: TimePeriod | null;
  totalsSubregion: TimePeriod | null;
};

const UnavailabilityDeliveryPickup = ({
  dataTestId,
  header,
  totals,
  totalsSubregion,
}: Props) => {
  const { totalsText } = useMemo(() => {
    // Format function for the message that needs to be displayed to show only the absolute values bigger than 0
    const formatTotalPeriod = (value: number, period: string) => {
      const positiveValue = Math.abs(value);
      if (positiveValue >= 1) {
        return positiveValue + period + ' ';
      } else {
        return '';
      }
    };

    const subregionDisplayDuration = (
      hours: number,
      minutes: number,
    ): string => {
      if (minutes === 0) {
        return hours > 1
          ? formatTotalPeriod(hours, ' hours')
          : formatTotalPeriod(hours, ' hour');
      }

      return formatTotalPeriod(hours, 'hr') + formatTotalPeriod(minutes, 'min');
    };

    let totalsText;

    // Positive totals = Shop unavailability is "more than" the subregion average (i.e., poorer availability).
    // Negative totals = Shop unavailability is "less than" the subregion average (i.e., better availability).
    if (totals === null) {
      totalsText = `You have no ${header} hours set up.`;
    } else if (totalsSubregion === null) {
      totalsText = 'Calculating. Check back soon.';
    } else if (totalsSubregion.hours > 0 || totalsSubregion.minutes > 0) {
      totalsText =
        subregionDisplayDuration(
          totalsSubregion.hours,
          totalsSubregion.minutes,
        ) + 'more than average local shop';
    } else if (totalsSubregion.hours < 0 || totalsSubregion.minutes < 0) {
      totalsText =
        subregionDisplayDuration(
          totalsSubregion.hours,
          totalsSubregion.minutes,
        ) + 'less than average local shop';
    } else {
      totalsText = 'Equal to the average local shop';
    }
    return { totalsText };
  }, [header, totals, totalsSubregion]);

  return (
    <MetricTile className={styles.tile} dataTestId={dataTestId}>
      <MetricTileContent>
        <MetricTileHeader>
          <MetricTileTitle>{capitalize(header)} Paused/Closed</MetricTileTitle>
        </MetricTileHeader>
        <div className={styles.wrapper}>
          <MetricTileValue
            dataTestId="unavailability-total-hours"
            value={totals ? totals.hours : 0}
            type="hours"
          />
          <MetricTileValue
            dataTestId="unavailability-total-minutes"
            value={totals ? totals.minutes : 0}
            type="minutes"
          />
        </div>

        <div className={styles.text}>{totalsText}</div>
      </MetricTileContent>
    </MetricTile>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default UnavailabilityDeliveryPickup;
