import { ComponentPropsWithoutRef } from 'react';
import cx from 'classnames';

import styles from './styles.module.scss';

type Props = {
  characterLimit?: number;
  value: string;
} & Omit<ComponentPropsWithoutRef<'input'>, 'value'>;

const TextInput = ({
  className,
  characterLimit,
  value = '',
  ...props
}: Props) => {
  const isMaxCharacterLimit = value.length === characterLimit;

  return (
    <div className={styles.container}>
      <input
        className={cx(styles.input, className)}
        maxLength={characterLimit}
        type="text"
        value={value}
        {...props}
      />
      {characterLimit && (
        <span
          className={cx(
            styles.counter,
            isMaxCharacterLimit && styles.counterMax,
          )}
        >
          {value.length} / {characterLimit}
        </span>
      )}
    </div>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default TextInput;
