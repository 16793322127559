import { toast } from 'react-toastify';

export const INVALID_SUBMIT_MESSAGE =
  'Please complete the highlighted fields to continue.';
export const ERROR_SUBMIT_MESSAGE =
  'Sorry, something went wrong. Please try again.';
export const WERE_NOT_ABLE_TO_PROCESS_MESSAGE =
  "We weren't able to process your request. Please contact your Partner Success Manager.";

export const showInvalidSubmitToast = (): void => {
  toast.error(INVALID_SUBMIT_MESSAGE);
};

export const showUnexpectedErrorToast = (): void => {
  toast.error(ERROR_SUBMIT_MESSAGE);
};

export const showWereNotAbleToProcessErrorToast = (): void => {
  toast.error(WERE_NOT_ABLE_TO_PROCESS_MESSAGE);
};
