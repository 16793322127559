import {
  Control,
  useFieldArray,
  UseFormGetValues,
  UseFormSetValue,
  UseFormTrigger,
} from 'react-hook-form';
import cx from 'classnames';

import { Checkbox } from 'crust';

import Label from 'components/shared/label';
import { DayOfWeek, MenuCategoryFormValues } from 'types/menu/category';

import { AddAvailabilityButton } from './add-button';
import Availability from './availability';

import styles from './styles.module.scss';

type Props = {
  control: Control<MenuCategoryFormValues>;
  dayFieldIndex: number;
  getValues: UseFormGetValues<MenuCategoryFormValues>;
  hasDaysError?: boolean;
  name: DayOfWeek;
  setValue: UseFormSetValue<MenuCategoryFormValues>;
  trigger: UseFormTrigger<MenuCategoryFormValues>;
};

export const Day = ({
  control,
  dayFieldIndex,
  getValues,
  hasDaysError,
  name,
  setValue,
  trigger,
}: Props) => {
  const availabilitiesFieldName =
    `days.${dayFieldIndex}.availabilities` as const;

  const availabilities = useFieldArray({
    control,
    name: availabilitiesFieldName,
  });

  const isSelected = availabilities.fields.length > 0;

  const handleChangeIsSelected = (isChecked: boolean) => {
    if (isChecked) {
      availabilities.append({ start: '00:00', end: '23:59' });
    } else {
      availabilities.remove();
    }

    trigger('days');
  };

  return (
    <div className={cx(styles.day, isSelected && styles.selected)}>
      <Checkbox
        isSelected={isSelected}
        className={styles.checkbox}
        isInvalid={hasDaysError}
        label={name}
        onChange={handleChangeIsSelected}
      />
      {isSelected && (
        <>
          <div className={styles.form}>
            <Label>Start</Label>
            <Label>End</Label>
            {availabilities.fields.map((availability, index) => (
              <Availability
                availabilityFieldIndex={index}
                control={control}
                day={name}
                dayFieldIndex={dayFieldIndex}
                getValues={getValues}
                isRemoveable={availabilities.fields.length > 1}
                key={availability.id}
                removeField={availabilities.remove}
                setValue={setValue}
                trigger={trigger}
              />
            ))}
          </div>
          <AddAvailabilityButton
            control={control}
            dayFieldIndex={dayFieldIndex}
            appendField={availabilities.append}
          />
        </>
      )}
    </div>
  );
};
