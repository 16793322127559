import { useCallback, useEffect, useMemo } from 'react';
import {
  Control,
  useController,
  UseFormGetValues,
  UseFormSetValue,
  UseFormTrigger,
  useWatch,
} from 'react-hook-form';
import { SingleValue } from 'react-select';
import cx from 'classnames';

import FormFeedback from 'components/shared/form-feedback';
import { DeleteButton } from 'components/shared/icon-button';
import Select from 'components/shared/slice-select';
import VisuallyHiddenLabel from 'components/shared/visually-hidden/label';
import {
  DayOfWeek,
  MenuCategoryFormEndOption,
  MenuCategoryFormStartOption,
  MenuCategoryFormValues,
} from 'types/menu/category';
import {
  getAvailabilityOptions,
  getContiguousEnd,
  getIsEndOverlapping,
  getIsStartBeforeEnd,
  getIsStartOverlapping,
} from 'utilities/menu';

import styles from './styles.module.scss';

type Props = {
  availabilityFieldIndex: number;
  control: Control<MenuCategoryFormValues>;
  day: DayOfWeek;
  dayFieldIndex: number;
  getValues: UseFormGetValues<MenuCategoryFormValues>;
  isRemoveable: boolean;
  removeField: (index: number) => void;
  setValue: UseFormSetValue<MenuCategoryFormValues>;
  trigger: UseFormTrigger<MenuCategoryFormValues>;
};

const Availability = ({
  availabilityFieldIndex,
  control,
  day,
  dayFieldIndex,
  getValues,
  isRemoveable,
  removeField,
  setValue,
  trigger,
}: Props) => {
  const availabilitiesFieldName =
    `days.${dayFieldIndex}.availabilities` as const;

  const startFieldName =
    `${availabilitiesFieldName}.${availabilityFieldIndex}.start` as const;
  const startInputId = `start-time-${dayFieldIndex}-${availabilityFieldIndex}-select`;
  const startLabelId = `start-time-${dayFieldIndex}-${availabilityFieldIndex}-label`;

  const endFieldName =
    `${availabilitiesFieldName}.${availabilityFieldIndex}.end` as const;
  const endInputId = `end-time-${dayFieldIndex}-${availabilityFieldIndex}-select`;
  const endLabelId = `end-time-${dayFieldIndex}-${availabilityFieldIndex}-label`;

  // Validation of the start and end times is mostly for showing errors for bad
  // data created prior to sc-329538.
  const validateStart = useCallback(
    (time: string) => {
      if (time) {
        // Empty string caught by required validation.
        const others = getValues(availabilitiesFieldName).slice(
          0,
          availabilityFieldIndex,
        );

        if (others.some((other) => getIsStartOverlapping(time, other))) {
          return 'The start time cannot overlap another availability.';
        }
      }

      return true;
    },
    [availabilityFieldIndex, availabilitiesFieldName, getValues],
  );

  const { field: startField, fieldState: startFieldState } = useController({
    control,
    name: startFieldName,
    rules: {
      required: 'Please select a start time.',
      validate: validateStart,
    },
  });

  const validateEnd = useCallback(
    (end: string) => {
      const start = getValues(startFieldName);

      if (start && end) {
        if (!getIsStartBeforeEnd({ start, end })) {
          return 'The end time must be later than the start time.';
        }

        const others = getValues(availabilitiesFieldName).slice(
          0,
          availabilityFieldIndex,
        );

        if (others.some((other) => getIsEndOverlapping(start, end, other))) {
          return 'The end time cannot overlap another availability.';
        }
      }

      return true;
    },
    [
      availabilityFieldIndex,
      availabilitiesFieldName,
      getValues,
      startFieldName,
    ],
  );

  const { field: endField, fieldState: endFieldState } = useController({
    control,
    name: endFieldName,
    rules: {
      required: 'Please select an end time.',
      validate: validateEnd,
    },
  });

  const availabilities = useWatch({
    control,
    name: availabilitiesFieldName,
  });

  const { endOptions, selectedEndOption, selectedStartOption, startOptions } =
    useMemo(() => {
      const currentAvailability = availabilities[availabilityFieldIndex];
      const otherAvailabilities = availabilities.filter(
        (_, i) => i !== availabilityFieldIndex,
      );

      const { endOptions, startOptions } = getAvailabilityOptions(
        currentAvailability!,
        otherAvailabilities,
      );
      const selectedEndOption = endOptions.find(
        (it) => it.value === endField.value,
      );
      const selectedStartOption = startOptions.find(
        (it) => it.value === startField.value,
      );

      return {
        endOptions,
        selectedEndOption,
        selectedStartOption,
        startOptions,
      };
    }, [
      availabilityFieldIndex,
      availabilities,
      endField.value,
      startField.value,
    ]);

  // As noted elsewhere, data created prior to sc-329538 can be invalid. To help
  // users correct the data we will show the validation messages when the form
  // is first loaded rather than waiting until they submit the form or
  // interact with the inputs.
  useEffect(() => {
    if (selectedStartOption?.isDisabled && !startFieldState.isTouched) {
      trigger(startFieldName);
    }
  }, [
    selectedStartOption?.isDisabled,
    startFieldName,
    startFieldState.isTouched,
    trigger,
  ]);

  useEffect(() => {
    if (selectedEndOption?.isDisabled && !endFieldState.isTouched) {
      trigger(endFieldName);
    }
  }, [
    selectedEndOption?.isDisabled,
    endFieldName,
    endFieldState.isTouched,
    trigger,
  ]);

  // When the times of the current availability are changed, we need to
  // re-validate the times of the succeeding availabilities because they may no
  // longer be overlapping.
  const triggerSucceedingAvailabilities = () => {
    for (let i = availabilityFieldIndex; i < availabilities.length; i++) {
      trigger(`${availabilitiesFieldName}.${i}.start`);
      trigger(`${availabilitiesFieldName}.${i}.end`);
    }
  };

  const handleChangeStart = (
    option: SingleValue<MenuCategoryFormStartOption>,
  ) => {
    if (option) {
      startField.onChange(option.value);

      // Ensure a valid end time.
      setValue(
        endFieldName,
        getContiguousEnd(getValues(endFieldName), option),
        // getContiguousEnd ensures validity.
        { shouldValidate: false },
      );
      triggerSucceedingAvailabilities();
    }
  };

  const handleChangeEnd = (option: SingleValue<MenuCategoryFormEndOption>) => {
    if (option) {
      endField.onChange(option.value);
      triggerSucceedingAvailabilities();
    }
  };

  return (
    <>
      <VisuallyHiddenLabel htmlFor={startInputId} id={startLabelId}>
        {day} start time {availabilityFieldIndex + 1}
      </VisuallyHiddenLabel>
      <Select
        aria-labelledby={startLabelId}
        className={cx(styles.select, styles.start)}
        inputId={startInputId}
        isInvalid={startFieldState.error != null}
        name={startField.name}
        onBlur={startField.onBlur}
        onChange={handleChangeStart}
        options={startOptions}
        ref={startField.ref}
        value={selectedStartOption}
      />
      <VisuallyHiddenLabel htmlFor={endInputId} id={endLabelId}>
        {day} end time {availabilityFieldIndex + 1}
      </VisuallyHiddenLabel>
      <Select
        aria-labelledby={endLabelId}
        className={cx(styles.select, styles.end)}
        inputId={endInputId}
        isInvalid={endFieldState.error != null}
        name={endField.name}
        onBlur={endField.onBlur}
        onChange={handleChangeEnd}
        options={endOptions}
        ref={endField.ref}
        value={selectedEndOption}
      />
      <DeleteButton
        className={styles.delete}
        label="Delete"
        disabled={!isRemoveable}
        onClick={() => removeField(availabilityFieldIndex)}
      />
      <FormFeedback className={styles.feedback}>
        {startFieldState.error?.message}
      </FormFeedback>
      <FormFeedback className={styles.feedback}>
        {endFieldState.error?.message}
      </FormFeedback>
    </>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default Availability;
