import { Control, useWatch } from 'react-hook-form';

import { OnlineDiscountFormValues } from 'types/discounts';

type Props = {
  control: Control<OnlineDiscountFormValues>;
};

export const TypeFieldsSummary = ({ control }: Props) => {
  const amount = useWatch({
    control,
    name: 'amount',
  });

  return <>{amount ? `${amount}%` : null}</>;
};
