import { ForwardedRef, forwardRef } from 'react';
import { NumericFormat, NumericFormatProps } from 'react-number-format';

import Input, { InputProps } from 'components/shared/input';

type Props = NumericFormatProps<InputProps>;

const NumberInput = forwardRef(function NumberInput(
  props: Props,
  ref: ForwardedRef<HTMLInputElement>,
) {
  return (
    <NumericFormat
      allowNegative={false}
      customInput={Input}
      decimalScale={2}
      fixedDecimalScale
      getInputRef={ref}
      inputMode="numeric"
      placeholder="e.g. 5.00"
      thousandSeparator=","
      {...props}
    />
  );
});

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default NumberInput;
