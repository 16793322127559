import CustomerAnalytics from 'components/customers';
import { UnexpectedError } from 'components/shared/error';
import Loading from 'components/shared/loading';
import Page from 'components/shared/page';
import { useCurrentUserQuery } from 'hooks/shared';
import { useCurrentShopId, useShopQuery } from 'hooks/shops';

import styles from './styles.module.scss';

const CustomerAnalyticsPage = () => {
  const shopId = useCurrentShopId();

  const {
    data: user,
    isError: isUserError,
    isLoading: isUserLoading,
  } = useCurrentUserQuery();

  const {
    data: shop,
    isError: isShopError,
    isLoading: isShopLoading,
  } = useShopQuery(shopId);

  let content;
  if (isShopLoading || isUserLoading) {
    content = <Loading />;
  } else if (isShopError || isUserError) {
    content = <UnexpectedError />;
  } else {
    content = <CustomerAnalytics shop={shop} user={user} />;
  }

  return (
    <Page
      name="Customer Analytics"
      title="Customer Analytics"
      contentClassName={styles.content}
    >
      {content}
    </Page>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default CustomerAnalyticsPage;
