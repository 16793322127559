import AllCustomersIcon from 'images/all-customers-icon.svg?react';
import DeliveryCustomersIcon from 'images/delivery-customers-icon.svg?react';
import LoyalCustomerIcon from 'images/loyal-customer-icon.svg?react';
import NewCustomerIcon from 'images/new-customer-icon.svg?react';
import PickupCustomersIcon from 'images/pickup-customers-icon.svg?react';
import RepeatCustomerIcon from 'images/repeat-customer-icon.svg?react';
import ValuableCustomersIcon from 'images/valuable-customers-icon.svg?react';
import { CustomerList } from 'types/customers';

import styles from './styles.module.scss';

const listIcons = {
  [CustomerList.All]: AllCustomersIcon,
  [CustomerList.New]: NewCustomerIcon,
  [CustomerList.Loyal]: LoyalCustomerIcon,
  [CustomerList.Repeat]: RepeatCustomerIcon,
  [CustomerList.Pickup]: PickupCustomersIcon,
  [CustomerList.Delivery]: DeliveryCustomersIcon,
  [CustomerList.MostFrequent]: ValuableCustomersIcon,
  [CustomerList.MostValuable]: ValuableCustomersIcon,
};

type Props = {
  customerList: CustomerList;
};

const KnowYourCustomersIcon = ({ customerList }: Props) => {
  const Icon = listIcons[customerList];

  return <Icon aria-hidden="true" className={styles.icon} />;
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default KnowYourCustomersIcon;
