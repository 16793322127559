import { ShopTraits } from 'types/shops';

// Based on the backend, there is actually only two widget types, Banner and Floating Button. A Sticker is
// a Layout option for Button widgets. However in the UI we want Sticker to appear as an option under widget types
export enum WidgetType {
  Banner = 'banner',
  Button = 'floating_button',
  Sticker = 'sticker',
}

export enum Theme {
  Simple = 'dough',
  Iconic = 'saucy',
  Elegant = 'crispy',
  Slice = 'slice',
}

export enum ThemeVariant {
  Dark = 'dark',
  Light = 'light',
}

export const WebsiteIntegrationColors = {
  black: '000000',
  brick: '87281F',
  red: 'DA3327',
  dough: 'F3E3C4',
  yellow: 'E7B62A',
  green: '17723E',
  blue: '104E95',
  wine: '83194C',
} as const;

export type WebsiteIntegrationColor = keyof typeof WebsiteIntegrationColors;

export type ValueMessage = 'true' | 'false' | null;

export enum BannerPosition {
  Top = 'top',
  Bottom = 'bottom',
}

export enum ButtonPosition {
  BottomCenter = 'bottom_center',
  BottomLeft = 'bottom_left',
  BottomRight = 'bottom_right',
}

export enum ButtonLayout {
  Button = 'button',
  Sticker = 'sticker',
}

export type SmartButtonConfigOption = {
  label: string;
  value: string;
};

export type PromoMessageFormValue = 'true' | 'false' | '';

export type SmartButtonFormValues = {
  color: WebsiteIntegrationColor;
  format: WidgetType;
  mode: ThemeVariant;
  position: ButtonPosition | BannerPosition;
  promoMessage: PromoMessageFormValue;
  theme: Theme;
};

export type WebsiteIntegrationsSettings = {
  floatingButtonEnabled: boolean;
  floatingButtonLayout: ButtonLayout | null;
  floatingButtonPosition: ButtonPosition | null;
  floatingButtonTheme: Theme | null;
  floatingButtonThemeColor: WebsiteIntegrationColor | null;
  floatingButtonThemeVariant: ThemeVariant | null;
  floatingButtonValueMessage: ValueMessage | null;
  floatingButtonMultilocationEnabled: boolean;
  navBarEnabled: boolean;
  navBarPosition: BannerPosition | null;
  navBarTheme: Theme | null;
  navBarThemeColor: WebsiteIntegrationColor | null;
  navBarThemeVariant: ThemeVariant | null;
  navBarValueMessage: ValueMessage | null;
  navBarMultilocationEnabled: boolean;
  modalEnabled: boolean;
  modalTheme: Theme | null;
  modalThemeColor: WebsiteIntegrationColor | null;
  modalThemeVariant: ThemeVariant | null;
  modalValueMessage: ValueMessage | null;
  modalMultilocationEnabled: boolean;
};

export enum SmartButtonStorageKey {
  siteUrl = 'smart_button_site_url',
}

export type EmailTemplate = {
  toAddress: string;
  subject: string;
  body: string;
};

export type UrlInputFormValues = {
  urlInput: string;
};

export type SmartButtonFormAnalyticsParams = {
  shopId: number;
  formValues: SmartButtonFormValues;
  isFirstTimeSetup: boolean;
  isActive: string;
};

export type SmartButtonFormAnalyticsValues = {
  shopId: string;
  format: 'button' | 'banner' | 'sticker';
  position: 'bottom left' | 'bottom right' | 'bottom center' | 'top' | 'bottom';
  theme: 'simple' | 'iconic' | 'elegant' | 'slice';
  color: string;
  colorHex: string;
  mode: 'light' | 'dark';
  promoMessage: 'on' | 'off';
  previouslyEnabledSettings: boolean;
  isActive: string;
};

export type SaveSmartButtonsRequestAnalytics = {
  url: string;
  success: boolean;
  page: string;
} & SmartButtonFormAnalyticsValues;

export type SaveSmartButtonsAnalyticsParams = {
  shopId: number;
  formValues: SmartButtonFormValues;
  success: boolean;
  url: string;
  isFirstTimeSetup: boolean;
  page: string;
  isActive: string;
};

export enum WebsiteIntegrationsAnalyticsEventsName {
  ClickedEditorDropdown = 'clicked_editor_bar_dropdown',
  ClickedSaveChanges = 'clicked_save_changes',
  SubmitWebsitePreview = 'submitted_website_preview',
  ValidationErrorDisplayed = 'validation_error_displayed',
  ViewedGetCodeModal = 'viewed_code_documentation_modal',
  ClickedCopyCode = 'clicked_copy_code',
  ClickedBestPractices = 'clicked_best_practices_link',
  ClickedTechnicalHelpEmail = 'clicked_email_link',
  ClickedInternalFeatureLink = 'clicked_internal_feature_link',
  FetchedSettings = 'fetched_settings',
}

export enum WebsiteIntegrationsAnalyticsPageName {
  SmartButtons = 'smart buttons',
  SmartButtonsMultiloc = 'smart buttons multiloc',
  SmartPopups = 'smart popups',
  SmartPopupsMultiloc = 'smart popups multiloc',
}

export type WebsiteIntegrationsClickedEditorAnalyticsData = {
  field: 'format' | 'position' | 'theme' | 'color' | 'mode' | 'promo message';
  optionSelected: string | null;
  page: string;
  shopId: string;
  isActive?: string;
};

export type SmartButtonsClickedSaveAnalyticsData = {
  shopId: string;
  format: 'button' | 'banner' | 'sticker';
  position: 'bottom left' | 'bottom right' | 'bottom center' | 'top' | 'bottom';
  theme: 'simple' | 'iconic' | 'elegant' | 'slice';
  color: string;
  colorHex: string;
  mode: 'light' | 'dark';
  promoMessage: 'on' | 'off';
  url: string;
  success: boolean;
  previouslyEnabledSettings: boolean;
  page: string;
  isActive: string;
};

export type WebsiteIntegrationsSubmitPreviewAnalyticsData = {
  shopId: string;
  value: string;
  passedValidation: boolean;
  page: string;
  isActive?: string;
};

export type WebsiteIntegrationsUrlValidationErrorDisplayedAnalyticsData = {
  shopId: string;
  errorMessage: string;
  errorType: string;
  input: string;
  location: string;
  submitCount: number;
  value: string;
  isActive?: string;
};

export type WebsiteIntegrationsViewedGetCodeModalAnalyticsData = {
  shopId: string;
  source: 'get code and documentation link' | 'save changes CTA';
  page: string;
};

export type WebsiteIntegrationsCopiedScriptAnalyticsData = {
  shopId: string;
  page: string;
  isActive?: string;
  shopIntegrationAdoption?: ShopTraits['shopIntegrationAdoption'];
};

export type WebsiteIntegrationsClickedBestPracticesAnalyticsData = {
  shopId: string;
  content: string;
  page: string;
  isActive?: string;
};

export type WebsiteIntegrationsClickedTechnicalHelpAnalyticsData = {
  shopId: string;
  destination: string;
  subject: string;
  page: string;
};

export type WebsiteIntegrationsClickedClassicButtonsLinkAnalyticsData = {
  shopId: string;
  fromPage: string;
  toPage: string;
  copy: string;
};

export type WebsiteIntegrationsFetchedSettingsAnalyticsData = {
  shopId: string;
  previouslyEnabledSettings: boolean;
  format?: 'button' | 'banner' | 'sticker';
  position?:
    | 'bottom left'
    | 'bottom right'
    | 'bottom center'
    | 'top'
    | 'bottom';
  theme?: 'simple' | 'iconic' | 'elegant';
  color?: string;
  mode?: 'light' | 'dark';
  promoMessage?: 'on' | 'off';
  page: string;
  isActive?: string;
  shopIntegrationAdoption?: ShopTraits['shopIntegrationAdoption'];
};

export type SubmittedShareCodeForm = {
  isActive: string;
  page: string;
  shopId: string;
  shopIntegrationAdoption?: ShopTraits['shopIntegrationAdoption'];
};
