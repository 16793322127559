import { Id, shop } from './shop';

const websiteToolsBase = (shopId?: Id) => `${shop(shopId)}/websites`;

export const shopSmartButtons = (shopId?: Id) =>
  `${websiteToolsBase(shopId)}/smart-buttons`;

export const shopSmartPopups = (shopId?: Id) =>
  `${websiteToolsBase(shopId)}/smart-popups`;

export const shopCustomWebsite = (shopId?: Id) =>
  `${websiteToolsBase(shopId)}/custom-website`;

export const shopPartnerButtonsCreateCustomButton = (shopId?: Id) =>
  `${websiteToolsBase(shopId)}/classic-buttons`;

export const shopPartnerButtonsGetLink = (shopId?: Id) =>
  `${shopPartnerButtonsCreateCustomButton(shopId)}/links`;
