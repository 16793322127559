import { ElementRef, ForwardedRef, forwardRef, ReactElement } from 'react';
import {
  FieldPath,
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';
import { Merge } from 'type-fest';

import { FileUpload, type FileUploadProps } from 'crust';

export type RHFFileUploadProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = Merge<
  Omit<FileUploadProps, 'isInvalid' | 'onChange' | 'onBlur'>,
  Omit<UseControllerProps<TFieldValues, TName>, 'disabled'>
>;

export const RHFFileUpload = forwardRef(function RHFFileUpload<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
  {
    control,
    defaultValue,
    isDisabled,
    name,
    rules,
    shouldUnregister,
    ...props
  }: RHFFileUploadProps<TFieldValues, TName>,
  ref: ForwardedRef<ElementRef<typeof FileUpload>>,
) {
  const { field, fieldState } = useController({
    control,
    defaultValue,
    disabled: isDisabled,
    name,
    rules,
    shouldUnregister,
  });

  return (
    <FileUpload
      error={fieldState.error?.message}
      inputRef={field.ref}
      isDisabled={field.disabled}
      isInvalid={fieldState.invalid}
      name={field.name}
      onBlur={field.onBlur}
      onChange={field.onChange}
      ref={ref}
      validationBehavior="aria"
      {...props}
    />
  );
}) as <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
  props: RHFFileUploadProps<TFieldValues, TName>,
  ref: ForwardedRef<ElementRef<typeof FileUpload>>,
) => ReactElement;
