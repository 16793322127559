import BrandManager from 'components/brand-manager';
import Page from 'components/shared/page';
import { useCurrentShopId } from 'hooks/shops';

import styles from './styles.module.scss';

const BrandManagerPage = () => {
  const shopId = useCurrentShopId();

  return (
    <Page
      contentClassName={styles.brandManagerPage}
      name="Brand Manager"
      title="Brand Manager"
    >
      <BrandManager shopId={String(shopId)} />
    </Page>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default BrandManagerPage;
