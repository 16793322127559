import ContentTile from 'components/shared/content-tile';
import { RegisterUserRowData } from 'types/register-users';

import AddUserButton from './add-user-button';
import RegisterUsersTable from './table';
import UserCount from './user-count';

import styles from './styles.module.scss';

type Props = {
  shopId: string;
  usersRowData: RegisterUserRowData[];
};

const RegisterUsersTab = ({ shopId, usersRowData }: Props) => {
  return (
    <ContentTile>
      <div className={styles.aboveTableArea}>
        <UserCount numUsers={usersRowData.length} />
        <AddUserButton shopId={shopId} />
      </div>
      <RegisterUsersTable shopId={shopId} usersRowData={usersRowData} />
    </ContentTile>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default RegisterUsersTab;
