import CloseIcon from 'images/delete.svg?react';

import styles from './styles.module.scss';

type Props = {
  onRemoveImage: () => void;
};

const RemovePhotoButton = ({ onRemoveImage }: Props) => {
  return (
    <button
      className={styles.removeImageButton}
      onClick={onRemoveImage}
      type="button"
    >
      <CloseIcon aria-hidden="true" className={styles.closeIcon} /> Remove
    </button>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default RemovePhotoButton;
