import { ChangeEvent, RefObject } from 'react';
import { FileTriggerProps } from 'react-aria-components';
import { useFocusable } from '@react-aria/focus';
import { useFormValidation } from '@react-aria/form';
import { useField } from '@react-aria/label';
import { filterDOMProps, mergeProps, useFormReset } from '@react-aria/utils';
import {
  AriaLabelingProps,
  AriaValidationProps,
  DOMProps,
  FocusableDOMProps,
  FocusableProps,
  HelpTextProps,
  InputBase,
  InputDOMProps,
  LabelableProps,
  Validation,
  ValueBase,
} from '@react-types/shared';

import { FileCriteria } from './get-files-validation';
import { FileFieldState } from './use-file-field-state';

export type UseFileFieldProps = AriaLabelingProps &
  AriaValidationProps &
  DOMProps &
  FileCriteria &
  FocusableDOMProps &
  FocusableProps &
  HelpTextProps &
  InputBase &
  InputDOMProps &
  LabelableProps &
  Pick<
    FileTriggerProps,
    'allowsMultiple' | 'acceptDirectory' | 'defaultCamera'
  > &
  Pick<Validation<File[]>, 'isInvalid' | 'isRequired' | 'validationBehavior'> &
  Pick<ValueBase<File[]>, 'onChange'>;

/**
 * Provides the behavior and accessibility implementation for a file field.
 * @param props - Props for the file field.
 * @param state - State for the file field.
 * @param ref - Ref to the HTML input element.
 */
export const useFileField = (
  props: UseFileFieldProps,
  state: FileFieldState,
  ref: RefObject<HTMLInputElement>,
) => {
  const {
    isDisabled = false,
    isRequired = false,
    isReadOnly = false,
    validationBehavior = 'native',
  } = props;

  const {
    isDropTarget,
    isInvalid,
    setIsDropTarget,
    setValue,
    value,
    ...validation
  } = state;

  const { validationDetails, validationErrors } = validation.displayValidation;

  const { focusableProps } = useFocusable(props, ref);

  const { labelProps, fieldProps, descriptionProps, errorMessageProps } =
    useField({
      ...props,
      isInvalid,
      errorMessage: props.errorMessage || validationErrors,
    });

  const domProps = filterDOMProps(props, { labelable: true });

  useFormReset(ref, value, setValue);
  useFormValidation(props, validation, ref);

  return {
    labelProps,
    inputProps: mergeProps(domProps, {
      disabled: isDisabled,
      readOnly: isReadOnly,
      required: isRequired && validationBehavior === 'native',
      'aria-required':
        (isRequired && validationBehavior === 'aria') || undefined,
      'aria-invalid': isInvalid || undefined,
      'aria-errormessage': props['aria-errormessage'],
      'data-drop-target': isDropTarget || undefined,
      'data-has-files': value.length || undefined,
      onChange: (event: ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;

        if (files) {
          setValue(Array.from(files));
        }
      },
      onDragEnter: () => {
        setIsDropTarget(true);
      },
      onDragLeave: () => {
        setIsDropTarget(false);
      },
      onDrop: () => {
        setIsDropTarget(false);
      },
      accept: props.acceptedFileTypes?.toString(),
      capture: props.defaultCamera,
      multiple: props.allowsMultiple,
      webkitdirectory: props.acceptDirectory ? '' : undefined,
      name: props.name,
      type: 'file',
      ...focusableProps,
      ...fieldProps,
    }),
    descriptionProps,
    errorMessageProps,
    isInvalid,
    validationErrors,
    validationDetails,
  };
};
