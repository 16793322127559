import { GoogleBusinessProfile } from 'components/business-profile';
import { NotFoundError } from 'components/shared/error';
import Loading from 'components/shared/loading';
import Page from 'components/shared/page';
import { useCurrentShopId } from 'hooks/shops';
import useFeatureFlag from 'hooks/use-feature-flag';
import { OptimizelyFeatureFlag } from 'types/optimizely-feature-flag';

export const GoogleBusinessProfilePage = () => {
  const shopId = useCurrentShopId();

  const {
    isEnabled: isGoogleBusinessProfilePageEnabled,
    isLoading: isGoogleBusinessProfilePageFeatureFlagLoading,
  } = useFeatureFlag(OptimizelyFeatureFlag.GoogleBusinessProfile);

  let content;
  if (isGoogleBusinessProfilePageFeatureFlagLoading) {
    content = <Loading />;
  } else if (!isGoogleBusinessProfilePageEnabled) {
    content = <NotFoundError />;
  } else {
    content = <GoogleBusinessProfile shopId={shopId} />;
  }

  return (
    <Page name="Google Business Profile" title="Google Business Profile">
      {content}
    </Page>
  );
};
