import { ElementRef, ForwardedRef, forwardRef } from 'react';
import {
  Label as AriaLabel,
  LabelProps as AriaLabelProps,
} from 'react-aria-components';
import classNames from 'classnames';

import { getCrustClassName } from '../utilities/get-crust-class-name';

import './field-label.css';

export type FieldLabelProps = AriaLabelProps;

export const FieldLabel = forwardRef(function FieldLabel(
  { className, ...props }: FieldLabelProps,
  ref: ForwardedRef<ElementRef<typeof AriaLabel>>,
) {
  return (
    <AriaLabel
      className={classNames(getCrustClassName('field-label'), className)}
      ref={ref}
      {...props}
    />
  );
});
