import { Link } from 'crust';

import useAnalytics from 'hooks/use-analytics';
import * as paths from 'routes/paths';

type Props = {
  shopId: string;
};

const AddUserButton = ({ shopId }: Props) => {
  const { trackClickedAddUser } = useAnalytics();
  return (
    <Link
      appearance="button"
      data-chameleon-target="Add Register User"
      onPress={() => {
        trackClickedAddUser(shopId);
      }}
      href={paths.registerUser(shopId, 'new')}
      variant="secondary"
    >
      Add
    </Link>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default AddUserButton;
