import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  ClickEvent,
  Menu,
  MenuButton,
  MenuChangeEvent,
  MenuDivider,
} from '@szhsin/react-menu';
import cx from 'classnames';

import { useUpdateProductStock } from 'hooks/menu/use-update-product-stock-mutation';
import useAnalytics from 'hooks/use-analytics';
import CaretIcon from 'images/caret.svg?react';
import { Product, ProductType } from 'types/shared/stock-dropdown';
import { showUnexpectedErrorToast } from 'utilities/forms';

import { StockMenuItems } from './menu-items';
import { StockSubMenus } from './sub-menus';
import { getDurationLabel } from './utilities';

import styles from './styles.module.scss';

type Props = {
  buttonClassName?: string;
  product: Product;
  productTypes: ProductType[];
  shopId: string;
};

const StockDropdown = ({
  buttonClassName,
  product,
  productTypes,
  shopId,
}: Props) => {
  const location = useLocation();
  const { trackUpdatedProductStock, trackClickedProductStockDropdown } =
    useAnalytics();

  const { mutate: updateProductStock } = useUpdateProductStock();

  const hasUnavailableType = productTypes.some((it) => it.unavailable);

  const duration = getDurationLabel(
    product.unavailable,
    product.unavailableUntil,
  );

  const handleMenuChange = (event: MenuChangeEvent): void => {
    if (event.open) {
      trackClickedProductStockDropdown(shopId, product.id, location.pathname);
    }
  };

  const handleItemClick = (event: ClickEvent): void => {
    if (event.value.isProduct) {
      updateProductStock(
        {
          shopId: Number(shopId),
          productId: event.value.id,
          duration: event.value.duration,
        },
        {
          onError: () => {
            showUnexpectedErrorToast();
          },
          onSuccess: (data, { productId, duration }) => {
            toast.success(
              `${data.product.name} is now ${
                data.product.unavailable ? 'out of stock' : 'in stock'
              }.`,
            );

            trackUpdatedProductStock(
              shopId,
              productId,
              null,
              duration,
              location.pathname,
            );
          },
        },
      );
    } else {
      updateProductStock(
        {
          shopId: Number(shopId),
          productId: product.id,
          productTypeId: event.value.id,
          duration: event.value.duration,
        },
        {
          onError: () => {
            showUnexpectedErrorToast();
          },
          onSuccess: (data, { productId, productTypeId, duration }) => {
            const productType = data.relationships.productTypes?.find(
              (it) => it.id === productTypeId,
            );

            if (productType) {
              toast.success(
                `${data.product.name}, ${productType.name} is now ${
                  productType.unavailable ? 'out of stock' : 'in stock'
                }.`,
              );
            }

            trackUpdatedProductStock(
              shopId,
              productId,
              productTypeId,
              duration,
              location.pathname,
            );
          },
        },
      );
    }
  };

  const isProductUnavailable = product.unavailable;

  return (
    <Menu
      menuClassName={styles.menu}
      menuButton={({ open }) => (
        <MenuButton
          className={cx(
            styles.button,
            buttonClassName,
            (product.unavailable || hasUnavailableType) && styles.unavailable,
            open && styles.open,
          )}
        >
          <span className={styles.status}>
            {product.unavailable
              ? 'Out of Stock'
              : hasUnavailableType
                ? 'Mixed Stock'
                : 'In Stock'}
            {duration && <span className={styles.duration}>{duration}</span>}
          </span>
          <CaretIcon aria-hidden="true" className={styles.caret} />
        </MenuButton>
      )}
      onItemClick={handleItemClick}
      onMenuChange={handleMenuChange}
    >
      <StockMenuItems entity={product} />
      <MenuDivider />
      <StockSubMenus
        isProductUnavailable={isProductUnavailable}
        productTypes={productTypes}
      />
    </Menu>
  );
};

export { StockDropdown };
