import { Link } from 'crust';

import Modal from 'components/shared/slice-modal';
import { customerImportTemplateUrl } from 'routes/external-urls';
import { CustomerImportErrors } from 'types/customers/profile/customer-profile-import';

import styles from './styles.module.scss';

// We add 2 here for the display to the end user to account for both the backend being 0 index based and
// to account for the header row. To a user in something like sheets, row 1 is the header and row 2 is the first
// row of data, whereas the backend only considers data rows and beings at 0.
const getGroup = (start: number, end: number) =>
  start === end ? String(start + 2) : `${start + 2} - ${end + 2}`;

// To format the error messages, we want to group the messages by error message and the row number that given
// error is occurring. We then want to display the row numbers with sequential rows
// condensed to something such 'Row 1 - 5, 8 : Invalid First Name' for example which would mean errors on lines 1, 2, 3, 4, 5 and 8
const formatErrorMessages = (customerImportErrors: CustomerImportErrors) => {
  const messages = [] as string[];

  // We can display the file errors exactly as we receive them. These are errors such as invalid column headings in the file
  for (const message of customerImportErrors.fileErrors?.errors ?? []) {
    messages.push(message);
  }

  const mapped = {} as Record<string, number[]>;

  // Group the rows per error message string
  // eg message: [1,2,3,7,9,10]
  for (const row of customerImportErrors.rowErrors ?? []) {
    for (const error of row.errors) {
      const message = error.errorMessage;

      mapped[message] ??= [];
      mapped[message]?.push(row.row);
    }
  }

  // Here go through each message and it's corresponding array of rows
  // We then go through the row numbers for that error message and check for
  // sequential groups of row numbers to group in a format such as 5-8, rather than 5, 6, 7, 8
  for (const [message, rows] of Object.entries(mapped)) {
    rows.sort();
    const groups = [] as string[];
    let start = null;
    let end = null;

    for (const [index, row] of rows.entries()) {
      if (start == null) {
        start = row;
      }

      if (end == null || end === row - 1) {
        end = row;
      }

      if (end !== row) {
        // This row number is not sequential, so start a new group.
        groups.push(getGroup(start, end));
        start = row;
        end = row;
      }

      if (index === rows.length - 1) {
        // Make sure to grab the last group.
        groups.push(getGroup(start, end));
      }
    }

    messages.push(`Row ${groups.join(', ')}: ${message}`);
  }

  return messages;
};

type Props = {
  customerImportErrors: CustomerImportErrors;
  isOpen: boolean;
  onClose: () => void;
};

export const CustomerImportErrorDetailsModal = ({
  customerImportErrors,
  isOpen,
  onClose,
}: Props) => {
  const errorMessages = formatErrorMessages(customerImportErrors);

  return (
    <Modal
      className={styles.modal}
      header="Import Error Details"
      isOpen={isOpen}
      onRequestClose={onClose}
      isFullScreenMobile
    >
      <p className={styles.description}>
        We found the following errors in your CSV file. Please use our{' '}
        <Link href={customerImportTemplateUrl} variant="tertiary">
          CSV template
        </Link>{' '}
        to fix the issues and import again.
      </p>
      <ul className={styles.errorList}>
        {errorMessages.map((message, index) => (
          <li key={index}>{message}</li>
        ))}
      </ul>
    </Modal>
  );
};
