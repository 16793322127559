import { useEffect, useRef, useState } from 'react';
import {
  Control,
  FieldError,
  useFieldArray,
  UseFormGetValues,
  UseFormSetValue,
  UseFormTrigger,
} from 'react-hook-form';

import { Radio, RadioGroup } from 'crust';

import CollapsibleTile from 'components/shared/collapsible-tile';
import FormFeedback from 'components/shared/form-feedback';
import {
  MenuCategoryFormDay,
  MenuCategoryFormValues,
} from 'types/menu/category';
import { getEmptyCategoryFormDays } from 'utilities/menu';

import { Day } from './day';

import styles from './styles.module.scss';

type Props = {
  className?: string;
  daysError?: FieldError;
  control: Control<MenuCategoryFormValues>;
  getValues: UseFormGetValues<MenuCategoryFormValues>;
  setValue: UseFormSetValue<MenuCategoryFormValues>;
  submitCount: number;
  trigger: UseFormTrigger<MenuCategoryFormValues>;
};

const CategoryAvailabilityTile = ({
  className,
  control,
  daysError,
  getValues,
  setValue,
  submitCount,
  trigger,
}: Props) => {
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    if (daysError) {
      setIsOpen(true);
    }
  }, [daysError, submitCount]);

  const days = useFieldArray({
    control,
    name: 'days',
    rules: {
      validate: (fields) => {
        if (
          Array.isArray(fields) &&
          fields.length > 0 &&
          (fields as MenuCategoryFormDay[]).every(
            (it) => it.availabilities.length < 1,
          )
        ) {
          return 'Please select at least one day.';
        }

        return true;
      },
    },
  });
  const previousDaysRef = useRef<MenuCategoryFormDay[]>();

  const isEverday = days.fields.length === 0;

  const handleChangeAvailability = (value: string) => {
    if (value === 'everyday') {
      previousDaysRef.current = getValues('days');
      setValue('days', []);
    } else {
      setValue('days', previousDaysRef.current ?? getEmptyCategoryFormDays());
    }
  };

  return (
    <CollapsibleTile
      bodyClassName={styles.body}
      className={className}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title="Choose Availability"
      bodyChameleonTarget="Choose Availability Area"
    >
      <>
        <RadioGroup
          label="Is this category available everyday or only on specific days/times?"
          onChange={handleChangeAvailability}
          value={isEverday ? 'everyday' : 'specific'}
        >
          <Radio label="Everyday" value="everyday" />
          <Radio label="Specific days/times" value="specific" />
        </RadioGroup>
        {!isEverday &&
          days.fields.map((field, index) => (
            <Day
              control={control}
              dayFieldIndex={index}
              getValues={getValues}
              hasDaysError={!!daysError}
              key={field.id}
              name={field.name}
              setValue={setValue}
              trigger={trigger}
            />
          ))}
      </>
      <FormFeedback>{daysError?.message}</FormFeedback>
    </CollapsibleTile>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default CategoryAvailabilityTile;
