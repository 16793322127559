import * as React from "react";
import { forwardRef } from "react";
const SvgColorPicker = ({
  title,
  titleId,
  ...props
}, ref) => {
  return /* @__PURE__ */ React.createElement("svg", { width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", ref, "aria-labelledby": titleId, ...props }, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { d: "M3 20H21", stroke: "#211E1E", strokeWidth: 2 }), /* @__PURE__ */ React.createElement("path", { d: "M5.25705 2.92572L8.36907 4.77173M8.36907 4.77173L16.8305 9.79091L12.8588 16.4864L4.3974 11.4672L8.36907 4.77173Z", stroke: "#211E1E", strokeWidth: 2, strokeLinejoin: "round" }), /* @__PURE__ */ React.createElement("path", { d: "M14.7617 10.2031L6.63281 11.0117L12.2617 14.3555L14.7617 10.2031Z", fill: "black" }), /* @__PURE__ */ React.createElement("path", { d: "M20.5832 13.8217C20.5832 14.7886 19.7993 15.5725 18.8324 15.5725C17.8655 15.5725 17.0816 14.7886 17.0816 13.8217C17.0816 12.8548 18.8324 10.9237 18.8324 10.9237C18.8324 10.9237 20.5832 12.8548 20.5832 13.8217Z", fill: "black" }));
};
const ForwardRef = forwardRef(SvgColorPicker);
export default ForwardRef;
