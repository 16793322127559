import * as React from "react";
import { forwardRef } from "react";
const SvgSparkleIcon = ({
  title,
  titleId,
  ...props
}, ref) => {
  return /* @__PURE__ */ React.createElement("svg", { width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", "aria-hidden": "true", ref, "aria-labelledby": titleId, ...props }, title === void 0 ? /* @__PURE__ */ React.createElement("title", { id: titleId }, "Sparkle Icon") : title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { d: "M21 5.36842C21 6.67646 19.9396 7.73684 18.6316 7.73684C17.3235 7.73684 16.2632 6.67646 16.2632 5.36842C16.2632 4.06038 17.3235 3 18.6316 3C19.9396 3 21 4.06038 21 5.36842Z", fill: "currentColor" }), /* @__PURE__ */ React.createElement("path", { d: "M3 11.5263C5.69607 11.5263 7.54412 10.8529 8.72485 9.67221C9.90557 8.49149 10.5789 6.64344 10.5789 3.94737H12.4737C12.4737 6.64344 13.1471 8.49149 14.3278 9.67221C15.5085 10.8529 17.3566 11.5263 20.0526 11.5263V13.4211C17.3566 13.4211 15.5085 14.0944 14.3278 15.2752C13.1471 16.4559 12.4737 18.3039 12.4737 21H10.5789C10.5789 18.3039 9.90557 16.4559 8.72485 15.2752C7.54412 14.0944 5.69607 13.4211 3 13.4211V11.5263Z", fill: "currentColor" }), /* @__PURE__ */ React.createElement("path", { d: "M5.36842 20.0526C6.15325 20.0526 6.78947 19.4164 6.78947 18.6316C6.78947 17.8468 6.15325 17.2105 5.36842 17.2105C4.5836 17.2105 3.94737 17.8468 3.94737 18.6316C3.94737 19.4164 4.5836 20.0526 5.36842 20.0526Z", fill: "currentColor" }));
};
const ForwardRef = forwardRef(SvgSparkleIcon);
export default ForwardRef;
