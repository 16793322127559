import { useEffect } from 'react';
import { FieldError } from 'react-hook-form';

import useAnalytics from 'hooks/use-analytics';

export enum ValidationErrorLocation {
  MenuProductEditPage = 'Menu Product Edit Page',
}

export enum ValidationInputId {
  ProductName = 'product name',
  ProductKitchenName = 'product kitchen name',
  ProductCategory = 'product category',
  ProductTypeName = 'product type name',
  ProductTypePrice = 'product type price',
  ProductCustomizationName = 'product customization name',
  ProductCustomizationSelectionName = 'product customization selection name',
  ProductCustomizationSelectionWholePrice = 'product customization selection whole price',
  ProductCustomizationSelectionHalfPrice = 'product customization selection half price',
}

const useValidationErrorTracking = (
  shopId: string,
  validationErrorLocation: ValidationErrorLocation,
  validationInputId: ValidationInputId,
  errorInfo: FieldError | undefined,
  submitCount: number,
  isActive = true,
): void => {
  const { trackValidationErrorDisplayed } = useAnalytics();

  useEffect(() => {
    if (!isActive) {
      return;
    }

    if (!errorInfo) {
      return;
    }

    trackValidationErrorDisplayed({
      shopId: shopId,
      location: validationErrorLocation,
      input: validationInputId,
      value: errorInfo.ref?.value ?? '',
      errorType: errorInfo.type,
      errorMessage: errorInfo.message,
      submitCount: submitCount,
    });
  }, [
    errorInfo,
    submitCount,
    shopId,
    validationErrorLocation,
    validationInputId,
    trackValidationErrorDisplayed,
    isActive,
  ]);
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default useValidationErrorTracking;
