import { CamelCasedProperties, CamelCasedPropertiesDeep } from 'type-fest';

export type StringOrNull = string | null;
export type NumberOrNull = number | null;

export type TimePeriod = {
  hours: number;
  minutes: number;
};

export type UnavailabilityTotals = {
  delivery: TimePeriod | null;
  pickup: TimePeriod | null;
};

export type NetworkLast30Days = {
  availability_score: NumberOrNull;
  availability_score_range: StringOrNull;
  shop_availability: NumberOrNull;
  unavailability_totals: UnavailabilityTotals;
  unavailability_totals_gt_subregion: UnavailabilityTotals;
};

export type Last30Days = CamelCasedPropertiesDeep<NetworkLast30Days>;

export type NetworkWeeklySummary = {
  shop_availability: NumberOrNull;
  subregion_shop_availability: NumberOrNull;
  week_end: string;
  week_start: string;
};

export type WeeklySummary = CamelCasedProperties<NetworkWeeklySummary>;

export type NetworkAvailabilitySummaryWithoutAsOfText = {
  last_30_days: NetworkLast30Days;
  weekly_summary: NetworkWeeklySummary[];
};

export type AvailabilitySummaryWithoutAsOfText =
  CamelCasedPropertiesDeep<NetworkAvailabilitySummaryWithoutAsOfText>;

export type AvailabilitySummary = {
  dataAsOfText: string;
  last30Days: Last30Days;
  weeklySummary: WeeklySummary[];
};

export type ChartDatum = {
  x: string;
  y: number;
};

export type LoadedDataHoursAnalyticsPage = {
  shopId: number;
  last30DaysAvailability: string;
  last30DaysAvailabilityPercentage: string;
};

export const HoursAnalyticsEventsName = {
  LoadedHoursAnalyticsPage: 'loaded_hours_analytics_page',
  SubmittedProductFeedbackSurvey: 'submitted_product_feedback_survey',
} as const;
