import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { CalendarDate, parseDate, today } from '@internationalized/date';

import { RangeValue, useDateFormatter } from 'crust';

import { RHFDateRangePicker } from 'components/shared/rhf-date-range-picker';
import { RHFRadio, RHFRadioGroup } from 'components/shared/rhf-radio';
import { useCreateOfflineOrdersReportMutation } from 'hooks/reports';
import { useCreateOnlineOrdersReportMutation } from 'hooks/reports/use-create-online-orders-report-mutation';
import useAnalytics from 'hooks/use-analytics';
import {
  OrdersReportFormValues,
  OrdersReportOrderType,
  ReportAnalyticsType,
} from 'types/financials';
import {
  showInvalidSubmitToast,
  showUnexpectedErrorToast,
} from 'utilities/forms';

import { CreateReportForm } from '../../create-report-form';

export type Props = {
  closeForm: () => void;
  shopId: number;
  shopTimezone: string;
  initialOrderType: OrdersReportOrderType;
  initialStartDate: string;
  initialEndDate: string;
  shopHasFullRegister: boolean;
};

const EARLIEST_OFFLINE_REPORT_DATE = new CalendarDate(2021, 1, 1);
const EARLIEST_ONLINE_REPORT_DATE = new CalendarDate(2023, 1, 1);

export const OrdersReportForm = ({
  closeForm,
  shopId,
  shopTimezone,
  initialOrderType,
  initialStartDate,
  initialEndDate,
  shopHasFullRegister,
}: Props) => {
  const { trackCreateReportClick } = useAnalytics();
  const maxDate = today(shopTimezone);

  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
    watch,
  } = useForm({
    defaultValues: {
      dates:
        initialStartDate !== '' && initialEndDate !== ''
          ? {
              start: parseDate(initialStartDate),
              end: parseDate(initialEndDate),
            }
          : (null as unknown as OrdersReportFormValues['dates']),
      orderType: initialOrderType,
    } as OrdersReportFormValues,
    mode: 'onChange',
  });

  const dateFormatter = useDateFormatter({
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    timeZone: shopTimezone,
  });

  const orderType = watch('orderType');
  const isOnline = orderType === 'online';
  const minDate = isOnline
    ? EARLIEST_ONLINE_REPORT_DATE
    : EARLIEST_OFFLINE_REPORT_DATE;
  const minDateFormatted = dateFormatter.format(minDate.toDate(shopTimezone));

  const {
    mutate: createOfflineOrdersReport,
    isLoading: isCreateOfflineReportLoading,
  } = useCreateOfflineOrdersReportMutation(shopId, shopTimezone);

  const {
    mutate: createOnlineOrdersReport,
    isLoading: isCreateOnlineReportLoading,
  } = useCreateOnlineOrdersReportMutation(shopId, shopTimezone);

  const validateDates = (dates: RangeValue<CalendarDate>) => {
    if (!dates || !dates.start || !dates.end) {
      return 'Please select start and end dates.';
    }

    if (dates.end.compare(dates.start) < 0) {
      return 'Please select an end date that is after the start date.';
    }

    if (dates.end.compare(maxDate) > 0) {
      const formatted = dateFormatter.format(maxDate.toDate(shopTimezone));
      return `Please select an end date of ${formatted} or earlier.`;
    }

    if (dates.end.compare(dates.start) > 30) {
      return 'Please select a maximum of 31 days.';
    }

    if (dates.start.compare(minDate) < 0) {
      return `Please select a start date of ${minDateFormatted} or later.`;
    }

    return true;
  };

  const handleSubmitSuccess = (values: OrdersReportFormValues) => {
    trackCreateReportClick({
      type: ReportAnalyticsType.Order,
      startDate: values.dates.start.toString(),
      endDate: values.dates.end.toString(),
      ordersReportDataSource: values.orderType,
      shopId,
    });
    toast.success('Report is being generated. Check back soon!');
    closeForm();
  };

  const handleValidSubmit = (values: OrdersReportFormValues) => {
    if (values.orderType === 'register') {
      createOfflineOrdersReport(values, {
        onError: () => {
          showUnexpectedErrorToast();
        },
        onSuccess: () => {
          handleSubmitSuccess(values);
        },
      });
    } else {
      createOnlineOrdersReport(values, {
        onError: () => {
          showUnexpectedErrorToast();
        },
        onSuccess: () => {
          handleSubmitSuccess(values);
        },
      });
    }
  };

  return (
    <CreateReportForm
      onCancel={closeForm}
      onSubmit={handleSubmit(handleValidSubmit, showInvalidSubmitToast)}
      isSubmitting={
        isCreateOfflineReportLoading ||
        isCreateOnlineReportLoading ||
        isSubmitting
      }
    >
      {shopHasFullRegister && (
        <RHFRadioGroup
          control={control}
          isRequired
          label="Order type"
          name="orderType"
          orientation="horizontal"
          rules={{
            deps: ['dates'],
          }}
        >
          <RHFRadio label="Online Orders" value="online" />
          <RHFRadio label="Register Orders" value="register" />
        </RHFRadioGroup>
      )}
      <RHFDateRangePicker
        control={control}
        name="dates"
        label="Date Range"
        maxValue={maxDate}
        description={`${isOnline ? 'Online' : 'Register'} order data is available from ${minDateFormatted}.`}
        minValue={minDate}
        rules={{ validate: validateDates }}
      />
    </CreateReportForm>
  );
};
