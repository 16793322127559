// import { useState } from 'react';
import { QueryKey } from '@tanstack/react-query';

// import useAnalytics from 'hooks/use-analytics';
import { CustomerList, TopProduct } from 'types/customers';

// import AddPhotoButton from './add-photo-button';
// import AddPhotoModal from './add-photo-modal';
// import ProductImage from './product-image';
import styles from './styles.module.scss';

type Props = {
  customerAnalyticsQueryKey: QueryKey;
  customerList: CustomerList;
  shopId: string;
  topProduct: TopProduct;
};

const FavoriteItem = ({
  // customerAnalyticsQueryKey,
  // customerList,
  // shopId,
  topProduct,
}: Props) => {
  // const { trackClickedAddMenuPhoto } = useAnalytics();

  // const [isModalOpen, setIsModalOpen] = useState(false);

  const {
    productName,
    customersCount,
    // image,
    // productId
  } = topProduct;

  // const handleClickAddPhotoButton = (): void => {
  //   trackClickedAddMenuPhoto({
  //     shopId,
  //     productId,
  //     productName,
  //     page: 'my customers',
  //     pageDetail: customerList,
  //   });
  //   setIsModalOpen(true);
  // };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.leftArea}>
          {/*
            Temporarily hiding the photo from the favorite item area:
            https://app.shortcut.com/slicelife/story/395834/hide-photo-and-photo-input-in-favorite-item-area-of-my-customers-page

            Relates to bug requiring backend work.
            https://app.shortcut.com/slicelife/story/389400/favorite-item-photo-not-displaying-on-my-customers-page-when-order-source-is-online-only

          { image === null ? (
              <AddPhotoButton onClick={handleClickAddPhotoButton} />
            ) : (
              <ProductImage imageUrl={image} productName={productName} />
            )}
            */}
          <div>
            <div className={styles.labelText}>Favorite Item</div>
            <div className={styles.valueText}>{productName}</div>
          </div>
        </div>
        <div className={styles.rightArea}>
          <div className={styles.labelText}>Ordered By</div>
          <div className={styles.valueText}>
            {customersCount === 1
              ? '1 Customer'
              : `${customersCount} Customers`}
          </div>
        </div>
      </div>
      {/*<AddPhotoModal
        customerAnalyticsQueryKey={customerAnalyticsQueryKey}
        customerList={customerList}
        isOpen={isModalOpen}
        closeModal={() => setIsModalOpen(false)}
        productName={productName}
        productId={productId}
        shopId={shopId}
        />*/}
    </>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default FavoriteItem;
