import ContentTile from 'components/shared/content-tile';
import {
  CampaignManagerPlatformsInfo,
  CampaignManagerResults,
  CampaignManagerState,
} from 'types/campaign-manager';
import { Shop } from 'types/shops';

import { Header } from './header';
import Insights from './insights';
import Metrics from './metrics';

import styles from './styles.module.scss';

type Props = {
  campaignState: CampaignManagerState;
  description: string;
  hasEnded?: boolean;
  insights?: React.ReactNode[];
  isOptimizeMyCampaignLinkVisible?: boolean;
  platformsInfo?: CampaignManagerPlatformsInfo;
  results?: CampaignManagerResults;
  shopId: Shop['shopId'];
  title: string;
};

const ResultsTile = ({
  campaignState,
  description,
  hasEnded = false,
  insights,
  isOptimizeMyCampaignLinkVisible = true,
  platformsInfo,
  results,
  shopId,
  title,
}: Props) => {
  return (
    <ContentTile
      className={styles.tile}
      headerClassName={styles.header}
      dataTestId="results-tile"
      headerContent={
        <Header
          description={description}
          isOptimizeMyCampaignLinkVisible={isOptimizeMyCampaignLinkVisible}
          shopId={shopId}
          title={title}
        />
      }
    >
      <Metrics results={results} hasEnded={hasEnded} />
      {insights && (
        <Insights
          campaignState={campaignState}
          insights={insights}
          platformsInfo={platformsInfo}
          shopId={shopId}
        />
      )}
    </ContentTile>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default ResultsTile;
