import { useMatch } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import PropTypes from 'prop-types';

import ContentTile from 'components/shared/content-tile';
import NavigationTabs from 'components/shared/navigation-tabs';
import { TooltipIconButton } from 'components/shared/tooltips';
import useAnalytics from 'hooks/use-analytics';
import useApi from 'hooks/use-api';
import * as paths from 'routes/paths';
import { showUnexpectedErrorToast } from 'utilities/forms';
import { upsertBy } from 'utilities/lists';

import HoursEditor from './editor';
import { entriesByTypeAndDay } from './helpers';

import styles from './styles.module.scss';

const WeeklyHours = ({ shopId }) => {
  const api = useApi();
  const queryClient = useQueryClient();

  const { data: weeklyHoursData, isLoading: isHoursLoading } = useQuery(
    [shopId, 'weeklyHours'],
    () => api.getShopOpenings(shopId),
  );

  // format the hours to be by shipping type, then by day of week
  const formattedWeeklyHours =
    weeklyHoursData && entriesByTypeAndDay(weeklyHoursData);

  const { trackShopScheduleUpdated } = useAnalytics();

  const handleMutationError = () => {
    showUnexpectedErrorToast();
    trackShopScheduleUpdated(shopId, false);
  };

  const { mutateAsync: deleteHours, isLoading: isDeleteHoursInProgress } =
    useMutation(
      (entries) => {
        const requests = [];

        for (const values of entries) {
          requests.push(
            api.deleteShopOpening({
              shopId,
              openingId: values.id,
              openFor: values.open_for,
              dayOfWeek: values.day_of_week,
            }),
          );
        }

        return Promise.all(requests);
      },
      {
        onError: handleMutationError,
        onSuccess: (responses) => {
          for (const data of responses) {
            queryClient.setQueryData([shopId, 'weeklyHours'], (oldHours) => {
              const deletedHourIndex = oldHours.findIndex(
                (hour) => hour.id === data.openingId,
              );
              oldHours.splice(deletedHourIndex, 1);
              return oldHours;
            });
          }
        },
      },
    );

  const { mutate: updateHours, isLoading: isUpdateHoursInProgress } =
    useMutation(
      (entries) => {
        const requests = [];

        for (const values of entries) {
          if (values.type === 'update') {
            requests.push(
              api.updateShopOpening({
                shopId,
                opening: values,
                openingId: values.id,
              }),
            );
          } else {
            requests.push(
              api.createShopOpening({
                shopId,
                opening: values,
              }),
            );
          }
        }

        return Promise.all(requests);
      },
      {
        onError: handleMutationError,
        onSuccess: (responses) => {
          for (const data of responses) {
            queryClient.setQueryData([shopId, 'weeklyHours'], (oldHours) =>
              upsertBy(oldHours, data, 'id'),
            );
          }

          toast.success(
            "Your restaurant's hours of operation have been successfully updated.",
          );
          trackShopScheduleUpdated(shopId, true);
        },
      },
    );

  const saveEntries = async (entries, onSaveSuccessCallback) => {
    const deleteEntries = [];
    const addAndUpdateEntries = [];
    try {
      entries
        .filter((entry) => entry.isModified)
        .forEach((entry) => {
          const isNew = entry.id.toString().includes('new');
          let formattedEntry = {
            day_of_week: entry.dayOfWeek,
            from: entry.from,
            id: isNew ? null : entry.id,
            open_for: entry.openFor,
            to: entry.to,
            isDeleted: entry.isDeleted,
          };
          if (isNew && !entry.isDeleted) {
            formattedEntry.type = 'create';
            addAndUpdateEntries.push(formattedEntry);
          } else if (!isNew && entry.isDeleted) {
            formattedEntry.type = 'delete';
            deleteEntries.push(formattedEntry);
          } else if (!isNew && !entry.isDeleted) {
            formattedEntry.type = 'update';
            addAndUpdateEntries.push(formattedEntry);
          } else {
            throw new Error(
              "invalid request type, must be 'create', 'delete' or 'update'",
            );
          }
        });
    } catch {
      showUnexpectedErrorToast();
      return;
    }

    // Delete hours first to avoid the following race condition:
    // User toggles shop closed - creates a deletion of an hours record
    // User Toggles shop open - creates a new hours record with same hours
    // Add request hits rez-api first which results in an overlap.
    await deleteHours(deleteEntries);
    updateHours(addAndUpdateEntries, {
      onSuccess: onSaveSuccessCallback,
    });
  };

  const editorProps = {
    isUpdateInProgress: isUpdateHoursInProgress || isDeleteHoursInProgress,
    saveEntries,
  };

  const weeklyHoursPath = paths.shopHoursWeekly(shopId);
  const deliveryHoursPath = paths.shopHoursDelivery(shopId);

  const tileHeader = (
    <div className={styles.tileHeader}>
      All Weekly Hours
      <TooltipIconButton label="More information about weekly hours">
        This is your weekly pickup and delivery schedule. Feel free to adjust
        your hours by clicking on a {"'Pencil'"} icon.
      </TooltipIconButton>
    </div>
  );

  const weeklyHoursMatch = useMatch(weeklyHoursPath);
  const deliveryHoursMatch = useMatch(deliveryHoursPath);

  return (
    <ContentTile
      className={styles.container}
      isLoading={isHoursLoading}
      title={tileHeader}
    >
      <NavigationTabs variant="secondary">
        <NavigationTabs.Link end to={weeklyHoursPath}>
          Pickup
        </NavigationTabs.Link>
        <NavigationTabs.Link end to={deliveryHoursPath}>
          Delivery
        </NavigationTabs.Link>
      </NavigationTabs>
      {weeklyHoursMatch && (
        <HoursEditor
          {...editorProps}
          entries={formattedWeeklyHours?.pickup}
          type="pickup"
        />
      )}
      {deliveryHoursMatch && (
        <HoursEditor
          {...editorProps}
          entries={formattedWeeklyHours?.delivery}
          type="delivery"
        />
      )}
    </ContentTile>
  );
};

WeeklyHours.propTypes = {
  shopId: PropTypes.string.isRequired,
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default WeeklyHours;
