import * as React from "react";
import { forwardRef } from "react";
const SvgIconSlice = ({
  title,
  titleId,
  ...props
}, ref) => {
  return /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", fill: "none", viewBox: "0 0 15 18", ref, "aria-labelledby": titleId, ...props }, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { fill: "#fff", fillRule: "evenodd", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: 1.5, d: "M7.49965 16.5654L14 3.96436S11.8328 1 7.49965 1C3.16725 1 1 3.96436 1 3.96436L7.49965 16.5654z", clipRule: "evenodd" }), /* @__PURE__ */ React.createElement("path", { stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: 1.5, d: "M13.1613 5.62757c-.7423-1.03299-2.6805-2.52412-5.48279-2.52412-2.72738 0-4.6362 1.37368-5.42045 2.43877" }));
};
const ForwardRef = forwardRef(SvgIconSlice);
export default ForwardRef;
