import * as React from "react";
import { forwardRef } from "react";
const SvgPageIcon = ({
  title,
  titleId,
  ...props
}, ref) => {
  return /* @__PURE__ */ React.createElement("svg", { width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", "aria-hidden": "true", ref, "aria-labelledby": titleId, ...props }, title === void 0 ? /* @__PURE__ */ React.createElement("title", { id: titleId }, "Page Icon") : title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M4 4C4 2.89543 4.89543 2 6 2H12V6C12 8.20914 13.7909 10 16 10H20V20C20 21.1046 19.1046 22 18 22H6C4.89543 22 4 21.1046 4 20V4ZM8 15V13H16V15H8ZM8 19V17H16V19H8Z", fill: "currentColor" }), /* @__PURE__ */ React.createElement("path", { d: "M20 8H16C14.8954 8 14 7.10457 14 6V2L20 8Z", fill: "currentColor" }));
};
const ForwardRef = forwardRef(SvgPageIcon);
export default ForwardRef;
