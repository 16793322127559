import * as React from "react";
import { forwardRef } from "react";
const SvgNewWindowIcon = ({
  title,
  titleId,
  ...props
}, ref) => {
  return /* @__PURE__ */ React.createElement("svg", { width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", "aria-hidden": "true", ref, "aria-labelledby": titleId, ...props }, title === void 0 ? /* @__PURE__ */ React.createElement("title", { id: titleId }, "New Window Icon") : title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { d: "M15.052 3.00004C14.5045 3.00002 14.0606 3.44386 14.0606 3.99137C14.0606 4.53887 14.5044 4.98271 15.0519 4.98271H17.6194L13.6989 8.90322C13.3129 9.28923 13.3129 9.91507 13.6989 10.3011C14.0849 10.6871 14.7108 10.6871 15.0968 10.3011L19.0173 6.38056V8.94804C19.0173 9.49555 19.4611 9.93939 20.0086 9.93939C20.5562 9.93939 21 9.49555 21 8.94804V3.00025L15.052 3.00004Z", fill: "currentColor" }), /* @__PURE__ */ React.createElement("path", { d: "M3 5C3 3.89543 3.89543 3 5 3H11V5H3Z", fill: "currentColor" }), /* @__PURE__ */ React.createElement("path", { d: "M5 19H19V21H5V19Z", fill: "currentColor" }), /* @__PURE__ */ React.createElement("path", { d: "M3 5H5V21C3.89543 21 3 20.1046 3 19V5Z", fill: "currentColor" }), /* @__PURE__ */ React.createElement("path", { d: "M19 13H21V19C21 20.1046 20.1046 21 19 21V13Z", fill: "currentColor" }));
};
const ForwardRef = forwardRef(SvgNewWindowIcon);
export default ForwardRef;
