import * as React from "react";
import { forwardRef } from "react";
const SvgCalendarIcon = ({
  title,
  titleId,
  ...props
}, ref) => {
  return /* @__PURE__ */ React.createElement("svg", { width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", "aria-hidden": "true", ref, "aria-labelledby": titleId, ...props }, title === void 0 ? /* @__PURE__ */ React.createElement("title", { id: titleId }, "Calendar Icon") : title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { d: "M9 3H7V4.89474H5C3.89543 4.89474 3 5.74304 3 6.78947V9.63158H21V6.78947C21 5.74304 20.1046 4.89474 19 4.89474H17V3H15V4.89474H9V3Z", fill: "currentColor" }), /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M3 11.5263H21V19.1053C21 20.1517 20.1046 21 19 21H5C3.89543 21 3 20.1517 3 19.1053V11.5263ZM13 15.3158C13 14.7926 13.4477 14.3684 14 14.3684H17C17.5523 14.3684 18 14.7926 18 15.3158V17.2105C18 17.7337 17.5523 18.1579 17 18.1579H14C13.4477 18.1579 13 17.7337 13 17.2105V15.3158Z", fill: "currentColor" }));
};
const ForwardRef = forwardRef(SvgCalendarIcon);
export default ForwardRef;
