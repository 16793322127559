import { useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Link } from 'crust';

import RequestProductChangeModal from 'components/menu/product/form/request-change-modal';
import { phoneNumbers } from 'utilities/constants';
import { formatPhoneNumber } from 'utilities/strings';

import styles from './styles.module.scss';

const CustomizationsHelp = ({ getValues, productId, shopId }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div className={styles.help}>
      Please call us at{' '}
      <Link href={`tel:${phoneNumbers.accountManagers}`} variant="tertiary">
        {formatPhoneNumber(phoneNumbers.accountManagers)}
      </Link>{' '}
      for any additional changes or send them or send them{' '}
      <Button
        appearance="link"
        onPress={() => setIsModalOpen(true)}
        variant="tertiary"
      >
        here.
      </Button>
      <RequestProductChangeModal
        getProductFormValues={getValues}
        isOpen={isModalOpen}
        productId={productId}
        setIsOpen={setIsModalOpen}
        shopId={shopId}
      />
    </div>
  );
};

CustomizationsHelp.propTypes = {
  getValues: PropTypes.func.isRequired,
  productId: PropTypes.string.isRequired,
  shopId: PropTypes.string.isRequired,
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default CustomizationsHelp;
