import { useQuery } from '@tanstack/react-query';
import { z } from 'zod';

import useApi from 'hooks/use-api';
import { Shop } from 'types/shops';
import { camelCaseKeys } from 'utilities/objects';

const schema = z.object({
  redirectUrl: z.string(),
  showOnboardingLink: z.boolean(),
});

const getAchOnboardingLinkQueryKey = (shopId: Shop['shopId']) =>
  [shopId, 'ach_onboarding_link'] as const;

export const useAchOnboardingLink = (shopId: Shop['shopId']) => {
  const { authenticatedFetch } = useApi();

  return useQuery({
    queryKey: getAchOnboardingLinkQueryKey(shopId),
    queryFn: async () => {
      try {
        const response = await authenticatedFetch.get(
          `api/management/v2/shops/${shopId}/onboarding_link`,
        );

        return schema.parse(camelCaseKeys(response));
      } catch (error: unknown) {
        // This endpoint will return with an error for shops not configured for adyen
        // which currently we cannot determine before making the request used to determine
        // the ach banner visibility
        return schema.parse({
          redirectUrl: '',
          showOnboardingLink: false,
        });
      }
    },
  });
};
