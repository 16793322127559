// This file contains utilities for dealing with times in the absence of dates
// or timezones. Historically, we have used moment.js to handle clock time
// operations, but using full datetimes for handling times still requires the
// correct handling of timezones, daylight savings, leap seconds, etc. Use cases
// that just require specifying a time of day (eg 11:00 AM to 5:00 PM on
// Wednesdays) benefit from the simplification of omitting dates and timezones.
//
// Currently, a time is defined as a string in the 24-hour format (eg '13:00'
// for 1:00 PM). The precision is to the minute, but could be extended by using
// the dot notation in the future (eg '13:00:09.1345' for second and
// millisecond precision).
//
// In the future the Temporal.PlainTime object will likely be a better choice
// for dealing with plain times, but the Temporal proposal has yet to accepted
// into the JS standard and widely implemented.
// https://tc39.es/proposal-temporal/docs/#Temporal-PlainTime

const TimeRegex = /^([01]\d|2[0-3]):([0-5]\d)$/;

export const getIsValidTime = (time: string): boolean => TimeRegex.test(time);

export const parseTime = (time: string): { hours: number; minutes: number } => {
  const match = TimeRegex.exec(time);

  if (match == null || !match[1] || !match[2]) {
    throw new Error(`${time} is not a valid time string!`);
  }

  return {
    hours: Number.parseInt(match[1]),
    minutes: Number.parseInt(match[2]),
  };
};

export const addMinutesToTime = (time: string, minutes: number): string =>
  getMinutesAsTime(getTimeAsMinutes(time) + minutes);

export const compareTimes = (a: string, b: string): number =>
  getTimeAsMinutes(a) - getTimeAsMinutes(b);

// For example, 13:00 -> 1:00 PM
export const get12HourString = (time: string): string => {
  const parsed = parseTime(time);
  const period = parsed.hours >= 12 ? 'PM' : 'AM';
  const hours = String(
    parsed.hours > 12 ? parsed.hours - 12 : parsed.hours || 12,
  );
  const minutes = String(parsed.minutes).padStart(2, '0');

  return `${hours}:${minutes} ${period}`;
};

// The time as the number of minutes since midnight, which is 0.
const getTimeAsMinutes = (time: string): number => {
  const { hours, minutes } = parseTime(time);
  return hours * 60 + minutes;
};

const getMinutesAsTime = (minutes: number): string => {
  // When adding (or subtracting) minutes the value can overflow into an
  // adjacent day. We normalize the value to be within a 24-hour (1440 minute)
  // range.
  const normalized = minutes >= 0 ? minutes % 1440 : 1440 + (minutes % 1440);
  const hstr = String(Math.trunc(normalized / 60)).padStart(2, '0');
  const mstr = String(normalized % 60).padStart(2, '0');

  return `${hstr}:${mstr}`;
};

export const getTimesInRange = (
  earliest: string,
  latest: string,
  step = 1,
): string[] => {
  const times: string[] = [];

  let minutes = getTimeAsMinutes(earliest);
  let limit = getTimeAsMinutes(latest);

  // A stop time before or equal to the start time is interpreted as a range
  // carrying over to the next day.
  if (limit <= minutes) {
    limit += 1440;
  }

  while (minutes <= limit) {
    times.push(getMinutesAsTime(minutes % 1440));
    minutes += step;
  }

  // Add the latest time to the end in case it is not a multiple of the step.
  if (times[times.length - 1] !== latest) {
    times.push(latest);
  }

  return times;
};
