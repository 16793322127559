import cx from 'classnames';

import styles from './styles.module.scss';

type Props = {
  className?: string;
  customizedImageUrl: string;
  customizedImageAlt: string;
  header: string;
};

const ImagePreview = ({
  className,
  customizedImageUrl,
  customizedImageAlt,
  header,
}: Props) => {
  return (
    <div className={cx(styles.preview, className)}>
      <h4 className={styles.heading}>{header}</h4>
      <div className={styles.previewImageContainer}>
        <img src={customizedImageUrl} alt={customizedImageAlt} />
      </div>
    </div>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default ImagePreview;
