import { Dispatch, SetStateAction } from 'react';
import { VisibilityState } from '@tanstack/react-table';
import cx from 'classnames';

import { Icon, Menu, MenuItem } from 'crust';

import { useTypeSafeParams } from 'hooks/params/use-type-safe-params';
import useAnalytics from 'hooks/use-analytics';
import { Shop } from 'types/shops';
import { getShopHasFullRegister } from 'utilities/shops';

import { FilterButton } from '../filter-button';

import styles from './styles.module.scss';

type TableSettingsProps = {
  shop: Shop;
  columnVisibility: VisibilityState;
  setColumnVisibility: Dispatch<SetStateAction<VisibilityState>>;
};

export const TableSettings = ({
  shop,
  setColumnVisibility,
  columnVisibility,
}: TableSettingsProps) => {
  const { trackTableSettingsClicked, trackTableSettingsUpdateClicked } =
    useAnalytics();
  const { shopId } = useTypeSafeParams(['shopId']);

  const handleColumnVisibilityChange = (column: keyof VisibilityState) => {
    setColumnVisibility((prevState) => {
      const newState = { ...prevState };
      newState[column] = !prevState[column];

      const columnsSelectedArr = Object.keys(newState).filter(
        (it) => newState[it],
      );

      const columnsSelectedCount = columnsSelectedArr.length;

      trackTableSettingsUpdateClicked({
        shopId: Number(shopId),
        columnsSelectedCount,
        columnsSelectedArr,
      });

      return newState;
    });
  };

  return (
    <Menu
      selectionMode="multiple"
      triggerElement={
        <FilterButton
          icon="gear"
          hasActiveFilters={false}
          onPress={() => trackTableSettingsClicked({ shopId: Number(shopId) })}
        >
          Settings
        </FilterButton>
      }
    >
      <MenuItem onAction={() => handleColumnVisibilityChange('price')}>
        <Icon
          icon="checkmark"
          className={cx(columnVisibility.price ? null : styles.isNotSelected)}
        />
        Price
      </MenuItem>
      <MenuItem onAction={() => handleColumnVisibilityChange('category')}>
        <Icon
          icon="checkmark"
          className={cx(
            columnVisibility.category ? null : styles.isNotSelected,
          )}
        />
        Category
      </MenuItem>
      {getShopHasFullRegister(shop) && (
        <MenuItem onAction={() => handleColumnVisibilityChange('availability')}>
          <Icon
            icon="checkmark"
            className={cx(
              columnVisibility.availability ? null : styles.isNotSelected,
            )}
          />
          Availability
        </MenuItem>
      )}
      {getShopHasFullRegister(shop) && (
        <MenuItem onAction={() => handleColumnVisibilityChange('printing')}>
          <Icon
            icon="checkmark"
            className={cx(
              columnVisibility.printing ? null : styles.isNotSelected,
            )}
          />
          Printing
        </MenuItem>
      )}
      <MenuItem onAction={() => handleColumnVisibilityChange('stock')}>
        <Icon
          icon="checkmark"
          className={cx(columnVisibility.stock ? null : styles.isNotSelected)}
        />
        Stock
      </MenuItem>
    </Menu>
  );
};
