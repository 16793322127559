import Users from 'components/register/users';
import { UnexpectedError } from 'components/shared/error';
import Loading from 'components/shared/loading';
import Page from 'components/shared/page';
import { useCurrentShopId } from 'hooks/shops';
import { useShopQuery } from 'hooks/shops/use-shop-query';
import useRegisterUsers from 'hooks/use-register-users';

const RegisterEmployeePermissionsPage = () => {
  const shopId = useCurrentShopId();

  const {
    data: shopData,
    isError: isShopError,
    isLoading: isShopDataLoading,
  } = useShopQuery(shopId);

  const {
    isLoading: isUsersLoading,
    permissionGroups,
    users,
    usersRowData,
  } = useRegisterUsers(String(shopId));

  let content;

  if (isShopDataLoading || isUsersLoading) {
    content = <Loading />;
  } else if (isShopError) {
    content = <UnexpectedError />;
  } else {
    content = (
      <Users
        permissionGroups={permissionGroups}
        shopData={shopData}
        users={users}
        usersRowData={usersRowData}
      />
    );
  }

  return (
    <Page name="employee-permissions" title="Employee Permissions">
      {content}
    </Page>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default RegisterEmployeePermissionsPage;
