import { ElementRef, ForwardedRef, forwardRef, ReactNode } from 'react';
import {
  Button,
  ButtonContext,
  ButtonProps,
  useContextProps,
} from 'react-aria-components';
import classNames from 'classnames';

import { Icon } from 'crust';
import { IconName } from 'crust/components/icon/icon';

import styles from './styles.module.scss';

type FilterButtonProps = Omit<ButtonProps, 'children'> & {
  children: ReactNode;
  icon: IconName;
  hasActiveFilters: boolean;
};

const FilterButton = forwardRef(function FilterButton(
  props: FilterButtonProps,
  ref: ForwardedRef<ElementRef<typeof Button>>,
) {
  [props, ref] = useContextProps(props, ref, ButtonContext);
  const { children, className, icon, hasActiveFilters, ...rest } = props;

  return (
    <Button
      className={classNames(
        styles.filterButton,
        hasActiveFilters && styles.hasActiveFilters,
        className,
      )}
      ref={ref}
      {...rest}
    >
      <Icon icon={icon} />
      {children}
    </Button>
  );
});

export { FilterButton };
