import { useMutation, useQueryClient } from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { Coupon } from 'types/discounts';
import { Shop } from 'types/shops';
import { camelCaseKeys } from 'utilities/objects';

import { getCouponsQueryKey } from './use-coupons-query';

type MutationParams = {
  couponId: Coupon['id'];
  isBeingEnabled: boolean;
};

export const useToggleCouponStatusMutation = (shopId: Shop['shopId']) => {
  const { authenticatedFetch } = useApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ couponId, isBeingEnabled }: MutationParams) => {
      const response = isBeingEnabled
        ? await authenticatedFetch.put(
            `api/management/v2/shops/${shopId}/menus/coupons/${couponId}/enable`,
          )
        : await authenticatedFetch.put(
            `api/management/v2/shops/${shopId}/menus/coupons/${couponId}/disable`,
          );

      return camelCaseKeys(response);
    },
    onMutate: async ({ couponId, isBeingEnabled }) => {
      const queryKey = getCouponsQueryKey(shopId);

      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(queryKey);
      const cachedCoupons = queryClient.getQueryData<Coupon[]>(queryKey);
      if (!cachedCoupons) {
        return;
      }

      const couponBeingEditedIndex = cachedCoupons.findIndex(
        (coupon) => coupon.id === couponId,
      );
      if (couponBeingEditedIndex === -1) {
        return;
      }

      const couponBeingEdited = cachedCoupons[couponBeingEditedIndex];
      if (!couponBeingEdited) {
        return;
      }

      const couponClone: Coupon = {
        ...couponBeingEdited,
        disabled: !isBeingEnabled,
      };

      queryClient.setQueryData(
        getCouponsQueryKey(shopId),
        cachedCoupons.toSpliced(couponBeingEditedIndex, 1, couponClone),
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries(getCouponsQueryKey(shopId));
    },
  });
};
