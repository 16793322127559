import { Fragment } from 'react';
import { UseFormRegister } from 'react-hook-form';
import cx from 'classnames';

import { ConfigOption, SiteEditorFormValues } from 'types/site-editor';
import { fontOptions } from 'utilities/site-editor';
import { capitalize } from 'utilities/strings';

import styles from './styles.module.scss';

type Props = {
  register: UseFormRegister<SiteEditorFormValues>;
};

const FontPicker = ({ register }: Props) => {
  const getInputId = (font: ConfigOption) => {
    return `site-editor-font-picker-${font.name}`;
  };

  return (
    <div>
      <div className={styles.helpText}>
        Updating font updates title, menu category titles, and footer title.
      </div>
      <div className={styles.fontOptionsContainer}>
        {fontOptions.map((font) => (
          <Fragment key={getInputId(font)}>
            <label className={styles.fontOptionLabel}>
              <input
                className={styles.fontOptionInput}
                id={getInputId(font)}
                type="radio"
                {...register('fontCode')}
                value={font.value}
              />
              <span
                className={cx(
                  styles.fontOptionSpan,
                  styles[`fontOption${capitalize(font.name)}`],
                )}
              >
                {font.label}
              </span>
            </label>
          </Fragment>
        ))}
      </div>
    </div>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default FontPicker;
