// This is the Smart integration script that is expected to be embedded into third party websites to enable the smart buttons. The consumer embed team was in charge of developing it.
export const getWebsiteIntegrationsScriptCode = (
  city: string | null,
  name: string | null,
  uuid: string,
): string => {
  return `<!-- Slice Integration // ${name}, ${city}, ${uuid} -->
<script>
(function (w, d, s, o, f, js, fjs) {
    w[o] = w[o] || function (opt) { w[o].q = w[o].q || opt };
    (js = d.createElement(s)), (fjs = d.getElementsByTagName(s)[0]);
    js.src = f;
    js.async = 1;
    if (fjs) { fjs.parentNode.insertBefore(js, fjs) } else { fjs = d.getElementsByTagName('head')[0]; fjs.appendChild(js) }
})(window, document, 'script', 'SliceWidgets', 'https://restaurant-widgets-integrations.slicelife.com/widget.js');
SliceWidgets({ shopUuid: '${uuid}' });
</script>
<!-- Slice Integration // ${name}, ${city}, ${uuid} -->`;
};
