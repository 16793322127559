import { useEffect, useState } from 'react';
import { Control, FieldErrors, useForm, useWatch } from 'react-hook-form';

import FormFeedback from 'components/shared/form-feedback';
import Input from 'components/shared/input';
import Button, { ButtonVariant } from 'components/shared/slice-button';
import { TooltipIconButton } from 'components/shared/tooltips';
import useAnalytics from 'hooks/use-analytics';
import { SetStoredState, StoredState } from 'hooks/use-stored-state';
import {
  SmartPopupFormValues,
  UrlInputFormValues,
  WebsiteIntegrationsAnalyticsPageName,
} from 'types/website-tools';
import { addHttpsProtocolToUrl, isValidHttpUrlString } from 'utilities/strings';
import { getSmartPopupsUrlParams } from 'utilities/website-tools';

import styles from './styles.module.scss';

type Props = {
  control: Control<SmartPopupFormValues>;
  shopId: number;
  shopUuid: string;
  shopUrl: StoredState<string>;
  setShopUrl: SetStoredState<string>;
  page: WebsiteIntegrationsAnalyticsPageName;
};

const SmartPopupPreview = ({
  control,
  shopId,
  shopUuid,
  shopUrl,
  setShopUrl,
  page,
}: Props) => {
  const [invalidSubmitCount, setInvalidSubmitCount] = useState(0);

  const {
    trackSubmittedWebsiteIntegrationsWebsitePreview,
    trackWebsiteIntegrationsUrlValidationErrorDisplayed,
  } = useAnalytics();
  const currentFormValues = useWatch({ control });

  const {
    formState: { errors },
    getValues,
    handleSubmit,
    register,
  } = useForm<UrlInputFormValues>({
    defaultValues: {
      urlInput: shopUrl ?? '',
    },
    mode: 'onBlur',
  });

  const params = getSmartPopupsUrlParams(
    shopUrl ?? '',
    shopUuid,
    currentFormValues as SmartPopupFormValues,
  );

  const handleValidSubmit = ({ urlInput }: UrlInputFormValues) => {
    trackSubmittedWebsiteIntegrationsWebsitePreview({
      shopId: String(shopId),
      page,
      value: urlInput,
      passedValidation: true,
    });
    setShopUrl(urlInput === '' ? urlInput : addHttpsProtocolToUrl(urlInput));
    setInvalidSubmitCount(0);
  };

  const handleInvalidSubmit = (errors: FieldErrors<UrlInputFormValues>) => {
    trackSubmittedWebsiteIntegrationsWebsitePreview({
      shopId: String(shopId),
      page,
      value: errors.urlInput?.ref?.value,
      passedValidation: false,
    });
    setInvalidSubmitCount((previous) => {
      return previous + 1;
    });
  };

  useEffect(() => {
    if (invalidSubmitCount > 0) {
      trackWebsiteIntegrationsUrlValidationErrorDisplayed({
        shopId: String(shopId),
        errorMessage: 'please enter a valid URL',
        errorType: 'validate',
        input: 'website preview',
        location: page,
        submitCount: invalidSubmitCount,
        value: getValues('urlInput'),
      });
    }
  }, [
    getValues,
    invalidSubmitCount,
    shopId,
    trackWebsiteIntegrationsUrlValidationErrorDisplayed,
    page,
  ]);

  const validateUrlInput = (value: string) => {
    if (value === '' || isValidHttpUrlString(addHttpsProtocolToUrl(value))) {
      return true;
    }

    return 'Please enter a valid URL.';
  };

  return (
    <div>
      <form
        className={styles.previewUrlForm}
        onSubmit={handleSubmit(handleValidSubmit, handleInvalidSubmit)}
      >
        <div className={styles.urlInputContainer}>
          <Input
            aria-label={'Enter your website address'}
            placeholder={'Enter your website address'}
            id="smart-button-shop-url"
            {...register('urlInput', {
              required: false,
              validate: validateUrlInput,
            })}
          />
          <Button
            className={styles.previewUrlButton}
            type="submit"
            variant={ButtonVariant.Tertiary}
          >
            Go
          </Button>
        </div>
        <FormFeedback>{errors.urlInput?.message}</FormFeedback>
        {shopUrl && !errors.urlInput && (
          <p className={styles.urlHint}>
            Preview your site below. Not seeing your website?
            <TooltipIconButton
              label="Preview Site"
              tooltipClassName={styles.tooltip}
            >
              Check the URL and try again. Note: Some websites are not able to
              be previewed. You can still manage your Smart Popup using this
              tool.
            </TooltipIconButton>
          </p>
        )}
      </form>
      <iframe
        className={styles.previewIframe}
        src={`/smart-button-preview.html?${params}`}
        scrolling="no"
        title="Smart Button Preview"
      />
    </div>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default SmartPopupPreview;
