import { NotFoundError } from 'components/shared/error';
import Page from 'components/shared/page';

const pageName = 'not-found';

const NotFound = () => (
  <Page name={pageName} isNavShown={false} isHeaderShown={false} isFooterShown>
    <NotFoundError />
  </Page>
);

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default NotFound;
