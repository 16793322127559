import * as React from "react";
import { forwardRef } from "react";
const SvgCalendarAddEvent = ({
  title,
  titleId,
  ...props
}, ref) => {
  return /* @__PURE__ */ React.createElement("svg", { width: 20, height: 22, viewBox: "0 0 20 22", fill: "none", xmlns: "http://www.w3.org/2000/svg", ref, "aria-labelledby": titleId, ...props }, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { d: "M19 8.45V4.5C19 3.94772 18.5523 3.5 18 3.5H15M19 8.45H1M19 8.45V19.5C19 20.0523 18.5523 20.5 18 20.5H2C1.44772 20.5 1 20.0523 1 19.5V8.45M1 8.45V4.5C1 3.94772 1.44772 3.5 2 3.5H5M5 3.5V0.5M5 3.5H15M15 3.5V0.5M6 14.5H10M14 14.5H10M10 14.5V10.5M10 14.5V18.5", stroke: "currentColor", strokeWidth: 2 }));
};
const ForwardRef = forwardRef(SvgCalendarAddEvent);
export default ForwardRef;
