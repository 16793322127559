import { useMemo } from 'react';
import cx from 'classnames';

import { OrderItem } from 'types/register-orders';
import { toDollarString, toMoneyString } from 'utilities/currency';
import { getRegisterOrderItemTotalPrice } from 'utilities/financials/orders';
import { createIntegerComparator } from 'utilities/sorting';

import styles from './styles.module.scss';

type Props = {
  orderItem: OrderItem;
};

type SubItemProps = {
  isRefunded: boolean;
} & Props;

const OpenFoodItemEntry = ({ orderItem, isRefunded }: SubItemProps) => {
  return (
    <li
      className={cx(styles.orderItem, isRefunded && styles.refunded)}
      key={orderItem.productName}
    >
      <div className={styles.orderItemQuantity}>{orderItem.quantity}</div>
      <div className={styles.orderItemName}>Open Food</div>
      <div className={styles.orderItemTotalPrice}>
        {getRegisterOrderItemTotalPrice(orderItem)}
      </div>
      <div className={styles.orderItemModifiers}>
        Item:{' '}
        {orderItem.productName ? orderItem.productName : '[no name entered]'} (
        {toMoneyString(toDollarString(orderItem.unitPrice), true, true)})
      </div>
    </li>
  );
};

const ExtraChargeItemEntry = ({ orderItem, isRefunded }: SubItemProps) => {
  return (
    <li
      className={cx(styles.orderItem, isRefunded && styles.refunded)}
      key={orderItem.productName}
    >
      <div className={styles.orderItemQuantity}>{orderItem.quantity}</div>
      <div className={styles.orderItemName}>Extra Charge</div>
      <div className={styles.orderItemTotalPrice}>
        {getRegisterOrderItemTotalPrice(orderItem)}
      </div>
    </li>
  );
};

const ItemWithModifiersEntry = ({ orderItem, isRefunded }: SubItemProps) => {
  // only show modifiers that have selections made
  const addonSummaries = useMemo(() => {
    const positionComparator = createIntegerComparator('position');

    return orderItem.orderItemAddons
      .filter((addon) => addon.orderItemSelections.length > 0)
      .sort(positionComparator)
      .map((addon) => {
        const id = addon.id;
        const addonName = addon.name;
        const selections = addon.orderItemSelections;
        const sortedSelections = selections.slice().sort(positionComparator);

        const selectionDetails = sortedSelections.map((selection) => {
          // For each addon, we want to display the selections in the following format:
          // Customization Set A Name: Customization X ($#.##), Customization Y (2x $#.##), Customization Z on half
          // meaning each row will be an addon, with the selections for that addon on the same row, with the name,
          // details on if it is a half selection, quantity if available and price if available

          const isHalf =
            selection.kind === 'LEFT_HALF' || selection.kind === 'RIGHT_HALF';
          const price = selection.unitPrice
            ? toMoneyString(toDollarString(selection.unitPrice))
            : '';
          const quantity = selection.quantity;
          const name = selection.modifierName;

          let fullDetail = name;
          if (isHalf) {
            fullDetail += ' on half';
          }

          if (price || quantity > 1) {
            fullDetail += ' (';

            if (quantity > 1) {
              fullDetail += `${quantity}x`;

              if (price) {
                fullDetail += ' ';
              }
            }

            if (price) {
              fullDetail += `${price}`;
            }

            fullDetail += ')';
          }

          return fullDetail;
        });

        return {
          id,
          summary: `${addonName}: ${selectionDetails.join(', ')}`,
        };
      });
  }, [orderItem.orderItemAddons]);

  return (
    <li
      className={cx(styles.orderItem, isRefunded && styles.refunded)}
      key={orderItem.productName}
    >
      <div className={styles.orderItemQuantity}>{orderItem.quantity}</div>
      <div className={styles.orderItemName}>{orderItem.productName}</div>
      <div className={styles.orderItemTotalPrice}>
        {getRegisterOrderItemTotalPrice(orderItem)}
      </div>
      <div className={styles.orderItemModifiers}>
        <div>
          Type: {orderItem.typeName} (
          {toMoneyString(toDollarString(orderItem.unitPrice), true, true)})
        </div>
        {addonSummaries.map((entry) => (
          <div key={entry.id}>{entry.summary}</div>
        ))}
      </div>
    </li>
  );
};

const OrderItemEntry = ({ orderItem }: Props) => {
  const isRefunded = !!orderItem.refundId;

  if (orderItem.lineItemType === 'open_food_item') {
    return <OpenFoodItemEntry orderItem={orderItem} isRefunded={isRefunded} />;
  }

  if (orderItem.lineItemType === 'extra_charge') {
    return (
      <ExtraChargeItemEntry orderItem={orderItem} isRefunded={isRefunded} />
    );
  }

  return (
    <ItemWithModifiersEntry orderItem={orderItem} isRefunded={isRefunded} />
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default OrderItemEntry;
