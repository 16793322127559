// List of traits shown in the shop-scores actions page
// Maintaining full list of traits to be compared against list of trait strings
// returned by the traits endpoint.
// If a trait string is in the list of traits returned from the endpoint,
// it is a *completed* action.
// If a trait string is not found in the list of traits returned from the
// endpoint, it is an *incomplete* action.
export enum ShopScoreTraits {
  OpsScore10PlusPhotos = 'ops_score_10_plus_photos',
  OpsScorePhotosHasFeaturedPhotos = 'ops_score_photos_has_featured_photos',
  DigitalScoreGmbWebsite = 'digital_score_gmb_website',
  DigitalScoreHasGfo = 'digital_score_has_gfo',
  DigitalScoreHasOnlineOrdering = 'digital_score_has_online_ordering',
  DigitalScoreRealDirectPartnership = 'digital_score_real_direct_partnership',
  OpsScorePreferredTransmission = 'ops_score_preferred_transmission',
  OpsScoreHasDelivery = 'ops_score_has_delivery',
  OpsScoreHasOnlineDiscount = 'ops_score_has_online_discount',
  OpsScoreAvoidableOrderIssueRate28 = 'ops_score_avoidable_order_issue_rate_28',
}

// These are always part of "traitsWithValues" in the shop score data.
// These do not show up in the "traits" array in the shop score data.
export enum ShopScoreAdditionalTraits {
  AvoidableIssueRate = 'odr_percent',
  NetworkScore = 'derived_network_average_score',
}

export enum ShopScoreStatusLevel {
  AllStar = 85,
  Great = 63,
  Good = 40,
}

export type ShopScoreResponse = {
  score: number;
  traitsWithValues: { [key in ShopScoreTraits]?: number } & {
    [key in ShopScoreAdditionalTraits]: number;
  };
};
