import { useMemo } from 'react';

import { Link } from 'crust';

import { registerLayout } from 'routes/paths';
import { RegisterLayoutCategory } from 'types/register-layout';
import { getItemsWord } from 'utilities/menu';

import styles from './styles.module.scss';

type Props = {
  category?: RegisterLayoutCategory;
  isSingleColumn?: boolean;
  shopId: string;
};

const RegisterMenuTileHeader = ({ category, shopId }: Props) => {
  const title = useMemo(() => {
    if (category?.items == null) {
      return 'Categories';
    }

    const count = category.items.length;

    return (
      <>
        {category.name}{' '}
        <span className={styles.titleCount}>
          ({count} {getItemsWord(count)})
        </span>
      </>
    );
  }, [category]);

  return (
    <>
      <div className={styles.header}>
        {category != null && (
          <Link href={registerLayout(shopId)}>Back to categories</Link>
        )}
        <div className={styles.title}>{title}</div>
      </div>
      <div className={styles.description}>
        Drag around your {category == null ? 'categories' : 'items'} to
        customize your menu within Register. Edits made in either the grid or
        the list view will effectively update your menu.
      </div>
    </>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default RegisterMenuTileHeader;
