import { Link } from 'crust';

import FeatureHighlight from 'components/shared/feature-highlight';
import useAnalytics from 'hooks/use-analytics';
import * as paths from 'routes/paths';
import { Shop } from 'types/shops';

import styles from './styles.module.scss';

const linkLabel = 'Check It Out';

type Props = {
  shopId: Shop['shopId'];
};

export const PayoutsFeatureHighlight = ({ shopId }: Props) => {
  const { trackInternalFeatureLinkClick } = useAnalytics();

  const onLinkClick = () => {
    trackInternalFeatureLinkClick({
      copy: linkLabel,
      fromPage: 'statements',
      shopId: shopId,
      toPage: 'payouts',
    });
  };

  return (
    <FeatureHighlight
      buttonsWrapperClassName={styles.buttonsWrapper}
      containerClassName={styles.container}
      textClassName={styles.text}
      titleClassName={styles.title}
      primaryCta={
        <Link
          onPress={onLinkClick}
          href={paths.getFinancialsPayoutsPath(shopId)}
          size="small"
          variant="primary"
        >
          {linkLabel}
        </Link>
      }
      text="We've added a new section where you can see a history of your payouts."
      title="New Payouts Section"
    />
  );
};
