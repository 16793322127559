import styles from './styles.module.scss';

// Offsets chosen to match as close to designs as possible.
const rectWidth = 36;
const rectHeight = 20;
const rectYOffset = (-1 * rectHeight) / 2;
const rectXOffset = 22;

const textXOffset = 30;
const textYOffset = 4;

type Props = {
  availability: string;
  x: number;
  y: number;
};

export const YAxisTick = ({ availability, x, y }: Props) => {
  return (
    <g>
      <rect
        x={x + rectXOffset}
        y={y + rectYOffset}
        width={rectWidth}
        height={rectHeight}
        fill="white"
        opacity={1}
        rx="4px"
        ry="4px"
      />
      <text
        x={x + textXOffset}
        y={y + textYOffset}
        className={styles.yAxisTickText}
      >
        {availability}%
      </text>
    </g>
  );
};
