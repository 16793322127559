import * as React from "react";
import { forwardRef } from "react";
const SvgChevronLeftIcon = ({
  title,
  titleId,
  ...props
}, ref) => {
  return /* @__PURE__ */ React.createElement("svg", { width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", "aria-hidden": "true", ref, "aria-labelledby": titleId, ...props }, title === void 0 ? /* @__PURE__ */ React.createElement("title", { id: titleId }, "Chevron Left Icon") : title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M8.14849 9.22731L15.3758 2L17.1067 3.5012L10.7326 12L17.1067 20.4988L15.3758 22L8.14849 14.7727C7.41312 14.0373 7 13.04 7 12C7 10.96 7.41312 9.96267 8.14849 9.22731Z", fill: "currentColor" }));
};
const ForwardRef = forwardRef(SvgChevronLeftIcon);
export default ForwardRef;
