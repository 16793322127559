import { MenuCategoryAvailability } from 'types/menu/category';
import { formatTime } from 'utilities/date-time';

type ExtendedCategoryAvailability = {
  additionalAvailability: number;
} & MenuCategoryAvailability;

type AvailabilityByDay = Record<string, ExtendedCategoryAvailability>;

export const getAvailabilitySummary = (
  availabilities: MenuCategoryAvailability[],
): string[] => {
  if (availabilities.length === 0) {
    return ['Always Available'];
  } else {
    const availabilityByDay = getAvailabilitiesByDay(availabilities);

    return getAvailabilityDayStrings(availabilityByDay);
  }
};

export const getAvailabilitiesByDay = (
  availabilities: MenuCategoryAvailability[],
): AvailabilityByDay => {
  const availabilityByDay: AvailabilityByDay = {};

  availabilities.forEach((availability) => {
    const entry = availabilityByDay[availability.dayOfWeek];

    // If the day does not already have an availability object then create one
    if (entry == null) {
      availabilityByDay[availability.dayOfWeek] = {
        id: availability.id,
        dayOfWeek: availability.dayOfWeek,
        startTime: availability.startTime,
        endTime: availability.endTime,
        additionalAvailability: 0,
      };
      // Add to existing availability object for the day
    } else {
      // Ensure the earliest start time will display
      if (
        Number.parseInt(entry.startTime.replace(':', '')) >
        Number.parseInt(availability.startTime.replace(':', ''))
      ) {
        entry.startTime = availability.startTime;
        entry.endTime = availability.endTime;
      }
      entry.additionalAvailability += 1;
    }
  });
  return availabilityByDay;
};

export const getAvailabilityDayStrings = (
  availabilityByDayObject: AvailabilityByDay,
): string[] => {
  const availability = [];
  for (const day of days) {
    const value = availabilityByDayObject[day];

    if (value == null) {
      continue;
    }

    availability.push(
      `${day.slice(0, 3)} ${formatTime(
        value.startTime,
        'HH:mm',
        'hh:mmA',
      )} - ${formatTime(value.endTime, 'HH:mm', 'hh:mmA')}${
        value.additionalAvailability > 0
          ? ` +${value.additionalAvailability} more`
          : ''
      }`,
    );
  }
  return availability;
};

const days = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];
