import { Id, shop } from './shop';

export const shopHours = (shopId?: Id): string => `${shop(shopId)}/hours`;

export const shopHoursWeekly = (shopId?: Id): string =>
  `${shop(shopId)}/weekly-hours`;

export const shopHoursDelivery = (shopId?: Id): string =>
  `${shop(shopId)}/weekly-hours/delivery`;

export const shopClosings = (shopId?: Id): string => `${shop(shopId)}/closings`;

export const shopHoursAnalytics = (shopId?: Id): string =>
  `${shopHours(shopId)}/analytics`;
