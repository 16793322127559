import { ReactNode } from 'react';

import { TooltipIconButton } from 'components/shared/tooltips';

import styles from './styles.module.scss';

type Props = {
  content: ReactNode;
  label: string;
};

const CustomerTypeTooltip = ({ content, label }: Props) => {
  return (
    <TooltipIconButton label={label} tooltipClassName={styles.tooltip}>
      {content}
    </TooltipIconButton>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default CustomerTypeTooltip;
