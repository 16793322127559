import classNames from 'classnames';

/* eslint-disable-next-line @typescript-eslint/no-explicit-any -- We don't need
 * to infer the parameter type here. We'll do so for the return type. */
type ClassName = string | ((values: any) => string);

type MergedClassName<T extends ClassName> = T extends (
  values: infer R,
) => string
  ? (values: R) => string
  : string;

export const mergeAriaClassName = <T extends ClassName>(
  base: string,
  className?: T,
): MergedClassName<T> => {
  if (typeof className === 'function') {
    return ((values: T) => classNames(base, className(values))) as never;
  }

  return classNames(base, className) as never;
};
