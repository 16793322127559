import { useWatch } from 'react-hook-form';
import PropTypes from 'prop-types';

import { CustomizationField, getCustomizationFieldName } from 'utilities/menu';

import CustomizationSummary from '../../customization-summary';

const CustomizationTileSummary = ({ control, index }) => {
  const isRequired = useWatch({
    control,
    name: getCustomizationFieldName(CustomizationField.IsRequired, index),
  });

  const limit = useWatch({
    control,
    name: getCustomizationFieldName(CustomizationField.Limit, index),
  });

  return <CustomizationSummary isRequired={isRequired} limit={limit} />;
};

CustomizationTileSummary.propTypes = {
  control: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default CustomizationTileSummary;
