import { z } from 'zod';

const CustomerImportUploadMetadataSchema = z.object({
  customers: z.object({
    existing: z.number(),
    new: z.number(),
    total: z.number(),
    updated: z.number(),
  }),
});

export type CustomerImportUploadMetadata = z.infer<
  typeof CustomerImportUploadMetadataSchema
>;

const CustomerImportSchema = z.object({
  createdAt: z.string(),
  expiresAt: z.string(),
  generatedAt: z.string(),
  id: z.string(),
  shopUuid: z.string(),
  updatedAt: z.string(),
  uploadMetadata: z.nullable(CustomerImportUploadMetadataSchema),
  uploadStatus: z.string(),
  uploadType: z.string(),
});

export type CustomerImport = z.infer<typeof CustomerImportSchema>;

const CustomerImportMetaSchema = z.object({
  pagination: z.object({
    currentPage: z.number(),
    pages: z.number(),
    perPage: z.number(),
    total: z.number(),
  }),
});

export type CustomerImportMeta = z.infer<typeof CustomerImportMetaSchema>;

export const CustomerImportsSchema = z.object({
  data: z.array(CustomerImportSchema).default([]),
  meta: CustomerImportMetaSchema,
});

export type CustomerImports = z.infer<typeof CustomerImportsSchema>;

const FileErrorSchema = z.object({
  errors: z.array(z.string()),
});

export type CustomerImportFileErrors = z.infer<typeof FileErrorSchema>;

const RowErrorSchema = z.object({
  column: z.string(),
  errorMessage: z.string(),
});

const RowErrorsSchema = z.object({
  errors: z.array(RowErrorSchema),
  row: z.number(),
});

export const CustomerImportsErrorSchema = z.object({
  fileErrors: FileErrorSchema.optional(),
  rowErrors: z.array(RowErrorsSchema).optional(),
});

export type CustomerImportErrors = z.infer<typeof CustomerImportsErrorSchema>;
