import cx from 'classnames';
import PropTypes from 'prop-types';

import Spinner from 'components/shared/spinner';

import styles from './styles.module.scss';

// This component is meant to simplify the patterns used to show content behind
// a loading spinner. It contains both the styling necessary to act as a decent
// placeholder and the conditional logic to remove ternary operators elsewhere.
const Suspended = ({ children, className, isLoading, render, variant }) => {
  if (isLoading) {
    return (
      <div className={cx(styles[variant], className)}>
        <Spinner />
      </div>
    );
  }

  if (render) {
    return render();
  }

  return children;
};

Suspended.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  render: PropTypes.func,

  // Variants are needed because the height of the placeholder needs to vary
  // depending upon the situation. In some cases we will want the placeholder to
  // stretch to fill available space. In others, we will want standardized
  // heights because the parent component does not stretch but the final height
  // of the content is unknown (eg CollapsibleTile). The viewPort variant exists
  // in the case where the a parent element does not set a height and the
  // spinner is to be placed in the middle the page.
  variant: PropTypes.oneOf(['short', 'stretch', 'viewPort']),
};

Suspended.defaultProps = {
  children: null,
  className: null,
  isLoading: false,
  render: null,
  variant: 'stretch',
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default Suspended;
