import * as React from "react";
import { forwardRef } from "react";
const SvgNewCustomerIconNoOutline = ({
  title,
  titleId,
  ...props
}, ref) => {
  return /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", fill: "none", viewBox: "0 0 24 24", ref, "aria-labelledby": titleId, ...props }, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { fill: "#fff", d: "M0 0h24v24H0z" }), /* @__PURE__ */ React.createElement("path", { stroke: "#000", strokeWidth: 2, d: "M21 4.5c0 .82843-.6716 1.5-1.5 1.5S18 5.32843 18 4.5 18.6716 3 19.5 3s1.5.67157 1.5 1.5ZM4 11.9706c2.50538-.152 4.42182-.8924 5.75-2.2206 1.3282-1.32818 2.0686-3.24462 2.2206-5.75h.0588c.152 2.50538.8924 4.42182 2.2206 5.75 1.3282 1.3282 3.2446 2.0686 5.75 2.2206v.0588c-2.5054.152-4.4218.8924-5.75 2.2206-1.3282 1.3282-2.0686 3.2446-2.2206 5.75h-.0588c-.152-2.5054-.8924-4.4218-2.2206-5.75-1.32818-1.3282-3.24462-2.0686-5.75-2.2206v-.0588ZM6 18.5c0 .2761-.22386.5-.5.5s-.5-.2239-.5-.5.22386-.5.5-.5.5.2239.5.5Z" }));
};
const ForwardRef = forwardRef(SvgNewCustomerIconNoOutline);
export default ForwardRef;
