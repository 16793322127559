import { Shop, ShopTraits } from 'types/shops';
import { User } from 'types/user';
import { getShopHasFullRegister } from 'utilities/shops';

import CustomWebsiteCallout from './custom-website-callout';
import OnlineOrderingCallout from './online-ordering-callout';
import ReferralCallout from './referral-callout';
import RegisterCallout from './register-callout';
import SmartButtonCallout from './smart-button-callout';

type Props = {
  hasValidCase: boolean;
  onRequestDemoButtonClick: () => void;
  shop: Shop;
  traits: ShopTraits;
  user: User;
};

/**
 *  Decide which Callout to display on the homepage:
 *  https://docs.google.com/presentation/d/1HeX_sgAVnbViSjWSoulw5XVhbMBDL3OfvpKreqFymYc/edit#slide=id.g2bca5721e55_0_66.
 *
 *  In isOnlineOrdering, we're using both OO and EOO shops.
 */

const RecommendedCallout = ({
  hasValidCase,
  onRequestDemoButtonClick,
  shop,
  traits,
  user,
}: Props) => {
  // https://mypizza.slack.com/archives/C0151665FA8/p1709137731277789
  const shopIntegrationAdoption = traits.shopIntegrationAdoption?.toLowerCase();
  const isOnlineOrdering =
    shopIntegrationAdoption === 'direct' ||
    shopIntegrationAdoption === 'exclusive direct';
  const isRegisterShop = getShopHasFullRegister(shop);
  const hasOwnWebsite = !!shop.ownWebsite;
  const hasCustomWebsite = !!shop.domainName;
  const hasPartnerButton = traits.isSmartButtonActive;

  if (!isOnlineOrdering && hasOwnWebsite) {
    return hasPartnerButton ? (
      <OnlineOrderingCallout
        hasCustomWebsite={hasCustomWebsite}
        hasOwnWebsite={hasOwnWebsite}
        hasSmartButton={hasPartnerButton ?? false}
        isRegisterShop={isRegisterShop}
        shopId={shop.shopId}
        user={user}
      />
    ) : (
      <SmartButtonCallout
        hasCustomWebsite={hasCustomWebsite}
        hasOwnWebsite={hasOwnWebsite}
        hasSmartButton={hasPartnerButton ?? false}
        isOnlineOrdering={isOnlineOrdering}
        isRegisterShop={isRegisterShop}
        shopId={shop.shopId}
      />
    );
  }

  if (!isOnlineOrdering && !hasOwnWebsite) {
    return hasCustomWebsite ? (
      <OnlineOrderingCallout
        hasCustomWebsite={hasCustomWebsite}
        hasOwnWebsite={hasOwnWebsite}
        hasSmartButton={hasPartnerButton ?? false}
        isRegisterShop={isRegisterShop}
        shopId={shop.shopId}
        user={user}
      />
    ) : (
      <CustomWebsiteCallout
        hasOwnWebsite={hasOwnWebsite}
        hasSmartButton={hasPartnerButton ?? false}
        isOnlineOrdering={isOnlineOrdering}
        isRegisterShop={isRegisterShop}
        shopId={shop.shopId}
      />
    );
  }

  return isRegisterShop ? (
    <ReferralCallout
      hasCustomWebsite={hasCustomWebsite}
      hasOwnWebsite={hasOwnWebsite}
      hasSmartButton={hasPartnerButton ?? false}
      isOnlineOrdering={isOnlineOrdering}
      isRegisterShop={isRegisterShop}
      shopId={shop.shopId}
    />
  ) : (
    <RegisterCallout
      hasCustomWebsite={hasCustomWebsite}
      hasOwnWebsite={hasOwnWebsite}
      hasSmartButton={hasPartnerButton ?? false}
      hasValidCase={hasValidCase}
      isOnlineOrdering={isOnlineOrdering}
      onRequestDemoButtonClick={onRequestDemoButtonClick}
      shopId={shop.shopId}
    />
  );
};
/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default RecommendedCallout;
