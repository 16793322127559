import PropTypes from 'prop-types';

import styles from './styles.module.scss';

const Table = ({ rowContent: RowContent, data }) => (
  <div className={styles.table}>
    {data.map((rowData) => (
      <div
        className={styles.rowWrapper}
        key={rowData.id}
        aria-label="Customer entry"
      >
        <RowContent data={rowData} />
      </div>
    ))}
  </div>
);

Table.propTypes = {
  data: PropTypes.array,
  rowContent: PropTypes.func.isRequired,
};

Table.defaultProps = {
  data: [],
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default Table;
