import * as React from "react";
import { forwardRef } from "react";
import { useId } from "react-aria";
const SvgAllCustomersIcon = ({
  title,
  titleId,
  ...props
}, ref) => {
  const id0 = useId();
  return /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", fill: "none", viewBox: "0 0 32 32", ref, "aria-labelledby": titleId, ...props }, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("circle", { cx: 16, cy: 16, r: 16, fill: "#F5F3F0" }), /* @__PURE__ */ React.createElement("g", { clipPath: `url(#${id0})` }, /* @__PURE__ */ React.createElement("path", { stroke: "#E7664C", strokeWidth: 2, d: "M7.83325 22.5002c.83463-2.8738 3.31725-4.9584 6.25005-4.9584 2.1107 0 3.9883 1.0798 5.1861 2.7564m1.0639 2.202c-.234-.8055-.5974-1.549-1.0639-2.202m6.8972 2.202c-.5564-1.8595-2.2115-3.2084-4.1667-3.2084-1.0333 0-1.9827.3767-2.7305 1.0064m-1.7139-8.423c0 1.956-1.5546 3.5416-3.4722 3.5416-1.9177 0-3.4723-1.5856-3.4723-3.5416 0-1.95605 1.5546-3.5417 3.4723-3.5417 1.9176 0 3.4722 1.58565 3.4722 3.5417Zm6.7592 2.9166c0 1.2657-1.0363 2.2917-2.3148 2.2917-1.2784 0-2.3148-1.026-2.3148-2.2917 0-1.2656 1.0364-2.2916 2.3148-2.2916 1.2785 0 2.3148 1.026 2.3148 2.2916Z" })), /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("clipPath", { id: id0 }, /* @__PURE__ */ React.createElement("path", { fill: "#fff", d: "M7 5h20v20H7z" }))));
};
const ForwardRef = forwardRef(SvgAllCustomersIcon);
export default ForwardRef;
