import * as React from "react";
import { forwardRef } from "react";
const SvgPinIcon = ({
  title,
  titleId,
  ...props
}, ref) => {
  return /* @__PURE__ */ React.createElement("svg", { width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", "aria-hidden": "true", ref, "aria-labelledby": titleId, ...props }, title === void 0 ? /* @__PURE__ */ React.createElement("title", { id: titleId }, "Pin Icon") : title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M12 2C7.5879 2 4 5.57642 4 9.99164C4 11.8452 4.64432 13.6412 5.82265 15.072L11.2281 21.6357C11.418 21.8664 11.7012 22 12 22C12.2988 22 12.582 21.8664 12.7719 21.6357L18.1773 15.072C19.3557 13.6412 20 11.8452 20 9.99164C20 5.57642 16.4121 2 12 2ZM10 10C10 8.89543 10.8954 8 12 8C13.1046 8 14 8.89543 14 10C14 11.1046 13.1046 12 12 12C10.8954 12 10 11.1046 10 10Z", fill: "currentColor" }));
};
const ForwardRef = forwardRef(SvgPinIcon);
export default ForwardRef;
