import * as React from "react";
import { forwardRef } from "react";
const SvgSuccess = ({
  title,
  titleId,
  ...props
}, ref) => {
  return /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", fill: "none", viewBox: "0 0 20 21", ref, "aria-labelledby": titleId, ...props }, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { stroke: "#0D7728", strokeLinecap: "round", strokeLinejoin: "round", d: "m13.5 8-5.25 4.9-1.75-1.75" }), /* @__PURE__ */ React.createElement("path", { stroke: "#0D7728", strokeLinecap: "round", strokeLinejoin: "round", d: "M18 10.5a8 8 0 1 1-16 0 8 8 0 0 1 16 0v0Z", clipRule: "evenodd" }));
};
const ForwardRef = forwardRef(SvgSuccess);
export default ForwardRef;
