import {
  cloneElement,
  ReactElement,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import cx from 'classnames';
import { v4 as uuidv4 } from 'uuid';

import { calculateTooltipPosition } from 'utilities/tooltips/position';

import styles from './styles.module.scss';

export type TooltipWrapperProps = {
  isTooltipEnabled?: boolean;
  targetElement: ReactElement;
  tooltipClassName?: string;
  tooltipContent: ReactNode;
};

const TooltipWrapper = ({
  isTooltipEnabled = true,
  targetElement,
  tooltipClassName,
  tooltipContent,
}: TooltipWrapperProps) => {
  const tooltipId = useMemo(() => uuidv4(), []);

  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [left, setLeft] = useState(0);
  const [top, setTop] = useState(0);
  const tooltipRef = useRef<HTMLDivElement>(null);
  const targetRef = useRef<HTMLElement>(null);

  const showTooltip = () => {
    if (!isTooltipEnabled || !tooltipRef.current || !targetRef.current) {
      return;
    }

    setIsTooltipVisible(true);

    const { top, left } = calculateTooltipPosition(
      targetRef.current,
      tooltipRef.current,
    );

    setTop(top);
    setLeft(left);
  };

  const hideTooltip = () => {
    setIsTooltipVisible(false);
  };

  useEffect(() => {
    if (!isTooltipVisible) {
      return;
    }

    const mouseDownListener = (event: MouseEvent) => {
      if (!targetRef.current || !event.target) {
        return;
      }

      if (!targetRef.current.contains(event.target as Node)) {
        hideTooltip();
      }
    };

    const scrollListener = () => {
      hideTooltip();
    };

    window.addEventListener('mousedown', mouseDownListener);
    window.addEventListener('scroll', scrollListener);
    window.addEventListener('wheel', scrollListener);

    return () => {
      window.removeEventListener('mousedown', mouseDownListener);
      window.removeEventListener('scroll', scrollListener);
      window.removeEventListener('wheel', scrollListener);
    };
    // We only want this useEffect to run upon changes to tooltip visibility.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTooltipVisible]);

  useEffect(() => {
    if (!isTooltipEnabled && isTooltipVisible) {
      hideTooltip();
    }
  }, [isTooltipEnabled, isTooltipVisible]);

  return (
    <>
      {cloneElement(targetElement, {
        'aria-describedby': tooltipId,
        onBlur: (event: FocusEvent) => {
          hideTooltip();
          targetElement.props.onBlur?.(event);
        },
        onClick: (event: MouseEvent) => {
          event.stopPropagation();
          event.preventDefault();
          showTooltip();
          targetElement.props.onClick?.(event);
        },
        onFocus: (event: FocusEvent) => {
          showTooltip();
          targetElement.props.onFocus?.(event);
        },
        onKeyDown: (event: KeyboardEvent) => {
          if (event.code === 'Escape') {
            hideTooltip();
          }
        },
        onMouseEnter: (event: MouseEvent) => {
          showTooltip();
          targetElement.props.onMouseEnter?.(event);
        },
        onMouseLeave: (event: MouseEvent) => {
          hideTooltip();
          targetElement.props.onMouseLeave?.(event);
        },
        ref: targetRef,
      })}
      {createPortal(
        <div
          className={cx(styles.tooltipContainer, tooltipClassName)}
          id={tooltipId}
          ref={tooltipRef}
          role="tooltip"
          style={{
            left: left,
            top: top,
            visibility: isTooltipVisible ? 'visible' : 'hidden',
          }}
        >
          {tooltipContent}
        </div>,
        document.body,
      )}
    </>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default TooltipWrapper;
