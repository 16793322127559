import * as React from "react";
import { forwardRef } from "react";
const SvgCalendarEvent = ({
  title,
  titleId,
  ...props
}, ref) => {
  return /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 20 21", ref, "aria-labelledby": titleId, ...props }, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { fill: "none", stroke: "currentColor", strokeWidth: 2, d: "M19,7.95v-3.95c0-.55-.45-1-1-1h-3m4,4.95H1m18,0v11.05c0,.55-.45,1-1,1H2c-.55,0-1-.45-1-1V7.95m0,0v-3.95c0-.55,.45-1,1-1h3m0,0V0m0,3H15m0,0V0m-3,14.5v-1.5h3v1.5m-3,0v1.5h3v-1.5m-3,0h3" }));
};
const ForwardRef = forwardRef(SvgCalendarEvent);
export default ForwardRef;
