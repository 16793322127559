import { CustomerList, CustomerType, DateRange } from 'types/customers';
import {
  customerListKnowYourCustomersLabels,
  customerTypeLabels,
  dateRangeLabels,
} from 'utilities/customers';

import styles from './styles.module.scss';

type Props = {
  customerList: CustomerList;
  customerType: CustomerType;
  dateRange: DateRange;
};

const KnowYourCustomersTitle = ({
  customerList,
  customerType,
  dateRange,
}: Props) => {
  const customerListText = customerListKnowYourCustomersLabels[customerList];
  const customerTypeText = customerTypeLabels[customerType];
  const dateRangeText = dateRangeLabels[dateRange];

  const text = `${customerListText} ${customerTypeText} Customers Who Ordered in The ${dateRangeText}.`;

  return <div className={styles.dynamicTitle}>{text}</div>;
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default KnowYourCustomersTitle;
