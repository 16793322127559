import * as React from "react";
import { forwardRef } from "react";
const SvgRegisterIcon = ({
  title,
  titleId,
  ...props
}, ref) => {
  return /* @__PURE__ */ React.createElement("svg", { width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", "aria-hidden": "true", ref, "aria-labelledby": titleId, ...props }, title === void 0 ? /* @__PURE__ */ React.createElement("title", { id: titleId }, "Register Icon") : title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M3.81818 4C2.81403 4 2 4.81403 2 5.81818V18.5455C2 19.5496 2.81403 20.3636 3.81818 20.3636H20.1818C21.186 20.3636 22 19.5496 22 18.5455V5.81818C22 4.81403 21.186 4 20.1818 4H3.81818ZM5.63636 8.09091C5.63636 7.83987 5.83987 7.63636 6.09091 7.63636H11.5455C11.7965 7.63636 12 7.83987 12 8.09091V16.2727C12 16.5238 11.7965 16.7273 11.5455 16.7273H6.09091C5.83987 16.7273 5.63636 16.5238 5.63636 16.2727V8.09091ZM13.8182 8.09091C13.8182 7.83987 14.0217 7.63636 14.2727 7.63636H17.9091C18.1601 7.63636 18.3636 7.83987 18.3636 8.09091V9.90909C18.3636 10.1601 18.1601 10.3636 17.9091 10.3636H14.2727C14.0217 10.3636 13.8182 10.1601 13.8182 9.90909V8.09091ZM14.2727 12.1818C14.0217 12.1818 13.8182 12.3853 13.8182 12.6364V16.2727C13.8182 16.5238 14.0217 16.7273 14.2727 16.7273H17.9091C18.1601 16.7273 18.3636 16.5238 18.3636 16.2727V12.6364C18.3636 12.3853 18.1601 12.1818 17.9091 12.1818H14.2727Z", fill: "currentColor" }));
};
const ForwardRef = forwardRef(SvgRegisterIcon);
export default ForwardRef;
