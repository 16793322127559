import { useMemo } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import moment from 'moment-timezone';

import Button, { ButtonVariant } from 'components/shared/slice-button';
import Table from 'components/shared/table';
import { useTable } from 'hooks/shared';
import useAnalytics from 'hooks/use-analytics';
import {
  CustomerProfileOrder,
  CustomerProfileOrderOrderTypeLabels,
  CustomerProfileOrderShippingTypeLabels,
} from 'types/customers';
import { toDollarString, toMoneyString } from 'utilities/currency';

import ItemsCell from './customer-details-items-cell';

import styles from './styles.module.scss';

type Props = {
  customerUuid: string;
  loadMoreOrders: () => void;
  orders: CustomerProfileOrder[];
  shopId: string;
  shopTimezone: string;
  showLoadMore: boolean;
};

const CustomerOrdersTable = ({
  customerUuid,
  loadMoreOrders,
  orders,
  shopId,
  shopTimezone,
  showLoadMore,
}: Props) => {
  const { trackClickedLoadMoreOrders } = useAnalytics();

  const handleLoadMoreClick = () => {
    trackClickedLoadMoreOrders({
      shopId,
      customerUuid,
    });

    loadMoreOrders();
  };

  const columns = useMemo(() => {
    const helper = createColumnHelper<CustomerProfileOrder>();

    return [
      helper.display({
        id: 'datePurchased',
        header: 'Date & Time',
        meta: {
          className: styles.dateTimeColumn,
        },
        cell(ctx) {
          return (
            <div className={styles.dateTimeCell}>
              <div className={styles.orderDate}>
                {moment
                  .tz(ctx.row.original.datePurchased, shopTimezone)
                  .format('MM/DD/YYYY')}
              </div>
              <div>
                {moment
                  .tz(ctx.row.original.datePurchased, shopTimezone)
                  .format('hh:mma')}
              </div>
            </div>
          );
        },
      }),
      helper.accessor(
        (row) => CustomerProfileOrderOrderTypeLabels[row.orderType],
        {
          id: 'orderType',
          header: 'Order Source',
          meta: {
            className: styles.orderSourceColumn,
          },
        },
      ),
      helper.accessor(
        (row) => CustomerProfileOrderShippingTypeLabels[row.shippingType],
        {
          id: 'shippingType',
          header: 'Order Type',
          meta: {
            className: styles.orderTypeColumn,
          },
        },
      ),
      helper.display({
        id: 'items',
        header: 'Items',
        cell(ctx) {
          return <ItemsCell items={ctx.row.original.items} />;
        },
      }),
      helper.accessor(
        (row) => toMoneyString(toDollarString(row.orderTotal), true, true),
        {
          id: 'orderTotal',
          header: 'Total Spend',
        },
      ),
    ];
  }, [shopTimezone]);

  const table = useTable({
    columns,
    data: orders,
    enableSorting: false,
    chameleonTableTitle: 'Customer Orders',
    state: {},
  });

  return (
    <div data-test-id="customer details modal table">
      <Table table={table} />
      {showLoadMore && (
        <div className={styles.loadMoreContainer}>
          <Button
            className={styles.loadMoreButton}
            onClick={handleLoadMoreClick}
            variant={ButtonVariant.Secondary}
          >
            Load More
          </Button>
        </div>
      )}
    </div>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default CustomerOrdersTable;
