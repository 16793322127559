import moment from 'moment-timezone';

import { CLOSED_VALUE } from 'components/shared/time-select';

/**
 * Breaks down an array of entries into a nested object
 * keyed by type then day then ID
 * @param {Array} entries - the array of entries to break down
 * @return {Object} - a nested keyed object of entries
 */
export const entriesByTypeAndDay = (entries) =>
  // exmaple input "entries": [
  //   { id: 1, openFor: 'delivery' },
  //   { id: 2, openFor: 'delivery' },
  //   { id: 3, openFor: 'delivery' },
  //   { id: 4, openFor: 'pickup' },
  //   { id: 5, openFor: 'pickup' },
  //   { id: 6, openFor: 'pickup' },
  // ];
  // expected output: [
  //   {
  //     delivery: {
  //       Monday: { 258816: {} },
  //       Tuesday: { 258936: {} },
  //       ...
  //     },
  //     pickup: {
  //       Monday: { 258816: {} },
  //       Tuesday: { 258936: {} },
  //       ...
  //     },
  //   },
  // ];
  entries.reduce(
    (acc, hour) => ({
      ...acc,
      [hour.openFor]: {
        ...acc[hour.openFor],
        [hour.dayOfWeek]: {
          ...(acc[hour.openFor] && acc[hour.openFor][hour.dayOfWeek]),
          [hour.id]: {
            ...hour,
          },
        },
      },
    }),
    {},
  );

export const timeAsRange = ({ from, to }) =>
  `${moment(from, 'HH:mm').format('h:mma')} - ${moment(to, 'hh:mm').format(
    'h:mma',
  )}`;

/**
 * Accepts a time string (09:00) and converts it to an int (900)
 * @param {string} time - the time string to parse
 * @return {number} - the time as an int
 */
export const timeAsInts = (time) => parseInt(time.split(':').join(''), 10);

/**
 * Checks if two hour entries overlap
 * @param {Object} entryA - the first entry to compare
 * @param {Object} entryB - the second entry to compare
 * @return {boolean} - whether or not the entries overlap
 */
export const entriesOverlap = (
  { from: fromA, to: toA },
  { from: fromB, to: toB },
) => {
  const entryAFrom = timeAsInts(fromA);
  let entryATo = timeAsInts(toA);
  // If the entry's 'to' value is before its 'from' value
  // it must be extending into the next day, so add a day
  // to keep comparisons simple
  if (entryAFrom >= entryATo) {
    entryATo += 2400;
  }

  const entryBFrom = timeAsInts(fromB);
  let entryBTo = timeAsInts(toB);
  if (entryBFrom >= entryBTo) {
    entryBTo += 2400;
  }

  if (entryAFrom >= entryBFrom && entryAFrom <= entryBTo) {
    return true;
  }

  if (entryATo >= entryBFrom && entryATo <= entryBTo) {
    return true;
  }

  if (entryAFrom <= entryBFrom && entryATo >= entryBFrom) {
    return true;
  }

  return false;
};

/**
 * Returns information about the state of the provided Day's entries
 * @param {Object} entries - an object of entries keyed by ID
 * @return {Object} - an object containing flags
 */
export const getDayMetadata = (entries) => {
  let hasChanges = false;
  let hasInvalidEntry = false;
  let hasMaxEntries = false;
  let isOpenToday = false;

  const entriesAsArray = Object.values(entries);

  if (entriesAsArray.filter((entry) => !entry.isDeleted).length >= 2) {
    hasMaxEntries = true;
  }

  if (
    entriesAsArray.filter(
      (entry) => entry.from !== CLOSED_VALUE && !entry.isDeleted,
    ).length
  ) {
    isOpenToday = true;
  }

  entriesAsArray.forEach((entryA) => {
    const isInvalid = Object.values(entries).find(
      (entryB) =>
        !entryA.isDeleted &&
        !entryB.isDeleted &&
        entryA.openFor === entryB.openFor &&
        entryA.dayOfWeek === entryB.dayOfWeek &&
        entryA.id !== entryB.id &&
        entriesOverlap(entryA, entryB),
    );

    if (isInvalid) {
      hasInvalidEntry = true;
    }

    if (entryA.isModified) {
      hasChanges = true;
    }
  });

  return {
    hasChanges,
    hasInvalidEntry,
    hasMaxEntries,
    isOpenToday,
  };
};

export const desktopDayLabels = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

export const nextDayLabels = {
  Monday: 'Tue',
  Tuesday: 'Wed',
  Wednesday: 'Thu',
  Thursday: 'Fri',
  Friday: 'Sat',
  Saturday: 'Sun',
  Sunday: 'Mon',
};
