import { CampaignManagerResults } from 'types/campaign-manager';
import { toDollarString, toMoneyString } from 'utilities/currency';

import styles from './styles.module.scss';

type Props = {
  lifetimeResults?: CampaignManagerResults;
  title: string;
};

const Header = ({ lifetimeResults, title }: Props) => {
  return (
    <div className={styles.header}>
      <div className={styles.title}>{title}</div>
      {lifetimeResults ? (
        <p className={styles.subtitle}>
          {`You've generated `}
          <span className={styles.boldText}>
            {toMoneyString(toDollarString(lifetimeResults.sales))}
          </span>
          {` in total sales from `}
          <span className={styles.boldText}>
            {lifetimeResults.orders.toLocaleString('en-US')}
          </span>
          {` orders, with a total of `}
          <span className={styles.boldText}>
            {lifetimeResults.impressions.toLocaleString('en-US')}
          </span>
          {` ad views.`}
        </p>
      ) : null}
    </div>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default Header;
