import { SnakeCasedProperties, SnakeCasedPropertiesDeep } from 'type-fest';

import { camelCaseKeys } from 'utilities/objects';

export type CampaignManagerResults = {
  impressions: number;
  newCustomers: number;
  orders: number;
  sales: number;
};

export type CampaignManagerLast90DaysResults = {
  lapsed: boolean;
  maxDsPt: string;
} & CampaignManagerResults;

export type NetworkCampaignManagerResults =
  SnakeCasedProperties<CampaignManagerResults>;

export type CampaignManagerPlatformsInfo = {
  active: string[];
  leading?: string;
};

export type CampaignManagerSummary = {
  last90Days: CampaignManagerLast90DaysResults;
  lifetime: CampaignManagerResults;
  platforms: CampaignManagerPlatformsInfo;
};

export type NetworkCampaignManagerSummary = SnakeCasedPropertiesDeep<
  Omit<CampaignManagerSummary, 'last90Days'> & {
    last_90_days: CampaignManagerLast90DaysResults;
  }
>;

export type CampaignManagerMonthlyResults = {
  date: string;
  impressions: number | null;
  newCustomers: number | null;
  orders: number | null;
  sales: number | null;
};

export type NetworkCampaignManagerMonthlyResults =
  SnakeCasedProperties<CampaignManagerMonthlyResults>;

export type NetworkCampaignManagerHistory =
  NetworkCampaignManagerMonthlyResults[];

export type CampaignManagerHistory = CampaignManagerMonthlyResults[];

export const networkToDomainCampaignManagerHistory = (
  networkHistory: NetworkCampaignManagerHistory,
): CampaignManagerHistory => {
  return networkHistory.map((networkMonth) => camelCaseKeys(networkMonth));
};

export enum ActiveCampaignPhase {
  Learning = 'Learning',
  RampUp = 'Ramp up',
  Optimization = 'Optimization',
  Execution = 'Execution',
}

export type LowerDeliveryFeeEmailInfo = {
  body: string;
  subject: string;
};

export enum CampaignManagerAnalyticsEventsName {
  ClickedCampaignManagerInsights = 'clicked_campaign_manager_insights',
  OpenedOptimizeMyCampaignModal = 'opened_optimize_my_campaign_modal',
  ViewedCampaignManagerState = 'viewed_campaign_manager_state',
}

export enum CampaignManagerState {
  CanceledOrLapsed = 'lapsed',
  ExecutionPhase = 'execution days 90+',
  LearningPhase = 'learning phase days 1 to 7',
  NoCampaignHistory = 'no campaign history',
  OptimizationPhase = 'optimization days 31 to 89',
  RampUpPhase = 'ramp up days 8 to 30',
}

export enum CampaignManagerPageData {
  CanceledOrLapsed = 'campaign manager canceled/lapsed',
  NoCampaignHistory = 'campaign manager no campaign history',
}

export enum CampaignManagerLinkLabel {
  GetStarted = 'get started',
  SignUpNow = 'sign up now',
}

export type ViewedCampaignManagerStateData = {
  shopId: string;
  state: CampaignManagerState;
};

export type ClickedCampaignManagerInsightsData = {
  shopId: string;
  state: CampaignManagerState;
};

export type OpenedOptimizeMyCampaignModalData = {
  deliveryFeeChecked: boolean;
  discountsChecked: boolean;
  menuPhotosChecked: boolean;
  shopId: string;
};
