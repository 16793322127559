import { useWatch } from 'react-hook-form';
import PropTypes from 'prop-types';

import Modal from 'components/shared/slice-modal';
import {
  AnalyticsField,
  CustomizationField,
  getCustomizationFieldName,
  ProductField,
} from 'utilities/menu';

import styles from './styles.module.scss';

const CustomizationDeleteModal = ({
  control,
  index,
  isOpen,
  removeField,
  setIsOpen,
  setValue,
}) => {
  const productName = useWatch({ control, name: ProductField.Name });
  const customizationName = useWatch({
    control,
    name: getCustomizationFieldName(CustomizationField.Name, index),
  });

  const handleClickYes = () => {
    setValue(AnalyticsField.CustomizationRemoved, true);
    removeField();
    setIsOpen(false);
  };

  const handleClose = () => setIsOpen(false);

  return (
    <Modal
      header="Are you sure?"
      isOpen={isOpen}
      onClickNo={handleClose}
      onClickYes={handleClickYes}
      onRequestClose={handleClose}
    >
      <div className={styles.body}>
        You are about to delete {customizationName} from {productName}.
      </div>
    </Modal>
  );
};

CustomizationDeleteModal.propTypes = {
  control: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  isOpen: PropTypes.bool.isRequired,
  removeField: PropTypes.func.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default CustomizationDeleteModal;
