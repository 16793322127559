import { CalendarDate } from '@internationalized/date';

export enum RegisterDiscountType {
  Fixed = 'FIXED',
  Flexible = 'FLEXIBLE',
  Percent = 'PERCENT',
}

export type RegisterDiscount = {
  amount: string | null;
  description: string;
  endAt: string | null;
  id: string;
  isCombinable: boolean;
  name: string;
  percent: string | null;
  shopId: string; // Actually the shop UUID.
  startAt: string;
  type: RegisterDiscountType;
};

export type RegisterDiscountRequestBody = Omit<
  RegisterDiscount,
  'id' | 'shopId'
>;

export type RegisterDiscountFormValues = (
  | {
      end: null;
      isTemporary: false;
    }
  | {
      end: CalendarDate;
      isTemporary: true;
    }
) & {
  start: CalendarDate;
  amount: number;
  description: string;
  id: string;
  isCombinable: string;
  name: string;
  type: RegisterDiscountType;
};

export enum RegisterDiscountAnalyticsEventName {
  CreatedRegisterDiscount = 'slice_link_register_discount_created',
  EditedRegisterDiscount = 'slice_link_register_discount_edited',
  DeactivatedRegisterDiscount = 'slice_link_register_discount_deactivated',
}

export type CreatedRegisterDiscountAnalyticsData = {
  name: string;
  shopId: string;
  success: boolean;
  type: RegisterDiscountType;
};

export type EditedRegisterDiscountAnalyticsData =
  CreatedRegisterDiscountAnalyticsData;

export type DeactivatedRegisterDiscountAnalyticsData = {
  shopId: string;
  id: string;
  success: boolean;
};

export type ClickedCreateRegisterDiscount = {
  countRegisterDiscounts: number;
  isRegister: boolean;
  location: string;
  page: string;
  shopId: number;
};
