import { EventProperties, Options } from '@segment/analytics-next';

import {
  AnalyticsAdapter,
  AnalyticsParameters,
  AnalyticsReturnType,
} from 'types/shared/analytics';
import * as log from 'utilities/log';

/*
 * The order of arguments for all of the Segment methods is very relaxed. Most
 * arguments are optional and can appear in any position depending on which
 * arguments are being provided. Each of the arguments is typed to accept any of
 * the proceeding arguments and you can also do things like provide just one
 * argument, an object, instead using positional arguments. The Segment
 * implementation has parser functions to deal with the argument soup, which are
 * not exported. Rather than copy those functions here, the methods below are
 * meant to be just "good enough" for development logging purposes. In edge
 * cases we may log the incorrect value.
 */
class ConsoleAnalyticsAdapter implements AnalyticsAdapter {
  async identify(
    ...args: AnalyticsParameters<'identify'>
  ): AnalyticsReturnType<'identify'> {
    const [id, traits] = args;

    log.info(
      '🍕 If analytics was enabled, I would have sent an identify event!',
    );
    log.info('userId:', id);

    if (traits) {
      log.info('traits:', traits);
    }
  }

  async page(
    ...args: AnalyticsParameters<'page'>
  ): AnalyticsReturnType<'page'> {
    const strings = args.filter((it) => typeof it === 'string') as string[];
    const objects = args.filter((it) => typeof it === 'object') as (
      | EventProperties
      | Options
    )[];

    let name: string | undefined;
    let properties: EventProperties | undefined;

    if (strings.length) {
      name = strings[strings.length - 1];
      properties = objects[0] as EventProperties;
    }

    log.info('🍕 If analytics was enabled, I would have sent a page event!');
    log.info('name:', name);

    if (properties) {
      log.info('properties:', properties);
    }
  }

  async track(
    ...args: AnalyticsParameters<'track'>
  ): AnalyticsReturnType<'track'> {
    const name = typeof args[0] === 'string' ? args[0] : args[0].name;
    const properties =
      typeof args[0] === 'string' ? args[1] : args[0].properties;

    log.info('🍕 If analytics was enabled, I would have sent a track event!');
    log.info('event:', name);

    if (properties) {
      log.info('properties:', properties);
    }
  }
}

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default ConsoleAnalyticsAdapter;
