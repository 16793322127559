/* eslint-disable @typescript-eslint/no-explicit-any */
import { Defs } from '@nivo/core';
import { area, curveMonotoneX } from 'd3-shape';

/*
  There are issues with types, in particular for `xScale` and `yScale`.
  The easiest way around this issue is to make use of `any`.
  This was done as an alternative to keeping this file in JS rather than TS.

  For more info:
  https://github.com/plouc/nivo/issues/1947
  https://github.com/plouc/nivo/issues/2173
*/
type Props = {
  innerHeight: any;
  series: any;
  xScale: any;
  yScale: any;
};

export const ShopAvailabilityAreaLayer = ({
  series,
  xScale,
  yScale,
  innerHeight,
}: Props) => {
  const areaGenerator = area()
    .x((d: any) => xScale(d.data.x))
    .y0(() => innerHeight)
    .y1((d: any) => yScale(d.data.y))
    .curve(curveMonotoneX); // https://d3js.org/d3-shape/area#area_curve

  return (
    <>
      <Defs
        defs={[
          {
            id: 'linearGradientFill', // https://nivo.rocks/guides/gradients/
            type: 'linearGradient',
            colors: [
              {
                offset: 0,
                color: 'var(--op-color-hours-analytics-chart-top-gradient)',
              },
              {
                offset: 100,
                color: 'var(--op-color-hours-analytics-chart-bottom-gradient)',
              },
            ],
          },
        ]}
      />
      <path
        // eslint-disable-next-line react/prop-types
        d={areaGenerator(series[0].data) as string}
        fill="url(#linearGradientFill)"
      />
    </>
  );
};
