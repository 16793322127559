import { PropsWithChildren, ReactElement, useEffect } from 'react';
import cx from 'classnames';

import Suspended from 'components/shared/suspended';
import { useCurrentShopId } from 'hooks/shops';
import useAnalytics from 'hooks/use-analytics';

import PageFooter from './footer';
import PageHeader from './header';
import PageTitle from './title';

import styles from './styles.module.scss';

type Props = {
  customPageHeader?: ReactElement;
  isLoading?: boolean;
  isFooterShown?: boolean;
  isHeaderShown?: boolean;
  isNavShown?: boolean;
  name: string;
  title?: string;
  wrapperClassName?: string;
  contentClassName?: string;
  mainClassName?: string;
  additionalTrackingProperties?: Record<string, boolean | string>;
};

const Page = ({
  children,
  customPageHeader,
  mainClassName,
  isLoading = false,
  isFooterShown = true,
  isHeaderShown = true,
  isNavShown = true,
  name,
  title = '',
  wrapperClassName = '',
  contentClassName = '',
  additionalTrackingProperties,
}: PropsWithChildren<Props>) => {
  const { trackPageLoad } = useAnalytics();
  const shopId = useCurrentShopId();

  useEffect(() => {
    if (!isLoading) {
      trackPageLoad(name, {
        shopId: shopId,
        ...additionalTrackingProperties,
      });
    }
  }, [trackPageLoad, name, shopId, additionalTrackingProperties, isLoading]);

  return (
    <Suspended isLoading={isLoading} variant="viewPort">
      <div className={cx(styles.wrapper, wrapperClassName)}>
        <div
          className={cx(
            isNavShown ? styles.mainWithNav : styles.main,
            mainClassName,
          )}
        >
          {isHeaderShown &&
            (customPageHeader || (
              <PageHeader>
                <PageTitle>{title}</PageTitle>
              </PageHeader>
            ))}
          <div
            className={cx(
              isNavShown ? styles.content : styles.contentWithoutNav,
              contentClassName,
            )}
          >
            {children}
          </div>
          {isFooterShown && <PageFooter />}
        </div>
      </div>
    </Suspended>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default Page;
