import { useState } from 'react';

import { RegisterUpsellModal } from 'components/register/register-splash/register-upsell-modal';
import { useCustomersFiltersStorage } from 'hooks/customers/use-customers-filters-storage';
import { useRegisterUpsellModal } from 'hooks/salesforce/use-register-upsell-modal';
import { BaseCustomer } from 'types/customers';
import { Shop } from 'types/shops';
import { User } from 'types/user';
import {
  getCustomerTypeOptions,
  getDateRangeOptions,
} from 'utilities/customers';
import { getShopHasFullRegister } from 'utilities/shops';

import CustomerAnalyticsTiles from './analytics-tiles';
import CustomerDetailsModal from './customer-details-modal';
import CustomerTypeDropdown from './customer-type-dropdown';
import CustomerTypeTile from './customer-type-tile';
import DateRangeDropdown from './date-range-dropdown';
import GuestCustomersTile from './guest-customers-tile';
import CustomerAnalyticsHeader from './header';
import ServiceTypeTile from './service-type-tile';
import Top10CustomersTile from './top-10-customers';

import styles from './styles.module.scss';

type Props = {
  shop: Shop;
  user: User;
};

const CustomerAnalytics = ({ shop, user }: Props) => {
  const shopId = String(shop.shopId);
  const timezone = shop.timezoneIdentifier;

  const {
    storedCustomerType,
    setStoredCustomerType,
    storedDateRange,
    setStoredDateRange,
  } = useCustomersFiltersStorage();

  const { options: customerTypeOptions, defaultCustomerTypeOption } =
    getCustomerTypeOptions(getShopHasFullRegister(shop));
  const { options: dateRangeOptions, defaultDateRangeOption } =
    getDateRangeOptions();

  // Check if filter state has been provided upon navigation.
  // If so, update stored values based on the navigation state.
  // This avoids a race condition in which navigation occurs
  // before local storage is updated in the useCustomersFiltersStorage hook.
  const state = window?.history?.state?.state;

  let storedCustomerTypeOption;
  let storedDateRangeOption;

  if (state?.orderType) {
    storedCustomerTypeOption = customerTypeOptions.find(
      (option) => option.value === state.orderType,
    );

    setStoredCustomerType(storedCustomerTypeOption?.value);
  } else if (storedCustomerType) {
    storedCustomerTypeOption = customerTypeOptions.find(
      (option) => option.value === storedCustomerType,
    );
  }

  if (state?.resetDateRange) {
    storedDateRangeOption = defaultDateRangeOption;
    setStoredDateRange(storedDateRangeOption.value);
  } else if (storedDateRange) {
    storedDateRangeOption = dateRangeOptions.find(
      (option) => option.value === storedDateRange,
    );
  }

  if (state && Object.keys(state).length > 0) {
    // Update history state to reflect that state has been processed.
    window.history.replaceState(
      {
        ...window.history.state,
        state: {},
      },
      '',
    );
  }

  const [customerType, setCustomerType] = useState(
    storedCustomerTypeOption?.value || defaultCustomerTypeOption.value,
  );
  const [dateRange, setDateRange] = useState(
    storedDateRangeOption?.value || defaultDateRangeOption.value,
  );

  const [selectedCustomer, setSelectedCustomer] = useState<BaseCustomer | null>(
    null,
  );

  const {
    handleModalClose,
    handleOpenModalClick,
    hasValidCase,
    openedModal,
    setCaseExpiration,
  } = useRegisterUpsellModal(shop.shopId);

  const handleCloseCustomerDetailsModalClick = () => {
    setSelectedCustomer(null);
  };

  return (
    <>
      <div className={styles.headerAndFilterContainer}>
        <CustomerAnalyticsHeader />
        <div className={styles.filterContainer}>
          <div
            data-chameleon-target="Customer Analytics Date Range Dropdown"
            className={styles.dateRangeFilter}
          >
            <DateRangeDropdown
              dateRange={dateRange}
              dateRangeOptions={dateRangeOptions}
              page="customer analytics"
              setDateRange={setDateRange}
              setStoredDateRange={setStoredDateRange}
              shopId={shopId}
            />
          </div>
          <div
            data-chameleon-target="Customer Analytics Customer Type Dropdown"
            className={styles.filter}
          >
            <CustomerTypeDropdown
              className={styles.customerTypeDropdown}
              customerType={customerType}
              customerTypeOptions={customerTypeOptions}
              hasValidCase={hasValidCase}
              onRequestDemoButtonClick={handleOpenModalClick}
              page="customer analytics"
              setCustomerType={setCustomerType}
              setStoredCustomerType={setStoredCustomerType}
              shop={shop}
            />
          </div>
        </div>
      </div>
      <CustomerAnalyticsTiles
        customerType={customerType}
        dateRange={dateRange}
        shopId={shopId}
        timezone={timezone}
      />
      <div className={styles.belowAnalyticsArea}>
        <Top10CustomersTile
          customerType={customerType}
          dateRange={dateRange}
          shopId={shopId}
          timezone={timezone}
          setSelectedCustomer={setSelectedCustomer}
        />
        <div className={styles.otherTiles}>
          <CustomerTypeTile
            customerType={customerType}
            dateRange={dateRange}
            shopId={shopId}
            timezone={timezone}
          />
          <ServiceTypeTile
            customerType={customerType}
            dateRange={dateRange}
            shopId={shopId}
            timezone={timezone}
          />
          <GuestCustomersTile
            customerType={customerType}
            dateRange={dateRange}
            shopId={shopId}
            timezone={timezone}
          />
        </div>
      </div>
      {selectedCustomer && (
        <CustomerDetailsModal
          shopId={Number(shopId)}
          selectedCustomer={selectedCustomer}
          handleCloseCustomerDetailsModalClick={
            handleCloseCustomerDetailsModalClick
          }
        />
      )}
      <RegisterUpsellModal
        isOpen={openedModal}
        onRequestClose={handleModalClose}
        page={'customer analytics'}
        setCaseExpiration={setCaseExpiration}
        shopId={shop.shopId}
        user={user}
      />
    </>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default CustomerAnalytics;
