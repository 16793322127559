import * as React from "react";
import { forwardRef } from "react";
const SvgHeadsetIcon = ({
  title,
  titleId,
  ...props
}, ref) => {
  return /* @__PURE__ */ React.createElement("svg", { width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", "aria-hidden": "true", ref, "aria-labelledby": titleId, ...props }, title === void 0 ? /* @__PURE__ */ React.createElement("title", { id: titleId }, "Headset Icon") : title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { d: "M3 19.2V12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12V19.2C21 20.1941 20.1941 21 19.2 21H12V19.2L15.6 18.9V15.6C15.6 14.6059 16.4059 13.8 17.4 13.8H19.2V12.9C19.2 8.92355 15.9764 5.7 12 5.7C8.02355 5.7 4.8 8.92355 4.8 12.9V13.8H6.6C7.59411 13.8 8.4 14.6059 8.4 15.6V19.2C8.4 20.1941 7.59411 21 6.6 21H4.8C3.80589 21 3 20.1941 3 19.2Z", fill: "currentColor" }));
};
const ForwardRef = forwardRef(SvgHeadsetIcon);
export default ForwardRef;
