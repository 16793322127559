import cx from 'classnames';

import { Button, ButtonGroup } from 'crust';

import styles from './styles.module.scss';

type Props = {
  cancelText?: string;
  className?: string;
  isSubmitDisabled: boolean;
  isSubmitting: boolean;
  onCancel: () => void;
  showCancel?: boolean;
  submitButtonChameleonTarget?: string;
  submitButtonDataTestId?: string;
  submitText?: string;
};

const SubmitFooter = ({
  cancelText = 'Cancel',
  className = '',
  isSubmitDisabled,
  isSubmitting,
  onCancel,
  showCancel = true,
  submitButtonChameleonTarget,
  submitButtonDataTestId,
  submitText = 'Save Changes',
}: Props) => (
  <div className={cx(styles.wrapper, className)}>
    <div className={styles.description}>
      Don&#39;t forget to save your selections.
    </div>
    <ButtonGroup className={styles.buttons}>
      {showCancel && (
        <Button onPress={onCancel} variant="secondary">
          {cancelText}
        </Button>
      )}
      <Button
        data-chameleon-target={submitButtonChameleonTarget}
        data-testid={submitButtonDataTestId}
        isDisabled={isSubmitDisabled}
        type="submit"
        variant="primary"
      >
        {isSubmitting ? 'Saving...' : submitText}
      </Button>
    </ButtonGroup>
  </div>
);

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default SubmitFooter;
