import { useState } from 'react';
import { Control } from 'react-hook-form';

import CollapsibleTile from 'components/shared/collapsible-tile';
import { RHFRadio, RHFRadioGroup } from 'components/shared/rhf-radio';
import { PromoCodeFormValues, PromoCodeLimit } from 'types/discounts';
import { PromoCodeLimitLabels } from 'utilities/discounts';

import { UsageLimitSummary } from './summary';

import styles from './styles.module.scss';

type Props = {
  className?: string;
  control: Control<PromoCodeFormValues>;
};

export const UsageLimitFields = ({ className, control }: Props) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <CollapsibleTile
      bodyChameleonTarget="Promo Code Limit Usage"
      className={className}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      summary={<UsageLimitSummary control={control} />}
      title="Limit Usage"
    >
      <RHFRadioGroup
        name="limit"
        control={control}
        className={styles.types}
        label="Limit how many times this promo can be redeemed"
        orientation="horizontal"
        variant="card"
      >
        {Object.values(PromoCodeLimit).map((limit) => (
          <RHFRadio
            key={limit}
            label={PromoCodeLimitLabels[limit]}
            value={limit}
          />
        ))}
      </RHFRadioGroup>
    </CollapsibleTile>
  );
};
