import * as React from "react";
import { forwardRef } from "react";
const SvgNewCustomerIcon = ({
  title,
  titleId,
  ...props
}, ref) => {
  return /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", xmlSpace: "preserve", viewBox: "0 0 32 32", ref, "aria-labelledby": titleId, ...props }, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("circle", { cx: 16, cy: 16, r: 16, fill: "#f5f3f0" }), /* @__PURE__ */ React.createElement("path", { fill: "none", stroke: "#9170b8", strokeWidth: 2, d: "M24.5 9c0 .83-.67 1.5-1.5 1.5s-1.5-.67-1.5-1.5.67-1.5 1.5-1.5 1.5.67 1.5 1.5zm-17 7.53v-.06c2.51-.15 4.42-.89 5.75-2.22s2.07-3.24 2.22-5.75h.06c.15 2.51.89 4.42 2.22 5.75s3.24 2.07 5.75 2.22v.06c-2.51.15-4.42.89-5.75 2.22s-2.07 3.24-2.22 5.75h-.06c-.15-2.51-.89-4.42-2.22-5.75s-3.24-2.07-5.75-2.22zm2 6.47c0 .28-.22.5-.5.5s-.5-.22-.5-.5.22-.5.5-.5.5.22.5.5z" }));
};
const ForwardRef = forwardRef(SvgNewCustomerIcon);
export default ForwardRef;
