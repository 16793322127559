import { PropsWithChildren, useMemo } from 'react';
import classNames from 'classnames';

import {
  FieldDescription,
  type FieldDescriptionProps,
} from '../field-description/field-description';
import { FieldError, type FieldErrorProps } from '../field-error/field-error';
import { FieldLabel, type FieldLabelProps } from '../field-label/field-label';
import { getCrustClassName } from '../utilities/get-crust-class-name';
import { mergeAriaClassName } from '../utilities/merge-aria-class-name';

import './field.css';

export type FieldProps = {
  description?: FieldDescriptionProps['children'];
  error?: FieldErrorProps['children'];
  label?: FieldLabelProps['children'];
};

type UseFieldProps<T> = FieldProps & {
  block: string;
  className?: string | ((values: T) => string);
};

/**
 * An internal hook for managing the common structure of form fields.
 */
export const useField = <T,>({
  block,
  className,
  description,
  error,
  label,
}: UseFieldProps<T>) => {
  const getFieldClassName = useMemo(
    () => getCrustClassName.bind(null, block),
    [block],
  );

  // React Aria Components allows for passing a transform function to
  // FieldError to handle browser validation messages.
  const isInvalid =
    error == null || typeof error === 'function' ? undefined : true;

  return {
    getFieldClassName,
    fieldProps: {
      isInvalid,
      className: mergeAriaClassName(
        classNames(getCrustClassName('field'), getFieldClassName()),
        className,
      ),
    },
    fieldChildrenProps: {
      description,
      error,
      getFieldClassName,
      label,
    },
  };
};

export const FieldChildren = ({
  children,
  description,
  error,
  getFieldClassName,
  label,
}: PropsWithChildren<ReturnType<typeof useField>['fieldChildrenProps']>) => (
  <>
    {label && (
      <FieldLabel className={getFieldClassName('label')}>{label}</FieldLabel>
    )}
    {description && (
      <FieldDescription className={getFieldClassName('description')}>
        {description}
      </FieldDescription>
    )}
    {children}
    <FieldError className={getFieldClassName('error')}>{error}</FieldError>
  </>
);
