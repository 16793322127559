import * as React from "react";
import { forwardRef } from "react";
const SvgCameraIcon = ({
  title,
  titleId,
  ...props
}, ref) => {
  return /* @__PURE__ */ React.createElement("svg", { width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", "aria-hidden": "true", ref, "aria-labelledby": titleId, ...props }, title === void 0 ? /* @__PURE__ */ React.createElement("title", { id: titleId }, "Camera Icon") : title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M3 8.70588C3 7.66629 3.84276 6.82353 4.88235 6.82353H6.76471L8.08825 4.83821C8.43736 4.31454 9.02509 4 9.65446 4H15.1691C15.7984 4 16.3862 4.31454 16.7353 4.83821L18.0588 6.82353H19.9412C20.9808 6.82353 21.8235 7.66629 21.8235 8.70588V18.1176C21.8235 19.1572 20.9808 20 19.9412 20H4.88235C3.84276 20 3 19.1572 3 18.1176V8.70588ZM16.1765 13.4118C16.1765 15.491 14.491 17.1765 12.4118 17.1765C10.3326 17.1765 8.64706 15.491 8.64706 13.4118C8.64706 11.3326 10.3326 9.64706 12.4118 9.64706C14.491 9.64706 16.1765 11.3326 16.1765 13.4118ZM18.5294 11.5294C19.3091 11.5294 19.9412 10.8973 19.9412 10.1176C19.9412 9.33795 19.3091 8.70588 18.5294 8.70588C17.7497 8.70588 17.1176 9.33795 17.1176 10.1176C17.1176 10.8973 17.7497 11.5294 18.5294 11.5294Z", fill: "currentColor" }));
};
const ForwardRef = forwardRef(SvgCameraIcon);
export default ForwardRef;
