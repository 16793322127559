import { useMemo } from 'react';

import {
  AnalyticsDateRange,
  CustomerMetric,
  CustomerMetricData,
  FormattedCustomerTotals,
  FormattedMostValuableCustomer,
  MostValuableCustomer,
} from 'types/analytics';
import { nowDateTime } from 'utilities/date-time';

const transformCustomerCounts = (countData: CustomerMetric) => ({
  ...countData,
  infrequent: {
    count: countData.total.count - countData.loyal.count - countData.new.count,
    orderValue:
      countData.total.orderValue -
      countData.loyal.orderValue -
      countData.new.orderValue,
  },
});

export const useLastFullWeek = (timezone: string): AnalyticsDateRange => {
  const { start, end } = useMemo(
    () => getWeeklyDateRangeEndingLastWeek(timezone),
    [timezone],
  );

  return { start, end };
};

export const formatCustomerTotalsByWeek = (
  data: CustomerMetricData,
): FormattedCustomerTotals => {
  if (!data?.customerMetrics) {
    return { totals: {}, dates: {} };
  }

  return Object.entries(data.customerMetrics).reduce(
    (acc: FormattedCustomerTotals, [date, values]) => {
      // https://github.com/slicelife/owners-web/pull/3274/files#r1097445231.
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const transformedValues = transformCustomerCounts(values);

      acc.dates[date] = transformedValues;

      Object.entries(transformedValues).forEach(([key, value]) => {
        if (key !== 'total') {
          // TODO: Fix typings
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          acc.totals[key].count += value.count;
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          acc.totals[key].orderValue += value.orderValue;
        }
      });

      return acc;
    },
    {
      dates: {},
      totals: {
        new: { count: 0, orderValue: 0 },
        loyal: { count: 0, orderValue: 0 },
        infrequent: { count: 0, orderValue: 0 },
      },
    },
  );
};

export const formatMostValuableCustomers = (
  data: MostValuableCustomer[],
): FormattedMostValuableCustomer[] =>
  data
    ? data.reduce(
        (
          acc: FormattedMostValuableCustomer[],
          { firstName, lastName, count, total, customerUuid },
        ) => {
          // Filtering out the records without a firstName as some of the data on QA
          // had empty names
          if (firstName) {
            acc.push({
              name: `${firstName} ${lastName}`,
              orderValue: total,
              id: customerUuid,
              count,
            });
          }
          return acc;
        },
        [],
      )
    : [];

export const getWeeklyDateRangeEndingLastWeek = (
  shopTimezone: string,
  additionalWeeksToGoBack = 0,
): AnalyticsDateRange => {
  const endMoment = nowDateTime(shopTimezone)
    .endOf('isoWeek')
    .subtract(1, 'week');

  const startMoment = endMoment
    .clone()
    .startOf('isoWeek')
    .subtract(additionalWeeksToGoBack, 'week');

  return {
    start: startMoment.utc().format(),
    end: endMoment.utc().format(),
  };
};

const storageKeyPrefix = 'analytics-page';

export const AnalyticsStorageKeys = {
  OrderType: `${storageKeyPrefix}-order-type`,
} as const;
