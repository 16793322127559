import * as React from "react";
import { forwardRef } from "react";
const SvgDownloadSquare = ({
  title,
  titleId,
  ...props
}, ref) => {
  return /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", fill: "none", viewBox: "0 0 39 40", ref, "aria-labelledby": titleId, ...props }, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("rect", { width: 39, height: 40, fill: "currentColor", rx: 4 }), /* @__PURE__ */ React.createElement("path", { stroke: "#fff", strokeWidth: 2, d: "M10 29h18M16 11v11h-1l4 3 4-3h-1V11h-6Z" }));
};
const ForwardRef = forwardRef(SvgDownloadSquare);
export default ForwardRef;
