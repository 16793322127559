import { useController, useWatch } from 'react-hook-form';
import PropTypes from 'prop-types';

import Label from 'components/shared/label';
import PhotoUploader from 'components/shared/photo-uploader';
import { usePhotoUploadMutation } from 'hooks/shared';
import useAnalytics from 'hooks/use-analytics';
import { ProductField } from 'utilities/menu';

import styles from './styles.module.scss';

const ProductPhoto = ({ control, productId, shopId }) => {
  const productName = useWatch({ control, name: ProductField.Name });

  const { field } = useController({
    control,
    name: ProductField.Image,
  });
  const handleUrlChange = field.onChange;
  const url = field.value;

  const { trackProductImageRemoved } = useAnalytics();

  const handleProductImageRemoval = () => {
    handleUrlChange('');
    trackProductImageRemoved({ productId, shopId });
  };

  const {
    error,
    isLoading,
    mutate: onUploadPhoto,
    variables,
  } = usePhotoUploadMutation({
    shopId,
    productId,
    page: 'menu',
    pageDetail: 'menu photo upload',
    productName,
  });

  const onChangeImage = (file) => {
    onUploadPhoto(file, {
      onSuccess: (response) => {
        const url = response.sources.imgix;
        handleUrlChange(url);
      },
    });
  };

  return (
    <div className={styles.photoSectionWrapper}>
      <Label htmlFor="product-image-input">Photo</Label>
      <PhotoUploader
        dataChameleonTarget="Menu Product Image"
        error={error}
        variables={variables}
        isUploadInProgress={isLoading}
        onChangeImage={onChangeImage}
        onRemoveImage={handleProductImageRemoval}
        productName={productName}
        url={url}
      />
    </div>
  );
};

ProductPhoto.propTypes = {
  control: PropTypes.object.isRequired,
  productId: PropTypes.string.isRequired,
  shopId: PropTypes.string.isRequired,
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default ProductPhoto;
