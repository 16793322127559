import LinkedAccountsTooltip from './tooltip';

import styles from './styles.module.scss';

const LinkedAccountsTileHeader = () => {
  return (
    <div className={styles.titleAndIconContainer}>
      <div className={styles.title}>Online Linked Accounts</div>
      <LinkedAccountsTooltip />
    </div>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default LinkedAccountsTileHeader;
