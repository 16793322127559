import { Control, useController, useWatch } from 'react-hook-form';

import { SelectMenuOption } from 'components/website-tools/form/select-menu-option';
import useAnalytics from 'hooks/use-analytics';
import { ShopTraits } from 'types/shops';
import {
  BannerPosition,
  ButtonPosition,
  SmartButtonFormValues,
  WebsiteIntegrationsAnalyticsPageName,
} from 'types/website-tools';
import {
  getPositionOptions,
  smartButtonAnalyticsPositionStrings,
} from 'utilities/website-tools';

import SelectMenu from '../../../form/select-menu';

type Props = {
  buttonClassName?: string;
  control: Control<SmartButtonFormValues>;
  setIsFormChanged: (isFormChanged: boolean) => void;
  shopId: number;
  shopTraits: ShopTraits;
};

const PositionDropdown = ({
  buttonClassName,
  control,
  setIsFormChanged,
  shopId,
  shopTraits,
}: Props) => {
  const {
    field: { onChange, value },
    fieldState: { isDirty },
  } = useController({
    control,
    name: 'position',
  });

  const currentlySelectedWidgetType = useWatch({
    control,
    name: 'format',
  });

  const { trackClickedWebsiteIntegrationsEditorDropdown } = useAnalytics();

  const postionOptions = getPositionOptions(currentlySelectedWidgetType);

  const selectedItem = postionOptions.find((option) => option.value === value);

  const handleOnBlur = () => {
    trackClickedWebsiteIntegrationsEditorDropdown({
      field: 'position',
      shopId: String(shopId),
      isActive: String(shopTraits.isSmartButtonActive),
      optionSelected: isDirty
        ? smartButtonAnalyticsPositionStrings[value]
        : null,
      page: WebsiteIntegrationsAnalyticsPageName.SmartButtons,
    });
  };

  return (
    <SelectMenu
      buttonClassName={buttonClassName}
      buttonId="position-dropdown"
      label={selectedItem?.label ?? postionOptions[0].label}
      onChange={(value) => {
        onChange(value);
        setIsFormChanged(true);
      }}
      onBlur={handleOnBlur}
    >
      {postionOptions.map((option, index) => {
        const isActive = selectedItem?.value === option.value;

        return (
          <SelectMenuOption
            isActive={isActive}
            key={index}
            label={option.label}
            value={option.value}
            isRecommended={
              option.value === ButtonPosition.BottomCenter ||
              option.value === BannerPosition.Top
            }
          />
        );
      })}
    </SelectMenu>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default PositionDropdown;
