import cx from 'classnames';

import AvgIcon from 'images/hours-analytics/avg.svg?react';

import { LegendEntry } from './entry';

import styles from './styles.module.scss';

type Props = {
  className?: string;
};

export const Legend = ({ className }: Props) => {
  return (
    <div className={cx(styles.legend, className)}>
      <LegendEntry className={styles.yourShop} label="Your Shop" />
      <LegendEntry Icon={AvgIcon} label="Average Local Shop" />
    </div>
  );
};
