import * as React from "react";
import { forwardRef } from "react";
const SvgDummyLogo = ({
  title,
  titleId,
  ...props
}, ref) => {
  return /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", viewBox: "0 0 32 32", ref, "aria-labelledby": titleId, ...props }, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("rect", { width: 31, height: 31, x: 0.5, y: 0.5, rx: 15.5 }), /* @__PURE__ */ React.createElement("path", { fill: "#A3A2A0", d: "M13.8926 10h-1.9361c-.3214 0-.6297.1264-.8571.3515l-1.23361 1.2213c-.34098.3376-.53254.7954-.53254 1.2728V13.6c0 .6627.54269 1.2 1.21215 1.2h1.2121c.6694 0 1.2121-.5373 1.2121-1.2v-1.2c0-.0763.0147-.152.0434-.2228L13.8926 10Zm.2891 3.6c0 .6627.5427 1.2 1.2122 1.2h1.2121c.6694 0 1.2121-.5373 1.2121-1.2v-1.0844L16.8017 10h-1.6036l-1.0164 2.5156V13.6Zm4.8052-1.4228L18.1072 10h1.9361c.3215 0 .6298.1264.8571.3515l1.2337 1.2213c.3409.3376.5325.7954.5325 1.2728V13.6c0 .6627-.5427 1.2-1.2121 1.2h-1.2122c-.6694 0-1.2121-.5373-1.2121-1.2v-1.2c0-.0763-.0147-.152-.0433-.2228Z" }), /* @__PURE__ */ React.createElement("path", { fill: "#A3A2A0", fillRule: "evenodd", d: "M9.93931 20.8v-4.8756c.19369.0493.39679.0756.60609.0756h1.2121c.7241 0 1.374-.3143 1.8182-.8125.4442.4982 1.0941.8125 1.8182.8125h1.2121c.724 0 1.374-.3143 1.8182-.8125.4442.4982 1.0941.8125 1.8181.8125h1.2122c.2092 0 .4123-.0263.606-.0756V20.8c0 .6627-.5427 1.2-1.2121 1.2h-5.4545v-4.2h-2.4243V22h-1.8182c-.6694 0-1.21209-.5373-1.21209-1.2Zm10.30299-3H17.212v2.4h3.0303v-2.4Z", clipRule: "evenodd" }));
};
const ForwardRef = forwardRef(SvgDummyLogo);
export default ForwardRef;
