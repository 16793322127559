import Link from 'components/shared/link';
import { useCurrentShopId } from 'hooks/shops';
import * as paths from 'routes/paths';

import styles from './styles.module.scss';

type Props = {
  isApproved: boolean;
  last4Digits: string;
  expireDate: string;
  type: string;
};

const ViewCard = ({ isApproved, last4Digits, expireDate, type }: Props) => {
  const shopId = useCurrentShopId();

  return (
    <div className={styles.card}>
      <div className={styles.cardDetails}>
        {isApproved ? 'Default' : 'Pending'}
        <div className={styles.cardNumber}>XXXX XXXX XXXX {last4Digits}</div>
        Expires: {expireDate}
        <div className={styles.bottomContainer}>
          {type}
          <Link
            className={styles.updateCardLink}
            to={paths.debitCardNew(shopId)}
          >
            Update
          </Link>
        </div>
      </div>
    </div>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default ViewCard;
