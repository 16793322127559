export const buildAnchorCategoryId = (categoryName) => {
  // This behaviour needs to match URLs generated by Direct Web
  return encodeURIComponent(
    // The output should be URL safe but this protects against unexpected inputs
    categoryName
      // Replace all characters except words and whitespace, globally and case-insensitively, with an empty string
      .replace(/[^\w\s]/gi, '')
      // Replace all whitespace strings with a single -
      .replace(/\s+/g, '-')
      // Convert to lowercase for consistency with URLs
      .toLowerCase(),
  );
};
