import { RowData } from '@tanstack/react-table';

import {
  AutomaticTableHeaderRowProps,
  BaseTableHeaderRowProps,
  OverloadedTableHeaderRowComponent,
} from 'types/shared/table';

import TableHeaderCell from '../cell';

const BaseTableHeaderRow = (props: BaseTableHeaderRowProps) => (
  <tr {...props} />
);

const TableHeaderRow: OverloadedTableHeaderRowComponent = <
  TData extends RowData,
>(
  props: BaseTableHeaderRowProps | AutomaticTableHeaderRowProps<TData>,
) => {
  // Pass a headerGroup from the useReactTable object to automatically render
  // the header and its cells.
  if ('headerGroup' in props) {
    const { headerGroup, ...base } = props;

    return (
      <BaseTableHeaderRow {...base}>
        {headerGroup.headers.map((header) => (
          <TableHeaderCell header={header} key={header.id} />
        ))}
      </BaseTableHeaderRow>
    );
  }

  return <BaseTableHeaderRow {...props} />;
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default TableHeaderRow;
