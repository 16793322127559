import { useMutation } from '@tanstack/react-query';

import { OrderStatementResponse } from 'types/order-statement';
import { Shop } from 'types/shops';
import { camelCaseKeys } from 'utilities/objects';

import useApi from './use-api';

type Props = {
  shopId: Shop['shopId'];
};

export const useStatementMutation = ({ shopId }: Props) => {
  const { authenticatedFetch } = useApi();

  return useMutation({
    mutationFn: async (statementId) => {
      const response = await authenticatedFetch.get(
        `api/management/v1/shops/${shopId}/statements/${statementId}`,
      );

      return camelCaseKeys(response.statement) as OrderStatementResponse;
    },
  });
};
