import { QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query';

import { User } from 'types/user';

import useApi from '../use-api';

type useCurrentUserQueryOptions = Omit<
  UseQueryOptions<User>,
  'queryFn' | 'queryKey'
>;

export const getCurrentUserQueryKey = (): QueryKey => ['user'];

export const useCurrentUserQuery = (options?: useCurrentUserQueryOptions) => {
  const api = useApi();

  return useQuery({
    queryKey: getCurrentUserQueryKey(),
    queryFn: () => api.getCurrentUser(),
    staleTime: 300000, // Five minutes.
    ...options,
  });
};
