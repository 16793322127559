import { QueryKey, useQuery } from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { Coupon } from 'types/discounts';
import { camelCaseKeys } from 'utilities/objects';

export const getCouponsQueryKey = (shopId: number): QueryKey => [
  shopId,
  'coupons',
];

export const useCouponsQuery = (shopId: number) => {
  const { authenticatedFetch } = useApi();

  return useQuery({
    queryKey: getCouponsQueryKey(shopId),
    queryFn: async () => {
      const response = await authenticatedFetch.get(
        `api/management/v2/shops/${shopId}/menus/coupons`,
      );

      return camelCaseKeys(response.coupons) as Coupon[];
    },
  });
};
