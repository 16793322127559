import {
  QueryKey,
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { Schedule } from 'types/shop-hours';
import { formatGenerateScheduleRequest } from 'utilities/closings';

import { getOpeningsQueryKey } from './use-openings';

type GenerateScheduleMutationParams = {
  // TODO: Need to add types for openings and closings
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  closings?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  openings?: any;
  shopId: string;
  shopTimezone: string;
};

export const getScheduleQueryKey = (shopId: string): QueryKey => [
  shopId,
  'schedule',
];

export const useScheduleQuery = (
  shopId: string,
  options?: UseQueryOptions<Schedule>,
): UseQueryResult<Schedule> => {
  const api = useApi();

  return useQuery(
    getScheduleQueryKey(shopId),
    () => api.getShopSchedule(shopId),
    {
      ...options,
    },
  );
};

export const useGenerateScheduleMutation = ({
  closings,
  openings,
  shopId,
  shopTimezone,
}: GenerateScheduleMutationParams): UseMutationResult => {
  const api = useApi();
  const queryClient = useQueryClient();

  return useMutation(
    async () => {
      let openingsForSchedule = openings;
      if (openingsForSchedule == null) {
        openingsForSchedule = await api.getShopOpenings(shopId);
      }

      let closingsForSchedule = closings;
      if (closingsForSchedule == null) {
        closingsForSchedule = await api.getClosings(shopId);
      }

      const generateRequestParams = formatGenerateScheduleRequest(
        openingsForSchedule,
        closingsForSchedule,
        shopTimezone,
      );

      const schedule = await api.generateShopSchedule(shopId, {
        ...generateRequestParams,
      });

      return {
        closings: closingsForSchedule,
        schedule,
        openings: openingsForSchedule,
      };
    },
    {
      onSuccess: ({ closings, schedule, openings }) => {
        queryClient.setQueryData([shopId, 'closings'], closings);
        queryClient.setQueryData(getScheduleQueryKey(shopId), schedule);
        queryClient.setQueryData(getOpeningsQueryKey(shopId), openings);
      },
    },
  );
};
