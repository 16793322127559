import { useEffect, useState } from 'react';
import {
  Control,
  useController,
  UseFormGetValues,
  UseFormSetValue,
  useFormState,
} from 'react-hook-form';
import { CalendarDate, today } from '@internationalized/date';

import {
  DatePicker,
  DateRangePicker,
  mergeRefs,
  Switch,
  useDateFormatter,
} from 'crust';

import CollapsibleTile from 'components/shared/collapsible-tile';
import { RegisterDiscountFormValues } from 'types/discounts';
import { Shop } from 'types/shops';

import styles from './styles.module.scss';

type Props = {
  control: Control<RegisterDiscountFormValues>;
  getValues: UseFormGetValues<RegisterDiscountFormValues>;
  setValue: UseFormSetValue<RegisterDiscountFormValues>;
  shopTimezone: Shop['timezoneIdentifier'];
};

const DateFields = ({ control, getValues, setValue, shopTimezone }: Props) => {
  const todayAtShop = today(shopTimezone);
  const [isOpen, setIsOpen] = useState(true);

  // The name used here is not really important, we just need to listen to the
  // defaultValues without subscribing to the entire form.
  const { defaultValues: { start = todayAtShop, end = null } = {} } =
    useFormState({ control, name: 'start' });
  const defaultStartValue = start as CalendarDate;
  const defaultEndValue = end ? (end as CalendarDate) : null;

  // The minimum date visible in the calendar is today, unless we are editing a
  // discount with a start date in the past.
  const minCalendarValue =
    defaultStartValue.compare(todayAtShop) < 0
      ? defaultStartValue
      : todayAtShop;

  const formatter = useDateFormatter({
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    timeZone: shopTimezone,
  });

  const {
    field: {
      onChange: onChangeStart,
      onBlur: onBlurStart,
      ref: startRef,
      value: startValue,
    },
    fieldState: { error: startError, invalid: isStartInvalid },
    formState: { submitCount },
  } = useController({
    control,
    name: 'start',
    rules: {
      validate: (value) => {
        if (value == null) {
          return getValues('isTemporary')
            ? 'Please choose start and end dates.'
            : 'Please choose a date.';
        }

        if (
          value.compare(todayAtShop) < 0 &&
          value.compare(defaultStartValue) !== 0
        ) {
          const target = formatter.format(todayAtShop.toDate(shopTimezone));
          return `Please choose a start date of ${target} or later.`;
        }

        return true;
      },
    },
  });

  const {
    field: {
      onChange: onChangeEnd,
      onBlur: onBlurEnd,
      ref: endRef,
      value: endValue,
    },
    fieldState: { error: endError, invalid: isEndInvalid },
  } = useController({
    control,
    name: 'end',
    rules: {
      validate: (value) => {
        if (getValues('isTemporary')) {
          if (value == null) {
            return 'Please choose an end date.';
          }

          const start = getValues('start');

          if (value && start && value.compare(start) < 0) {
            return 'Please choose an end date that is after the start date.';
          }

          if (
            defaultEndValue &&
            value.compare(todayAtShop) < 0 &&
            value.compare(defaultEndValue) !== 0
          ) {
            const target = formatter.format(todayAtShop.toDate(shopTimezone));
            return `Please choose an end date of ${target} or later.`;
          }
        }

        return true;
      },
    },
  });

  const {
    field: {
      onChange: onChangeIsTemporary,
      onBlur: onBlurIsTemporary,
      ref: isTemporaryRef,
      value: isTemporary,
    },
  } = useController({
    control,
    name: 'isTemporary',
  });

  useEffect(() => {
    if (isTemporary) {
      const start = getValues('start');

      if (start != null && getValues('end') == null) {
        // Set end to the next available date. The day after the start date
        // is not valid when adding an end date to a start date in the past.
        const anchor = start.compare(todayAtShop) < 0 ? todayAtShop : start;
        setValue('end', anchor.add({ days: 1 }), { shouldValidate: true });
      }
    } else {
      setValue('end', null);
    }
  }, [getValues, isTemporary, setValue, todayAtShop]);

  useEffect(() => {
    if (submitCount && (isStartInvalid || isEndInvalid)) {
      setIsOpen(true);
    }
  }, [isEndInvalid, isStartInvalid, submitCount]);

  const format = (date: CalendarDate | null) =>
    date ? formatter.format(date.toDate(shopTimezone)) : 'No date selected';

  return (
    <CollapsibleTile
      bodyChameleonTarget="Register Discount Date Fields"
      bodyClassName={styles.body}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      summary={`Start: ${format(startValue)} End: ${format(endValue)}`}
      title="Set Date(s)"
    >
      {isTemporary ? (
        <DateRangePicker
          allowsNonContiguousRanges
          error={startError?.message ?? endError?.message}
          isDateUnavailable={(date) =>
            date.compare(todayAtShop) < 0 &&
            date.compare(defaultStartValue) !== 0 &&
            (defaultEndValue ? date.compare(defaultEndValue) !== 0 : true)
          }
          isInvalid={isStartInvalid || isEndInvalid}
          label="Start and End Date"
          minValue={minCalendarValue}
          onBlur={() => {
            onBlurStart();
            onBlurEnd();
          }}
          onChange={(value) => {
            onChangeStart(value?.start ?? null);
            onChangeEnd(value?.end ?? null);
          }}
          ref={mergeRefs(startRef, endRef)}
          validationBehavior="aria"
          value={
            startValue == null || endValue == null
              ? null
              : { start: startValue, end: endValue }
          }
        />
      ) : (
        <DatePicker
          error={startError?.message}
          isDateUnavailable={(date) =>
            date.compare(todayAtShop) < 0 &&
            date.compare(defaultStartValue) !== 0
          }
          isInvalid={isStartInvalid}
          label="Start Date"
          minValue={minCalendarValue}
          onBlur={onBlurStart}
          onChange={onChangeStart}
          ref={startRef}
          validationBehavior="aria"
          value={startValue}
        />
      )}
      <Switch
        isSelected={isTemporary}
        label="Set end date"
        onBlur={onBlurIsTemporary}
        onChange={onChangeIsTemporary}
        ref={isTemporaryRef}
      />
    </CollapsibleTile>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default DateFields;
