import { ElementRef, ForwardedRef, forwardRef } from 'react';
import {
  Group as AriaGroup,
  type GroupProps as AriaGroupProps,
  Input as AriaInput,
  type InputProps as AriaInputProps,
  TextArea as AriaTextArea,
  type TextAreaProps as AriaTextAreaProps,
} from 'react-aria-components';

import { getCrustClassName } from '../utilities/get-crust-class-name';
import { mergeAriaClassName } from '../utilities/merge-aria-class-name';

import './input.css';

export type InputProps = AriaInputProps;

export const Input = forwardRef(function Input(
  { className, ...props }: InputProps,
  ref: ForwardedRef<ElementRef<typeof AriaInput>>,
) {
  return (
    <AriaInput
      className={mergeAriaClassName(getCrustClassName('input'), className)}
      ref={ref}
      {...props}
    />
  );
});

export type InputGroupProps = AriaGroupProps;

export const InputGroup = forwardRef(function InputGroup(
  { children, className, ...props }: InputGroupProps,
  ref: ForwardedRef<ElementRef<typeof AriaGroup>>,
) {
  return (
    <AriaGroup
      className={mergeAriaClassName(
        getCrustClassName('input-group'),
        className,
      )}
      ref={ref}
      {...props}
    >
      {children}
    </AriaGroup>
  );
});

export type TextAreaProps = AriaTextAreaProps;

export const TextArea = forwardRef(function TextArea(
  { className, rows = 3, ...props }: TextAreaProps,
  ref: ForwardedRef<ElementRef<typeof AriaTextArea>>,
) {
  return (
    <AriaTextArea
      className={mergeAriaClassName(getCrustClassName('text-area'), className)}
      ref={ref}
      rows={rows}
      {...props}
    />
  );
});
