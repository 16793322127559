import moment from 'moment-timezone';

const hoursForDate = (hours, date) =>
  hours?.filter(
    ({ from }) => date.format('DD-MM-YY') === from.format('DD-MM-YY'),
  );

export const scheduleForDate = (hours, date) => {
  const { pickup: pickupHours, delivery: deliveryHours } = hours;
  return {
    delivery: hoursForDate(deliveryHours, date),
    pickup: hoursForDate(pickupHours, date),
  };
};

const convertToTimezone = (hours, timezone) =>
  hours?.map(({ from, to }) => ({
    from: moment.tz(from, timezone),
    to: moment.tz(to, timezone),
  }));

export const convertScheduleToTimezone = (schedule, shopTimezone) => {
  const { pickup, delivery } = schedule;

  return {
    delivery: convertToTimezone(delivery, shopTimezone),
    pickup: convertToTimezone(pickup, shopTimezone),
  };
};
