import LoyalCustomerIcon from 'images/loyal-customer-icon.svg?react';
import NewCustomerIcon from 'images/new-customer-icon.svg?react';
import RepeatCustomerIcon from 'images/repeat-customer-icon.svg?react';
import { CustomerLoyaltyStatus } from 'types/customers';

import styles from './styles.module.scss';

const loyaltyStatusIcon = {
  [CustomerLoyaltyStatus.New]: NewCustomerIcon,
  [CustomerLoyaltyStatus.Repeat]: RepeatCustomerIcon,
  [CustomerLoyaltyStatus.Loyal]: LoyalCustomerIcon,
};

type Props = {
  customerLoyaltyStatus:
    | CustomerLoyaltyStatus.New
    | CustomerLoyaltyStatus.Repeat
    | CustomerLoyaltyStatus.Loyal;
};

const CustomerSegmentsTableRowIcon = ({ customerLoyaltyStatus }: Props) => {
  const Icon = loyaltyStatusIcon[customerLoyaltyStatus];

  return (
    <Icon title={`${customerLoyaltyStatus} icon`} className={styles.icon} />
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default CustomerSegmentsTableRowIcon;
