import * as React from "react";
import { forwardRef } from "react";
const SvgWalletIcon = ({
  title,
  titleId,
  ...props
}, ref) => {
  return /* @__PURE__ */ React.createElement("svg", { width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", "aria-hidden": "true", ref, "aria-labelledby": titleId, ...props }, title === void 0 ? /* @__PURE__ */ React.createElement("title", { id: titleId }, "Wallet Icon") : title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M6 3C4.34314 3 3 4.33897 3 5.99582L3 18.0042C3.00001 19.6611 4.34315 21 6 21H19C20.1046 21 21 20.1046 21 19V9C21 7.89543 20.1046 7 19 7H7.99999C7.44771 7 6.99999 6.55228 6.99999 6C6.99999 5.44772 7.44771 5 7.99999 5H19C19 3.89543 18.1046 3 17 3H6ZM18 15C18.5523 15 19 14.5523 19 14C19 13.4477 18.5523 13 18 13C17.4477 13 17 13.4477 17 14C17 14.5523 17.4477 15 18 15Z", fill: "currentColor" }));
};
const ForwardRef = forwardRef(SvgWalletIcon);
export default ForwardRef;
