import * as React from "react";
import { forwardRef } from "react";
const SvgProgressStatusOutline = ({
  title,
  titleId,
  ...props
}, ref) => {
  return /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 23 39", ref, "aria-labelledby": titleId, ...props }, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("g", { fill: "none", fillRule: "evenodd", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: 1.5, opacity: 0.602562314 }, /* @__PURE__ */ React.createElement("path", { d: "M11.4353393 38l10.4364556-20.231265s-3.4795628-4.7593541-10.4364556-4.7593541C4.47956274 13.0093809 1 17.768735 1 17.768735L11.4353393 38z" }), /* @__PURE__ */ React.createElement("path", { d: "M20.5252275 20.4390244c-1.1916832-1.6584932-4.3035503-4.0525328-8.8027164-4.0525328-4.37887362 0-7.44352303 2.205468-8.70265998 3.9154963M5.59898932 4.2964992s.57942948 2.92544507 2.7931047 2.92544507c2.21367521 0 2.76709398-3.95941908 2.76709398-3.95941908s.5534188 3.95941908 2.7670941 3.95941908c2.2136752 0 2.7931047-2.8807602 2.7931047-2.8807602l-1.1328483 6.27454798H6.73183761L5.59898932 4.2964992z" }), /* @__PURE__ */ React.createElement("path", { fill: "#858585", d: "M12.2660256 2.13126259c0 .62445696-.4958632 1.1312626-1.1068376 1.1312626-.61097432 0-1.10683757-.50680564-1.10683757-1.1312626C10.05235043 1.50680564 10.54821368 1 11.159188 1c.6109744 0 1.1068376.50680564 1.1068376 1.13126259h0zM15.8632479 3.49839344c0 .46834271.3718974.84844695.8301282.84844695.4582307 0 .8301282-.38010424.8301282-.84844695 0-.46890835-.3718975-.84844695-.8301282-.84844695-.4582308 0-.8301282.3795386-.8301282.84844695h0zM4.79487179 3.45088041c0 .46890835.37189744.84844695.83012821.84844695s.83012821-.3795386.83012821-.84844695c0-.46834271-.37189744-.84844694-.83012821-.84844694s-.83012821.38010423-.83012821.84844694h0z" })));
};
const ForwardRef = forwardRef(SvgProgressStatusOutline);
export default ForwardRef;
