import * as React from "react";
import { forwardRef } from "react";
const SvgNavArrowLeft = ({
  title,
  titleId,
  ...props
}, ref) => {
  return /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "-13 -21 20 25", ref, "aria-labelledby": titleId, ...props }, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { fill: "none", stroke: "currentColor", strokeLinecap: "round", strokeWidth: 2.8, d: "M0 0l-8-8 8-8", opacity: 0.53 }));
};
const ForwardRef = forwardRef(SvgNavArrowLeft);
export default ForwardRef;
