import { useState } from 'react';
import { toast } from 'react-toastify';

type CopiedValue = string | null;
type CopyFn = (text: string, successMessage: string) => Promise<boolean>;

export function useCopyToClipboard(): [CopyFn, CopiedValue] {
  const [copiedText, setCopiedText] = useState<CopiedValue>(null);

  const copy: CopyFn = async (text, successMessage) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopiedText(text);
      toast.success(successMessage);

      return true;
    } catch (error) {
      toast.error('Failed to copy to clipboard');
      setCopiedText(null);

      return false;
    }
  };

  return [copy, copiedText];
}
