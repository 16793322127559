import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import SubmitFooter from 'components/shared/submit-footer';
import { useSavePromoCodeMutation } from 'hooks/discounts/use-save-promo-code-mutation';
import PromoCodesImg from 'images/discounts/promo-codes.svg';
import { ApiRequestError } from 'providers/api/helpers';
import * as paths from 'routes/paths';
import { PromoCodeFormValues } from 'types/discounts';
import { Shop } from 'types/shops';
import { getDefaultPromoCodeFormValues } from 'utilities/discounts';
import {
  showInvalidSubmitToast,
  showUnexpectedErrorToast,
} from 'utilities/forms';

import { SummaryTile } from '../summary-tile';

import { PromoCodeForm } from './form';
import { PromoCodeFormHeader } from './form-header';

import styles from './styles.module.scss';

type Props = {
  shopId: Shop['shopId'];
  shopTimezone: Shop['timezoneIdentifier'];
};

export const PromoCode = ({ shopId, shopTimezone }: Props) => {
  const navigate = useNavigate();

  const {
    control,
    formState: { errors, isSubmitting },
    getValues,
    handleSubmit,
    register,
    setError,
    trigger,
  } = useForm({
    defaultValues: getDefaultPromoCodeFormValues(shopTimezone),
    mode: 'onTouched',
  });

  const {
    error: discountError,
    mutate: saveDiscount,
    isError: isDiscountRejected,
    isLoading: isDiscountSaving,
    isSuccess: isDiscountSaved,
  } = useSavePromoCodeMutation(shopId);

  useEffect(() => {
    if (isDiscountRejected) {
      if (
        discountError instanceof ApiRequestError &&
        discountError.status === 422 &&
        discountError.responseBody?.messages?.some((it: string) =>
          it.includes('Tag has already been taken'),
        )
      ) {
        setError(
          'tag',
          {
            type: 'manual',
            message:
              'This name has already been used. Please use a different one.',
          },
          { shouldFocus: true },
        );
        showInvalidSubmitToast();
      } else {
        showUnexpectedErrorToast();
      }
    }
  }, [discountError, isDiscountRejected, setError]);

  useEffect(() => {
    if (isDiscountSaved) {
      toast.success('Promo code saved!');
      navigate(paths.promoCodes(shopId));
    }
  }, [navigate, isDiscountSaved, shopId]);

  const handleValidSubmit = (values: PromoCodeFormValues) =>
    saveDiscount(values);

  const handleCancel = () => navigate(-1);

  return (
    <>
      <PromoCodeFormHeader />
      <div className={styles.container}>
        <PromoCodeForm
          control={control}
          errors={errors}
          getValues={getValues}
          onSubmit={handleSubmit(handleValidSubmit, showInvalidSubmitToast)}
          register={register}
          shopTimezone={shopTimezone}
          trigger={trigger}
        >
          <SubmitFooter
            onCancel={handleCancel}
            isSubmitDisabled={isSubmitting || isDiscountSaving}
            isSubmitting={isSubmitting || isDiscountSaving}
            submitText="Create Promo Code"
            submitButtonChameleonTarget="Promo Code Create"
          />
        </PromoCodeForm>
        <SummaryTile
          content="Share the discount code anywhere, like in your shop or on social media."
          imgUrl={PromoCodesImg}
          summaryPoints={[
            'Discount on entire order',
            'Customer enters code at checkout',
          ]}
        />
      </div>
    </>
  );
};
