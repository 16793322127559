import { CSSProperties } from 'react';
import cx from 'classnames';

import { Last30Days } from 'types/hours-analytics';
import { capitalize } from 'utilities/strings';

import styles from './styles.module.scss';

type Props = {
  data: Last30Days;
};

const AvailabilityPercentageBar = ({ data }: Props) => {
  const score = data.availabilityScore;
  const range = data.availabilityScoreRange;
  const isCalculating = score == null || range == null;

  const label = isCalculating ? 'Calculating...' : capitalize(range);
  const message = isCalculating
    ? 'Check back soon.'
    : 'Compared to local shops';
  const progressClassName = isCalculating ? 'calculating' : range;
  const cssScore = isCalculating ? '0' : score;
  const scoreLabel = !isCalculating ? 'scoreLabel' : '';

  return (
    <>
      <div className={styles.container}>
        <div
          className={cx(styles.progress, styles[progressClassName])}
          style={{ '--score': cssScore } as CSSProperties}
        >
          <div className={cx(styles.label, styles[scoreLabel])}>{label}</div>
        </div>
      </div>
      <div className={styles.message}>{message}</div>
    </>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default AvailabilityPercentageBar;
