import { useContext } from 'react';

import { ApiContext } from 'providers/api';

export function useApi() {
  const api = useContext(ApiContext);

  if (api == null) {
    throw new Error('useApi must be used within an ApiProvider.');
  }

  return api;
}

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default useApi;
