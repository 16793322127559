import styles from './styles.module.scss';

const PhotoGuidelines = () => {
  return (
    <div className={styles.guidelinesContainer}>
      <div className={styles.guideline}>Guidelines: JPG or PNG up to 10MB.</div>
      <div className={styles.guideline}>
        Dimensions: minimum 800px width, 600px height.
      </div>
    </div>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default PhotoGuidelines;
