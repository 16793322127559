import PropTypes from 'prop-types';

import ContentTile from 'components/shared/content-tile';
import Button, { ButtonVariant } from 'components/shared/slice-button';
import useAnalytics from 'hooks/use-analytics';

import styles from './styles.module.scss';

const IntegrationListItem = ({
  copyText,
  disabled,
  imageAltText,
  imageSource,
  isConnected,
  onDisconnect,
  provider,
  redirectUrl,
}) => {
  const { trackClickIntegrationItem } = useAnalytics();

  let buttonText;

  if (disabled) {
    buttonText = 'Call Us';
  } else if (isConnected) {
    buttonText = 'Disconnect';
  } else {
    buttonText = 'Connect';
  }

  const handleClick = () => {
    trackClickIntegrationItem(provider);

    if (isConnected) {
      onDisconnect();
    } else {
      window.location.replace(redirectUrl);
    }
  };

  // There is no loading state anywhere on this page, so disable the button
  // until it will actually do something.
  const isDisabled = disabled || (!isConnected && !redirectUrl);

  return (
    <ContentTile className={styles.posListItem}>
      <img
        src={imageSource}
        alt={imageAltText}
        className={styles.posListItemImage}
      />
      <div className={styles.posListItemCopy}>{copyText}</div>
      <Button
        variant={ButtonVariant.Primary}
        disabled={isDisabled}
        onClick={handleClick}
        className={styles.button}
      >
        {buttonText}
      </Button>
    </ContentTile>
  );
};

IntegrationListItem.propTypes = {
  copyText: PropTypes.node,
  disabled: PropTypes.bool,
  imageAltText: PropTypes.string,
  imageSource: PropTypes.string,
  isConnected: PropTypes.bool,
  onDisconnect: PropTypes.func,
  provider: PropTypes.string.isRequired,
  redirectUrl: PropTypes.string,
};

IntegrationListItem.defaultProps = {
  copyText: <p />,
  disabled: false,
  imageAltText: '',
  imageSource: '',
  isConnected: false,
  onDisconnect: () => null,
  redirectUrl: null,
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default IntegrationListItem;
