import { v4 as uuidv4 } from 'uuid';

import { MenuProductAvailability } from 'types/menu/product';
import { indexBy } from 'utilities/lists';

// We might be able to delete the following enums when migrating to TypeScript.
export const AnalyticsField = {
  CategoryChanged: 'categoryChanged',
  CustomizationAdded: 'customizationAdded',
  CustomizationLimitChanged: 'customizationLimitChanged',
  CustomizationNameChanged: 'customizationNameChanged',
  CustomizationRemoved: 'customizationRemoved',
  CustomizationRequiredChanged: 'customizationRequiredChanged',
  DescriptionChanged: 'descriptionChanged',
  IsFeaturedChanged: 'isFeaturedChanged',
  IsAvailableOnlineChanged: 'isAvailableOnlineChanged',
  IsAvailableOfflineChanged: 'isAvailableOfflineChanged',
  NameChanged: 'nameChanged',
  KitchenNameChanged: 'kitchenNameChanged',
  PrintingChanged: 'printingChanged',
  SelectionAdded: 'selectionAdded',
  SelectionNameChanged: 'selectionNameChanged',
  SelectionPriceChanged: 'selectionPriceChanged',
  SelectionRemoved: 'selectionRemoved',
  TypeAdded: 'typeAdded',
  TypeNameChanged: 'typeNameChanged',
  TypePriceChanged: 'typePriceChanged',
  TypeRemoved: 'typeRemoved',
};

export const ProductField = {
  CategoryId: 'categoryId',
  Customizations: 'customizations',
  Description: 'description',
  Id: 'id',
  Image: 'image',
  IsFeatured: 'isFeatured',
  Availabilities: 'availabilities',
  Name: 'name',
  KitchenName: 'kitchenName',
  PrinterSettings: 'printerSettings',
  ProductTypes: 'productTypes',
};

export const PrinterSettingField = {
  Id: 'id',
  IsEnabled: 'isEnabled',
  Name: 'name',
};

export const ProductTypeField = {
  Id: 'id',
  Name: 'name',
  Price: 'price',
};

export const CustomizationField = {
  ActiveProductTypeId: 'activeProductTypeId',
  IsByHalf: 'isByHalf',
  IsByType: 'isByType',
  IsRequired: 'isRequired',
  Limit: 'limit',
  Name: 'name',
  Relationships: 'relationships',
  Selections: 'selections',
};

export const SelectionField = {
  Name: 'name',
  HalfOffers: 'halfOffers',
  Relationships: 'relationships',
  WholeOffers: 'wholeOffers',
};

export const OfferField = {
  Id: 'id',
  IsDisabled: 'isDisabled',
  LeftId: 'leftId',
  Price: 'price',
  ProductTypeId: 'productTypeId',
  RightId: 'rightId',
};

export const RelationshipField = {
  Id: 'id',
  ProductTypeId: 'productTypeId',
};

// In V1 menu data, selection offers can have one of the following names. These
// names are replaced with an integer value in V2 (selection price "kind").
export const OfferName = {
  Whole: 'whole',
  Half: 'half', // Referred as the left half.
  SecondHalf: 'second-half', // Referred to as the right half.
};

export const getCustomizationFieldName = (field, index) =>
  `${ProductField.Customizations}.${index}.${field}`;

export const getCustomizationRelationshipFieldName = (
  field,
  customizationIndex,
  relationshipIndex,
) =>
  `${getCustomizationFieldName(
    CustomizationField.Relationships,
    customizationIndex,
  )}.${relationshipIndex}.${field}`;

export const getSelectionFieldName = (
  field,
  customizationIndex,
  selectionIndex,
) =>
  `${getCustomizationFieldName(
    CustomizationField.Selections,
    customizationIndex,
  )}.${selectionIndex}.${field}`;

export const getSelectionRelationshipFieldName = (
  field,
  customizationIndex,
  selectionIndex,
  relationshipIndex,
) =>
  `${getSelectionFieldName(
    SelectionField.Relationships,
    customizationIndex,
    selectionIndex,
  )}.${relationshipIndex}.${field}`;

export const getOfferFieldName = (
  field,
  customizationIndex,
  selectionIndex,
  offerIndex,
  isHalf = false,
) =>
  `${getSelectionFieldName(
    isHalf ? SelectionField.HalfOffers : SelectionField.WholeOffers,
    customizationIndex,
    selectionIndex,
  )}.${offerIndex}.${field}`;

export const getPrinterSettingFieldName = (field, index) =>
  `${ProductField.PrinterSettings}.${index}.${field}`;

export const getProductTypeFieldName = (field, index) =>
  `${ProductField.ProductTypes}.${index}.${field}`;

const createNewId = () => `new_${uuidv4()}`;

// In both findFieldsToAdd and findFieldsToRemove, the fields argument is a list
// of customization relationships, selection relationships, or offers.
// RelationshipField.ProductTypeId happens to be the same as
// OfferField.ProductTypeId, but it would be better to enforce that shared
// interface with TypeScript.
export const findFieldsToAdd = (productTypes, fields) => {
  // List of product type identifiers that we don't find among the fields.
  const missing = new Set(productTypes.map((it) => it[ProductTypeField.Id]));
  fields.forEach((it) => missing.delete(it[RelationshipField.ProductTypeId]));

  // Sets don't have collection methods like map.
  return [...missing];
};

export const findFieldsToRemove = (productTypes, fields) => {
  const productTypeIds = new Set(
    productTypes.map((it) => it[ProductTypeField.Id]),
  );

  // Removing fields from a field array requires indexes.
  return fields.reduce((indexes, field, index) => {
    if (!productTypeIds.has(field[RelationshipField.ProductTypeId])) {
      indexes.push(index);
    }

    return indexes;
  }, []);
};

const createGroupByReducer = (getKey) => (groups, object, index, objects) => {
  const key = getKey(object);

  // It's easier to use a dictionary to create the groups, however, when using
  // this function the starting value should be passed as an empty array so that
  // calling reduce on an empty array will still return an array.
  if (index === 0) {
    groups = {};
  }

  // Create the group list if we haven't yet.
  groups[key] ??= [];
  groups[key].push(object);

  // Just return the list of the groups.
  if (index === objects.length - 1) {
    return Object.values(groups);
  }

  return groups;
};

const groupByIdReducer = createGroupByReducer((it) => it.id);
const groupByPriceReducer = createGroupByReducer((it) => it.price);

const differentiateMenuSelection = (selection) => {
  selection.id = createNewId();

  for (const price of selection.prices) {
    price.id = createNewId();
  }
};

const createMenuPrices = (formWholeOffers, formHalfOffers, isByHalf) => {
  const indexedPrices = {};

  for (const formOffer of formWholeOffers) {
    if (formOffer[OfferField.IsDisabled]) {
      continue;
    }

    indexedPrices[formOffer[OfferField.ProductTypeId]] ??= [];
    indexedPrices[formOffer[OfferField.ProductTypeId]].push({
      id: formOffer[OfferField.Id],
      kind: isByHalf ? 4 : 1,
      price: Number(formOffer[OfferField.Price]),
    });
  }

  for (const formOffer of formHalfOffers) {
    if (formOffer[OfferField.IsDisabled]) {
      continue;
    }

    indexedPrices[formOffer[OfferField.ProductTypeId]] ??= [];
    indexedPrices[formOffer[OfferField.ProductTypeId]].push({
      id: formOffer[OfferField.LeftId],
      kind: 2,
      price: Number(formOffer[OfferField.Price]),
    });

    indexedPrices[formOffer[OfferField.ProductTypeId]].push({
      id: formOffer[OfferField.RightId],
      kind: 3,
      price: Number(formOffer[OfferField.Price]),
    });
  }

  // We need to detect when shared prices are differentiated, so group all of
  // the prices by id.
  const idGroups = Object.values(indexedPrices).reduce(groupByIdReducer, []);

  for (const idGroup of idGroups) {
    // The price was never shared.
    if (idGroup.length === 1) {
      continue;
    }

    // Regroup the prices that share an id by value and set the largest group
    // aside so we only change a minimum set of ids.
    const priceGroups = idGroup
      .reduce(groupByPriceReducer, [])
      .sort((a, b) => a.length - b.length)
      .slice(0, -1);

    for (const priceGroup of priceGroups) {
      for (const price of priceGroup) {
        price.id = createNewId();
      }
    }
  }

  return indexedPrices;
};

const createMenuSelections = (formSelections, isByHalf) => {
  const indexedSelections = {};

  for (const formSelection of formSelections) {
    // Prices for this selection indexed by product type.
    const indexedPrices = createMenuPrices(
      formSelection[SelectionField.WholeOffers],
      formSelection[SelectionField.HalfOffers],
      isByHalf,
    );

    for (const formRelationship of formSelection[
      SelectionField.Relationships
    ]) {
      const productTypeId = formRelationship[RelationshipField.ProductTypeId];
      const selection = {
        id: formRelationship[RelationshipField.Id],
        name: formSelection[SelectionField.Name],
        prices: indexedPrices[productTypeId],
      };

      // The selection is disabled.
      if (selection.prices == null) {
        continue;
      }

      // If we differentiated one of the prices, we need to differentiate the
      // entire selection.
      if (selection.prices.some((it) => it.id.startsWith('new'))) {
        differentiateMenuSelection(selection);
      }

      indexedSelections[productTypeId] ??= [];
      indexedSelections[productTypeId].push(selection);
    }
  }

  return indexedSelections;
};

const createMenuAddons = (formCustomizations) => {
  const indexedAddons = {};
  let selections = [];

  for (const formCustomization of formCustomizations) {
    // Selections for this addon indexed by product type.
    const indexedSelections = createMenuSelections(
      formCustomization[CustomizationField.Selections],
      formCustomization[CustomizationField.IsByHalf],
    );

    for (const formRelationship of formCustomization[
      CustomizationField.Relationships
    ]) {
      const productTypeId = formRelationship[RelationshipField.ProductTypeId];
      const addonSelections = indexedSelections[productTypeId];

      // The addon is disabled.
      if (addonSelections == null) {
        continue;
      }

      let id = formRelationship[RelationshipField.Id];

      // Differentiating one of the selections requires differentiating the
      // entire addon.
      if (addonSelections.some((it) => it.id.startsWith('new'))) {
        id = createNewId();
        addonSelections.forEach(differentiateMenuSelection);
      }

      const addon = {
        id,
        name: formCustomization[CustomizationField.Name],
        limit: formCustomization[CustomizationField.Limit],
        required: formCustomization[CustomizationField.IsRequired],
        kind: formCustomization[CustomizationField.IsByHalf] ? 2 : 1,
        selectionIds: addonSelections.map((it) => it.id),
      };

      indexedAddons[productTypeId] ??= [];
      indexedAddons[productTypeId].push(addon);
      selections = selections.concat(addonSelections);
    }
  }

  return [indexedAddons, selections];
};

const createMenuProductTypes = (formProductTypes, indexedAddons) => {
  let addons = [];
  const productTypes = [];

  for (const formProductType of formProductTypes) {
    const id = formProductType[ProductTypeField.Id];
    const productTypeAddons = indexedAddons[id] ?? [];
    const productType = {
      id,
      name: formProductType[ProductTypeField.Name],
      price: Number(formProductType[ProductTypeField.Price]),
      addonIds: productTypeAddons.map((it) => it.id),
    };

    productTypes.push(productType);
    addons = addons.concat(productTypeAddons);
  }

  return [productTypes, addons];
};

export const createProductWithFormData = (
  values,
  shopId,
  isKitchenNameEnabled,
) => {
  const [indexedAddons, selections] = createMenuAddons(
    values[ProductField.Customizations],
  );
  const [productTypes, addons] = createMenuProductTypes(
    values[ProductField.ProductTypes],
    indexedAddons,
  );
  const availabilies = values[ProductField.Availabilities];
  let [isAvailableOnline, isAvailableOffline] = [false, false];
  if (availabilies.includes(MenuProductAvailability.Online)) {
    isAvailableOnline = true;
  }
  if (availabilies.includes(MenuProductAvailability.Offline)) {
    isAvailableOffline = true;
  }

  const product = {
    shopId,
    product: {
      id: values[ProductField.Id],
      description: values[ProductField.Description],
      name: values[ProductField.Name],
      image: values[ProductField.Image],
      isFeatured: values[ProductField.IsFeatured],
      isAvailableOnline: isAvailableOnline,
      isAvailableOffline: isAvailableOffline,
      categoryId: values[ProductField.CategoryId],
      productTypeIds: productTypes.map((it) => it.id),
    },
    relationships: {
      productTypes,
      addons,
      selections,
    },
  };

  if (isKitchenNameEnabled) {
    product.product.kitchenTicketAlias = values[ProductField.KitchenName];
  }

  return product;
};

// The printer settings are sent through their own endpoint.
export const createPrinterSettingsWithFormData = (values) =>
  values[ProductField.PrinterSettings].map((it) => ({
    printerRoleId: it[PrinterSettingField.Id],
    printerRoleName: it[PrinterSettingField.Name],
    printingEnabled: it[PrinterSettingField.IsEnabled],
  }));

// The menu team would like to get email alerts when certain changes are made.
// This method compares the default form values to the submitted form values to
// determine which alerts need to be sent.
export const getMenuQualityAlerts = (shopName, defaults, submitted) => {
  const opening = `${shopName} - Menu Quality Alert`;

  const updates = [opening];

  for (const submittedCustomization of submitted[ProductField.Customizations]) {
    const submittedCustomizationName =
      submittedCustomization[CustomizationField.Name];
    const submittedCustomizationRequired =
      submittedCustomization[CustomizationField.IsRequired];

    if (!submittedCustomizationRequired) {
      continue;
    }

    const defaultCustomization = defaults[ProductField.Customizations].find(
      (it) => it[CustomizationField.Name] === submittedCustomizationName,
    );
    const defaultCustomizationRequired =
      defaultCustomization?.[CustomizationField.IsRequired] ?? false;

    if (!defaultCustomizationRequired) {
      updates.push(
        `Product: ${
          submitted[ProductField.Name]
        }, Customization Set: ${submittedCustomizationName}, Required has been marked TRUE`,
      );
    }
  }

  return {
    updates: updates.length > 1 ? updates.join('; ') : null,
  };
};

export const getProductEditAnalytics = (values) => ({
  productId: String(values[ProductField.Id]),
  productName: values[ProductField.Name],
  productKitchenName: values[ProductField.KitchenName],
  customizationEditContent: {
    customizationAdded: values[AnalyticsField.CustomizationAdded],
    customizationLimitChanged: values[AnalyticsField.CustomizationLimitChanged],
    customizationNameChanged: values[AnalyticsField.CustomizationNameChanged],
    customizationRemoved: values[AnalyticsField.CustomizationRemoved],
    customizationRequiredChanged:
      values[AnalyticsField.CustomizationRequiredChanged],
    customizationSelectionAdded: values[AnalyticsField.SelectionAdded],
    customizationSelectionNameChanged:
      values[AnalyticsField.SelectionNameChanged],
    customizationSelectionPriceChanged:
      values[AnalyticsField.SelectionPriceChanged],
    customizationSelectionRemoved: values[AnalyticsField.SelectionRemoved],
  },
  detailsEditContent: {
    categoryChanged: values[AnalyticsField.CategoryChanged],
    descriptionChanged: values[AnalyticsField.DescriptionChanged],
    nameChanged: values[AnalyticsField.NameChanged],
    kitchenNameChanged: values[AnalyticsField.KitchenNameChanged],
  },
  optionsEditContent: {
    isFeaturedChanged: values[AnalyticsField.IsFeaturedChanged],
    IsAvailableOnlineChanged: values[AnalyticsField.IsAvailableOnlineChanged],
    IsAvailableOfflineChanged: values[AnalyticsField.IsAvailableOfflineChanged],
    registerPrintingChanged: values[AnalyticsField.PrintingChanged],
  },
  typeEditContent: {
    nameChanged: values[AnalyticsField.TypeNameChanged],
    priceChanged: values[AnalyticsField.TypePriceChanged],
    typeAdded: values[AnalyticsField.TypeAdded],
    typeRemoved: values[AnalyticsField.TypeRemoved],
  },
});

export const createFormWholeOffer = ({
  id = createNewId(),
  isDisabled = false,
  price = '',
  productTypeId,
}) => ({
  [OfferField.Id]: String(id),
  [OfferField.IsDisabled]: isDisabled,
  [OfferField.Price]: String(price),
  [OfferField.ProductTypeId]: String(productTypeId),
});

export const createFormHalfOffer = ({
  isDisabled = false,
  leftId = createNewId(),
  price = '',
  productTypeId,
  rightId = createNewId(),
}) => ({
  [OfferField.IsDisabled]: isDisabled,
  [OfferField.LeftId]: String(leftId),
  [OfferField.Price]: String(price),
  [OfferField.ProductTypeId]: String(productTypeId),
  [OfferField.RightId]: String(rightId),
});

export const createFormRelationship = ({
  id = createNewId(),
  productTypeId,
}) => ({
  [RelationshipField.Id]: String(id),
  [RelationshipField.ProductTypeId]: String(productTypeId),
});

export const createFormSelection = ({
  halfOffers,
  name = '',
  relationships,
  wholeOffers,
}) => ({
  [SelectionField.HalfOffers]: halfOffers,
  [SelectionField.Name]: name,
  [SelectionField.Relationships]: relationships,
  [SelectionField.WholeOffers]: wholeOffers,
});

const createFormCustomization = ({
  activeProductTypeId,
  isByHalf,
  isByType,
  isRequired,
  limit,
  name,
  relationships,
  selections,
}) => ({
  [CustomizationField.ActiveProductTypeId]: String(activeProductTypeId),
  [CustomizationField.IsByHalf]: isByHalf,
  [CustomizationField.IsByType]: isByType,
  [CustomizationField.IsRequired]: isRequired,
  [CustomizationField.Limit]: limit,
  [CustomizationField.Name]: name,
  [CustomizationField.Relationships]: relationships,
  [CustomizationField.Selections]: selections,
});

export const createFormProductType = ({
  id = createNewId(),
  name = '',
  price = '',
} = {}) => ({
  [ProductTypeField.Id]: String(id),
  [ProductTypeField.Name]: name,
  [ProductTypeField.Price]: String(price),
});

const createFormPrinterSetting = ({
  printerRoleId,
  printerRoleName,
  printingEnabled,
}) => ({
  [PrinterSettingField.Id]: printerRoleId,
  [PrinterSettingField.Name]: printerRoleName,
  [PrinterSettingField.IsEnabled]: printingEnabled,
});

export const getFormCustomizations = (productData) => {
  const formCustomizations = [];
  const { product } = productData;
  const {
    addonGrids = [],
    addons = [],
    selections = [],
  } = productData.relationships;

  const addonsById = indexBy(addons, 'id');
  const gridsById = indexBy(addonGrids, 'id');
  const selectionsById = indexBy(selections, 'id');

  for (const gridId of product.addonGridIds ?? []) {
    const grid = gridsById[gridId];
    const { addonIds, data, productTypeIds } = grid;

    const firstAddonId = addonIds.find((it) => it != null);
    const firstAddon = addonsById[firstAddonId];

    const isByHalf = firstAddon.kind === 2;

    const formSelections = [];

    for (const row of data) {
      const firstSelectionId = row.find((it) => it != null);
      const firstSelection = selectionsById[firstSelectionId];

      const formHalfOffers = [];
      const formWholeOffers = [];

      for (const [index, selectionId] of row.entries()) {
        // A null selection ID represents that the selection is disabled for a
        // given product type.
        const selection = selectionsById[selectionId];
        const pricesByKind = indexBy(selection?.prices ?? [], 'kind');

        const whole = pricesByKind[1] ?? pricesByKind[4];
        const left = pricesByKind[2];
        const right = pricesByKind[3];

        // If the selection is disabled for the type or kind, we'll create a
        // placeholder that initially shows as disabled to make the form logic
        // easier. It will be straightforward to add the ability to toggle prices
        // as we just need to flip the boolean.
        formWholeOffers.push(
          createFormWholeOffer({
            id: whole?.id,
            price: whole != null ? whole.price : undefined,
            isDisabled: whole == null,
            productTypeId: productTypeIds[index],
          }),
        );

        // Form half offers are distinct in that they combine the two separate
        // half and second-half prices from the menu data into one object. The
        // price is always the same.
        if (isByHalf) {
          formHalfOffers.push(
            createFormHalfOffer({
              leftId: left?.id,
              rightId: right?.id,
              productTypeId: productTypeIds[index],
              price: left != null ? left.price : undefined,
              isDisabled: left == null,
            }),
          );
        }
      }

      formSelections.push(
        createFormSelection({
          name: firstSelection.name,
          relationships: productTypeIds.map((productTypeId, index) =>
            createFormRelationship({
              id: row[index] ?? createNewId(),
              productTypeId,
            }),
          ),
          halfOffers: formHalfOffers,
          wholeOffers: formWholeOffers,
        }),
      );
    }

    formCustomizations.push(
      createFormCustomization({
        activeProductTypeId: product.productTypeIds[0],
        isByHalf,
        isByType: grid.byType,
        isRequired: firstAddon.required,
        limit: firstAddon.limit ?? 0,
        name: firstAddon.name,
        relationships: productTypeIds.map((productTypeId, index) =>
          createFormRelationship({
            id: addonIds[index] ?? createNewId(),
            productTypeId,
          }),
        ),
        selections: formSelections,
      }),
    );
  }

  return formCustomizations;
};

const getFormPrinterSettings = (settings) =>
  settings.map(createFormPrinterSetting);

const getFormProductTypes = (productData) => {
  const productTypesById = indexBy(
    productData.relationships.productTypes,
    'id',
  );

  return productData.product.productTypeIds.map((it) => {
    const productType = productTypesById[it];

    return createFormProductType({
      id: productType.id,
      name: productType.name,
      price: productType.price,
    });
  });
};

export const getDefaultFormValues = (productResponse, printSettingResponse) => {
  const product = productResponse?.product;
  let availabilies = [];
  if (product?.isAvailableOnline == null || product?.isAvailableOnline) {
    availabilies.push(MenuProductAvailability.Online);
  }
  if (product?.isAvailableOffline == null || product?.isAvailableOffline) {
    availabilies.push(MenuProductAvailability.Offline);
  }

  return {
    [ProductField.Id]: product?.id ?? createNewId(),
    [ProductField.CategoryId]: product?.categoryId ?? null,
    [ProductField.Description]: product?.description ?? '',
    [ProductField.Image]: product?.image ?? '',
    [ProductField.IsFeatured]: product?.isFeatured ?? false,
    [ProductField.Availabilities]: availabilies,
    [ProductField.Name]: product?.name ?? '',
    [ProductField.KitchenName]: product?.kitchenTicketAlias ?? null,

    [ProductField.Customizations]: product
      ? getFormCustomizations(productResponse)
      : [],
    [ProductField.PrinterSettings]:
      getFormPrinterSettings(printSettingResponse),
    [ProductField.ProductTypes]: product
      ? getFormProductTypes(productResponse)
      : [
          createFormProductType({
            id: createNewId(),
            name: '',
            price: '',
          }),
        ],

    [AnalyticsField.CategoryChanged]: false,
    [AnalyticsField.CustomizationAdded]: false,
    [AnalyticsField.CustomizationLimitChanged]: false,
    [AnalyticsField.CustomizationNameChanged]: false,
    [AnalyticsField.CustomizationRemoved]: false,
    [AnalyticsField.CustomizationRequiredChanged]: false,
    [AnalyticsField.DescriptionChanged]: false,
    [AnalyticsField.IsFeaturedChanged]: false,
    [AnalyticsField.IsAvailableOnlineChanged]: false,
    [AnalyticsField.IsAvailableOfflineChanged]: false,
    [AnalyticsField.NameChanged]: false,
    [AnalyticsField.KitchenNameChanged]: false,
    [AnalyticsField.PrintingChanged]: false,
    [AnalyticsField.SelectionAdded]: false,
    [AnalyticsField.SelectionNameChanged]: false,
    [AnalyticsField.SelectionPriceChanged]: false,
    [AnalyticsField.SelectionRemoved]: false,
    [AnalyticsField.TypeAdded]: false,
    [AnalyticsField.TypeNameChanged]: false,
    [AnalyticsField.TypePriceChanged]: false,
    [AnalyticsField.TypeRemoved]: false,
  };
};

const copyFormOffers = (
  sourceOffers,
  translatedProductTypeIds,
  untranslatedProductTypeIds,
) => {
  // We can stop early if there is nothing to copy.
  if (sourceOffers.length === 0) {
    return [];
  }

  const isHalf = sourceOffers[0][OfferField.LeftId] != null;
  const createFormOffer = isHalf ? createFormHalfOffer : createFormWholeOffer;

  // By removing all source offers whose product type ID is not in the
  // translated IDs dictionary, we handle the case in which the source product
  // types outnumber the target product types. Those IDs cannot correspond to
  // any target product type, so it is safe to delete associated data.
  return sourceOffers
    .filter(
      (it) => translatedProductTypeIds[it[OfferField.ProductTypeId]] != null,
    )
    .map((it) =>
      createFormOffer({
        isDisabled: it[OfferField.IsDisabled],
        price: it[OfferField.Price],
        productTypeId: translatedProductTypeIds[it[OfferField.ProductTypeId]],
      }),
    )
    .concat(
      // When there are more target product types than source product types, we
      // need to create some new data. Importantly, if the customization is not
      // by type, these new offers will adopt the correct price once they are
      // rendered.
      untranslatedProductTypeIds.map((productTypeId) =>
        createFormOffer({ productTypeId }),
      ),
    );
};

const copyFormSelections = (
  sourceSelections,
  targetProductTypeIds,
  translatedProductTypeIds,
  untranslatedProductTypeIds,
) =>
  sourceSelections.map((sourceSelection) =>
    createFormSelection({
      name: sourceSelection.name,

      relationships: targetProductTypeIds.map((productTypeId) =>
        createFormRelationship({
          productTypeId,
        }),
      ),

      halfOffers: copyFormOffers(
        sourceSelection[SelectionField.HalfOffers],
        translatedProductTypeIds,
        untranslatedProductTypeIds,
      ),
      wholeOffers: copyFormOffers(
        sourceSelection[SelectionField.WholeOffers],
        translatedProductTypeIds,
        untranslatedProductTypeIds,
      ),
    }),
  );

// When copying customizations from one product to another, we need to line up
// the product types from each product to retain as much pricing information as
// possible. For example, if we are copying from a product with types [Infant,
// Toddler, Teenager] to one with types [Small, Medium, Large, Super], we want
// Small to have the prices of Infant, etc. There is no reasonable way to line
// up the product types semantically, so we'll just do so by index.
export const copyFormCustomizations = (
  sourceCustomizations,
  sourceProductTypeIds,
  targetProductTypeIds,
) => {
  // Figure out how many product types we can line up.
  const length = Math.min(
    sourceProductTypeIds.length,
    targetProductTypeIds.length,
  );

  // This dictionary allows us to align prices by product type ID (eg Infant to
  // Small in the example above).
  const translatedProductTypeIds = {};

  // Using the length found above allows us to know when to delete data if there
  // are more source product types than target product types. The "extra" source
  // product types won't be in the dictionary.
  for (let i = 0; i < length; i++) {
    translatedProductTypeIds[sourceProductTypeIds[i]] = targetProductTypeIds[i];
  }

  // If there are more target product types (eg Super in the example above), we
  // will have to create new objects, so we record a list of the IDs from the
  // "extra" target product types.
  const untranslatedProductTypeIds = targetProductTypeIds.slice(length);

  return sourceCustomizations.map((sourceCustomization) =>
    createFormCustomization({
      activeProductTypeId: targetProductTypeIds[0],
      isByHalf: sourceCustomization[CustomizationField.IsByHalf],
      isByType: sourceCustomization[CustomizationField.IsByType],
      isRequired: sourceCustomization[CustomizationField.IsRequired],
      limit: sourceCustomization[CustomizationField.Limit],
      name: sourceCustomization[CustomizationField.Name],

      relationships: targetProductTypeIds.map((productTypeId) =>
        createFormRelationship({
          productTypeId,
        }),
      ),

      selections: copyFormSelections(
        sourceCustomization[CustomizationField.Selections],
        targetProductTypeIds,
        translatedProductTypeIds,
        untranslatedProductTypeIds,
      ),
    }),
  );
};
