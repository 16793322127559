import { AuditLogStatus } from 'types/register-users';

export const permissionLogStatusToDisplayText = (
  status: AuditLogStatus,
): string => {
  switch (status) {
    case AuditLogStatus.Authorized:
      return 'Authorized';
    case AuditLogStatus.Successful:
      return 'Successful';
    case AuditLogStatus.Preauthorized:
      return 'Preauthorized';
  }
};
