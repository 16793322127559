import { flexRender, RowData } from '@tanstack/react-table';
import cx from 'classnames';

import {
  AutomaticTableHeaderCellProps,
  BaseTableHeaderCellProps,
  OverloadedTableHeaderCellComponent,
} from 'types/shared/table';

import styles from './styles.module.scss';

const BaseTableHeaderCell = ({
  children,
  className,
  isActionColumn = false,
  isNextSortAscending = true,
  isSortable = false,
  isSorted = false,
  onToggleSort: handleToggleSort,
  ...props
}: BaseTableHeaderCellProps) => {
  if (isSortable) {
    children = (
      <button
        className={cx(styles.toggle)}
        onClick={handleToggleSort}
        type="button"
      >
        <span className={cx(styles.label, isSorted && styles.sorted)}>
          {children}
        </span>
        <span
          aria-label={`Sort ${
            isNextSortAscending ? 'ascending' : 'descending'
          }`}
          className={cx(styles.arrow, !isNextSortAscending && styles.up)}
          role="img"
        >
          ▾
        </span>
      </button>
    );
  }

  return (
    <th
      className={cx(styles.cell, isActionColumn && styles.action, className)}
      {...props}
    >
      {children}
    </th>
  );
};

const TableHeaderCell: OverloadedTableHeaderCellComponent = <
  TData extends RowData,
  TValue,
>(
  props:
    | BaseTableHeaderCellProps
    | AutomaticTableHeaderCellProps<TData, TValue>,
) => {
  // Pass a header object from the result of useReactTable to automatically
  // render the header cell.
  if ('header' in props) {
    const { header, ...base } = props;
    const column = header.column;

    const className = column.columnDef.meta?.className;
    const isActionColumn = column.columnDef.meta?.isActionColumn;
    const isSortable = column.getCanSort();
    const isSorted = column.getIsSorted();
    const isNextSortAscending = !isSorted || isSorted === 'desc';
    const handleToggleSort = column.getToggleSortingHandler();

    return (
      <BaseTableHeaderCell
        className={className}
        isActionColumn={isActionColumn}
        isNextSortAscending={isNextSortAscending}
        isSortable={isSortable}
        isSorted={isSorted}
        onToggleSort={handleToggleSort}
        {...base}
      >
        {header.isPlaceholder
          ? null
          : flexRender(column.columnDef.header, header.getContext())}
      </BaseTableHeaderCell>
    );
  }

  return <BaseTableHeaderCell {...props} />;
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default TableHeaderCell;
