import { useCallback, useMemo, useRef, useState } from 'react';
import { Point, ResponsiveLine } from '@nivo/line';

import useEventListener from 'hooks/use-event-listener';
import { WeeklySummary } from 'types/hours-analytics';
import { mobileDesktopThresholdWidth } from 'utilities/constants';
import {
  getSlicedData,
  regionAvailabilityToChartData,
  shopAvailabilityToChartData,
} from 'utilities/hours-analytics/shop-availability-chart';

import { RegionAverageAvailabilityLineLayer } from './custom-layers/region-average-availability';
import { ShopAvailabilityAreaLayer } from './custom-layers/shop-availability';
import { NoDataMessage } from './no-data-message';
import { Tooltip } from './tooltip';
import { XAxisTick } from './x-axis-tick';
import { YAxisTick } from './y-axis-tick';

import styles from './styles.module.scss';

type Props = {
  data: WeeklySummary[];
  dataAsOfText: string;
  isEmpty: boolean;
};

export const Chart = ({ data, dataAsOfText, isEmpty }: Props) => {
  const [isMobile, setIsMobile] = useState(
    window.document.body.clientWidth < mobileDesktopThresholdWidth,
  );

  // On mobile, we show only 6 data points
  const [slicedData, setSlicedData] = useState(() =>
    getSlicedData(data, isMobile),
  );

  const shopAvailabilityChartData = useMemo(
    () => shopAvailabilityToChartData(slicedData),
    [slicedData],
  );

  const regionAvailabilityChartData = useMemo(
    () => regionAvailabilityToChartData(slicedData),
    [slicedData],
  );

  const endDate = slicedData.at(-1)?.weekEnd ?? '';
  const startDate = slicedData.at(0)?.weekEnd ?? '';

  const outerChartWrapperRef = useRef<HTMLDivElement>(null);

  const [chartWidth, setChartWidth] = useState('100%');

  const windowResizeHandler = useCallback(() => {
    const outerChartWrapper = outerChartWrapperRef.current;

    if (!outerChartWrapper) {
      return;
    }

    const windowWidth = window.document.body.clientWidth;
    const isNowMobile = windowWidth < mobileDesktopThresholdWidth;

    const width = outerChartWrapper.clientWidth;
    setChartWidth(`${width}px`);

    // On mobile, we condense the data displayed down to 6 data points.
    // That way, there will be 4 visible x-axis labels (the first and
    // last data points do not have an x-axis label).
    setSlicedData(isNowMobile ? data.slice(-6) : data);

    setIsMobile(isNowMobile);
  }, [setChartWidth, data]);

  useEventListener('resize', windowResizeHandler);

  return (
    <div ref={outerChartWrapperRef} className={styles.outerChartWrapper}>
      {isEmpty || (
        <div className={styles.asOfDate}>Data as of {dataAsOfText}</div>
      )}
      <div className={styles.innerChartWrapper} style={{ width: chartWidth }}>
        <ResponsiveLine
          data={[
            { id: 'shop availability', data: shopAvailabilityChartData },
            { id: 'region availability', data: regionAvailabilityChartData },
          ]}
          // https://nivo.rocks/guides/theming/
          theme={{
            axis: {
              ticks: {
                text: {
                  fontSize: '12',
                  fill: 'var(--crust-color-text-subtle)',
                },
              },
            },
            grid: {
              line: {
                stroke: 'var(--crust-color-border-neutral-default)',
                strokeDasharray: '1 3',
              },
            },
          }}
          // Use Voronoi mesh to detect mouse interactions
          useMesh
          enablePoints
          margin={{ top: 5, right: 0, bottom: 30, left: 0 }}
          enableSlices="x"
          sliceTooltip={(sliceTooltipProps) => {
            const { points } = sliceTooltipProps.slice;

            const weekEndDate = points[0]!.data.x;

            const shopPoint = points.find(
              (point) => point.serieId === 'shop availability',
            ) as Point;
            const regionAveragePoint = points.find(
              (point) => point.serieId === 'region availability',
            ) as Point;

            return (
              <Tooltip
                regionAverageAvailability={regionAveragePoint.data.y as number}
                shopAvailability={shopPoint.data.y as number}
                weekEndDate={weekEndDate as string}
              />
            );
          }}
          yScale={{
            type: 'linear',
            min: 0,
            max: 100,
          }}
          gridYValues={[25, 50, 75]}
          axisLeft={{
            tickValues: [25, 50, 75],
            renderTick: (axisTickProps) => {
              const { x, y, value: availability } = axisTickProps;

              return <YAxisTick availability={availability} x={x} y={y} />;
            },
          }}
          enableGridX={false}
          enableGridY
          axisBottom={{
            renderTick: (axisTickProps) => {
              const { x, y, value: date } = axisTickProps;

              // Don't show a label for the first or last data point.
              if (date === startDate || date === endDate) {
                return <text></text>;
              }

              return (
                <XAxisTick
                  useShortDates={isMobile}
                  weekEndDate={date}
                  x={x}
                  y={y}
                />
              );
            },
            tickPadding: 0,
            tickSize: 3,
          }}
          layers={
            isEmpty
              ? ['grid', 'axes', 'slices', 'mesh']
              : [
                  'grid',
                  ShopAvailabilityAreaLayer,
                  RegionAverageAvailabilityLineLayer,
                  'axes',
                  'crosshair',
                  'slices',
                  'mesh',
                ]
          }
        />
      </div>
      {isEmpty && <NoDataMessage />}
    </div>
  );
};
