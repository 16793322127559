import { useMemo } from 'react';
import { createColumnHelper } from '@tanstack/react-table';

import ContentTile from 'components/shared/content-tile';
import Table from 'components/shared/table';
import Pagination from 'components/shared/table/pagination';
import Text from 'components/shared/text';
import { useTable } from 'hooks/shared';
import usePermissionsLog from 'hooks/use-permissions-log';
import { PermissionLog } from 'types/register-users';
import { formatDate } from 'utilities/date-time';
import { permissionLogStatusToDisplayText } from 'utilities/permission-logs';

import styles from './styles.module.scss';

type Props = {
  shopId: string;
};

const PermissionsLogTab = ({ shopId }: Props) => {
  const {
    logsRowData,
    setPagination,
    pageCount,
    isLoading,
    pagination,
    numLogEntries,
  } = usePermissionsLog(shopId);

  const suppressPagination = isLoading || pageCount <= 1;

  const columns = useMemo(() => {
    const helper = createColumnHelper<PermissionLog>();

    return [
      helper.accessor('registerUserName', {
        header: 'Actioned By',
        cell: (ctx) => {
          return <Text wrap="truncate">{ctx.getValue()}</Text>;
        },
      }),
      helper.accessor('authorizedByName', {
        header: 'Authorized By',
        cell: (ctx) => {
          return <Text wrap="truncate">{ctx.getValue()}</Text>;
        },
      }),
      helper.accessor('registerProfileName', {
        header: 'Register Profile Name',
        cell: (ctx) => {
          return <Text wrap="truncate">{ctx.getValue()}</Text>;
        },
      }),
      helper.accessor('actionName', {
        header: 'Task',
      }),
      helper.accessor((row) => permissionLogStatusToDisplayText(row.status), {
        id: 'status',
        header: 'Status',
      }),
      helper.accessor('createdAt', {
        header: 'Date',
        cell(ctx) {
          const dateTime = ctx.row.original.createdAt;
          return formatDate(dateTime, 'MMM D, YYYY • h:mm a');
        },
        meta: {
          className: styles.createdAtColumn,
        },
      }),
    ];
  }, []);

  const table = useTable({
    columns,
    data: logsRowData,
    enableSorting: false,
    manualFiltering: true,
    manualPagination: true,
    onPaginationChange: setPagination,
    pageCount: pageCount,
    state: {
      isLoading,
      pagination,
    },
    chameleonTableTitle: 'Permissions Log Table',
  });

  return (
    <ContentTile
      className={styles.permissionsLogContentTile}
      isLoading={isLoading}
    >
      <div className={styles.logCount}>
        {numLogEntries} Permission {numLogEntries === 1 ? 'Log' : 'Logs'}
      </div>
      <Table className={styles.table} table={table} />
      <Pagination
        currentPage={table.getState().pagination.pageIndex + 1}
        isPageControlVisible={!suppressPagination}
        onChangePage={(page: number) => table.setPageIndex(page - 1)}
        totalPages={table.getPageCount()}
        perPage={pagination.pageSize}
        onChangePerPage={(selectedPerPage) =>
          setPagination({ pageIndex: 0, pageSize: selectedPerPage })
        }
      />
    </ContentTile>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default PermissionsLogTab;
