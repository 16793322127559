import { useEffect, useState } from 'react';

const isBrowser =
  typeof window === 'object' && typeof window.matchMedia === 'function';

export const useMediaQuery = (query: string) => {
  const [matches, setMatches] = useState(() =>
    isBrowser ? window.matchMedia(query).matches : false,
  );

  useEffect(() => {
    if (isBrowser) {
      const observer = window.matchMedia(query);
      const callback = (event: MediaQueryListEvent) => {
        setMatches(event.matches);
      };

      observer.addEventListener('change', callback);

      return () => {
        observer.removeEventListener('change', callback);
      };
    }
  }, [query]);

  return matches;
};
