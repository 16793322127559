import {
  chainComparators,
  createIntegerComparator,
  createStringComparator,
  Direction,
} from 'utilities/sorting';

// Sort categories for dropdown.
// Sort by name.
// Differentiate same-named categories using product counts.

const categoryNameSortDirection = Direction.Ascending;
const nameComparator = createStringComparator('label', {
  direction: categoryNameSortDirection,
});

const productCountSortDirection = Direction.Descending;
const productCountComparator = createIntegerComparator('length', {
  direction: productCountSortDirection,
});

const categoryComparator = chainComparators(
  nameComparator,
  productCountComparator,
);

export const sortCategories = (categoryOptions) => {
  return categoryOptions.slice().sort(categoryComparator);
};
