import { ReactElement } from 'react';
import { Control, useWatch } from 'react-hook-form';
import cx from 'classnames';

import Button, { ButtonVariant } from 'components/shared/slice-button';
import { SiteEditorFormValues } from 'types/site-editor';
import { colorOptions, fontOptions } from 'utilities/site-editor';

import styles from './styles.module.scss';

type Props = {
  control: Control<SiteEditorFormValues>;
  icon: ReactElement;
  isSelected: boolean;
  label: string;
  onClick: () => void;
  optionKey: 'colorCode' | 'fontCode';
};

const OptionToggleButton = ({
  control,
  icon,
  isSelected = false,
  label,
  onClick,
  optionKey,
}: Props) => {
  const optionCode = useWatch({
    control,
    name: optionKey,
  });

  const options = optionKey === 'colorCode' ? colorOptions : fontOptions;

  const selectedOptionName =
    options.find((option) => String(option.value) === optionCode)?.label ?? '';

  return (
    <Button
      className={styles.configOptionToggle}
      onClick={onClick}
      variant={ButtonVariant.Transparent}
    >
      <div className={styles.optionIcon}>{icon}</div>
      <div className={styles.optionDetails}>
        <div className={styles.optionLabel}>{label}</div>
        <div
          className={cx(
            styles.selectedOptionName,
            isSelected && styles.optionSelected,
          )}
        >
          {selectedOptionName}{' '}
          <span
            className={cx(styles.caret, isSelected && styles.caretSelected)}
          >
            ▾
          </span>
        </div>
      </div>
    </Button>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default OptionToggleButton;
