import { useMemo, useState } from 'react';
import {
  createColumnHelper,
  getPaginationRowModel,
  PaginationState,
} from '@tanstack/react-table';
import moment from 'moment-timezone';

import ContentTile from 'components/shared/content-tile';
import Table from 'components/shared/table';
import PageControl from 'components/shared/table/page-control';
import useCampaignManagerHistoryQuery from 'hooks/campaign-manager/use-campaign-manager-history';
import { useTable } from 'hooks/shared';
import {
  CampaignManagerMonthlyResults,
  CampaignManagerResults,
} from 'types/campaign-manager';
import { Shop } from 'types/shops';
import { toDollarString, toMoneyString } from 'utilities/currency';
import { formatDate } from 'utilities/date-time';

import Header from './header';

import styles from './styles.module.scss';

type Props = {
  lifetimeResults?: CampaignManagerResults;
  shopId: Shop['shopId'];
};

const HistoryTile = ({ lifetimeResults, shopId }: Props) => {
  const { data, isLoading } = useCampaignManagerHistoryQuery(shopId);

  const columns = useMemo(() => {
    const helper = createColumnHelper<CampaignManagerMonthlyResults>();

    return [
      helper.accessor((row) => row.date, {
        id: 'date',
        header: 'Month',
        cell: (info) => {
          const date = info.getValue();
          const formattedDate = formatDate(date, 'MMM YYYY');
          const isCurrentMonth = moment().isSame(date, 'month');

          return (
            <>
              <span>{formattedDate}</span>
              {isCurrentMonth && (
                <span className={styles.currentMonth}> (To-date)</span>
              )}
            </>
          );
        },
      }),
      helper.accessor((row) => row.sales, {
        id: 'sales',
        header: 'Sales',
        enableSorting: false,
        cell: (info) => {
          const value = info.getValue();
          return (
            <span>
              {value === null ? '--' : toMoneyString(toDollarString(value))}
            </span>
          );
        },
      }),
      helper.accessor((row) => row.orders, {
        id: 'orders',
        header: 'Orders',
        enableSorting: false,
        cell: (info) => {
          const value = info.getValue();
          return <span>{value === null ? '--' : value}</span>;
        },
      }),
      helper.accessor((row) => row.newCustomers, {
        id: 'newCustomers',
        header: 'New Customers',
        enableSorting: false,
        cell: (info) => {
          const value = info.getValue();
          return <span>{value === null ? '--' : value}</span>;
        },
      }),
      helper.accessor((row) => row.impressions, {
        id: 'impressions',
        header: 'Ad Views',
        enableSorting: false,
        cell: (info) => {
          const value = info.getValue();
          return <span>{value === null ? '--' : value}</span>;
        },
      }),
    ];
  }, []);

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 12,
  });

  const table = useTable({
    columns,
    data: data ?? [],
    chameleonTableTitle: 'Campaign Manager Overall History',
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    state: {
      isLoading,
      pagination,
    },
    initialState: {
      sorting: [{ id: 'date', desc: true }],
      pagination: { pageIndex: 0, pageSize: 12 },
    },
  });

  const suppressPagination = !data || isLoading || data.length <= 1;

  return (
    <ContentTile
      className={styles.tile}
      dataTestId="history-tile"
      headerClassName={styles.header}
      headerContent={
        <Header lifetimeResults={lifetimeResults} title="Overall History" />
      }
    >
      <Table table={table} />
      {!suppressPagination && (
        <PageControl
          currentPage={table.getState().pagination.pageIndex + 1}
          setPage={(page) =>
            setPagination({ ...pagination, pageIndex: page - 1 })
          }
          totalPages={table.getPageCount()}
        />
      )}
    </ContentTile>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default HistoryTile;
