import { useMediaQuery } from './use-media-query';

/*
 * Adapted from:
 * https://github.com/adobe/react-spectrum/blob/1697a7fef3516f7a194f43b75edb858589ef6535/packages/%40react-spectrum/utils/src/useIsMobileDevice.ts#L13
 *
 * Mobile is a misleading term. There are many opportunities for the user to be
 * viewing the app on a narrow screen: phones, resized desktop windows, tablet
 * split view, etc. Style logic we want on mobile devices makes sense for any
 * narrow view.
 */
export const useIsMobileDevice = () => {
  // Most tablets in any orientation are wider than 700px. Phones are usually
  // only wider than 700px in landscape.
  return useMediaQuery('(max-width: 700px)');
};
