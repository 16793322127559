import * as React from "react";
import { forwardRef } from "react";
const SvgIconSliceFilled = ({
  title,
  titleId,
  ...props
}, ref) => {
  return /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", fill: "none", viewBox: "0 0 16 18", ref, "aria-labelledby": titleId, ...props }, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { fill: "#FFE4AF", fillRule: "evenodd", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: 1.5, d: "M7.68112 17L14.363 4.04713S12.1352 1 7.68112 1C3.22776 1 1 4.04713 1 4.04713L7.68112 17z", clipRule: "evenodd" }), /* @__PURE__ */ React.createElement("path", { stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: 1.5, d: "M13.5009 5.75673c-.763-1.06183-2.7553-2.59459-5.63589-2.59459-2.80354 0-4.76565 1.41203-5.5718 2.50686" }));
};
const ForwardRef = forwardRef(SvgIconSliceFilled);
export default ForwardRef;
