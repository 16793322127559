import { toast } from 'react-toastify';
import cx from 'classnames';

import Link from 'components/shared/link';
import { useCreateSalesforceCaseMutation } from 'hooks/salesforce/use-create-salesforce-case';
import { useShopTraitsContext } from 'hooks/shops/use-shop-traits-context';
import useAnalytics from 'hooks/use-analytics';
import MegaphoneIcon from 'images/megaphone.svg?react';
import { marketingServices as marketingServicesUrl } from 'routes/external-urls';
import {
  SalesforceCaseType,
  SalesforceNote,
  SalesforceSubject,
} from 'types/salesforce';
import { ClickedExternalLinkType } from 'types/shared/analytics';
import { Shop } from 'types/shops';
import { User } from 'types/user';

import styles from './styles.module.scss';

type Props = {
  shopId: Shop['shopId'];
  user: User;
};

const ExpandAdvertisingOptions = ({ shopId, user }: Props) => {
  const { trackProductUpsellLinkClick } = useAnalytics();
  const shopTraits = useShopTraitsContext();

  const {
    mutate: createSalesforceCase,
    isLoading: isCreatingSalesforceCaseLoading,
  } = useCreateSalesforceCaseMutation();

  const handleLetsDiscussClick = () => {
    if (!isCreatingSalesforceCaseLoading) {
      createSalesforceCase(
        {
          salesforceCase: {
            caseType: SalesforceCaseType.AMS,
            subject: SalesforceSubject.AMS,
          },
          note: SalesforceNote.AdvancedMarketingServicesRenewallNote,
          shopId: shopId,
          user: user,
        },
        {
          onError: () =>
            toast.error(
              `We weren't able to process your request. Please contact your Partner Success Manager.`,
            ),
          onSuccess: () =>
            toast.success(
              'Great! We received your request. Our team will be in touch shortly.',
            ),
        },
      );
    }
    trackProductUpsellLinkClick({
      shopId: String(shopId),
      product: 'advanced marketing services',
      page: 'campaign manager execution',
      linkLabel: "let's discuss",
      type: ClickedExternalLinkType.LinkedText,
      outcome: 'lead',
      shopIntegrationAdoption: shopTraits.shopIntegrationAdoption,
    });
  };

  return (
    <div className={styles.expandOptions}>
      <MegaphoneIcon className={styles.megaphoneIcon} />
      <p className={styles.expandOptionsCopy}>
        <span className={styles.boldText}>
          Want to expand your advertising options?
        </span>
        {` Slice has you covered. `}
        <Link
          className={cx(styles.letsDiscuss, styles.boldText)}
          onClick={() => handleLetsDiscussClick()}
          to={marketingServicesUrl}
          variant="undecorated"
        >
          {`Let's Discuss`}
        </Link>
      </p>
    </div>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default ExpandAdvertisingOptions;
