import { CalendarDate, toZoned, ZonedDateTime } from '@internationalized/date';

import { MenuResponseBody, MutationParams } from 'types/menu/api';
import { MenuProduct } from 'types/menu/product';
import { Shop } from 'types/shops';

export const formatStockUpdateSuccessMessage = (
  productsLength: number,
  isUnavailable: boolean,
) => {
  const formatPlural = productsLength === 1 ? ' item was ' : ' items were ';
  const stockState = isUnavailable ? 'out of stock.' : 'in stock.';

  return productsLength + formatPlural + 'marked ' + stockState;
};

export const formatUnavailableUntilString = (
  date: CalendarDate | ZonedDateTime | null,
  shopTimezone: Shop['timezoneIdentifier'],
) => {
  return date && toZoned(date, shopTimezone).toAbsoluteString();
};

export const createMenuCopyWithUpdatedAvailability =
  (
    productIds: MenuProduct['id'][],
    values: MutationParams,
    shopTimezone: Shop['timezoneIdentifier'],
  ) =>
  (current: MenuResponseBody | undefined) => {
    if (current) {
      const unavailableUntil = formatUnavailableUntilString(
        values.date,
        shopTimezone,
      );

      const menuCopy = {
        ...current,
        relationships: {
          ...current.relationships,
          products: current.relationships.products?.map((product) => {
            if (productIds.includes(product.id)) {
              return {
                ...product,
                unavailable: values.isUnavailable,
                unavailableUntil: unavailableUntil,
              };
            }

            return product;
          }),
        },
      };

      return menuCopy;
    }
  };

export const createMenuCopyWithPreviousAvailability =
  (idsFromError: MenuProduct['id'][], previousMenuData: MenuResponseBody) =>
  (current: MenuResponseBody | undefined) => {
    if (current) {
      const menuCopy = {
        ...current,
        relationships: {
          ...current.relationships,
          products: current.relationships.products?.map((product) => {
            if (idsFromError.includes(product.id)) {
              const previous = previousMenuData.relationships.products?.find(
                (it) => (it.id = product.id),
              );

              if (previous) {
                return {
                  ...product,
                  unavailable: previous.unavailable,
                  unavailableUntil: previous.unavailableUntil,
                };
              }
            }

            return product;
          }),
        },
      };

      return menuCopy;
    }
  };
