import { Control, useWatch } from 'react-hook-form';

import { RegisterDiscountFormValues } from 'types/discounts';

type Props = {
  control: Control<RegisterDiscountFormValues>;
};

const NameFieldsSummary = ({ control }: Props) => {
  const name = useWatch({
    control,
    name: 'name',
  });

  return name ? <>{name}</> : null;
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default NameFieldsSummary;
