import moment, { Moment } from 'moment-timezone';

const collator = new Intl.Collator('en-us');

// This flag determines how null values are handled when sorting, either always
// moved to the beginning or the end. The latter is usually the default.
export enum NullBehavior {
  First = -1,
  Last = 1,
}

export enum Direction {
  Ascending = 1,
  Descending = -1,
}

type ComparatorOptions = {
  direction?: Direction;
  nullBehavior?: NullBehavior;
};

// Determines the appropriate order in the case that one or both of the values
// are null, according to the specified null behavior.
export const compareNull = (
  av: unknown,
  bv: unknown,
  nullBehavior: NullBehavior,
): number | undefined => {
  if (av == null && bv != null) {
    return 1 * nullBehavior;
  }

  if (av != null && bv == null) {
    return -1 * nullBehavior;
  }

  if (av == null && bv == null) {
    return 0;
  }

  // Return undefined if neither of the values is null to signal that more
  // comparisons need to be performed on the given variables.
};

// Creates a comparator function that operates over a list of objects that have
// an integer value for the given key.
export const createIntegerComparator = <K extends string>(
  key: K,
  options?: ComparatorOptions,
): (<T extends { [key in K]: number | null }>(a: T, b: T) => number) => {
  const { direction, nullBehavior } = {
    direction: Direction.Ascending,
    nullBehavior: NullBehavior.Last,
    ...options,
  };

  return (a, b) =>
    compareNull(a[key], b[key], nullBehavior) ??
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    (a[key]! - b[key]!) * direction;
};

type StringComparatorOptions = ComparatorOptions & Intl.CollatorOptions;

// Creates a comparator function that operates over a list of objects that have
// an string value for the given key.
export const createStringComparator = <K extends string>(
  key: K,
  options?: StringComparatorOptions,
): (<T extends { [key in K]: string | null }>(a: T, b: T) => number) => {
  const { direction, nullBehavior, ...collatorOptions } = {
    direction: Direction.Ascending,
    nullBehavior: NullBehavior.Last,
    ...options,
  };

  const collator = new Intl.Collator('en-US', collatorOptions);

  return (a, b) =>
    compareNull(a[key], b[key], nullBehavior) ??
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    collator.compare(a[key]!, b[key]!) * direction;
};

// Creates a comparator function that operates over a list of objects that have
// a moment date value for the given key.
export const createMomentComparator = <K extends string>(
  key: K,
  options?: ComparatorOptions,
): (<T extends { [key in K]: Moment | null }>(a: T, b: T) => number) => {
  const { direction, nullBehavior } = {
    direction: Direction.Ascending,
    nullBehavior: NullBehavior.Last,
    ...options,
  };

  return (a, b) => {
    const compareMoment = () => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      if (a[key]!.isAfter(b[key])) {
        return 1 * direction;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      } else if (a[key]!.isBefore(b[key])) {
        return -1 * direction;
      } else {
        return 0;
      }
    };

    return compareNull(a[key], b[key], nullBehavior) ?? compareMoment();
  };
};

// The chainComparators function is generalized and needs the any type in a few
// places to correctly infer the types of the final operands.
/* eslint-disable @typescript-eslint/no-explicit-any */

type DropFirst<T extends unknown[]> = T extends [any?, ...infer U] ? U : [...T];

type Intersection<T extends unknown[]> = T['length'] extends 1
  ? T[0]
  : T[0] & Intersection<DropFirst<T>>;

type Comparator<T> = (a: T, b: T) => number;
type Comparators = Comparator<any>[];
type Operand<T> = T extends Comparator<infer U> ? U : never;
type Operands<T extends Comparators> = { [P in keyof T]: Operand<T[P]> };
type ChainedOperand<T extends Comparators> = Intersection<Operands<T>>;

// Combines a set of comparator functions into a single function that runs the
// comparators in order on the given objects until a non-zero comparison is
// found. Useful when you want to compare two objects by multiple properties.
// For example, comparing two objects by name and then by id to break ties.
export const chainComparators =
  <T extends Comparators, V extends ChainedOperand<T>>(...comparators: T) =>
  (a: V, b: V): number => {
    for (const comparator of comparators) {
      const result = comparator(a, b);

      if (result !== 0) {
        return result;
      }
    }

    // All comparators returned zero, so the objects are equal.
    return 0;
  };

/* eslint-enable @typescript-eslint/no-explicit-any */

// Sort the given list of objects by the string value found at given key. Nulls
// will always be sorted to the end as that is the default behavior of
// Intl.Collator.compare.
//
// Deprecated.
// Use createStringComparator to fully handle null values and maintain the
// ability to chain method calls. For example:
//
// const myComparator = createStringComparator('myProperty')
// const result = myArray.sort(myComparator).map(...)
export const sortString = <K extends string, T extends Record<K, string>>(
  columnId: K,
  sortAscending: boolean,
  data: T[],
): T[] =>
  data.sort((a, b) => {
    return sortAscending
      ? collator.compare(a[columnId], b[columnId])
      : collator.compare(b[columnId], a[columnId]);
  });

// Sort the given list of objects by the date value found at given key.
export const sortDate = <
  K extends string,
  T extends Record<K, string | Date | Moment>,
>(
  columnId: K,
  sortAscending: boolean,
  data: T[],
): T[] =>
  data.sort((a, b) => {
    const firstDate = moment(a[columnId]);
    const secondDate = moment(b[columnId]);

    if (firstDate.isBefore(secondDate)) {
      return sortAscending ? -1 : 1;
    }
    if (secondDate.isBefore(firstDate)) {
      return sortAscending ? 1 : -1;
    }
    return 0;
  });
