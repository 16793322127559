import cx from 'classnames';

import { toDollarString, toMoneyString } from 'utilities/currency';

import styles from './styles.module.scss';

type Props = {
  label: React.ReactNode;
  price: number;
  isBold?: boolean;
  isRefundItem?: boolean;
  pricePrefix?: '+' | '-';
};

const PriceItemEntry = ({
  label,
  price,
  isBold,
  isRefundItem,
  pricePrefix,
}: Props) => {
  return (
    <>
      <dt
        className={cx(
          styles.priceItem,
          isBold && styles.boldItem,
          isRefundItem && styles.refundItem,
          styles.priceItemLabel,
        )}
      >
        {label}
      </dt>
      <dd
        className={cx(
          styles.priceItem,
          isBold && styles.boldItem,
          isRefundItem && styles.refundItem,
          styles.priceValue,
        )}
      >
        {!!pricePrefix && <span>{pricePrefix}</span>}
        {toMoneyString(toDollarString(String(price)), true, true)}
      </dd>
    </>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default PriceItemEntry;
