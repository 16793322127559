import * as React from "react";
import { forwardRef } from "react";
const SvgDesktopIcon = ({
  title,
  titleId,
  ...props
}, ref) => {
  return /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", fill: "none", viewBox: "0 0 40 40", ref, "aria-labelledby": titleId, ...props }, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("circle", { cx: 20, cy: 20, r: 19, fill: "#fff", stroke: "currentColor", strokeWidth: 2 }), /* @__PURE__ */ React.createElement("path", { stroke: "currentColor", strokeWidth: 2, d: "M15 27h5m5 0h-5m0 0v-3m0 0h-8a1 1 0 0 1-1-1V13a1 1 0 0 1 1-1h16a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1h-8Z" }));
};
const ForwardRef = forwardRef(SvgDesktopIcon);
export default ForwardRef;
