import ShopIntegrations from 'components/settings/shop-integrations';
import Page from 'components/shared/page';
import { useCurrentShopId } from 'hooks/shops';

const ShopIntegrationsPage = () => {
  const shopId = useCurrentShopId();

  return (
    <Page name="shop-integrations" title="Point of Sale Integrations">
      <ShopIntegrations shopId={String(shopId)} />
    </Page>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default ShopIntegrationsPage;
