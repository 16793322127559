import * as React from "react";
import { forwardRef } from "react";
const SvgWarning = ({
  title,
  titleId,
  ...props
}, ref) => {
  return /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", fill: "none", viewBox: "0 0 24 24", ref, "aria-labelledby": titleId, ...props }, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { fill: "currentColor", fillRule: "nonzero", d: "M21.4775 16.2087 14.8596 4.66489C14.2713 3.63822 13.1745 3 11.9972 3c-1.1768 0-2.27325.63807-2.86235 1.66444L2.44121 16.3404c-.58836 1.0267-.58821 2.3024 0 3.3281.58866 1.0266 1.68537 1.6648 2.86254 1.6648H18.694c.0094 0 .0188 0 .0286-.0005C20.5323 21.3185 22 19.8306 22 18.0047c0-.6408-.1804-1.2598-.5224-1.7963l-.0001.0003Zm-9.4776-8.37601c1.0352 0 1.2876.7838 1.2239 1.23261l-.6211 4.8645c-.0207.44-.3602.6073-.603.6073-.2718 0-.5791-.1443-.6029-.6073l-.6212-4.8645c-.0634-.46176.1954-1.23261 1.2242-1.23261h.0001Zm.8948 9.54401c-.2334.2375-.5605.3752-.8948.3752-.3348 0-.6617-.1378-.8976-.3752-.2355-.2375-.37-.5668-.37-.9039 0-.3344.1343-.6637.37-.9012.2359-.2375.5629-.3727.8976-.3727.3343 0 .6615.1351.8948.3727.2382.2375.3726.5668.3726.9012 0 .3373-.1343.6664-.3726.9039Z" }));
};
const ForwardRef = forwardRef(SvgWarning);
export default ForwardRef;
