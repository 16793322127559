import { Controller } from 'react-hook-form';
import { CardCvcElement } from '@stripe/react-stripe-js';
import PropTypes from 'prop-types';

import {
  getStripeInputOptions,
  onStripeInputChange,
} from 'components/debit-card/form/inputs/utilities';
import FormFeedback from 'components/shared/form-feedback';
import Label from 'components/shared/label';

import styles from './styles.module.scss';

const DebitCardCvcInput = ({ clearErrors, control, cvcError, setError }) => {
  const options = getStripeInputOptions(styles.validDebitCardCvcInput);

  return (
    <>
      <Label htmlFor="debit-card-cvc">CVC</Label>
      <Controller
        control={control}
        name="cardCvc"
        rules={{
          required:
            cvcError?.message ?? "Please enter this card's security number",
        }}
        render={({ field }) => (
          <CardCvcElement
            options={options}
            id="debit-card-cvc"
            onBlur={field.onBlur}
            onChange={onStripeInputChange(
              'cardCvc',
              field,
              setError,
              clearErrors,
            )}
          />
        )}
      />
      <FormFeedback>{cvcError?.message}</FormFeedback>
    </>
  );
};

DebitCardCvcInput.propTypes = {
  clearErrors: PropTypes.func.isRequired,
  control: PropTypes.object.isRequired,
  cvcError: PropTypes.shape({
    message: PropTypes.string.isRequired,
  }),
  setError: PropTypes.func.isRequired,
};

DebitCardCvcInput.defaultProps = {
  cvcError: null,
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default DebitCardCvcInput;
