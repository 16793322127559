import { Coupon } from 'types/discounts';
import { toDollarString, toMoneyString } from 'utilities/currency';

export const getCouponAmount = (coupon: Coupon) => {
  if (coupon.pricingType === 'flat_discount') {
    return toMoneyString(toDollarString(coupon.flatDiscount));
  } else if (coupon.pricingType === 'percentage') {
    return `${coupon.percentageDiscount}%`;
  } else if (coupon.pricingType === 'bundle_price') {
    return toMoneyString(toDollarString(coupon.bundlePrice));
  }

  return '';
};
