import { Navigate, Route, Routes, useMatch } from 'react-router-dom';

import Loading from 'components/shared/loading';
import { useShopHasGoogleConnection } from 'hooks/business-profile/use-shop-has-google-connection';
import { googleBusinessProfile } from 'routes/paths';
import { Shop } from 'types/shops';

import { AccountLocations } from './account-locations';
import { Login } from './login';
import * as paths from './paths';
import { Profile } from './profile';
import { SearchCTA } from './search';
import { SearchAndClaim } from './search-and-claim';

type Props = {
  shopId: Shop['shopId'];
};

export const GoogleBusinessProfile = ({ shopId }: Props) => {
  // Redirect anyone navigating directly to /login if the shop's Google account was already connected.
  const {
    hasGoogleConnection,
    requiresReauthorization,
    isLoading: isGoogleConnectionLoading,
  } = useShopHasGoogleConnection(shopId);
  const atLoginPath = useMatch(
    `${googleBusinessProfile(shopId)}/${paths.login}`,
  );
  const requiresLoginRedirect =
    !atLoginPath &&
    !isGoogleConnectionLoading &&
    (!hasGoogleConnection || requiresReauthorization);

  if (requiresLoginRedirect) {
    return <Navigate to={paths.login} replace />;
  }

  if (isGoogleConnectionLoading) {
    return <Loading />;
  }

  return (
    <Routes>
      <Route path="/" element={<Profile shopId={shopId} />} />
      <Route
        path={paths.accountLocations}
        element={<AccountLocations shopId={shopId} />}
      />
      <Route path={paths.login} element={<Login shopId={shopId} />} />
      <Route
        path={paths.searchAndClaim}
        element={<SearchAndClaim shopId={shopId} />}
      />
      <Route path={paths.searchCTA} element={<SearchCTA shopId={shopId} />} />
    </Routes>
  );
};
