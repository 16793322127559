import { useState } from 'react';

import { Button, Link } from 'crust';

import Header from 'components/shared/header';
import useAnalytics from 'hooks/use-analytics';
import { Shop } from 'types/shops';
import { User } from 'types/user';
import {
  WebsiteIntegrationsAnalyticsPageName,
  WebsiteIntegrationsSettings,
} from 'types/website-tools';
import { getSmartPopupsTechnicalHelpEmailDetails } from 'utilities/website-tools';

import GetCodeModal from './get-code-modal';
import SmartPopupConfiguration from './popup-configuration';

import styles from './styles.module.scss';

type Props = {
  shop: Shop;
  smartPopupSettings: WebsiteIntegrationsSettings;
  isMultiLocEnabled: boolean;
  user: User;
};

const SmartPopups = ({
  shop,
  smartPopupSettings,
  isMultiLocEnabled,
  user,
}: Props) => {
  const page = isMultiLocEnabled
    ? WebsiteIntegrationsAnalyticsPageName.SmartPopupsMultiloc
    : WebsiteIntegrationsAnalyticsPageName.SmartPopups;
  const {
    trackViewedWebsiteIntegrationsCodeDocumentationModal,
    trackClickedTechnicalHelpEmailLink,
  } = useAnalytics();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { toAddress, subject, body } = getSmartPopupsTechnicalHelpEmailDetails(
    shop.shopId,
  );
  const onSave = () => {
    setIsModalOpen(true);
    trackViewedWebsiteIntegrationsCodeDocumentationModal({
      shopId: String(shop.shopId),
      source: 'save changes CTA',
      page,
    });
  };

  const handleGetCodeClick = () => {
    setIsModalOpen(true);
    trackViewedWebsiteIntegrationsCodeDocumentationModal({
      shopId: String(shop.shopId),
      source: 'get code and documentation link',
      page,
    });
  };

  const handleGetTechnicalHelpClick = () => {
    trackClickedTechnicalHelpEmailLink({
      shopId: String(shop.shopId),
      destination: toAddress,
      subject,
      page,
    });
  };

  return (
    <>
      <Header
        content={[
          `Make your web page pop! With a Smart Popup, let your customers know that online ordering is preferred.`,
        ]}
        contentClass={styles.headerContent}
        title="Promote Your Online Ordering"
      />
      <SmartPopupConfiguration
        onSave={onSave}
        shop={shop}
        smartPopupSettings={smartPopupSettings}
        page={page}
        isMultiLocEnabled={isMultiLocEnabled}
        user={user}
      />
      {!isMultiLocEnabled && (
        <>
          <div className={styles.helpLinksContainer}>
            <Button
              appearance="link"
              data-chameleon-target="Smart Popups Get Code Modal"
              icon="website"
              onPress={handleGetCodeClick}
              variant="secondary"
            >
              Get code and documentation
            </Button>
            <Link
              href={`mailto:${toAddress}?subject=${subject}&body=${body}`}
              icon="help"
              onPress={handleGetTechnicalHelpClick}
              variant="secondary"
            >
              Need technical help?
            </Link>
          </div>
          <GetCodeModal
            shopId={shop.shopId}
            shopUuid={shop.uuid}
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            shopCity={shop.city}
            shopName={shop.name}
          />
        </>
      )}
    </>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default SmartPopups;
