import { useMatch } from 'react-router-dom';

import SocialMediaTab from 'components/asset-library/social-media';
import NavigationTabs from 'components/shared/navigation-tabs';
import Page from 'components/shared/page';
import { useCurrentShopId } from 'hooks/shops';
import * as paths from 'routes/paths';

import styles from './styles.module.scss';

const AssetLibraryPage = () => {
  const shopId = useCurrentShopId();

  const assetLibraryPath = paths.assetLibrary(shopId);
  const match = useMatch(assetLibraryPath);

  return (
    <Page
      name="Branding Studio"
      title="Branding Studio"
      contentClassName={styles.content}
    >
      <NavigationTabs className={styles.subNav}>
        <NavigationTabs.Link to={assetLibraryPath}>
          Social Media
        </NavigationTabs.Link>
      </NavigationTabs>
      {match && <SocialMediaTab shopId={String(shopId)} />}
    </Page>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default AssetLibraryPage;
