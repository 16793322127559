import { useEffect, useMemo, useState } from 'react';
import {
  CalendarDate,
  now,
  parseAbsolute,
  parseDate,
  today,
} from '@internationalized/date';
import { z } from 'zod';

import { PresetRangeState } from 'crust';

import RecommendedCallout from 'components/dashboard/recommended-callout';
import OrderStatementsTile from 'components/order-statements-tile';
import RecentOrderDashboardTile from 'components/orders/dashboard-tile';
import { RegisterUpsellModal } from 'components/register/register-splash/register-upsell-modal';
import { useAchOnboardingLink } from 'hooks/ach-enrollment-banner/use-ach-enrollment-link';
import { useRegisterUpsellModal } from 'hooks/salesforce/use-register-upsell-modal';
import { Shop, ShopTraits } from 'types/shops';
import { User } from 'types/user';
import {
  isFromLast24Hours,
  toEndOfDateAbsoluteString,
  toStartOfDateAbsoluteString,
} from 'utilities/date-time';
import { getDefaultDateRangePresets } from 'utilities/shared/date-range-presets';

import { MetricsPanel } from './metrics-panel';
import { OrdersTile } from './orders-tile';
import { DashboardSubheader } from './subheader';

import styles from './styles.module.scss';

const localStorageId = 'dashboard';

const getStoredValue = () => {
  const item = localStorage.getItem(localStorageId);

  if (item) {
    try {
      return JSON.parse(item);
    } catch (error) {
      // Do nothing.
    }
  }
};

const setStoredValue = (dates: unknown) => {
  try {
    localStorage.setItem(
      localStorageId,
      JSON.stringify({
        lastUpdatedAt: now('UTC').toAbsoluteString(),
        dates,
      }),
    );
  } catch {
    // Do nothing.
  }
};

type SchemaContext = {
  maxValue: CalendarDate;
  presets: ReturnType<typeof getDefaultDateRangePresets>;
};

const getSchema = ({ maxValue, presets }: SchemaContext) => {
  const date = z
    .string()
    .date()
    .transform((it) => parseDate(it));
  return z.object({
    lastUpdatedAt: z
      .string()
      .datetime()
      .transform((it) => parseAbsolute(it, 'UTC'))
      .refine((it) => isFromLast24Hours(it)),
    dates: z
      .object({
        id: z.string(),
        label: z.string(),
        start: date,
        end: date,
      })
      .transform((value, ctx) => {
        const id = value.id;
        const preset = presets.find((it) => it.id === id);

        if (preset) {
          return preset;
        }

        if (
          value.end.compare(value.start) >= 0 &&
          value.end.compare(value.start) < 32 &&
          value.end.compare(maxValue) <= 0
        ) {
          return {
            id: 'custom',
            label: 'Custom',
            start: value.start,
            end: value.end,
          } as const;
        }

        // The message is unimportant here.
        ctx.addIssue({ code: z.ZodIssueCode.custom });

        return z.NEVER;
      }),
  });
};

const getDateContext = (timezone: string) =>
  ({
    timezone,
    maxValue: today(timezone),
    presets: getDefaultDateRangePresets(timezone),
  }) as const;

type Props = {
  isHomepageCalloutRecommendationEnabled: boolean;
  shop: Shop;
  traits: ShopTraits;
  user: User;
};

export const Dashboard = ({
  isHomepageCalloutRecommendationEnabled,
  shop,
  traits,
  user,
}: Props) => {
  const { data: achOnboardingLink } = useAchOnboardingLink(shop.shopId);

  const {
    handleModalClose,
    handleOpenModalClick,
    hasValidCase,
    openedModal,
    setCaseExpiration,
  } = useRegisterUpsellModal(shop.shopId);

  const [dateContext, setDateContext] = useState(() =>
    getDateContext(shop.timezoneIdentifier),
  );

  useEffect(() => {
    if (shop.timezoneIdentifier !== dateContext.timezone) {
      setDateContext(getDateContext(shop.timezoneIdentifier));
    }
  }, [dateContext.timezone, shop.timezoneIdentifier]);

  const { presets, maxValue } = dateContext;

  const [dates, setDates] = useState<
    NonNullable<PresetRangeState<typeof presets>>
  >(() => {
    const stored = getStoredValue();

    if (stored) {
      const schema = getSchema({ maxValue, presets });
      const result = schema.safeParse(stored);
      if (result.success) {
        return result.data.dates;
      }
    }
    return presets[2];
  });

  useEffect(() => {
    setStoredValue({
      ...dates,
      start: dates.start.toString(),
      end: dates.end.toString(),
    });
  }, [dates]);

  const diffInDays = dates.end.compare(dates.start);
  const prevStartDate = toStartOfDateAbsoluteString(
    dates.start.subtract({ days: diffInDays }),
    shop.timezoneIdentifier,
  );
  const prevEndDate = toEndOfDateAbsoluteString(
    dates.end.subtract({ days: diffInDays }),
    shop.timezoneIdentifier,
  );

  const activeDates = useMemo(
    () => ({
      current: {
        startDate: toStartOfDateAbsoluteString(
          dates.start,
          shop.timezoneIdentifier,
        ),
        endDate: toEndOfDateAbsoluteString(dates.end, shop.timezoneIdentifier),
      },
      previous: {
        startDate: prevStartDate,
        endDate: prevEndDate,
      },
    }),
    [
      dates.start,
      dates.end,
      shop.timezoneIdentifier,
      prevStartDate,
      prevEndDate,
    ],
  );

  return (
    <>
      <DashboardSubheader
        isAchOnboardingLink={
          achOnboardingLink ? achOnboardingLink.showOnboardingLink : false
        }
        shop={shop}
        defaultValue={dates}
        maxValue={maxValue}
        onChange={setDates}
        presets={presets}
        redirectUrl={achOnboardingLink ? achOnboardingLink.redirectUrl : ''}
      />
      <MetricsPanel
        activeDates={activeDates}
        shopId={shop.shopId}
        shopTimezone={shop.timezoneIdentifier}
      />
      <div className={styles.container}>
        <OrdersTile activeDates={activeDates} shop={shop} />
        {isHomepageCalloutRecommendationEnabled && (
          <RecommendedCallout
            hasValidCase={hasValidCase}
            onRequestDemoButtonClick={handleOpenModalClick}
            shop={shop}
            traits={traits}
            user={user}
          />
        )}
      </div>
      <RecentOrderDashboardTile
        shopId={String(shop.shopId)}
        shopTimezone={shop.timezoneIdentifier}
      />
      <OrderStatementsTile shopId={shop.shopId} />
      <RegisterUpsellModal
        isOpen={openedModal}
        onRequestClose={handleModalClose}
        page={'home'}
        setCaseExpiration={setCaseExpiration}
        shopId={shop.shopId}
        user={user}
      />
    </>
  );
};
