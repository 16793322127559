import * as React from "react";
import { forwardRef } from "react";
import { useId } from "react-aria";
const SvgRepeatCustomerIconNoOutline = ({
  title,
  titleId,
  ...props
}, ref) => {
  const id0 = useId();
  return /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", fill: "none", viewBox: "0 0 24 24", ref, "aria-labelledby": titleId, ...props }, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { fill: "#181218", fillOpacity: 0.6, d: "m9 12 4 4-4 4v-8Z" }), /* @__PURE__ */ React.createElement("mask", { id: id0, width: 22, height: 13, x: 1, y: 6, maskUnits: "userSpaceOnUse", style: {
    maskType: "alpha"
  } }, /* @__PURE__ */ React.createElement("path", { fill: "#fff", d: "M12 19v-5h4v5h7V6H1v13h11Z" })), /* @__PURE__ */ React.createElement("g", { mask: `url(#${id0})` }, /* @__PURE__ */ React.createElement("path", { stroke: "#211E1E", strokeWidth: 2, d: "M21 12c0 .7979-.6728 1.7894-2.3761 2.6411C16.9803 15.4629 14.6398 16 12 16c-2.63984 0-4.98033-.5371-6.62385-1.3589C3.67281 13.7894 3 12.7979 3 12c0-.7979.67281-1.7894 2.37615-2.64111C7.01967 8.53713 9.36016 8 12 8c2.6398 0 4.9803.53713 6.6239 1.35889C20.3272 10.2106 21 11.2021 21 12Z" })));
};
const ForwardRef = forwardRef(SvgRepeatCustomerIconNoOutline);
export default ForwardRef;
