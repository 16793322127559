import { useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';

import { getDailyHoursQueryKey } from 'hooks/daily-hours/use-daily-hours';
import {
  getOpenForTodayQueryKey,
  getPausingsQueryKey,
  useGenerateScheduleMutation,
  useOpenForTodayQuery,
  useOpenForTodaySelectors,
  useOpeningsQuery,
  usePausingsQuery,
} from 'hooks/hours';
import { useShopQuery } from 'hooks/shops';
import useApi from 'hooks/use-api';
import { databaseRecordSource } from 'utilities/constants';
import { nowDateTime } from 'utilities/date-time';
import { showUnexpectedErrorToast } from 'utilities/forms';
import { getShopShippingStatus } from 'utilities/shop-hours-utils';

import CloseShopModal from './close-shop-modal';
import { modalOptions, openForShippingTypePayloads } from './constants';
import CurrentDateTime from './current-date-time';
import ExtendPauseModal from './extend-pause-modal';
import PauseOrdersModal from './pause-orders-modal';
import ShopOpeningStatusTile from './shop-opening-status-tile';

import styles from './styles.module.scss';

const HoursHeader = ({ shopId }) => {
  const [activeModal, setActiveModal] = useState(null);
  const [shippingType, setShippingType] = useState(null);
  const [closingReasonById, setClosingReasonById] = useState(null);
  const [pausingReasonById, setPausingReasonById] = useState(null);

  const api = useApi();
  const queryClient = useQueryClient();

  const { data: shop } = useShopQuery(shopId);
  const shopTimezone = shop?.timezoneIdentifier ?? moment.tz.guess();

  const { data: shopOpenForTodayData, isLoading: isShopOpenForTodayLoading } =
    useOpenForTodayQuery(shopId);

  const openForTodaySelectors = useOpenForTodaySelectors(
    shopOpenForTodayData,
    isShopOpenForTodayLoading,
  );
  const {
    isAcceptingOrders,
    isClosedForDelivery,
    isClosedForPickup,
    isRegularScheduleDeliveryOpen,
    isRegularSchedulePickupOpen,
    nextOpenAt,
  } = openForTodaySelectors;

  const { data: openings } = useOpeningsQuery(shopId);

  const { data: pausings, isLoading: isShopPausingsLoading } =
    usePausingsQuery(shopId);

  const { mutate: generateSchedule } = useGenerateScheduleMutation({
    openings,
    shopId,
    shopTimezone,
  });

  const updateOpenForTodaySuccess = (_event, response) => {
    queryClient.setQueryData(getOpenForTodayQueryKey(shopId), response);
    // When we open or close we invalidate the daily hours so that we get the latest today and tomorrow times
    queryClient.invalidateQueries(getDailyHoursQueryKey(shopId));
    queryClient.invalidateQueries(getPausingsQueryKey(shopId));

    generateSchedule();
  };

  const updateOpenForTodayError = () => () => {
    showUnexpectedErrorToast();
  };

  const { mutate: handleAcceptOrders, isLoading: isUpdatingAcceptedOrders } =
    useMutation(
      () => {
        return api.updateShopOpenForToday(shopId, {
          open_for_today: { delivery: true, pickup: true },
        });
      },
      {
        onError: updateOpenForTodayError('accept orders'),
        onSuccess: ({ response }) =>
          updateOpenForTodaySuccess('accept orders', response),
        onSettled: () => handleOnCloseModal(),
      },
    );

  const { mutate: handleCloseShop, isLoading: isClosingShop } = useMutation(
    () => {
      const payload = openForShippingTypePayloads[shippingType];

      return api.updateShopOpenForToday(shopId, {
        open_for_today: payload,
        closure_reason_id: closingReasonById,
        source: databaseRecordSource,
      });
    },
    {
      onError: updateOpenForTodayError('pause orders'),
      onSuccess: ({ response }) =>
        updateOpenForTodaySuccess('pause orders', response),
      onSettled: () => handleOnCloseModal(),
    },
  );

  const pauseOrdersSuccess = (newPause) => {
    queryClient.setQueryData(getPausingsQueryKey(shopId), [newPause]);
    queryClient.invalidateQueries(getOpenForTodayQueryKey(shopId));

    generateSchedule();
  };

  const { mutate: handlePauseOrders, isLoading: isPausingOrders } = useMutation(
    ({ startTime, endTime }) => {
      return api.createPause(shopId, {
        startTime,
        endTime,
        shippingType,
        source: databaseRecordSource,
        closureReasonId: pausingReasonById,
      });
    },
    {
      onError: () => showUnexpectedErrorToast(),
      onSuccess: (data, { callback }) => {
        pauseOrdersSuccess(data);
        callback();
      },
      onSettled: () => handleOnCloseModal(),
    },
  );

  const handleExtendPauseSuccess = (updatedPause) => {
    queryClient.setQueryData(getPausingsQueryKey(shopId), [updatedPause]);
    queryClient.invalidateQueries(getOpenForTodayQueryKey(shopId));

    generateSchedule();
  };

  const { mutate: handleExtendPause, isLoading: isExtendingPause } =
    useMutation(
      ({ updatedEndTime }) => {
        return api.updatePause(shopId, pausings?.[0].id, updatedEndTime);
      },
      {
        onError: () => showUnexpectedErrorToast(),
        onSuccess: (data, { callback }) => {
          handleExtendPauseSuccess(data);
          callback();
        },
        onSettled: () => handleOnCloseModal(),
      },
    );

  const handleDeletePauseSuccess = () => {
    queryClient.setQueryData(getPausingsQueryKey(shopId), []);
    queryClient.invalidateQueries(getOpenForTodayQueryKey(shopId));

    generateSchedule();
  };

  const { mutate: handleDeletePause, isLoading: isDeletingPause } = useMutation(
    (pauseId) => {
      return api.deletePause(shopId, pauseId);
    },
    {
      onError: () => showUnexpectedErrorToast(),
    },
  );

  const openFromPause = (pauseId) =>
    handleDeletePause(pauseId, {
      onSuccess: () => handleDeletePauseSuccess(),
      onSettled: () => handleOnCloseModal(),
    });

  const replaceExistingPause = ({ startTime, endTime, callback }) =>
    handleDeletePause(pausings?.[0].id, {
      onSuccess: () => {
        handlePauseOrders({
          startTime,
          endTime,
          callback,
        });
      },
    });

  const nextOpenAtMoment = moment.tz(nextOpenAt, shopTimezone);

  const closedForTodayString = nowDateTime(shopTimezone).format('dddd, MMMM D');
  const nextOpenOnDateString = nextOpenAtMoment.format('dddd, MMMM D');

  const handleOnCloseModal = () => {
    setActiveModal(null);
    setShippingType(null);
    setClosingReasonById(null);
    setPausingReasonById(null);
  };

  const handleOpenCloseModal = () => {
    setActiveModal(modalOptions.closeShippingType);
  };

  const handleOpenPauseModal = () => {
    setActiveModal(modalOptions.pauseShippingType);
  };

  const handleOpenExtendPauseModal = () => {
    setActiveModal(modalOptions.extendPauseShippingType);
  };

  const handleClickOpen = (openingFromPaused = false) => {
    if (openingFromPaused) {
      openFromPause(pausings?.[0].id);
    } else if (isClosedForDelivery || isClosedForPickup) {
      handleAcceptOrders();
    }
  };

  const handleClickClose = () => {
    if (isAcceptingOrders) {
      handleOpenCloseModal();
    }
  };

  const handleClickPause = () => {
    handleOpenPauseModal();
  };

  const handleClickExtend = () => {
    handleOpenExtendPauseModal();
  };

  return (
    <div className={styles.currentInfoWrapperWithPause}>
      <CurrentDateTime shopTimezone={shopTimezone} />
      <ShopOpeningStatusTile
        areOptionsDisabled={
          isClosingShop ||
          isPausingOrders ||
          isUpdatingAcceptedOrders ||
          isExtendingPause ||
          isDeletingPause
        }
        className={styles.openingStatusTile}
        isLoading={isShopOpenForTodayLoading || isShopPausingsLoading}
        onClickClose={handleClickClose}
        onClickExtend={handleClickExtend}
        onClickOpen={handleClickOpen}
        onClickPause={handleClickPause}
        shopId={shopId}
        shopOpeningStatus={getShopShippingStatus({
          openForTodaySelectors,
          shopTimezone,
        })}
        shopTimezone={shopTimezone}
      />
      <CloseShopModal
        canCloseForShippingTypes={{
          canCloseForDeliveryAndPickup:
            isRegularScheduleDeliveryOpen && isRegularSchedulePickupOpen,
          canCloseForDelivery:
            isRegularScheduleDeliveryOpen && !isClosedForDelivery,
          canCloseForPickup: isRegularSchedulePickupOpen && !isClosedForPickup,
        }}
        closedForTodayDate={closedForTodayString}
        isOpen={activeModal === modalOptions.closeShippingType}
        nextOpenAtDate={nextOpenOnDateString}
        onClose={handleOnCloseModal}
        onSubmit={handleCloseShop}
        selectedClosingReasonId={closingReasonById}
        selectedShippingType={shippingType}
        setSelectedReasonId={setClosingReasonById}
        setShippingType={setShippingType}
        shopId={shopId}
      />
      <PauseOrdersModal
        canPauseForShippingTypes={{
          canPauseForDeliveryAndPickup:
            isRegularScheduleDeliveryOpen &&
            isRegularSchedulePickupOpen &&
            pausings?.[0]?.shippingType !== 'delivery_and_pickup' &&
            !isClosedForDelivery &&
            !isClosedForPickup,
          canPauseForDelivery:
            isRegularScheduleDeliveryOpen &&
            !isClosedForDelivery &&
            pausings?.[0]?.shippingType !== 'delivery',
          canPauseForPickup:
            isRegularSchedulePickupOpen &&
            !isClosedForPickup &&
            pausings?.[0]?.shippingType !== 'pickup',
        }}
        closedForTodayDate={closedForTodayString}
        isOpen={activeModal === modalOptions.pauseShippingType}
        onClose={handleOnCloseModal}
        onSubmit={pausings?.[0] ? replaceExistingPause : handlePauseOrders}
        selectedPausingReasonId={pausingReasonById}
        selectedShippingType={shippingType}
        setSelectedReasonId={setPausingReasonById}
        setShippingType={setShippingType}
        shopId={shopId}
        shopTimezone={shopTimezone}
      />
      {pausings?.[0] && (
        <ExtendPauseModal
          existingPause={pausings[0]}
          isOpen={activeModal === modalOptions.extendPauseShippingType}
          onClose={handleOnCloseModal}
          onSubmit={handleExtendPause}
          shopId={shopId}
          shopTimezone={shopTimezone}
        />
      )}
    </div>
  );
};

HoursHeader.propTypes = {
  shopId: PropTypes.string.isRequired,
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default HoursHeader;
