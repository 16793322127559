import { Controller, useWatch } from 'react-hook-form';
import PropTypes from 'prop-types';

import FormFeedback from 'components/shared/form-feedback';
import Input from 'components/shared/input';
import Label from 'components/shared/label';
import { RHFCheckbox } from 'components/shared/rhf-checkbox';
import Select from 'components/shared/slice-select';
import useValidationErrorTracking, {
  ValidationErrorLocation,
  ValidationInputId,
} from 'hooks/use-validation-error-tracking.ts';
import {
  AnalyticsField,
  CustomizationField,
  getCustomizationFieldName,
  ProductField,
} from 'utilities/menu';

import styles from './styles.module.scss';

export const limitOptions = [
  { value: 0, label: 'No Limit' },
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4' },
  { value: 5, label: '5' },
  { value: 6, label: '6' },
  { value: 7, label: '7' },
  { value: 8, label: '8' },
  { value: 9, label: '9' },
  { value: 10, label: '10' },
];

const CustomizationInputs = ({
  control,
  customizationIndex,
  getValues,
  length,
  nameError,
  register,
  setValue,
  shopId,
  submitCount,
}) => {
  const isByHalf = useWatch({
    control,
    name: getCustomizationFieldName(
      CustomizationField.IsByHalf,
      customizationIndex,
    ),
  });

  useValidationErrorTracking(
    shopId,
    ValidationErrorLocation.MenuProductEditPage,
    ValidationInputId.ProductCustomizationName,
    nameError,
    submitCount,
  );

  // A product can have only one "by half" customization, and the backend
  // automatically names the customization "Choose Toppings". Thus, any "by
  // half" customization should not allow name editing.
  const isNameReadOnly = isByHalf;

  const nameInputId = `customization-${customizationIndex}-name-input`;
  const limitSelectId = `customization-${customizationIndex}-limit-select`;

  const validateNameInput = (value) => {
    const customizations = getValues(ProductField.Customizations);

    if (
      customizations.some(
        (c, i) => i !== customizationIndex && c.name === value,
      )
    ) {
      return 'Names must be unique.';
    }

    return true;
  };

  return (
    <div className={styles.container}>
      <Label className={styles.nameLabel} htmlFor={nameInputId}>
        Name
      </Label>
      <Input
        aria-required="true"
        className={styles.nameInput}
        id={nameInputId}
        isInvalid={nameError != null}
        readOnly={isNameReadOnly}
        {...register(
          getCustomizationFieldName(
            CustomizationField.Name,
            customizationIndex,
          ),
          {
            deps: [...Array(length).keys()]
              .filter((it) => it !== customizationIndex)
              .map((it) =>
                getCustomizationFieldName(CustomizationField.Name, it),
              ),
            maxLength: {
              message: 'Please enter no more than 500 characters.',
              value: 500,
            },
            onChange: () =>
              setValue(AnalyticsField.CustomizationNameChanged, true),
            required: 'Please enter a name.',
            validate: validateNameInput,
          },
        )}
      />
      <FormFeedback className={styles.nameError}>
        {nameError?.message}
      </FormFeedback>
      <Label className={styles.limitLabel} htmlFor={limitSelectId}>
        Limit Choices
      </Label>
      <Controller
        control={control}
        name={getCustomizationFieldName(
          CustomizationField.Limit,
          customizationIndex,
        )}
        rules={{
          onChange: () =>
            setValue(AnalyticsField.CustomizationLimitChanged, true),
        }}
        render={({ field }) => (
          <Select
            className={styles.limitSelect}
            inputId={limitSelectId}
            name={field.name}
            onBlur={field.onBlur}
            onChange={(option) => field.onChange(option.value)}
            options={limitOptions}
            ref={field.ref}
            value={limitOptions[field.value]}
          />
        )}
      />
      <RHFCheckbox
        control={control}
        name={getCustomizationFieldName(
          CustomizationField.IsRequired,
          customizationIndex,
        )}
        className={styles.isRequired}
        label="Required?"
        rules={{
          onChange: () =>
            setValue(AnalyticsField.CustomizationRequiredChanged, true),
          setValueAs: (value) => value === 'true',
        }}
      />
      <input
        type="hidden"
        {...register(
          getCustomizationFieldName(
            CustomizationField.IsByHalf,
            customizationIndex,
          ),
        )}
      />
      <input
        type="hidden"
        {...register(
          getCustomizationFieldName(
            CustomizationField.IsByType,
            customizationIndex,
          ),
        )}
      />
    </div>
  );
};

CustomizationInputs.propTypes = {
  control: PropTypes.object.isRequired,
  customizationIndex: PropTypes.number.isRequired,
  getValues: PropTypes.func.isRequired,
  length: PropTypes.number.isRequired,
  nameError: PropTypes.shape({
    message: PropTypes.string.isRequired,
  }),
  register: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  shopId: PropTypes.string.isRequired,
  submitCount: PropTypes.number.isRequired,
};

CustomizationInputs.defaultProps = {
  nameError: null,
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default CustomizationInputs;
