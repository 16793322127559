import { ReactNode } from 'react';
import { Button, DialogTrigger } from 'react-aria-components';

import { Icon } from '../icon/icon';
import { Popover } from '../popover/popover';
import { getCrustClassName } from '../utilities/get-crust-class-name';

import './hint.css';

export type HintProps = {
  children: ReactNode;
  label: string;
};

const getClassName = getCrustClassName.bind(null, 'hint');

export const Hint = ({ children, label = 'information' }: HintProps) => (
  // We don't use IconButton here because a Hint is inline content. Padding and
  // hover styles don't apply.
  <DialogTrigger>
    <Button aria-label={label} className={getClassName('button')}>
      <Icon className={getClassName('icon')} icon="info" />
    </Button>
    <Popover offset={4}>{children}</Popover>
  </DialogTrigger>
);
