import { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Button } from 'crust';

import ContentTile from 'components/shared/content-tile';
import Loading from 'components/shared/loading';
import { RHFRadio, RHFRadioGroup } from 'components/shared/rhf-radio';
import { useBusinessProfileAccountLocationsQuery } from 'hooks/business-profile/use-business-profile-account-locations-query';
import { useCreateBusinessProfileMutation } from 'hooks/business-profile/use-create-business-profile-mutation';
import { useShopQuery } from 'hooks/shops';
import useAnalytics from 'hooks/use-analytics';
import { pageNames } from 'providers/analytics/events';
import { Shop } from 'types/shops';
import { getShopHasFullRegister } from 'utilities/shops';

import { searchCTA } from '../paths';

import styles from './styles.module.scss';

type Props = {
  shopId: Shop['shopId'];
};

export const AccountLocations = ({ shopId }: Props) => {
  const {
    data: accountLocationsData,
    isLoading: isAccountLocationsLoading,
    isSuccess: isAccountLocationsSuccess,
  } = useBusinessProfileAccountLocationsQuery(shopId);
  const { mutate: createBusinessProfile } = useCreateBusinessProfileMutation();
  const { data: shop, isSuccess: isShopSuccess } = useShopQuery(shopId);
  const {
    trackBusinessProfileViewedLocationSelect,
    trackBusinessProfileSelectedAccountLocation,
    trackBusinessProfileConfirmedAccountLocation,
    trackDisplayedError,
  } = useAnalytics();
  const hasSentTrackingEventRef = useRef(false);
  const isShopRegister =
    (isShopSuccess && getShopHasFullRegister(shop)) ?? false;

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    watch,
  } = useForm({
    defaultValues: {
      location: '',
    },
    shouldUnregister: true,
  });

  const navigate = useNavigate();

  const watchedLocation = watch('location');
  useEffect(() => {
    if (watchedLocation && isAccountLocationsSuccess) {
      const location = accountLocationsData.locations.find(
        (location) => location.name === watchedLocation,
      );
      if (!location) {
        return;
      }
      trackBusinessProfileSelectedAccountLocation({
        shopId,
        isRegister: isShopRegister,
        selection: location.title,
        isVerified: location.metadata.hasVoiceOfMerchant || false,
      });
    }
  }, [
    watchedLocation,
    trackBusinessProfileSelectedAccountLocation,
    shopId,
    isShopRegister,
    isAccountLocationsSuccess,
    accountLocationsData,
  ]);

  useEffect(() => {
    if (isAccountLocationsSuccess && !hasSentTrackingEventRef.current) {
      const locations = isAccountLocationsSuccess
        ? accountLocationsData.locations
        : [];
      trackBusinessProfileViewedLocationSelect({
        shopId,
        isRegister: isShopRegister,
        locationsCount: locations.length,
        locations: locations.map((location) => location.title),
      });
      hasSentTrackingEventRef.current = true;
    }
  }, [
    trackBusinessProfileViewedLocationSelect,
    isAccountLocationsSuccess,
    accountLocationsData,
    isShopRegister,
    shopId,
  ]);

  const handleValidSubmit = (values: { location: string }) => {
    if (values.location === 'none') {
      navigate(`../${searchCTA}`, { relative: 'path' });
    } else {
      const location =
        isAccountLocationsSuccess &&
        accountLocationsData.locations.find(
          (location) => location.name === watchedLocation,
        );
      if (location) {
        trackBusinessProfileConfirmedAccountLocation({
          shopId,
          isRegister: isShopRegister,
          selection: location.title,
          isVerified: location.metadata.hasVoiceOfMerchant || false,
        });
      }

      createBusinessProfile(
        {
          shopId: shopId,
          googleLocationId: values.location,
        },
        {
          onSuccess() {
            navigate('..', { relative: 'path' });
          },
          onError() {
            const errorMessage =
              "We weren't able to connect your Google Business location at this time. Please try again or contact your Partner Success Manager.";
            toast.error(errorMessage);
            trackDisplayedError({
              shopId,
              isRegister: isShopRegister,
              errorText: errorMessage,
              page: pageNames.businessProfile,
            });
          },
        },
      );
    }
  };

  return (
    <ContentTile>
      {isAccountLocationsLoading ? (
        <Loading />
      ) : isAccountLocationsSuccess ? (
        accountLocationsData.locations.length === 0 ? (
          <>
            <p className={styles.header}>No Google Business Locations Found</p>
            <p className={styles.subheader}>
              {
                'We couldn’t find any Google Business locations already belonging to your account.'
              }
            </p>
            <Button
              variant="primary"
              onPress={() => {
                navigate(`../${searchCTA}`, { relative: 'path' });
              }}
            >
              Claim Your Business
            </Button>
          </>
        ) : (
          <>
            <p className={styles.header}>Confirm your business</p>
            <p className={styles.subheader}>
              We’ve recovered multiple locations for your business. Please
              confirm below
            </p>
            <form onSubmit={handleSubmit(handleValidSubmit)}>
              <RHFRadioGroup
                aria-label="google-locations"
                className={styles.radioGroup}
                control={control}
                name="location"
                orientation="horizontal"
                variant="card"
              >
                {accountLocationsData.locations.map((location) => (
                  <RHFRadio
                    value={location.name}
                    key={location.name}
                    label={location.title}
                    description={
                      <>
                        {location.storefrontAddress ? (
                          <>
                            {location.storefrontAddress.addressLines}{' '}
                            {location.storefrontAddress.locality},{' '}
                            {location.storefrontAddress.postalCode}{' '}
                            {location.storefrontAddress.regionCode}
                            <br />
                          </>
                        ) : (
                          ''
                        )}
                        {location.websiteUri}
                      </>
                    }
                  />
                ))}
                <RHFRadio
                  value={'none'}
                  label="None of these look correct, I want to set up a new location."
                />
              </RHFRadioGroup>
              <Button variant="primary" type="submit" isDisabled={isSubmitting}>
                Confirm Business
              </Button>
            </form>
          </>
        )
      ) : (
        <div className={styles.header}>
          Something went wrong retrieving your Google Account Locations. Please
          try again or contact your Partner Success Manager.
        </div>
      )}
    </ContentTile>
  );
};
