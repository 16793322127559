import { arrayMove } from '@dnd-kit/sortable';
import { v4 as uuidv4 } from 'uuid';

import { RegisterLayout } from 'types/register-layout';
import {
  chainComparators,
  createIntegerComparator,
  createStringComparator,
} from 'utilities/sorting';

const columnComparator = createIntegerComparator('column');
const positionComparator = createIntegerComparator('position');
const nameComparator = createStringComparator('name');

// We sort the layout categories and items into a flat list that will be
// rendered by a CSS Grid from left to right, top to bottom. In other words, we
// need to sort by row (position) and then by column, with null coordinates
// being distributed by name at the end.
const layoutComparator = chainComparators(
  positionComparator,
  columnComparator,
  nameComparator,
);

export const getItemsWord = (count: number): string =>
  count > 1 ? 'items' : 'item';

export const createSortableLayout = (
  layout?: RegisterLayout,
): RegisterLayout => ({
  id: layout?.id ?? uuidv4(),
  maxColumns: layout?.maxColumns ?? 1,
  maxRows: layout?.maxRows ?? 1,
  categories:
    layout?.categories
      .filter((it) => it.items.length > 0)
      .sort(layoutComparator)
      .map((category, categoryPosition) => ({
        ...category,
        column: 0,
        position: categoryPosition,
        items: category.items
          .slice()
          .sort(layoutComparator)
          .map((item, itemPosition) => ({
            ...item,
            column: 0,
            position: itemPosition,
          })),
      })) ?? [],
});

const createRevisionFromCategorySort = (
  current: RegisterLayout,
  fromIndex: number,
  toIndex: number,
): RegisterLayout => ({
  ...current,
  categories: arrayMove(current.categories, fromIndex, toIndex).map(
    (category, position) => ({ ...category, position }),
  ),
});

const createRevisionFromItemSort = (
  current: RegisterLayout,
  fromIndex: number,
  toIndex: number,
  categoryId: number,
): RegisterLayout => ({
  ...current,
  categories: current.categories.map((category) => {
    if (category.id === categoryId) {
      return {
        ...category,
        items: arrayMove(category.items, fromIndex, toIndex).map(
          (item, position) => ({
            ...item,
            position,
          }),
        ),
      };
    }

    return category;
  }),
});

export const createRevisionFromSort = (
  current: RegisterLayout,
  fromIndex: number,
  toIndex: number,
  categoryId?: number,
): RegisterLayout =>
  categoryId == null
    ? createRevisionFromCategorySort(current, fromIndex, toIndex)
    : createRevisionFromItemSort(current, fromIndex, toIndex, categoryId);

export const createRevisionFromCategoryColorChange = (
  current: RegisterLayout,
  colorHex: string,
  id: number,
): RegisterLayout => ({
  ...current,
  categories: current.categories.map((it) =>
    it.id === id ? { ...it, colorHex } : it,
  ),
});

export const createRevisionFromItemColorChange = (
  current: RegisterLayout,
  colorHex: string,
  categoryId: number,
  productId: number,
): RegisterLayout => ({
  ...current,
  categories: current.categories.map((category) => {
    if (category.id === categoryId) {
      return {
        ...category,
        items: category.items.map((item) => {
          if (item.id === productId) {
            return { ...item, colorHex };
          }

          return item;
        }),
      };
    }

    return category;
  }),
});

export const createRevisionFromColorChange = (
  current: RegisterLayout,
  colorHex: string,
  id: number,
  categoryId?: number,
): RegisterLayout =>
  categoryId == null
    ? createRevisionFromCategoryColorChange(current, colorHex, id)
    : createRevisionFromItemColorChange(current, colorHex, categoryId, id);

export const isRevisionEqual = (
  a: RegisterLayout,
  b: RegisterLayout,
): boolean => {
  if (a.categories.length !== b.categories.length) {
    return false;
  }

  const categoriesLength = a.categories.length;

  for (let i = 0; i < categoriesLength; i++) {
    const ca = a.categories[i];
    const cb = b.categories[i];

    if (
      !ca ||
      !cb ||
      ca.id !== cb.id ||
      ca.colorHex !== cb.colorHex ||
      ca.items.length !== cb.items.length
    ) {
      return false;
    }

    const itemsLength = ca.items.length;

    for (let j = 0; j < itemsLength; j++) {
      const ia = ca.items[j];
      const ib = cb.items[j];

      if (!ia || !ib || ia.id !== ib.id || ia.colorHex !== ib.colorHex) {
        return false;
      }
    }
  }

  return true;
};
