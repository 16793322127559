import PropTypes from 'prop-types';

import { Logomark } from 'crust';

import PsmInfo from '../psm-info';
import ShopSelector from '../shop-selector';

import styles from './styles.module.scss';

const NavigationHeader = ({ activeShop, shopId, shops, verificationCode }) => {
  return (
    <div className={styles.header}>
      <Logomark className={styles.sliceLogo} />
      {activeShop && <ShopSelector activeShop={activeShop} shops={shops} />}
      <PsmInfo shopId={shopId} verificationCode={verificationCode} />
    </div>
  );
};

NavigationHeader.propTypes = {
  activeShop: PropTypes.object,
  shops: PropTypes.array,
  shopId: PropTypes.string.isRequired,
  verificationCode: PropTypes.string,
};

NavigationHeader.defaultProps = {
  activeShop: null,
  shops: [],
  shopId: '',
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default NavigationHeader;
