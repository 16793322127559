import Big from 'big.js';

import {
  OnlineOrdersSortKey,
  OnlineOrderStatus,
} from 'types/financials/online-orders';
import { OnlineMetricKeys, RegisterMetricKeys } from 'types/orders';
import { OrderItem, RegisterOrderFilterValue } from 'types/register-orders';
import { toDollarString, toMoneyString } from 'utilities/currency';

export const registerMetricLabels = {
  [RegisterMetricKeys.NumOrders]: 'Total Orders',
  [RegisterMetricKeys.AverageOrder]: 'Average Order',
  [RegisterMetricKeys.TotalTips]: 'Total Tips',
  [RegisterMetricKeys.TotalSales]: 'Total Sales',
};

export const onlineMetricLabels = {
  [OnlineMetricKeys.TotalOrders]: 'Total Orders',
  [OnlineMetricKeys.AverageOrder]: 'Average Order',
  [OnlineMetricKeys.Tips]: 'Total Paid Tips',
  [OnlineMetricKeys.VoidedEarnings]: 'Voided Sales',
  [OnlineMetricKeys.Earnings]: 'Total Sales',
};

export const onlineMetricDashboardIds = {
  [OnlineMetricKeys.TotalOrders]: 'recentOnlineOrderActivityTotalOrdersTile',
  [OnlineMetricKeys.AverageOrder]: 'recentOnlineOrderActivityAverageOrderTile',
  [OnlineMetricKeys.Tips]: 'recentOnlineOrderActivityTotalPaidTipsTile',
  [OnlineMetricKeys.VoidedEarnings]: 'recentOnlineOrderActivityVoidedSalesTile',
  [OnlineMetricKeys.Earnings]: 'recentOnlineOrderActivityTotalSalesTile',
} as const;

export const onlineMetricIds = {
  [OnlineMetricKeys.TotalOrders]: 'onlineOrdersTotalOrdersTile',
  [OnlineMetricKeys.AverageOrder]: 'onlineOrdersAverageOrderTile',
  [OnlineMetricKeys.Tips]: 'onlineOrdersTotalPaidTipsTile',
  [OnlineMetricKeys.VoidedEarnings]: 'onlineOrdersVoidedSalesTile',
  [OnlineMetricKeys.Earnings]: 'onlineOrdersTotalSalesTile',
} as const;

export const registerOrderStatuses: {
  [key in RegisterOrderFilterValue]: string;
} = {
  [RegisterOrderFilterValue.All]: 'All',
  [RegisterOrderFilterValue.AwaitingPayment]: 'Awaiting Payment',
  [RegisterOrderFilterValue.Cleared]: 'Cleared',
  [RegisterOrderFilterValue.Held]: 'Held',
  [RegisterOrderFilterValue.Paid]: 'Paid',
  [RegisterOrderFilterValue.PartiallyRefunded]: 'Partially Refunded',
  [RegisterOrderFilterValue.Refunded]: 'Refunded',
};

export const onlineOrdersStatuses: {
  [key in OnlineOrderStatus]: string;
} = {
  [OnlineOrderStatus.All]: 'All',
  [OnlineOrderStatus.Authorized]: 'Authorized',
  [OnlineOrderStatus.Paid]: 'Paid',
  [OnlineOrderStatus.Refunded]: 'Refunded',
  [OnlineOrderStatus.Void]: 'Voided',
};

export const onlineOrdersSortKeys: {
  [key in OnlineOrdersSortKey]: string;
} = {
  [OnlineOrdersSortKey.CreatedAt]: 'created_at',
  [OnlineOrdersSortKey.Name]: 'delivery_name',
  [OnlineOrdersSortKey.OrderType]: 'shipping_type',
  [OnlineOrdersSortKey.Status]: 'payment_status',
  [OnlineOrdersSortKey.Tip]: 'cached_tip',
  [OnlineOrdersSortKey.Total]: 'cached_total',
  [OnlineOrdersSortKey.Id]: 'orders_id',
  [OnlineOrdersSortKey.Subtotal]: 'cached_subtotal',
  [OnlineOrdersSortKey.Tax]: 'cached_tax',
};

export const getOnlineOrderStatus = (status: OnlineOrderStatus): string => {
  if (
    status === OnlineOrderStatus.Authorized ||
    status === OnlineOrderStatus.Paid
  ) {
    return 'Completed';
  }

  return onlineOrdersStatuses[status];
};

// This function gets the total price for a given order item. We consider the total price for an order item to be:
// the price of the order item, multiplied by the quantity of the item, add the price of each modifier for an item (made up of multiple selections that have multiple quantities)
// multiplied by the quantity of the item. It is essentially implementing this formula:
// (product quantity * product type unit price) +
// product quantity * (customization quantity * customization unit price)
// = Item Subtotal
export const getRegisterOrderItemTotalPrice = (item: OrderItem): string => {
  const { unitPrice, quantity, orderItemAddons } = item;
  const addonsTotalPrices = orderItemAddons
    .filter((addon) => addon.orderItemSelections.length > 0)
    .map((addon) => {
      const selectionTotalPrices = addon.orderItemSelections.map(
        (selection) => {
          const selectionPrice = new Big(selection.unitPrice ?? 0);
          const selectionQuantity = new Big(selection.quantity);

          return selectionPrice.mul(selectionQuantity);
        },
      );

      return selectionTotalPrices.reduce(
        (total, current) => total.add(current),
        new Big(0),
      );
    });

  const bigItemPrice = new Big(unitPrice);
  const bigItemQuantity = new Big(quantity);

  const itemWithoutModifierTotalTotal = bigItemPrice.mul(bigItemQuantity);
  const modifiersTotal = addonsTotalPrices
    .reduce((total, current) => total.add(current), new Big(0))
    .mul(bigItemQuantity);

  const total = itemWithoutModifierTotalTotal.add(modifiersTotal);

  return toMoneyString(toDollarString(total.toNumber()), true, true);
};
