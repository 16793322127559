import * as React from "react";
import { forwardRef } from "react";
const SvgPlayIcon = ({
  title,
  titleId,
  ...props
}, ref) => {
  return /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", ref, "aria-labelledby": titleId, ...props }, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { d: "M17.858 9.88L9.075 4.39062C7.40988 3.34992 5.25 4.54703 5.25 6.51062V17.4894C5.25 19.453 7.40988 20.6501 9.075 19.6094L17.858 14.12C19.4247 13.1408 19.4247 10.8592 17.858 9.88Z", fill: "currentColor" }));
};
const ForwardRef = forwardRef(SvgPlayIcon);
export default ForwardRef;
