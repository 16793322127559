import { useCallback, useMemo, useState } from 'react';
import { parseAbsolute, parseDate } from '@internationalized/date';
import {
  createColumnHelper,
  PaginationState,
  Row,
  SortingState,
} from '@tanstack/react-table';

import { Button, Hint, useDateFormatter } from 'crust';

import Header from 'components/customer-feedback/header';
import ContentTile from 'components/shared/content-tile';
import { SearchBar } from 'components/shared/search-bar';
import Table from 'components/shared/table';
import PageControl from 'components/shared/table/page-control';
import VisuallyHidden from 'components/shared/visually-hidden';
import { useReviewsQuery } from 'hooks/customer-feedback/use-reviews-query';
import { useShopRatingsQuery } from 'hooks/customer-feedback/use-shop-ratings-query';
import { useTable } from 'hooks/shared';
import useAnalytics from 'hooks/use-analytics';
import { CustomerReview } from 'types/customer-feedback';
import { Shop } from 'types/shops';
import {
  isFromLastGivenHours,
  toStartOfDateAbsoluteString,
} from 'utilities/date-time';
import { generateShopMenuLink } from 'utilities/misc';
import { toSnakeCase } from 'utilities/strings';

import { ReplyForm } from './reply-form';
import { Stars } from './stars';
import ViewReply from './view-reply';

import styles from './styles.module.scss';

const MINIMUM_SEARCH_QUERY_LENGTH = 3;
const EARLIEST_START_DATE = parseDate('2021-01-01');

const DEFAULT_SORT = {
  id: 'createdAt',
  desc: false,
} as const;

type ViewReplyButtonProps = {
  row: Row<CustomerReview>;
  shopId: Shop['shopId'];
  shopTimeZone: Shop['timezoneIdentifier'];
};

const ViewReplyButton = ({
  row,
  shopId,
  shopTimeZone,
}: ViewReplyButtonProps) => {
  const { trackViewFeedbackClicked, trackReplyFeedbackClicked } =
    useAnalytics();

  const review = row.original;

  const hasResponse = review.responses.length > 0;
  const isReviewWithinCutoffPeriod = isFromLastGivenHours(
    parseAbsolute(review.createdAt, shopTimeZone),
    24 * 90,
  );

  const isDisabled = !hasResponse && !isReviewWithinCutoffPeriod;

  const handlePress = () => {
    row.toggleExpanded();

    if (hasResponse) {
      trackViewFeedbackClicked(shopId, row.index);
    } else {
      trackReplyFeedbackClicked(shopId, row.index);
    }
  };

  return (
    <div className={styles.replyButtonContainer}>
      <Button
        appearance="link"
        isDisabled={isDisabled}
        onPress={handlePress}
        size="small"
        variant="secondary"
      >
        {hasResponse ? 'View' : 'Reply'}
      </Button>
      {isDisabled ? (
        <Hint label="information about replying">
          You can only reply to reviews posted within the last 90 days.
        </Hint>
      ) : null}
    </div>
  );
};

type Props = {
  shop: Shop;
};

export const CustomerFeedback = ({ shop }: Props) => {
  const { shopId, timezoneIdentifier: shopTimeZone, webSlug } = shop;

  const [filter, setFilter] = useState('');

  const [sorting, setSorting] = useState<SortingState>([DEFAULT_SORT]);

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });

  const { trackCancelReplyButton } = useAnalytics();

  const { data: ratingsData, isLoading: isRatingsLoading } =
    useShopRatingsQuery(shopId);

  const sort = sorting.at(0) ?? DEFAULT_SORT;
  const { data: reviewsData, isLoading: isReviewsLoading } = useReviewsQuery(
    shopId,
    {
      searchQuery: filter,
      page: pagination.pageIndex + 1,
      perPage: pagination.pageSize,
      sort: `${sort.desc ? '-' : '+'}${toSnakeCase(sort.id)}`,
      startDate: toStartOfDateAbsoluteString(EARLIEST_START_DATE, shopTimeZone),
    },
    {
      keepPreviousData: true,
    },
  );

  const menuUrl = generateShopMenuLink(webSlug);

  const dateFormatter = useDateFormatter({
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    timeZone: shopTimeZone,
  });

  const columns = useMemo(() => {
    const helper = createColumnHelper<CustomerReview>();

    return [
      helper.accessor('createdAt', {
        header: 'Date',
        meta: {
          className: styles.createdAtColumn,
          chameleonId: 'createdAt',
        },
        cell(ctx) {
          return dateFormatter.format(new Date(ctx.getValue()));
        },
      }),

      helper.accessor('body', {
        id: 'rating',
        header: 'Review',
        meta: {
          className: styles.ratingColumn,
          chameleonId: 'content',
        },
        cell(ctx) {
          const body = ctx.getValue();
          const rating = ctx.row.original.rating;

          return (
            <div className={styles.ratingCellContent}>
              <p>{body}</p>
              <Stars rating={rating} />
            </div>
          );
        },
      }),

      helper.accessor(
        (it) => `${it.user.firstName} ${it.user.lastName}`.trim(),
        {
          id: 'userName',
          header: 'Customer',
          meta: {
            className: styles.customerColumn,
            chameleonId: 'customer',
          },
        },
      ),

      helper.accessor(
        (it) => (it.responses.length ? 'Replied' : 'Not replied'),
        {
          id: 'replied',
          header: 'Status',
          meta: {
            className: styles.statusColumn,
            chameleonId: 'status',
          },
        },
      ),
      helper.display({
        id: 'actions',
        header() {
          return <VisuallyHidden>Actions</VisuallyHidden>;
        },
        cell({ row }) {
          return (
            <ViewReplyButton
              row={row}
              shopId={shopId}
              shopTimeZone={shopTimeZone}
            />
          );
        },
        meta: {
          className: styles.replyColumn,
          isActionColumn: true,
        },
      }),
    ];
  }, [dateFormatter, shopId, shopTimeZone]);

  const getRowExpansion = useCallback(
    ({ row }: { row: Row<CustomerReview> }) => {
      const reply = row.original.responses.at(0);

      if (reply) {
        return <ViewReply reply={reply} menuUrl={menuUrl} />;
      }

      const handleCancel = () => {
        row.toggleExpanded(false);
        trackCancelReplyButton(shopId, row.index);
      };

      return (
        <ReplyForm
          review={row.original}
          onCancel={handleCancel}
          shopId={shopId}
        />
      );
    },
    [menuUrl, shopId, trackCancelReplyButton],
  );

  const table = useTable({
    columns,
    getRowExpansion,
    data: reviewsData?.data ?? [],
    getRowCanExpand: () => true,
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    onGlobalFilterChange: setFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    pageCount: reviewsData?.meta.pagination.pages ?? 1,
    state: {
      isLoading: isReviewsLoading,
      globalFilter: filter,
      pagination,
      sorting,
    },
    chameleonTableTitle: 'Recent Reviews',
  });

  const handleChangeSearch = useCallback(
    (value: string) => {
      table.setGlobalFilter(
        value.length >= MINIMUM_SEARCH_QUERY_LENGTH ? value : '',
      );
      table.setPageIndex(0);
    },
    [table],
  );

  return (
    <>
      <Header shopRatings={ratingsData?.ratings} isLoading={isRatingsLoading} />
      <ContentTile>
        <SearchBar
          className={styles.search}
          placeholderText="Search Reviews"
          onChange={handleChangeSearch}
        />
        <Table className={styles.table} table={table} />
        <PageControl
          currentPage={table.getState().pagination.pageIndex + 1}
          setPage={(page: number) => table.setPageIndex(page - 1)}
          totalPages={table.getPageCount()}
        />
      </ContentTile>
    </>
  );
};
