import { useMemo } from 'react';

import { Link } from 'crust';

import Callout from 'components/shared/callout';
import useAnalytics from 'hooks/use-analytics';
import CustomWebsite from 'images/callout/custom-website.png';
import { shopCustomWebsite } from 'routes/paths/website-tools';
import { CalloutEventType } from 'types/callout';
import { Shop } from 'types/shops';

type Props = {
  hasOwnWebsite: boolean;
  hasSmartButton: boolean;
  isOnlineOrdering: boolean;
  isRegisterShop: boolean;
  shopId: Shop['shopId'];
};

const CustomWebsiteCallout = ({
  hasOwnWebsite,
  hasSmartButton,
  isOnlineOrdering,
  isRegisterShop,
  shopId,
}: Props) => {
  const { trackClickedCalloutAction } = useAnalytics();

  const handleCustomWebsiteClick = () => {
    trackClickedCalloutAction({
      '1stLinkLabel': 'Create Website',
      '2ndLinkLabel': null,
      actionClicked: 'Create Website',
      hasCustomWebsite: false,
      hasOwnWebsite: hasOwnWebsite,
      hasSmartButton: hasSmartButton,
      isOnlineOrdering: isOnlineOrdering,
      isRegister: isRegisterShop,
      page: 'homepage',
      shopId: String(shopId),
      type: 'custom_website_callout',
    });
  };

  const analyticEvent: CalloutEventType = useMemo(
    () => ({
      '1stLinkLabel': 'Create Website',
      '2ndLinkLabel': null,
      hasCustomWebsite: false,
      hasOwnWebsite: hasOwnWebsite,
      hasSmartButton: hasSmartButton,
      isOnlineOrdering: isOnlineOrdering,
      isRegister: isRegisterShop,
      page: 'homepage',
      shopId: String(shopId),
      type: 'custom_website_callout',
    }),
    [hasOwnWebsite, hasSmartButton, isOnlineOrdering, isRegisterShop, shopId],
  );
  return (
    <Callout
      event={analyticEvent}
      image={CustomWebsite}
      primary={
        <Link
          href={shopCustomWebsite(shopId)}
          onPress={handleCustomWebsiteClick}
        >
          Create Website
        </Link>
      }
      text={'Create a free custom website powered by Slice.'}
    />
  );
};
/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default CustomWebsiteCallout;
