import {
  QueryKey,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { Pause } from 'types/shop-hours';

export const getPausingsQueryKey = (shopId: string): QueryKey => [
  shopId,
  'pausings',
];

export const usePausingsQuery = (
  shopId: string,
  options?: UseQueryOptions<Pause[]>,
): UseQueryResult<Pause[]> => {
  const api = useApi();

  return useQuery(getPausingsQueryKey(shopId), () => api.getPausings(shopId), {
    ...options,
  });
};
