import * as React from "react";
import { forwardRef } from "react";
const SvgCartIcon = ({
  title,
  titleId,
  ...props
}, ref) => {
  return /* @__PURE__ */ React.createElement("svg", { width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", "aria-hidden": "true", ref, "aria-labelledby": titleId, ...props }, title === void 0 ? /* @__PURE__ */ React.createElement("title", { id: titleId }, "Cart Icon") : title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { d: "M10.449 15.6832C9.24212 15.6832 8.27177 16.6895 8.27177 17.9113C8.27177 19.1572 9.24212 20.1634 10.449 20.1634C11.656 20.1634 12.6263 19.1572 12.6263 17.9113C12.6263 16.6656 11.6561 15.6832 10.449 15.6832Z", fill: "currentColor" }), /* @__PURE__ */ React.createElement("path", { d: "M17.2412 15.6832C16.0343 15.6832 15.0639 16.6895 15.0639 17.9113C15.0639 19.1572 16.0343 20.1634 17.2412 20.1634C18.4481 20.1634 19.4185 19.1572 19.4185 17.9113C19.3947 16.6656 18.4245 15.6832 17.2412 15.6832Z", fill: "currentColor" }), /* @__PURE__ */ React.createElement("path", { d: "M21.8561 6.57888C21.7141 6.38719 21.5012 6.29143 21.2881 6.29143H7.25408L6.99375 5.47687C6.68604 4.47061 5.78682 3.7998 4.72172 3.7998H2.71002C2.30772 3.7998 2 4.11132 2 4.51861C2 4.9259 2.30771 5.23741 2.71002 5.23741H4.72172C5.14763 5.23741 5.5027 5.50097 5.62108 5.90826L7.79835 13.024C8.12967 14.1262 9.1474 14.8689 10.2833 14.8689H17.7381C18.8978 14.8689 19.8918 14.1262 20.2231 13.0001L21.9507 7.22603C22.0455 7.01011 21.9981 6.7706 21.8561 6.57893L21.8561 6.57888Z", fill: "currentColor" }));
};
const ForwardRef = forwardRef(SvgCartIcon);
export default ForwardRef;
