import { useEffect, useState } from 'react';
import moment from 'moment-timezone';

// Format is used to determine the precision of the comparison. By default, it checks if the time has
// advanced by a minute. Default interval is 5s to avoid any potential performance issues.
// NOTE: If the time changes, it'll cause a re-render of the component this powers which will cause
// the comparison to be run again, so be wary of setting the format to anything more precise than a second.
export const useLiveTime = ({
  timezone = 'America/New_York',
  interval = 1000,
  format = 'MM-DD-YYYY hh:mm',
  enabled = true,
} = {}) => {
  const [currentTime, setCurrentTime] = useState(moment.tz(timezone));

  useEffect(() => {
    if (enabled) {
      const updateTimeOnChange = (newTime) => {
        if (newTime.format(format) !== currentTime.format(format)) {
          setCurrentTime(newTime);
        }
      };
      const timeInterval = setInterval(
        () => updateTimeOnChange(moment.tz(timezone)),
        interval,
      );

      return () => clearInterval(timeInterval);
    }
  });

  return currentTime;
};
