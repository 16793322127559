import * as React from "react";
import { forwardRef } from "react";
const SvgGaugeIcon = ({
  title,
  titleId,
  ...props
}, ref) => {
  return /* @__PURE__ */ React.createElement("svg", { width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", "aria-hidden": "true", ref, "aria-labelledby": titleId, ...props }, title === void 0 ? /* @__PURE__ */ React.createElement("title", { id: titleId }, "Gauge Icon") : title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { d: "M11.1918 11.8827C11.4297 11.8827 11.6674 11.978 11.834 12.1682C12.2144 12.5252 12.2144 13.1202 11.8577 13.4533C11.6674 13.6199 11.4297 13.7151 11.1918 13.7151C10.9539 13.7151 10.7162 13.6437 10.5259 13.4533C10.1692 13.0962 10.1692 12.5013 10.5259 12.1445C10.7162 11.9778 10.9539 11.8827 11.1918 11.8827Z", fill: "currentColor" }), /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM13.1658 13.7149L15.9721 8.2657C16.0673 8.09922 15.9008 7.93247 15.7345 8.02792L10.2882 10.8357C10.0027 10.9547 9.74111 11.1451 9.5271 11.4069C8.78987 12.2396 8.83731 13.5247 9.59844 14.3337C10.0266 14.7859 10.5973 15 11.1681 15C11.7388 15 12.2859 14.7857 12.7139 14.3575C12.9042 14.1671 13.0708 13.953 13.1658 13.7149Z", fill: "currentColor" }));
};
const ForwardRef = forwardRef(SvgGaugeIcon);
export default ForwardRef;
