import { ReactNode, useEffect } from 'react';
import {
  createInstance,
  enums,
  ListenerPayload,
  OptimizelyDecision,
  OptimizelyProvider,
} from '@optimizely/react-sdk';

import useAnalytics from 'hooks/use-analytics';
import { OptimizelyFeatureFlag } from 'types/optimizely-feature-flag';

/*
  Give the client "eventBatchSize" and "eventFlushInterval"
  to prevent warnings. If you do not specify these, Optimizely will
  log warnings and then use defaults of 10 for eventBatchSize and
  1000 for eventFlushInterval.
  (An "eventBatchSize" of 1 means "no batching".)
*/
const eventBatchSize = 10;
const eventFlushInterval = 1000;

/*
  The Optimizely provider will not work until it has a "user ID".
  The ID does not have to be a "user" - in our case, we use "shop IDs".
  The ID given to Optimizely is used for bucketing decisions when
  you give Optimizely a percentage based rule and we want such decisions
  to be for shops not individual users.

  We start by giving the provider an ID that does not conflict with
  actual shop IDs. Then, when we make use of Optimizely, we can specify
  the appropriate shop ID at the time of making the call.
*/
const defaultUserId = 'none';

/*
    Changes to feature flags will take time to be reflected in Owner's Portal.
    The time it takes depends upon the polling interval Optimizely uses to get
    the latest datafile.

    The default value is 5 minutes (i.e., a value of 300000 in milliseconds).
*/
const datafilePollingInterval = 300000;

const optimizelyClient = createInstance({
  sdkKey: import.meta.env.VITE_OPTIMIZELY_SDK_KEY,
  eventBatchSize,
  eventFlushInterval,
  datafileOptions: {
    autoUpdate: true,
    updateInterval: datafilePollingInterval,
  },
});

type DecisionInfo = {
  decisionEventDispatched: boolean;
} & Omit<OptimizelyDecision, 'userContext'>;

type DecisionListenerPayload = {
  type: string;
  decisionInfo: DecisionInfo;
} & ListenerPayload;

type Props = {
  children: ReactNode;
};

const FeatureFlagsProvider = ({ children }: Props) => {
  const { trackOptimizelyFlagDecision } = useAnalytics();

  useEffect(() => {
    const onDecision = (payload: DecisionListenerPayload): void => {
      const { type, userId, decisionInfo } = payload;

      if (type !== enums.DECISION_NOTIFICATION_TYPES.FLAG) {
        return;
      }

      /*
        Optimizely flag decision events are needed for when we need data for
        experiments. We want to limit events to the specific flags under
        experimentation, to avoid needlessly sending Segment events.
        As of 2023-01-17, we no longer need to track the customers_page Optimizely
        flag decisions.

        Future Segment event tracking of Optimizely `useDecision` can be done here,
        but we need to investigate approaches for reducing the number of events sent,
        such as local storage caching of results.

        See the Shortcut story for that additional work here:
        https://app.shortcut.com/slicelife/story/362356/investigate-approaches-for-reducing-number-of-segment-events-sent-for-optimizely-usedecision
      */
      const optimizelyFlagsBeingTracked: OptimizelyFeatureFlag[] = [];
      const flagKey = decisionInfo.flagKey as OptimizelyFeatureFlag;

      if (!optimizelyFlagsBeingTracked.includes(flagKey)) {
        return;
      }

      trackOptimizelyFlagDecision({
        shopId: userId,
        flagKey: decisionInfo.flagKey,
        isFlagEnabled: decisionInfo.enabled,
      });
    };

    const listenerId =
      optimizelyClient.notificationCenter.addNotificationListener(
        enums.NOTIFICATION_TYPES.DECISION,
        onDecision,
      );

    return () => {
      optimizelyClient.notificationCenter.removeNotificationListener(
        listenerId,
      );
    };
  }, [trackOptimizelyFlagDecision]);

  return (
    <OptimizelyProvider
      optimizely={optimizelyClient}
      user={{ id: defaultUserId }}
    >
      {children}
    </OptimizelyProvider>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default FeatureFlagsProvider;
