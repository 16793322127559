import cx from 'classnames';
import PropTypes from 'prop-types';

import Text from 'components/shared/text';
import CameraIcon from 'images/camera.svg?react';
import { toDollarString, toMoneyString } from 'utilities/currency';

import styles from './styles.module.scss';

const isDiscontinuedClass = (unavailable) =>
  unavailable ? styles.unavailable : '';

const Row = ({ data }) => {
  // Currently we use a flag t determine if we set the should_use_opas query parameter to true or false.
  // Data returned has different field names for the product name field so here we decide which to use.
  // When we have fully moved to opas we can remove this line and use just 'product_name'.
  const nameField = 'product_name';

  return (
    <tr>
      <td className={cx(styles.cell, isDiscontinuedClass(data.discontinued))}>
        <div className={styles.description}>
          {data.image_url ? (
            <img className={styles.photo} alt="" src={data.image_url} />
          ) : (
            <CameraIcon className={styles.icon} />
          )}
          <Text clamp className={styles.name}>
            {data[nameField]}
          </Text>
          {data.discontinued && (
            <Text clamp className={styles.discontinued}>
              No longer available
            </Text>
          )}
        </div>
      </td>
      <td
        className={cx(
          styles.cell,
          styles.sales,
          isDiscontinuedClass(data.discontinued),
        )}
      >
        {data.orders}
      </td>
      <td
        className={cx(
          styles.cell,
          styles.sales,
          isDiscontinuedClass(data.discontinued),
        )}
      >
        {toMoneyString(toDollarString(data.sales))}
      </td>
    </tr>
  );
};

Row.propTypes = {
  data: PropTypes.object,
};

Row.defaultProps = {
  data: {},
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default Row;
