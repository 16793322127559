import { useMemo } from 'react';
import { createColumnHelper } from '@tanstack/react-table';

import Table from 'components/shared/table';
import TableAction from 'components/shared/table/action';
import Text from 'components/shared/text';
import VisuallyHidden from 'components/shared/visually-hidden';
import { useTable } from 'hooks/shared';
import * as paths from 'routes/paths';
import { RegisterPermissionGroup, RegisterUser } from 'types/register-users';
import { getPermissionCategoryDisplayName } from 'utilities/register-users';

import styles from './styles.module.scss';

type Props = {
  permissionGroups: RegisterPermissionGroup[];
  shopId: string;
  users: RegisterUser[];
};

const RegisterPermissionsTable = ({
  permissionGroups,
  shopId,
  users,
}: Props) => {
  const columns = useMemo(() => {
    const helper = createColumnHelper<RegisterPermissionGroup>();

    return [
      helper.accessor('name', {
        header: 'Permission Group',
        sortingFn: 'text',
        meta: {
          className: styles.permissionGroupColumn,
          mobileHeading: 'Group',
        },
        cell(ctx) {
          return <Text clamp>{ctx.getValue()}</Text>;
        },
      }),
      helper.accessor(
        (row) =>
          Array.from(
            new Set(
              row.permissions.map((it) =>
                getPermissionCategoryDisplayName(it.category),
              ),
            ),
          )
            .sort()
            .join(', '),
        {
          id: 'permissions',
          header: 'Access',
          meta: {
            className: styles.accessColumn,
          },
        },
      ),
      helper.accessor(
        (row) =>
          users.filter((user) => user.permissionGroups.includes(row.id)).length,
        {
          id: 'userCount',
          header: 'Users',
          meta: {
            className: styles.userCountColumn,
          },
        },
      ),
      helper.display({
        id: 'actions',
        enableSorting: false,
        header() {
          return <VisuallyHidden>Actions</VisuallyHidden>;
        },
        cell(ctx) {
          const { id, name } = ctx.row.original;

          return (
            <TableAction
              aria-label={`Edit permission group ${name}`}
              icon="pencil"
              to={paths.registerPermission(shopId, id)}
            />
          );
        },
        meta: {
          isActionColumn: true,
          className: styles.actionsColumn,
        },
      }),
    ];
  }, [shopId, users]);

  const table = useTable({
    columns,
    data: permissionGroups,
    chameleonTableTitle: 'Register Permissions',
    initialState: {
      sorting: [
        {
          id: 'name',
          desc: false,
        },
      ],
    },
  });

  return <Table table={table} />;
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default RegisterPermissionsTable;
