import * as React from "react";
import { forwardRef } from "react";
const SvgCalendarCheck = ({
  title,
  titleId,
  ...props
}, ref) => {
  return /* @__PURE__ */ React.createElement("svg", { width: 24, height: 24, viewBox: "0 0 21 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", ref, "aria-labelledby": titleId, ...props }, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { d: "M19 7.95V4C19 3.44772 18.5523 3 18 3H15M19 7.95H1M19 7.95V19C19 19.5523 18.5523 20 18 20H2C1.44772 20 1 19.5523 1 19V7.95M1 7.95V4C1 3.44772 1.44772 3 2 3H5M5 3V0M5 3H15M15 3V0M14.5 10.5L9 16L6 13", stroke: "#211E1E", strokeWidth: 2 }));
};
const ForwardRef = forwardRef(SvgCalendarCheck);
export default ForwardRef;
