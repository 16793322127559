import _camelCaseKeys from 'camelcase-keys';

// The deep option is true by default for the snakeCaseKeys function, but we
// include the export here for consistency.
// https://github.com/bendrucker/snakecase-keys#deep
export { default as snakeCaseKeys } from 'snakecase-keys';

// The camelCaseKeys function is not deep by default, so we are going to wrap it
// a function that defaults deep to true, as expected.
// https://github.com/sindresorhus/camelcase-keys/issues/27

// Get the default parameter types so we don't have to copy them.
type CamelCaseParameters = Required<Parameters<typeof _camelCaseKeys>>;
type CamelCaseData = CamelCaseParameters[0];
type CamelCaseOptions = CamelCaseParameters[1];

// Use the given options if the deep property is explicitly a boolean, otherwise
// default the deep value to true. When defaulting, we need to omit deep because
// it may be type undefined, either explicitly or optionally.
type WithDeep = Pick<Required<CamelCaseOptions>, 'deep'>;
type ResolvedOptions<Options extends CamelCaseOptions> =
  Options extends WithDeep ? Options : Omit<Options, 'deep'> & { deep: true };

// The camelcase-keys library exports a CamelCaseKeys type, but it is not well
// suited to our override function because it requires more copying of
// implementation detail here. Using the following definition is easier to
// understand and maintain.
type CamelCaseKeys<
  Data extends CamelCaseData,
  Options extends CamelCaseOptions = CamelCaseOptions,
> = ReturnType<typeof _camelCaseKeys<Data, ResolvedOptions<Options>>>;

export const camelCaseKeys = <
  Data extends CamelCaseData,
  Options extends CamelCaseOptions = CamelCaseOptions,
>(
  data: Data,
  options?: Options,
): CamelCaseKeys<Data, Options> => {
  const { deep, ...others } = options ?? {};
  return _camelCaseKeys(data, {
    deep: deep ?? true,
    ...others,
  } as ResolvedOptions<Options>);
};
