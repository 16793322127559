import { ElementRef, ForwardedRef, forwardRef, ReactElement } from 'react';
import {
  FieldPath,
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';
import { Merge } from 'type-fest';

import { mergeRefs, NumberField, NumberFieldProps } from 'crust';

export type RHFNumberFieldProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = Merge<
  Omit<NumberFieldProps, 'isInvalid' | 'onChange' | 'onBlur'>,
  Omit<UseControllerProps<TFieldValues, TName>, 'disabled'>
>;

export const RHFNumberField = forwardRef(function RHFNumberField<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
  {
    control,
    defaultValue,
    isDisabled,
    name,
    rules,
    shouldUnregister,
    ...props
  }: RHFNumberFieldProps<TFieldValues, TName>,
  ref: ForwardedRef<ElementRef<typeof NumberField>>,
) {
  const { field, fieldState } = useController({
    control,
    defaultValue,
    disabled: isDisabled,
    name,
    rules,
    shouldUnregister,
  });

  return (
    <NumberField
      error={fieldState.error?.message}
      isDisabled={field.disabled}
      isInvalid={fieldState.invalid}
      name={field.name}
      onBlur={field.onBlur}
      onChange={field.onChange}
      ref={mergeRefs(ref, field.ref)}
      validationBehavior="aria"
      value={field.value}
      {...props}
    />
  );
}) as <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
  props: RHFNumberFieldProps<TFieldValues, TName>,
  ref: ForwardedRef<ElementRef<typeof NumberField>>,
) => ReactElement;
