import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { now, ZonedDateTime } from '@internationalized/date';
import classNames from 'classnames';

import { Button, Icon, Menu, MenuItem, useMediaQuery } from 'crust';

import { useUpdateProductsStockMutation } from 'hooks/menu/use-update-products-stock-mutation';
import useAnalytics from 'hooks/use-analytics';
import useFeatureFlag from 'hooks/use-feature-flag';
import { OptimizelyFeatureFlag } from 'types/optimizely-feature-flag';
import { Shop } from 'types/shops';
import { getShopHasFullRegister } from 'utilities/shops';

import { useBulkEditContext } from '../context';
import { formatStockUpdateSuccessMessage } from '../utilities';

import styles from './styles.module.scss';

type StockUpdateParams =
  | {
      date: ZonedDateTime | null;
      isUnavailable: true;
    }
  | {
      date: null;
      isUnavailable: false;
    };

type OutOfStockPreset = 'custom' | '12 hours' | 'indefinitely';

type OutOfStockActionsProps = {
  isDisabled: boolean;
  onPressCustom: () => void;
  onPressMarkOutOfStockPresetOption: (
    preset: OutOfStockPreset,
    params: StockUpdateParams,
  ) => void;
  shopTimezone: Shop['timezoneIdentifier'];
};

const OutOfStockActions = ({
  isDisabled,
  onPressCustom,
  onPressMarkOutOfStockPresetOption,
  shopTimezone,
}: OutOfStockActionsProps) => {
  const isMobile = useMediaQuery('(max-width: 811px)');

  return (
    <Menu
      className={styles.menu}
      // The gap to the left of the button.
      crossOffset={-20}
      // Fixed height of the bar, less the Link line-height, plus 4.
      offset={15}
      triggerElement={
        <div className={styles.iconButtonContainer}>
          <Icon icon="close" className={styles.text} />
          <Button
            className={styles.text}
            appearance="link"
            aria-label="mark out of stock"
            variant="secondary"
            isDisabled={isDisabled}
          >
            {isMobile ? 'Out of stock' : 'Mark out of stock'}
          </Button>
        </div>
      }
    >
      <MenuItem onAction={onPressCustom}>Custom</MenuItem>
      <MenuItem
        onAction={() =>
          onPressMarkOutOfStockPresetOption('indefinitely', {
            date: null,
            isUnavailable: true,
          })
        }
      >
        Indefinitely
      </MenuItem>
      <MenuItem
        onAction={() =>
          onPressMarkOutOfStockPresetOption('12 hours', {
            date: now(shopTimezone).add({ hours: 12 }),
            isUnavailable: true,
          })
        }
      >
        For 12 hours
      </MenuItem>
    </Menu>
  );
};

type ActionBarProps = {
  onOpenBulkChangePricingModal: () => void;
  onOpenBulkPrinterAssignmentsModal: () => void;
  onPressCustom: () => void;
  selectedProductNames: string[];
  shop: Shop;
};

export const ActionBar = ({
  onPressCustom,
  onOpenBulkChangePricingModal,
  onOpenBulkPrinterAssignmentsModal,
  selectedProductNames,
  shop,
}: ActionBarProps) => {
  const isMobile = useMediaQuery('(max-width: 811px)');
  const { selectedProductIds, clearSelectedProductIds } = useBulkEditContext();
  const {
    trackMenuClickedBulkEditInStock,
    trackMenuClickedBulkEditOutOfStock,
    trackMenuCancelledBulkSelection,
    trackMenuBulkEditDisplayedError,
  } = useAnalytics();
  const count = selectedProductIds.size;

  const {
    isEnabled: isBulkChangePrinterAssignmentsEnabled,
    isLoading: isBulkChangePrinterAssignmentsLoading,
  } = useFeatureFlag(OptimizelyFeatureFlag.BulkChangePrinterAssignments);

  const [isHidden, setIsHidden] = useState(true);
  const [isAnimated, setIsAnimated] = useState(false);

  // Very lightweight version of React Transition Group. Needed because
  // transitions and animations involving display changes are not yet supported
  // in all browsers.
  useEffect(() => {
    let timeout = null;

    if (count > 0) {
      setIsHidden(false);
      timeout = setTimeout(() => setIsAnimated(true), 0);
    } else {
      setIsAnimated(false);
      timeout = setTimeout(() => setIsHidden(true), 400);
    }

    return () => clearTimeout(timeout);
  }, [count]);

  const {
    isLoading: isUpdateProductsStockLoading,
    mutate: updateProductsStock,
  } = useUpdateProductsStockMutation({
    productIds: Array.from(selectedProductIds),
    shopId: shop.shopId,
    shopTimezone: shop.timezoneIdentifier,
  });

  const shouldDisableActions = isUpdateProductsStockLoading || count === 0;

  const handleStockUpdate = (params: StockUpdateParams) => {
    updateProductsStock(params, {
      onError: () => {
        toast.error(
          'An unexpected error occurred. Some of your products may not be updated.',
        );
        trackMenuBulkEditDisplayedError({
          shopId: shop.shopId,
          page: 'menu items',
          isRegister: getShopHasFullRegister(shop),
          failedItems: selectedProductIds,
          countFailedItems: selectedProductIds.size,
        });
      },
      onSuccess: () => {
        clearSelectedProductIds();
        toast.success(
          formatStockUpdateSuccessMessage(
            selectedProductIds.size,
            params.isUnavailable,
          ),
        );
      },
    });
  };

  const handleClickInStock = () => {
    handleStockUpdate({ date: null, isUnavailable: false });
    trackMenuClickedBulkEditInStock({
      shopId: shop.shopId,
      page: 'menu items',
      isRegister: getShopHasFullRegister(shop),
      productIds: selectedProductIds,
      productNames: selectedProductNames,
    });
  };

  const onPressMarkOutOfStockPresetOption = (
    preset: OutOfStockPreset,
    stockUpdateParams: StockUpdateParams,
  ) => {
    handleStockUpdate(stockUpdateParams);
    trackMenuClickedBulkEditOutOfStock({
      shopId: shop.shopId,
      page: 'menu items',
      isRegister: getShopHasFullRegister(shop),
      productIds: selectedProductIds,
      productNames: selectedProductNames,
      optionSelected: preset,
      until: stockUpdateParams.date
        ? stockUpdateParams.date.toAbsoluteString()
        : null,
    });
  };

  const onPressCancelSelection = () => {
    clearSelectedProductIds();
    trackMenuCancelledBulkSelection({
      shopId: shop.shopId,
      page: 'menu items',
      isRegister: getShopHasFullRegister(shop),
      countSelection: count,
    });
  };

  return (
    <div
      className={classNames(styles.bar, isAnimated && styles.animated)}
      hidden={isHidden}
    >
      <div className={styles.itemCount}>
        <Icon icon="minus" className={styles.text} />
        <Button
          className={styles.text}
          appearance="link"
          isDisabled={shouldDisableActions}
          onPress={onPressCancelSelection}
          variant="secondary"
        >
          {isMobile ? String(count) : `${count} selected`}
        </Button>
      </div>
      <span className={styles.fullHeightDivider}></span>
      <div className={styles.bulkActions}>
        <OutOfStockActions
          onPressMarkOutOfStockPresetOption={onPressMarkOutOfStockPresetOption}
          isDisabled={shouldDisableActions}
          onPressCustom={onPressCustom}
          shopTimezone={shop.timezoneIdentifier}
        />
        <span className={styles.divider}></span>
        <div className={styles.iconButtonContainer}>
          <Icon icon="checkmark" className={styles.text} />
          <Button
            className={styles.text}
            appearance="link"
            aria-label="mark in stock"
            isDisabled={shouldDisableActions}
            onPress={handleClickInStock}
            variant="secondary"
          >
            {isMobile ? 'In stock' : 'Mark in stock'}
          </Button>
        </div>
        {isMobile ? null : (
          <>
            <span className={styles.divider}></span>
            <div className={styles.iconButtonContainer}>
              <Icon icon="money" className={styles.text} />
              <Button
                className={styles.text}
                appearance="link"
                aria-label="change price"
                onPress={onOpenBulkChangePricingModal}
                variant="secondary"
                isDisabled={shouldDisableActions}
              >
                Change price
              </Button>
            </div>
          </>
        )}
        {getShopHasFullRegister(shop) &&
          !isMobile &&
          isBulkChangePrinterAssignmentsEnabled &&
          !isBulkChangePrinterAssignmentsLoading && (
            <>
              <span className={styles.divider} />
              <div className={styles.iconButtonContainer}>
                {/* TODO: Add printer icon when we have it */}
                <Icon icon="register" className={styles.text} />
                <Button
                  className={styles.text}
                  appearance="link"
                  aria-label="assign printer"
                  onPress={onOpenBulkPrinterAssignmentsModal}
                  variant="secondary"
                  isDisabled={shouldDisableActions}
                >
                  Assign printer
                </Button>
              </div>
            </>
          )}
      </div>
    </div>
  );
};
