import { ReactElement } from 'react';
import { Menu, MenuButton, MenuItem } from '@szhsin/react-menu';
import cx from 'classnames';

import useAnalytics from 'hooks/use-analytics';
import { SetStoredState } from 'hooks/use-stored-state';
import AllCustomersIcon from 'images/all-customers-icon.svg?react';
import CaretIcon from 'images/caret.svg?react';
import DeliveryCustomersIcon from 'images/delivery-customers-icon.svg?react';
import LoyalCustomerIcon from 'images/loyal-customer-icon.svg?react';
import NewCustomerIcon from 'images/new-customer-icon.svg?react';
import PickupCustomersIcon from 'images/pickup-customers-icon.svg?react';
import RepeatCustomerIcon from 'images/repeat-customer-icon.svg?react';
import ValuableCustomersIcon from 'images/valuable-customers-icon.svg?react';
import { CustomerList, CustomerListOption } from 'types/customers';
import {
  customerListIconsNoOutline,
  CustomerListLabels,
} from 'utilities/customers';

import styles from './styles.module.scss';

type DropdownItemProps = {
  customerList: CustomerList;
  icon: ReactElement;
  onClick: (selectedList: CustomerList) => void;
};

const DropdownItem = ({ customerList, icon, onClick }: DropdownItemProps) => {
  return (
    <MenuItem className={styles.menuItem} onClick={() => onClick(customerList)}>
      {icon}
      <span className={styles.menuItemText}>
        {CustomerListLabels[customerList]}
      </span>
    </MenuItem>
  );
};

type Props = {
  className?: string;
  customerList: CustomerList;
  customerListOptions: CustomerListOption[];
  setCustomerList: (customerListFilter: CustomerList) => void;
  shopId: string;
  setStoredCustomerList: SetStoredState<CustomerList>;
};

const CustomerListDropdown = ({
  className,
  customerList,
  customerListOptions,
  setCustomerList,
  shopId,
  setStoredCustomerList,
}: Props) => {
  const { trackSelectedCustomerList } = useAnalytics();

  const label = customerListOptions.find(
    (option) => option.value === customerList,
  )?.label;

  const handleClickDropdownItem = (selectedList: CustomerList): void => {
    trackSelectedCustomerList(
      shopId,
      CustomerListLabels[customerList],
      CustomerListLabels[selectedList],
    );

    setCustomerList(selectedList);

    setStoredCustomerList(selectedList);
  };

  const MenuButtonIcon = customerListIconsNoOutline[customerList];

  return (
    <div className={cx(styles.dropdownContainer, className)}>
      <Menu
        // This fixes an issue with clashing SVG id's causing rendering issues, related to the repeating customers SVG.
        // react-menu defaults to display: none and this unmounts the component from the DOM.
        // Shortcut: https://app.shortcut.com/slicelife/story/449456/
        unmountOnClose
        direction="bottom"
        menuButton={({ open }) => (
          <MenuButton className={styles.menuButton}>
            <MenuButtonIcon
              aria-hidden="true"
              className={styles.menuButtonIcon}
            />
            <span className={styles.menuButtonText}>{label}</span>
            <CaretIcon className={open ? styles.caretUp : styles.caretDown} />
          </MenuButton>
        )}
        overflow="auto"
        position="initial"
      >
        <div className={styles.customerTypeLabel}>Customer Type</div>
        <DropdownItem
          customerList={CustomerList.New}
          icon={
            <NewCustomerIcon
              aria-hidden="true"
              className={styles.menuItemIcon}
            />
          }
          onClick={handleClickDropdownItem}
        />
        <DropdownItem
          customerList={CustomerList.Repeat}
          icon={
            <RepeatCustomerIcon
              aria-hidden="true"
              className={styles.menuItemIcon}
            />
          }
          onClick={handleClickDropdownItem}
        />
        <DropdownItem
          customerList={CustomerList.Loyal}
          icon={
            <LoyalCustomerIcon
              aria-hidden="true"
              className={styles.menuItemIcon}
            />
          }
          onClick={handleClickDropdownItem}
        />
        <DropdownItem
          customerList={CustomerList.All}
          icon={<AllCustomersIcon className={styles.menuItemIcon} />}
          onClick={handleClickDropdownItem}
        />
        <div className={styles.optionGroupLabel}>Order Type</div>
        <DropdownItem
          customerList={CustomerList.Delivery}
          icon={
            <DeliveryCustomersIcon
              aria-hidden="true"
              className={styles.menuItemIcon}
            />
          }
          onClick={handleClickDropdownItem}
        />
        <DropdownItem
          customerList={CustomerList.Pickup}
          icon={
            <PickupCustomersIcon
              aria-hidden="true"
              className={styles.menuItemIcon}
            />
          }
          onClick={handleClickDropdownItem}
        />
        <div className={styles.optionGroupLabel}>Top 10</div>
        <DropdownItem
          customerList={CustomerList.MostFrequent}
          icon={
            <ValuableCustomersIcon
              aria-hidden="true"
              className={styles.menuItemIcon}
            />
          }
          onClick={handleClickDropdownItem}
        />
        <DropdownItem
          customerList={CustomerList.MostValuable}
          icon={
            <ValuableCustomersIcon
              aria-hidden="true"
              className={styles.menuItemIcon}
            />
          }
          onClick={handleClickDropdownItem}
        />
      </Menu>
    </div>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default CustomerListDropdown;
