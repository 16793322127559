import { useMemo } from 'react';

import { SingleRegisterOrder } from 'types/register-orders';

import AdditionalOrderDetails from '../additional-order-details';
import CustomerDetails from '../customer-details';
import OrderItemsList from '../order-items-list';
import PriceDetails from '../price-details';

import styles from './styles.module.scss';

type Props = {
  order: SingleRegisterOrder;
  timezone: string;
};

const ModalBody = ({ order, timezone }: Props) => {
  const orderItems = useMemo(
    () => order.orderItems.filter((item) => item.active),
    [order.orderItems],
  );

  return (
    <>
      <CustomerDetails
        className={styles.customerDetails}
        orderCustomer={order.orderCustomer}
        orderAddress={order.orderAddress}
      />

      <div className={styles.orderWrapper}>
        <div>
          <OrderItemsList
            className={styles.orderItems}
            orderItems={orderItems}
          />
          <PriceDetails order={order} />
        </div>

        <AdditionalOrderDetails order={order} timezone={timezone} />
      </div>
    </>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default ModalBody;
