import { useEffect, useMemo, useRef, useState } from 'react';
import {
  ColumnSort,
  createColumnHelper,
  PaginationState,
  SortingState,
  Updater,
} from '@tanstack/react-table';

import ContentTile from 'components/shared/content-tile';
import FormFeedback from 'components/shared/form-feedback';
import { SearchBar } from 'components/shared/search-bar';
import Table from 'components/shared/table';
import Pagination from 'components/shared/table/pagination';
import Text from 'components/shared/text';
import VisuallyHidden from 'components/shared/visually-hidden';
import useCustomersQuery from 'hooks/customers/use-customers-query';
import { useTable } from 'hooks/shared';
import useAnalytics from 'hooks/use-analytics';
import {
  BaseCustomer,
  CustomerList,
  CustomerSegmentsTableColumnId,
  CustomerSegmentsTableDefaultSort,
  CustomerSegmentsTableSortableColumnId,
  CustomerSort,
  CustomerSortBy,
  CustomerSortDirection,
  CustomerType,
  DateRange,
} from 'types/customers';
import { toDollarString, toMoneyString } from 'utilities/currency';
import { getCustomerDisplayName } from 'utilities/customers';
import { formatDate } from 'utilities/date-time';
import { capitalize } from 'utilities/strings';

import CustomerNameButton from '../../customer-name-button';

import MobileSortMenu from './mobile-sort-menu';
import CustomerSegmentsTableResultsInfo from './results-info';
import CustomerSegmentsTableRowIcon from './row-icon';
import CustomerSegmentsTableTitle from './title';
import { ColumnIdToCustomerSort } from './utilities';

import styles from './styles.module.scss';

const DEFAULT_SORTING = {
  id: CustomerSegmentsTableColumnId.LastOrderDate,
  desc: true,
} as const;

type Props = {
  areNonTop10QueriesEnabled: boolean;
  customerList: CustomerList;
  customerType: CustomerType;
  customerUuids: string[];
  dateRange: DateRange;
  setSelectedCustomer: React.Dispatch<
    React.SetStateAction<BaseCustomer | null>
  >;
  shopId: string;
  timezone: string;
};

const CustomerSegmentsTableTile = ({
  areNonTop10QueriesEnabled,
  customerList,
  customerType,
  customerUuids,
  dateRange,
  setSelectedCustomer,
  shopId,
  timezone,
}: Props) => {
  const {
    trackSortedCustomerList,
    trackEditCustomerListShowRows,
    trackChangedCustomerListPage,
    trackSearchedCustomerName,
  } = useAnalytics();
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  useEffect(() => {
    setPagination({
      pageIndex: 0,
      pageSize: pagination.pageSize,
    });
  }, [pagination.pageSize, customerList, customerType, dateRange]);

  const [userNameSearch, setUserNameSearch] = useState('');
  const isSearching = userNameSearch.length > 0;
  const searchMeetsMinumumCharacter = userNameSearch.length >= 3;
  const customerRequestEnabled = !(isSearching && !searchMeetsMinumumCharacter);

  const setPerPage = (perPage: number): void => {
    setPagination({
      pageIndex: 0,
      pageSize: perPage,
    });
  };

  useEffect(() => {
    if (isSearching && searchMeetsMinumumCharacter) {
      trackSearchedCustomerName(shopId, userNameSearch);
    }
  }, [
    shopId,
    isSearching,
    searchMeetsMinumumCharacter,
    userNameSearch,
    trackSearchedCustomerName,
  ]);

  useEffect(() => {
    // Return to first page upon new search
    if (customerRequestEnabled) {
      setPagination((prevPagination) => ({
        pageIndex: 0,
        pageSize: prevPagination.pageSize,
      }));
    }
  }, [customerRequestEnabled, userNameSearch, setPagination]);

  const [activeMobileSort, setActiveMobileSort] = useState<ColumnSort>({
    id: CustomerSegmentsTableColumnId.LastOrderDate,
    desc: true,
  });

  const onApplyMobileSort = (newColumnSort: ColumnSort): void => {
    shouldTrackNewSort.current = true;
    setSorting([newColumnSort]);
  };

  const [sorting, setSorting] = useState<SortingState>([DEFAULT_SORTING]);

  const shouldTrackNewSort = useRef<boolean>(false);

  useEffect(() => {
    shouldTrackNewSort.current = false;

    const newSort = {
      id: CustomerSegmentsTableDefaultSort[customerList],
      desc: true,
    };

    setActiveMobileSort(newSort);
    setSorting([newSort]);
  }, [customerList]);

  const sort = useMemo((): CustomerSort => {
    const order = sorting.at(0);

    if (order == null) {
      return {
        by: CustomerSortBy.OrderDate,
        direction: CustomerSortDirection.Descending,
      };
    }

    const sortDirection = order.desc
      ? CustomerSortDirection.Descending
      : CustomerSortDirection.Ascending;

    const sortId = order.id;
    const sortBy =
      ColumnIdToCustomerSort[sortId as CustomerSegmentsTableSortableColumnId];

    const newSort = {
      by: sortBy,
      direction: sortDirection,
    };

    if (shouldTrackNewSort.current) {
      trackSortedCustomerList(shopId, newSort);
    }

    return newSort;
  }, [sorting, trackSortedCustomerList, shopId]);

  const isCustomersQueryEnabled =
    areNonTop10QueriesEnabled && (!isSearching || customerRequestEnabled);

  const { data: customersResponse, isLoading } = useCustomersQuery({
    customerList,
    customerType,
    customerUuids,
    dateRange,
    shopId,
    page: pagination.pageIndex + 1,
    perPage: pagination.pageSize,
    timezone,
    sort,
    options: {
      enabled: isCustomersQueryEnabled,
    },
    userNameSearch: searchMeetsMinumumCharacter ? userNameSearch : '',
  });

  const totalPages = customersResponse?.meta.pagination.pages ?? 0;

  const suppressPagination =
    !customersResponse?.meta.pagination || isLoading || totalPages <= 1;

  const columns = useMemo(() => {
    const helper = createColumnHelper<BaseCustomer>();

    return [
      helper.display({
        id: 'loyaltyStatusIcon',
        enableSorting: false,
        header() {
          return <VisuallyHidden>Actions</VisuallyHidden>;
        },
        cell: (ctx) => (
          <CustomerSegmentsTableRowIcon
            customerLoyaltyStatus={ctx.row.original.loyaltyStatus}
          />
        ),
        meta: {
          className: styles.iconColumn,
        },
      }),
      helper.accessor(
        (row) => getCustomerDisplayName(row.firstName, row.lastName),
        {
          cell(ctx) {
            return (
              <CustomerNameButton
                customer={ctx.row.original}
                customerList={customerList}
                displayName={ctx.getValue()}
                setSelectedCustomer={setSelectedCustomer}
                shopId={shopId}
                shopTimezone={timezone}
              />
            );
          },
          id: 'name',
          header: 'Customer Name',
          enableSorting: false,
          meta: {
            className: styles.nameColumn,
          },
        },
      ),
      helper.accessor((row) => row.favouriteProduct.productName, {
        cell: (info) => <Text clamp>{info.getValue()}</Text>,
        id: 'favoriteItem',
        header: 'Favorite Item',
        enableSorting: false,
        meta: {
          className: styles.standardColumn,
        },
      }),
      helper.accessor((row) => capitalize(row.loyaltyStatus), {
        id: 'type',
        header: 'Customer Type',
        enableSorting: false,
        meta: {
          className: styles.standardColumn,
        },
      }),
      helper.accessor((row) => formatDate(row.lastOrderedAt, 'MM/DD/YYYY'), {
        id: 'lastOrder',
        header: 'Last Order',
        enableSorting: true,
        meta: {
          className: styles.standardColumn,
        },
      }),
      helper.accessor('orderCount', {
        id: 'orderCount',
        header: 'Order Count',
        enableSorting: true,
        meta: {
          className: styles.standardColumn,
        },
      }),
      helper.accessor(
        (row) =>
          toMoneyString(toDollarString(row.averageOrderValue), true, true),
        {
          id: 'averageOrder',
          header: 'Avg Order',
          enableSorting: true,
          meta: {
            className: styles.standardColumn,
          },
        },
      ),
      helper.accessor(
        (row) => toMoneyString(toDollarString(row.orderTotal), true, true),
        {
          id: 'totalSpend',
          header: 'Total Spend',
          enableSorting: true,
          meta: {
            className: styles.standardColumn,
          },
        },
      ),
    ];
  }, [customerList, setSelectedCustomer, shopId, timezone]);

  const onApplySort = (updaterOrValue: Updater<SortingState>): void => {
    setSorting(updaterOrValue);
    shouldTrackNewSort.current = true;
  };

  const table = useTable({
    columns,
    data: customersResponse?.data ?? [],
    manualPagination: true,
    manualSorting: true,
    onPaginationChange: setPagination,
    onSortingChange: onApplySort,
    pageCount: customersResponse?.meta.pagination.pages,
    state: {
      isLoading,
      pagination,
      sorting,
    },
    chameleonTableTitle: 'Customer Segments Table',
  });

  const handlePerPageChange = (selectedPerPage: number) => {
    trackEditCustomerListShowRows(shopId, pagination.pageSize, selectedPerPage);
    setPerPage(selectedPerPage);
  };

  const handlePageChange = (selectedPage: number) => {
    trackChangedCustomerListPage(
      shopId,
      pagination.pageIndex + 1,
      selectedPage,
    );
    table.setPageIndex(selectedPage - 1);
  };

  return (
    <ContentTile dataTestId="my-customers-table-tile">
      <div className={styles.headerArea}>
        <div>
          <CustomerSegmentsTableTitle
            customerList={customerList}
            customerType={customerType}
          />
          <CustomerSegmentsTableResultsInfo
            dateRange={dateRange}
            pagination={customersResponse?.meta.pagination}
          />
        </div>
        <div className={styles.mobileContainer}>
          <SearchBar
            className={styles.searchBarContainer}
            inputClassName={styles.searchBarInput}
            onChange={(value) => setUserNameSearch(value)}
            placeholderText="Search by customer name"
            iconClassName={styles.searchBarIconClassName}
            data-chameleon-target="Customer Segments Table Search Bar"
          />
          {isSearching && !searchMeetsMinumumCharacter && (
            <FormFeedback>Please enter at least 3 characters.</FormFeedback>
          )}
          <MobileSortMenu
            activeSort={activeMobileSort}
            setActiveSort={setActiveMobileSort}
            setCurrentSort={onApplyMobileSort}
            shopId={shopId}
            columnSort={sorting[0] ?? DEFAULT_SORTING}
            customerSort={sort}
          />
        </div>
      </div>
      <Table table={table} />
      <Pagination
        currentPage={table.getState().pagination.pageIndex + 1}
        isPageControlVisible={!suppressPagination}
        onChangePage={handlePageChange}
        totalPages={table.getPageCount()}
        perPage={pagination.pageSize}
        onChangePerPage={handlePerPageChange}
      />
    </ContentTile>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default CustomerSegmentsTableTile;
