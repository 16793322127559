import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { PRODUCT_SALES_MINMAX_CENTS } from 'components/analytics/orders/products-by-sales/constants';
import Link from 'components/shared/link';
import Suspended from 'components/shared/suspended';
import useAnalytics from 'hooks/use-analytics';
import * as paths from 'routes/paths';
import {
  chainComparators,
  createIntegerComparator,
  createStringComparator,
  Direction,
} from 'utilities/sorting';

import Control from '../control';
import AnalyticsTable from '..';

import CategorySelect from './category-select';
import Placeholder from './placeholder';
import Row from './row';

import styles from './styles.module.scss';

const DEFAULT_PAGE_SIZE = 4;
const DEFAULT_MAX_PAGES = 4;

const paginate = (
  data,
  pageSize = DEFAULT_PAGE_SIZE,
  maxPages = DEFAULT_MAX_PAGES,
) => {
  let tmpData = [...data];
  let pages = [];
  while (tmpData.length > 0 && pages.length < maxPages) {
    pages.push(tmpData.splice(0, pageSize));
  }
  return pages;
};

const ProductsBySales = ({
  data,
  isEmpty,
  isLoading,
  shopId,
  parentTileTitle,
  selectedCategoryId,
  setSelectedCategoryId,
  sortDirection,
}) => {
  const [page, setPage] = useState(0);
  const [paginatedData, setPaginatedData] = useState([]);
  const [sortBy, setSortBy] = useState('salesValue');

  const {
    trackGoToMenuItemsFromProductSalesTile,
    trackFilterProductSalesByCategory,
  } = useAnalytics();

  const nameField = 'product_name';

  const filterProducts = useMemo(
    () => (product) =>
      sortDirection === Direction.Ascending
        ? product.sales < PRODUCT_SALES_MINMAX_CENTS
        : product.sales >= PRODUCT_SALES_MINMAX_CENTS,
    [sortDirection],
  );

  const salesComparator = useMemo(
    () => createIntegerComparator('sales', { direction: sortDirection }),
    [sortDirection],
  );
  const ordersComparator = useMemo(
    () => createIntegerComparator('orders', { direction: sortDirection }),
    [sortDirection],
  );
  const nameComparator = useMemo(
    () => createStringComparator(nameField),
    [nameField],
  );

  // Sorting columns will sort by the selected column first, using the other coulmn in cases where items
  // have the same value for the first column, followed by the name in the case all other columns are equal.
  const sortBySalesComparator = useMemo(
    () => chainComparators(salesComparator, ordersComparator, nameComparator),
    [nameComparator, salesComparator, ordersComparator],
  );

  const sortByOrdersComparator = useMemo(
    () => chainComparators(ordersComparator, salesComparator, nameComparator),
    [nameComparator, ordersComparator, salesComparator],
  );

  const sortBySalesValue = useMemo(() => {
    return data.filter(filterProducts).sort(sortBySalesComparator);
  }, [data, sortBySalesComparator, filterProducts]);

  const sortByOrderCount = useMemo(() => {
    return data.filter(filterProducts).sort(sortByOrdersComparator);
  }, [data, sortByOrdersComparator, filterProducts]);

  useEffect(() => {
    setPaginatedData(
      paginate(sortBy === 'salesValue' ? sortBySalesValue : sortByOrderCount),
    );
  }, [data, sortBy, sortBySalesValue, sortByOrderCount]);

  const isTableEmpty =
    isEmpty ||
    !paginatedData ||
    !paginatedData[page] ||
    paginatedData[page].length === 0;

  const onGoToMenuClick = () => {
    trackGoToMenuItemsFromProductSalesTile({ shopId, tile: parentTileTitle });
  };

  const onCategorySelected = ({ value, label }) => {
    value = value === -1 ? 'all' : String(value);

    setSelectedCategoryId(value);
    setPage(0);
    trackFilterProductSalesByCategory({
      shopId,
      selectedCategoryName: label,
      selectedCategoryId: value,
      tile: parentTileTitle,
    });
  };

  return (
    <div className={styles.contentWrapper}>
      <CategorySelect
        className={styles.categorySelect}
        shopId={shopId}
        inputId={parentTileTitle}
        onChange={onCategorySelected}
        value={selectedCategoryId === 'all' ? -1 : Number(selectedCategoryId)}
      />
      <Suspended isLoading={isLoading}>
        {isTableEmpty ? (
          <Placeholder />
        ) : (
          <>
            <AnalyticsTable
              data={paginatedData[page]}
              RowComponent={Row}
              shopId={shopId}
              sortBy={sortBy}
              setSortBy={setSortBy}
              isSortAscending={sortDirection === Direction.Ascending}
            />
          </>
        )}
        <div className={styles.footer}>
          <div className={styles.navigation}>
            {!isTableEmpty && (
              <Control
                count={paginatedData.length}
                position={page}
                setPosition={setPage}
                parentTileTitle={parentTileTitle}
              />
            )}
          </div>
          <Link
            className={styles.link}
            to={paths.getMenuItemsPath(shopId)}
            onClick={onGoToMenuClick}
          >
            Go to Menu Items
          </Link>
        </div>
      </Suspended>
    </div>
  );
};

ProductsBySales.propTypes = {
  data: PropTypes.array,
  isEmpty: PropTypes.bool,
  isLoading: PropTypes.bool,
  shopId: PropTypes.string.isRequired,
  parentTileTitle: PropTypes.string,
  selectedCategoryId: PropTypes.string,
  setSelectedCategoryId: PropTypes.func.isRequired,
  sortDirection: PropTypes.number,
};

ProductsBySales.defaultProps = {
  data: [],
  isEmpty: true,
  isLoading: false,
  shopId: '',
  parentTileTitle: '',
  selectedCategoryId: 'all',
  sortDirection: Direction.Ascending,
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default ProductsBySales;
