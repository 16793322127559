import { QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { PromoCode } from 'types/discounts';
import { camelCaseKeys } from 'utilities/objects';

type UsePromoCodesQueryOptions = Omit<
  UseQueryOptions<PromoCode[]>,
  'queryFn' | 'queryKey'
>;

export const getPromoCodesQueryKey = (shopId: number): QueryKey => [
  shopId,
  'promoCodes',
];

export const usePromoCodesQuery = (
  shopId: number,
  options?: UsePromoCodesQueryOptions,
) => {
  const { authenticatedFetch } = useApi();

  return useQuery({
    queryKey: getPromoCodesQueryKey(shopId),
    queryFn: async () => {
      const response = await authenticatedFetch.get(
        `api/management/v1/shops/${shopId}/promo_codes`,
      );

      return camelCaseKeys(response.promo_codes) as PromoCode[];
    },
    ...options,
  });
};
