export const formatPhoneNumber = (number: string): string =>
  number && number.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');

// As of 2022-07-15, posted a question regarding this new formatting in
// Slack design channel. We may end up removing this and using the same/
// consistent phone number formatting (as done by `formatPhoneNumber` above).
export const formatUSPhoneNumber = (number: string): string =>
  number && number.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');

export const capitalize = (string: string): string =>
  string && string.charAt(0).toUpperCase() + string.slice(1);

/**
 * We check for the protocol here as for iframes this is required in order to be a valid
 * iframe src attribute value.
 */
export const isValidHttpUrlString = (urlString: string): boolean => {
  // Prevent spaces in url, which can be used in URL constructor
  if (urlString.includes(' ')) {
    return false;
  }

  let url;
  try {
    url = new URL(urlString);
  } catch (_) {
    // Not a valid URL accroding to URL constructor
    return false;
  }

  if (url.protocol !== 'http:' && url.protocol !== 'https:') {
    // We only want URLs with http(s) protocols
    return false;
  }

  if (url.origin.split('.').length === 1) {
    // we also want the user to include the top level domain (eg. something like '.com' or '.co.uk')
    return false;
  }

  return true;
};

export const addHttpsProtocolToUrl = (urlString: string): string => {
  if (urlString.startsWith('http://') || urlString.startsWith('https://')) {
    return urlString;
  }

  return `https://${urlString}`;
};

export const toSnakeCase = (it: string) =>
  it.replace(/[A-Z]/g, (match) => `_${match.toLowerCase()}`);
