import * as React from "react";
import { forwardRef } from "react";
const SvgInfoIcon = ({
  title,
  titleId,
  ...props
}, ref) => {
  return /* @__PURE__ */ React.createElement("svg", { width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", "aria-hidden": "true", ref, "aria-labelledby": titleId, ...props }, title === void 0 ? /* @__PURE__ */ React.createElement("title", { id: titleId }, "Info Icon") : title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM13.875 7.625C13.875 8.66053 13.0355 9.5 12 9.5C10.9645 9.5 10.125 8.66053 10.125 7.625C10.125 6.58947 10.9645 5.75 12 5.75C13.0355 5.75 13.875 6.58947 13.875 7.625ZM13.25 12V18.25H10.75V12H13.25Z", fill: "currentColor" }));
};
const ForwardRef = forwardRef(SvgInfoIcon);
export default ForwardRef;
