import { createContext, PropsWithChildren, useMemo } from 'react';

import { AnalyticsAdapter } from 'types/shared/analytics';

import events from './events';

export type CompiledAnalyticsMethods = ReturnType<typeof events>;

export const AnalyticsContext = createContext<CompiledAnalyticsMethods | null>(
  null,
);
AnalyticsContext.displayName = 'Analytics';

type Props = {
  adapter: AnalyticsAdapter;
};

export const AnalyticsProvider = ({
  adapter,
  children,
}: PropsWithChildren<Props>) => {
  const analytics = useMemo(() => {
    return events(adapter);
  }, [adapter]);

  return (
    <AnalyticsContext.Provider value={analytics}>
      {children}
    </AnalyticsContext.Provider>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default AnalyticsProvider;
