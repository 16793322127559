import {
  cloneElement,
  ForwardedRef,
  forwardRef,
  ReactElement,
  Ref,
} from 'react';
import ReactSelect, {
  ClearIndicatorProps,
  components,
  ControlProps,
  DropdownIndicatorProps,
  GroupBase,
  Props as ReactSelectProps,
  SelectInstance,
} from 'react-select';
// https://react-select.com/typescript#custom-select-props
import type {} from 'react-select/base';
import cx from 'classnames';

import CaretIcon from 'images/caret.svg?react';
import CloseIcon from 'images/close.svg?react';

import styles from './styles.module.scss';

export const DropdownIndicator = <
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>,
>(
  props: DropdownIndicatorProps<Option, IsMulti, Group>,
) => (
  <components.DropdownIndicator {...props}>
    <CaretIcon />
  </components.DropdownIndicator>
);

export const ClearIndicator = <
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>,
>(
  props: ClearIndicatorProps<Option, IsMulti, Group>,
) => (
  <components.ClearIndicator {...props}>
    <CloseIcon />
  </components.ClearIndicator>
);

// Handle custom selectProps typing
// https://github.com/JedWatson/react-select/issues/4804
declare module 'react-select/base' {
  // We are extending the react-select interface.
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  export interface Props<
    Option,
    IsMulti extends boolean,
    Group extends GroupBase<Option>,
  > {
    option?: Option;
    isMulti: IsMulti;
    group?: Group;
    icon?: ReactElement;
  }
}

export const Control = <
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>,
>(
  props: ControlProps<Option, IsMulti, Group>,
) => {
  return (
    <components.Control {...props}>
      {props.selectProps?.icon &&
        cloneElement(props.selectProps.icon, {
          className: cx(
            styles.dropdownIcon,
            props.selectProps.icon.props.className,
          ),
        })}
      {props.children}
    </components.Control>
  );
};

export const IndicatorSeparator = (): null => null;

export type SelectProps<
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option> = GroupBase<Option>,
> = {
  isInvalid?: boolean;
  icon?: ReactElement;
} & ReactSelectProps<Option, IsMulti, Group>;

// We need to cast the result of forwardRef as the underlying generics are not
// propagated otherwise.
// https://stackoverflow.com/a/58473012
const Select = forwardRef(function Select<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: SelectProps<Option, IsMulti, Group>,
  ref: ForwardedRef<SelectInstance<Option, IsMulti, Group>>,
) {
  const { className, components, isInvalid, ...selectProps } = props;

  return (
    <ReactSelect
      components={{
        DropdownIndicator,
        IndicatorSeparator,
        ClearIndicator,
        Control,
        ...components,
      }}
      className={cx(styles.select, isInvalid && styles.invalid, className)}
      classNamePrefix="react-select"
      isSearchable={false}
      ref={ref}
      {...selectProps}
    />
  );
}) as <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: SelectProps<Option, IsMulti, Group> & {
    ref?: Ref<SelectInstance<Option, IsMulti, Group>>;
  },
) => ReactElement;

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default Select;
