import * as React from "react";
import { forwardRef } from "react";
const SvgPizzaLeftIcon = ({
  title,
  titleId,
  ...props
}, ref) => {
  return /* @__PURE__ */ React.createElement("svg", { width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", "aria-hidden": "true", ref, "aria-labelledby": titleId, ...props }, title === void 0 ? /* @__PURE__ */ React.createElement("title", { id: titleId }, "Pizza Left Icon") : title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M12 3.99999C12 2.89542 11.0955 1.97913 10.0128 2.19744C5.44193 3.11904 2 7.15756 2 12C2 16.8424 5.44193 20.8809 10.0128 21.8025C11.0955 22.0209 12 21.1046 12 20V14C11.4477 14 11 13.5523 11 13C11 12.4477 11.4477 12 12 12V3.99999ZM10 6C10 6.55228 9.55228 7 9 7C8.44772 7 8 6.55228 8 6C8 5.44772 8.44772 5 9 5C9.55228 5 10 5.44772 10 6ZM10 18C10.5523 18 11 17.5523 11 17C11 16.4477 10.5523 16 10 16C9.44772 16 9 16.4477 9 17C9 17.5523 9.44772 18 10 18ZM6.5 12C7.32843 12 8 11.3284 8 10.5C8 9.67157 7.32843 9 6.5 9C5.67157 9 5 9.67157 5 10.5C5 11.3284 5.67157 12 6.5 12Z", fill: "currentColor" }), /* @__PURE__ */ React.createElement("path", { d: "M22 12C22 16.838 18.5645 20.8735 14 21.8V19.748C17.4505 18.8599 20 15.7277 20 12C20 8.27236 17.4505 5.14016 14 4.25207V2.20007C18.5645 3.12661 22 7.16212 22 12Z", fill: "currentColor" }));
};
const ForwardRef = forwardRef(SvgPizzaLeftIcon);
export default ForwardRef;
