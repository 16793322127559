import { bar } from '../svg-settings';

const Rectangle = () => (
  <rect
    x={bar.x}
    y={bar.y}
    width={bar.width}
    height={bar.height}
    rx="6.5"
    fill={bar.fill}
    fillOpacity={bar.fillOpacity}
  />
);

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default Rectangle;
