import * as React from "react";
import { forwardRef } from "react";
const SvgChevronUpIcon = ({
  title,
  titleId,
  ...props
}, ref) => {
  return /* @__PURE__ */ React.createElement("svg", { width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", "aria-hidden": "true", ref, "aria-labelledby": titleId, ...props }, title === void 0 ? /* @__PURE__ */ React.createElement("title", { id: titleId }, "Chevron Up Icon") : title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M9.22729 8.14849L1.99998 15.3758L3.50118 17.1067L12 10.7326L20.4988 17.1067L22 15.3758L14.7727 8.14849C14.0373 7.41312 13.0399 7 12 7C10.96 7 9.96266 7.41312 9.22729 8.14849Z", fill: "currentColor" }));
};
const ForwardRef = forwardRef(SvgChevronUpIcon);
export default ForwardRef;
