import PropTypes from 'prop-types';

import { toMoneyString } from 'utilities/currency';

import styles from './styles.module.scss';

const TableContent = ({ data }) => (
  <div className={styles.tableContent}>
    <div className={styles.nameSection}>{data.name}</div>
    <div className={styles.orderSection}>{`${data.count} orders`}</div>
    <div className={styles.valueSection}>
      {toMoneyString(data.orderValue / 100)}
    </div>
  </div>
);

TableContent.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
    count: PropTypes.number,
    orderValue: PropTypes.number,
  }).isRequired,
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default TableContent;
