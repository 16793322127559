import { useMemo } from 'react';

import { Link } from 'crust';

import Callout from 'components/shared/callout';
import useAnalytics from 'hooks/use-analytics';
import Referral from 'images/callout/referral.png';
import { shopReferral as shopReferralUrl } from 'routes/external-urls';
import { CalloutEventType } from 'types/callout';
import { Shop } from 'types/shops';

type Props = {
  hasCustomWebsite: boolean;
  hasOwnWebsite: boolean;
  hasSmartButton: boolean;
  isOnlineOrdering: boolean;
  isRegisterShop: boolean;
  shopId: Shop['shopId'];
};

const ReferralCallout = ({
  hasCustomWebsite,
  hasOwnWebsite,
  hasSmartButton,
  isOnlineOrdering,
  isRegisterShop,
  shopId,
}: Props) => {
  const { trackClickedCalloutAction } = useAnalytics();

  const handleReferralClick = () => {
    trackClickedCalloutAction({
      '1stLinkLabel': 'Refer Shop',
      '2ndLinkLabel': null,
      actionClicked: 'Refer Shop',
      hasCustomWebsite: hasCustomWebsite,
      hasOwnWebsite: hasOwnWebsite,
      hasSmartButton: hasSmartButton,
      isOnlineOrdering: isOnlineOrdering,
      isRegister: isRegisterShop,
      page: 'homepage',
      shopId: String(shopId),
      type: 'referral_callout',
    });
  };

  const analyticEvent: CalloutEventType = useMemo(
    () => ({
      '1stLinkLabel': 'Refer Shop',
      '2ndLinkLabel': null,
      hasCustomWebsite: hasCustomWebsite,
      hasOwnWebsite: hasOwnWebsite,
      hasSmartButton: hasSmartButton,
      isOnlineOrdering: isOnlineOrdering,
      isRegister: isRegisterShop,
      page: 'homepage',
      shopId: String(shopId),
      type: 'referral_callout',
    }),
    [
      hasCustomWebsite,
      hasOwnWebsite,
      hasSmartButton,
      isOnlineOrdering,
      isRegisterShop,
      shopId,
    ],
  );

  return (
    <Callout
      event={analyticEvent}
      image={Referral}
      primary={
        <Link href={shopReferralUrl} onPress={handleReferralClick}>
          Refer Shop
        </Link>
      }
      text={'Get a major bonus when a shop you refer switches to Slice.'}
    />
  );
};
/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default ReferralCallout;
