import { useCallback, useMemo, useState } from 'react';
import { DateFormatter, parseDate } from '@internationalized/date';
import { createColumnHelper } from '@tanstack/react-table';
import PropTypes from 'prop-types';

import DeleteModal from 'components/hours/closings/delete-modal';
import EditClosingTile from 'components/hours/closings/edit-tile';
import Table from 'components/shared/table';
import TableAction from 'components/shared/table/action';
import Text from 'components/shared/text';
import VisuallyHidden from 'components/shared/visually-hidden';
import { ClosingScope, useClosingReasons, useClosings } from 'hooks/hours';
import { useTable } from 'hooks/shared';
import { newClosingDefaultValues } from 'utilities/constants';

import { shippingTypeLabels } from './utils';

const ClosingsTable = ({
  shopId,
  activeClosingId,
  setActiveClosingId,
  shopTimezone,
}) => {
  const { closingsData } = useClosings(shopId);

  const { data: closingReasonOptions } = useClosingReasons(
    ClosingScope.Closing,
  );

  const [deletingRecord, setDeletingRecord] = useState(null);

  const [sorting, setSorting] = useState([
    {
      id: 'startDate',
      desc: false,
    },
  ]);

  const closings = useMemo(() => {
    if (!closingsData) {
      return [];
    }

    return closingsData;
  }, [closingsData]);

  const columns = useMemo(() => {
    const helper = createColumnHelper();

    const dateFormatter = new DateFormatter('en-US', {
      day: '2-digit',
      month: '2-digit',
      weekday: 'long',
      year: '2-digit',
      timeZone: 'UTC',
    });

    const formatDate = (date) =>
      dateFormatter.format(parseDate(date).toDate('UTC')).replace(',', '');

    return [
      helper.accessor('closureReasonName', {
        id: 'closureReasonName',
        header: 'Reason',
      }),
      helper.accessor('reason', {
        id: 'reason',
        header: 'Comment',
        sortingFn: 'text',
        cell(ctx) {
          return <Text wrap="truncate">{ctx.getValue()}</Text>;
        },
      }),
      helper.accessor('startDate', {
        id: 'startDate',
        header: 'Start Date',
        cell(ctx) {
          return formatDate(ctx.getValue());
        },
      }),
      helper.accessor('endDate', {
        id: 'endDate',
        header: 'End Date',
        cell(ctx) {
          return formatDate(ctx.getValue());
        },
      }),
      helper.accessor((row) => shippingTypeLabels[row.shippingType], {
        id: 'shippingType',
        header: 'Shipping Type',
      }),
      helper.display({
        id: 'actions',
        enableSorting: false,
        header() {
          return <VisuallyHidden>Actions</VisuallyHidden>;
        },
        cell(ctx) {
          return (
            <>
              <TableAction
                icon="pencil"
                title="Edit"
                onClick={() => ctx.row.toggleExpanded()}
                dataTestId={`edit-scheduled-closing-${ctx.row.index}`}
              />
              <TableAction
                icon="trashCan"
                title="Delete"
                onClick={() =>
                  setDeletingRecord(
                    closings.find(
                      (closing) => closing.id === ctx.row.original.id,
                    ),
                  )
                }
                dataTestId={`delete-scheduled-closing-${ctx.row.index}`}
              />
            </>
          );
        },
        meta: {
          isActionColumn: true,
        },
      }),
    ];
  }, [closings]);

  const existingClosingDefaultValues = useCallback(
    (closingId) => {
      const activeClosing = closings.find((c) => c.id === closingId);

      if (!activeClosing) {
        return newClosingDefaultValues;
      }

      return {
        closingComment: activeClosing.reason ?? '',
        closingReason:
          closingReasonOptions?.find(
            (c) => c.value === activeClosing.closureReason,
          ) || null,
        dates: {
          start: parseDate(activeClosing.startDate),
          end: parseDate(activeClosing.endDate),
        },
        shippingType: activeClosing.shippingType,
      };
    },
    [closingReasonOptions, closings],
  );

  const getRowExpansion = useCallback(
    ({ row }) => {
      return (
        <EditClosingTile
          shopId={shopId}
          defaultValues={existingClosingDefaultValues(row.original.id)}
          activeClosingId={row.original.id}
          onClose={() => {
            setActiveClosingId(null);
            row.toggleExpanded(false);
          }}
          shopTimezone={shopTimezone}
        />
      );
    },
    [existingClosingDefaultValues, setActiveClosingId, shopId, shopTimezone],
  );

  const table = useTable({
    columns,
    data: closings ?? [],
    getRowCanExpand: () => true,
    getRowExpansion,
    chameleonTableTitle: 'Closings',
    onSortingChange: setSorting,
    state: {
      sorting,
    },
  });

  return (
    <>
      <DeleteModal
        shopId={shopId}
        activeClosingId={activeClosingId}
        setActiveClosingId={setActiveClosingId}
        deletingRecord={deletingRecord}
        setDeletingRecord={setDeletingRecord}
        shopTimezone={shopTimezone}
      />
      <Table table={table} />
    </>
  );
};

ClosingsTable.propTypes = {
  activeClosingId: PropTypes.number,
  setActiveClosingId: PropTypes.func.isRequired,
  shopId: PropTypes.string.isRequired,
  shopTimezone: PropTypes.string.isRequired,
};

ClosingsTable.defaultProps = {
  activeClosingId: null,
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default ClosingsTable;
