import ContentTile from 'components/shared/content-tile';
import { useLiveTime } from 'hooks/hours';
import CalendarIcon from 'images/calendarEvent.svg?react';
import ClockIcon from 'images/clock.svg?react';

import styles from './styles.module.scss';

type Props = {
  shopTimezone: string;
};

const CurrentDateTime = ({ shopTimezone }: Props) => {
  const time = useLiveTime({ timezone: shopTimezone });

  const formattedDates = {
    month: time.format('MMM'),
    date: time.format('D'),
    time: time.format('h:mm'),
    amPmString: time.format('a'),
  };

  return (
    <ContentTile>
      <div className={styles.header}>{"Today's Date & Time"}</div>
      <div className={styles.content}>
        <div className={styles.dateTimeContainer}>
          <CalendarIcon className={styles.icon} aria-hidden="true" />
          <div className={styles.value}>{formattedDates.date}</div>
          <div className={styles.unit}>
            {formattedDates.month.toUpperCase()}
          </div>
        </div>
        <div className={styles.dateTimeContainer}>
          <ClockIcon className={styles.icon} aria-hidden="true" />
          <div className={styles.value}>{formattedDates.time}</div>
          <div className={styles.unit}>
            {formattedDates.amPmString.toUpperCase()}
          </div>
        </div>
      </div>
    </ContentTile>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default CurrentDateTime;
