import { Id, shop } from './shop';

const getCustomerBasePath = (shopId?: Id) => `${shop(shopId)}/customers`;

export const getCustomerAnalyticsPath = (shopId?: Id) =>
  `${getCustomerBasePath(shopId)}/dashboard`;

export const getCustomerProfilesPath = (shopId?: Id) =>
  `${getCustomerBasePath(shopId)}/profile`;

/**
 * Old path for the customer dashboard/analytics page. Only used for redirecting to the latest URL
 * to support the Owners App which contains an old link.
 */
export const getCustomerDashboardPathDeprecated = (shopId?: Id) =>
  `${shop(shopId)}/customer-dashboard`;

export const getCustomerSegmentsPath = (shopId?: Id) =>
  `${getCustomerBasePath(shopId)}/my-customers`;

export const getCustomerFeedbackPath = (shopId?: Id) =>
  `${getCustomerBasePath(shopId)}/feedback`;
