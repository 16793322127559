import ContentTile from 'components/shared/content-tile';
import Link from 'components/shared/link';
import useAnalytics from 'hooks/use-analytics';
import { assetLibrary } from 'routes/paths';
import { Shop } from 'types/shops';

import styles from './styles.module.scss';

type Props = {
  shopId: Shop['shopId'];
};

const SocialMediaResources = ({ shopId }: Props) => {
  const { trackInternalFeatureLinkClick } = useAnalytics();

  return (
    <div className={styles.wrapper}>
      <ContentTile className={styles.tile}>
        <div className={styles.title}>Social Media Graphics</div>
        <div className={styles.content}>
          Social media is an excellent (and free) way to drive orders. Find
          graphics that promote anything from contact-free delivery to pizza
          deals, then post them (one at a time) to social media.
        </div>
        <Link
          className={styles.link}
          onClick={() =>
            trackInternalFeatureLinkClick({
              copy: 'find graphics',
              fromPage: 'marketing services',
              shopId: String(shopId),
              toPage: 'branding studio',
            })
          }
          to={assetLibrary(shopId)}
          variant="undecorated"
        >
          Find Graphics
        </Link>
      </ContentTile>
    </div>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default SocialMediaResources;
