import PropTypes from 'prop-types';

import CaretIcon from 'images/caret.svg?react';

import Item from '../item';

import styles from './styles.module.scss';

const SubDrawer = ({
  activeSubMenu,
  clearActiveSubMenu,
  trackNavigationItemClick,
}) => {
  return (
    <div className={styles.subMenu}>
      <button onClick={clearActiveSubMenu} className={styles.title}>
        <div>
          <CaretIcon className={styles.icon} />
          <span>{activeSubMenu.label}</span>
        </div>
      </button>

      <div>
        {activeSubMenu.customSubMenu
          ? activeSubMenu.customSubMenu
          : activeSubMenu.items.map(
              (item) =>
                item.label && (
                  <Item
                    key={item.url}
                    label={item.label}
                    parentItemLabel={activeSubMenu.label}
                    trackNavigationItemClick={trackNavigationItemClick}
                    url={item.url}
                  />
                ),
            )}
      </div>
    </div>
  );
};

SubDrawer.propTypes = {
  activeSubMenu: PropTypes.object,
  clearActiveSubMenu: PropTypes.func,
  trackNavigationItemClick: PropTypes.func,
};

SubDrawer.defaultProps = {
  activeSubMenu: null,
  clearActiveSubMenu: () => {},
  trackNavigationItemClick: () => {},
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default SubDrawer;
