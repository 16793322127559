import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import Drawer from './drawer';
import Header from './header';

const MobileNav = ({
  activeShop,
  navigationItems,
  shopId,
  shops,
  trackNavigationItemClick,
  verificationCode,
}) => {
  const { pathname } = useLocation();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const [activeSubMenu, setActiveSubMenu] = useState();

  useEffect(() => {
    setIsDrawerOpen(false);
    setActiveSubMenu(null);
  }, [pathname]);

  return (
    <>
      <Header onHamburgerClick={() => setIsDrawerOpen(true)} />
      <Drawer
        activeShop={activeShop}
        activeSubMenu={activeSubMenu}
        closeDrawer={() => setIsDrawerOpen(false)}
        isDrawerOpen={isDrawerOpen}
        navigationItems={navigationItems}
        setActiveSubMenu={setActiveSubMenu}
        shopId={shopId}
        shops={shops}
        trackNavigationItemClick={trackNavigationItemClick}
        verificationCode={verificationCode}
      />
    </>
  );
};

MobileNav.propTypes = {
  activeShop: PropTypes.object,
  navigationItems: PropTypes.array,
  shopId: PropTypes.string.isRequired,
  shops: PropTypes.array.isRequired,
  trackNavigationItemClick: PropTypes.func,
  verificationCode: PropTypes.string,
};

MobileNav.defaultProps = {
  activeShop: null,
  navigationItems: [],
  trackNavigationItemClick: () => {},
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default MobileNav;
