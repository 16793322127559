import { Icon } from 'crust';

import ContentTile from 'components/shared/content-tile';
import VideoPreview from 'images/register/register-splash/video-preview.jpg';
import Register from 'images/stand-on-cashbox.png';

import styles from './styles.module.scss';

type Props = {
  onPreviewClick: () => void;
};

export const VideoPreviewSection = ({ onPreviewClick }: Props) => {
  return (
    <div className={styles.container}>
      <img
        alt="registerCashbox"
        className={styles.registerImage}
        src={Register}
      />

      <ContentTile
        className={styles.videoContentTile}
        onClick={onPreviewClick}
        onKeyDown={onPreviewClick}
        role="button"
        tabIndex={0}
      >
        <img alt="videoPreview" src={VideoPreview} />
        <div className={styles.playButton}>
          <Icon icon="play" className={styles.play} />
          See how it works
        </div>
      </ContentTile>
    </div>
  );
};
