import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import cx from 'classnames';

import { Button, Icon } from 'crust';

import ContentTile from 'components/shared/content-tile';
import Loading from 'components/shared/loading';
import { RHFRadio, RHFRadioGroup } from 'components/shared/rhf-radio';
import { useCreateAccountLocationMutation } from 'hooks/business-profile/use-create-account-location-mutation';
import { useGBPAccountLocationSearchQuery } from 'hooks/business-profile/use-gbp-account-location-search-query';
import { useShopQuery } from 'hooks/shops';
import useAnalytics from 'hooks/use-analytics';
import { Shop } from 'types/shops';
import { getShopHasFullRegister } from 'utilities/shops';

import styles from './styles.module.scss';

type Props = {
  shopId: Shop['shopId'];
};

export const SearchAndClaim = ({ shopId }: Props) => {
  const {
    data: locationSearchData,
    isLoading: isAccountLocationSearchLoading,
    isSuccess: isAccountLocationSearchSuccess,
  } = useGBPAccountLocationSearchQuery(shopId);

  const {
    trackBusinessProfileViewedLocationSelect,
    trackBusinessProfileSelectedSearchLocation,
    trackBusinessProfileClickedToConfirmSearchLocation,
  } = useAnalytics();
  const { data: shop, isSuccess: isShopSuccess } = useShopQuery(shopId);
  const isShopRegister =
    (isShopSuccess && getShopHasFullRegister(shop)) ?? false;
  const { mutate: createAccountLocation } = useCreateAccountLocationMutation();

  const navigate = useNavigate();
  const [isDisabled, setIsDisabled] = useState(true);
  const [selectedLocationType, setSelectedLocationType] = useState('claimed');
  const [hasSentTrackingEventRef, setHasSentTrackingEventRef] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      location: '',
    },
    shouldUnregister: true,
  });

  useEffect(() => {
    if (isAccountLocationSearchSuccess && !hasSentTrackingEventRef) {
      const locations = isAccountLocationSearchSuccess
        ? locationSearchData.googleLocations
        : [];
      trackBusinessProfileViewedLocationSelect({
        shopId,
        isRegister: isShopRegister,
        locationsCount: locations.length,
        locations: locations.map((location) => location.location.title),
      });
      setHasSentTrackingEventRef(true);
    }
  }, [
    trackBusinessProfileViewedLocationSelect,
    isShopRegister,
    shopId,
    isAccountLocationSearchSuccess,
    locationSearchData?.googleLocations,
    hasSentTrackingEventRef,
  ]);

  const handleButtonChange = (
    googleLocation:
      | {
          requestAdminRightsUri?: string | null;
          name: string;
        }
      | undefined,
  ) => {
    setIsDisabled(false);
    if (googleLocation?.requestAdminRightsUri) {
      setSelectedLocationType('claimed');
    } else if (googleLocation?.name === 'none') {
      setSelectedLocationType('none');
    } else {
      setSelectedLocationType('unclaimed');
    }
    if (selectedLocationType !== 'none' && isDisabled === false) {
      trackBusinessProfileSelectedSearchLocation({
        shopId,
        isRegister: isShopRegister,
        selection: googleLocation?.name || '',
        isClaimed: selectedLocationType === 'claimed' ? true : false,
        address: shop?.address || '',
      });
    }
  };

  const handleValidSubmit = (values: { location?: string }) => {
    if (values.location === 'none') {
      navigate(`..`, { relative: 'path' });
    } else {
      const googleLocation =
        isAccountLocationSearchSuccess &&
        locationSearchData.googleLocations.find(
          (location) => location.name === values.location,
        );
      if (googleLocation) {
        trackBusinessProfileClickedToConfirmSearchLocation({
          shopId,
          isRegister: isShopRegister,
          selection: googleLocation.name,
          action: googleLocation.requestAdminRightsUri
            ? 'request access'
            : locationSearchData.googleLocations.length === 0
              ? 'create'
              : 'claim',
          address: shop?.address || '',
        });
        if (googleLocation.requestAdminRightsUri) {
          window.open(
            googleLocation.requestAdminRightsUri,
            '_blank',
            'noreferrer',
          );
        } else {
          createAccountLocation(
            {
              shopId: shopId,
              searchLocationId: values?.location,
            },
            {
              onSuccess() {
                navigate('..', { relative: 'path' });
              },
              onError() {
                const errorMessage =
                  "We weren't able to connect your Google Business location at this time. Please try again or contact your Partner Success Manager.";
                toast.error(errorMessage);
              },
            },
          );
        }
      } else {
        toast.error('Please select a location');
      }
    }
  };

  return (
    <ContentTile>
      {isAccountLocationSearchLoading ? (
        <Loading />
      ) : isAccountLocationSearchSuccess ? (
        locationSearchData.googleLocations.length === 0 ? (
          <>
            <div className={cx(styles.alert, styles.info)}>
              <Icon icon={'info'} />
              <span>Creating a new location on Google is Coming Soon!</span>
            </div>
            <p className={styles.header}>No Locations Found</p>
            <p className={styles.subheader}>
              {
                "We couldn't find any locations for this address. Would you like to create a new one?"
              }
            </p>
            {/* TODO change the navigate to handleValidSubmit hen the create flow is ready */}
            <form
              onSubmit={handleSubmit(() =>
                navigate('..', { relative: 'path' }),
              )}
            >
              {/* TODO enable button when the create flow is ready */}
              <Button
                variant="primary"
                type="submit"
                isDisabled={isSubmitting || true}
              >
                Create
              </Button>
              {/* TODO remove back to home button when create flow is ready */}
              <Button
                variant="primary"
                type="submit"
                className={styles.backToHomeButton}
              >
                Back to Home
              </Button>
            </form>
          </>
        ) : (
          <>
            <p className={styles.header}>Search results</p>
            <p className={styles.subheader}>
              {
                'We found the following locations for your address. Please select a location:'
              }
            </p>
            <form onSubmit={handleSubmit(handleValidSubmit)}>
              <RHFRadioGroup
                aria-label="google-locations"
                className={styles.radioGroup}
                control={control}
                name="location"
                orientation="horizontal"
                variant="card"
                defaultValue={locationSearchData.googleLocations[0]?.name}
              >
                {locationSearchData.googleLocations.map((googleLocation) => (
                  <RHFRadio
                    onFocus={() => handleButtonChange(googleLocation)}
                    value={googleLocation.name}
                    key={googleLocation.name}
                    label={
                      <div className={styles.label}>
                        {googleLocation.location.title}{' '}
                        {
                          /* TODO swap this with a crust library version when available */
                          googleLocation.requestAdminRightsUri ? (
                            <p className={styles.claimedTag}>Claimed</p>
                          ) : null
                        }
                      </div>
                    }
                    description={
                      <>
                        {googleLocation.location.storefrontAddress ? (
                          <>
                            {
                              googleLocation.location.storefrontAddress
                                .addressLines
                            }{' '}
                            {googleLocation.location.storefrontAddress.locality}
                            ,{' '}
                            {
                              googleLocation.location.storefrontAddress
                                .postalCode
                            }{' '}
                            {
                              googleLocation.location.storefrontAddress
                                .regionCode
                            }
                            <br />
                          </>
                        ) : (
                          ''
                        )}
                        {googleLocation.location.websiteUri}
                      </>
                    }
                  />
                ))}
                <RHFRadio
                  onFocus={() =>
                    handleButtonChange({
                      requestAdminRightsUri: undefined,
                      name: 'none',
                    })
                  }
                  value={'none'}
                  label="None of these look correct, I want to set up a new location."
                />
              </RHFRadioGroup>
              <Button
                variant="primary"
                type="submit"
                isDisabled={isSubmitting || isDisabled}
              >
                {selectedLocationType === 'claimed'
                  ? 'Request Access'
                  : selectedLocationType === 'unclaimed'
                    ? 'Claim'
                    : 'Back to Home'}
              </Button>
            </form>
          </>
        )
      ) : (
        <div className={styles.header}>
          Something went wrong while searching for Locations. Please try again
          or contact your Partner Success Manager.
        </div>
      )}
    </ContentTile>
  );
};
