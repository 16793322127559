import { useEffect } from 'react';
import moment from 'moment-timezone';

import ResultsTile from 'components/campaign-manager/campaign-info/results-tile';
import Alert from 'components/shared/alert';
import useAnalytics from 'hooks/use-analytics';
import {
  CampaignManagerState,
  CampaignManagerSummary,
} from 'types/campaign-manager';
import { Shop } from 'types/shops';
import { User } from 'types/user';

import RenewYourCampaignTile from '../renew-tile';

import styles from './styles.module.scss';

type Props = {
  endDate: string;
  hasEnded: boolean;
  shopId: Shop['shopId'];
  startDate: string;
  summaryData: CampaignManagerSummary;
  user: User;
};

const InactiveCampaign = ({
  endDate,
  hasEnded,
  shopId,
  startDate,
  summaryData,
  user,
}: Props) => {
  const { trackViewedCampaignManagerState } = useAnalytics();

  const formattedStartDate = moment(startDate).format('MMM D, YYYY');
  const formattedEndDate = moment(endDate).format('MMM D, YYYY');

  useEffect(() => {
    trackViewedCampaignManagerState({
      shopId: String(shopId),
      state: CampaignManagerState.CanceledOrLapsed,
    });
  }, [shopId, trackViewedCampaignManagerState]);

  useEffect(() => {
    trackViewedCampaignManagerState({
      shopId: String(shopId),
      state: CampaignManagerState.CanceledOrLapsed,
    });
  }, [shopId, trackViewedCampaignManagerState]);

  return (
    <>
      <Alert alertClassName={styles.alert} variant="info">
        <span className={styles.discontinuedDate}>
          {`Your campaign was discontinued on ${formattedEndDate}.`}
        </span>
      </Alert>
      <ResultsTile
        campaignState={CampaignManagerState.CanceledOrLapsed}
        description={`${formattedStartDate} - ${formattedEndDate}`}
        hasEnded={hasEnded}
        isOptimizeMyCampaignLinkVisible={false}
        platformsInfo={summaryData?.platforms}
        results={summaryData?.lifetime}
        shopId={shopId}
        title="Previous Campaign Results"
      />
      <RenewYourCampaignTile shopId={String(shopId)} user={user} />
    </>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default InactiveCampaign;
