import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

import PasswordResetForm from 'components/password-reset-form';
import Page from 'components/shared/page';

const pageName = 'password-reset';

const PasswordReset = () => {
  const { search } = useLocation();
  const { email, token } = queryString.parse(search);

  return (
    <Page
      name={pageName}
      isNavShown={false}
      isHeaderShown={false}
      isFooterShown={false}
    >
      <PasswordResetForm email={email} passwordResetToken={token} />
    </Page>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default PasswordReset;
