import moment, { Moment } from 'moment-timezone';

import { OpenForTodaySelectors } from 'hooks/hours/use-open-for-today-selectors';
import { OrderStatus, ShopShippingStatus } from 'types/shop-hours';
import { isDateTomorrow } from 'utilities/date-time';

type GetShopShippingStatusParams = {
  openForTodaySelectors: OpenForTodaySelectors;
  shopTimezone: string;
};

export const getShopShippingStatus = ({
  openForTodaySelectors,
  shopTimezone,
}: GetShopShippingStatusParams): ShopShippingStatus => {
  const {
    isAcceptingDeliveryAndPickupOrders,
    isAcceptingDeliveryOrders,
    isAcceptingPickupOrders,
    isClosedForDelivery,
    isClosedForPickup,
    isPausedForDeliveryAndPickupOrders,
    isPausedForDeliveryOrders,
    isPausedForPickupOrders,
    isRegularScheduleDeliveryOpen,
    isRegularScheduleOpen,
    isRegularSchedulePickupOpen,
    nextOpenAt,
    nextOpenForDeliveryAt,
    nextOpenForPickupAt,
    unpausedAt,
  } = openForTodaySelectors;

  const nextOpenAtMoment = moment.tz(nextOpenAt, shopTimezone);
  const nextOpenForDeliveryAndPickupAtTimeString = nextOpenAtMoment.isValid()
    ? `until ${
        isDateTomorrow(nextOpenAtMoment, shopTimezone) ? 'tomorrow at ' : ''
      }${nextOpenAtMoment.format('hh:mma ddd, MMM D')}`
    : 'for at least 14 days';

  const nextOpenForDeliveryAtMoment = moment.tz(
    nextOpenForDeliveryAt,
    shopTimezone,
  );
  const nextOpenForDeliveryAtTimeString = nextOpenForDeliveryAtMoment.isValid()
    ? `until ${nextOpenForDeliveryAtMoment.format('hh:mma ddd, MMM D')}`
    : 'for at least 14 days';

  const nextOpenForPickupAtMoment = moment.tz(
    nextOpenForPickupAt,
    shopTimezone,
  );
  const nextOpenForPickupAtTimeString = nextOpenForPickupAtMoment.isValid()
    ? `until ${nextOpenForPickupAtMoment.format('hh:mma ddd, MMM D')}`
    : 'for at least 14 days';

  // For each shipping type, the order of preference for the status we should show is
  // Closed over Paused over Open

  let deliveryAndPickupStatus;

  if ((isClosedForDelivery && isClosedForPickup) || !isRegularScheduleOpen) {
    deliveryAndPickupStatus = OrderStatus.Closed;
  } else if (isPausedForDeliveryAndPickupOrders) {
    deliveryAndPickupStatus = OrderStatus.Paused;
  } else if (isAcceptingDeliveryAndPickupOrders) {
    deliveryAndPickupStatus = OrderStatus.Accepting;
  } else {
    deliveryAndPickupStatus = OrderStatus.Closed;
  }

  let deliveryStatus;

  if (isClosedForDelivery || !isRegularScheduleDeliveryOpen) {
    deliveryStatus = OrderStatus.Closed;
  } else if (isPausedForDeliveryOrders) {
    deliveryStatus = OrderStatus.Paused;
  } else if (isAcceptingDeliveryOrders) {
    deliveryStatus = OrderStatus.Accepting;
  } else {
    deliveryStatus = OrderStatus.Closed;
  }

  let pickupStatus;

  if (isClosedForPickup || !isRegularSchedulePickupOpen) {
    pickupStatus = OrderStatus.Closed;
  } else if (isPausedForPickupOrders) {
    pickupStatus = OrderStatus.Paused;
  } else if (isAcceptingPickupOrders) {
    pickupStatus = OrderStatus.Accepting;
  } else {
    pickupStatus = OrderStatus.Closed;
  }

  const shopStatus = {
    deliveryAndPickup: {
      status: deliveryAndPickupStatus,
      nextOpen: nextOpenForDeliveryAndPickupAtTimeString,
      isRegularScheduleOpen,
    },
    delivery: {
      status: deliveryStatus,
      nextOpen: nextOpenForDeliveryAtTimeString,
      isRegularScheduleOpen: isRegularScheduleDeliveryOpen,
    },
    pickup: {
      status: pickupStatus,
      nextOpen: nextOpenForPickupAtTimeString,
      isRegularScheduleOpen: isRegularSchedulePickupOpen,
    },
    pauseDetails: {
      endTime: unpausedAt,
    },
  };

  return shopStatus;
};

export const durationOptions = [
  { value: 15, label: '15 Minutes' },
  { value: 30, label: '30 Minutes' },
  { value: 45, label: '45 Minutes' },
  { value: 60, label: '1 Hour' },
] as const;

export const getPauseTimeRemainingInMinutesAndSeconds = (
  pauseEndTime: Moment,
): string => {
  const timeRemainingInSeconds = pauseEndTime.diff(moment.tz(), 'seconds');
  const minutes = `${Math.floor(timeRemainingInSeconds / 60)}`.padStart(2, '0');
  const seconds = `${timeRemainingInSeconds % 60}`.padStart(2, '0');

  return `${minutes}:${seconds}`;
};
