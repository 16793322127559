import RightArrow from 'images/right-arrow.svg?react';

import styles from './styles.module.scss';

type Props = {
  children: React.ReactNode;
};

const Insight = ({ children }: Props) => {
  return (
    <li className={styles.insight}>
      <RightArrow aria-hidden="true" className={styles.rightArrow} />
      <div className={styles.text}>{children}</div>
    </li>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default Insight;
