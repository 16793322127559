import { ComponentPropsWithoutRef } from 'react';
import cx from 'classnames';

import VisuallyHidden from 'components/shared/visually-hidden';

import styles from './styles.module.scss';

type Props = Omit<ComponentPropsWithoutRef<'div'>, 'children' | 'role'>;

const Spinner = ({ className, ...props }: Props) => (
  <div className={cx(styles.icon, className)} role="status" {...props}>
    <VisuallyHidden>Loading</VisuallyHidden>
  </div>
);

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default Spinner;
