import { MutableRefObject, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  DndContext,
  DragEndEvent,
  DraggableAttributes,
  DraggableSyntheticListeners,
  DragStartEvent,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { useQueryClient } from '@tanstack/react-query';
import cx from 'classnames';

import CollapsibleTile, {
  CollapsibleTileVariant,
} from 'components/shared/collapsible-tile';
import { DragButton } from 'components/shared/icon-button';
import SortableListItem from 'components/shared/sortable-list-item';
import SortableOverlay from 'components/shared/sortable-overlay';
import { getMenuQueryKey } from 'hooks/menu';
import { useProductSortMutation } from 'hooks/menu/use-product-sort-mutation';
import useAnalytics from 'hooks/use-analytics';
import useDebounce from 'hooks/use-debounce';
import { MenuResponseBody } from 'types/menu/api';
import { MenuProduct } from 'types/menu/product';
import { showUnexpectedErrorToast } from 'utilities/forms';

import MenuLayoutProduct from '../product';

import styles from './styles.module.scss';

type Props = {
  className?: string;
  draggableAttributes?: DraggableAttributes;
  draggableListeners?: DraggableSyntheticListeners;
  dragsRef: MutableRefObject<Set<number>>;
  id: number;
  isDragging?: boolean;
  isOverlay?: boolean;
  name: string;
  productIds: number[];
  productsById: Record<number, MenuProduct>;
  revisedMenuRef: MutableRefObject<MenuResponseBody | undefined>;
  shopId: string;
};

const MenuLayoutCategory = ({
  className,
  draggableAttributes,
  draggableListeners,
  dragsRef,
  id,
  isDragging = false,
  isOverlay = false,
  name,
  productIds,
  productsById,
  revisedMenuRef,
  shopId,
}: Props) => {
  const queryClient = useQueryClient();
  const { trackMenuSortUpdated } = useAnalytics();

  const [isOpen, setIsOpen] = useState(false);

  // Rendering the products even when the tile is closed causes a noticeable
  // performance issue when dragging. The closing animation requires the content
  // to still be visible, so we cannot rely on isOpen alone to lazily render.
  const [hasCollapsed, setHasCollapsed] = useState(!isOpen);

  const handleTransitionStateChange = (state: string) => {
    if (state === 'expandStart') {
      setHasCollapsed(false);
    } else if (state === 'collapseEnd') {
      setHasCollapsed(true);
    }
  };

  const { mutate: handleProductSort } = useProductSortMutation(shopId, id);

  const [sortedIds, setSortedIds] = useState(productIds);
  const debouncedSortedIds = useDebounce(sortedIds, 1000);

  useEffect(() => setSortedIds(productIds), [productIds]);

  useEffect(() => {
    if (dragsRef.current.has(id)) {
      dragsRef.current.delete(id);
      handleProductSort(debouncedSortedIds, {
        onSettled: (data, error, productIds) => {
          toast.dismiss();

          if (error) {
            showUnexpectedErrorToast();
            return;
          }

          toast.success('Products sorted!');
          trackMenuSortUpdated(shopId, false);

          if (data && revisedMenuRef.current?.relationships.categories) {
            // The default response depth does not include any product
            // information.
            const index =
              revisedMenuRef.current.relationships.categories.findIndex(
                (it) => it.id === data.category.id,
              );

            revisedMenuRef.current = {
              ...revisedMenuRef.current,
              relationships: {
                ...revisedMenuRef.current.relationships,
                categories: revisedMenuRef.current.relationships.categories.map(
                  (it, ix) => (ix === index ? { ...it, productIds } : it),
                ),
              },
            };
          }

          if (dragsRef.current.size === 0) {
            queryClient.setQueryData(
              getMenuQueryKey(shopId),
              revisedMenuRef.current,
            );
          }
        },
      });
    }
  }, [
    debouncedSortedIds,
    dragsRef,
    handleProductSort,
    id,
    queryClient,
    revisedMenuRef,
    shopId,
    trackMenuSortUpdated,
  ]);

  const findIndex = (id: number) => sortedIds.findIndex((it) => it === id);

  // No product will have a negative ID, so use -1 for none.
  const [activeProductId, setActiveProductId] = useState(-1);

  const activeProduct = productsById[activeProductId];

  const handleDragStart = ({ active }: DragStartEvent) => {
    setActiveProductId(Number(active?.id ?? -1));
  };

  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    setActiveProductId(-1);

    if (over == null) {
      return;
    }

    const activeIndex = findIndex(Number(active.id));
    const overIndex = findIndex(Number(over.id));

    if (activeIndex === overIndex) {
      return;
    }

    dragsRef.current.add(id);
    setSortedIds((current) => arrayMove(current, activeIndex, overIndex));
  };

  const handleDragCancel = () => {
    setActiveProductId(-1);
  };

  return (
    <CollapsibleTile
      bodyClassName={styles.body}
      className={cx(
        isDragging && styles.dragging,
        isOverlay && styles.overlay,
        className,
        styles.tile,
      )}
      description={`${productIds.length} items`}
      descriptionClassName={styles.description}
      headerClassName={styles.header}
      isOpen={isOpen}
      onTransitionStateChange={handleTransitionStateChange}
      setIsOpen={setIsOpen}
      title={name}
      titleClassName={styles.title}
      toggleBefore={
        <DragButton
          className={styles.handle}
          label={`Move ${name}`}
          {...draggableAttributes}
          {...draggableListeners}
        />
      }
      variant={CollapsibleTileVariant.Embedded}
    >
      {isOpen || !hasCollapsed ? (
        <DndContext
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
          onDragCancel={handleDragCancel}
        >
          <SortableContext
            items={sortedIds.map(String)}
            strategy={verticalListSortingStrategy}
          >
            <ol className={styles.products}>
              {sortedIds.map((it) => {
                const product = productsById[it];
                return product ? (
                  <SortableListItem id={it} key={it}>
                    <MenuLayoutProduct name={product.name} />
                  </SortableListItem>
                ) : null;
              })}
            </ol>
          </SortableContext>
          <SortableOverlay withPortal>
            {activeProduct ? (
              <MenuLayoutProduct name={activeProduct.name} />
            ) : null}
          </SortableOverlay>
        </DndContext>
      ) : null}
    </CollapsibleTile>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default MenuLayoutCategory;
