import { useMemo } from 'react';
import { Moment } from 'moment-timezone';

import {
  convertScheduleToTimezone,
  scheduleForDate,
} from 'components/hours/opening-status/overview/utils';
import { Schedule, ScheduleForDate } from 'types/shop-hours';
import { nowDateTime } from 'utilities/date-time';
import { createMomentComparator } from 'utilities/sorting';

type TodaysShippingTypeClosingTimes = {
  latestDeliveryTime?: Moment | undefined;
  latestPickupTime?: Moment | undefined;
  latestDeliveryAndPickupTime?: Moment | undefined;
};

export const useTodaysShippingTypeClosingTimes = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  shopSchedule: Schedule | undefined,
  shopTimezone: string,
): TodaysShippingTypeClosingTimes => {
  const todaysShopClosingTimes = useMemo(() => {
    if (!shopSchedule) {
      return {};
    }

    const today = nowDateTime(shopTimezone);
    const schedule = convertScheduleToTimezone(
      shopSchedule.schedule,
      shopTimezone,
    );

    const todaysSchedule: ScheduleForDate = scheduleForDate(schedule, today);
    const comparator = createMomentComparator('to');

    const todaysDeliveryEndTimes = todaysSchedule.delivery
      .sort(comparator)
      .map((delivery) => delivery.to);

    const todaysPickupEndTimes = todaysSchedule.pickup
      .sort(comparator)
      .map((pickup) => pickup.to);

    const latestDeliveryTime =
      todaysDeliveryEndTimes[todaysDeliveryEndTimes.length - 1];
    const latestPickupTime =
      todaysPickupEndTimes[todaysPickupEndTimes.length - 1];

    let latestDeliveryAndPickupTime;

    if (!latestDeliveryTime && !latestPickupTime) {
      latestDeliveryAndPickupTime = undefined;
    } else if (!latestDeliveryTime) {
      latestDeliveryAndPickupTime = latestPickupTime;
    } else if (!latestPickupTime) {
      latestDeliveryAndPickupTime = latestDeliveryTime;
    } else {
      latestDeliveryAndPickupTime = latestDeliveryTime.isSameOrBefore(
        latestPickupTime,
      )
        ? latestDeliveryTime
        : latestPickupTime;
    }

    return {
      latestDeliveryTime,
      latestPickupTime,
      latestDeliveryAndPickupTime,
    };
  }, [shopSchedule, shopTimezone]);

  return todaysShopClosingTimes;
};
