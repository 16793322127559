import { useMemo } from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

const CustomizationTitle = ({ name, selections }) => {
  const subtitle = useMemo(() => {
    const names = selections.map((it) => it.name);

    if (names.length < 4) {
      return <span className={styles.truncate}>{names.join(', ')}</span>;
    }

    return (
      <>
        <span className={styles.truncate}>{`${names[0]}, ${names[1]}`}</span>
        <span className={styles.extended}>{`+${names.length - 2} more`}</span>
      </>
    );
  }, [selections]);

  return (
    <>
      <span className={styles.truncate}>{name}</span>
      <span className={styles.subtitle}>{subtitle}</span>
    </>
  );
};

CustomizationTitle.propTypes = {
  name: PropTypes.string.isRequired,
  selections: PropTypes.array.isRequired,
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default CustomizationTitle;
