import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react';

type Props = {
  children: ReactNode;
  initialSelectedProductIds?: Set<number>; // For use in unit test setup
};

type ContextValue = {
  selectedProductIds: Set<number>;
  changeSelectedProductIds: (productIds: number[], selected: boolean) => void;
  clearSelectedProductIds: () => void;
};

const BulkEditContext = createContext<ContextValue | null>(null);

export const BulkEditProvider = ({
  children,
  initialSelectedProductIds = new Set<number>(),
}: Props) => {
  const [selectedProductIds, setSelectedProductIds] = useState(
    initialSelectedProductIds,
  );

  const changeSelectedProductIds = useCallback(
    (productIds: number[], selected: boolean) => {
      setSelectedProductIds((current) => {
        const next = new Set(current);
        const action = selected ? next.add : next.delete;

        productIds.forEach(action, next);
        return next;
      });
    },
    [],
  );

  const clearSelectedProductIds = useCallback(() => {
    setSelectedProductIds(new Set());
  }, []);

  return (
    <BulkEditContext.Provider
      value={{
        selectedProductIds,
        changeSelectedProductIds,
        clearSelectedProductIds,
      }}
    >
      {children}
    </BulkEditContext.Provider>
  );
};

export const useBulkEditContext = () => {
  const value = useContext(BulkEditContext);

  if (value == null) {
    throw new Error(
      'You must call useBulkEditContext within a BulkEditContext!',
    );
  }

  return value;
};
