import useStoredState, {
  SetStoredState,
  StoredState,
} from 'hooks/use-stored-state';
import { CustomerList, CustomerType, DateRange } from 'types/customers';

type CustomersFiltersState = {
  storedCustomerList: StoredState<CustomerList>;
  storedCustomerType: StoredState<CustomerType>;
  storedDateRange: StoredState<DateRange>;
};

type UseCustomersFiltersStorage = {
  setStoredCustomerList: SetStoredState<CustomerList>;
  setStoredCustomerType: SetStoredState<CustomerType>;
  setStoredDateRange: SetStoredState<DateRange>;
} & CustomersFiltersState;

export const CustomersFiltersStateKey = {
  customerList: 'customerList',
  customerType: 'customerType',
  customerDateRange: 'customerDateRange',
};

export const useCustomersFiltersStorage = (
  initialState?: CustomersFiltersState,
): UseCustomersFiltersStorage => {
  const [storedCustomerList, setStoredCustomerList] = useStoredState(
    CustomersFiltersStateKey.customerList,
    initialState?.storedCustomerList,
  );

  const [storedCustomerType, setStoredCustomerType] = useStoredState(
    CustomersFiltersStateKey.customerType,
    initialState?.storedCustomerType,
  );

  const [storedDateRange, setStoredDateRange] = useStoredState(
    CustomersFiltersStateKey.customerDateRange,
    initialState?.storedDateRange,
  );

  return {
    storedCustomerList,
    setStoredCustomerList,
    storedCustomerType,
    setStoredCustomerType,
    storedDateRange,
    setStoredDateRange,
  };
};
