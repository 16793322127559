import { useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Link } from 'crust';

import IconButton from 'components/shared/icon-button';
import PageHeader from 'components/shared/page/header';
import PageTitle from 'components/shared/page/title';
import VideoTutorialModal from 'components/shared/video-tutorial-modal';
import { useShopQuery } from 'hooks/shops';
import useAnalytics from 'hooks/use-analytics';
import QuestionIcon from 'images/question-icon.svg?react';
import * as paths from 'routes/paths';
import { generateShopMenuLink } from 'utilities/misc';

import styles from './styles.module.scss';

type Props = {
  shopId: string;
  viewMenuOnlineLinkLocation: string;
  helpVideo?: {
    videoSrc: string;
    modalHeader: string;
    videoTitle: string;
    analyticsPage: string;
    analyticsVideoType: string;
    analyticsVideoLocation: string;
  };
  title?: string;
};

const ConsumerMenuPageHeader = ({
  shopId,
  viewMenuOnlineLinkLocation,
  helpVideo,
  title = 'Menu',
}: Props) => {
  const { data: shop } = useShopQuery(shopId);

  const webSlug = shop?.webSlug;
  const {
    trackClickedViewMyMenuOnline,
    trackClickedAddNewCategory,
    trackClickedAddNewProduct,
    trackViewedVideoTutorial,
  } = useAnalytics();

  const { pathname } = useLocation();

  const [showModal, setShowModal] = useState(false);

  const handleClickViewMenu = () => {
    trackClickedViewMyMenuOnline(shopId, viewMenuOnlineLinkLocation);
  };

  const handleOpenNewCategoryPage = () => {
    trackClickedAddNewCategory({ shopId });
  };

  const handleOpenNewProductPage = () => {
    trackClickedAddNewProduct({ shopId });
  };

  const isAddItemEnabled = pathname === paths.getMenuItemsPath(shopId);
  const isAddCategoryEnabled = pathname === paths.getMenuCategoriesPath(shopId);

  const onClickHelpIcon = () => {
    trackViewedVideoTutorial({
      shopId,
      type: 'menu',
      page: helpVideo?.analyticsPage,
      videoType: helpVideo?.analyticsVideoType,
      videoLocation: helpVideo?.analyticsVideoLocation,
    });
    setShowModal(true);
  };

  return (
    <PageHeader className={styles.header} id="menu-page-header">
      <PageTitle className={styles.title}>{title}</PageTitle>
      <div className={styles.linksContainer}>
        {webSlug && (
          <Link
            href={generateShopMenuLink(webSlug)}
            icon="newWindow"
            onPress={handleClickViewMenu}
            variant="secondary"
          >
            View Online
          </Link>
        )}
        {isAddItemEnabled && (
          <Link
            href={paths.getMenuItemPath(shopId, 'new')}
            icon="plus"
            onPress={handleOpenNewProductPage}
            variant="secondary"
          >
            Create Item
          </Link>
        )}
        {isAddCategoryEnabled && (
          <Link
            href={paths.getMenuCategoryPath(shopId, 'new')}
            icon="plus"
            onPress={handleOpenNewCategoryPage}
            variant="secondary"
          >
            Create Category
          </Link>
        )}
        {helpVideo && (
          <IconButton
            label="Open help video"
            className={styles.helpIcon}
            icon={<QuestionIcon />}
            onClick={() => onClickHelpIcon()}
          />
        )}
      </div>
      {helpVideo && (
        <VideoTutorialModal
          modalHeader={helpVideo.modalHeader}
          onClose={() => setShowModal(false)}
          showModal={showModal}
          videoSrc={helpVideo.videoSrc}
          videoTitle={helpVideo.videoTitle}
        />
      )}
    </PageHeader>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default ConsumerMenuPageHeader;
