import { toDollarString, toMoneyString } from 'utilities/currency';

export const getProductPriceLabel = (prices: number[]) => {
  if (prices.length === 0) {
    return toMoneyString(toDollarString(0));
  }

  const minPrice = Math.min(...prices);
  const maxPrice = Math.max(...prices);

  if (minPrice === maxPrice) {
    return toMoneyString(toDollarString(minPrice));
  }

  return `${toMoneyString(toDollarString(minPrice))}+`;
};
