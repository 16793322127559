export enum CustomerReviewModerationStatus {
  Hidden = 'hidden',
  Pending = 'pending',
  Published = 'published',
}

export type CustomerReviewUser = {
  id: number;
  uuid: string;
  email: string;
  firstName: string;
  lastName: string;
};

export type CustomerReview = {
  body: string;
  createdAt: string;
  id: number;
  moderationStatus: CustomerReviewModerationStatus;
  rating: number;
  responses: CustomerReviewReply[];
  updatedAt: string;
  user: CustomerReviewUser;
  uuid: string;
};

export type CustomerReviewReply = {
  feedbackResponseId: number;
} & Omit<CustomerReview, 'rating' | 'responses'>;

export type CustomerReviewReplyFormValues = {
  reply: string;
};

export type CustomerReviewsRequestParams = {
  page: number;
  perPage: number;
  searchQuery: string;
  sort: string;
  startDate: string;
};

export type CustomerReviewReplyBody = {
  feedbackResponseReply: {
    body: string;
  };
};

export type CustomerReviewsResponse = {
  data: CustomerReview[];
  meta: {
    pagination: {
      pages: number;
      results: number;
    };
  };
};
