import { Stars } from 'components/customer-feedback/stars';

import styles from './styles.module.scss';

type Props = {
  rating?: number;
  ratingsCount?: number;
};

const ReviewSummary = ({ rating = 0, ratingsCount = 0 }: Props) => (
  <div
    className={styles.wrapper}
    data-chameleon-target="Customer Reviews Summary"
  >
    <span className={styles.title}> Total Rating </span>
    <div className={styles.summaryWrapper}>
      <div className={styles.summary}>
        <span className={styles.ratingNumber}> {rating.toFixed(1)} </span>
      </div>
      <div className={styles.ratings}>
        <Stars rating={rating} />
        <span>{`${ratingsCount} ratings`}</span>
      </div>
    </div>
  </div>
);

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default ReviewSummary;
