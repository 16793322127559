import moment from 'moment-timezone';

import Link from 'components/shared/link';
import * as externalUrls from 'routes/external-urls';

import styles from './styles.module.scss';

const PageFooter = () => (
  <div className={styles.footer}>
    <Link to={externalUrls.termsOfUse} className={styles.termsOfUse}>
      Terms of Use
    </Link>
    <span className={styles.copyright}>
      &copy; Copyright {moment().year()} Slice Solutions, Inc.
    </span>
  </div>
);

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default PageFooter;
