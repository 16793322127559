import { useLocation } from 'react-router-dom';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { Icon } from 'crust';

import Link from 'components/shared/link';
import CaretIcon from 'images/caret.svg?react';
import ExternalIcon from 'images/nav/external.svg?react';

import styles from './styles.module.scss';

const isItemActive = (pathname, url = '', activeWith = []) =>
  [...activeWith, url].some((item) =>
    item.test ? item.test(pathname) : item === pathname,
  ); // If item is a RegExp, test for a match.

const NavigationItem = ({
  activeWith,
  dataTestId,
  icon,
  items,
  label,
  trackNavigationItemClick,
  url,
}) => {
  const { pathname } = useLocation();
  const isExternalLink = url.startsWith('http');
  const isActive = items
    ? items.some((item) => isItemActive(pathname, item.url, item.activeWith))
    : isItemActive(pathname, url, activeWith);
  const iconStyle = cx(styles.icon, { [styles.activeIcon]: isActive });
  const externalStyle = cx(styles.external, { [styles.activeIcon]: isActive });
  const className = cx(styles.item, { [styles.activeItem]: isActive });
  const canShowSubNavItems = items && isActive;

  return (
    <>
      <Link
        className={className}
        data-chameleon-target={`Nav. Item ${label}`}
        to={url}
        onClick={() => trackNavigationItemClick([label], isExternalLink)}
        variant="undecorated"
        data-testid={dataTestId}
      >
        <Icon className={iconStyle} icon={icon} size="large" />
        <div className={styles.label}>
          {label}
          <div className={styles.wrapper}>
            {canShowSubNavItems && <CaretIcon className={styles.caret} />}
          </div>
        </div>
        {isExternalLink && <ExternalIcon className={externalStyle} />}
      </Link>
      {canShowSubNavItems && (
        <ul className={styles.subNavItems}>
          {items.map((item) => {
            const isSubActive = isItemActive(
              pathname,
              item.url,
              item.activeWith,
            );
            const subClassName = cx(styles.subNavItem, {
              [styles.activeSubItem]: isSubActive,
            });
            const isSubItemExternalLink =
              item.url && item.url.startsWith('http');

            return (
              <li className={styles.listItem} key={item.url}>
                <Link
                  className={subClassName}
                  data-chameleon-target={`Nav. Item ${item.label}`}
                  to={item.url}
                  onClick={() =>
                    trackNavigationItemClick(
                      [label, item.label],
                      isSubItemExternalLink,
                    )
                  }
                  variant="undecorated"
                >
                  {item.label}
                  {isSubItemExternalLink && (
                    <ExternalIcon className={externalStyle} />
                  )}
                </Link>
              </li>
            );
          })}
        </ul>
      )}
    </>
  );
};

NavigationItem.propTypes = {
  activeWith: PropTypes.array,
  dataTestId: PropTypes.string,
  icon: PropTypes.string,
  items: PropTypes.array,
  label: PropTypes.string.isRequired,
  trackNavigationItemClick: PropTypes.func,
  url: PropTypes.string.isRequired,
};

NavigationItem.defaultProps = {
  activeWith: [],
  Icon: null,
  items: null,
  trackNavigationItemClick: () => {},
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default NavigationItem;
