import { useQuery } from '@tanstack/react-query';
import queryString from 'query-string';

import useApi from 'hooks/use-api';
import { TipsAndEarningsResponseBody } from 'types/orders';
import { Shop } from 'types/shops';
import { camelCaseKeys, snakeCaseKeys } from 'utilities/objects';

type UseTipsAndEarningsData = {
  tips: string;
  earnings: string;
  voidedEarnings: string;
  totalOrders: string;
  averageOrder: number;
  totalVoidedOrders: number;
};

type UseTipsAndEarnings = {
  isLoading: boolean;
  data: UseTipsAndEarningsData;
};

const useTipsAndEarnings = (
  dates: {
    startDate: string;
    endDate: string;
  },
  shopId: Shop['shopId'] | string,
  enabled?: boolean,
) => {
  const { authenticatedFetch } = useApi();

  const { data, isLoading } = useQuery({
    queryKey: [shopId, 'tips-and-earnings', dates],
    queryFn: async () => {
      const queryStr = `?${queryString.stringify(snakeCaseKeys(dates))}`;

      const response = await authenticatedFetch.get(
        `api/management/v2/shops/${shopId}/orders/tips_and_earnings${queryStr}`,
      );

      return camelCaseKeys(response) as TipsAndEarningsResponseBody;
    },
    enabled,
  });

  return {
    data:
      isLoading || !data
        ? {
            tips: '0',
            earnings: '0',
            voidedEarnings: '0',
            totalOrders: '0',
            averageOrder: 0,
            totalVoidedOrders: 0,
          }
        : {
            tips: data?.tips,
            earnings: data?.earnings,
            voidedEarnings: data?.voidedEarnings,
            totalOrders: data?.totalOrders,
            totalVoidedOrders: data?.totalVoidedOrders,
            averageOrder:
              parseInt(data?.totalOrders) > 0
                ? Number(data?.earnings) / Number(data?.totalOrders)
                : 0,
          },
    isLoading,
  } as UseTipsAndEarnings;
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default useTipsAndEarnings;
