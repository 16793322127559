import { ElementRef, ForwardedRef, forwardRef } from 'react';
import {
  Button,
  ButtonContext,
  ButtonProps,
  Link,
  LinkContext,
  LinkProps,
  useContextProps,
} from 'react-aria-components';
import { Merge } from 'type-fest';

import { Icon, type IconProps } from '../icon/icon';
import { getCrustClassName } from '../utilities/get-crust-class-name';
import { mergeAriaClassName } from '../utilities/merge-aria-class-name';

import './icon-button-link.css';

export type IconButtonProps = Merge<
  Omit<ButtonProps, 'children'>,
  Pick<IconProps, 'icon'>
>;

const getIconButtonClassName = getCrustClassName.bind(null, 'icon-button');

export const IconButton = forwardRef(function IconButton(
  { className, icon, ...props }: IconButtonProps,
  ref: ForwardedRef<ElementRef<typeof Button>>,
) {
  const [buttonProps] = useContextProps(props, ref, ButtonContext);

  // Normally, React Aria Button would catch a missing label (via useLabel), but
  // that check fails because we are providing the icon as children.
  if (!buttonProps['aria-label'] && !buttonProps['aria-labelledby']) {
    console.warn(
      'IconButton requires an aria-label or aria-labelledby attribute for accessibility.',
    );
  }

  return (
    <Button
      className={mergeAriaClassName(getIconButtonClassName(), className)}
      ref={ref}
      {...props}
    >
      <Icon icon={icon} />
    </Button>
  );
});

export type IconLinkProps = Merge<
  Omit<LinkProps, 'children'>,
  Pick<IconProps, 'icon'>
>;

const getIconLinkClassName = getCrustClassName.bind(null, 'icon-link');

export const IconLink = forwardRef(function IconLink(
  { className, icon, ...props }: IconLinkProps,
  ref: ForwardedRef<ElementRef<typeof Link>>,
) {
  const [linkProps] = useContextProps(props, ref, LinkContext);

  if (!linkProps['aria-label'] && !linkProps['aria-labelledby']) {
    console.warn(
      'IconLink requires an aria-label or aria-labelledby attribute for accessibility.',
    );
  }

  return (
    <Link
      className={mergeAriaClassName(getIconLinkClassName(), className)}
      ref={ref}
      {...props}
    >
      <Icon icon={icon} />
    </Link>
  );
});
