import cx from 'classnames';

import CaretIcon from 'images/caret.svg?react';
import { range } from 'utilities/lists';

import styles from './styles.module.scss';

type Props = {
  containerClassName?: string;
  currentPage: number;
  currentPageClassName?: string;
  navArrowClassName?: string;
  navArrowContainerClassName?: string;
  pageClassName?: string;
  setPage: (page: number) => void;
  totalPages: number;
};

const PageControl = ({
  containerClassName,
  currentPage,
  currentPageClassName,
  navArrowClassName,
  navArrowContainerClassName,
  pageClassName,
  setPage,
  totalPages,
}: Props) => {
  const Ellipsis = () => <span>...</span>;

  const generatePages = () => {
    const lowestLimit =
      currentPage === 1
        ? 1
        : currentPage === totalPages
          ? Math.max(1, currentPage - 2)
          : currentPage - 1;
    const highestLimit =
      currentPage === totalPages
        ? totalPages
        : currentPage === 1
          ? Math.min(totalPages, currentPage + 2)
          : currentPage + 1;

    const pages = range(lowestLimit, highestLimit + 1);
    return (
      <>
        {lowestLimit !== 1 && (
          <button
            key={`btn-page-1`}
            onClick={() => {
              setPage(1);
            }}
            className={cx(
              styles.button,
              pageClassName,
              currentPage === 1 &&
                (currentPageClassName ? currentPageClassName : styles.active),
            )}
          >
            1
          </button>
        )}
        {lowestLimit > 2 && <Ellipsis />}
        {pages.map((pageElement) => (
          <button
            key={`btn-page-${pageElement}`}
            onClick={() => {
              setPage(pageElement);
            }}
            className={cx(
              styles.button,
              pageClassName,
              currentPage === pageElement
                ? currentPageClassName
                  ? currentPageClassName
                  : styles.active
                : '',
            )}
          >
            {pageElement}
          </button>
        ))}
        {highestLimit < totalPages - 1 && <Ellipsis />}
        {highestLimit < totalPages && (
          <button
            key={`btn-page-${totalPages}`}
            onClick={() => {
              setPage(totalPages);
            }}
            className={cx(
              styles.button,
              pageClassName,
              currentPage === totalPages &&
                (currentPageClassName ? currentPageClassName : styles.active),
            )}
          >
            {totalPages}
          </button>
        )}
      </>
    );
  };

  const NextBtn = () => (
    <button
      className={cx(styles.button, styles.next, navArrowContainerClassName)}
      disabled={currentPage === totalPages}
      onClick={() => {
        setPage(currentPage + 1);
      }}
    >
      <CaretIcon className={navArrowClassName} title="Next page" />
    </button>
  );

  const PrevBtn = () => (
    <button
      className={cx(styles.button, styles.prev, navArrowContainerClassName)}
      disabled={currentPage === 1}
      onClick={() => {
        setPage(currentPage - 1);
      }}
    >
      <CaretIcon className={navArrowClassName} title="Previous page" />
    </button>
  );

  return totalPages > 1 ? (
    <nav
      aria-label="Page selection area"
      className={containerClassName ? containerClassName : styles.pagination}
    >
      <PrevBtn />
      <div
        className={cx(
          styles.pagesWrapper,
          totalPages < 5 && totalPages >= 3
            ? styles.contentWidth
            : totalPages === 2
              ? styles.contentMinWidth
              : '',
        )}
      >
        {generatePages()}
      </div>
      <NextBtn />
    </nav>
  ) : (
    <></>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default PageControl;
