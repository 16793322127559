// Return a shallow copy of the list with the item appended to the end.
export const insert = <T>(list: T[], item: T): T[] => [...list, item];

// Return a shallow copy of the list with the entry for the item either updated
// or the item appended to the end. The third argument can be used to customive
// how a matching entry is found.
export const upsertBy = <T, K extends keyof T>(
  list: T[],
  item: T,
  key: K,
): T[] => {
  const copy = list.slice();
  const index = copy.findIndex((it) => it[key] === item[key]);

  if (index === -1) {
    copy.push(item);
  } else {
    copy[index] = item;
  }

  return copy;
};

// Return a shallow copy of the list with the item that matches the key/value
// pair removed. Mostly just shorthand for simple uses of Array.filter.
export const remove = <T, K extends keyof T, V extends T[K]>(
  list: T[],
  key: K,
  value: V,
): T[] => list.filter((item) => item[key] !== value);

// Object/Record keys can only be symbols, numbers, or strings, though
// non-symbols are converted to strings, anyway.
type AsKey<T> = T extends symbol ? symbol : T extends number ? number : string;

// Return an object that represents the items of the given list indexed by the
// specified key. The key can be a property name or function. Useful for
// avoiding repeated Array.find operations.
export const indexBy = <T, K extends keyof T | ((it: T) => unknown)>(
  list: T[],
  key: K,
): Record<
  AsKey<K extends (it: T) => infer R ? R : K extends keyof T ? T[K] : never>,
  T
> => {
  return Object.fromEntries(
    list.map((it) => [
      typeof key === 'function' ? key(it) : it[key as keyof T],
      it,
    ]),
  );
};

// An implementation of Array.group until the polyfill is included. Please don't
// use the Lodash version as it does not align with this proposal.
// https://github.com/tc39/proposal-array-grouping
export const groupBy = <
  Element,
  Callback extends (
    element: Element,
    index?: number,
    array?: Element[],
  ) => PropertyKey,
  // We want the return Record key type to be be as narrow as possible.
  Key extends PropertyKey = Extract<PropertyKey, ReturnType<Callback>>,
>(
  array: Element[],
  callbackFn: Callback,
): Record<Key, Element[]> =>
  array.reduce(
    (result, item, index, array) => {
      const key = callbackFn(item, index, array) as Key;

      result[key] ??= [];
      result[key].push(item);

      return result;
    },
    {} as Record<Key, Element[]>,
  );

// Creates an array of numbers starting at start, up to but not including end, incrementing by the step
// value if provided, or 1 if not.
export const range = (start: number, end: number, step = 1) => {
  return Array.from(
    { length: Math.ceil((end - start) / step) },
    (_, i) => start + i * step,
  );
};
