import { Control, useWatch } from 'react-hook-form';
import cx from 'classnames';

import { customWebsiteDemo as customWebsiteDemoURL } from 'routes/external-urls';
import {
  Breakpoint,
  FontColorCodes,
  SiteEditorFormValues,
} from 'types/site-editor';
import { getDirectWebUrl } from 'utilities/site-editor';

import styles from './styles.module.scss';

type Props = {
  breakpoint: Breakpoint;
  control: Control<SiteEditorFormValues>;
  domainName: string | null;
};

const SitePreviewIframe = ({ breakpoint, control, domainName }: Props) => {
  const selectedColorCode = useWatch({
    control,
    name: 'colorCode',
  });

  const selectedFontCode = useWatch({
    control,
    name: 'fontCode',
  });

  const iframeSrc = domainName
    ? getDirectWebUrl(domainName, selectedColorCode, selectedFontCode)
    : `${customWebsiteDemoURL}?color=${selectedColorCode}&font_family=${selectedFontCode}&font_color=${FontColorCodes.White}`;

  return (
    <iframe
      className={cx(
        styles.sitePreview,
        breakpoint === 'desktop' && styles.desktop,
        breakpoint === 'tablet' && styles.tablet,
        breakpoint === 'phone' && styles.phone,
      )}
      scrolling="no"
      src={iframeSrc}
      title="Site Preview"
    />
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default SitePreviewIframe;
