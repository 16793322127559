import { ElementRef, ForwardedRef, forwardRef } from 'react';
import {
  FieldError as AriaFieldError,
  FieldErrorProps as AriaErrorProps,
} from 'react-aria-components';

import { getCrustClassName } from '../utilities/get-crust-class-name';
import { mergeAriaClassName } from '../utilities/merge-aria-class-name';

import './field-error.css';

export type FieldErrorProps = AriaErrorProps;

export const FieldError = forwardRef(function FieldError(
  { className, ...props }: FieldErrorProps,
  ref: ForwardedRef<ElementRef<typeof AriaFieldError>>,
) {
  return (
    <AriaFieldError
      className={mergeAriaClassName(
        getCrustClassName('field-error'),
        className,
      )}
      ref={ref}
      {...props}
    />
  );
});
