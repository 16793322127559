import MenuCategoriesPageContent from 'components/menu/categories';
import ConsumerMenuPageHeader from 'components/menu/consumer-page-header';
import { UnexpectedError } from 'components/shared/error';
import Loading from 'components/shared/loading';
import Page from 'components/shared/page';
import { useMenuQuery } from 'hooks/menu/use-menu-query';
import { useCurrentShopId } from 'hooks/shops';
import { viewMyMenuOnlineLinkLocations } from 'utilities/constants';
import VideoTutorials from 'utilities/video-tutorials';

const MenuCategoriesPage = () => {
  const shopId = useCurrentShopId();

  // We fetch the menu here because the categories-specific endpoint does not
  // return products as well, so we wouldn't be able to display a product count
  // in the table. The data is almost identical anyway.
  const {
    data: menuData,
    isError: isMenuError,
    isLoading: isMenuLoading,
  } = useMenuQuery(shopId);

  let content;
  if (isMenuLoading) {
    content = <Loading />;
  } else if (isMenuError) {
    content = <UnexpectedError />;
  } else {
    content = <MenuCategoriesPageContent menuData={menuData} shopId={shopId} />;
  }

  return (
    <Page
      name="menu-categories"
      customPageHeader={
        <ConsumerMenuPageHeader
          shopId={String(shopId)}
          viewMenuOnlineLinkLocation={
            viewMyMenuOnlineLinkLocations.menuCategoriesPage
          }
          helpVideo={{
            modalHeader: 'How to add and edit a category',
            videoSrc: VideoTutorials.AddOrEditMenuCategory,
            videoTitle: 'How to add and edit a category',
            analyticsPage: 'Category',
            analyticsVideoType: 'Menu Category',
            analyticsVideoLocation: 'Category Header',
          }}
        />
      }
      isHeaderShown
    >
      {content}
    </Page>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default MenuCategoriesPage;
