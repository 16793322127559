import { Control, useWatch } from 'react-hook-form';

import { PromoCodeFormValues } from 'types/discounts';

type Props = {
  control: Control<PromoCodeFormValues>;
};

export const TagSummary = ({ control }: Props) => {
  const tag = useWatch({
    control,
    name: 'tag',
  });

  // Note the null coalescing operator is not used here to match empty string.
  return <>{tag || null}</>;
};
