import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import RegisterDiscountForm from 'components/register/discount/form';
import RegisterDiscountFormHeader from 'components/register/discount/form-header';
import SubmitFooter from 'components/shared/submit-footer';
import { useSaveRegisterDiscountMutation } from 'hooks/discounts/use-save-register-discount-mutation';
import * as paths from 'routes/paths';
import {
  RegisterDiscount as IRegisterDiscount,
  RegisterDiscountFormValues,
} from 'types/discounts';
import { Shop } from 'types/shops';
import { getDefaultRegisterDiscountFormValues } from 'utilities/discounts';
import {
  showInvalidSubmitToast,
  showUnexpectedErrorToast,
} from 'utilities/forms';

type Props = {
  discount: IRegisterDiscount | null;
  isRosEnabled: boolean;
  shopId: Shop['shopId'];
  shopTimezone: Shop['timezoneIdentifier'];
};

const RegisterDiscount = ({
  discount,
  isRosEnabled,
  shopId,
  shopTimezone,
}: Props) => {
  const navigate = useNavigate();

  const {
    control,
    formState: { errors, isSubmitting },
    getValues,
    handleSubmit,
    register,
    setValue,
  } = useForm<RegisterDiscountFormValues>({
    defaultValues: getDefaultRegisterDiscountFormValues(discount, shopTimezone),
    mode: 'onTouched',
  });

  const {
    mutate: saveDiscount,
    isError: isDiscountError,
    isLoading: isDiscountSaving,
    isSuccess: isDiscountSaved,
  } = useSaveRegisterDiscountMutation(shopId, shopTimezone);

  useEffect(() => {
    if (isDiscountError) {
      showUnexpectedErrorToast();
    }
  }, [isDiscountError]);

  useEffect(() => {
    if (isDiscountSaved) {
      toast.success('Register discount saved!');
      navigate(paths.registerDiscounts(shopId));
    }
  }, [isDiscountSaved, navigate, shopId]);

  const handleValidSubmit = (values: RegisterDiscountFormValues) =>
    saveDiscount(values);

  const handleInvalidSubmit = () => {
    showInvalidSubmitToast();
  };

  const handleCancel = () => {
    navigate(-1);
  };

  return (
    <>
      <RegisterDiscountFormHeader isNew={discount == null} />
      <RegisterDiscountForm
        control={control}
        errors={errors}
        isRosEnabled={isRosEnabled}
        getValues={getValues}
        onSubmit={handleSubmit(handleValidSubmit, handleInvalidSubmit)}
        register={register}
        setValue={setValue}
        shopTimezone={shopTimezone}
      >
        <SubmitFooter
          onCancel={handleCancel}
          isSubmitDisabled={isSubmitting || isDiscountSaving}
          isSubmitting={isSubmitting || isDiscountSaving}
          submitButtonChameleonTarget="Discount Edit"
        />
      </RegisterDiscountForm>
    </>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default RegisterDiscount;
