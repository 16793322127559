import * as React from "react";
import { forwardRef } from "react";
const SvgRightArrow = ({
  title,
  titleId,
  ...props
}, ref) => {
  return /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", fill: "none", viewBox: "0 0 16 16", ref, "aria-labelledby": titleId, ...props }, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { stroke: "currentColor", strokeWidth: 2, d: "m8.66671 4.3335 3.66669 3.66666          L8.66671 11.6668          m3.66669 -3.66664          H2.66671" }));
};
const ForwardRef = forwardRef(SvgRightArrow);
export default ForwardRef;
