import { useMutation, useQueryClient } from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { Shop } from 'types/shops/shop';

import { getQueryKey as getGoogleConnectionQueryKey } from './use-shop-has-google-connection';

export const useDeleteShopGoogleConnectionMutation = () => {
  const queryClient = useQueryClient();
  const { authenticatedFetch } = useApi();

  return useMutation({
    mutationFn: async ({ shopId }: { shopId: Shop['shopId'] }) => {
      const response = await authenticatedFetch.delete(
        `api/management/v1/shops/${shopId}/google_oauth_token`,
      );
      return response;
    },
    onSuccess(data, variables) {
      queryClient.invalidateQueries(
        getGoogleConnectionQueryKey(variables.shopId),
      );
    },
  });
};
