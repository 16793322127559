import { useMemo } from 'react';
import { SingleValue } from 'react-select';
import cx from 'classnames';

import SharedCategorySelect, {
  CategoryOption,
} from 'components/shared/category-select';
import Label from 'components/shared/label';
import { useMenuQuery } from 'hooks/menu';

import styles from './styles.module.scss';

type InputId = 'highest-selling' | 'lowest-selling';

type Props = {
  className?: string;
  shopId: string;
  inputId: InputId;
  onChange: (selectedCategory: CategoryOption) => void;
  value: number;
};

const CategorySelect = ({
  className = '',
  shopId,
  inputId,
  onChange,
  value,
}: Props) => {
  const { data: menu } = useMenuQuery(shopId);

  const options = useMemo(() => {
    const categories = menu?.relationships.categories ?? [];

    const options = categories
      .filter((it) => it.productIds?.length)
      .map((it) => ({
        label: it.name,
        value: it.id,
        length: it.productIds?.length,
      }));

    return [
      ...options,
      { label: 'All Categories', length: -1, value: -1 },
    ] as CategoryOption[];
  }, [menu]);

  const handleChange = (option: SingleValue<CategoryOption>) => {
    if (option) {
      onChange(option);
    }
  };

  return (
    <div className={cx(styles.categorySelectWrapper, className)}>
      <Label
        htmlFor={`${inputId}-category-select`}
        id={`${inputId}-category-select-label`}
        className={styles.categoryLabel}
      >
        Category
      </Label>
      <SharedCategorySelect
        aria-labelledby={`${inputId}-category-select-label`}
        aria-required="true"
        inputId={`${inputId}-category-select`}
        name={`${inputId}-category-select`}
        onChange={handleChange}
        options={options}
        showCount={false}
        value={options.find((option) => option.value === value)}
      />
    </div>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default CategorySelect;
