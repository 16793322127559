import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
  getRegisterDiscountQueryKey,
  getRegisterDiscountsQueryKey,
} from 'hooks/discounts';
import useAnalytics from 'hooks/use-analytics';
import useApi from 'hooks/use-api';
import { RegisterDiscount, RegisterDiscountFormValues } from 'types/discounts';
import { Shop } from 'types/shops';
import { getRegisterDiscountBody } from 'utilities/discounts';
import { upsertBy } from 'utilities/lists';
import { camelCaseKeys, snakeCaseKeys } from 'utilities/objects';

export const useSaveRegisterDiscountMutation = (
  shopId: Shop['shopId'],
  shopTimezone: Shop['timezoneIdentifier'],
) => {
  const { authenticatedFetch } = useApi();
  const queryClient = useQueryClient();
  const { trackCreateRegisterDiscount, trackEditRegisterDiscount } =
    useAnalytics();

  return useMutation({
    mutationFn: async (values: RegisterDiscountFormValues) => {
      const body = snakeCaseKeys(getRegisterDiscountBody(values, shopTimezone));

      const response = values.id
        ? await authenticatedFetch.put(
            `api/management/v1/ros/shops/${shopId}/discounts/${values.id}`,
            body,
          )
        : await authenticatedFetch.post(
            `api/management/v1/ros/shops/${shopId}/discounts`,
            body,
          );

      return camelCaseKeys(response) as RegisterDiscount;
    },
    onSuccess: (data, values) => {
      // Update the entry for this discount in the cached list.
      queryClient.setQueryData<RegisterDiscount[]>(
        getRegisterDiscountsQueryKey(shopId),
        (current) => upsertBy(current ?? [], data, 'id'),
      );

      // Update the cache entry for this single discount.
      queryClient.setQueryData(
        getRegisterDiscountQueryKey(shopId, data.id),
        data,
      );

      if (values.id) {
        trackEditRegisterDiscount({
          name: data.name,
          shopId: String(shopId),
          success: true,
          type: data.type,
        });
      } else {
        trackCreateRegisterDiscount({
          name: data.name,
          shopId: String(shopId),
          success: true,
          type: data.type,
        });
      }
    },
  });
};
