import { mobileDesktopThresholdWidth } from 'utilities/constants';

export const open = (destination) => window.open(destination);

export const getWindowSize = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return { width, height, isMobile: width < mobileDesktopThresholdWidth };
};

export const scrollToTop = (win = window) => win.scrollTo(0, 0);
