import { MenuCategory } from 'types/menu/category';
import { MenuProduct } from 'types/menu/product';

import { Id, shop } from './shop';

const getMenuBasePath = (shopId?: Id) => `${shop(shopId)}/menu`;

export const getMenuItemsPath = (shopId?: Id) =>
  `${getMenuBasePath(shopId)}/items`;

export const getMenuItemPath = (
  shopId?: Id,
  productId: MenuProduct['id'] | string = ':productId',
) => `${getMenuItemsPath(shopId)}/${productId}`;

export const getMenuCategoriesPath = (shopId?: Id) =>
  `${getMenuBasePath(shopId)}/categories`;

export const getMenuCategoryPath = (
  shopId?: Id,
  categoryId: MenuCategory['id'] | string = ':categoryId',
) => `${getMenuCategoriesPath(shopId)}/${categoryId}`;

export const getMenuLayoutPath = (shopId?: Id) =>
  `${getMenuBasePath(shopId)}/layout`;
