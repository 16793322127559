import {
  AverageOrder,
  TotalOrders,
  TotalSales,
  VoidedSales,
} from 'components/shared/metric-tiles';
import { Shop } from 'types/shops';

import styles from './styles.module.scss';

type Props = {
  activeDates: {
    current: {
      startDate: string;
      endDate: string;
    };
    previous: {
      startDate: string;
      endDate: string;
    };
  };
  shopId: Shop['shopId'];
  shopTimezone: Shop['timezoneIdentifier'];
};

export const MetricsPanel = ({ activeDates, shopId, shopTimezone }: Props) => {
  return (
    <div className={styles.wrapper}>
      <TotalOrders
        className={styles.metricTile}
        shopId={shopId}
        activeDates={activeDates}
        shopTimezone={shopTimezone}
      />
      <TotalSales
        className={styles.metricTile}
        shopId={shopId}
        activeDates={activeDates}
        shopTimezone={shopTimezone}
      />
      <AverageOrder
        className={styles.metricTile}
        shopId={shopId}
        activeDates={activeDates}
        shopTimezone={shopTimezone}
      />
      <VoidedSales
        className={styles.metricTile}
        shopId={shopId}
        activeDates={activeDates}
        dataChameleonTarget="Voided Sales Dashboard Tile"
      />
    </div>
  );
};
