import { useParams } from 'react-router-dom';

import { Shop } from 'types/shops';

type Params = {
  shopId: string;
};

export const useCurrentShopId = (): Shop['shopId'] => {
  const params = useParams<Params>();

  // URL parameters will always be parsed as strings, but shop IDs are numbers
  // in the data model.
  return Number(params.shopId);
};
