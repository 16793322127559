import ContentTile from 'components/shared/content-tile';

import styles from './styles.module.scss';

type Props = {
  content: string;
  imgUrl: string;
  summaryPoints: string[];
};

export const SummaryTile = ({ content, imgUrl, summaryPoints }: Props) => {
  return (
    <ContentTile className={styles.tile}>
      <div className={styles.header}>Summary</div>
      <div className={styles.content}>
        <ul>
          {summaryPoints.map((point) => (
            <li key={point}>{point}</li>
          ))}
          <li>Funded by you</li>
        </ul>
      </div>
      <div className={styles.content}>{content}</div>
      <img alt="discounts" className={styles.img} src={imgUrl} />
    </ContentTile>
  );
};
