import { ComponentPropsWithoutRef, createElement, CSSProperties } from 'react';
import cx from 'classnames';

import styles from './styles.module.scss';

export type TextAs = keyof JSX.IntrinsicElements;

type BaseProps<As extends TextAs = 'div'> = {
  /**
   * Set the HTML tag of the outer element.
   */
  as?: As;
};

type OverflowProps = {
  /**
   * Clamp the number of lines to the specified limit. Setting this property to
   * true is a shorthand for clamping to a single line. Clamping occurs
   * separately from the treatment of long words. For example, you can use both
   * word wrapping and clamping. Most use cases for clamping call for keeping
   * text to a single line.
   */
  clamp?: boolean | number;

  /**
   * Specify the wrapping behavior of long words. If you are clamping to a
   * single line, this property will be ignored.
   */
  wrap?: 'break' | 'hyphens' | 'normal' | 'truncate';
};

type ElementProps<As extends TextAs = 'div'> = Omit<
  ComponentPropsWithoutRef<As>,
  keyof BaseProps<As> | keyof OverflowProps
>;

export type TextProps<As extends TextAs = 'div'> = BaseProps<As> &
  OverflowProps &
  ElementProps<As>;

/**
 * A general purpose component for wrapping text nodes with defaults and
 * utilities for controlling text wrapping, line clamping, and more. Eventually
 * this component will be expanded to handle more typography properties like
 * font variants and text-transform, as well as localizations.
 */
const Text = <As extends TextAs = 'div'>({
  as,
  clamp = false,
  wrap = 'hyphens',
  className,
  ...props
}: TextProps<As>) => {
  const resolvedClamp =
    typeof clamp === 'number' ? Math.trunc(clamp) : Number(!!clamp);
  const isClamped = !Number.isNaN(resolvedClamp) && resolvedClamp > 0;
  const isClampedOne = isClamped && resolvedClamp === 1;
  const isClampedMany = isClamped && resolvedClamp > 1;

  const resolvedClassName = cx(
    isClampedOne && styles.clampOne,
    isClampedMany && styles.clampMany,
    wrap ? styles[wrap] : styles.nowrap,
    className,
  );

  const style = {
    '--clamp': isClampedMany ? resolvedClamp : undefined,
  } as CSSProperties;

  return createElement(as ?? 'div', {
    style,
    className: resolvedClassName,
    ...props,
  });
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default Text;
