import { useMemo } from 'react';
import { createColumnHelper } from '@tanstack/react-table';

import Table from 'components/shared/table';
import TableAction from 'components/shared/table/action';
import Text from 'components/shared/text';
import VisuallyHidden from 'components/shared/visually-hidden';
import { useTable } from 'hooks/shared';
import useAnalytics from 'hooks/use-analytics';
import * as paths from 'routes/paths';
import { RegisterUserRowData } from 'types/register-users';
import { formatUSPhoneNumber } from 'utilities/strings';

import styles from './styles.module.scss';

type Props = {
  shopId: string;
  usersRowData: RegisterUserRowData[];
};

const RegisterUsersTable = ({ shopId, usersRowData }: Props) => {
  const { trackClickedEditRegisterUserProfile } = useAnalytics();

  const columns = useMemo(() => {
    const helper = createColumnHelper<RegisterUserRowData>();

    return [
      helper.accessor('name', {
        cell: (ctx) => {
          return <Text wrap="truncate">{ctx.getValue()}</Text>;
        },
        enableSorting: true,
        header: 'Name',
        id: 'name',
      }),
      helper.accessor('email', {
        cell: (ctx) => {
          const { email, phone } = ctx.row.original;

          return (
            <>
              <Text clamp>{email ?? ''}</Text>
              <Text clamp>
                {phone ? (formatUSPhoneNumber(phone) ?? '') : ''}
              </Text>
            </>
          );
        },
        enableSorting: false,
        header: 'Email & Phone',
        id: 'emailAndPhone',
      }),
      helper.accessor((row) => row.permissionGroupNames.join(', '), {
        cell: (ctx) => {
          return <Text wrap="truncate">{ctx.getValue()}</Text>;
        },
        enableSorting: false,
        header: 'Permissions',
        id: 'permissions',
      }),
      helper.accessor((row) => (row.pin ? `${row.pin.slice(0, 2)}**` : ''), {
        header: 'PIN Number',
        enableSorting: false,
        id: 'pin',
      }),
      helper.display({
        id: 'actions',
        enableSorting: false,
        header() {
          return <VisuallyHidden>Actions</VisuallyHidden>;
        },
        cell(ctx) {
          const { id, name } = ctx.row.original;

          return (
            <TableAction
              icon="pencil"
              onClick={() => {
                trackClickedEditRegisterUserProfile({
                  shopId,
                  userName: name,
                  userId: id,
                });
              }}
              title="Edit Profile"
              to={paths.registerUser(shopId, id)}
            />
          );
        },
        meta: {
          isActionColumn: true,
        },
      }),
    ];
  }, [shopId, trackClickedEditRegisterUserProfile]);

  const table = useTable({
    columns,
    data: usersRowData,
    initialState: {
      sorting: [
        {
          id: 'name',
          desc: false,
        },
      ],
    },
    chameleonTableTitle: 'Register Users Table',
  });

  return <Table className={styles.table} table={table} />;
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default RegisterUsersTable;
