import { useCallback, useEffect, useState } from 'react';
import {
  Control,
  FieldError,
  UseFormGetValues,
  useFormState,
  useWatch,
} from 'react-hook-form';

import CollapsibleTile from 'components/shared/collapsible-tile';
import { RHFNumberField } from 'components/shared/rhf-number-field';
import { RHFRadio, RHFRadioGroup } from 'components/shared/rhf-radio';
import {
  RegisterDiscountFormValues,
  RegisterDiscountType,
} from 'types/discounts';

import TypeFieldsSummary from './summary';

import styles from './styles.module.scss';

type Props = {
  amountError?: FieldError;
  className?: string;
  control: Control<RegisterDiscountFormValues>;
  getValues: UseFormGetValues<RegisterDiscountFormValues>;
  isRosEnabled: boolean;
};

const TypeFields = ({
  amountError,
  className,
  control,
  getValues,
  isRosEnabled,
}: Props) => {
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    if (amountError) {
      setIsOpen(true);
    }
  }, [amountError]);

  const type = useWatch({ control, name: 'type' });

  const isFixed = type === RegisterDiscountType.Fixed;
  const isFlexible = type === RegisterDiscountType.Flexible;

  const validateAmount = useCallback(
    (value: number) => {
      const type = getValues('type');
      const isPercent = type === RegisterDiscountType.Percent;

      if (Number.isNaN(value)) {
        return 'Please enter an amount.';
      }

      if (value < 0.01) {
        return `Must be at least ${isPercent ? '0.01%' : '$0.01'}.`;
      }

      if (isPercent && value > 100) {
        return 'Cannot be greater than 100%.';
      }

      return true;
    },
    [getValues],
  );

  const { touchedFields } = useFormState({ control });

  return (
    <CollapsibleTile
      bodyChameleonTarget="Register Discount Type Fields"
      bodyClassName={styles.body}
      className={className}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      summary={<TypeFieldsSummary control={control} />}
      title="Select Type"
    >
      <RHFRadioGroup
        name="type"
        control={control}
        label="What type of discount do you want to add?"
        orientation="horizontal"
        variant="card"
        rules={{
          deps: touchedFields.amount ? ['amount'] : undefined,
        }}
      >
        <RHFRadio
          description="Offer dollar-based discounts (e.g. $5 Off)"
          label="Fixed Amount"
          value={RegisterDiscountType.Fixed}
        />
        <RHFRadio
          description="Offer percent-based discounts (e.g. 5% Off)"
          label="Percentage"
          value={RegisterDiscountType.Percent}
        />
        {isRosEnabled && (
          <RHFRadio
            description="Offer a variable priced discount of any type."
            label="Variable Discount"
            value={RegisterDiscountType.Flexible}
          />
        )}
      </RHFRadioGroup>
      {isFlexible ? (
        <div className={styles.flexibleDiscountHelpText}>
          You will be able to manually enter it at the time of the sale directly
          on your Slice Register tablet.
        </div>
      ) : (
        <>
          <RHFNumberField
            className={styles.value}
            control={control}
            formatOptions={{
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }}
            isRequired
            label="Value"
            name="amount"
            prefix={isFixed ? '$' : undefined}
            suffix={isFixed ? undefined : '%'}
            rules={{ validate: validateAmount }}
          />
          <RHFRadioGroup
            label="Can this discount be combined?"
            name="isCombinable"
            control={control}
          >
            <RHFRadio label="Yes, with all discounts" value="true" />
            <RHFRadio label="No" value="false" />
          </RHFRadioGroup>
        </>
      )}
    </CollapsibleTile>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default TypeFields;
