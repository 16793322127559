import * as React from "react";
import { forwardRef } from "react";
const SvgPersonIcon = ({
  title,
  titleId,
  ...props
}, ref) => {
  return /* @__PURE__ */ React.createElement("svg", { width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", "aria-hidden": "true", ref, "aria-labelledby": titleId, ...props }, title === void 0 ? /* @__PURE__ */ React.createElement("title", { id: titleId }, "Person Icon") : title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { d: "M12 10.0383C14.2091 10.0383 16 8.23885 16 6.01914C16 3.79943 14.2091 2 12 2C9.79086 2 8 3.79943 8 6.01914C8 8.23885 9.79086 10.0383 12 10.0383Z", fill: "currentColor" }), /* @__PURE__ */ React.createElement("path", { d: "M3 19.9904C3 21.1003 3.89543 22 5 22H19C20.1046 22 21 21.1003 21 19.9904C21 19.7225 20.9966 19.4583 20.899 19.2039C20.4176 17.9479 18.1192 12.0478 12 12.0478C5.88076 12.0478 3.58245 17.9479 3.10096 19.2039C3.00344 19.4583 3 19.7225 3 19.9904Z", fill: "currentColor" }));
};
const ForwardRef = forwardRef(SvgPersonIcon);
export default ForwardRef;
