import { RowData } from '@tanstack/react-table';
import cx from 'classnames';

import {
  AutomaticTableHeaderProps,
  BaseTableHeaderProps,
  OverloadedTableHeaderComponent,
} from 'types/shared/table';

import TableHeaderRow from './row';

import styles from './styles.module.scss';

const BaseTableHeader = ({
  className,
  children,
  ...props
}: BaseTableHeaderProps) => (
  <thead className={cx(styles.header, className)} {...props}>
    {children}
  </thead>
);

const TableHeader: OverloadedTableHeaderComponent = <TData extends RowData>(
  props: BaseTableHeaderProps | AutomaticTableHeaderProps<TData>,
) => {
  // Pass the result of useReactTable to automatically render the table header.
  if ('table' in props) {
    const { table, ...base } = props;

    return (
      <BaseTableHeader {...base}>
        {table
          ?.getHeaderGroups()
          .map((it) => <TableHeaderRow key={it.id} headerGroup={it} />)}
      </BaseTableHeader>
    );
  }

  return <BaseTableHeader {...props} />;
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default TableHeader;
