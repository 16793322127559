import * as React from "react";
import { forwardRef } from "react";
const SvgPickupCustomersIcon = ({
  title,
  titleId,
  ...props
}, ref) => {
  return /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", fill: "none", viewBox: "0 0 32 32", ref, "aria-labelledby": titleId, ...props }, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("circle", { cx: 16, cy: 16, r: 16, fill: "#F5F3F0" }), /* @__PURE__ */ React.createElement("path", { stroke: "#CA8EAE", strokeWidth: 2, d: "M20 10c0-2.20914-1.7909-4-4-4-2.2091 0-4 1.79086-4 4m8 4c0 2.2091-1.7909 4-4 4-2.2091 0-4-1.7909-4-4m-4-3h16v11c0 1.1046-.8954 2-2 2H10c-1.10457 0-2-.8954-2-2V11Z" }));
};
const ForwardRef = forwardRef(SvgPickupCustomersIcon);
export default ForwardRef;
