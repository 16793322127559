import { today } from '@internationalized/date';
import moment from 'moment-timezone';

import {
  CreatePromoCodeRequestBody,
  PromoCode,
  PromoCodeCategory,
  PromoCodeChannel,
  PromoCodeFormValues,
  PromoCodeLimit,
  PromoCodeType,
} from 'types/discounts';
import { Shop } from 'types/shops';
import { toMoneyString, toPennyString } from 'utilities/currency';

export const getDefaultPromoCodeFormValues = (
  timezone: Shop['timezoneIdentifier'],
): PromoCodeFormValues => ({
  amount: '',
  channels: Object.values(PromoCodeChannel),
  dates: { kind: 'date', value: today(timezone) },
  hasOrderMinimum: 'false',
  limit: PromoCodeLimit.None,
  orderMinimum: '',
  tag: '',
  type: PromoCodeType.Flat,
});

export const getCreatePromoCodeRequestBody = (
  values: PromoCodeFormValues,
): CreatePromoCodeRequestBody => ({
  promoCode: {
    category: PromoCodeCategory.Promocode,
    active: true,
    tag: values.tag,
    promoAmountType: values.type,
    promoPercentAmount:
      values.type === PromoCodeType.Percent ? Number(values.amount) : null,
    promoFlatAmount:
      values.type === PromoCodeType.Flat ? Number(values.amount) : null,
    minOrder:
      values.hasOrderMinimum === 'true' ? Number(values.orderMinimum) : 0,
    dateStart:
      values.dates.kind === 'date'
        ? values.dates.value.toString()
        : values.dates.value.start.toString(),
    dateEnd:
      values.dates.kind === 'range' ? values.dates.value.end.toString() : null,
    singleUse: values.limit === PromoCodeLimit.Once,
    singleUsePerCustomer: values.limit === PromoCodeLimit.OncePerCustomer,
    placedVias: values.channels,
  },
});

export const PromoCodeChannelLabels: { [K in PromoCodeChannel]: string } = {
  [PromoCodeChannel.Web]: 'Website',
  [PromoCodeChannel.Ios]: 'iOS App',
  [PromoCodeChannel.Android]: 'Android App',
} as const;

export const PromoCodeLimitLabels: {
  [K in PromoCodeLimit]: string;
} = {
  [PromoCodeLimit.None]: 'No limit',
  [PromoCodeLimit.Once]: 'Only once',
  [PromoCodeLimit.OncePerCustomer]: 'Once per customer',
} as const;

export const getFormattedPromoCodeAmount = (
  flat: PromoCode['promoFlatAmount'],
  percent: PromoCode['promoPercentAmount'],
  category: PromoCode['category'],
): string => {
  if (category == PromoCodeCategory.FreeDelivery) {
    return 'Free Delivery';
  }

  if (percent != null) {
    // The percent amounts are received as fraction strings (eg "0.23" for 23%).
    // For consistency with how percentages are displayed elsewhere, we'll
    // format the percentage as money without the currency symbol, treating the
    // received value as if it were pennies.
    return `${toMoneyString(toPennyString(percent), false)}%`;
  }

  // Flat amounts are received as negative dollar strings (eg "-20.0" for a
  // $20.00 dollar discount).
  return toMoneyString(Number(flat) * -1);
};

export const getFormattedPromoCodeDate = (
  value: string | null,
  timezone: string,
): string =>
  value
    ? moment.tz(value, 'MM/DD/YYYY hh:mm A', timezone).format('dddd MM/DD/YYYY')
    : '';
