import { useMemo } from 'react';

import ViewAllLink from 'components/orders/dashboard-tile/view-all-link/index';
import TipsAndEarningsHeader from 'components/orders/online-orders/metrics';
import OnlineOrdersTable from 'components/orders/online-orders/table';
import ContentTile from 'components/shared/content-tile';
import { onlineMetricDashboardIds } from 'utilities/financials/orders';
import { getLast7DaysPresetRange } from 'utilities/shared/date-range-presets';

type Props = {
  shopId: string;
  shopTimezone: string;
};

const RecentOrderDashboardTile = ({ shopId, shopTimezone }: Props) => {
  const dates = useMemo(
    () => getLast7DaysPresetRange(shopTimezone),
    [shopTimezone],
  );

  return (
    <ContentTile title="Recent Online Order Activity">
      <TipsAndEarningsHeader
        dates={dates}
        metricIds={onlineMetricDashboardIds}
        shopId={shopId}
        shopTimezone={shopTimezone}
      />
      <OnlineOrdersTable
        dates={dates}
        hideHeader
        hidePagination
        hideSortTriangle
        shopId={shopId}
        shopTimezone={shopTimezone}
      />
      <ViewAllLink shopId={shopId} />
    </ContentTile>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default RecentOrderDashboardTile;
