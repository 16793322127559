import {
  EditOnlineDiscountRequestBody,
  OnlineDiscountFormValues,
  OnlineDiscountType,
} from 'types/discounts';
import { Shop } from 'types/shops';

export const getDefaultOnlineDiscountFormValues = (
  shopDiscountPercent: Shop['discountPercent'],
): OnlineDiscountFormValues => ({
  amount: String(shopDiscountPercent ?? ''),
  type: OnlineDiscountType.Percent,
});

export const getEditOnlineDiscountRequestBody = (
  values: OnlineDiscountFormValues,
): EditOnlineDiscountRequestBody => ({
  discountPercent: values.amount == null ? null : Number(values.amount),
});
