import { useCallback } from 'react';

import { UnexpectedError } from 'components/shared/error';
import Loading from 'components/shared/loading';
import Page from 'components/shared/page';
import SmartPopups from 'components/website-tools/smart-popups';
import { useCurrentUserQuery } from 'hooks/shared';
import { useCurrentShopId, useShopQuery } from 'hooks/shops';
import { useShopTraitsContext } from 'hooks/shops/use-shop-traits-context';
import useAnalytics from 'hooks/use-analytics';
import { useWebsiteIntegrationsSettingsQuery } from 'hooks/website-tools/use-website-integrations-settings';
import {
  WebsiteIntegrationsAnalyticsPageName,
  WebsiteIntegrationsSettings,
} from 'types/website-tools';
import {
  getIsPromoMessageAvailableForShop,
  getSmartPopupsAnalyticsValuesForForm,
  getSmartPopupsDefaultFormValuesFromSettings,
  isSmartPopupsFirstTimeSetup,
} from 'utilities/website-tools';

import styles from './styles.module.scss';

const SmartPopupsPage = () => {
  const shopId = useCurrentShopId();
  const { trackFetchedWebsiteIntegrationsSettings } = useAnalytics();
  const shopTraits = useShopTraitsContext();

  const {
    data: shop,
    isError: isShopError,
    isLoading: isShopLoading,
  } = useShopQuery(shopId);

  const isMultiLocEnabled = useCallback(
    (settings: WebsiteIntegrationsSettings) =>
      // Right now the multi-location feature is not dependant on the integration type, so the feature is enabled if at least one of them is.
      // Future work will be done to show different experiences based on the integration type.
      settings.floatingButtonMultilocationEnabled ||
      settings.navBarMultilocationEnabled ||
      settings.modalMultilocationEnabled,
    [],
  );

  const {
    data: user,
    isError: isUserError,
    isLoading: isUserLoading,
  } = useCurrentUserQuery();

  const handleFetchedSettings = (data: WebsiteIntegrationsSettings) => {
    if (shop) {
      const isPromoMessageAvailable = getIsPromoMessageAvailableForShop(shop);
      const isFirstTimeSetup = isSmartPopupsFirstTimeSetup(data);
      const defaultFormValues = getSmartPopupsDefaultFormValuesFromSettings(
        data,
        isPromoMessageAvailable,
      );
      const multiLocEnabled = isMultiLocEnabled(data);
      const previouslySavedSettings = isFirstTimeSetup
        ? {}
        : getSmartPopupsAnalyticsValuesForForm({
            shopId: shopId,
            isFirstTimeSetup,
            formValues: defaultFormValues,
          });

      trackFetchedWebsiteIntegrationsSettings({
        shopId: String(shopId),
        previouslyEnabledSettings: !isFirstTimeSetup,
        page: multiLocEnabled
          ? WebsiteIntegrationsAnalyticsPageName.SmartPopupsMultiloc
          : WebsiteIntegrationsAnalyticsPageName.SmartPopups,
        shopIntegrationAdoption: shopTraits.shopIntegrationAdoption,
        ...previouslySavedSettings,
      });
    }
  };

  const {
    data: smartPopupSettings,
    isError: isSmartButtonsSettingsError,
    isLoading: isSmartButtonsSettingsLoading,
  } = useWebsiteIntegrationsSettingsQuery(shopId, {
    enabled: !!shop,
    onSuccess: handleFetchedSettings,
  });

  let content;
  if (isShopLoading || isSmartButtonsSettingsLoading || isUserLoading) {
    content = <Loading />;
  } else if (isShopError || isSmartButtonsSettingsError || isUserError) {
    content = <UnexpectedError />;
  } else {
    content = (
      <SmartPopups
        shop={shop}
        smartPopupSettings={smartPopupSettings}
        isMultiLocEnabled={
          smartPopupSettings && isMultiLocEnabled(smartPopupSettings)
        }
        user={user}
      />
    );
  }

  return (
    <Page
      contentClassName={styles.smartPopupsContent}
      name="viewed_smart_popups"
      title="Smart Popups"
    >
      {content}
    </Page>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default SmartPopupsPage;
