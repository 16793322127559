import { ForwardedRef, forwardRef, useCallback, useMemo } from 'react';
import { SelectInstance } from 'react-select';
import moment from 'moment-timezone';

import Select, { SelectProps } from 'components/shared/slice-select';

import styles from './styles.module.scss';

export type TimeOption = {
  value: string;
};

export const CLOSED_VALUE = '0';

const getAllTimeOptions = () => {
  const hours = [...Array(24).keys()].map((it) => String(it).padStart(2, '0'));
  const minutes = ['00', '15', '30', '45'];

  return hours
    .flatMap((hour) =>
      minutes.map((minute) => ({ value: `${hour}:${minute}` })),
    )
    .concat([{ value: '23:59' }]);
};

type Props = {
  hasClosedValue?: boolean;
  labelNextDayBefore?: string;
  nextDayLabel?: string;
  value?: string | null;
  startAtTime?: string;
} & Omit<SelectProps<TimeOption, false>, 'value'>;

const TimeSelect = forwardRef(function TimeSelect(
  {
    hasClosedValue = false,
    labelNextDayBefore,
    nextDayLabel = '',
    value,
    startAtTime,
    ...selectProps
  }: Props,
  ref: ForwardedRef<SelectInstance<TimeOption, false>>,
) {
  const options = useMemo(() => {
    let options = getAllTimeOptions();
    const startOptionIndex = startAtTime
      ? options.findIndex((it) => it.value === startAtTime)
      : -1;

    if (startOptionIndex > -1) {
      const optionsBeforeStart = options.slice(0, startOptionIndex);
      const optionsAfterStart = options.slice(startOptionIndex + 1);

      options = [...optionsAfterStart, ...optionsBeforeStart];
    }

    if (hasClosedValue) {
      options.push({ value: CLOSED_VALUE });
    }

    return options;
  }, [hasClosedValue, startAtTime]);

  const getOptionLabel = useCallback(
    (option: TimeOption) => {
      if (option.value === CLOSED_VALUE) {
        return 'Closed';
      }

      const parsedTime = moment(option.value, 'hh:mm');
      const formattedTime = parsedTime.format('h:mm A');

      if (!labelNextDayBefore || !nextDayLabel) {
        return formattedTime;
      }

      const integerTime = parseInt(option.value.replace(':', ''), 10);
      const integerLabelNextDayBefore = parseInt(
        labelNextDayBefore.replace(':', ''),
        10,
      );
      return integerTime < integerLabelNextDayBefore
        ? `${formattedTime} (${nextDayLabel})`
        : formattedTime;
    },
    [labelNextDayBefore, nextDayLabel],
  );

  return (
    <Select
      className={styles.timeSelect}
      getOptionLabel={getOptionLabel}
      options={options}
      value={options.find((option) => option.value === value) ?? null}
      ref={ref}
      {...selectProps}
    />
  );
});

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default TimeSelect;
