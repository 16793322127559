import * as React from "react";
import { forwardRef } from "react";
const SvgCamera = ({
  title,
  titleId,
  ...props
}, ref) => {
  return /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 51 36", ref, "aria-labelledby": titleId, ...props }, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("g", { fill: "none", fillRule: "evenodd" }, /* @__PURE__ */ React.createElement("path", { stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: 2, d: "M14.495 6.667L20.787 1h9.429l6.292 5.667h9.168c2.381 0 4.324 2.425 4.324 4.765V30.75c0 2.337-1.943 4.25-4.324 4.25H5.324C2.946 35 1 33.087 1 30.75V11.432c0-2.34 1.946-4.765 4.324-4.765h9.171z" }), /* @__PURE__ */ React.createElement("path", { fill: "currentColor", d: "M41.5 14c-.828 0-1.5.672-1.5 1.5s.672 1.5 1.5 1.5 1.5-.672 1.5-1.5-.672-1.5-1.5-1.5" }), /* @__PURE__ */ React.createElement("path", { stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: 2, d: "M34 19c0 4.419-3.581 8-8 8-4.419 0-8-3.581-8-8 0-4.419 3.581-8 8-8 4.419 0 8 3.581 8 8h0z" })));
};
const ForwardRef = forwardRef(SvgCamera);
export default ForwardRef;
