import Operations from 'components/operations';
import Page from 'components/shared/page';
import { useCurrentShopId } from 'hooks/shops';

const OperationsPage = () => {
  const shopId = useCurrentShopId();

  return (
    <Page name="operations" title="Operations">
      <Operations shopId={shopId} />
    </Page>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default OperationsPage;
