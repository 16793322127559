import * as React from "react";
import { forwardRef } from "react";
const SvgCloseCircle = ({
  title,
  titleId,
  ...props
}, ref) => {
  return /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", fill: "none", viewBox: "0 0 30 30", ref, "aria-labelledby": titleId, ...props }, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("circle", { cx: 15, cy: 15, r: 15, fill: "#000" }), /* @__PURE__ */ React.createElement("path", { fill: "#fff", fillRule: "evenodd", d: "m13.586 15-4.293-4.293 1.414-1.414L15 13.586l4.293-4.293 1.414 1.414L16.414 15l4.293 4.293-1.414 1.414L15 16.414l-4.293 4.293-1.414-1.414L13.586 15Z", clipRule: "evenodd" }));
};
const ForwardRef = forwardRef(SvgCloseCircle);
export default ForwardRef;
