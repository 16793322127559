import { FormEventHandler, PropsWithChildren } from 'react';
import {
  Control,
  FieldErrors,
  UseFormGetValues,
  UseFormSetValue,
  UseFormTrigger,
} from 'react-hook-form';

import { MenuCategoryFormValues } from 'types/menu/category';

import CategoryAvailabilityTile from './availability-tile';
import CategoryDetailsTile from './details-tile';

import styles from './styles.module.scss';

type Props = {
  control: Control<MenuCategoryFormValues>;
  errors: FieldErrors<MenuCategoryFormValues>;
  getValues: UseFormGetValues<MenuCategoryFormValues>;
  onSubmit: FormEventHandler<HTMLFormElement>;
  setValue: UseFormSetValue<MenuCategoryFormValues>;
  submitCount: number;
  trigger: UseFormTrigger<MenuCategoryFormValues>;
};

const CategoryForm = ({
  children,
  control,
  errors,
  getValues,
  onSubmit,
  setValue,
  submitCount,
  trigger,
}: PropsWithChildren<Props>) => {
  return (
    <form onSubmit={onSubmit}>
      <CategoryDetailsTile
        control={control}
        nameError={errors.name}
        submitCount={submitCount}
      />
      <CategoryAvailabilityTile
        control={control}
        daysError={errors.days?.root}
        getValues={getValues}
        setValue={setValue}
        submitCount={submitCount}
        trigger={trigger}
      />
      <div className={styles.additionalChanges}>
        Please call us at (855) 237-4992 for any additional changes.
      </div>
      {children}
    </form>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default CategoryForm;
