import * as React from "react";
import { forwardRef } from "react";
const SvgWarningIcon = ({
  title,
  titleId,
  ...props
}, ref) => {
  return /* @__PURE__ */ React.createElement("svg", { width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", "aria-hidden": "true", ref, "aria-labelledby": titleId, ...props }, title === void 0 ? /* @__PURE__ */ React.createElement("title", { id: titleId }, "Warning Icon") : title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { d: "M22.6416 16.4095L15.2885 3.81624C14.6347 2.69624 13.4162 2 12.108 2C10.8004 2 9.58217 2.69607 8.92761 3.81575L1.49024 16.5532C0.836505 17.6732 0.836669 19.0649 1.49024 20.1838C2.1443 21.3038 3.36287 22 4.67083 22H19.5489C19.5593 22 19.5697 22 19.5806 21.9994C21.5914 21.9838 23.2222 20.3607 23.2222 18.3688C23.2222 17.6697 23.0218 16.9944 22.6418 16.4091L22.6416 16.4095ZM12.111 7.27203C13.2612 7.27203 13.5417 8.12708 13.4709 8.61669L12.7808 13.9235C12.7578 14.4034 12.3806 14.5859 12.1108 14.5859C11.8088 14.5859 11.4673 14.4285 11.4408 13.9235L10.7507 8.61669C10.6802 8.11295 10.9678 7.27203 12.1109 7.27203H12.111ZM13.1052 17.6837C12.8459 17.9428 12.4825 18.093 12.111 18.093C11.739 18.093 11.3758 17.9426 11.1137 17.6837C10.852 17.4246 10.7026 17.0653 10.7026 16.6976C10.7026 16.3328 10.8518 15.9736 11.1137 15.7145C11.3758 15.4554 11.7391 15.3078 12.111 15.3078C12.4825 15.3078 12.8459 15.4552 13.1052 15.7145C13.3698 15.9736 13.5192 16.3328 13.5192 16.6976C13.5192 17.0655 13.37 17.4246 13.1052 17.6837Z", fill: "currentColor" }));
};
const ForwardRef = forwardRef(SvgWarningIcon);
export default ForwardRef;
