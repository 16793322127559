import { z } from 'zod';

export const CustomerExportSchema = z.object({
  createdAt: z.string(),
  download: z.string(),
  downloadUrl: z.string(),
  fromDate: z.string(),
  id: z.string(),
  shopUuid: z.string(),
  status: z.string(),
  toDate: z.string(),
  updatedAt: z.string(),
});

export type CustomerExport = z.infer<typeof CustomerExportSchema>;

export const CustomerExportsSchema = z.object({
  data: z.array(CustomerExportSchema).default([]),
  meta: z.object({
    pagination: z.object({
      currentPage: z.number(),
      pages: z.number(),
      perPage: z.number(),
      total: z.number(),
    }),
  }),
});

export type CustomerExports = z.infer<typeof CustomerExportsSchema>;
