import styles from './styles.module.scss';

type Props = {
  isNew?: boolean;
};

export const AutomaticDiscountFormHeader = ({ isNew = false }: Props) => (
  <h4 className={styles.header}>
    {isNew
      ? 'Add a new discount that will apply at checkout to online orders.'
      : 'Edit the discount that will apply at checkout to online orders.'}
  </h4>
);
