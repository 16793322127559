import { Shop } from 'types/shops';
import { User } from 'types/user';

import AdvancedMarketingServices from './advanced-marketing-services';
import SocialMediaResources from './social-media-resources';

import styles from './styles.module.scss';

type Params = {
  shopId: Shop['shopId'];
  user: User;
};
const MarketingServices = ({ shopId, user }: Params) => {
  return (
    <div className={styles.wrapper}>
      <AdvancedMarketingServices shopId={shopId} user={user} />
      <SocialMediaResources shopId={shopId} />
    </div>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default MarketingServices;
