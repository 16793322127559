import cx from 'classnames';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

const Header = ({ content, title, containerClass, contentClass }) => (
  <div className={containerClass}>
    <h1 className={styles.title}>{title}</h1>
    {content.map((paragraph, index) => (
      <p
        className={cx(styles.paragraph, contentClass)}
        key={`paragraph-${index}`}
      >
        {paragraph}
      </p>
    ))}
  </div>
);

Header.propTypes = {
  content: PropTypes.array,
  title: PropTypes.string.isRequired,
  containerClass: PropTypes.string,
  contentClass: PropTypes.string,
};

Header.defaultProps = {
  content: [],
  containerClass: '',
  contentClass: '',
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default Header;
