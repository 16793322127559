import * as React from "react";
import { forwardRef } from "react";
const SvgHamburger = ({
  title,
  titleId,
  ...props
}, ref) => {
  return /* @__PURE__ */ React.createElement("svg", { fill: "currentColor", viewBox: "0 0 100 74", ref, "aria-labelledby": titleId, ...props }, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("rect", { width: 100, height: 10, rx: 8 }), /* @__PURE__ */ React.createElement("rect", { y: 32, width: 100, height: 10, rx: 8 }), /* @__PURE__ */ React.createElement("rect", { y: 64, width: 100, height: 10, rx: 8 }));
};
const ForwardRef = forwardRef(SvgHamburger);
export default ForwardRef;
