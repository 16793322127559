import { useState } from 'react';
import { toast } from 'react-toastify';
import { today } from '@internationalized/date';

import { Calendar } from 'crust';

import Modal from 'components/shared/slice-modal';
import { useUpdateProductsStockMutation } from 'hooks/menu/use-update-products-stock-mutation';
import useAnalytics from 'hooks/use-analytics';
import { Shop } from 'types/shops';
import { getShopHasFullRegister } from 'utilities/shops';

import { useBulkEditContext } from '../context';
import { formatStockUpdateSuccessMessage } from '../utilities';

import styles from './styles.module.scss';

type Props = {
  closeModal: () => void;
  isOpen: boolean;
  shop: Shop;
};

export const CustomOutOfStockModal = ({ closeModal, isOpen, shop }: Props) => {
  const { trackMenuClickedBulkEditOutOfStock } = useAnalytics();
  const { selectedProductIds, clearSelectedProductIds } = useBulkEditContext();
  // today can't be selected to mark an item as out of stock
  const firstAvailableDate = today(shop.timezoneIdentifier).add({ days: 1 });

  const [date, setDate] = useState(firstAvailableDate);

  // 30days should be available on the calendar, because today is unavailable to be selected our startDate is today+1
  const maxDate = firstAvailableDate.add({ days: 29 });

  const {
    isLoading: isUpdateProductsStockLoading,
    mutate: updateProductsStock,
  } = useUpdateProductsStockMutation({
    productIds: Array.from(selectedProductIds),
    shopId: shop.shopId,
    shopTimezone: shop.timezoneIdentifier,
  });

  const handleCloseModal = () => {
    setDate(firstAvailableDate);
    closeModal();
  };

  const handleConfirm = () => {
    updateProductsStock(
      {
        date,
        isUnavailable: true,
      },
      {
        onError: () => {
          handleCloseModal();
          toast.error(
            'An unexpected error occurred. Some of your products may not be updated.',
          );
        },
        onSuccess: () => {
          clearSelectedProductIds();
          handleCloseModal();
          trackMenuClickedBulkEditOutOfStock({
            shopId: shop.shopId,
            page: 'menu items',
            isRegister: getShopHasFullRegister(shop),
            optionSelected: 'custom',
            productIds: selectedProductIds,
            productNames: [],
            until: date.toString(),
          });
          toast.success(
            formatStockUpdateSuccessMessage(selectedProductIds.size, true),
          );
        },
      },
    );
  };

  return (
    <Modal
      header="Select when your item is back in stock."
      hideCloseIcon
      isFullScreenMobile
      isNoButtonDisabled={isUpdateProductsStockLoading}
      isOpen={isOpen}
      isYesButtonDisabled={isUpdateProductsStockLoading}
      noButtonText="Cancel"
      onClickNo={handleCloseModal}
      onClickYes={handleConfirm}
      yesButtonText="Confirm"
    >
      <Calendar
        className={styles.calendar}
        maxValue={maxDate}
        minValue={firstAvailableDate}
        onChange={setDate}
        value={date}
      />
    </Modal>
  );
};
