import { useQuery, useQueryClient } from '@tanstack/react-query';

import useApi from 'hooks/use-api';

export const useClosings = (shopId) => {
  const queryClient = useQueryClient();
  const api = useApi();

  const { data: closingsData, isLoading } = useQuery([shopId, 'closings'], () =>
    api.getClosings(shopId),
  );

  const invalidateOpenForTodayQueries = () =>
    queryClient.invalidateQueries([shopId, 'openForToday']);

  const setClosingsQueryData = (action) =>
    queryClient.setQueryData([shopId, 'closings'], action);

  return {
    closingsData: closingsData ?? [],
    isLoading,
    invalidateOpenForTodayQueries,
    setClosingsQueryData,
  };
};
