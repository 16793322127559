export const SalesforceCaseType = {
  AMS: 'Advanced Marketing Services',
  CustomWebsite: 'Custom Website',
  OnlineOrdering: 'Ordering Button',
  Register: 'Slice Register',
  SmartButton: 'Smart Button',
  SmartPopup: 'Smart Popup',
};

export const SalesforceSubject = {
  AMS: 'AMS: Owners Portal Request',
  CustomWebsite: 'Slice Custom Website: Owners Portal Request',
  OnlineOrdering: 'Online Ordering: Owners Portal Request',
  Register: 'Register: Owners Portal Request',
  SmartButton: 'Smart Button: Owners Portal Request',
};

export const SalesforceNote = {
  AdvancedMarketingServicesNote:
    'User is interested in signing up for Advanced Marketing Services',
  AdvancedMarketingServicesRenewallNote:
    'User is interested in rewening Advanced Marketing Services',
  CopyCodeNote: 'User has copied Smart Button code in Owners Portal',
  CustomWebsiteNote:
    "User has requested to setup a Slice Custom Website in Owner's portal",
  OnlineOrderingNote: 'User is interested in online ordering',
  ShareSmartButtonNote:
    "User has requested support with a 3P implementing a Smart Button in Owner's Portal",
  SliceRegisterNote: 'User requested to schedule a demo of Slice Register',
  SmartButtonNote:
    'User has requested support adding a Smart Button to their website in Owners Portal',
  SmartButtonMultilocationNote:
    'User has requested support related to their Multi-location Smart Button in OP. Editing the Smart Button in Owners Portal is not allowed because Multi-location is enabled',
  SmartPopupMultilocationNote:
    'User has requested support related to their Multi-location Smart Button from the Smart Popup page in OP. Editing the Smart Popup in Owners Portal is not allowed because Multi-location is enabled.',
};

export type CreateSalesforceCase = {
  caseType: string;
  description?: string;
  origin?: string;
  subject: string;
};
