import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import * as businessProfilePaths from 'components/business-profile/paths';
import { ApiRequestError } from 'providers/api/helpers';
import * as mainPaths from 'routes/paths';

const ERROR_CODE_GOOGLE_AUTHORIZATION_REQUIRED =
  'google_authorization_required';

export const useHandleGoogleReauthError = () => {
  const navigate = useNavigate();

  const handleBusinessProfileError = useCallback(
    (shopId: number, error: unknown) => {
      if (
        error instanceof ApiRequestError &&
        error.status === 403 &&
        Array.isArray(error.responseBody.messages)
      ) {
        const messages: string[] = error.responseBody.messages;
        if (messages.includes(ERROR_CODE_GOOGLE_AUTHORIZATION_REQUIRED)) {
          console.warn(
            `${ERROR_CODE_GOOGLE_AUTHORIZATION_REQUIRED} error received. Redirecting to business profile login.`,
          );
          navigate(
            `${mainPaths.googleBusinessProfile(shopId)}/${businessProfilePaths.login}`,
          );
        }
      }
    },
    [navigate],
  );

  return handleBusinessProfileError;
};
