import { useQuery } from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { Shop } from 'types/shops';
import { camelCaseKeys } from 'utilities/objects';

export const usePosIntegrationRedirectQuery = (
  shopId: Shop['shopId'],
  provider: string,
) => {
  const { authenticatedFetch } = useApi();

  return useQuery({
    queryKey: [shopId, 'integration', 'squareRedirect'],
    queryFn: async () => {
      const response = await authenticatedFetch.get(
        `api/management/v2/shops/${shopId}/pos_integration/${provider}/authorize`,
      );

      return camelCaseKeys(response);
    },
  });
};
