import * as React from "react";
import { forwardRef } from "react";
const SvgCopy = ({
  title,
  titleId,
  ...props
}, ref) => {
  return /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", viewBox: "0 0 15 14", ref, "aria-labelledby": titleId, ...props }, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("rect", { width: 12, height: 12, fill: "inherit", rx: 2 }), /* @__PURE__ */ React.createElement("rect", { width: 11, height: 11, x: 3.5, y: 2.5, fill: "inherit", stroke: "#fff", rx: 1.5 }));
};
const ForwardRef = forwardRef(SvgCopy);
export default ForwardRef;
