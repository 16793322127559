import * as React from "react";
import { forwardRef } from "react";
const SvgClockSolid = ({
  title,
  titleId,
  ...props
}, ref) => {
  return /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", fill: "none", viewBox: "0 0 16 16", ref, "aria-labelledby": titleId, ...props }, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { fill: "currentColor", fillRule: "evenodd", d: "M8 14.667A6.667 6.667 0 1 0 8 1.333a6.667 6.667 0 0 0 0 13.334Zm.667-7.334V4H7.334v3.333a2 2 0 0 0 .8 1.6l2.133 1.6.8-1.066-2.133-1.6a.667.667 0 0 1-.267-.534Z", clipRule: "evenodd" }));
};
const ForwardRef = forwardRef(SvgClockSolid);
export default ForwardRef;
