import { ComponentPropsWithoutRef } from 'react';

import styles from './styles.module.scss';

/*
  For adding accessible text, e.g., when you want an accessible title on an
  element that does not support a "title" attribute.

  Github discussion: https://github.com/slicelife/owners-web/pull/2394#discussion_r771655913
*/
const VisuallyHidden = (
  props: Omit<ComponentPropsWithoutRef<'span'>, 'className'>,
) => <span className={styles.visuallyHidden} {...props} />;

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default VisuallyHidden;
