type Block = string;
type Element = string;
type Modifier = string | undefined | null | false | number;
type Modifiers = Modifier[];

type GetCrustClassName = {
  (block: Block, modifiers?: Modifiers): string;
  (block: Block, element?: Element, modifiers?: Modifiers): string;
};

export const getCrustClassName: GetCrustClassName = (
  block: Block,
  element?: Element | Modifiers,
  modifiers?: Modifiers,
) => {
  let base = `crust-${block}`;

  if (typeof element === 'string') {
    base += `__${element}`;
  } else if (element != null) {
    modifiers = element;
  }

  if (modifiers == null) {
    return base;
  }

  let result = base;

  for (const modifier of modifiers) {
    if (modifier) {
      result += ` ${base}--${modifier}`;
    }
  }

  return result;
};
