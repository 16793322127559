import { ElementRef, ForwardedRef, forwardRef, ReactElement } from 'react';
import {
  Controller,
  FieldPath,
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';

import {
  Checkbox,
  CheckboxGroup,
  type CheckboxGroupProps,
  type CheckboxProps,
  mergeRefs,
} from 'crust';

type ControllerPropsKeys = Exclude<keyof UseControllerProps, 'disabled'>;

type CheckboxGroupPropsKeys = Exclude<
  keyof CheckboxGroupProps,
  'isInvalid' | 'onChange' | 'validate' | ControllerPropsKeys
>;

export type RHFCheckboxGroupProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = Pick<CheckboxGroupProps, CheckboxGroupPropsKeys> &
  Pick<UseControllerProps<TFieldValues, TName>, ControllerPropsKeys>;

export const RHFCheckboxGroup = forwardRef(function RHFCheckboxGroup<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
  {
    children,
    control,
    defaultValue,
    isDisabled,
    name,
    rules,
    shouldUnregister,
    ...props
  }: RHFCheckboxGroupProps<TFieldValues, TName>,
  ref: ForwardedRef<ElementRef<typeof CheckboxGroup>>,
) {
  const { field, fieldState } = useController({
    control,
    defaultValue,
    disabled: isDisabled,
    name,
    rules,
    shouldUnregister,
  });

  return (
    <CheckboxGroup
      error={fieldState.error?.message}
      isDisabled={field.disabled}
      isInvalid={fieldState.invalid}
      name={field.name}
      onBlur={field.onBlur}
      onChange={field.onChange}
      ref={mergeRefs(ref, field.ref)}
      validationBehavior="aria"
      value={field.value}
      {...props}
    >
      {children}
    </CheckboxGroup>
  );
}) as <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
  props: RHFCheckboxGroupProps<TFieldValues, TName>,
  ref: ForwardedRef<ElementRef<typeof CheckboxGroup>>,
) => ReactElement;

type ControllerProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = Omit<UseControllerProps<TFieldValues, TName>, 'disabled'>;

type NonControllerProps = {
  [Property in keyof ControllerProps]+?: never;
};

type RHFSafeCheckboxProps = Omit<
  CheckboxProps,
  'isInvalid' | 'onChange' | 'validate' | 'validationBehavior'
>;

type UngroupedRHFCheckboxProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = ControllerProps<TFieldValues, TName> & RHFSafeCheckboxProps;

type GroupedRHFCheckboxProps = NonControllerProps & RHFSafeCheckboxProps;

export type RHFCheckboxProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = UngroupedRHFCheckboxProps<TFieldValues, TName> | GroupedRHFCheckboxProps;

export const RHFCheckbox = forwardRef(function RHFCheckbox<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
  props: RHFCheckboxProps<TFieldValues, TName>,
  ref: ForwardedRef<ElementRef<typeof Checkbox>>,
) {
  if (props.name) {
    const {
      control,
      defaultValue,
      isDisabled,
      name,
      shouldUnregister,
      rules,
      ...checkboxProps
    } = props;
    return (
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        disabled={isDisabled}
        shouldUnregister={shouldUnregister}
        rules={rules}
        render={({ field, fieldState }) => (
          <Checkbox
            isDisabled={field.disabled}
            isInvalid={fieldState.invalid}
            isSelected={!!field.value}
            name={field.name}
            onChange={field.onChange}
            ref={mergeRefs(ref, field.ref)}
            validationBehavior="aria"
            value={field.value}
            {...checkboxProps}
          />
        )}
      />
    );
  }

  return <Checkbox {...props} />;
}) as <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
  props: RHFCheckboxProps<TFieldValues, TName>,
  ref: ForwardedRef<ElementRef<typeof Checkbox>>,
) => ReactElement;
