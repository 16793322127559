import { useEffect, useState } from 'react';
import cx from 'classnames';

import CopyIcon from 'images/copy.svg?react';
import { copyTextToClipboard } from 'utilities/misc';

import styles from './styles.module.scss';

type Props = {
  buttonText?: string;
  className?: string;
  textToCopy: string;
  onClick?: () => void;
};

const CopyToClipboardButton = ({
  buttonText = 'Copy',
  className,
  textToCopy,
  onClick,
}: Props) => {
  const [isClicked, setIsClicked] = useState(false);

  useEffect(() => {
    if (isClicked) {
      const timeout = setTimeout(() => {
        setIsClicked(false);
      }, 2000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [isClicked]);

  const handleOnClick = () => {
    if (!isClicked) {
      onClick?.();
      copyTextToClipboard(textToCopy, false);
      setIsClicked(true);
    }
  };

  return (
    <button
      className={cx(styles.copyButton, className)}
      disabled={isClicked}
      onClick={handleOnClick}
    >
      <div className={styles.buttonContent}>
        <CopyIcon aria-hidden />
        <div className={styles.buttonText}>
          {isClicked ? 'Copied' : buttonText}
        </div>
      </div>
    </button>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default CopyToClipboardButton;
