import * as React from "react";
import { forwardRef } from "react";
const SvgMoneyIcon = ({
  title,
  titleId,
  ...props
}, ref) => {
  return /* @__PURE__ */ React.createElement("svg", { width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", "aria-hidden": "true", ref, "aria-labelledby": titleId, ...props }, title === void 0 ? /* @__PURE__ */ React.createElement("title", { id: titleId }, "Money Icon") : title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM10.3744 7H11V5H13V7H16V9H10.3744C9.89147 9 9.5 9.39147 9.5 9.87437C9.5 10.3095 9.81996 10.6784 10.2507 10.74L14.0321 11.2802C15.4482 11.4824 16.5 12.6952 16.5 14.1256C16.5 15.7131 15.2131 17 13.6256 17H13V19H11V17H8V15H13.6256C14.1085 15 14.5 14.6085 14.5 14.1256C14.5 13.6905 14.18 13.3216 13.7493 13.2601L9.96787 12.7198C8.55182 12.5176 7.5 11.3048 7.5 9.87437C7.5 8.2869 8.7869 7 10.3744 7Z", fill: "currentColor" }));
};
const ForwardRef = forwardRef(SvgMoneyIcon);
export default ForwardRef;
