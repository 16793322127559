import * as React from "react";
import { forwardRef } from "react";
const SvgTrashCanIcon = ({
  title,
  titleId,
  ...props
}, ref) => {
  return /* @__PURE__ */ React.createElement("svg", { width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", "aria-hidden": "true", ref, "aria-labelledby": titleId, ...props }, title === void 0 ? /* @__PURE__ */ React.createElement("title", { id: titleId }, "Trash Can Icon") : title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { d: "M6 4C4.89543 4 4 4.89543 4 6V7H20V6C20 4.89543 19.1046 4 18 4H16L15.5528 3.10557C15.214 2.428 14.5215 2 13.7639 2H10.2361C9.47852 2 8.786 2.428 8.44721 3.10557L8 4H6Z", fill: "currentColor" }), /* @__PURE__ */ React.createElement("path", { d: "M18 9H6L6.85795 20.1534C6.93811 21.1954 7.80699 22 8.85206 22H15.1479C16.193 22 17.0619 21.1954 17.142 20.1534L18 9Z", fill: "currentColor" }));
};
const ForwardRef = forwardRef(SvgTrashCanIcon);
export default ForwardRef;
