import { ForwardedRef, forwardRef, useCallback, useMemo } from 'react';
import { FormatOptionLabelMeta, GroupBase, SelectInstance } from 'react-select';

import Select, { SelectProps } from 'components/shared/slice-select';

import { sortCategories } from './sorting';

import styles from './styles.module.scss';

export type CategoryOption = {
  label: string;
  length: number;
  value: number;
};

type Props = {
  showCount?: boolean;
} & SelectProps<CategoryOption, false, GroupBase<CategoryOption>>;

const CategorySelect = forwardRef(function CategorySelect(
  { options, showCount = true, ...selectProps }: Props,
  ref: ForwardedRef<SelectInstance<CategoryOption, false>>,
) {
  const sortedOptions = useMemo(() => {
    return sortCategories(options);
  }, [options]);

  const formatOptionLabel = useCallback(
    (
      option: CategoryOption,
      { context }: FormatOptionLabelMeta<CategoryOption>,
    ) => {
      // Hide the count when the select is not open.
      if (context === 'value' || !showCount) {
        return option.label;
      }

      return (
        <>
          <div className={styles.optionLabel}>{option.label}</div>
          <div className={styles.count}>
            Items: {option.length < 0 ? 0 : option.length}
          </div>
        </>
      );
    },
    [showCount],
  );

  return (
    <Select
      formatOptionLabel={formatOptionLabel}
      options={sortedOptions}
      ref={ref}
      {...selectProps}
    />
  );
});

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default CategorySelect;
