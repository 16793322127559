import { QueryKey, useQuery, UseQueryResult } from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { OpenForToday } from 'types/open-for-today';

export const getOpenForTodayQueryKey = (shopId: string): QueryKey => [
  shopId,
  'openForToday',
];

export const useOpenForTodayQuery = (
  shopId: string,
): UseQueryResult<OpenForToday> => {
  const api = useApi();

  return useQuery(getOpenForTodayQueryKey(shopId), () =>
    api.getShopOpenForToday(shopId),
  );
};
