import PropTypes from 'prop-types';

import FormFeedback from 'components/shared/form-feedback';
import Input from 'components/shared/input';
import InputDescription from 'components/shared/input-description';
import Label from 'components/shared/label';

const PrinterNameInput = ({ error, inputIdPrefix, isReadOnly, register }) => (
  <>
    <Label htmlFor={`${inputIdPrefix}-printer-name`}>Name</Label>
    <Input
      aria-describedby={`${inputIdPrefix}-printer-name-description`}
      aria-required="true"
      id={`${inputIdPrefix}-printer-name`}
      isInvalid={error != null}
      placeholder="e.g., Kitchen Receipt"
      readOnly={isReadOnly || undefined}
      {...register('name', {
        required: 'Please enter a name.',
      })}
    />
    <FormFeedback>{error?.message}</FormFeedback>
    {isReadOnly && (
      <InputDescription id={`${inputIdPrefix}-printer-name-description`}>
        This name cannot be changed.
      </InputDescription>
    )}
  </>
);

PrinterNameInput.propTypes = {
  error: PropTypes.object,
  inputIdPrefix: PropTypes.string.isRequired,
  isReadOnly: PropTypes.bool,
  register: PropTypes.func.isRequired,
};

PrinterNameInput.defaultProps = {
  error: null,
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default PrinterNameInput;
