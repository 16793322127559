import * as React from "react";
import { forwardRef } from "react";
const SvgBellIcon = ({
  title,
  titleId,
  ...props
}, ref) => {
  return /* @__PURE__ */ React.createElement("svg", { width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", "aria-hidden": "true", ref, "aria-labelledby": titleId, ...props }, title === void 0 ? /* @__PURE__ */ React.createElement("title", { id: titleId }, "Bell Icon") : title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { d: "M12 2C8.13401 2 5 5.13401 5 9V11.1716C5 11.702 4.78929 12.2107 4.41421 12.5858L3.58579 13.4142C3.21071 13.7893 3 14.298 3 14.8284V15C3 16.1046 3.89543 17 5 17H19C20.1046 17 21 16.1046 21 15V14.8284C21 14.298 20.7893 13.7893 20.4142 13.4142L19.5858 12.5858C19.2107 12.2107 19 11.702 19 11.1716V9C19 5.13401 15.866 2 12 2Z", fill: "currentColor" }), /* @__PURE__ */ React.createElement("path", { d: "M12 22C13.6569 22 15 20.6569 15 19H9C9 20.6569 10.3431 22 12 22Z", fill: "currentColor" }));
};
const ForwardRef = forwardRef(SvgBellIcon);
export default ForwardRef;
