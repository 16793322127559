import { ReactNode } from 'react';

import { Button, ButtonProps } from 'crust';

import styles from './styles.module.scss';

type Props = {
  description?: ReactNode;
  onClickCreateReport: ButtonProps['onPress'];
};

export const ReportHeader = ({ description, onClickCreateReport }: Props) => (
  <div className={styles.header}>
    {description && <p className={styles.description}>{description}</p>}
    <Button
      className={styles.button}
      id="show-basic-tax-report-form"
      onPress={onClickCreateReport}
      variant="secondary"
    >
      Create New Report
    </Button>
  </div>
);
