import * as React from "react";
import { forwardRef } from "react";
const SvgEllipses = ({
  title,
  titleId,
  ...props
}, ref) => {
  return /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 8 28", ref, "aria-labelledby": titleId, ...props }, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { fill: "currentColor", fillRule: "evenodd", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round", d: "M4 21.129c1.657 0 3 1.314 3 2.936C7 25.686 5.657 27 4 27s-3-1.314-3-2.935c0-1.622 1.343-2.936 3-2.936h0zm0-10.064c1.657 0 3 1.313 3 2.935s-1.343 2.935-3 2.935S1 15.622 1 14s1.343-2.935 3-2.935h0zM4 1c1.657 0 3 1.314 3 2.935 0 1.622-1.343 2.936-3 2.936S1 5.557 1 3.935C1 2.314 2.343 1 4 1h0z" }));
};
const ForwardRef = forwardRef(SvgEllipses);
export default ForwardRef;
