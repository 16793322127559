import { useEffect, useState } from 'react';
import { Control, Controller, FieldError } from 'react-hook-form';

import CollapsibleTile from 'components/shared/collapsible-tile';
import FormFeedback from 'components/shared/form-feedback';
import Label from 'components/shared/label';
import NumberInput from 'components/shared/number-input';
import { RHFRadio, RHFRadioGroup } from 'components/shared/rhf-radio';
import { OnlineDiscountFormValues, OnlineDiscountType } from 'types/discounts';

import { TypeFieldsSummary } from './summary';

import styles from './styles.module.scss';

type Props = {
  amountError?: FieldError;
  className?: string;
  control: Control<OnlineDiscountFormValues>;
};

export const TypeFields = ({ amountError, className, control }: Props) => {
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    if (amountError) {
      setIsOpen(true);
    }
  }, [amountError]);

  return (
    <CollapsibleTile
      className={className}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      summary={<TypeFieldsSummary control={control} />}
      title="Select Type"
    >
      <RHFRadioGroup
        name="type"
        control={control}
        label="What type of discount do you want to run?"
        orientation="horizontal"
        variant="card"
      >
        <RHFRadio
          description="Offer percent-based discounts (e.g. 5% Off)"
          label="Percentage"
          value={OnlineDiscountType.Percent}
        />
      </RHFRadioGroup>
      <div
        className={styles.value}
        data-chameleon-target="Online Discount Value"
      >
        <Label htmlFor="discount-amount">Value</Label>
        <Controller
          control={control}
          name="amount"
          rules={{
            min: {
              value: 1,
              message: 'Must be at least 1%.',
            },
            max: {
              value: 99,
              message: 'Cannot be greater than 99%.',
            },
            required: 'Please enter an amount.',
          }}
          render={({ field }) => (
            <NumberInput
              aria-required="true"
              decimalScale={0}
              id="discount-amount"
              isInvalid={amountError != null}
              name={field.name}
              onBlur={field.onBlur}
              onValueChange={(values) => field.onChange(values.value)}
              placeholder="e.g. 5"
              ref={field.ref}
              suffix="%"
              value={field.value}
              valueIsNumericString
            />
          )}
        />
        <FormFeedback>{amountError?.message}</FormFeedback>
      </div>
    </CollapsibleTile>
  );
};
