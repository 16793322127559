import { CSSProperties } from 'react';
import {
  DraggableAttributes,
  DraggableSyntheticListeners,
} from '@dnd-kit/core';
import {
  Menu,
  MenuChangeEvent,
  MenuItem,
  MenuRadioGroup,
  RadioChangeEvent,
} from '@szhsin/react-menu';
import cx from 'classnames';

import { DragButton, OverflowButton } from 'components/shared/icon-button';
import Link from 'components/shared/link';
import Text from 'components/shared/text';
import useAnalytics from 'hooks/use-analytics';
import { registerLayoutCategory } from 'routes/paths';
import { getItemsWord } from 'utilities/menu';

import styles from './styles.module.scss';

const colorOptions = [
  { label: 'Gray', value: '#efefef' },
  { label: 'Red', value: '#ffcece' },
  { label: 'Orange', value: '#ffe7ce' },
  { label: 'Yellow', value: '#ffffce' },
  { label: 'Green', value: '#e7ffce' },
  { label: 'Blue', value: '#cee4ff' },
  { label: 'Purple', value: '#d1ceff' },
  { label: 'Neutral', value: '#fdf7f1' },
  { label: 'White', value: '#ffffff' },
];

type Props = {
  colorHex: string;
  count: number;
  draggableAttributes?: DraggableAttributes;
  draggableListeners?: DraggableSyntheticListeners;
  id: number;
  isDragging?: boolean;
  isOverlay?: boolean;
  isSingleColumn?: boolean;
  name: string;
  onSelectColor: (colorHex: string, id: number, productId?: number) => void;
  parentId?: number;
  shopId: string;
};

const RegisterListItem = ({
  colorHex,
  count = 0,
  draggableAttributes,
  draggableListeners,
  id,
  isDragging = false,
  isOverlay = false,
  isSingleColumn = false,
  name,
  onSelectColor,
  parentId,
  shopId,
}: Props) => {
  const { trackRegisterMenuEditItemsClicked, trackRegisterMenuColorsOpened } =
    useAnalytics();

  const handleRadioChange = (event: RadioChangeEvent) => {
    onSelectColor(event.value, id, parentId);
  };

  const handleMenuChange = (event: MenuChangeEvent) => {
    if (event.open) {
      trackRegisterMenuColorsOpened(shopId, parentId == null, isSingleColumn);
    }
  };

  const handleClicktEditItems = () => {
    trackRegisterMenuEditItemsClicked(shopId, isSingleColumn);
  };

  return (
    <div
      className={cx(
        styles.content,
        isDragging && styles.dragging,
        isOverlay && styles.overlay,
        isSingleColumn && styles.single,
      )}
      style={
        {
          '--layout-color': colorHex,
        } as CSSProperties
      }
    >
      <DragButton
        className={styles.handle}
        label={`Move ${name}`}
        {...draggableAttributes}
        {...draggableListeners}
      />
      <Text className={styles.name} clamp={2}>
        {name}
      </Text>
      {count > 0 && (
        <>
          <div className={styles.count}>
            {count} {getItemsWord(count)}
          </div>
          <Link
            className={styles.edit}
            onClick={handleClicktEditItems}
            to={registerLayoutCategory(shopId, id)}
          >
            Edit items
          </Link>
        </>
      )}
      <Menu
        portal
        align="end"
        className={styles.menu}
        menuButton={
          <OverflowButton
            className={styles.overflow}
            label={`Change the color of ${name}`}
          />
        }
        onMenuChange={handleMenuChange}
      >
        <MenuRadioGroup value={colorHex} onRadioChange={handleRadioChange}>
          {colorOptions.map(({ label, value }) => (
            <MenuItem
              key={value}
              style={{ '--option-color': value } as CSSProperties}
              type="radio"
              value={value}
            >
              {label}
            </MenuItem>
          ))}
        </MenuRadioGroup>
      </Menu>
    </div>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default RegisterListItem;
