import Tile from 'components/shared/tile/tile';
import { ShopTraits } from 'types/shops';
import { Shop } from 'types/shops/shop';
import { User } from 'types/user';
import { getWebsiteIntegrationsScriptCode } from 'utilities/website-tools';

import { EmbedActions } from '../embed-actions';

import styles from './styles.module.scss';

type Props = {
  openShareEmbedCodeModal: () => void;
  shopInfo: Shop;
  shopTraits: ShopTraits;
  user: User;
};

export const EmbedCode = ({
  openShareEmbedCodeModal,
  shopInfo,
  shopTraits,
  user,
}: Props) => {
  const code = getWebsiteIntegrationsScriptCode(
    shopInfo.city,
    shopInfo.name,
    shopInfo.uuid,
  );

  return (
    <Tile className={styles.container}>
      <div>
        <p className={styles.title}>Embed Button</p>
        <p
          className={styles.subTitle}
        >{`To use a Smart Button on your website, copy and paste this code into the <head> tag of your website HTML. This only needs to be done once.`}</p>
      </div>
      <input
        type="text"
        className={styles.embedInput}
        value={code}
        readOnly
        aria-label="readonly-code-input"
        spellCheck="false"
      />
      <div className={styles.footer}>
        <EmbedActions
          isShareCodeDisplayed={true}
          isTertiary={false}
          openShareEmbedCodeModal={openShareEmbedCodeModal}
          shopInfo={shopInfo}
          shopTraits={shopTraits}
          shouldCreateCase
          user={user}
        />
      </div>
    </Tile>
  );
};
