import CheckmarkSolidIcon from 'images/checkmark-solid.svg?react';

import styles from './styles.module.scss';

type Props = {
  description: string;
};

const BenefitItem = ({ description }: Props) => {
  return (
    <li className={styles.listItem}>
      <CheckmarkSolidIcon className={styles.listIcon} />
      <div>{description}</div>
    </li>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default BenefitItem;
