import { ReactNode } from 'react';
import { useCollapse } from 'react-collapsed';
import cx from 'classnames';

import { CollapsibleTileVariant } from '../utilities';

import styles from './styles.module.scss';

type CollapsibleTileBodyProps = {
  chameleonTarget?: string;
  children: ReactNode;
  className?: string;
  getCollapseProps: ReturnType<typeof useCollapse>['getCollapseProps'];
  variant: CollapsibleTileVariant;
};

const CollapsibleTileBody = ({
  chameleonTarget,
  children,
  className,
  getCollapseProps,
  variant,
}: CollapsibleTileBodyProps) => (
  // Due to the way dynamic height animations work, an outer div is needed. The
  // outer div will be transitioned to the height of the inner div, which can be
  // rendered and measured before the animation starts.
  <div {...getCollapseProps()}>
    <div
      className={cx(styles[variant], className)}
      data-chameleon-target={chameleonTarget}
    >
      {children}
    </div>
  </div>
);

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default CollapsibleTileBody;
