import { useMemo } from 'react';
import { createColumnHelper } from '@tanstack/react-table';

import Table from 'components/shared/table';
import { useTable } from 'hooks/shared';
import { BaseCustomer, CustomerList } from 'types/customers';
import { toDollarString, toMoneyString } from 'utilities/currency';
import { getCustomerDisplayName } from 'utilities/customers';
import { capitalize } from 'utilities/strings';

import CustomerNameButton from '../../customer-name-button';

import styles from './styles.module.scss';

type Props = {
  customerList: CustomerList;
  customers: BaseCustomer[];
  isLoading: boolean;
  setSelectedCustomer: React.Dispatch<
    React.SetStateAction<BaseCustomer | null>
  >;
  shopId: string;
  shopTimezone: string;
};

const Top10CustomersTable = ({
  customerList,
  customers,
  isLoading,
  setSelectedCustomer,
  shopId,
  shopTimezone,
}: Props) => {
  const columns = useMemo(() => {
    const helper = createColumnHelper<BaseCustomer>();

    return [
      helper.accessor((_row, index) => index + 1, {
        id: 'rank',
        header: 'Rank',
        meta: {
          className: styles.rankColumn,
        },
      }),
      helper.accessor(
        (row) => getCustomerDisplayName(row.firstName, row.lastName),
        {
          id: 'name',
          header: 'Name',
          cell(ctx) {
            return (
              <CustomerNameButton
                customer={ctx.row.original}
                customerList={customerList}
                displayName={ctx.getValue()}
                setSelectedCustomer={setSelectedCustomer}
                shopId={shopId}
                shopTimezone={shopTimezone}
              />
            );
          },
          meta: {
            className: styles.standardColumn,
          },
        },
      ),
      helper.accessor((row) => capitalize(row.loyaltyStatus), {
        id: 'type',
        header: 'Type',
        meta: {
          className: styles.typeColumn,
        },
      }),
      helper.accessor('orderCount', {
        id: 'orderCount',
        header: 'Order Count',
        meta: {
          className: styles.orderCountColumn,
        },
      }),
      helper.accessor(
        (row) => toMoneyString(toDollarString(row.orderTotal), true, true),
        {
          id: 'totalSpend',
          header: 'Total Spend',
        },
      ),
    ];
  }, [customerList, setSelectedCustomer, shopId, shopTimezone]);

  const table = useTable({
    columns,
    data: customers,
    enableSorting: false,
    state: {
      isLoading,
    },
    chameleonTableTitle: 'Top 10 Customers Table',
  });

  return <Table table={table} />;
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default Top10CustomersTable;
