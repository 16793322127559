import Label from './label';
import Rectangle from './rectangle';
import ScoreIndicator from './score-indicator';
import { outerSvgDimensions, xOffsetsFromBarStart } from './svg-settings';

import styles from './styles.module.scss';

type Props = {
  shopScore: number;
  averageScore: number;
};

const NetworkStatusBar = ({ shopScore, averageScore }: Props) => (
  <div className={styles.wrapper}>
    <svg
      className={styles.bar}
      width={outerSvgDimensions.width}
      height={outerSvgDimensions.height}
      viewBox={`0 0 ${outerSvgDimensions.width} ${outerSvgDimensions.height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <title>Network Status Score</title>
      <desc id="networkStatusBarDescription">
        Your shop has a network status score of {shopScore}. The average score
        in your area is {averageScore}.
      </desc>
      <Rectangle />
      <Label labelText="Ok" xOffsetFromBarStart={xOffsetsFromBarStart.ok} />
      <Label labelText="Good" xOffsetFromBarStart={xOffsetsFromBarStart.good} />
      <Label
        labelText="Great"
        xOffsetFromBarStart={xOffsetsFromBarStart.great}
      />
      <Label
        labelText="All-Star"
        xOffsetFromBarStart={xOffsetsFromBarStart.allStar}
      />
      <ScoreIndicator score={averageScore} isAverageScore />
      <ScoreIndicator score={shopScore} />
    </svg>
  </div>
);

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default NetworkStatusBar;
