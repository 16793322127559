import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createColumnHelper, SortingState } from '@tanstack/react-table';

import ContentTile from 'components/shared/content-tile';
import { SearchBar } from 'components/shared/search-bar';
import Table from 'components/shared/table';
import TableAction from 'components/shared/table/action';
import Text from 'components/shared/text';
import VisuallyHidden from 'components/shared/visually-hidden';
import {
  CategoryNavPage,
  CategoryNavType,
  removeMenuProductsSearchSynchronous,
  setMenuProductsScrollIdSynchronous,
  useCategoryNavTracking,
} from 'hooks/menu';
import { useTable } from 'hooks/shared';
import useAnalytics from 'hooks/use-analytics';
import * as paths from 'routes/paths';
import { MenuResponseBody } from 'types/menu/api';
import { MenuCategory } from 'types/menu/category';
import { Shop } from 'types/shops';
import { getAvailabilitySummary } from 'utilities/menu';

import styles from './styles.module.scss';

type Props = {
  menuData: MenuResponseBody;
  shopId: Shop['shopId'];
};

const MenuCategoriesPageContent = ({ menuData, shopId }: Props) => {
  const navigate = useNavigate();
  const { trackCategorySearch } = useAnalytics();
  const { trackCategoryNavClick } = useCategoryNavTracking(String(shopId));

  const categories = menuData?.relationships.categories ?? [];

  const [searchTerm, setSearchTerm] = useState('');
  const [sorting, setSorting] = useState<SortingState>([
    {
      id: 'name',
      desc: false,
    },
  ]);

  const handleChangeQuery = useCallback(
    (value: string) => {
      if (value) {
        trackCategorySearch(String(shopId));
      }
      setSearchTerm(value);
    },
    [shopId, trackCategorySearch],
  );

  const handleClickItems = useCallback(
    (id: number, name: string) => {
      // Clear any search, otherwise clicking the category could take you to
      // the items page, but with an unrelated previous search still in place.
      trackCategoryNavClick({
        categoryNavType: CategoryNavType.CategoriesPageLink,
        page: CategoryNavPage.CategoriesPage,
        categoryName: name,
      });
      removeMenuProductsSearchSynchronous();
      setMenuProductsScrollIdSynchronous(`category-${id}`);
      navigate(paths.getMenuItemsPath(shopId));
    },
    [navigate, shopId, trackCategoryNavClick],
  );

  const columns = useMemo(() => {
    const helper = createColumnHelper<MenuCategory>();

    return [
      helper.accessor('name', {
        header: 'Category',
        sortingFn: 'text',
        cell(ctx) {
          return <Text wrap="truncate">{ctx.getValue()}</Text>;
        },
      }),

      helper.accessor((it) => it.description ?? '', {
        id: 'description',
        header: 'Description',
        enableSorting: false,
      }),

      helper.accessor((it) => it.productIds?.length ?? 0, {
        id: 'items',
        enableGlobalFilter: false,
        header: 'Items',
        sortingFn: 'alphanumeric',
        meta: {
          className: styles.itemsColumn,
        },
        cell(ctx) {
          const count = ctx.getValue();
          const { id, name } = ctx.row.original;

          if (count === 0) {
            return count;
          }

          return (
            <button
              aria-label={`See all items in category ${name}`}
              className={styles.items}
              onClick={() => handleClickItems(id, name)}
            >
              {count}
            </button>
          );
        },
      }),

      helper.accessor('availabilities', {
        id: 'availability', // Matches QA ID already in use.
        enableGlobalFilter: false,
        enableSorting: false,
        header: 'Availability',
        cell(ctx) {
          return (
            <>
              {getAvailabilitySummary(ctx.getValue()).map((day) => (
                <div key={day}>{day}</div>
              ))}
            </>
          );
        },
      }),

      helper.display({
        id: 'actions',
        header() {
          return <VisuallyHidden>Actions</VisuallyHidden>;
        },
        cell(ctx) {
          // Hidden categories like "Non Display Products" are not editable.
          if (ctx.row.original.hidden) {
            return null;
          }

          return (
            <TableAction
              className={styles.edit}
              icon="pencil"
              title={`Edit category ${ctx.row.original.name}`}
              to={paths.getMenuCategoryPath(shopId, ctx.row.original.id)}
            />
          );
        },
        meta: {
          className: styles.editColumn,
          isActionColumn: true,
        },
      }),
    ];
  }, [handleClickItems, shopId]);

  const table = useTable({
    columns,
    data: categories,
    globalFilterFn: 'includesString',
    onGlobalFilterChange: setSearchTerm,
    onSortingChange: setSorting,
    state: {
      globalFilter: searchTerm,
      sorting,
    },
    chameleonTableTitle: 'Menu Categories',
  });

  return (
    <ContentTile>
      <SearchBar
        className={styles.search}
        placeholderText="Search categories"
        onChange={handleChangeQuery}
      />
      <Table className={styles.table} table={table} />
    </ContentTile>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default MenuCategoriesPageContent;
