import * as React from "react";
import { forwardRef } from "react";
const SvgLock = ({
  title,
  titleId,
  ...props
}, ref) => {
  return /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 21 28", ref, "aria-labelledby": titleId, ...props }, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("g", { fill: "none", fillRule: "evenodd", stroke: "currentColor", strokeLinejoin: "round" }, /* @__PURE__ */ React.createElement("path", { strokeLinecap: "round", d: "M1 27.004118h19v-15H1z" }), /* @__PURE__ */ React.createElement("path", { strokeLinecap: "round", strokeWidth: 2, d: "M10.5 22.004118v-3" }), /* @__PURE__ */ React.createElement("path", { fill: "#FFF", strokeWidth: 2, d: "M10 18.504118c0 .27.22.5.5.5s.5-.23.5-.5c0-.28-.22-.5-.5-.5s-.5.22-.5.5z" }), /* @__PURE__ */ React.createElement("path", { d: "M10.5 1.004118c-3.59 0-6.5 2.83-6.5 6.32v4.51h13v-4.51c0-3.49-2.91-6.32-6.5-6.32z" })));
};
const ForwardRef = forwardRef(SvgLock);
export default ForwardRef;
