import { Shop } from 'types/shops';

import { StatementsFeatureHighlight } from './statements-feature-highlight';
import { PayoutsTableTile } from './table-tile';

type Props = {
  shopId: Shop['shopId'];
  shopTimezone: Shop['timezoneIdentifier'];
};

export const Payouts = ({ shopId, shopTimezone }: Props) => {
  return (
    <>
      <StatementsFeatureHighlight shopId={shopId} />
      <PayoutsTableTile shopId={shopId} shopTimezone={shopTimezone} />
    </>
  );
};
