import { Fragment, useEffect } from 'react';
import { useFieldArray, useWatch } from 'react-hook-form';
import PropTypes from 'prop-types';

import {
  createFormRelationship,
  findFieldsToAdd,
  findFieldsToRemove,
  getSelectionFieldName,
  getSelectionRelationshipFieldName,
  ProductField,
  RelationshipField,
  SelectionField,
} from 'utilities/menu';

const SelectionMeta = ({
  control,
  customizationIndex,
  selectionIndex,
  register,
}) => {
  const { append, fields, remove } = useFieldArray({
    control,
    keyName: 'key', // Don't confuse the field key with the relationship ID.
    name: getSelectionFieldName(
      SelectionField.Relationships,
      customizationIndex,
      selectionIndex,
    ),
  });

  // A product type was added, removed, etc.
  const productTypes = useWatch({
    control,
    name: ProductField.ProductTypes,
  });

  useEffect(() => {
    const toAdd = findFieldsToAdd(productTypes, fields);

    if (toAdd.length > 0) {
      // Create relationships for product types that were added.
      append(
        toAdd.map((productTypeId) => createFormRelationship({ productTypeId })),
        { shouldFocus: false },
      );

      // We can only call one field array action per render.
      return;
    }

    const toRemove = findFieldsToRemove(productTypes, fields);

    if (toRemove.length > 0) {
      // Remove relationships for product types that no longer exist.
      remove(toRemove);
    }
  }, [append, fields, productTypes, remove]);

  return fields.map((field, index) => (
    <Fragment key={field.key}>
      <input
        type="hidden"
        {...register(
          getSelectionRelationshipFieldName(
            RelationshipField.Id,
            customizationIndex,
            selectionIndex,
            index,
          ),
        )}
      />
      <input
        type="hidden"
        {...register(
          getSelectionRelationshipFieldName(
            RelationshipField.ProductTypeId,
            customizationIndex,
            selectionIndex,
            index,
          ),
        )}
      />
    </Fragment>
  ));
};

SelectionMeta.propTypes = {
  control: PropTypes.object.isRequired,
  customizationIndex: PropTypes.number.isRequired,
  selectionIndex: PropTypes.number.isRequired,
  register: PropTypes.func.isRequired,
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default SelectionMeta;
