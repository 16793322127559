import { ChangeEvent, ComponentPropsWithoutRef } from 'react';

import Spinner from 'components/shared/spinner';
import CameraIcon from 'images/camera.svg?react';

import styles from './styles.module.scss';

type Props = {
  isInProgress?: boolean;
  onChange: (file: File) => void;
} & Omit<ComponentPropsWithoutRef<'input'>, 'onChange' | 'type'>;

const FileUploader = ({ isInProgress, onChange, ...props }: Props) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.currentTarget.files?.[0];

    if (file) {
      onChange?.(file);
    }
  };

  return (
    <div className={styles.fileUploader}>
      <input
        accept="image/png, image/jpeg"
        className={styles.fileUploaderInput}
        onChange={handleChange}
        type="file"
        {...props}
      />
      {isInProgress ? (
        <>
          <Spinner className={styles.loadingSpinner} />
          <span className={styles.title}>Uploading...</span>
        </>
      ) : (
        <>
          <CameraIcon className={styles.cameraIcon} />
          <span className={styles.title}>Drag and drop here</span>
          <span className={styles.subtitle}>
            or <span className={styles.subtitleHighlight}>Browse</span> your
            computer
          </span>
        </>
      )}
    </div>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default FileUploader;
