import { today } from '@internationalized/date';

export const getTodayPresetRange = (timezone: string) => {
  const end = today(timezone);

  return {
    id: 'today',
    label: 'Today',
    start: end,
    end,
  } as const;
};

export const getYesterdayPresetRange = (timezone: string) => {
  const end = today(timezone).subtract({ days: 1 });

  return {
    id: 'yesterday',
    label: 'Yesterday',
    start: end,
    end,
  } as const;
};

export const getLast7DaysPresetRange = (timezone: string) => {
  const end = today(timezone);
  const start = end.subtract({ days: 7 });

  return {
    id: 'last7days',
    label: 'Last 7 Days',
    start,
    end,
  } as const;
};

export const getLast14DaysPresetRange = (timezone: string) => {
  const end = today(timezone);
  const start = end.subtract({ days: 14 });

  return {
    id: 'last14days',
    label: 'Last 14 Days',
    start,
    end,
  } as const;
};

export const getLast30DaysPresetRange = (timezone: string) => {
  const end = today(timezone);
  const start = end.subtract({ days: 30 });

  return {
    id: 'last30days',
    label: 'Last 30 Days',
    start,
    end,
  } as const;
};

export const getLast365DaysPresetRange = (timezone: string) => {
  const end = today(timezone);
  const start = end.subtract({ days: 365 });

  return {
    id: 'last365days',
    label: 'Last 365 Days',
    start,
    end,
  } as const;
};

export const getDefaultDateRangePresets = (timezone: string) =>
  [
    getTodayPresetRange(timezone),
    getYesterdayPresetRange(timezone),
    getLast7DaysPresetRange(timezone),
    getLast14DaysPresetRange(timezone),
    getLast30DaysPresetRange(timezone),
  ] as const;

export const getPhoneDateRangePresets = (timezone: string) =>
  [
    getTodayPresetRange(timezone),
    getYesterdayPresetRange(timezone),
    getLast7DaysPresetRange(timezone),
    getLast30DaysPresetRange(timezone),
    getLast365DaysPresetRange(timezone),
  ] as const;
