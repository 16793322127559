type TooltipPosition = {
  left: number;
  top: number;
};

const marginFromButton = 0;
const marginFromScreenEdge = 16;

const calculateTooltipTop = (
  targetRect: DOMRect,
  tooltipRect: DOMRect,
): number => {
  // Determine the screen space available above and below the button to which
  // the tooltip is attached.
  const spaceAboveButton = targetRect.top;
  const spaceBelowButton =
    window.innerHeight - (targetRect.top + targetRect.height);

  // Position the tooltip either above or below the button, based on where the
  // most screen space is. Note that, since the tooltip top position is relative
  // to the document body, we must adjust for any vertical scroll.
  if (spaceAboveButton > spaceBelowButton) {
    // Adjust the top position of the tooltip, such that the tooltip's bottom
    // edge is the desired margin from the button.
    return (
      targetRect.top - marginFromButton - tooltipRect.height + window.scrollY
    );
  } else {
    // Adjust the top position of the tooltip, such that the tooltip's top edge
    // is the desired margin from the button.
    return (
      targetRect.top + targetRect.height + marginFromButton + window.scrollY
    );
  }
};

const calculateTooltipLeft = (
  targetRect: DOMRect,
  tooltipRect: DOMRect,
): number => {
  // Determine the left offset needed for the tooltip, such that the tooltip's
  // center is aligned with the button's center.
  const halfTooltipWidth = tooltipRect.width * 0.5;
  const halfButtonWidth = targetRect.width * 0.5;
  const leftOffsetFromButton = -1 * halfTooltipWidth + halfButtonWidth;

  // Determine the "left" value for the tooltip's left edge (x1) and right edge
  // (x2), based upon the button's left value with the left offset. These values
  // are "unadjusted", as they do not account for the tooltip possibly going
  // "off screen" or being "too close" to the screen edge.
  const unadjustedTooltipX1 = targetRect.left + leftOffsetFromButton;
  const unadjustedTooltipX2 = unadjustedTooltipX1 + tooltipRect.width;

  // Determine if the "unadjusted" tooltip position would result in the tooltip
  // going "off screen" or being "too close" to the screen edge.
  const isTooFarLeft = unadjustedTooltipX1 < marginFromScreenEdge;
  const isTooFarRight =
    unadjustedTooltipX2 > window.innerWidth - marginFromScreenEdge;

  // Determine the appropriate "left" value to return by applying "just enough"
  // of an adjustment (as needed) so that the tooltip does not go "off screen"
  // and the tooltip is not "too close" to the screen edge.
  if (isTooFarLeft) {
    const leftAdjustment = marginFromScreenEdge - unadjustedTooltipX1;
    return unadjustedTooltipX1 + leftAdjustment + window.scrollX;
  } else if (isTooFarRight) {
    const rightAdjustment =
      marginFromScreenEdge - (window.innerWidth - unadjustedTooltipX2);
    return unadjustedTooltipX1 - rightAdjustment + window.scrollX;
  } else {
    return unadjustedTooltipX1 + window.scrollX;
  }
};

export const calculateTooltipPosition = (
  target: HTMLElement,
  tooltip: HTMLDivElement,
): TooltipPosition => {
  const targetRect = target.getBoundingClientRect();
  const tooltipRect = tooltip.getBoundingClientRect();

  return {
    top: calculateTooltipTop(targetRect, tooltipRect),
    left: calculateTooltipLeft(targetRect, tooltipRect),
  };
};
