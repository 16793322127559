import { Link } from 'crust';

import Text from 'components/shared/text';

import styles from './styles.module.scss';

type Props = {
  description: string;
  header: string;
  Icon: React.ComponentType<React.HTMLAttributes<HTMLOrSVGElement>>;
  label?: string;
  onVisitClick: () => void;
  url: string;
};

const OperationsCard = ({
  description,
  header,
  Icon,
  label = 'visit',
  onVisitClick,
  url,
}: Props) => {
  return (
    <div className={styles.card}>
      <Icon className={styles.cardIcon} />
      <div className={styles.description}>
        <Text className={styles.descriptionHeader} as="p">
          {header}
        </Text>
        <Text className={styles.descriptionSummary} as="p">
          {description}
        </Text>
      </div>
      <Link
        appearance="button"
        onPress={onVisitClick}
        href={url}
        variant="secondary"
      >
        {label}
      </Link>
    </div>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default OperationsCard;
