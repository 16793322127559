import { MenuProduct } from 'types/menu/product';
import { getFutureUtcDateTime } from 'utilities/date-time';

type StockAvailability = Pick<MenuProduct, 'unavailable' | 'unavailableUntil'>;

export const getDurationLabel = (
  unavailable: boolean,
  unavailableUntil: string | null,
): string | null => {
  if (unavailable) {
    if (unavailableUntil == null) {
      return 'Indefinitely';
    }
    const hourInMilliseconds = 1000 * 60 * 60;
    const diffInMilliseconds =
      new Date(unavailableUntil).getTime() - new Date().getTime();
    const hoursLeft = Math.ceil(diffInMilliseconds / hourInMilliseconds);
    const dayInMilliseconds = hoursLeft / 24;

    if (hoursLeft < 24) {
      return `${hoursLeft} ${hoursLeft > 1 ? 'Hours' : 'Hour'} Left`;
    }
    const daysLeft = Math.ceil(dayInMilliseconds);

    return `${daysLeft} ${daysLeft > 1 ? 'Days' : 'Day'} Left`;
  }

  return null;
};

export const getAvailability = (duration: number): StockAvailability => ({
  unavailable: duration > 0,
  unavailableUntil:
    duration === 0 || duration === Infinity
      ? null
      : getFutureUtcDateTime(duration).format(),
});
