import { PropsWithChildren } from 'react';
import { MenuItem } from '@szhsin/react-menu';
import cx from 'classnames';

import { Icon } from 'crust';

import {
  WebsiteIntegrationColor,
  WebsiteIntegrationColors,
} from 'types/website-tools';

import styles from './styles.module.scss';

type Props = {
  hasColor?: boolean;
  isActive: boolean;
  isRecommended?: boolean;
  label?: string;
  value: string | boolean;
};

export const SelectMenuOption = ({
  children,
  hasColor,
  isActive,
  isRecommended,
  label,
  value,
}: PropsWithChildren<Props>) => {
  return (
    <MenuItem className={styles.menuItem} value={value}>
      {(isActive || hasColor) && (
        <div
          className={cx(styles.activeIcon, hasColor && styles.iconHasColor)}
          style={
            hasColor
              ? {
                  backgroundColor: `#${
                    WebsiteIntegrationColors[value as WebsiteIntegrationColor]
                  }`,
                }
              : undefined
          }
        >
          {isActive && <Icon icon="checkmark" />}
        </div>
      )}
      <div
        className={cx(
          !isActive && styles.menuItemContentInactive,
          hasColor && styles.hasColor,
        )}
      >
        {label && (
          <div
            className={cx(
              styles.menuItemText,
              isActive && styles.menuItemActiveText,
            )}
          >
            {label}
          </div>
        )}
        {isRecommended && (
          <div className={styles.menuItemRecommended}>(Recommended)</div>
        )}
        {children && <div className={styles.menuItemIcon}>{children}</div>}
      </div>
    </MenuItem>
  );
};
