// The primary reason to convert dollars to pennies is reading a form input. The
// backend will usually want all monetary values in pennies and integers also
// make input validation simpler. A string is always returned to reduce logic
// errors and for easier use with inputs. We should be avoiding client-side math
// and in typed code an explicit conversion to a number will make the intent and
// peril clear.
export const toPennyString = (dollars: number | string): string => {
  if (typeof dollars !== 'number') {
    dollars = Number.parseFloat(dollars);
  }

  if (Number.isNaN(dollars)) {
    return '';
  }

  return (dollars * 100).toFixed(0);
};

// Converting penny amounts to dollars is useful for display backend vales in
// form inputs and user-facing components (almost always with toMoneyString).
// Just like toPennyString, a string is always returned as we should be avoiding
// client-side math. To facilitate form operations, non-numberic inputs return
// an empty string, so be careful when using this method outside of a form or
// without toMoneyString. Fractional pennies, which should never be given to us,
// but may result from client-side math, will be rounded half-up.
export const toDollarString = (pennies: number | string): string => {
  if (typeof pennies !== 'number') {
    pennies = Number.parseFloat(pennies);
  }

  if (Number.isNaN(pennies)) {
    return '';
  }

  return (pennies / 100).toFixed(2);
};

// Use this method to display monetary values as human readable currency strings
// with thousands separators and an optional currency symbol. If the input is
// not a number, zero will be used instead as sensible fallback (ie instead of
// NaN). Fractional pennies, which should never be given to us, but may result
// from client-side math, will be rounded half-up.
export const toMoneyString = (
  dollars: number | string,
  showDollarSymbol = true,
  showPennies = true,
): string => {
  const formatOptions: Intl.NumberFormatOptions = {
    maximumFractionDigits: showPennies ? 2 : 0,
    minimumFractionDigits: showPennies ? 2 : 0,
  };

  if (showDollarSymbol) {
    formatOptions.currency = 'USD';
    formatOptions.style = 'currency';
  }

  if (typeof dollars !== 'number') {
    dollars = Number.parseFloat(dollars);
  }

  if (Number.isNaN(dollars)) {
    dollars = 0;
  }

  return dollars.toLocaleString('en-US', formatOptions);
};
