import cx from 'classnames';

import { DropdownButton } from 'components/shared/dropdown-button';

import styles from './styles.module.scss';

type DropdownControls = {
  action: () => void;
  name: string;
};

type Props = {
  className?: string;
  controls: DropdownControls[];
  selectedOption: string;
};

const AnalyticsDropdownButton = ({
  className,
  controls,
  selectedOption,
}: Props) => {
  return (
    <DropdownButton
      buttonClassName={cx(styles.dropdownButton, className)}
      buttonContent={selectedOption}
      controls={controls}
      hasCaret
    />
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default AnalyticsDropdownButton;
