import { Menu, MenuButton, MenuItem } from '@szhsin/react-menu';
import cx from 'classnames';

import useAnalytics from 'hooks/use-analytics';
import { SetStoredState } from 'hooks/use-stored-state';
import CalendarIcon from 'images/calendarEvent.svg?react';
import CaretIcon from 'images/caret.svg?react';
import { DateRange, DateRangeOption } from 'types/customers';
import { dateRangeLabels } from 'utilities/customers';

import styles from './styles.module.scss';

type Props = {
  className?: string;
  dateRange: DateRange;
  dateRangeOptions: DateRangeOption[];
  page: string;
  setDateRange: (daterange: DateRange) => void;
  setStoredDateRange: SetStoredState<DateRange>;
  shopId: string;
};

const DateRangeDropdown = ({
  className,
  dateRange,
  dateRangeOptions,
  page,
  setDateRange,
  setStoredDateRange,
  shopId,
}: Props) => {
  const { trackSelectedDateRange } = useAnalytics();

  const selectedOption = dateRangeOptions.find(
    (option) => dateRange === option.value,
  );

  const handleClickDropdownItem = (selectedDateRange: DateRange): void => {
    trackSelectedDateRange(
      shopId,
      dateRangeLabels[dateRange],
      dateRangeLabels[selectedDateRange],
      page,
    );

    setDateRange(selectedDateRange);

    setStoredDateRange(selectedDateRange);
  };

  return (
    <div className={cx(styles.dropdownContainer, className)}>
      <Menu
        direction="bottom"
        menuButton={({ open }) => (
          <MenuButton className={styles.menuButton}>
            <CalendarIcon className={styles.menuButtonIcon} />
            <span className={styles.menuButtonText}>
              {selectedOption?.label}
            </span>
            <CaretIcon className={open ? styles.caretUp : styles.caretDown} />
          </MenuButton>
        )}
        overflow="auto"
        position="initial"
      >
        <MenuItem
          className={styles.menuItem}
          onClick={() => handleClickDropdownItem(DateRange.Seven)}
        >
          Last 7 Days
        </MenuItem>
        <MenuItem
          className={styles.menuItem}
          onClick={() => handleClickDropdownItem(DateRange.Thirty)}
        >
          Last 30 Days
        </MenuItem>
        <MenuItem
          className={styles.menuItem}
          onClick={() => handleClickDropdownItem(DateRange.Sixty)}
        >
          Last 60 Days
        </MenuItem>
        <MenuItem
          className={styles.menuItem}
          onClick={() => handleClickDropdownItem(DateRange.Ninety)}
        >
          Last 90 Days
        </MenuItem>
      </Menu>
    </div>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default DateRangeDropdown;
