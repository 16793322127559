enum VideoTutorials {
  AddOrEditMenuItem = 'https://player.vimeo.com/video/728245720?h=2ee8dfc86a',
  AddOrEditMenuCategory = 'https://player.vimeo.com/video/728245273?h=adb8bb7cc9',
  AddOrEditMenuCustomization = 'https://player.vimeo.com/video/728245904?h=315fcc3267',
}

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default VideoTutorials;
