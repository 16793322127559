import { useEffect, useState } from 'react';
import { Control, FieldError, UseFormRegister } from 'react-hook-form';

import CollapsibleTile from 'components/shared/collapsible-tile';
import FormFeedback from 'components/shared/form-feedback';
import Input from 'components/shared/input';
import InputDescription from 'components/shared/input-description';
import Label from 'components/shared/label';
import { PromoCodeFormValues } from 'types/discounts';

import { TagSummary } from './summary';

import styles from './styles.module.scss';

type Props = {
  className?: string;
  control: Control<PromoCodeFormValues>;
  register: UseFormRegister<PromoCodeFormValues>;
  tagError?: FieldError;
};

export const TagFields = ({
  className,
  control,
  register,
  tagError,
}: Props) => {
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    if (tagError) {
      setIsOpen(true);
    }
  }, [tagError]);

  return (
    <CollapsibleTile
      bodyChameleonTarget="Promo Code Name"
      className={className}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      summary={<TagSummary control={control} />}
      title="Add Name"
    >
      <div className={styles.fields}>
        <Label htmlFor="promo-code-name">Promo Code Name</Label>
        <Input
          aria-describedby="promo-code-name-description"
          aria-required="true"
          id="promo-code-name"
          isInvalid={tagError != null}
          placeholder="e.g. TwoForOne"
          {...register('tag', {
            pattern: {
              value: /^(?!slice|app|web|cmp)(?=.*[a-z])\w{6,}$/i,
              message: 'Please enter a valid Promo Code name.',
            },
            required: 'Please enter a Promo Code name.',
          })}
        />
        <FormFeedback>{tagError?.message}</FormFeedback>
        <InputDescription id="promo-code-name-description">
          No spaces, at least 1 letter, at least 6 characters, and cannot start
          with Slice, App, Cmp, or Web. Names are not case sensitive.
        </InputDescription>
      </div>
    </CollapsibleTile>
  );
};
