import Link from 'components/shared/link';
import useAnalytics from 'hooks/use-analytics';

type Props = {
  children: React.ReactNode;
  className?: string;
  dataChameleonTarget?: string;
  dataTestId?: string;
  shopId: string;
  title: string;
  to: string;
  trackingName: string;
};

const MetricTileLink = ({
  children,
  className,
  dataChameleonTarget,
  dataTestId,
  shopId,
  title,
  to,
  trackingName,
}: Props) => {
  const { trackDashboardTileClick } = useAnalytics();

  return (
    <Link
      className={className}
      data-chameleon-target={dataChameleonTarget}
      data-testid={dataTestId}
      onClick={() => trackDashboardTileClick(shopId, trackingName)}
      title={title}
      to={to}
      variant="undecorated"
    >
      {children}
    </Link>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default MetricTileLink;
