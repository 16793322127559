import { ComponentPropsWithoutRef, useMemo } from 'react';
import cx from 'classnames';
import moment from 'moment-timezone';

import Link from 'components/shared/link';
import {
  CustomerReviewModerationStatus,
  CustomerReviewReply,
} from 'types/customer-feedback';
import { capitalize } from 'utilities/strings';

import styles from './styles.module.scss';

type Props = {
  menuUrl: string;
  reply: CustomerReviewReply;
} & ComponentPropsWithoutRef<'div'>;

const ViewReply = ({ menuUrl, reply, ...props }: Props) => {
  const formattedCreatedAt = useMemo(
    () => moment(reply.createdAt).format('LL'),
    [reply.createdAt],
  );

  const formattedModerationStatus = useMemo(() => {
    if (reply.moderationStatus === CustomerReviewModerationStatus.Pending) {
      return `Pending Approval from Slice`;
    }

    return capitalize(reply.moderationStatus);
  }, [reply.moderationStatus]);

  return (
    <div className={styles.container} {...props}>
      <p className={styles.label}>Your Response on {formattedCreatedAt}</p>
      <p className={styles.responseText}>{reply.body}</p>
      <div
        className={cx(
          styles.status,
          reply.moderationStatus === CustomerReviewModerationStatus.Pending &&
            styles.pending,
          reply.moderationStatus === CustomerReviewModerationStatus.Published &&
            styles.success,
        )}
      >
        <span> {formattedModerationStatus}</span>
        <Link className={styles.menuUrl} to={menuUrl}>
          See it online
        </Link>
      </div>
      <hr className={styles.divider} />
      <div>
        Note: you can only reply once and a customer will not be able to reply
        back. Once approved, your response will be posted and visible online to
        other customers.
      </div>
    </div>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default ViewReply;
