import { MenuProductsFilters } from '.';

export const calculatedMenuProductsFiltersSelected = (
  filters: MenuProductsFilters,
) => {
  /**
   * Number of sections that user use to filter.
   *
   * For example, if we select 3 categories and 1 "in-stock" option,
   * then sections count will be 2, as our filters are based on
   * two critera (filter by categories and stock)
   */
  let sectionsCount = 0;

  /**
   * - Number of selected options
   *
   * For categories, printers, stock, and availability
   * it will be the number of selected checkboxes
   *
   * For price range it could be 0, 1, or 2 and it's
   * based on what input has value:
   * - 0 if no filters applied
   * - 1 if min OR max field has a value
   * - 2 if both min AND max fields have a value
   */
  let optionsCount = 0;

  if (filters.categories.length > 0) {
    sectionsCount++;
    optionsCount += filters.categories.length;
  }

  if (filters.printers.length > 0) {
    sectionsCount++;
    optionsCount += filters.printers.length;
  }

  if (filters.availability.length > 0) {
    sectionsCount++;
    optionsCount += filters.availability.length;
  }

  if (filters.stock.length > 0) {
    sectionsCount++;
    optionsCount += filters.stock.length;
  }

  if (!Number.isNaN(filters.minPrice) || !Number.isNaN(filters.maxPrice)) {
    sectionsCount++;
  }

  if (!Number.isNaN(filters.minPrice)) {
    optionsCount++;
  }

  if (!Number.isNaN(filters.maxPrice)) {
    optionsCount++;
  }

  return {
    sectionsCount,
    optionsCount,
  };
};
