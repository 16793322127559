import PropTypes from 'prop-types';

import { Button } from 'crust';

import Header from 'components/shared/header';

import styles from './styles.module.scss';

const RegisterPrintingHeader = ({ toggleCreateForm }) => (
  <div className={styles.header}>
    <Header
      title="Manage Register Printing"
      content={[
        `Print settings define when an order prints and the number of copies to
        be printed. You can create, edit, or remove print settings below.`,
      ]}
    />
    <Button
      id="create-new-setting-button"
      onPress={toggleCreateForm}
      variant="secondary"
    >
      Create New Setting
    </Button>
  </div>
);

RegisterPrintingHeader.propTypes = {
  toggleCreateForm: PropTypes.func.isRequired,
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default RegisterPrintingHeader;
