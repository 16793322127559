import { useState } from 'react';

import ContentTile from 'components/shared/content-tile';
import useCustomerCountsQuery from 'hooks/customers/use-customer-counts-query';
import useAnalytics from 'hooks/use-analytics';
import { CustomerType, DateRange, RegistrationType } from 'types/customers';

import GuestCustomersModal from './modal';

import styles from './styles.module.scss';

type Props = {
  customerType: CustomerType;
  dateRange: DateRange;
  shopId: string;
  timezone: string;
};

const GuestCustomersTile = ({
  customerType,
  dateRange,
  shopId,
  timezone,
}: Props) => {
  const { trackClickedViewList } = useAnalytics();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { data: customerCounts, isLoading } = useCustomerCountsQuery({
    customerType,
    dateRange,
    registrationType: RegistrationType.Guest,
    shopId,
    timezone,
  });

  const handleClickLearnMore = (): void => {
    trackClickedViewList(shopId, 'guest', customerCounts?.guest ?? 0);

    setIsModalOpen(true);
  };

  const text =
    customerCounts?.guest === 1
      ? '1 Guest Customer Found'
      : `${customerCounts?.guest} Guest Customers Found`;

  return (
    <>
      <ContentTile
        className={styles.tile}
        isLoading={isLoading}
        dataTestId="customer-dashboard-guest-customers-tile"
      >
        <div className={styles.primaryText}>{text}</div>
        <div className={styles.secondaryText}>
          Online Guest Customers now discoverable through Customer Analytics.
          <button
            type="button"
            className={styles.learnMoreButton}
            onClick={handleClickLearnMore}
          >
            Learn More
          </button>
        </div>
      </ContentTile>
      <GuestCustomersModal
        closeModal={() => setIsModalOpen(false)}
        customerType={customerType}
        dateRange={dateRange}
        guestCount={customerCounts?.guest ?? 0}
        isOpen={isModalOpen}
        shopId={shopId}
        timezone={timezone}
      />
    </>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default GuestCustomersTile;
