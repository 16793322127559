import { useMemo, useState } from 'react';

import ContentTile from 'components/shared/content-tile';
import Suspended from 'components/shared/suspended';
import useAnalytics from 'hooks/use-analytics';
import { useAssetLibrary } from 'hooks/use-asset-library';
import { AssetLibraryAsset, emptyAsset } from 'types/asset-library';
import { downloadImage } from 'utilities/download-image';
import { groupBy } from 'utilities/lists';

import AssetModal from './asset-modal';
import SocialMediaService from './service';

type Props = {
  shopId: string;
};

const sortedServiceNames = ['Instagram', 'Facebook', 'Twitter'];

const ServicesTile = ({ shopId }: Props) => {
  const { trackClickedDownloadDigitalAsset, trackClickedExpandDigitalAsset } =
    useAnalytics();
  const { assets, isLoading } = useAssetLibrary();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalServiceName, setModalServiceName] = useState('');
  const [modalAsset, setModalAsset] = useState<AssetLibraryAsset>(emptyAsset());

  const { visibleAssetsByServiceName, visibleServiceNames } = useMemo(() => {
    const visibleAssets = assets.filter(
      (it) => it.active && it.visibleToOwners,
    );
    const visibleAssetsByServiceName = groupBy(
      visibleAssets,
      (it) => it.categoryName,
    );
    const visibleServiceNames = sortedServiceNames.filter(
      (it) => visibleAssetsByServiceName[it] != null,
    );
    return { visibleAssetsByServiceName, visibleServiceNames };
  }, [assets]);

  const onClickExpand = (asset: AssetLibraryAsset, serviceName: string) => {
    trackClickedExpandDigitalAsset({
      shopId,
      category: 'Social Media',
      subCategory: serviceName,
      assetId: asset.id,
      title: asset.title,
      description: asset.description,
      assetUrl: asset.uri,
    });
    setIsModalOpen(true);
    setModalServiceName(serviceName);
    setModalAsset(asset);
  };

  const onCloseModal = () => {
    setIsModalOpen(false);
    setModalServiceName('');
    setModalAsset(emptyAsset());
  };

  const downloadAssetImage = async (
    asset: AssetLibraryAsset,
    page: 'Social Media' | 'Asset Modal',
  ): Promise<void> => {
    trackClickedDownloadDigitalAsset({
      shopId,
      category: 'Social Media',
      subCategory: asset.categoryName,
      assetId: asset.id,
      title: asset.title,
      description: asset.description,
      page,
      assetUrl: asset.uri,
    });

    await downloadImage(
      asset.uri,
      `${asset.categoryName}_${asset.title.replaceAll(' ', '')}`,
    );
  };

  const getServiceDescription = (serviceName: string): string => {
    if (serviceName === 'Instagram') {
      return 'These assets are sized in the correct dimensions for Instagram posts, stories, and reels.';
    }

    if (serviceName === 'Facebook') {
      return 'These assets are perfectly sized for Facebook posts and stories.';
    }

    if (serviceName === 'Twitter') {
      return 'These assets are perfectly sized for Twitter posts.';
    }

    return `These assets are perfectly sized for ${serviceName}.`;
  };

  return (
    <ContentTile>
      <Suspended isLoading={isLoading}>
        {visibleServiceNames.map((serviceName) => (
          <SocialMediaService
            key={serviceName}
            description={getServiceDescription(serviceName)}
            downloadAssetImage={downloadAssetImage}
            onClickExpand={onClickExpand}
            assets={visibleAssetsByServiceName[serviceName] ?? []}
            serviceName={serviceName}
            shopId={shopId}
          />
        ))}
        <AssetModal
          downloadAssetImage={downloadAssetImage}
          isOpen={isModalOpen}
          modalAsset={modalAsset}
          onClose={onCloseModal}
          serviceName={modalServiceName}
        />
      </Suspended>
    </ContentTile>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default ServicesTile;
