import { useEffect, useMemo, useState } from 'react';

import Button, { ButtonVariant } from 'components/shared/slice-button';
import {
  CategoryNavPage,
  CategoryNavType,
  useCategoryNavTracking,
} from 'hooks/menu';
import useDimensions from 'hooks/use-dimensions';
import CaretIcon from 'images/caret.svg?react';

import styles from './styles.module.scss';

type CategoryElement = ChildNode & HTMLDivElement;

type CategoryNameAndId = {
  name: string;
  id: number;
};

type Props = {
  categoryNamesAndIds: CategoryNameAndId[];
  onClickCategoryButton: (categoryId: number) => void;
  shopId: string;
};

// Logic for determining number of buttons to display based upon available
// width is based on Storefront logic:
// https://github.com/slicelife/storefront/blob/master/src/containers/MenuCategories/component.tsx

const CategoryButtons = ({
  categoryNamesAndIds,
  onClickCategoryButton,
  shopId,
}: Props) => {
  const { trackCategoryNavClick } = useCategoryNavTracking(shopId);

  const [numberOfFirstLineCategories, setNumberOfFirstLineCategories] =
    useState(5);

  const [categoryButtonsAreaRef, { width }, node] = useDimensions();

  const [firstChild, setFirstChild] = useState<CategoryElement | null>(null);

  useEffect(() => {
    setFirstChild(node?.firstChild as CategoryElement);
    return () => setFirstChild(null);
  }, [node]);

  const [isCollapsed, setIsCollapsed] = useState(true);

  const [menuCategoryWidths, setMenuCategoryWidths] = useState<number[]>([]);

  useEffect(() => {
    // Get the width of the category item buttons on first render.
    // This info will then be used to determine how many buttons to render before the
    // "More Categories" button (based upon available width).
    if (
      firstChild &&
      numberOfFirstLineCategories === categoryNamesAndIds.length
    ) {
      const widthArray = [];
      for (
        let child = firstChild.firstChild as CategoryElement;
        child !== null;
        child = child.nextSibling as CategoryElement
      ) {
        widthArray.push(child.clientWidth);
      }
      setMenuCategoryWidths(widthArray);
    }
  }, [firstChild, categoryNamesAndIds.length, numberOfFirstLineCategories]);

  // The number of categories to render in the list
  useEffect(() => {
    if (!width || !firstChild || menuCategoryWidths.length === 0) {
      setNumberOfFirstLineCategories(categoryNamesAndIds.length);
      return;
    }

    const moreButtonWidth = 180;
    const containerWidth = firstChild.clientWidth - moreButtonWidth;
    const padding = 16;

    let amountToRender = 0;
    let widthAcum = 0;
    menuCategoryWidths.slice().forEach((width, index, array) => {
      const itemWidth = width + padding;

      if (widthAcum + itemWidth <= containerWidth) {
        amountToRender++;
        widthAcum += itemWidth;
      } else {
        // Break out of the forEach function if there's no more space to add menu category items
        array.splice(1);
      }
    });
    setNumberOfFirstLineCategories(amountToRender);
  }, [firstChild, categoryNamesAndIds.length, menuCategoryWidths, width]);

  // Split category buttons into "first line" categories (i.e., before the "More Categories" button),
  // and the buttons to be displayed in the "More Categories" expansion area.
  const firstLineCategories = useMemo(
    () => categoryNamesAndIds.slice(0, numberOfFirstLineCategories),
    [categoryNamesAndIds, numberOfFirstLineCategories],
  );

  const moreAreaCategories = useMemo(
    () =>
      categoryNamesAndIds.slice(
        numberOfFirstLineCategories,
        categoryNamesAndIds.length,
      ),
    [categoryNamesAndIds, numberOfFirstLineCategories],
  );

  // Whether the "More Categories" button should be rendered
  const shouldRenderMoreButton = useMemo(
    () => moreAreaCategories.length > 0,
    [moreAreaCategories.length],
  );

  return (
    <div
      ref={categoryButtonsAreaRef}
      className={styles.categoryButtonsArea}
      aria-label="Category Buttons Area"
    >
      <div className={styles.topLineContainer}>
        {firstLineCategories.map((categoryNameAndId) => {
          const categoryId = categoryNameAndId.id;
          return (
            <Button
              onClick={() => {
                trackCategoryNavClick({
                  categoryNavType: CategoryNavType.ItemsPageTopLineButton,
                  categoryName: categoryNameAndId.name,
                  page: CategoryNavPage.ItemsPage,
                });
                onClickCategoryButton(categoryId);
              }}
              className={styles.categoryButton}
              key={categoryNameAndId.id}
              variant={ButtonVariant.CompactGroupOption}
            >
              {categoryNameAndId.name}
            </Button>
          );
        })}
        {shouldRenderMoreButton && (
          <Button
            onClick={() => setIsCollapsed((value) => !value)}
            className={styles.moreButton}
            aria-expanded={!isCollapsed}
            variant={ButtonVariant.Tertiary}
          >
            More Categories
            <CaretIcon
              className={
                isCollapsed
                  ? styles.moreCategoriesCaretClosed
                  : styles.moreCategoriesCaretOpen
              }
            />
          </Button>
        )}
      </div>
      {!isCollapsed && (
        <div className={styles.moreContainer}>
          {moreAreaCategories.map((categoryNameAndId) => {
            const categoryId = categoryNameAndId.id;
            return (
              <Button
                onClick={() => {
                  trackCategoryNavClick({
                    categoryNavType: CategoryNavType.ItemsPageMoreAreaButton,
                    categoryName: categoryNameAndId.name,
                    page: CategoryNavPage.ItemsPage,
                  });
                  onClickCategoryButton(categoryId);
                }}
                className={styles.categoryButton}
                key={categoryNameAndId.id}
                variant={ButtonVariant.CompactGroupOption}
              >
                {categoryNameAndId.name}
              </Button>
            );
          })}
        </div>
      )}
    </div>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default CategoryButtons;
