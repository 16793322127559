import PropTypes from 'prop-types';

import { Logomark } from 'crust';

import HamburgerIcon from 'images/nav/hamburger.svg?react';

import styles from './styles.module.scss';

const Header = ({ onHamburgerClick }) => (
  <div id="app-header" className={styles.mobileHeader}>
    <button onClick={onHamburgerClick} className={styles.hamburgerButton}>
      <HamburgerIcon className={styles.hamburger} />
    </button>
    <Logomark className={styles.sliceLogo} />
  </div>
);

Header.propTypes = {
  onHamburgerClick: PropTypes.func,
};

Header.defaultProps = {
  onHamburgerClick: () => {},
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default Header;
