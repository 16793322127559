import { ElementRef, ForwardedRef, forwardRef, Ref, useContext } from 'react';
import { Input as AriaInput } from 'react-aria-components';
import { Merge } from 'type-fest';

import { FieldChildren, FieldProps, useField } from '../field/field';
import { Icon } from '../icon/icon';

import { FileField, FileFieldProps, FileFieldStateContext } from './file-field';
import { formatFileSize } from './get-files-validation';

import './file-upload.css';

type FileNameProps = {
  className: string;
  value: string;
};

const FileName = ({ className, value }: FileNameProps) => (
  <div className={className}>
    {value.length < 8 ? (
      <span>{value}</span>
    ) : (
      <>
        <span>{value.slice(0, -7)}</span>
        <span>{value.slice(-7)}</span>
      </>
    )}
  </div>
);

type FileUploadHelperProps = {
  getFieldClassName: ReturnType<typeof useField>['getFieldClassName'];
  inputRef?: Ref<HTMLInputElement>;
};

const FileUploadHelper = ({
  getFieldClassName,
  inputRef,
}: FileUploadHelperProps) => {
  const state = useContext(FileFieldStateContext);

  if (state == null) {
    return null;
  }

  // We don't support multiple file uploads, yet.
  const file = state.value.at(0);

  return (
    <div className={getFieldClassName('container')}>
      <AriaInput className={getFieldClassName('input')} ref={inputRef} />
      <div className={getFieldClassName('information')}>
        {file == null ? (
          <>
            <Icon
              className={getFieldClassName('icon')}
              icon="page"
              size="large"
            />
            <div>
              Drag and drop or{' '}
              <span className={getFieldClassName('action')}>choose file</span>
            </div>
          </>
        ) : (
          <>
            <Icon className={getFieldClassName('icon')} icon="page" />
            <FileName
              className={getFieldClassName('filename')}
              value={file.name}
            />
            <div className={getFieldClassName('filesize')}>
              {formatFileSize(file.size)}
            </div>
            <div className={getFieldClassName('action')}>Replace</div>
          </>
        )}
      </div>
    </div>
  );
};

export type FileUploadProps = Merge<
  Omit<FileFieldProps, 'children'>,
  FieldProps & {
    inputRef?: Ref<HTMLInputElement>;
  }
>;

export const FileUpload = forwardRef(function FileUpload(
  { className, label, description, error, inputRef, ...props }: FileUploadProps,
  ref: ForwardedRef<ElementRef<typeof FileField>>,
) {
  const { getFieldClassName, fieldProps, fieldChildrenProps } = useField({
    className,
    description,
    error,
    label,
    block: 'file-upload',
  });

  return (
    <FileField ref={ref} {...fieldProps} {...props}>
      <FieldChildren {...fieldChildrenProps}>
        <FileUploadHelper
          getFieldClassName={getFieldClassName}
          inputRef={inputRef}
        />
      </FieldChildren>
    </FileField>
  );
});
