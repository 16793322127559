import { useMutation } from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { Shop } from 'types/shops';
import { camelCaseKeys } from 'utilities/objects';

export const useCreateDebitCardMutation = (shopId: Shop['shopId']) => {
  const { authenticatedFetch } = useApi();

  return useMutation({
    mutationFn: async (debitCardToken: string) => {
      const response = await authenticatedFetch.post(
        `api/management/v1/shops/${shopId}/debit_cards`,
        {
          token: {
            id: debitCardToken,
          },
        },
      );

      return camelCaseKeys(response);
    },
  });
};
