import * as React from "react";
import { forwardRef } from "react";
const SvgShopIcon = ({
  title,
  titleId,
  ...props
}, ref) => {
  return /* @__PURE__ */ React.createElement("svg", { width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", "aria-hidden": "true", ref, "aria-labelledby": titleId, ...props }, title === void 0 ? /* @__PURE__ */ React.createElement("title", { id: titleId }, "Shop Icon") : title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { d: "M8.83906 3H5.93493C5.45272 3 4.99026 3.18964 4.64929 3.52721L2.7988 5.35919C2.28734 5.86554 2 6.55229 2 7.26838V8.4C2 9.39412 2.81403 10.2 3.81818 10.2H5.63636C6.64052 10.2 7.45455 9.39412 7.45455 8.4V6.6C7.45455 6.4855 7.47661 6.37206 7.51957 6.26575L8.83906 3Z", fill: "currentColor" }), /* @__PURE__ */ React.createElement("path", { d: "M9.27273 8.4C9.27273 9.39412 10.0868 10.2 11.0909 10.2H12.9091C13.9132 10.2 14.7273 9.39412 14.7273 8.4V6.77333L13.2027 3H10.7973L9.27273 6.77333V8.4Z", fill: "currentColor" }), /* @__PURE__ */ React.createElement("path", { d: "M16.4804 6.26575L15.1609 3H18.0651C18.5473 3 19.0097 3.18964 19.3507 3.52721L21.2012 5.35919C21.7127 5.86554 22 6.55229 22 7.26838V8.4C22 9.39412 21.186 10.2 20.1818 10.2H18.3636C17.3595 10.2 16.5455 9.39412 16.5455 8.4V6.6C16.5455 6.4855 16.5234 6.37206 16.4804 6.26575Z", fill: "currentColor" }), /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M2.90909 19.2V11.8866C3.19965 11.9606 3.50427 12 3.81818 12H5.63636C6.72245 12 7.69732 11.5286 8.36363 10.7812C9.02995 11.5286 10.0048 12 11.0909 12H12.9091C13.9952 12 14.9701 11.5286 15.6364 10.7812C16.3027 11.5286 17.2776 12 18.3636 12H20.1818C20.4957 12 20.8003 11.9606 21.0909 11.8866V19.2C21.0909 20.1941 20.2769 21 19.2727 21H11.0909V14.7H7.45455V21H4.72727C3.72312 21 2.90909 20.1941 2.90909 19.2ZM18.3636 14.7H13.8182V18.3H18.3636V14.7Z", fill: "currentColor" }));
};
const ForwardRef = forwardRef(SvgShopIcon);
export default ForwardRef;
