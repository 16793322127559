import { useContext } from 'react';

import {
  AnalyticsContext,
  CompiledAnalyticsMethods,
} from 'providers/analytics';

const useAnalytics = (): CompiledAnalyticsMethods => {
  const analytics = useContext(AnalyticsContext);

  if (analytics == null) {
    throw new Error('useAnalytics must be used within an AnalyticsProvider.');
  }

  return analytics;
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default useAnalytics;
