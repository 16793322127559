import ExpandAdvertisingOptions from 'components/campaign-manager/campaign-info/expand-advertising-options';
import HistoryTile from 'components/campaign-manager/campaign-info/history-tile';
import ResultsTile from 'components/campaign-manager/campaign-info/results-tile';
import {
  CampaignManagerState,
  CampaignManagerSummary,
} from 'types/campaign-manager';
import { Shop } from 'types/shops';
import { User } from 'types/user';
import {
  getPlatformPerformingBetterText,
  getRecentResultsPerformanceText,
} from 'utilities/campaign-manager';

type Props = {
  shopId: Shop['shopId'];
  summaryData: CampaignManagerSummary;
  user: User;
};

const ExecutionPhase = ({ shopId, summaryData, user }: Props) => {
  return (
    <>
      <ResultsTile
        campaignState={CampaignManagerState.ExecutionPhase}
        description="Last 90 Days"
        insights={[
          getRecentResultsPerformanceText(summaryData?.last90Days?.sales),
          getPlatformPerformingBetterText(
            summaryData?.last90Days?.orders,
            summaryData?.platforms,
          ),
        ].filter((node) => node !== null)}
        platformsInfo={summaryData?.platforms}
        results={summaryData?.last90Days}
        shopId={shopId}
        title="Recent Results"
      />
      <HistoryTile shopId={shopId} lifetimeResults={summaryData?.lifetime} />
      <ExpandAdvertisingOptions shopId={shopId} user={user} />
    </>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default ExecutionPhase;
