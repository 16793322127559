import * as React from "react";
import { forwardRef } from "react";
const SvgBannerIcon = ({
  title,
  titleId,
  ...props
}, ref) => {
  return /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: 69, height: 53, fill: "none", viewBox: "0 0 69 53", ref, "aria-labelledby": titleId, ...props }, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("rect", { width: 69, height: 53, fill: "#fff", rx: 5 }), /* @__PURE__ */ React.createElement("rect", { width: 67, height: 51, x: 1, y: 1, stroke: "currentColor", strokeWidth: 2, rx: 3 }), /* @__PURE__ */ React.createElement("path", { stroke: "currentColor", strokeWidth: 2, d: "M1 9h66" }), /* @__PURE__ */ React.createElement("path", { fill: "currentColor", fillRule: "evenodd", d: "M7 5a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm2 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z", clipRule: "evenodd" }), /* @__PURE__ */ React.createElement("path", { fill: "currentColor", d: "M0 8h69v9H0z" }), /* @__PURE__ */ React.createElement("rect", { width: 14, height: 3, x: 35, y: 11, fill: "#fff", rx: 1.5 }), /* @__PURE__ */ React.createElement("rect", { width: 14, height: 3, x: 51, y: 11, fill: "#fff", rx: 1.5 }));
};
const ForwardRef = forwardRef(SvgBannerIcon);
export default ForwardRef;
