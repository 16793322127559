import ContentTile from 'components/shared/content-tile';
import useCustomersAnalyticsQuery from 'hooks/customers/use-customers-analytics-query';
import {
  CustomerList,
  CustomerType,
  DateRange,
  RegistrationType,
} from 'types/customers';

import CustomerCountInfo from '../customer-count-info';

import styles from './styles.module.scss';

type Props = {
  customerType: CustomerType;
  dateRange: DateRange;
  shopId: string;
  timezone: string;
};

const ServiceTypeTile = ({
  customerType,
  dateRange,
  shopId,
  timezone,
}: Props) => {
  const { data: customersAnalytics, isLoading } = useCustomersAnalyticsQuery({
    customerList: CustomerList.All,
    customerType,
    dateRange,
    registrationType: RegistrationType.Registered,
    shopId,
    timezone,
  });

  return (
    <ContentTile
      className={styles.tile}
      dataTestId="customer-dashboard-service-type-tile"
      headerClassName={styles.tileHeader}
      title="Order Type"
    >
      <CustomerCountInfo
        count={customersAnalytics?.deliveryUsersCount}
        customerList={CustomerList.Delivery}
        description={<div className={styles.typeName}>Delivery Customers</div>}
        isLoading={isLoading}
        shopId={shopId}
      />
      <CustomerCountInfo
        count={customersAnalytics?.pickupUsersCount}
        customerList={CustomerList.Pickup}
        description={<div className={styles.typeName}>Pickup Customers</div>}
        isLoading={isLoading}
        shopId={shopId}
      />
    </ContentTile>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default ServiceTypeTile;
