import { ShopProfile } from 'types/brand-manager';
import { formatUSPhoneNumber } from 'utilities/strings';

import styles from './styles.module.scss';

type Props = {
  shopProfile: ShopProfile | undefined;
};

const BusinessProfileTileContent = ({ shopProfile }: Props) => {
  if (!shopProfile) {
    return <></>;
  }

  const { logo, name, address, city, state, zipcode, phone } = shopProfile;

  const formattedAddress = `${address}, ${city}, ${state}, ${zipcode}`;
  const formattedPhone = formatUSPhoneNumber(String(phone));

  return (
    <div className={styles.tileContentContainer}>
      <div className={styles.logoContainer}>
        <img className={styles.logo} alt="" src={logo ?? ''} />
      </div>
      <div className={styles.textContainer}>
        <div className={styles.shopName}>{name}</div>
        <div className={styles.addressAndPhone}>{formattedAddress}</div>
        <div className={styles.addressAndPhone}>{formattedPhone}</div>
      </div>
    </div>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default BusinessProfileTileContent;
