import { FormEventHandler, PropsWithChildren } from 'react';
import {
  Control,
  FieldError,
  FieldErrors,
  UseFormGetValues,
  UseFormRegister,
  UseFormTrigger,
} from 'react-hook-form';

import { PromoCodeFormValues } from 'types/discounts';
import { Shop } from 'types/shops';

import { ChannelFields } from './channel-fields';
import { DateFields } from './date-fields';
import { OrderMinimumFields } from './order-minimum-fields';
import { TagFields } from './tag-fields';
import { TypeFields } from './type-fields';
import { UsageLimitFields } from './usage-limit-fields';

import styles from './styles.module.scss';

type Props = {
  control: Control<PromoCodeFormValues>;
  errors: FieldErrors<PromoCodeFormValues>;
  getValues: UseFormGetValues<PromoCodeFormValues>;
  onSubmit: FormEventHandler<HTMLFormElement>;
  register: UseFormRegister<PromoCodeFormValues>;
  shopTimezone: Shop['timezoneIdentifier'];
  trigger: UseFormTrigger<PromoCodeFormValues>;
};

export const PromoCodeForm = ({
  children,
  control,
  errors,
  getValues,
  register,
  shopTimezone,
  trigger,
  ...props
}: PropsWithChildren<Props>) => {
  return (
    <form {...props} className={styles.form}>
      <TagFields
        className={styles.tile}
        control={control}
        register={register}
        tagError={errors.tag}
      />
      <TypeFields
        amountError={errors.amount}
        className={styles.tile}
        control={control}
        getValues={getValues}
        register={register}
        trigger={trigger}
      />
      <OrderMinimumFields
        className={styles.tile}
        control={control}
        orderMinimumError={errors.orderMinimum}
        register={register}
      />
      <UsageLimitFields className={styles.tile} control={control} />
      <DateFields
        className={styles.tile}
        control={control}
        shopTimezone={shopTimezone}
      />
      <ChannelFields
        channelsError={errors.channels as FieldError}
        control={control}
      />
      {children}
    </form>
  );
};
