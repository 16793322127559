import * as React from "react";
import { forwardRef } from "react";
import { useId } from "react-aria";
const SvgRepeatCustomerIcon = ({
  title,
  titleId,
  ...props
}, ref) => {
  const id0 = useId();
  const id1 = useId();
  return /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", xmlSpace: "preserve", enableBackground: "new 0 0 32 32", viewBox: "0 0 32 32", ref, "aria-labelledby": titleId, ...props }, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("circle", { cx: 16, cy: 16, r: 16, fill: "#f5f3f0" }), /* @__PURE__ */ React.createElement("path", { fill: "#6092c0", d: "m13 15 4 4-4 4v-8z" }), /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("filter", { id: id1, width: 20, height: 10, x: 6, y: 10, filterUnits: "userSpaceOnUse" }, /* @__PURE__ */ React.createElement("feColorMatrix", { values: "1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0" }))), /* @__PURE__ */ React.createElement("mask", { id: id0, width: 20, height: 10, x: 6, y: 10, maskUnits: "userSpaceOnUse" }, /* @__PURE__ */ React.createElement("path", { fill: "#fff", d: "M16 22v-5h4v5h7V9H5v13h11z", filter: `url(#${id1})` })), /* @__PURE__ */ React.createElement("g", { mask: `url(#${id0})` }, /* @__PURE__ */ React.createElement("path", { fill: "none", stroke: "#6092c0", strokeWidth: 2, d: "M25 15c0 .8-.67 1.79-2.38 2.64C20.98 18.46 18.64 19 16 19s-4.98-.54-6.62-1.36C7.67 16.79 7 15.8 7 15s.67-1.79 2.38-2.64C11.02 11.54 13.36 11 16 11s4.98.54 6.62 1.36C24.33 13.21 25 14.2 25 15z" })));
};
const ForwardRef = forwardRef(SvgRepeatCustomerIcon);
export default ForwardRef;
