import { useState } from 'react';

import { OptimizeModal } from 'components/campaign-manager/optimize-modal';
import Button, { ButtonVariant } from 'components/shared/slice-button';
import { Shop } from 'types/shops';

import styles from './styles.module.scss';

type Props = {
  description: string;
  isOptimizeMyCampaignLinkVisible?: boolean;
  shopId: Shop['shopId'];
  title: string;
};

export const Header = ({
  description,
  isOptimizeMyCampaignLinkVisible = true,
  shopId,
  title,
}: Props) => {
  const [isOpenModal, setIsOpenModal] = useState(false);

  const handleToggleModal = () => setIsOpenModal((prev) => !prev);

  return (
    <div className={styles.header}>
      <div>
        <div className={styles.title}>{title}</div>
        <div className={styles.details}>{description}</div>
      </div>
      <div className={styles.linksContainer}>
        {isOptimizeMyCampaignLinkVisible && (
          <Button
            className={styles.link}
            onClick={handleToggleModal}
            variant={ButtonVariant.LinkStyleSecondary}
          >
            Optimize My Campaign
          </Button>
        )}
        {/*
          // This section below is commented out because it will be implemented in this ticket:
          // https://app.shortcut.com/slicelife/story/414896/add-link-to-facebook-ad-on-campaign-manager-page
          <div className={styles.linkDivider}>|</div>
          <Button
            className={styles.link}
            onClick={() =>
              alert('TBD. Should eventually be a link to Facebook ad.')
            }
            variant={ButtonVariant.LinkStyleSecondary}
          >
            View My Ad
          </Button> 
        */}
      </div>
      {isOpenModal && (
        <OptimizeModal handleCloseModal={handleToggleModal} shopId={shopId} />
      )}
    </div>
  );
};
