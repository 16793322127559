import cx from 'classnames';

import PageControl from '../page-control';
import PerPageMenu from '../per-page-menu';

import styles from './styles.module.scss';

type Props = {
  className?: string;
  currentPage: number;
  isPageControlVisible: boolean;
  isPerPageControlVisible?: boolean;
  onChangePage: (page: number) => void;
  onChangePerPage: (perPage: number) => void;
  perPage: number;
  totalPages: number;
};

const Pagination = ({
  className,
  currentPage,
  isPageControlVisible,
  isPerPageControlVisible = true,
  onChangePage,
  onChangePerPage,
  perPage,
  totalPages,
}: Props) => {
  return (
    <div
      className={cx(
        className,
        isPageControlVisible
          ? styles.containerWithPageControl
          : styles.containerWithoutPageControl,
      )}
    >
      {isPageControlVisible && (
        <PageControl
          containerClassName={styles.pageControlContainer}
          currentPage={currentPage}
          setPage={onChangePage}
          totalPages={totalPages}
        />
      )}
      {isPerPageControlVisible && (
        <div className={styles.perPageControlContainer}>
          <PerPageMenu perPage={perPage} setPerPage={onChangePerPage} />
        </div>
      )}
    </div>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default Pagination;
