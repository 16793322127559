import { useMemo } from 'react';

import { Button, Link } from 'crust';

import Callout from 'components/shared/callout';
import useAnalytics from 'hooks/use-analytics';
import Register from 'images/stand-on-cashbox.png';
import { getRegisterSplashPath } from 'routes/paths/register';
import { CalloutEventType } from 'types/callout';
import { ClickedExternalLinkType } from 'types/shared/analytics';
import { Shop } from 'types/shops';

type Props = {
  hasCustomWebsite: boolean;
  hasOwnWebsite: boolean;
  hasSmartButton: boolean;
  hasValidCase: boolean;
  isOnlineOrdering: boolean;
  onRequestDemoButtonClick: () => void;
  shopId: Shop['shopId'];
};

const RegisterCallout = ({
  hasCustomWebsite,
  hasOwnWebsite,
  hasSmartButton,
  hasValidCase,
  isOnlineOrdering,
  onRequestDemoButtonClick,
  shopId,
}: Props) => {
  const { trackClickedCalloutAction, trackProductUpsellLinkClick } =
    useAnalytics();

  const handleScheduleDemoClick = () => {
    onRequestDemoButtonClick();
    trackProductUpsellLinkClick({
      linkLabel: 'schedule demo',
      outcome: 'lead',
      page: 'home',
      product: 'register',
      shopId: String(shopId),
      type: ClickedExternalLinkType.LinkedText,
    });
    trackClickedCalloutAction({
      '1stLinkLabel': 'Schedule Demo',
      '2ndLinkLabel': 'Learn More',
      actionClicked: 'Schedule Demo',
      hasCustomWebsite: hasCustomWebsite,
      hasOwnWebsite: hasOwnWebsite,
      hasSmartButton: hasSmartButton,
      isOnlineOrdering: isOnlineOrdering,
      isRegister: false,
      page: 'homepage',
      shopId: String(shopId),
      type: 'register_callout',
    });
  };

  const handleLearnMoreClick = () => {
    trackClickedCalloutAction({
      '1stLinkLabel': 'Schedule Demo',
      '2ndLinkLabel': 'Learn More',
      actionClicked: 'Learn More',
      hasCustomWebsite: hasCustomWebsite,
      hasOwnWebsite: hasOwnWebsite,
      hasSmartButton: hasSmartButton,
      isOnlineOrdering: isOnlineOrdering,
      isRegister: false,
      page: 'homepage',
      shopId: String(shopId),
      type: 'register_callout',
    });
  };

  const analyticEvent: CalloutEventType = useMemo(
    () => ({
      '1stLinkLabel': 'Schedule Demo',
      '2ndLinkLabel': 'Learn More',
      hasCustomWebsite: hasCustomWebsite,
      hasOwnWebsite: hasOwnWebsite,
      hasSmartButton: hasSmartButton,
      isOnlineOrdering: isOnlineOrdering,
      isRegister: false,
      page: 'homepage',
      shopId: String(shopId),
      type: 'register_callout',
    }),
    [hasCustomWebsite, hasOwnWebsite, hasSmartButton, isOnlineOrdering, shopId],
  );

  return (
    <Callout
      event={analyticEvent}
      image={Register}
      primary={
        hasValidCase ? null : (
          <Button onPress={handleScheduleDemoClick}>Schedule Demo</Button>
        )
      }
      secondary={
        <Link
          href={getRegisterSplashPath(shopId)}
          onPress={handleLearnMoreClick}
        >
          Learn More
        </Link>
      }
      text={
        'Slice Register is the simple-to-use POS that transforms your business.'
      }
    />
  );
};
/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default RegisterCallout;
