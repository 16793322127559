import Page from 'components/shared/page';
import ShopScore from 'components/shop-score';
import { useCurrentShopId } from 'hooks/shops';

const pageName = 'shop-score';

const ShopScorePage = () => {
  const shopId = useCurrentShopId();

  return (
    <Page name={pageName} title="Pizzeria Score">
      <ShopScore shopId={String(shopId)} />
    </Page>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default ShopScorePage;
