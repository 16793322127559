const toDataURL = (url: string): Promise<string> => {
  return fetch(url)
    .then((response) => {
      return response.blob();
    })
    .then((blob) => {
      return URL.createObjectURL(blob);
    });
};

export const downloadImage = async (
  imageUrl: string,
  fileName: string,
): Promise<void> => {
  const fileExtension = imageUrl.split('.').pop();
  const objectUrl = await toDataURL(imageUrl);

  const element = document.createElement('a');
  element.style.display = 'none';
  element.href = objectUrl;
  document.body.appendChild(element);
  element.download = `${fileName}.${fileExtension}`;
  element.click();
  document.body.removeChild(element);
};
