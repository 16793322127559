import { ComponentPropsWithoutRef } from 'react';
import classNames from 'classnames';

import { getCrustClassName } from '../utilities/get-crust-class-name';

import './badge.css';

type BadgeVariant = 'critical' | 'neutral' | 'success' | 'warning';

export type BadgeProps = ComponentPropsWithoutRef<'span'> & {
  children: string;
  variant?: BadgeVariant;
};

export const Badge = ({
  children,
  className,
  variant = 'neutral',
  ...props
}: BadgeProps) => (
  <span
    className={classNames(getCrustClassName('badge'), className)}
    data-variant={variant}
    {...props}
  >
    {children}
  </span>
);
