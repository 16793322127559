import {
  QueryKey,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { RegisterDiscount } from 'types/discounts';
import { camelCaseKeys } from 'utilities/objects';

import { getRegisterDiscountsQueryKey } from './use-register-discounts-query';

type UseRegisterDiscountQueryOptions = Omit<
  UseQueryOptions<RegisterDiscount>,
  'queryFn' | 'queryKey'
>;

export const getRegisterDiscountQueryKey = (
  shopId: number,
  discountId: string,
): QueryKey => [...getRegisterDiscountsQueryKey(shopId), discountId];

export const useRegisterDiscountQuery = (
  shopId: number,
  discountId: string,
  options?: UseRegisterDiscountQueryOptions,
): UseQueryResult<RegisterDiscount> => {
  const { authenticatedFetch } = useApi();

  return useQuery({
    queryKey: getRegisterDiscountQueryKey(shopId, discountId),
    queryFn: async () => {
      const response = await authenticatedFetch.get(
        `api/management/v1/ros/shops/${shopId}/discounts/${discountId}`,
      );

      return camelCaseKeys(response) as RegisterDiscount;
    },
    ...options,
  });
};
