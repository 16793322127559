import moment from 'moment-timezone';

import Text from 'components/shared/text';
import useAnalytics from 'hooks/use-analytics';
import { BaseCustomer, CustomerList } from 'types/customers';

import styles from './styles.module.scss';

type Props = {
  customer: BaseCustomer;
  customerList: CustomerList;
  displayName: string;
  setSelectedCustomer: React.Dispatch<
    React.SetStateAction<BaseCustomer | null>
  >;
  shopId: string;
  shopTimezone: string;
};

const CustomerNameButton = ({
  customer,
  customerList,
  displayName,
  setSelectedCustomer,
  shopId,
  shopTimezone,
}: Props) => {
  const { trackClickedCustomerName } = useAnalytics();

  const handleClick = (): void => {
    trackClickedCustomerName({
      averageOrderValue: customer.averageOrderValue,
      customerFullName: displayName,
      customerList,
      customerUuid: customer.uuid,
      loyaltyStatus: customer.loyaltyStatus,
      lastOrderedAt: moment
        .tz(customer.lastOrderedAt, shopTimezone)
        .format('MM/DD/YYYY'),
      orderCount: customer.orderCount,
      orderTotal: customer.orderTotal,
      shopId,
    });

    setSelectedCustomer(customer);
  };

  // This button will eventually be replaced with the `<Link as="button" />`
  // from the Crust Web Library.
  return (
    <button
      className={styles.customerNameLink}
      onClick={handleClick}
      type="button"
    >
      <Text as="span" wrap="truncate">
        {displayName}
      </Text>
    </button>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default CustomerNameButton;
