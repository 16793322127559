import { Controller } from 'react-hook-form';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { IconButton } from 'crust';

import FormFeedback from 'components/shared/form-feedback';
import Input from 'components/shared/input';
import NumberInput from 'components/shared/number-input';
import VisuallyHiddenLabel from 'components/shared/visually-hidden/label';
import useValidationErrorTracking, {
  ValidationErrorLocation,
  ValidationInputId,
} from 'hooks/use-validation-error-tracking';
import { toDollarString, toPennyString } from 'utilities/currency';
import {
  AnalyticsField,
  getProductTypeFieldName,
  ProductField,
  ProductTypeField,
} from 'utilities/menu';

import styles from './styles.module.scss';

const ProductType = ({
  control,
  getValues,
  id,
  index,
  length,
  nameError,
  priceError,
  register,
  removeField,
  setValue,
  shopId,
  submitCount,
}) => {
  useValidationErrorTracking(
    shopId,
    ValidationErrorLocation.MenuProductEditPage,
    ValidationInputId.ProductTypeName,
    nameError,
    submitCount,
  );

  useValidationErrorTracking(
    shopId,
    ValidationErrorLocation.MenuProductEditPage,
    ValidationInputId.ProductTypePrice,
    priceError,
    submitCount,
  );

  const validateNameInput = (value) => {
    const productTypes = getValues(ProductField.ProductTypes);

    if (productTypes.some((p, i) => i !== index && p.name === value)) {
      return 'Names must be unique.';
    }

    return true;
  };

  return (
    <>
      <VisuallyHiddenLabel htmlFor={`product-type-${index}-name-input`}>
        Product type {index + 1} name
      </VisuallyHiddenLabel>
      <Input
        aria-required="true"
        className={styles.name}
        id={`product-type-${index}-name-input`}
        isInvalid={nameError != null}
        placeholder="e.g. Small"
        {...register(getProductTypeFieldName(ProductTypeField.Name, index), {
          deps: [...Array(length).keys()]
            .filter((it) => it !== index)
            .map((it) => getProductTypeFieldName(ProductTypeField.Name, it)),
          maxLength: {
            message: 'Please enter no more than 300 characters.',
            value: 300,
          },
          onChange: () => setValue(AnalyticsField.TypeNameChanged, true),
          required: 'Please enter a name.',
          validate: validateNameInput,
        })}
      />
      <VisuallyHiddenLabel htmlFor={`product-type-${index}-price-input`}>
        Product type {index + 1} price
      </VisuallyHiddenLabel>
      <Controller
        control={control}
        name={getProductTypeFieldName(ProductTypeField.Price, index)}
        rules={{
          onChange: () => setValue(AnalyticsField.TypePriceChanged, true),
          required: 'Please enter a price.',
          min: { value: 1, message: 'Must be at least $0.01.' },
          max: { value: 99999, message: 'Must be less than $1,000.' },
        }}
        render={({ field, fieldState }) => (
          <NumberInput
            aria-required="true"
            className={styles.price}
            id={`product-type-${index}-price-input`}
            isInvalid={fieldState.error != null}
            name={field.name}
            onBlur={field.onBlur}
            onValueChange={(values) =>
              field.onChange(toPennyString(values.value))
            }
            prefix="$"
            ref={field.ref}
            value={toDollarString(field.value)}
            valueIsNumericString
          />
        )}
      />
      <input
        defaultValue={id}
        type="hidden"
        {...register(getProductTypeFieldName(ProductTypeField.Id, index))}
      />
      {removeField && (
        <IconButton
          aria-label={`Delete product type ${index + 1}`}
          className={styles.delete}
          icon="trashCan"
          onPress={removeField}
        />
      )}
      <FormFeedback className={cx(styles.name, styles.feedback)}>
        {nameError?.message}
      </FormFeedback>
      <FormFeedback className={cx(styles.price, styles.feedback)}>
        {priceError?.message}
      </FormFeedback>
    </>
  );
};

ProductType.propTypes = {
  control: PropTypes.object.isRequired,
  getValues: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  length: PropTypes.number.isRequired,
  nameError: PropTypes.shape({
    message: PropTypes.string.isRequired,
  }),
  priceError: PropTypes.shape({
    message: PropTypes.string.isRequired,
  }),
  register: PropTypes.func.isRequired,
  removeField: PropTypes.func,
  setValue: PropTypes.func.isRequired,
  submitCount: PropTypes.number.isRequired,
  shopId: PropTypes.string,
};

ProductType.defaultProps = {
  shopId: '',
  nameError: null,
  priceError: null,
  removeField: null,
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default ProductType;
