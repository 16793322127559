import {
  ElementRef,
  ForwardedRef,
  ReactNode,
  useCallback,
  useMemo,
} from 'react';
import {
  DateInput as AriaDateInput,
  DateInputProps as AriaDateInputProps,
  DatePicker as AriaDatePicker,
  DatePickerProps as AriaDatePickerProps,
  DateRangePicker as AriaDateRangePicker,
  DateRangePickerProps as AriaDateRangePickerProps,
  DateSegment as AriaDateSegment,
  DateSegmentProps as AriaDateSegmentProps,
  GroupContext as AriaGroupContext,
  useSlottedContext,
} from 'react-aria-components';
import { DateValue } from '@internationalized/date';
import { useDescription } from '@react-aria/utils';
import { useControlledState } from '@react-stately/utils';
import classNames from 'classnames';
import { Merge } from 'type-fest';

import {
  Calendar,
  PresetRangeCalendar,
  PresetRangeValue,
  PresetRangeValueProps,
  RangeCalendar,
} from '../calendar/calendar';
import { FieldChildren, FieldProps, useField } from '../field/field';
import { IconButton } from '../icon-button-link/icon-button-link';
import { InputGroup } from '../input/input';
import { Popover } from '../popover/popover';
import { Tray } from '../tray/tray';
import { forwardRefWithGenerics } from '../utilities/forward-ref-with-generics';
import { getCrustClassName } from '../utilities/get-crust-class-name';
import { mergeAriaClassName } from '../utilities/merge-aria-class-name';
import { useIsMobileDevice } from '../utilities/use-is-mobile-device';

import './date-range-picker.css';

type DateSegmentProps = AriaDateSegmentProps;

const DateSegment = ({ className, ...props }: DateSegmentProps) => (
  <AriaDateSegment
    className={mergeAriaClassName(getCrustClassName('date-segment'), className)}
    {...props}
  />
);

type DateInputProps = Omit<AriaDateInputProps, 'children'>;

const DateInput = ({ className, ...props }: DateInputProps) => (
  <AriaDateInput
    className={mergeAriaClassName(getCrustClassName('date-input'), className)}
    {...props}
  >
    {(segment) => <DateSegment segment={segment} />}
  </AriaDateInput>
);

const getCalendarButtonClassName = getCrustClassName.bind(
  null,
  'calendar-button',
);

const CalendarButton = () => (
  <IconButton className={getCalendarButtonClassName()} icon="calendar" />
);

type CalendarOverlayProps = {
  children: ReactNode;
  className?: string;
};

const CalendarOverlay = ({
  children,
  className: propsClassName,
}: CalendarOverlayProps) => {
  const className = classNames(
    getCrustClassName('calendar-overlay'),
    propsClassName,
  );
  const isMobileDevice = useIsMobileDevice();

  if (isMobileDevice) {
    return (
      <Tray isDismissable className={className}>
        {children}
      </Tray>
    );
  }

  return <Popover className={className}>{children}</Popover>;
};

export type DatePickerProps<T extends DateValue> = Merge<
  AriaDatePickerProps<T>,
  FieldProps
>;

export const DatePicker = forwardRefWithGenerics(function DatePicker<
  T extends DateValue,
>(
  {
    className,
    description,
    error,
    label,
    shouldForceLeadingZeros = true,
    ...props
  }: DatePickerProps<T>,
  ref: ForwardedRef<ElementRef<typeof AriaDatePicker>>,
) {
  const { getFieldClassName, fieldProps, fieldChildrenProps } = useField({
    className,
    description,
    error,
    label,
    block: 'date-picker',
  });

  return (
    <AriaDatePicker
      ref={ref}
      shouldForceLeadingZeros={shouldForceLeadingZeros}
      {...fieldProps}
      {...props}
    >
      <FieldChildren {...fieldChildrenProps}>
        <InputGroup className={getFieldClassName('input-group')}>
          <DateInput className={getFieldClassName('input')} />
          <CalendarButton />
        </InputGroup>
        <CalendarOverlay className={getFieldClassName('overlay')}>
          <Calendar className={getFieldClassName('calendar')} />
        </CalendarOverlay>
      </FieldChildren>
    </AriaDatePicker>
  );
});

export type DateRangePickerProps<T extends DateValue> = Merge<
  AriaDateRangePickerProps<T>,
  FieldProps
>;

export const DateRangePicker = forwardRefWithGenerics(function DateRangePicker<
  T extends DateValue,
>(
  {
    className,
    description,
    error,
    label,
    shouldForceLeadingZeros = true,
    ...props
  }: DateRangePickerProps<T>,
  ref: ForwardedRef<ElementRef<typeof AriaDateRangePicker>>,
) {
  const { getFieldClassName, fieldProps, fieldChildrenProps } = useField({
    className,
    description,
    error,
    label,
    block: 'date-range-picker',
  });

  return (
    <AriaDateRangePicker
      ref={ref}
      shouldForceLeadingZeros={shouldForceLeadingZeros}
      {...fieldProps}
      {...props}
    >
      <FieldChildren {...fieldChildrenProps}>
        <InputGroup className={getFieldClassName('input-group')}>
          <DateInput className={getFieldClassName('input')} slot="start" />
          <span aria-hidden="true">–</span>
          <DateInput className={getFieldClassName('input')} slot="end" />
          <CalendarButton />
        </InputGroup>
        <CalendarOverlay className={getFieldClassName('overlay')}>
          <RangeCalendar className={getFieldClassName('calendar')} />
        </CalendarOverlay>
      </FieldChildren>
    </AriaDateRangePicker>
  );
});

type PresetInputGroupProps = {
  value: PresetRangeValue<DateValue, string> | null;
};

const getPresetInputGroupClassName = getCrustClassName.bind(
  null,
  'preset-input-group',
);

const PresetInputGroup = ({ value }: PresetInputGroupProps) => {
  const ctx = useSlottedContext(AriaGroupContext);

  const shouldShowInputs = value == null || value.id === 'custom';
  const description =
    value == null ? undefined : `Selected Preset: ${value.label}`;
  const descriptionProps = useDescription(description);

  // Make sure the preset label appears first.
  const ariaDescribedBy =
    [descriptionProps['aria-describedby'], ctx?.['aria-describedby']]
      .filter(Boolean)
      .join(' ') || undefined;

  return (
    <InputGroup
      className={getPresetInputGroupClassName()}
      aria-describedby={ariaDescribedBy}
    >
      {shouldShowInputs ? (
        <>
          <DateInput
            className={getPresetInputGroupClassName('input')}
            slot="start"
          />
          <span aria-hidden="true">–</span>
          <DateInput
            className={getPresetInputGroupClassName('input')}
            slot="end"
          />
        </>
      ) : (
        <span className={getPresetInputGroupClassName('label')}>
          {value.label}
        </span>
      )}
      <CalendarButton />
    </InputGroup>
  );
};

export type PresetRangePickerProps<
  T extends DateValue,
  S extends string,
> = Merge<
  AriaDateRangePickerProps<T>,
  FieldProps & PresetRangeValueProps<T, S>
>;

export const PresetRangePicker = forwardRefWithGenerics(
  function PresetRangePicker<T extends DateValue, S extends string>(
    {
      className,
      defaultOpen,
      defaultValue,
      description,
      error,
      isOpen: propsIsOpen,
      label,
      onChange,
      onOpenChange,
      presets,
      shouldCloseOnSelect = true,
      shouldForceLeadingZeros = true,
      value: propsValue,
      ...props
    }: PresetRangePickerProps<T, S>,
    ref: ForwardedRef<ElementRef<typeof AriaDateRangePicker>>,
  ) {
    const [value, setValue] = useControlledState(
      propsValue,
      defaultValue || null,
      onChange,
    );

    const [isOpen, setIsOpen] = useControlledState(
      propsIsOpen,
      defaultOpen || false,
      onOpenChange,
    );

    const { getFieldClassName, fieldProps, fieldChildrenProps } = useField({
      className,
      description,
      error,
      label,
      block: 'preset-range-picker',
    });

    const dates = useMemo(
      () => (value ? { start: value.start, end: value.end } : null),
      [value],
    );

    const closeOnSelect = useCallback(() => {
      const shouldClose =
        typeof shouldCloseOnSelect === 'function'
          ? shouldCloseOnSelect()
          : shouldCloseOnSelect;

      if (shouldClose) {
        setIsOpen(false);
      }
    }, [setIsOpen, shouldCloseOnSelect]);

    return (
      <AriaDateRangePicker
        isOpen={isOpen}
        onChange={(value) =>
          setValue(
            value == null
              ? null
              : {
                  id: 'custom',
                  label: 'Custom',
                  start: value.start as unknown as T,
                  end: value.end as unknown as T,
                },
          )
        }
        onOpenChange={setIsOpen}
        ref={ref}
        shouldCloseOnSelect={shouldCloseOnSelect}
        shouldForceLeadingZeros={shouldForceLeadingZeros}
        value={dates}
        {...fieldProps}
        {...props}
      >
        <FieldChildren {...fieldChildrenProps}>
          <PresetInputGroup value={value} />
          <CalendarOverlay className={getFieldClassName('overlay')}>
            <PresetRangeCalendar
              onChange={(value) => {
                if (value) {
                  setValue(value);
                  closeOnSelect();
                }
              }}
              // When the user selects a preset again after choosing custom,
              // close the overlay if shouldCloseOnSelect is true.
              onReselectPreset={closeOnSelect}
              presets={presets}
              value={value}
            />
          </CalendarOverlay>
        </FieldChildren>
      </AriaDateRangePicker>
    );
  },
);
