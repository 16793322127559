import MetricTile, {
  MetricTileContent,
  MetricTileHeader,
  MetricTileValue,
} from 'components/shared/metric-tiles/metric-tile';

import styles from './styles.module.scss';

type Props = {
  isCurrency?: boolean;
  title: string;
  value: number | undefined;
};

const Metric = ({ isCurrency = false, title, value }: Props) => {
  return (
    <MetricTile className={styles.tile} dataTestId={`${title} metric tile`}>
      <MetricTileContent>
        <MetricTileHeader headerClassName={styles.header}>
          {title}
        </MetricTileHeader>
        <MetricTileValue
          isNotAvailable={value === undefined}
          type={isCurrency ? 'currency' : 'number'}
          value={value ? (isCurrency ? value / 100 : value) : 0}
        />
      </MetricTileContent>
    </MetricTile>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default Metric;
