import { AnalyticsBrowser } from '@segment/analytics-next';

import { Shop } from 'types/shops/shop';

type AnalyticsMethods = 'identify' | 'page' | 'track';
type SegmentInterface = Pick<AnalyticsBrowser, AnalyticsMethods>;
type SegmentParameters<T extends AnalyticsMethods> = Parameters<
  SegmentInterface[T]
>;
type SegmentReturnType<T extends AnalyticsMethods> = ReturnType<
  SegmentInterface[T]
>;
type VoidableSegmentReturnType<T extends AnalyticsMethods> =
  SegmentReturnType<T> extends Promise<infer U>
    ? Promise<U | void>
    : SegmentReturnType<T>;

export type AnalyticsParameters<T extends AnalyticsMethods> =
  SegmentParameters<T>;
export type AnalyticsReturnType<T extends AnalyticsMethods> =
  VoidableSegmentReturnType<T>;
export type AnalyticsMethod<T extends AnalyticsMethods> = (
  ...args: AnalyticsParameters<T>
) => AnalyticsReturnType<T>;

export type AnalyticsAdapter = {
  [M in keyof SegmentInterface]: AnalyticsMethod<M>;
};

export enum ClickedExternalLinkType {
  CTA = 'cta',
  LinkedText = 'linked text',
}

export type ClickedExternalLinkData = {
  linkLabel: string;
  page?: string;
  shopId: string;
  type?: ClickedExternalLinkType;
};

export type ClickedInternalFeatureLinkData = {
  copy: string;
  fromPage: string;
  shopId: string | Shop['shopId'];
  toPage: string;
};
