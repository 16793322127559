import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { CalendarDate, today } from '@internationalized/date';

import { RangeValue, useDateFormatter } from 'crust';

import { CreateReportForm } from 'components/reports/create-report-form';
import { RHFDateRangePicker } from 'components/shared/rhf-date-range-picker';
import { RHFRadio, RHFRadioGroup } from 'components/shared/rhf-radio';
import { useCreateSalesSummaryReportMutation } from 'hooks/reports/use-create-sales-summary-report-mutation';
import useAnalytics from 'hooks/use-analytics';
import {
  ReportAnalyticsType,
  SalesSummaryReportFormValues,
} from 'types/financials';
import {
  showInvalidSubmitToast,
  showUnexpectedErrorToast,
} from 'utilities/forms';

type Props = {
  closeForm: () => void;
  shopId: number;
  shopTimezone: string;
};

export const SalesSummaryForm = ({
  closeForm,
  shopId,
  shopTimezone,
}: Props) => {
  const { trackCreateReportClick } = useAnalytics();
  const maxDate = today(shopTimezone);

  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
  } = useForm({
    defaultValues: {
      order_types: 'full',
      // The date picker start blank. There is no CalendarDate value we can
      // provide that will satisfy TS, but also represent a invalid value. The
      // DateRangePicker accepts null, though.
      dates: null as unknown as SalesSummaryReportFormValues['dates'],
    } as SalesSummaryReportFormValues,
    mode: 'onBlur',
  });

  const dateFormatter = useDateFormatter({
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    timeZone: shopTimezone,
  });

  const { mutate: createReport, isLoading: isCreatingReport } =
    useCreateSalesSummaryReportMutation(shopId, shopTimezone);

  const handleValidSubmit = (values: SalesSummaryReportFormValues) => {
    createReport(values, {
      onError: () => {
        showUnexpectedErrorToast();
      },
      onSuccess: (_data, values) => {
        trackCreateReportClick({
          type: ReportAnalyticsType.RegisterSalesSummary,
          startDate: values.dates.start.toString(),
          endDate: values.dates.end.toString(),
        });
        toast.success('Report is being generated. Check back soon!');
        closeForm();
      },
    });
  };

  const validateDates = (dates: RangeValue<CalendarDate>) => {
    if (!dates || !dates.start || !dates.end) {
      return 'Please select start and end dates.';
    }

    if (dates.end.compare(dates.start) < 0) {
      return 'Please select an end date that is after the start date.';
    }

    if (dates.end.compare(maxDate) > 0) {
      const formatted = dateFormatter.format(maxDate.toDate(shopTimezone));
      return `Please select an end date of ${formatted} or earlier.`;
    }

    if (dates.end.compare(dates.start) > 364) {
      return 'Please select a maximum of 365 days.';
    }

    return true;
  };

  return (
    <CreateReportForm
      onCancel={closeForm}
      onSubmit={handleSubmit(handleValidSubmit, showInvalidSubmitToast)}
      isSubmitting={isCreatingReport || isSubmitting}
    >
      <RHFRadioGroup
        control={control}
        isRequired
        label="Order type"
        name="order_types"
        orientation="horizontal"
      >
        <RHFRadio label="All Orders" value="full" />
        <RHFRadio label="Online Orders Only" value="online" />
        <RHFRadio label="Register Orders Only" value="offline" />
      </RHFRadioGroup>
      <RHFDateRangePicker
        control={control}
        name="dates"
        label="Date Range"
        maxValue={maxDate}
        rules={{
          validate: validateDates,
        }}
      />
    </CreateReportForm>
  );
};
