import { useEffect } from 'react';

const useClickAwayHandler = (ref, callback) => {
  useEffect(() => {
    const handleClick = (e) => {
      if (ref.current?.contains(e.target)) {
        return;
      }
      callback();
    };

    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [ref, callback]);
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default useClickAwayHandler;
