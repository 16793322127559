import { useEffect } from 'react';

import useAnalytics from 'hooks/use-analytics';
import {
  CampaignManagerState,
  CampaignManagerSummary,
} from 'types/campaign-manager';
import { User } from 'types/user';

import CampaignInfo from './campaign-info';
import Header from './header';
import StartCampaignTile from './start-campaign-tile';

type Props = {
  shopId: number;
  startDate: string | null;
  summaryData: CampaignManagerSummary;
  user: User;
};

const CampaignManager = ({ shopId, startDate, summaryData, user }: Props) => {
  const { trackViewedCampaignManagerState } = useAnalytics();
  const noStartDate = startDate === null;

  useEffect(() => {
    if (noStartDate) {
      trackViewedCampaignManagerState({
        shopId: String(shopId),
        state: CampaignManagerState.NoCampaignHistory,
      });
    }
  }, [noStartDate, shopId, trackViewedCampaignManagerState]);

  return (
    <>
      <Header />
      {noStartDate ? (
        <StartCampaignTile shopId={String(shopId)} user={user} />
      ) : (
        <CampaignInfo
          shopId={shopId}
          startDate={startDate}
          summaryData={summaryData}
          user={user}
        />
      )}
    </>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default CampaignManager;
