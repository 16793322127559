import {
  ComponentPropsWithoutRef,
  forwardRef,
  ReactElement,
  Ref,
  useRef,
  useState,
} from 'react';
import cx from 'classnames';

import { Icon } from 'crust';

import useClickAwayHandler from 'hooks/use-click-away-handler';
import CaretIcon from 'images/caret.svg?react';

import styles from './styles.module.scss';

type DropdownButtonControls = {
  action: () => void;
  isActive?: boolean;
  name: string;
  OptionIcon?: ReactElement;
};

type DropdownButtonProps = {
  buttonClassName?: string;
  buttonContent: React.ReactNode;
  buttonLabel?: string;
  controls: DropdownButtonControls[];
  hasActiveOptions?: boolean;
  hasCaret?: boolean;
  hasOptionIcon?: boolean;
  onMenuChange?: (open: boolean) => void;
} & ComponentPropsWithoutRef<'button'>;

// The function keyword syntax is used here to avoid having to specify the
// displayName property due to ref forwarding.
export const DropdownButton = forwardRef(function DropdownButton(
  props: DropdownButtonProps,
  ref: Ref<HTMLDivElement>,
) {
  const {
    buttonClassName,
    buttonContent,
    buttonLabel,
    controls,
    hasCaret,
    hasOptionIcon,
    onMenuChange,
    ...rest
  } = props;

  const [isDropDownVisible, setIsDropDownVisible] = useState(false);

  const defaultDropdown = useRef<HTMLDivElement>(null);
  const dropdownRef = ref ?? defaultDropdown;

  useClickAwayHandler(dropdownRef, () => setIsDropDownVisible(false));
  const handleAction = (action: () => void, isOptionActive = false) => {
    !isOptionActive && action();
    setIsDropDownVisible(false);
  };

  const handleClickButton = () => {
    setIsDropDownVisible((current) => {
      onMenuChange?.(!current);
      return !current;
    });
  };

  return (
    <div ref={dropdownRef} className={styles.wrapper}>
      <button
        aria-haspopup="true"
        aria-label={buttonLabel}
        className={cx(styles.button, buttonClassName)}
        onClick={handleClickButton}
        type="button"
        {...rest}
      >
        <div className={styles.buttonContent}>
          <span className={styles.buttonText}>{buttonContent}</span>
          {hasCaret && (
            <CaretIcon
              className={isDropDownVisible ? styles.caretUp : styles.caretDown}
              aria-hidden="true"
            />
          )}
        </div>
      </button>
      {isDropDownVisible && (
        <div
          role="menu"
          aria-orientation="vertical"
          className={styles.dropdown}
        >
          {controls.map(({ name, action, isActive, OptionIcon }) => {
            return (
              <button
                className={styles.dropdownOption}
                key={name}
                onClick={() => handleAction(action, isActive)}
                role="menuitem"
                type="button"
              >
                <div className={styles.optionContentWrapper}>
                  {hasOptionIcon && OptionIcon}
                  {name}
                </div>
                {buttonContent === name && <Icon icon="checkmark" />}
              </button>
            );
          })}
        </div>
      )}
    </div>
  );
});
