import cx from 'classnames';

import { OrderAddress, OrderCustomer } from 'types/register-orders';
import { formatUSPhoneNumber } from 'utilities/strings';

import styles from './styles.module.scss';

type Props = {
  className?: string;
  orderCustomer: OrderCustomer | null;
  orderAddress: OrderAddress | null;
};

const CustomerDetails = ({ className, orderCustomer, orderAddress }: Props) => {
  const customerName = orderCustomer
    ? `${orderCustomer.firstName} ${orderCustomer.lastName}`
    : '';

  const customerPhone = orderCustomer ? orderCustomer.phoneNumber : '';

  return (
    <div className={cx(styles.customerDetails, className)}>
      <div className={styles.details}>
        <div className={styles.detailTitle}>Customer:</div>
        <div className={styles.detailValue}>{customerName}</div>
      </div>

      <div className={styles.details}>
        <div className={styles.detailTitle}>Address:</div>
        {orderAddress && (
          <div className={styles.detailValue}>
            {orderAddress.addressLine1} {orderAddress.addressLine2},{' '}
            {orderAddress.city} {orderAddress.zipcode}
          </div>
        )}
      </div>

      <div className={styles.details}>
        <div className={styles.detailTitle}>Phone:</div>
        <div className={styles.detailValue}>
          {formatUSPhoneNumber(customerPhone)}
        </div>
      </div>
    </div>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default CustomerDetails;
