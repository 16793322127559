import * as React from "react";
import { forwardRef } from "react";
const SvgHeadphones = ({
  title,
  titleId,
  ...props
}, ref) => {
  return /* @__PURE__ */ React.createElement("svg", { width: 36, height: 36, viewBox: "0 0 36 36", fill: "none", xmlns: "http://www.w3.org/2000/svg", ref, "aria-labelledby": titleId, ...props }, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { d: "M4.5 28.8V18C4.5 10.5442 10.5442 4.5 18 4.5C25.4558 4.5 31.5 10.5442 31.5 18V28.8C31.5 30.2912 30.2912 31.5 28.8 31.5H18V28.8L23.4 28.35V23.4C23.4 21.9088 24.6088 20.7 26.1 20.7H28.8V19.35C28.8 13.3853 23.9647 8.55 18 8.55C12.0353 8.55 7.2 13.3853 7.2 19.35V20.7H9.9C11.3912 20.7 12.6 21.9088 12.6 23.4V28.8C12.6 30.2912 11.3912 31.5 9.9 31.5H7.2C5.70883 31.5 4.5 30.2912 4.5 28.8Z", fill: "currentColor" }));
};
const ForwardRef = forwardRef(SvgHeadphones);
export default ForwardRef;
