import PropTypes from 'prop-types';

import { ProductField } from 'utilities/menu';

import ProductCustomizationsTile from './customizations-tile';
import ProductDetailsTile from './details-tile';
import ProductOptionsTile from './options-tile';
import ProductTypesTile from './product-types-tile';
import RegisterPrintingTile from './register-printing-tile';

const ProductForm = ({
  categoriesById,
  categoryOptions,
  children,
  control,
  errors,
  getValues,
  isRosEnabled,
  isKitchenNameEnabled,
  onSubmit,
  productId,
  productsById,
  register,
  setValue,
  shopId,
  submitCount,
}) => {
  return (
    <form onSubmit={onSubmit}>
      <ProductDetailsTile
        categoryError={errors[ProductField.CategoryId]}
        categoriesById={categoriesById}
        categoryOptions={categoryOptions}
        control={control}
        isRosEnabled={isRosEnabled}
        isKitchenNameEnabled={isKitchenNameEnabled}
        kitchenNameError={errors[ProductField.KitchenName]}
        descriptionError={errors[ProductField.Description]}
        getValues={getValues}
        nameError={errors[ProductField.Name]}
        productId={productId}
        productsById={productsById}
        register={register}
        setValue={setValue}
        shopId={shopId}
        submitCount={submitCount}
      />
      <ProductOptionsTile
        control={control}
        isRosEnabled={isRosEnabled}
        register={register}
        setValue={setValue}
      />
      {isRosEnabled && (
        <RegisterPrintingTile
          control={control}
          register={register}
          setValue={setValue}
        />
      )}
      <ProductTypesTile
        control={control}
        errors={errors[ProductField.ProductTypes]}
        getValues={getValues}
        productId={productId}
        register={register}
        setValue={setValue}
        shopId={shopId}
        submitCount={submitCount}
      />
      <ProductCustomizationsTile
        categoriesById={categoriesById}
        categoryOptions={categoryOptions}
        control={control}
        errors={errors[ProductField.Customizations]}
        getValues={getValues}
        productId={productId}
        productsById={productsById}
        register={register}
        setValue={setValue}
        shopId={shopId}
        submitCount={submitCount}
      />
      {children}
    </form>
  );
};

ProductForm.propTypes = {
  categoriesById: PropTypes.object.isRequired,
  categoryOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      length: PropTypes.number.isRequired,
      value: PropTypes.number.isRequired,
    }),
  ).isRequired,
  children: PropTypes.node.isRequired,
  control: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  getValues: PropTypes.func.isRequired,
  isRosEnabled: PropTypes.bool.isRequired,
  isKitchenNameEnabled: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  productId: PropTypes.string.isRequired,
  productsById: PropTypes.object.isRequired,
  register: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  shopId: PropTypes.string.isRequired,
  submitCount: PropTypes.number.isRequired,
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default ProductForm;
