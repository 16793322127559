import { Control, useWatch } from 'react-hook-form';

import { PromoCodeFormValues } from 'types/discounts';
import { PromoCodeChannelLabels } from 'utilities/discounts';

type Props = {
  control: Control<PromoCodeFormValues>;
};

export const ChannelSummary = ({ control }: Props) => {
  const channels = useWatch({
    control,
    name: 'channels',
  });

  return (
    <>{channels.map((it) => PromoCodeChannelLabels[it]).join(', ') || null}</>
  );
};
