import * as React from "react";
import { forwardRef } from "react";
const SvgFileType = ({
  title,
  titleId,
  ...props
}, ref) => {
  return /* @__PURE__ */ React.createElement("svg", { width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", ref, "aria-labelledby": titleId, ...props }, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { d: "M21 6V18C21 18.5523 20.5523 19 20 19H4C3.44772 19 3 18.5523 3 18V6C3 5.44772 3.44772 5 4 5H20C20.5523 5 21 5.44772 21 6Z", stroke: "#211E1E", strokeWidth: 2 }), /* @__PURE__ */ React.createElement("path", { d: "M5 16L10.6 9L14 13.4545L15.5 11.5455L19 16H12H5Z", fill: "#181218", fillOpacity: 0.6 }));
};
const ForwardRef = forwardRef(SvgFileType);
export default ForwardRef;
