import ConsumerMenuPageHeader from 'components/menu/consumer-page-header';
import MenuLayout from 'components/menu/layout';
import Page from 'components/shared/page';
import { useCurrentShopId } from 'hooks/shops';
import { viewMyMenuOnlineLinkLocations } from 'utilities/constants';

const MenuLayoutPage = () => {
  const shopId = useCurrentShopId();

  return (
    <Page
      name="sort-consumer-menu"
      customPageHeader={
        <ConsumerMenuPageHeader
          shopId={String(shopId)}
          viewMenuOnlineLinkLocation={
            viewMyMenuOnlineLinkLocations.menuLayoutPage
          }
          title="Online Menu Layout"
        />
      }
      isHeaderShown
    >
      <MenuLayout shopId={String(shopId)} />
    </Page>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default MenuLayoutPage;
