import cx from 'classnames';

import ContentTile from 'components/shared/content-tile';
import Suspended from 'components/shared/suspended';
import { OrderStatus, OrderType } from 'types/shop-hours';

import ShopOpeningIndicator from './shop-opening-indicator';

import styles from './styles.module.scss';

type ShippingTypeStatus = {
  isRegularScheduleOpen: boolean;
  nextOpen: string;
  status: OrderStatus;
};

type PauseDetails = {
  endTime?: string;
};

export type ShopOpeningStatus = {
  delivery: ShippingTypeStatus;
  deliveryAndPickup: ShippingTypeStatus;
  pauseDetails: PauseDetails;
  pickup: ShippingTypeStatus;
};

export type ShopOpeningStatusTileProps = {
  areOptionsDisabled?: boolean;
  className?: string;
  isLoading?: boolean;
  onClickClose: () => void;
  onClickExtend: () => void;
  onClickOpen: (isOpeningFromPaused: boolean) => void;
  onClickPause: () => void;
  shopOpeningStatus: ShopOpeningStatus;
  shopId: string;
  shopTimezone: string;
};

const ShopOpeningStatusTile = ({
  areOptionsDisabled = false,
  className,
  isLoading = false,
  onClickClose,
  onClickExtend,
  onClickOpen,
  onClickPause,
  shopOpeningStatus,
  shopId,
  shopTimezone,
}: ShopOpeningStatusTileProps) => {
  const shouldShowDeliveryAndPickupTile =
    !shopOpeningStatus.deliveryAndPickup.isRegularScheduleOpen ||
    shopOpeningStatus.delivery.status === shopOpeningStatus.pickup.status;

  const indicatorSharedProps = {
    areOptionsDisabled: areOptionsDisabled,
    className: styles.indicatorContainer,
    onClickClose,
    onClickExtend,
    onClickOpen,
    onClickPause,
    pauseEndTime: shopOpeningStatus.pauseDetails.endTime,
    shopId,
    shopTimezone,
  };

  return (
    <ContentTile
      className={cx(
        !shouldShowDeliveryAndPickupTile && styles.tileSplitShippingType,
        className,
      )}
    >
      <div className={styles.header}>Shop Status</div>
      <div className={styles.content} data-chameleon-target="Pause Content">
        <Suspended className={styles.loadingContainer} isLoading={isLoading}>
          <>
            {shouldShowDeliveryAndPickupTile && (
              <ShopOpeningIndicator
                {...indicatorSharedProps}
                isRegularScheduleOpen={
                  shopOpeningStatus.deliveryAndPickup.isRegularScheduleOpen
                }
                nextOpenAtDate={shopOpeningStatus.deliveryAndPickup.nextOpen}
                orderStatus={shopOpeningStatus.deliveryAndPickup.status}
                orderType={OrderType.DeliveryAndPickup}
              />
            )}
            {!shouldShowDeliveryAndPickupTile && (
              <>
                <ShopOpeningIndicator
                  {...indicatorSharedProps}
                  isRegularScheduleOpen={
                    shopOpeningStatus.deliveryAndPickup.isRegularScheduleOpen &&
                    shopOpeningStatus.delivery.isRegularScheduleOpen
                  }
                  nextOpenAtDate={shopOpeningStatus.delivery.nextOpen}
                  orderStatus={shopOpeningStatus.delivery.status}
                  orderType={OrderType.Delivery}
                />
                <ShopOpeningIndicator
                  {...indicatorSharedProps}
                  isRegularScheduleOpen={
                    shopOpeningStatus.deliveryAndPickup.isRegularScheduleOpen &&
                    shopOpeningStatus.pickup.isRegularScheduleOpen
                  }
                  nextOpenAtDate={shopOpeningStatus.pickup.nextOpen}
                  orderStatus={shopOpeningStatus.pickup.status}
                  orderType={OrderType.Pickup}
                />
              </>
            )}
          </>
        </Suspended>
      </div>
    </ContentTile>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default ShopOpeningStatusTile;
