import { QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { RegisterDiscount } from 'types/discounts';
import { camelCaseKeys } from 'utilities/objects';

type UseRegisterDiscountsQueryOptions = Omit<
  UseQueryOptions<RegisterDiscount[]>,
  'queryFn' | 'queryKey'
>;

export const getRegisterDiscountsQueryKey = (shopId: number): QueryKey => [
  shopId,
  'discounts',
];

export const useRegisterDiscountsQuery = (
  shopId: number,
  options?: UseRegisterDiscountsQueryOptions,
) => {
  const { authenticatedFetch } = useApi();

  return useQuery({
    queryKey: getRegisterDiscountsQueryKey(shopId),
    queryFn: async () => {
      const response = await authenticatedFetch.get(
        `api/management/v1/ros/shops/${shopId}/discounts`,
      );

      return camelCaseKeys(response) as RegisterDiscount[];
    },
    ...options,
  });
};
