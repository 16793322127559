import moment from 'moment-timezone';

import { upsertBy } from 'utilities/lists';

export const buildChartData = (dailyOrderData) => {
  const { offlineData, data: onlineData } = dailyOrderData;
  const offlineDataCopy = offlineData;

  //  Format existing onlineData summaries to match offline summaries' structure
  const reducedOnlineData = onlineData?.reduce(
    (acc, { start, cash, credit }) => {
      // find offline summaries with matching dates
      const existingSummary =
        offlineData.find((offlineSummary) => offlineSummary.start === start) ||
        null;

      const onlineOrderCount = cash.count + credit.count;
      // combine data for online and offline summaries on the same date
      return upsertBy(
        acc,
        {
          count: existingSummary
            ? existingSummary.count + onlineOrderCount
            : onlineOrderCount,
          start,
        },
        'start',
      );
    },
    offlineDataCopy,
  );

  return reducedOnlineData
    ?.map((summary) => ({
      x: moment(summary.start).format('YYYY-MM-DD'),
      y: summary.count,
    }))
    .sort((a, b) => moment(a.x).diff(moment(b.x), 'days'));
};
