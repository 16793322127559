import { toast } from 'react-toastify';

import ContentTile from 'components/shared/content-tile';
import Link from 'components/shared/link';
import Button, { ButtonVariant } from 'components/shared/slice-button';
import { useCreateSalesforceCaseMutation } from 'hooks/salesforce/use-create-salesforce-case';
import { useShopTraitsContext } from 'hooks/shops/use-shop-traits-context';
import useAnalytics from 'hooks/use-analytics';
import useFeatureFlag from 'hooks/use-feature-flag';
import campaignAd from 'images/campaign-manager/campaign-manager-ad.gif';
import { marketingServices as marketingServicesUrl } from 'routes/external-urls';
import { OptimizelyFeatureFlag } from 'types/optimizely-feature-flag';
import {
  SalesforceCaseType,
  SalesforceNote,
  SalesforceSubject,
} from 'types/salesforce';
import { ClickedExternalLinkType } from 'types/shared/analytics';
import { User } from 'types/user';

import BenefitItem from './benefit-item';

import styles from './styles.module.scss';

type Props = {
  shopId: string;
  user: User;
};

const StartCampaignTile = ({ shopId, user }: Props) => {
  const { trackProductUpsellLinkClick } = useAnalytics();
  const shopTraits = useShopTraitsContext();

  const { isEnabled: isUpsellAMSEnabled } = useFeatureFlag(
    OptimizelyFeatureFlag.ShouldUpsellAMS,
  );

  const {
    mutate: createSalesforceCase,
    isLoading: isCreatingSalesforceCaseLoading,
  } = useCreateSalesforceCaseMutation();

  const handleOnGetStartedClick = () => {
    if (!isCreatingSalesforceCaseLoading) {
      createSalesforceCase(
        {
          salesforceCase: {
            caseType: SalesforceCaseType.AMS,
            subject: SalesforceSubject.AMS,
          },
          note: SalesforceNote.AdvancedMarketingServicesNote,
          shopId: Number(shopId),
          user: user,
        },
        {
          onError: () =>
            toast.error(
              `We weren't able to process your request. Please contact your Partner Success Manager.`,
            ),
          onSuccess: () =>
            toast.success(
              'Great! We received your request. Our team will be in touch shortly.',
            ),
        },
      );
    }

    trackProductUpsellLinkClick({
      shopId: shopId,
      product: 'advanced marketing services',
      page: 'campaign manager no campaign history',
      linkLabel: 'get started',
      type: ClickedExternalLinkType.CTA,
      outcome: 'lead',
      shopIntegrationAdoption: shopTraits.shopIntegrationAdoption,
    });
  };

  const handleOnLearnMoreClick = () => {
    trackProductUpsellLinkClick({
      shopId: shopId,
      product: 'advanced marketing services',
      page: 'campaign manager no campaign history',
      linkLabel: 'learn more',
      type: ClickedExternalLinkType.CTA,
      outcome: 'information',
      shopIntegrationAdoption: shopTraits.shopIntegrationAdoption,
    });
  };

  return (
    <ContentTile className={styles.noCampaignTile}>
      <img className={styles.adImage} alt="Campaign Manage" src={campaignAd} />
      <div className={styles.container}>
        <div className={styles.title}>Let Slice Manage Your Campaign</div>
        <div className={styles.listTitle}>What We Do For You:</div>
        <ul className={styles.list}>
          <BenefitItem description="Run ads on Facebook, Instagram, Google, and Bing." />
          <BenefitItem description="Target new and existing customers in your delivery area." />
          <BenefitItem description="Track performance and optimizes for the best results." />
          <BenefitItem description="Generate daily reports on sales, ad views, new customers, and more." />
        </ul>
        <div className={styles.buttonWrapper}>
          {isUpsellAMSEnabled && (
            <>
              <Button
                className={styles.getStartedButton}
                variant={ButtonVariant.Transparent}
                onClick={() => handleOnGetStartedClick()}
              >
                <span>Get Started</span>
              </Button>
              <Link
                className={styles.learnMoreLink}
                to={marketingServicesUrl}
                variant="undecorated"
                onClick={() => handleOnLearnMoreClick()}
              >
                Learn More
              </Link>
            </>
          )}
        </div>
      </div>
    </ContentTile>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default StartCampaignTile;
