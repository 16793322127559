import CopyableCodeBlock from 'components/shared/copyable-code-block';
import useAnalytics from 'hooks/use-analytics';

import styles from './styles.module.scss';

type Props = {
  customLink: string;
  shopId: number;
};

const GetCustomLink = ({ customLink, shopId }: Props) => {
  const { trackClickedCopyCustomLink } = useAnalytics();

  const handleCopyLinkClick = () => {
    trackClickedCopyCustomLink({ shopId: String(shopId) });
  };

  return (
    <>
      <div className={styles.tabSectionHeader}>
        <h3>
          {"Want Slice-powered ordering but don't need a button? No problem."}
        </h3>
        <div>
          {
            "This URL links directly to your shop's menu — connect it to your website's ordering button (or anywhere else you'd like to enable ordering) and you're all set."
          }
        </div>
      </div>
      <CopyableCodeBlock
        className={styles.codeBlock}
        copyButtonText="Copy Link"
        code={customLink}
        onCopyClick={handleCopyLinkClick}
      />
    </>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default GetCustomLink;
