import * as React from "react";
import { forwardRef } from "react";
const SvgCompletedCheckmark = ({
  title,
  titleId,
  ...props
}, ref) => {
  return /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", fill: "none", viewBox: "0 0 32 31", ref, "aria-labelledby": titleId, ...props }, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("circle", { cx: 15, cy: 16, r: 14, fill: "#6EC7A5", fillOpacity: 0.54, stroke: "currentColor", strokeWidth: 2 }), /* @__PURE__ */ React.createElement("path", { stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: 2, d: "M31 1L13.4712 21 5 13.9032" }));
};
const ForwardRef = forwardRef(SvgCompletedCheckmark);
export default ForwardRef;
