import CopyableCodeBlock from 'components/shared/copyable-code-block';
import Link from 'components/shared/link';
import Modal from 'components/shared/slice-modal';
import { useShopTraitsContext } from 'hooks/shops/use-shop-traits-context';
import useAnalytics from 'hooks/use-analytics';
import * as externalUrls from 'routes/external-urls';
import { Shop } from 'types/shops';
import { WebsiteIntegrationsAnalyticsPageName } from 'types/website-tools';
import { getWebsiteIntegrationsScriptCode } from 'utilities/website-tools';

import styles from './styles.module.scss';

type Props = {
  isModalOpen: boolean;
  setIsModalOpen: (isModalOpen: boolean) => void;
  shopCity: Shop['city'];
  shopId: number;
  shopName: Shop['name'];
  shopUuid: string;
};

const GetCodeModal = ({
  isModalOpen,
  setIsModalOpen,
  shopCity,
  shopId,
  shopName,
  shopUuid,
}: Props) => {
  const {
    trackClickedCopyWebsiteIntegrationsCodeButton,
    trackClickedWebsiteIntegrationsBestPracticesLink,
  } = useAnalytics();
  const shopTraits = useShopTraitsContext();

  const handleCopyClick = () => {
    trackClickedCopyWebsiteIntegrationsCodeButton({
      shopId: String(shopId),
      page: WebsiteIntegrationsAnalyticsPageName.SmartPopups,
      shopIntegrationAdoption: shopTraits.shopIntegrationAdoption,
    });
  };

  const handleReadMoreClick = () => {
    trackClickedWebsiteIntegrationsBestPracticesLink({
      shopId: String(shopId),
      content: 'instructions',
      page: WebsiteIntegrationsAnalyticsPageName.SmartPopups,
    });
  };

  return (
    <Modal
      className={styles.modal}
      header="Code and documentation"
      isOpen={isModalOpen}
      onRequestClose={() => setIsModalOpen(false)}
    >
      <p className={styles.helpText}>
        {
          'To embed your popup, copy and paste this code inside the <head> tag of the homepage of your website and publish.'
        }{' '}
        <span className={styles.helpTextNote}>
          Note: this only needs to be done once.
        </span>
      </p>
      <CopyableCodeBlock
        className={styles.codeBlock}
        code={getWebsiteIntegrationsScriptCode(shopCity, shopName, shopUuid)}
        copyButtonText="Copy Code"
        onCopyClick={handleCopyClick}
      />
      <div className={styles.additionalHelpHeader}>
        Need additional setup help?
      </div>
      <p className={styles.helpText}>
        Get step-by-step instructions including sites like WordPress,
        Squarespace, Godaddy, Wix, Weebly, Duda, Bentobox, Popmenu, and Square.{' '}
        <Link
          className={styles.readMore}
          to={externalUrls.websiteIntegrationsInstructions}
          onClick={handleReadMoreClick}
        >
          Read more →
        </Link>
      </p>
    </Modal>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default GetCodeModal;
