import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useMutation } from '@tanstack/react-query';
import PropTypes from 'prop-types';

import { IconButton } from 'crust';

import ClosingForm from 'components/hours/closings/closing-form';
import ContentTile from 'components/shared/content-tile';
import { useClosings } from 'hooks/hours';
import useAnalytics from 'hooks/use-analytics';
import useApi from 'hooks/use-api';
import { formatClosingForApi, formatClosingForTable } from 'utilities/closings';
import { newClosingDefaultValues } from 'utilities/constants';
import { showInvalidSubmitToast } from 'utilities/forms';
import { insert } from 'utilities/lists';

import styles from './styles.module.scss';

const CreateClosingTile = ({ closeTile, shopId, shopTimezone }) => {
  const api = useApi();

  const { trackAddClosing } = useAnalytics();

  const { invalidateOpenForTodayQueries, setClosingsQueryData } =
    useClosings(shopId);

  const {
    control,
    formState: { isSubmitting, errors },
    handleSubmit,
    setError,
  } = useForm({ defaultValues: newClosingDefaultValues, mode: 'onChange' });

  const insertIntoClosingsQueryData = (createClosingResponse) =>
    setClosingsQueryData((cachedClosingsData = []) =>
      insert(cachedClosingsData, formatClosingForTable(createClosingResponse)),
    );

  const { mutate: saveClosing } = useMutation(
    (values) => api.createClosing(shopId, formatClosingForApi(values)),
    {
      onSuccess: (response) => {
        insertIntoClosingsQueryData(response);

        // We invalidate the query to refetch the 'open_for_today' values. The backend calculates values needed
        // such as next_open_at which may be impacted by closing updates
        invalidateOpenForTodayQueries();
      },
    },
  );

  const handleRequestError = (responseBody, setError) => {
    if (
      responseBody?.status === 422 &&
      responseBody?.messages.some((it) =>
        it.includes('lie within another Closing'),
      )
    ) {
      setError('dates', {
        type: 'manual',
        message: 'The dates cannot overlap with an existing closing.',
      });
      showInvalidSubmitToast();
    } else {
      toast.error('An unexpected error occurred. Please try again later.');
    }
  };

  const handleValidSubmit = (values) => {
    saveClosing(values, {
      onSuccess: (response) => {
        trackAddClosing(shopId, response, true);
        closeTile();
      },
      onError: (error) => {
        handleRequestError(error.responseBody, setError);
      },
    });
  };

  return (
    <ContentTile>
      <div className={styles.addCardHeader}>
        <span className={styles.addCardTitle}>Add Closing</span>
        <IconButton
          aria-label="Hide the new closing form"
          icon="close"
          onPress={closeTile}
        />
      </div>
      <ClosingForm
        action="add"
        control={control}
        errors={errors}
        isSubmitting={isSubmitting}
        onClickCancel={closeTile}
        onSubmit={handleSubmit(handleValidSubmit, showInvalidSubmitToast)}
        shopTimezone={shopTimezone}
      />
    </ContentTile>
  );
};

CreateClosingTile.propTypes = {
  closeTile: PropTypes.func.isRequired,
  shopId: PropTypes.string.isRequired,
  shopTimezone: PropTypes.string.isRequired,
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default CreateClosingTile;
