import { useMemo } from 'react';

import { Link } from 'crust';

import Callout from 'components/shared/callout';
import useAnalytics from 'hooks/use-analytics';
import DealCard from 'images/callout/deal-card.png';
import { shopSmartButtons } from 'routes/paths/website-tools';
import { CalloutEventType } from 'types/callout';
import { Shop } from 'types/shops';

type Props = {
  hasCustomWebsite: boolean;
  hasOwnWebsite: boolean;
  hasSmartButton: boolean;
  isOnlineOrdering: boolean;
  isRegisterShop: boolean;
  shopId: Shop['shopId'];
};

const SmartButtonCallout = ({
  hasCustomWebsite,
  hasOwnWebsite,
  hasSmartButton,
  isOnlineOrdering,
  isRegisterShop,
  shopId,
}: Props) => {
  const { trackClickedCalloutAction } = useAnalytics();

  const handleSetUpButtonClick = () => {
    trackClickedCalloutAction({
      '1stLinkLabel': 'Set Up Button',
      '2ndLinkLabel': null,
      actionClicked: 'Set Up Button',
      hasCustomWebsite: hasCustomWebsite,
      hasOwnWebsite: hasOwnWebsite,
      hasSmartButton: hasSmartButton,
      isOnlineOrdering: isOnlineOrdering,
      isRegister: isRegisterShop,
      page: 'homepage',
      shopId: String(shopId),
      type: 'smart_button_callout',
    });
  };

  const analyticEvent: CalloutEventType = useMemo(
    () => ({
      '1stLinkLabel': 'Set Up Button',
      '2ndLinkLabel': null,
      hasCustomWebsite: hasCustomWebsite,
      hasOwnWebsite: hasOwnWebsite,
      hasSmartButton: hasSmartButton,
      isOnlineOrdering: isOnlineOrdering,
      isRegister: isRegisterShop,
      page: 'homepage',
      shopId: String(shopId),
      type: 'smart_button_callout',
    }),
    [
      hasCustomWebsite,
      hasOwnWebsite,
      hasSmartButton,
      isOnlineOrdering,
      isRegisterShop,
      shopId,
    ],
  );

  return (
    <Callout
      event={analyticEvent}
      image={DealCard}
      primary={
        <Link href={shopSmartButtons(shopId)} onPress={handleSetUpButtonClick}>
          Set Up Button
        </Link>
      }
      text={'Turn your website traffic into orders with a Smart Button.'}
    />
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default SmartButtonCallout;
