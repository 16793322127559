import * as React from "react";
import { forwardRef } from "react";
const SvgPlusIcon = ({
  title,
  titleId,
  ...props
}, ref) => {
  return /* @__PURE__ */ React.createElement("svg", { width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", "aria-hidden": "true", ref, "aria-labelledby": titleId, ...props }, title === void 0 ? /* @__PURE__ */ React.createElement("title", { id: titleId }, "Plus Icon") : title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { d: "M10.3125 13.6875L10.875 21H13.125L13.6875 13.6875L21 13.125V10.875L13.6875 10.3125L13.125 3H10.875L10.3125 10.3125L3 10.875V13.125L10.3125 13.6875Z", fill: "currentColor" }));
};
const ForwardRef = forwardRef(SvgPlusIcon);
export default ForwardRef;
