import { useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { IconButton } from 'crust';

import FormFeedback from 'components/shared/form-feedback';
import Input from 'components/shared/input';
import useValidationErrorTracking, {
  ValidationErrorLocation,
  ValidationInputId,
} from 'hooks/use-validation-error-tracking.ts';
import {
  AnalyticsField,
  CustomizationField,
  getCustomizationFieldName,
  getSelectionFieldName,
  SelectionField,
} from 'utilities/menu';

import DeleteSelectionModal from './delete-selection-modal';
import SelectionMeta from './selection-meta';
import SelectionOffers from './selection-offers';

import styles from './styles.module.scss';

const getPriceError = (errors) => errors?.find((it) => it?.price)?.price;

const Selection = ({
  control,
  customizationIndex,
  errors,
  getValues,
  isByHalf,
  isByType,
  length,
  register,
  removeField,
  selectionIndex,
  setValue,
  shopId,
  submitCount,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const validateNameInput = (value) => {
    const selections = getValues(
      getCustomizationFieldName(
        CustomizationField.Selections,
        customizationIndex,
      ),
    );

    if (selections.some((s, i) => i !== selectionIndex && s.name === value)) {
      return 'Names must be unique.';
    }

    return true;
  };

  const halfPriceError = getPriceError(errors?.halfOffers);
  const wholePriceError = getPriceError(errors?.wholeOffers);

  useValidationErrorTracking(
    shopId,
    ValidationErrorLocation.MenuProductEditPage,
    ValidationInputId.ProductCustomizationSelectionName,
    errors?.[SelectionField.Name],
    submitCount,
  );

  useValidationErrorTracking(
    shopId,
    ValidationErrorLocation.MenuProductEditPage,
    ValidationInputId.ProductCustomizationSelectionWholePrice,
    wholePriceError,
    submitCount,
  );

  useValidationErrorTracking(
    shopId,
    ValidationErrorLocation.MenuProductEditPage,
    ValidationInputId.ProductCustomizationSelectionHalfPrice,
    halfPriceError,
    submitCount,
    isByHalf,
  );

  return (
    <>
      <SelectionMeta
        control={control}
        customizationIndex={customizationIndex}
        selectionIndex={selectionIndex}
        register={register}
      />
      <Input
        aria-label="Selection Name"
        aria-required="true"
        className={styles.name}
        id={`selection-${customizationIndex}-${selectionIndex}-name-input`}
        isInvalid={errors?.[SelectionField.Name] != null}
        {...register(
          getSelectionFieldName(
            SelectionField.Name,
            customizationIndex,
            selectionIndex,
          ),
          {
            deps: [...Array(length).keys()]
              .filter((it) => it !== selectionIndex)
              .map((it) =>
                getSelectionFieldName(
                  SelectionField.Name,
                  customizationIndex,
                  it,
                ),
              ),
            maxLength: {
              message: 'Please enter no more than 500 characters.',
              value: 500,
            },
            onChange: () => setValue(AnalyticsField.SelectionNameChanged, true),
            required: 'Please enter a name.',
            validate: validateNameInput,
          },
        )}
      />
      <SelectionOffers
        control={control}
        customizationIndex={customizationIndex}
        isByType={isByType}
        register={register}
        selectionIndex={selectionIndex}
        setValue={setValue}
      />
      {isByHalf && (
        <SelectionOffers
          control={control}
          customizationIndex={customizationIndex}
          isByType={isByType}
          isHalf
          register={register}
          selectionIndex={selectionIndex}
          setValue={setValue}
        />
      )}
      {removeField && (
        <>
          <IconButton
            aria-label="Delete selection"
            className={styles.delete}
            icon="trashCan"
            onPress={isByType ? () => setIsModalOpen(true) : removeField}
          />
          <DeleteSelectionModal
            control={control}
            customizationIndex={customizationIndex}
            index={selectionIndex}
            isOpen={isModalOpen}
            removeField={removeField}
            setIsOpen={setIsModalOpen}
            setValue={setValue}
          />
        </>
      )}
      <FormFeedback className={cx(styles.name, styles.feedback)}>
        {errors?.[SelectionField.Name]?.message}
      </FormFeedback>
      <FormFeedback className={cx(styles.whole, styles.feedback)}>
        {wholePriceError?.message}
      </FormFeedback>
      <FormFeedback className={cx(styles.half, styles.feedback)}>
        {halfPriceError?.message}
      </FormFeedback>
    </>
  );
};

Selection.propTypes = {
  control: PropTypes.object.isRequired,
  customizationIndex: PropTypes.number.isRequired,
  errors: PropTypes.object,
  getValues: PropTypes.func.isRequired,
  isByHalf: PropTypes.bool.isRequired,
  isByType: PropTypes.bool.isRequired,
  length: PropTypes.number.isRequired,
  register: PropTypes.func.isRequired,
  removeField: PropTypes.func,
  selectionIndex: PropTypes.number.isRequired,
  setValue: PropTypes.func.isRequired,
  shopId: PropTypes.string.isRequired,
  submitCount: PropTypes.number.isRequired,
};

Selection.defaultProps = {
  errors: null,
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default Selection;
