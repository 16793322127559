import { Link } from 'crust';

import useAnalytics from 'hooks/use-analytics';
import { getPsmEmailInfo } from 'utilities/psm-info';

import styles from './styles.module.scss';

type Props = {
  shopId: string;
  hasPartnerName: boolean;
  partnerName: string;
};

const PsmInfoSubDrawer = ({ shopId, hasPartnerName, partnerName }: Props) => {
  const { trackClickedEmailLink, trackClickedCallLink } = useAnalytics();

  const { subject, body } = getPsmEmailInfo(shopId);

  const handleEmailLinkClick = (): void => {
    trackClickedEmailLink({
      shopId,
      destination: 'partnersuccess@slicelife.com',
      // For the analytics event, we want "general inquiry", rather than the
      // specific subject line.
      subject: 'general inquiry',
      page: 'navigation psm info',
      psmName: partnerName,
    });
  };

  const handleCallLinkClick = (): void => {
    trackClickedCallLink({
      shopId,
      page: 'navigation psm info',
      psmName: partnerName,
      phoneNumber: '(855) 237-4992',
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.drawerLabel}>
        {hasPartnerName ? 'Partner Success Manager' : 'How can we help?'}
      </div>
      <div className={styles.partnerText}>
        {hasPartnerName ? (
          partnerName
        ) : (
          <>
            <div>Connect with your</div>
            <div>Partner Success Manager</div>
          </>
        )}
      </div>
      <div className={styles.instructionsText}>
        Questions about how to get the most out of Slice? Issues with products?{' '}
        {"We're"} here to help you and your shop.
      </div>
      <Link
        icon="phone"
        href="tel:8552374992"
        onPress={handleCallLinkClick}
        variant="secondary"
      >
        855-237-4992
      </Link>
      <Link
        className={styles.truncate}
        icon="website"
        href={`mailto:partnersuccess@slicelife.com?subject=${subject}&body=${body}`}
        onPress={handleEmailLinkClick}
        variant="secondary"
      >
        partnersuccess@slicelife.com
      </Link>
    </div>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default PsmInfoSubDrawer;
