import { cloneElement, ReactElement } from 'react';
import { createPortal } from 'react-dom';
import { DragOverlay } from '@dnd-kit/core';

import styles from './styles.module.scss';

type Props = {
  children: ReactElement | null;
  withPortal?: boolean;
};

// The extra div resolves some odd animation issues.
const SortableOverlay = ({ children, withPortal }: Props) => {
  const overlay = (
    <DragOverlay>
      {children ? (
        <div className={styles.overlay}>
          {cloneElement(children, { isOverlay: true })}
        </div>
      ) : null}
    </DragOverlay>
  );

  if (withPortal) {
    return createPortal(overlay, document.body);
  }

  return overlay;
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default SortableOverlay;
