import ContentTile from 'components/shared/content-tile';
import MetricTile, {
  MetricTileContent,
  MetricTileHeader,
  MetricTileTitle,
  MetricTileValue,
} from 'components/shared/metric-tiles/metric-tile';
import Suspended from 'components/shared/suspended';
import { TooltipIconButton } from 'components/shared/tooltips';
import { useShopPublishersQuery } from 'hooks/brand-manager';
import { Shop } from 'types/shops';

import styles from './styles.module.scss';

type Props = {
  shopId: Shop['shopId'];
};

export const Results = ({ shopId }: Props) => {
  const { data, isLoading } = useShopPublishersQuery(shopId);

  let isDataUndefined,
    orders = 0,
    revenue = 0;

  if (data === undefined) {
    isDataUndefined = true;
  } else {
    orders = data.all.last28Days.orders.total;
    revenue = data.all.last28Days.revenue.total;
  }

  return (
    <ContentTile>
      <div className={styles.headerTile}>
        <div className={styles.header}>
          {'Google Business Profile Results'}
          <TooltipIconButton
            label="More information about Google business profile results"
            buttonClassName={styles.tooltip}
          >
            Slice orders that came from Google in the last 28 days.
          </TooltipIconButton>
        </div>
        <p className={styles.dateSelect}> Last 28 Days</p>
      </div>
      <div className={styles.metrics}>
        <MetricTile className={styles.metricTile}>
          <Suspended isLoading={isLoading}>
            <MetricTileContent>
              <MetricTileHeader>
                <MetricTileTitle>Total Sales</MetricTileTitle>
              </MetricTileHeader>
              <MetricTileValue
                hideDecimalsAboveValue={1}
                isNotAvailable={isDataUndefined}
                type="currency"
                value={revenue}
              />
            </MetricTileContent>
          </Suspended>
        </MetricTile>
        <MetricTile className={styles.metricTile}>
          <Suspended isLoading={isLoading}>
            <MetricTileContent>
              <MetricTileHeader>
                <MetricTileTitle>Total Orders</MetricTileTitle>
              </MetricTileHeader>
              <MetricTileValue
                isNotAvailable={isDataUndefined}
                value={orders}
              />
            </MetricTileContent>
          </Suspended>
        </MetricTile>
      </div>
    </ContentTile>
  );
};
