import * as React from "react";
import { forwardRef } from "react";
import { useId } from "react-aria";
const SvgTwitterGrey = ({
  title,
  titleId,
  ...props
}, ref) => {
  const id0 = useId();
  return /* @__PURE__ */ React.createElement("svg", { width: 16, height: 16, viewBox: "0 0 16 16", fill: "none", xmlns: "http://www.w3.org/2000/svg", ref, "aria-labelledby": titleId, ...props }, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("g", { clipPath: `url(#${id0})` }, /* @__PURE__ */ React.createElement("path", { d: "M16 3.03799C15.4113 3.29932 14.7787 3.47532 14.1147 3.55466C14.7927 3.14866 15.3133 2.50532 15.558 1.73866C14.924 2.11466 14.2213 2.38799 13.4733 2.53532C12.8753 1.89732 12.0213 1.49866 11.0773 1.49866C8.958 1.49866 7.40067 3.47599 7.87933 5.52866C5.152 5.39199 2.73333 4.08532 1.114 2.09932C0.254 3.57466 0.668 5.50466 2.12933 6.48199C1.592 6.46466 1.08533 6.31732 0.643333 6.07132C0.607333 7.59199 1.69733 9.01466 3.276 9.33132C2.814 9.45666 2.308 9.48599 1.79333 9.38732C2.21067 10.6913 3.42267 11.64 4.86 11.6667C3.48 12.7487 1.74133 13.232 0 13.0267C1.45267 13.958 3.17867 14.5013 5.032 14.5013C11.1267 14.5013 14.57 9.35399 14.362 4.73732C15.0033 4.27399 15.56 3.69599 16 3.03799Z", fill: "#525150" })), /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("clipPath", { id: id0 }, /* @__PURE__ */ React.createElement("rect", { width: 16, height: 16, fill: "white" }))));
};
const ForwardRef = forwardRef(SvgTwitterGrey);
export default ForwardRef;
