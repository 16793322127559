import PropTypes from 'prop-types';

import { Checkbox } from 'crust';

import CustomizationSummary from '../../customization-summary';
import CustomizationTitle from '../../customization-title';

import styles from './styles.module.scss';

const Customization = ({ customization, isChecked, onChange }) => {
  const { isRequired, limit, name, selections } = customization;

  return (
    <>
      <div className={styles.tile}>
        <div className={styles.contentTitle}>
          <CustomizationTitle name={name} selections={selections} />
        </div>
        <div className={styles.contentSummary}>
          <CustomizationSummary isRequired={isRequired} limit={limit} />
        </div>
        <Checkbox
          aria-label={name}
          isSelected={isChecked}
          onChange={onChange}
        />
      </div>
    </>
  );
};

Customization.propTypes = {
  customization: PropTypes.shape({
    isRequired: PropTypes.bool.isRequired,
    limit: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    selections: PropTypes.array.isRequired,
  }).isRequired,
  isChecked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default Customization;
