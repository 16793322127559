import { PointTooltipProps } from '@nivo/line';

import styles from './styles.module.scss';

type Props = {
  isMobile: boolean;
  pointIndexesToShow: number[];
  pointTooltipProps: PointTooltipProps;
  yAxisTooltipLabel: string;
};

export const ChartTooltip = ({
  isMobile,
  pointIndexesToShow,
  pointTooltipProps,
  yAxisTooltipLabel,
}: Props) => {
  const { point } = pointTooltipProps;
  const pointIndex = point.index;

  if (!isMobile || pointIndexesToShow.includes(pointIndex)) {
    return (
      <div
        className={styles.tooltip}
      >{`${yAxisTooltipLabel}: ${point.data.yFormatted}`}</div>
    );
  }

  return <></>;
};
