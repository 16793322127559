import { useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { createColumnHelper } from '@tanstack/react-table';

import { Link } from 'crust';

import ContentTile from 'components/shared/content-tile';
import Modal from 'components/shared/slice-modal';
import Table from 'components/shared/table';
import TableAction from 'components/shared/table/action';
import { TooltipIconButton } from 'components/shared/tooltips';
import VisuallyHidden from 'components/shared/visually-hidden';
import { useSaveOnlineDiscountMutation } from 'hooks/discounts';
import { useTable } from 'hooks/shared';
import * as paths from 'routes/paths';
import { OnlineDiscountType } from 'types/discounts';
import { Shop } from 'types/shops';
import { showUnexpectedErrorToast } from 'utilities/forms';

import styles from './styles.module.scss';

type OnlineDiscountRow = {
  id: number;
  discount: number;
};

export type AutomaticDiscountsProps = {
  shopDiscount: Shop['discountPercent'];
  shopId: Shop['shopId'];
};

export const AutomaticDiscounts = ({
  shopDiscount,
  shopId,
}: AutomaticDiscountsProps) => {
  const [idToDelete, setIdToDelete] = useState<number | null>(null);

  const { mutate: saveDiscount, isLoading: isDiscountSaving } =
    useSaveOnlineDiscountMutation(shopId, false);

  const handleDeleteDiscount = () => {
    saveDiscount(
      { amount: null, type: OnlineDiscountType.Percent },
      {
        onError: () => {
          showUnexpectedErrorToast();
        },
        onSuccess: () => {
          toast.success('Discount successfully deleted!');
        },
        onSettled: () => {
          handleModalClose();
        },
      },
    );
  };

  const columns = useMemo(() => {
    const helper = createColumnHelper<OnlineDiscountRow>();

    return [
      helper.accessor('discount', {
        header: 'Discount',
        meta: {
          className: styles.discountColumn,
        },
        cell(ctx) {
          return `${ctx.getValue()}%`;
        },
      }),

      helper.display({
        id: 'actions',
        enableSorting: false,
        header() {
          return <VisuallyHidden>Actions</VisuallyHidden>;
        },
        cell() {
          return (
            <>
              <TableAction
                icon="pencil"
                title="Edit online discount"
                to={paths.automaticDiscount(shopId, 'edit')}
              />
              <TableAction
                icon="trashCan"
                title="Delete online discount"
                onClick={() => setIdToDelete(shopId)}
              />
            </>
          );
        },
        meta: {
          className: styles.editColumn,
          isActionColumn: true,
        },
      }),
    ];
  }, [shopId]);

  const table = useTable({
    columns,
    enableFilters: false,
    enableSorting: false,
    manualPagination: true,
    data: shopDiscount == null ? [] : [{ id: shopId, discount: shopDiscount }],
    chameleonTableTitle: 'Online discounts',
  });

  const handleModalClose = () => {
    setIdToDelete(null);
  };

  const tileTitleContent = (
    <div className={styles.tileHeader}>
      Online Discount
      <TooltipIconButton label="More about online discount">
        This discount applies to all online orders. Typically a higher discount
        will result in more orders.
      </TooltipIconButton>
    </div>
  );

  return (
    <>
      <ContentTile
        title={tileTitleContent}
        headerContent={
          <Link
            appearance="button"
            data-chameleon-target="Add New Discount"
            isDisabled={shopDiscount != null}
            href={paths.automaticDiscount(shopId, 'new')}
            variant="secondary"
          >
            Create
          </Link>
        }
      >
        <Table table={table} />
      </ContentTile>
      <Modal
        header={`Are you sure you want to delete your Online Discount?`}
        isOpen={idToDelete != null}
        isYesButtonDisabled={isDiscountSaving}
        onClickNo={handleModalClose}
        onClickYes={handleDeleteDiscount}
        onRequestClose={handleModalClose}
        yesButtonText="Delete"
      >
        You are about to delete your Online Discount, this action cannot be
        undone.
      </Modal>
    </>
  );
};
