import * as React from "react";
import { forwardRef } from "react";
const SvgPizzaRightIcon = ({
  title,
  titleId,
  ...props
}, ref) => {
  return /* @__PURE__ */ React.createElement("svg", { width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", "aria-hidden": "true", ref, "aria-labelledby": titleId, ...props }, title === void 0 ? /* @__PURE__ */ React.createElement("title", { id: titleId }, "Pizza Right Icon") : title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M12 20.1343C12 21.2574 12.9045 22.189 13.9872 21.9671C18.5581 21.03 22 16.9237 22 12C22 7.07631 18.5581 2.97001 13.9872 2.03295C12.9045 1.81097 12 2.74264 12 3.86574L12 12C12.5523 12 13 12.4552 13 13.0168C13 13.5784 12.5523 14.0336 12 14.0336L12 20.1343ZM17 7.93289C17 9.056 16.1046 9.96646 15 9.96646C13.8954 9.96646 13 9.056 13 7.93289C13 6.80979 13.8954 5.89933 15 5.89933C16.1046 5.89933 17 6.80979 17 7.93289ZM19 13.0168C19 13.5784 18.5523 14.0336 18 14.0336C17.4477 14.0336 17 13.5784 17 13.0168C17 12.4552 17.4477 12 18 12C18.5523 12 19 12.4552 19 13.0168ZM17 17.5923C17 18.4346 16.3284 19.1175 15.5 19.1175C14.6716 19.1175 14 18.4346 14 17.5923C14 16.75 14.6716 16.0671 15.5 16.0671C16.3284 16.0671 17 16.75 17 17.5923Z", fill: "currentColor" }), /* @__PURE__ */ React.createElement("path", { d: "M2 12C2 7.08088 5.43552 2.97765 10 2.03556L10 4.12199C6.54954 5.02498 4 8.20975 4 12C4 15.7902 6.54955 18.975 10 19.878V21.9644C5.43552 21.0223 2 16.9191 2 12Z", fill: "currentColor" }));
};
const ForwardRef = forwardRef(SvgPizzaRightIcon);
export default ForwardRef;
