import { Button } from 'crust';

import { useShopTraitsContext } from 'hooks/shops/use-shop-traits-context';
import useAnalytics from 'hooks/use-analytics';
import { ClickedExternalLinkType } from 'types/shared/analytics';
import { Shop } from 'types/shops';

type Props = {
  onScheduleDemoButtonClick: () => void;
  shopId: Shop['shopId'];
};

export const ScheduleDemoButton = ({
  onScheduleDemoButtonClick,
  shopId,
}: Props) => {
  const { trackProductUpsellLinkClick } = useAnalytics();
  const shopTraits = useShopTraitsContext();

  const handleScheduleDemo = () => {
    onScheduleDemoButtonClick();
    trackProductUpsellLinkClick({
      shopId: shopId,
      product: 'register',
      page: 'register [splash]',
      linkLabel: 'schedule demo',
      type: ClickedExternalLinkType.CTA,
      outcome: 'lead',
      shopIntegrationAdoption: shopTraits.shopIntegrationAdoption,
    });
  };

  return <Button onPress={handleScheduleDemo}>schedule demo</Button>;
};
