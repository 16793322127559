import { useEffect, useState } from 'react';
import {
  Control,
  Controller,
  FieldError,
  UseFormRegister,
  useWatch,
} from 'react-hook-form';

import CollapsibleTile from 'components/shared/collapsible-tile';
import FormFeedback from 'components/shared/form-feedback';
import Label from 'components/shared/label';
import NumberInput from 'components/shared/number-input';
import { RHFRadio, RHFRadioGroup } from 'components/shared/rhf-radio';
import { PromoCodeFormValues } from 'types/discounts';

import { OrderMinimumSummary } from './summary';

import styles from './styles.module.scss';

type Props = {
  className?: string;
  control: Control<PromoCodeFormValues>;
  orderMinimumError?: FieldError;
  register: UseFormRegister<PromoCodeFormValues>;
};

export const OrderMinimumFields = ({
  className,
  control,
  orderMinimumError,
}: Props) => {
  const [isOpen, setIsOpen] = useState(true);

  const hasOrderMinimum = useWatch({
    control,
    name: 'hasOrderMinimum',
  });

  useEffect(() => {
    if (orderMinimumError) {
      setIsOpen(true);
    }
  }, [orderMinimumError]);

  return (
    <CollapsibleTile
      bodyChameleonTarget="Promo Code Order Minimum"
      className={className}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      summary={<OrderMinimumSummary control={control} />}
      title="Set Order Minimum"
    >
      <RHFRadioGroup
        name="hasOrderMinimum"
        control={control}
        data-chameleon-target="Promo Code Select Type"
        label="Set a minimum price customers must spend to use the discount"
        orientation="horizontal"
        variant="card"
      >
        <RHFRadio label="No minimum" value="false" />
        <RHFRadio label="Minimum amount ($)" value="true" />
      </RHFRadioGroup>
      {hasOrderMinimum === 'true' && (
        <div className={styles.minimum}>
          <Label htmlFor="promo-code-order-minimum">Minimum amount</Label>
          <Controller
            control={control}
            name="orderMinimum"
            rules={{
              min: {
                value: 0.01,
                message: 'Must be at least $0.01.',
              },
              required: 'Please enter a price.',
            }}
            render={({ field, fieldState }) => (
              <NumberInput
                aria-required="true"
                id="promo-code-order-minimum"
                isInvalid={fieldState.error != null}
                name={field.name}
                onBlur={field.onBlur}
                onValueChange={(values) => field.onChange(values.value)}
                prefix="$"
                ref={field.ref}
                value={field.value}
                valueIsNumericString
              />
            )}
          />
          <FormFeedback>{orderMinimumError?.message}</FormFeedback>
        </div>
      )}
    </CollapsibleTile>
  );
};
