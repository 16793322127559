import { FormEventHandler } from 'react';
import {
  Control,
  Controller,
  FieldErrors,
  UseFormGetValues,
  UseFormRegister,
} from 'react-hook-form';

import { Button, Link } from 'crust';

import ContentTile from 'components/shared/content-tile';
import FormFeedback from 'components/shared/form-feedback';
import Input from 'components/shared/input';
import Label from 'components/shared/label';
import PhoneInput from 'components/shared/phone-input';
import { RHFCheckbox, RHFCheckboxGroup } from 'components/shared/rhf-checkbox';
import * as paths from 'routes/paths';
import { RegisterPermissionGroup, UserFormValues } from 'types/register-users';

import styles from './styles.module.scss';

type Props = {
  shopId: string;
  control: Control<UserFormValues>;
  errors: FieldErrors<UserFormValues>;
  isNew?: boolean;
  onDeleteUser: () => void;
  onSubmit: FormEventHandler<HTMLFormElement>;
  register: UseFormRegister<UserFormValues>;
  getValues: UseFormGetValues<UserFormValues>;
  permissionGroups: RegisterPermissionGroup[];
};

// onDeleteUser is not used yet because that feature causes issues on the
// backend; deleting a user removes them from all shops, not just the current
// one.
// https://mypizza.slack.com/archives/C03M1SR0SH5/p1664297250233169
const UserTile = ({
  shopId,
  control,
  errors,
  isNew = false,
  onSubmit,
  register,
  getValues,
  permissionGroups,
}: Props) => {
  const hasPermissionGroups = permissionGroups && permissionGroups.length > 0;

  return (
    <ContentTile
      className={styles.userTile}
      title={isNew ? 'Add User' : 'Edit User'}
    >
      <form className={styles.userForm} onSubmit={onSubmit}>
        <div>
          <Label htmlFor="register-user-name">User Name</Label>
          <Input
            aria-required="true"
            id="register-user-name"
            isInvalid={errors.name != null}
            placeholder=""
            {...register('name', {
              required: 'Please enter a name for this user.',
            })}
            data-chameleon-target="Register User Name Input"
          />
          <FormFeedback>{errors.name?.message}</FormFeedback>
        </div>
        <div className={styles.userPermissionGroups}>
          <div className={styles.userPermissionsGroupLabel}>
            User Permissions Group
          </div>
          {!hasPermissionGroups && (
            <div className={styles.userPermissionsGroupDescription}>
              You currently have not set up a user permission group. Give your
              team access to specific tasks by adding users and assigning
              permissions.
            </div>
          )}
          {!hasPermissionGroups && (
            <Link href={paths.registerPermission(shopId, 'new')}>
              Add a Permission Group
            </Link>
          )}
          {hasPermissionGroups && (
            <RHFCheckboxGroup
              aria-label="User Permissions Group"
              control={control}
              name="permissionGroups"
              data-chameleon-target="Available Permission Groups"
              orientation="vertical"
            >
              {permissionGroups.map((group) => (
                <RHFCheckbox
                  label={group.name}
                  key={group.id}
                  value={group.id}
                />
              ))}
            </RHFCheckboxGroup>
          )}
          <FormFeedback>{errors.permissionGroups?.message}</FormFeedback>
        </div>
        <hr className={styles.divider} />
        <div
          className={styles.userEmailAndPhone}
          data-chameleon-target="Add Phone Or Email"
        >
          <div className={styles.emailAndPhoneDescription}>
            Only one of the following is required:
          </div>
          <div>
            <Label htmlFor="register-user-email">Email</Label>
            <Input
              aria-required="true"
              id="register-user-email"
              type="email"
              isInvalid={errors.email != null}
              placeholder=""
              {...register('email', {
                deps: ['phone'],
                validate: {
                  eitherEmailOrPhone: (value) => {
                    if (value || getValues('phone')) {
                      return true;
                    }

                    return 'Please enter an email address or phone number.';
                  },
                },
              })}
            />
            <FormFeedback>{errors.email?.message}</FormFeedback>
          </div>
          <div>
            <Label htmlFor="register-user-phone">Phone Number</Label>
            <Controller
              control={control}
              name="phone"
              rules={{
                pattern: {
                  value: /^\d{10}$/,
                  message: 'Please enter a valid phone number.',
                },
                validate: (value) =>
                  !!value ||
                  !!getValues('email') ||
                  'Please enter an email address or phone number.',
                deps: ['email'],
              }}
              render={({ field: { onChange, onBlur, value, name, ref } }) => (
                <PhoneInput
                  aria-required="true"
                  id="register-user-phone"
                  type="tel"
                  isInvalid={errors.phone != null}
                  placeholder=""
                  onValueChange={(values) => onChange(values.value)}
                  onBlur={onBlur}
                  value={value}
                  valueIsNumericString
                  name={name}
                  ref={ref}
                />
              )}
            />
            <FormFeedback>{errors.phone?.message}</FormFeedback>
          </div>
        </div>
        <hr className={styles.divider} />
        <div className={styles.buttonsWrapper}>
          <Link
            appearance="button"
            className={styles.button}
            href={paths.registerUsers(shopId)}
            variant="secondary"
          >
            Cancel
          </Link>
          <Button
            className={styles.button}
            data-chameleon-target="Save Register User"
            type="submit"
            variant="primary"
          >
            Save
          </Button>
        </div>
      </form>
    </ContentTile>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default UserTile;
