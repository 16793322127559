import { useMutation, useQueryClient } from '@tanstack/react-query';

import useAnalytics from 'hooks/use-analytics';
import useApi from 'hooks/use-api';
import { PromoCode } from 'types/discounts';
import { Shop } from 'types/shops';
import { camelCaseKeys } from 'utilities/objects';

import { getPromoCodesQueryKey } from './use-promo-codes-query';

type Values = {
  promoCodeId: PromoCode['id'];
  shopId: Shop['shopId'];
};

export const useDeletePromoCodeMutation = () => {
  const { authenticatedFetch } = useApi();
  const queryClient = useQueryClient();
  const { trackPromoCodeDeactivate } = useAnalytics();

  return useMutation({
    mutationFn: async ({ promoCodeId, shopId }: Values) => {
      const response = await authenticatedFetch.patch(
        `api/management/v1/shops/${shopId}/promo_codes/${promoCodeId}`,
        { active: false },
      );

      return camelCaseKeys(response.data) as PromoCode;
    },
    onSuccess: (_, { promoCodeId, shopId }) => {
      queryClient.setQueryData<PromoCode[]>(
        getPromoCodesQueryKey(shopId),
        (current) => current?.filter((it) => it.id !== promoCodeId),
      );

      trackPromoCodeDeactivate(String(shopId), promoCodeId, true);
    },
  });
};
