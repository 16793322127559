import { TooltipIconButton } from 'components/shared/tooltips';

import styles from './styles.module.scss';

const ShopProfileTooltip = () => {
  return (
    <TooltipIconButton
      label="About your business profile"
      tooltipClassName={styles.tooltip}
    >
      <p>
        If you need to update or correct any information, please reach out to
        your <span className={styles.emphasis}>Partner Success Manager</span>.
      </p>
    </TooltipIconButton>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default ShopProfileTooltip;
