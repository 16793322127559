import { ComponentPropsWithoutRef } from 'react';
import cx from 'classnames';

import styles from './styles.module.scss';

export type TableBodySpannerProps = {
  colSpan: number; // Make colSpan required.
} & Omit<ComponentPropsWithoutRef<'td'>, 'colSpan'>;

const TableBodySpanner = ({ className, ...props }: TableBodySpannerProps) => (
  <tr className={styles.row}>
    <td className={cx(styles.spanner, className)} {...props} />
  </tr>
);

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default TableBodySpanner;
