import * as React from "react";
import { forwardRef } from "react";
const SvgLoyalCustomerIconNoOutline = ({
  title,
  titleId,
  ...props
}, ref) => {
  return /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", fill: "none", viewBox: "0 0 24 24", ref, "aria-labelledby": titleId, ...props }, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { stroke: "#211E1E", strokeWidth: 2, d: "M4 16v4h16v-4M4 16 2 7c.83333 1.33333 3.2 4 6 4s3.8333-4.66667 4-7c.1667 2.33333 1.2 7 4 7s5.1667-2.66667 6-4l-2 9M4 16h16" }));
};
const ForwardRef = forwardRef(SvgLoyalCustomerIconNoOutline);
export default ForwardRef;
