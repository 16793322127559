import ContentTile from 'components/shared/content-tile';
import { useShopProfileQuery } from 'hooks/brand-manager';

import BusinessProfileTileContent from './tile-content';
import BusinessProfileTileHeader from './tile-header';

import styles from './styles.module.scss';

type Props = {
  shopId: string;
};

const BusinessProfile = ({ shopId }: Props) => {
  const { data, isLoading } = useShopProfileQuery(shopId);

  return (
    <ContentTile
      className={styles.tile}
      isLoading={isLoading}
      headerContent={<BusinessProfileTileHeader />}
      headerClassName={styles.header}
    >
      <BusinessProfileTileContent shopProfile={data} />
    </ContentTile>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default BusinessProfile;
