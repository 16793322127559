import { Id, shop } from './shop';

const getFinancialsBasePath = (shopId?: Id) => `${shop(shopId)}/financials`;

export const getFinancialsOrdersPath = (
  shopId?: Id,
  tab: 'online' | 'register' | ':tab' = ':tab',
) => `${getFinancialsBasePath(shopId)}/orders/${tab}`;

export const getFinancialsStatementsPath = (shopId?: Id) =>
  `${getFinancialsBasePath(shopId)}/statements`;

export const getFinancialsPayoutsPath = (shopId?: Id) =>
  `${getFinancialsBasePath(shopId)}/payouts`;

const getFinancialsReportsBasePath = (shopId?: Id) =>
  `${getFinancialsBasePath(shopId)}/reports`;

export const getFinancialsReportsOrderActivityPath = (shopId?: Id) =>
  `${getFinancialsReportsBasePath(shopId)}/order-activity`;

export const getFinancialsReportsBasicTaxPath = (shopId?: Id) =>
  `${getFinancialsReportsBasePath(shopId)}/tax`;

export const getFinancialsReportsSalesMixPath = (shopId?: Id) =>
  `${getFinancialsReportsBasePath(shopId)}/sales-mix`;

export const getFinancialsReportsOrdersPath = (shopId?: Id) =>
  `${getFinancialsReportsBasePath(shopId)}/orders`;

export const getFinancialsReportsRegisterSalesSummaryPath = (shopId?: Id) =>
  `${getFinancialsReportsBasePath(shopId)}/register-sales-summary`;

export const getFinancialsReportsCombinedSalesSummaryPath = (shopId?: Id) =>
  `${getFinancialsReportsBasePath(shopId)}/sales-summary`;

export const getFinancialsReportsStandalonePaymentsPath = (shopId?: Id) =>
  `${getFinancialsReportsBasePath(shopId)}/standalone-payments`;
