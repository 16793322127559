import ContentTile from 'components/shared/content-tile';
import Link from 'components/shared/link';
import useAnalytics from 'hooks/use-analytics';
import FacebookIcon from 'images/social-media/facebook-grey.svg?react';
import InstagramIcon from 'images/social-media/instagram-grey.svg?react';
import TwitterIcon from 'images/social-media/twitter-grey.svg?react';

import styles from './styles.module.scss';

const socialMedia = [
  {
    name: 'Instagram',
    icon: InstagramIcon,
    text: '@slice',
    url: 'https://www.instagram.com/slice/',
  },
  {
    name: 'Facebook',
    icon: FacebookIcon,
    text: '@SlicePizzaApp',
    url: 'https://www.facebook.com/SlicePizzaApp/',
  },
  {
    name: 'Twitter',
    icon: TwitterIcon,
    text: '@slice',
    url: 'https://twitter.com/slice',
  },
];

const HeroTile = ({ shopId }: { shopId: string }) => {
  const { trackClickedSocialMediaTitle } = useAnalytics();

  const onSocialMediaButtonClick = (name: string) => {
    trackClickedSocialMediaTitle({ type: name, shopId });
  };

  return (
    <ContentTile className={styles.header}>
      <div className={styles.primaryText}>Delicious on every platform.</div>
      <div className={styles.secondaryText}>
        Social media is a powerful way to stay top-of-mind with your customers
        and reach new clientele, but creating content can be time-consuming.
        These photos and videos are stylish, eye-catching, and instantly
        downloadable.
      </div>
      <div className={styles.socialMediaContainer}>
        <p>
          Check us out on social media and make sure to tag us in your posts.
        </p>
        <div className={styles.socialMediaButtons}>
          {socialMedia.map((social) => (
            <Link
              key={social.name}
              className={styles.socialMediaButton}
              to={social.url}
              onClick={() => onSocialMediaButtonClick(social.name)}
              variant="undecorated"
            >
              <social.icon className={styles.socialMediaIcon} />
              <span>{social.text}</span>
            </Link>
          ))}
        </div>
      </div>
    </ContentTile>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default HeroTile;
