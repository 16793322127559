import queryString from 'query-string';

export const partnerButtonImage = {
  url: (size, color) =>
    `https://slicelink-assets-production.imgix.net/partner-buttons/slice-${size}-${color}.svg`,
  alt: (size, color) => `${size} ${color} button`,
};

export const customPartnerButtonImage = {
  url: (size, color, shape, letterCase) =>
    `https://slicelink-assets-production.imgix.net/partner-buttons/${shape}-${size}-${color}-${letterCase}.svg`,
  alt: (size, color, shape, letterCase) =>
    `${size} ${color} ${shape} ${letterCase} button`,
};

export const imgixUrl = (url, height, width) => {
  if (!url) {
    return '';
  }

  const imgixParams = queryString.stringify({
    auto: 'compress,format',
    fit: 'crop',
    w: width,
    h: height,
  });

  const queryConnector = url.includes('?') ? '&' : '?';

  return `${url}${queryConnector}${imgixParams}`;
};
