export type Breakpoint = 'desktop' | 'tablet' | 'phone';

export type ConfigOption = {
  hexValue?: string;
  label: string;
  name: string;
  value: number;
};

export type SiteEditorFormValues = {
  colorCode: string;
  fontCode: string;
};

// These fields can be null in the response, this means that the shop has
// not accessed site editor before.
export type SiteEditorSettings = {
  color: number | null;
  fontColor: number | null;
  fontFamily: number | null;
};

export enum ColorCodes {
  CherryWine = 3,
  DarkRed = 4,
  DeepGreen = 2,
  Graphite = 5,
  SliceRed = 1,
}

export enum FontCodes {
  GtAmerica = 1,
  Jost = 2,
  Noto = 4,
  Oswald = 3,
  PollerOne = 5,
}

export enum FontColorCodes {
  White = 1,
}

export type SiteEditorAnalyticsEventData = {
  backgroundColorHex: string;
  backgroundColorName: string;
  font: string;
  shopId: string;
  success?: boolean;
};
