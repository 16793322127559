import { toast } from 'react-toastify';

import { Button, Link } from 'crust';

import { useCreateSalesforceCaseMutation } from 'hooks/salesforce/use-create-salesforce-case';
import useAnalytics from 'hooks/use-analytics';
import { useCopyToClipboard } from 'hooks/use-copy-to-clipboard';
import * as externalUrls from 'routes/external-urls';
import {
  SalesforceCaseType,
  SalesforceNote,
  SalesforceSubject,
} from 'types/salesforce';
import { ClickedExternalLinkType } from 'types/shared/analytics';
import { Shop, ShopTraits } from 'types/shops';
import { User } from 'types/user';
import { WebsiteIntegrationsAnalyticsPageName } from 'types/website-tools';
import { getWebsiteIntegrationsScriptCode } from 'utilities/website-tools';

import styles from './styles.module.scss';

type Props = {
  isShareCodeDisplayed: boolean;
  isTertiary: boolean;
  openShareEmbedCodeModal?: () => void;
  shopInfo: Shop;
  shopTraits: ShopTraits;
  shouldCreateCase: boolean;
  user?: User;
};

export const EmbedActions = ({
  openShareEmbedCodeModal,
  isShareCodeDisplayed,
  isTertiary,
  shopInfo,
  shopTraits,
  shouldCreateCase,
  user,
}: Props) => {
  const [copy] = useCopyToClipboard();
  const code = getWebsiteIntegrationsScriptCode(
    shopInfo.city,
    shopInfo.name,
    shopInfo.uuid,
  );
  const {
    trackClickedWebsiteIntegrationsBestPracticesLink,
    trackClickedCopyWebsiteIntegrationsCodeButton,
    trackProductUpsellLinkClick,
  } = useAnalytics();
  const {
    mutate: createSalesforceCase,
    isLoading: isCreatingSalesforceCaseLoading,
  } = useCreateSalesforceCaseMutation();

  const handleSetupInstructionsClick = () => {
    trackClickedWebsiteIntegrationsBestPracticesLink({
      shopId: String(shopInfo.shopId),
      content: 'instructions',
      isActive: String(shopTraits.isSmartButtonActive),
      page: WebsiteIntegrationsAnalyticsPageName.SmartButtons,
    });
  };

  const handleCopyClick = () => {
    copy(code, 'Embed code copied to your clipboard.');
    trackClickedCopyWebsiteIntegrationsCodeButton({
      shopId: String(shopInfo.shopId),
      isActive: String(shopTraits.isSmartButtonActive),
      page: WebsiteIntegrationsAnalyticsPageName.SmartButtons,
      shopIntegrationAdoption: shopTraits.shopIntegrationAdoption,
    });
  };

  const handleShouldCreateSaleforceCaseClick = () => {
    if (!isCreatingSalesforceCaseLoading && user) {
      createSalesforceCase(
        {
          salesforceCase: {
            caseType: SalesforceCaseType.SmartButton,
            subject: SalesforceSubject.SmartButton,
          },
          note: SalesforceNote.CopyCodeNote,
          shopId: shopInfo.shopId,
          user: user,
        },
        {
          onError: () =>
            toast.error(
              `We weren't able to process your request. Please contact your Partner Success Manager.`,
            ),
        },
      );
    }
    copy(code, 'Embed code copied to your clipboard.');
    trackClickedCopyWebsiteIntegrationsCodeButton({
      shopId: String(shopInfo.shopId),
      isActive: String(shopTraits.isSmartButtonActive),
      page: WebsiteIntegrationsAnalyticsPageName.SmartButtons,
      shopIntegrationAdoption: shopTraits.shopIntegrationAdoption,
    });
  };

  const handleShareCodeClick = () => {
    openShareEmbedCodeModal && openShareEmbedCodeModal();
    trackProductUpsellLinkClick({
      shopId: String(shopInfo.shopId),
      product: 'smart button',
      page: 'smart buttons',
      linkLabel: 'share code',
      type: ClickedExternalLinkType.CTA,
      outcome: 'lead',
      shopIntegrationAdoption: shopTraits.shopIntegrationAdoption,
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.buttons}>
        <Button
          className={styles.button}
          variant={isTertiary ? 'secondary' : 'primary'}
          type="button"
          onPress={
            shouldCreateCase
              ? () => handleShouldCreateSaleforceCaseClick()
              : () => handleCopyClick()
          }
        >
          Copy Code
        </Button>
        {isShareCodeDisplayed && (
          <Button
            className={styles.button}
            onPress={handleShareCodeClick}
            type="button"
            variant="secondary"
          >
            Share Code
          </Button>
        )}
      </div>
      <Link
        onPress={() => handleSetupInstructionsClick()}
        href={externalUrls.websiteIntegrationsInstructions}
      >
        Setup Instructions
      </Link>
    </div>
  );
};
